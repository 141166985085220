import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

import SettlementFilterModal from './components/SettlementFilterModal';
import SettlementTable from './components/SettlementTable';
import {
  FilterType,
  useSettlementDetailHooks,
} from './hooks/useSettlementDetailHooks';
import Text from 'components/common/Text';
import { SETTLEMENT_LIST_PATH } from 'constants/path';
import SettlementItem from 'containers/SettlementList/components/SettlementItem';
import SettlementRequestModal from 'containers/SettlementList/components/SettlementRequestModal';
import { ICON_ARROW_BLACK_BOTTOM, ICON_ARROW_LEFT } from 'assets/icons';
import HackleManager from 'utils/hackle';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 768px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  position: relative;
  width: 100%;
  border-bottom: 1px solid #dbdcdf;
  background: #fff;

  img {
    position: absolute;
    left: 20px;
  }

  span {
    width: 100%;
    color: #1b1c1e;
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: -0.096px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 24px;
  padding: 40px 0;
`;

const SettlementBox = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0 20px;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
`;

const FilterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  padding: 6px 8px;

  border-radius: 9999px;
  border: 1px solid #eaebec;
  background: #fff;

  color: #1b1c1e;

  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.6px;
`;

const Divider = styled.div`
  width: 100%;
  height: 8px;
  background: #eaebec;
`;

const SettlementDetailPage = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  dayjs.locale('ko');

  const {
    settlementItem,
    accountList,
    sortedAccountList,
    filterType,
    handleAccountListFilter,
  } = useSettlementDetailHooks();

  const [
    isOpenSettlementInformationModal,
    setIsOpenSettlementInformationModal,
  ] = useState<boolean>(false);
  const [isOpenFilterModal, setIsOpenFilterModal] = useState<boolean>(false);

  const handlePageBackwardNavigate = () => {
    if (isOpenSettlementInformationModal) {
      setIsOpenSettlementInformationModal(false);

      return;
    }

    if (isOpenFilterModal) {
      setIsOpenFilterModal(false);

      return;
    }

    navigate(SETTLEMENT_LIST_PATH, { state: { id: params.id } });
  };

  useEffect(() => {
    window.handlePageBackwardNavigate = handlePageBackwardNavigate;
  }, [isOpenFilterModal, isOpenSettlementInformationModal]);

  useEffect(() => {
    HackleManager.logSettlementDetailView(params.id);
  }, []);

  return (
    <Container>
      <Header>
        <img
          src={ICON_ARROW_LEFT}
          alt="back image"
          onClick={() => {
            navigate(SETTLEMENT_LIST_PATH, { state: { id: params.id } });
          }}
        />
        <span>판매 내역</span>
      </Header>
      {settlementItem && accountList && (
        <ContentContainer>
          <SettlementBox>
            <SettlementItem
              settlementItem={settlementItem!.settlement.settlement}
              type="detail"
              handleOpenSettlementInformationModal={() => {
                setIsOpenSettlementInformationModal(true);
              }}
            />
          </SettlementBox>
          <Divider />
          <Row>
            <Text color="#1b1c1e" size="20px">
              총 건수: {accountList.settlement.accounts.totalCount}
            </Text>
            <FilterButton onClick={() => setIsOpenFilterModal(true)}>
              <span>
                {filterType === FilterType.DATE
                  ? '결제일순'
                  : filterType === FilterType.TYPE
                  ? '유형별'
                  : '상품별'}
              </span>
              <img src={ICON_ARROW_BLACK_BOTTOM} alt="drop down icon" />
            </FilterButton>
          </Row>
          <SettlementTable accountItem={sortedAccountList} />
        </ContentContainer>
      )}
      {isOpenFilterModal && (
        <SettlementFilterModal
          handleAccountListFilter={handleAccountListFilter}
          onClose={() => setIsOpenFilterModal(false)}
        />
      )}
      {isOpenSettlementInformationModal && (
        <SettlementRequestModal
          type="information"
          settlementItem={settlementItem!.settlement.settlement}
          onClose={() => setIsOpenSettlementInformationModal(false)}
        />
      )}
    </Container>
  );
};

export default SettlementDetailPage;
