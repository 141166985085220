import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { SAVE_HOST_REGISTRATION_NO } from '..';
import { SETTLEMENT_PATH } from 'constants/path';

export function useMutationSaveHostRegistrationNo() {
  const navigate = useNavigate();
  const [saveHostRegistrationNo] = useMutation(SAVE_HOST_REGISTRATION_NO);

  const handleSaveHostRegistrationNo = async (
    hostId: string,
    registrationNo: string
  ) => {
    try {
      const response = await saveHostRegistrationNo({
        variables: {
          hostId,
          registrationNo,
        },
      });

      if (response) {
        navigate(SETTLEMENT_PATH);

        location.reload();
      }
    } catch (error) {
      console.log(error, 'save host regist error');
    }
  };

  return {
    handleSaveHostRegistrationNo,
  };
}
