import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { COMPLETE_HOST_ATTACHMENT_UPLOAD } from '..';
import { SETTLEMENT_PATH } from 'constants/path';

export function useMutationCompleteHostAttachmentUpload() {
  const navigate = useNavigate();
  const [completeHostAttachmentUpload] = useMutation(
    COMPLETE_HOST_ATTACHMENT_UPLOAD
  );

  const handleCompleteHostAttachmentUpload = async (
    type: 'create' | 'modify',
    hostId: string,
    url: string
  ) => {
    try {
      const response = await completeHostAttachmentUpload({
        variables: { hostId, url },
      });

      if (response) {
        if (type === 'modify') {
          navigate(SETTLEMENT_PATH);

          location.reload();
        }
      }
    } catch (error) {
      console.log(error, 'complet host attachment error');
    }
  };

  return { handleCompleteHostAttachmentUpload };
}
