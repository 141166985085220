import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ICON_CLOSE } from 'assets/icons';
import AuthButton from 'components/Button/AuthButton';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import InputForm from './InputForm';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import HackleManager from 'utils/hackle';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  width: 100%;
  /* min-height: 100vh; */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

const BottomSheet = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  max-width: 375px;
  border-radius: 16px 16px 0px 0px;
  border-bottom: 1px solid var(--gray-gray-03, #eaebec);
  background: var(--Static-White, #fff);
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
`;

interface FindPasswordModalIProps {
  handleOnChangeFindPasswordModal: () => void;
  handleFindPassword: (email: string, callback: () => void) => void;
}

const FindPasswordModal: React.FC<FindPasswordModalIProps> = props => {
  const { handleOnChangeFindPasswordModal, handleFindPassword } = props;

  const [email, setEmail] = useState<string>('');

  const handleOnchangeEmail = (key: string, value: string) => {
    setEmail(value);
  };

  useEffect(() => {
    HackleManager.logForgotPasswordView();
  }, []);

  return (
    <ModalContainer
      isAbled={true}
      isMobile={false}
      isBottomSheet={true}
      onClick={() => {}}
      onClose={() => {}}
      style={{ gap: 0 }}
    >
      <Row
        justifyContent="flex-start"
        style={{
          padding: '20px',
          width: '100%',
          borderBottom: '1px solid #EAEBEC',
        }}
      >
        <img
          src={ICON_CLOSE}
          alt="close icon"
          onClick={handleOnChangeFindPasswordModal}
          style={{ cursor: 'pointer' }}
        />
      </Row>
      <Column gap="40px" style={{ padding: '20px 20px 40px 20px' }}>
        <Column gap="32px">
          <Column gap="8px" align="flex-start">
            <Text color="#1B1C1E" size="20px" weight={700}>
              비밀번호 찾기
            </Text>
            <Text color="#70737C" weight={400}>
              가입하신 이메일 주소를 입력해 주세요. 비밀번호 재설정 링크를
              보내드리겠습니다.
            </Text>
          </Column>
          <InputContainer>
            <InputForm
              label="아이디(이메일)"
              type="text"
              styleType="large"
              target="email"
              onChange={handleOnchangeEmail}
            />
          </InputContainer>
        </Column>
        <AuthButton
          name="비밀번호 재설정"
          isAbled={email !== ''}
          onClick={() => {
            if (email !== '') {
              handleFindPassword(email, handleOnChangeFindPasswordModal);
            }
          }}
        />
      </Column>
    </ModalContainer>
  );
};

export default FindPasswordModal;
