import { useMutation } from '@apollo/client';
import { errorHandler } from 'utils/ErrorHandler';
import { OPEN_SCHEDULE, PAUSE_SCHEDULE } from '..';

export function useMutationScheduleStatus() {
  const [pauseSchedule] = useMutation(PAUSE_SCHEDULE);
  const [openSchedule] = useMutation(OPEN_SCHEDULE);

  const handlePauseSchedule = async (id: string, callback: () => void) => {
    try {
      const response = await pauseSchedule({ variables: { id } });

      if (response) {
        callback();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleOpenSchedule = async (id: string, callback: () => void) => {
    try {
      const response = await openSchedule({ variables: { id } });

      if (response) {
        callback();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  return {
    handlePauseSchedule,
    handleOpenSchedule,
  };
}
