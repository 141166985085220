import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { ModifyValue } from 'containers/ProductDetail/hooks/useProductDetaillHooks';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
  padding: 6px;
  width: 100%;

  border-radius: 99px;
  background: #eaebec;
`;

const SelectButton = styled.button<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  width: 100%;

  border-radius: 99px;
  background: ${props => (props.$isSelected ? '#fff' : '#eaebec')};
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
  color: #1b1c1e;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
`;

interface MenuSettingButtonIProps {
  isMobile: boolean;
  isSelected: boolean;
  firstMenu: string;
  secondMenu: string;
  modifyValue: ModifyValue;
  handleSetMenuType: (type: string) => void;
  setModifyValue: Dispatch<SetStateAction<ModifyValue>>;
}

const MenuSettingButton: React.FC<MenuSettingButtonIProps> = props => {
  const {
    isMobile,
    isSelected,
    firstMenu,
    secondMenu,
    modifyValue,
    handleSetMenuType,
    setModifyValue,
  } = props;

  return (
    <Container>
      <SelectButton
        $isSelected={isSelected}
        onClick={() => {
          handleSetMenuType(firstMenu);

          if (isMobile) {
            setModifyValue({ ...modifyValue, modifyStatus: '' });
          } else {
            setModifyValue({ ...modifyValue, modifyStatus: 'image' });
          }
        }}
      >
        {firstMenu}
      </SelectButton>
      <SelectButton
        $isSelected={!isSelected}
        onClick={() => {
          handleSetMenuType(secondMenu);

          if (isMobile) {
            setModifyValue({ ...modifyValue, modifyStatus: '' });
          } else {
            setModifyValue({ ...modifyValue, modifyStatus: 'option' });
          }
        }}
      >
        {secondMenu}
      </SelectButton>
    </Container>
  );
};

export default MenuSettingButton;
