import React, { useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';

import Column from 'components/common/Column';
import Row from 'components/common/Row';
import { ICON_CLOSE, ICON_PLUS_BLACK, ICON_PLUS_GRAY } from 'assets/icons';
import Text from 'components/common/Text';
import Divider from 'components/Divider';
import { CurriculumSectionItem, CurriculumStyle } from 'types/api';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import { ONLY_NUMBER_REGEX } from 'utils/regExp';
import { cloneDeep } from 'lodash';
import BasicButton from 'components/Button/BasicButton';
import CommonImage from 'components/common/CommonImage';
import ConfirmDialog from 'components/Modal/ConfirmDialog';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
`;

const TitleInput = styled.input`
  display: flex;
  padding: 12px;
  width: 100%;

  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;

  color: #1b1c1e;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.6px;

  &::placeholder {
    color: #c2c4c8;
  }
`;

const TimeInputForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 2px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;

  input {
    display: flex;
    width: 70px;
    border: none;

    color: #1b1c1e;
    text-align: right;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.6px;

    &::placeholder {
      color: #c2c4c8;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

const Textarea = styled.textarea`
  padding: 12px;
  width: 100%;
  color: var(--gray-gray-09, #1b1c1e);

  border-radius: 8px;
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);

  /* Body/B-16-Regular */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.6px;
  resize: none;

  &::placeholder {
    color: #c2c4c8;
  }

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const OptionAddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 12px 0;
  width: 100%;

  border-radius: 8px;
  border: 1px dashed var(--gray-gray-04, #dbdcdf);
  background: var(--gray-gray-01, #f7f7f8);

  color: var(--gray-gray-07, #70737c);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
`;

interface CurriculumFormIProps {
  productParamInput: ProductParamInput;
  handleAddCurriculumItems: (idx: number) => void;
  handleAddCurriculumSections: () => void;
  handleDeleteCurriculumItems: (idx: number) => void;
  handleRemoveCurriculumItems: (idx: number) => void;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
  handleResetCurriculum: (style: CurriculumStyle) => void;
}

const CurriculumForm: React.FC<CurriculumFormIProps> = props => {
  const {
    productParamInput,
    handleAddCurriculumItems,
    handleAddCurriculumSections,
    handleDeleteCurriculumItems,
    handleRemoveCurriculumItems,
    handleOnChangeProductParamInput,
    handleResetCurriculum,
  } = props;
  const curriculumItem = productParamInput.frip!.curriculum;
  const type = curriculumItem.style;

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [deleteIdx, setDeleteIdx] = useState<number>(0);

  const handleChangeCurriculumItems = (
    key: string,
    idx: number,
    sectionIdx: number,
    value: any,
  ) => {
    const updatedItems = cloneDeep(curriculumItem.sections);

    updatedItems[idx].items[sectionIdx] = {
      ...updatedItems[idx].items[sectionIdx],
      [key]: value,
    };

    handleOnChangeProductParamInput('frip', {
      ...productParamInput.frip,
      curriculum: { ...curriculumItem, sections: updatedItems },
    });
  };

  const handleAddEtcForm = (idx: number) => {
    const updatedItems = cloneDeep(curriculumItem.sections);
    updatedItems[idx] = {
      ...updatedItems[idx],
      isOpenEtc: true,
    };

    handleOnChangeProductParamInput('frip', {
      ...productParamInput.frip,
      curriculum: { ...curriculumItem, sections: updatedItems },
    });
  };

  const handleRemoveEtcForm = (idx: number) => {
    const updatedItems = cloneDeep(curriculumItem.sections);
    updatedItems[idx] = {
      ...updatedItems[idx],
      etc: '',
      isOpenEtc: false,
    };

    handleOnChangeProductParamInput('frip', {
      ...productParamInput.frip,
      curriculum: { ...curriculumItem, sections: updatedItems },
    });
  };

  const handleSetCurriculumTitle = (idx: number, key: string, value: any) => {
    const updatedItems = cloneDeep(curriculumItem.sections);
    updatedItems[idx] = {
      ...updatedItems[idx],
      [key]: value,
    };

    handleOnChangeProductParamInput('frip', {
      ...productParamInput.frip,
      curriculum: { ...curriculumItem, sections: updatedItems },
    });
  };

  const curriculumTitle = (): string => {
    switch (type) {
      case CurriculumStyle.DURATION: {
        return '시간표';
      }
      case CurriculumStyle.TIMETABLE: {
        return '구간표';
      }
      case CurriculumStyle.LEGACY: {
        return '직접입력';
      }
      default:
        return '';
    }
  };

  const curriculumPlaceholder = (): string => {
    switch (type) {
      case CurriculumStyle.DURATION: {
        return '시간표 타이틀을 입력해 주세요';
      }
      case CurriculumStyle.TIMETABLE: {
        return '구간표 타이틀을 입력해 주세요';
      }
      case CurriculumStyle.LEGACY: {
        return '타이틀을 입력해 주세요';
      }
      default:
        return '';
    }
  };

  const renderCurriculumForm = (
    idx: number,
    sectionItems: CurriculumSectionItem,
    sectionIdx: number,
  ): JSX.Element => {
    switch (type) {
      case CurriculumStyle.DURATION: {
        return (
          <TimeInputForm>
            <Row gap="2px">
              <input
                type="number"
                placeholder="00"
                value={sectionItems.duration}
                onChange={e => {
                  handleChangeCurriculumItems(
                    'duration',
                    idx,
                    sectionIdx,
                    Number(e.target.value.replaceAll(ONLY_NUMBER_REGEX, '')),
                  );
                }}
              />
              <Text color="#1b1c1e" size="14px" weight={400}>
                분
              </Text>
            </Row>
          </TimeInputForm>
        );
      }
      case CurriculumStyle.TIMETABLE: {
        return (
          <TimeInputForm>
            <Row gap="2px">
              <input
                type="text"
                placeholder="00"
                // value={sectionItems.term.startingTime.hour}
                value={
                  sectionItems.term.startingTime.hour !== 0
                    ? sectionItems.term.startingTime.hour
                    : '00'
                }
                onChange={e => {
                  handleChangeCurriculumItems('term', idx, sectionIdx, {
                    ...sectionItems.term,
                    startingTime: {
                      ...sectionItems.term.startingTime,
                      hour:
                        Number(e.target.value) > 23
                          ? 23
                          : Number(
                              e.target.value.replaceAll(ONLY_NUMBER_REGEX, ''),
                            ),
                    },
                  });
                }}
                style={{
                  width: '30px',
                  color: '#1b1c1e',
                }}
              />
              <Text color={'#1b1c1e'}>시</Text>
              •
              <input
                type="text"
                placeholder="00"
                value={
                  sectionItems.term.startingTime.minute !== 0
                    ? sectionItems.term.startingTime.minute
                    : '00'
                }
                onChange={e => {
                  handleChangeCurriculumItems('term', idx, sectionIdx, {
                    ...sectionItems.term,
                    startingTime: {
                      ...sectionItems.term.startingTime,
                      minute:
                        Number(e.target.value) > 59
                          ? 59
                          : Number(
                              e.target.value.replaceAll(ONLY_NUMBER_REGEX, ''),
                            ),
                    },
                  });
                }}
                style={{
                  width: '30px',
                  color: '#1b1c1e',
                }}
              />
              <Text color={'#1b1c1e'}>분</Text>
              <Text
                color="#1b1c1e"
                size="14px"
                weight={400}
                style={{ minWidth: '24px', textAlign: 'right' }}
              >
                부터
              </Text>
            </Row>
            <Row gap="2px">
              <input
                type="text"
                placeholder="00"
                value={
                  sectionItems.term.endingTime.hour !== 0
                    ? sectionItems.term.endingTime.hour
                    : '00'
                }
                onChange={e => {
                  handleChangeCurriculumItems('term', idx, sectionIdx, {
                    ...sectionItems.term,
                    endingTime: {
                      ...sectionItems.term.endingTime,
                      hour:
                        Number(e.target.value) > 23
                          ? 23
                          : Number(
                              e.target.value.replaceAll(ONLY_NUMBER_REGEX, ''),
                            ),
                    },
                  });
                }}
                style={{
                  width: '30px',
                  color: '#1b1c1e',
                }}
              />
              <Text color={'#1b1c1e'}>시</Text>
              •
              <input
                type="text"
                placeholder="00"
                value={
                  sectionItems.term.endingTime.minute !== 0
                    ? sectionItems.term.endingTime.minute
                    : '00'
                }
                onChange={e => {
                  handleChangeCurriculumItems('term', idx, sectionIdx, {
                    ...sectionItems.term,
                    endingTime: {
                      ...sectionItems.term.endingTime,
                      minute:
                        Number(e.target.value) > 59
                          ? 59
                          : Number(
                              e.target.value.replaceAll(ONLY_NUMBER_REGEX, ''),
                            ),
                    },
                  });
                }}
                style={{
                  width: '30px',
                  color: '#1b1c1e',
                }}
              />
              <Text color={'#1b1c1e'}>분</Text>
              <Text
                color="#1b1c1e"
                size="14px"
                weight={400}
                style={{ minWidth: '24px' }}
              >
                까지
              </Text>
            </Row>
          </TimeInputForm>
        );
      }
      case CurriculumStyle.LEGACY: {
        return (
          <TimeInputForm>
            <Row gap="2px">
              <input
                placeholder="제목"
                style={{ textAlign: 'left' }}
                value={sectionItems.textTime}
                onChange={e => {
                  handleChangeCurriculumItems(
                    'textTime',
                    idx,
                    sectionIdx,
                    e.target.value,
                  );
                }}
              />
            </Row>
          </TimeInputForm>
        );
      }
      default:
        return <></>;
    }
  };

  return (
    <Container>
      {curriculumItem.sections.map((item, idx) => {
        return (
          <React.Fragment key={idx}>
            <Column gap="16px">
              <Row justifyContent="space-between" style={{ width: '100%' }}>
                <Text color="#1b1c1e" size="16px">
                  {curriculumTitle()}
                  {idx + 1}
                </Text>
                <Text
                  color="#FF4242"
                  size="13px"
                  weight={400}
                  onClick={() => {
                    setIsOpenDeleteModal(true);
                    setDeleteIdx(idx);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  삭제
                </Text>
              </Row>
              <TitleInput
                placeholder={curriculumPlaceholder()}
                value={item.title}
                onChange={e =>
                  handleSetCurriculumTitle(idx, 'title', e.target.value)
                }
              />
            </Column>
            <Divider />
            <Column gap="8px">
              {item.items.map((sectionItems, sectionIdx) => {
                return (
                  <Row
                    gap="8px"
                    style={{ position: 'relative', width: '100%' }}
                    key={sectionIdx}
                  >
                    {renderCurriculumForm(idx, sectionItems, sectionIdx)}
                    <Textarea
                      placeholder="내용을 입력해 주세요"
                      ref={textareaRef}
                      value={sectionItems.content}
                      onChange={e =>
                        handleChangeCurriculumItems(
                          'content',
                          idx,
                          sectionIdx,
                          e.target.value,
                        )
                      }
                      rows={1}
                      style={{
                        height:
                          type === CurriculumStyle.TIMETABLE ? '72px' : '46px',
                        paddingRight: sectionIdx !== 0 ? '24px' : '0',
                      }}
                    />
                    {sectionIdx !== 0 && (
                      <img
                        src={ICON_CLOSE}
                        alt="icon close"
                        onClick={() => handleRemoveCurriculumItems(sectionIdx)}
                        style={{ position: 'absolute', right: '8px' }}
                      />
                    )}
                  </Row>
                );
              })}
            </Column>
            <Column gap="12px">
              <OptionAddButton
                onClick={() => {
                  handleAddCurriculumItems(idx);
                }}
              >
                <img src={ICON_PLUS_GRAY} alt="icon plus gray" /> 내용추가
              </OptionAddButton>
              {!item.isOpenEtc && (
                <BasicButton
                  content={
                    <Row gap="4px">
                      <CommonImage src={ICON_PLUS_GRAY} onClick={() => {}} />
                      <Text color="#70737C">기타사항 추가</Text>
                    </Row>
                  }
                  style={{
                    padding: '12px 0',
                    width: '100%',
                    borderRadius: '8px',
                    border: '1px solid #DBDCDF',
                  }}
                  onClick={() => handleAddEtcForm(idx)}
                />
              )}
            </Column>
            {item.isOpenEtc && (
              <Column gap="12px">
                <Row justifyContent="space-between" style={{ width: '100%' }}>
                  <Text color="#1b1c1e" size="16px">
                    기타사항
                  </Text>
                  <Text
                    color="#FF4242"
                    weight={400}
                    onClick={() => {
                      handleSetCurriculumTitle(idx, 'title', '');
                      handleRemoveEtcForm(idx);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    삭제
                  </Text>
                </Row>
                <Textarea
                  placeholder="내용을 입력해주세요."
                  value={item.etc}
                  onChange={e => {
                    handleSetCurriculumTitle(idx, 'etc', e.target.value);
                  }}
                />
              </Column>
            )}
            {curriculumItem.sections.length !== idx + 1 && <Divider />}
          </React.Fragment>
        );
      })}
      <Divider style={{ height: '8px' }} />
      <BasicButton
        content={
          <Row gap="4px">
            <CommonImage src={ICON_PLUS_BLACK} onClick={() => {}} />
            <Text color="#1b1c1e">스케줄표 추가</Text>
          </Row>
        }
        style={{
          padding: '12px 0',
          width: '100%',
          borderRadius: '8px',
          border: '1px solid #1b1c1e',
        }}
        onClick={() => handleAddCurriculumSections()}
      />
      {isOpenDeleteModal && (
        <ConfirmDialog
          title="선택한 스케줄을 삭제할까요?"
          description="스케줄은 저장되지 않고 삭제됩니다."
          onCloseText="아니요"
          onAcceptText="네, 삭제할게요"
          onClose={() => {
            setIsOpenDeleteModal(false);
          }}
          onAccept={() => {
            handleDeleteCurriculumItems(deleteIdx);

            setIsOpenDeleteModal(false);
          }}
        />
      )}
    </Container>
  );
};

export default CurriculumForm;
