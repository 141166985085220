import Column from 'components/common/Column';
import Text from 'components/common/Text';
import InputForm from 'containers/Login/components/InputForm';
import React, { useState } from 'react';
import styled from 'styled-components';
import { PASSWORD_REGEX } from 'utils/regExp';
import { SignupParam } from '../hooks/types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
`;

interface SignupPasswordIProps {
  signupParam: SignupParam;
  handleChangeSignupParam: (key: string, value: string) => void;
}

const SignupPassword: React.FC<SignupPasswordIProps> = props => {
  const { signupParam, handleChangeSignupParam } = props;

  const [rePassword, setRepassword] = useState<string>('');
  const [isFocusedInput, setIsFocusedInput] = useState<boolean>(false);

  const handleCheckIsFocusedInput = (isFocused: boolean) => {
    setIsFocusedInput(isFocused);
  };

  const handleRepassword = (target: string, value: string) => {
    setRepassword(value);
  };

  return (
    <Column gap="8px" align="flex-start">
      <Container>
        <InputForm
          type="password"
          styleType="top"
          label="비밀번호 입력"
          target="password"
          regExp={PASSWORD_REGEX}
          autoComplete={true}
          handleCheckIsFocusedInput={handleCheckIsFocusedInput}
          onChange={handleChangeSignupParam}
        />
        <InputForm
          type="password"
          styleType="bottom"
          label="비밀번호 다시 입력"
          target="repassword"
          autoComplete={true}
          passwordValue={signupParam.password}
          handleCheckIsFocusedInput={handleCheckIsFocusedInput}
          onChange={handleRepassword}
        />
      </Container>
      {signupParam.password === '' && isFocusedInput && (
        <Text color="#70737c" size="12px" weight={400}>
          영문, 숫자, 특수문자를 조합하여 10자 이상으로 설정해 주세요.
        </Text>
      )}
      {rePassword !== '' && rePassword !== signupParam.password && (
        <Text color="#FF4242" size="12px" weight={400}>
          비밀번호가 일치하지 않습니다. 다시 확인해 주세요.
        </Text>
      )}

      {signupParam.password !== '' &&
        !PASSWORD_REGEX.test(signupParam.password) && (
          <Text color="#FF4242" size="12px" weight={400}>
            영문, 숫자, 특수문자를 조합하여 10자 이상으로 설정해 주세요.
          </Text>
        )}
    </Column>
  );
};

export default SignupPassword;
