import { INDIVIDUAL_NOTICE, TERM_NOTICE_PATH } from 'constants/path';
import React from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { styled } from 'styled-components';
import Column from './Column';
import Row from './Row';
import Text from './Text';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;

  background: var(--gray-gray-01, #f7f7f8);
`;

const Footer = () => {
  const { width, ref } = useResizeDetector();

  const isMobile = width ? width < 768 : window.innerWidth < 768;

  return (
    <Container
      ref={ref}
      style={{ padding: isMobile ? '32px 0 135px' : '32px 0 64px' }}
    >
      <Column
        gap="16px"
        align="flex-start"
        style={{ padding: isMobile ? '0 20px' : '0 40px', maxWidth: '768px' }}
      >
        <Text color="#1b1c1e" size="11px">
          (주) 프렌트립
        </Text>
        <Column align="flex-start">
          <Text
            color="#989ba2"
            size="10px"
            weight={400}
            style={{ letterSpacing: '-0.2px' }}
          >
            사업자 등록번호: 261-81-04385 | 통신판매업신고번호:
            2016-서울성동-01088 <br /> 대표: 임수열 | 개인정보관리책임자: 양사열{' '}
            <br />
            서울 성동구 왕십리로 115 헤이그라운드 서울숲점 G704
          </Text>
        </Column>
        <Row gap="8px">
          <a href={TERM_NOTICE_PATH} target="_blank">
            <Text color="#989ba2" size="10px" weight={400}>
              이용약관
            </Text>
          </a>
          <Text color="#989ba2" size="10px" weight={400}>
            |
          </Text>
          <a href={INDIVIDUAL_NOTICE} target="_blank">
            <Text color="#989ba2" size="10px">
              개인정보처리방침
            </Text>
          </a>
        </Row>
      </Column>
    </Container>
  );
};

export default Footer;
