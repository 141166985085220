import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs";

import { SettlementsNode } from "../graphQL/types";
import SettlementBadge from "./SettlementBadge";
import { ICON_ARROW_BLACK } from "assets/icons";
import Text from "components/common/Text";
import { SETTLEMENT_DETAIL_PATH_DEFAULT } from "constants/path";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 6px 14px;
  border-radius: 9999px;
  background: #f4f4f5;

  color: #1b1c1e;

  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.6px;
`;

interface SettlementItemIProps {
  settlementItem: SettlementsNode;
  type?: string;
  handleOpenSettlementInformationModal: () => void;
  handleIsOpenAllSettlementItemModal?: () => void;
}

const SettlementItem: React.FC<SettlementItemIProps> = (props) => {
  const {
    settlementItem,
    type,
    handleOpenSettlementInformationModal,
    handleIsOpenAllSettlementItemModal,
  } = props;

  const navigate = useNavigate();

  const isMinusSettlement =
    Number(settlementItem.totalPayment.value) +
      Number(settlementItem.totalFee.value) <
    0;

  return (
    <Container>
      <Column>
        <Row>
          <Text color="#1b1c1e" size="24px">
            <span
              style={{
                textDecorationLine: handleIsOpenAllSettlementItemModal
                  ? "underline"
                  : "none",
              }}
              onClick={() => {
                handleIsOpenAllSettlementItemModal &&
                  handleIsOpenAllSettlementItemModal();
              }}
            >
              {settlementItem.title.slice(6, 15)}
            </span>{" "}
            수입
          </Text>
          <SettlementBadge
            status={
              settlementItem.invoice !== null
                ? settlementItem.invoice.status
                : null
            }
          />
        </Row>
        <Text color="#70737c" size="14px" weight={400}>
          {dayjs(settlementItem.startedDate).format("M월 D일(dd)")}부터{" "}
          {dayjs(settlementItem.endedDate).format("M월 D일(dd)")}까지
        </Text>
      </Column>
      <Row style={{ marginTop: "8px" }}>
        <Text color="#1b1c1e" size="32px">
          <span
            style={{
              color: isMinusSettlement ? "#FF4242" : "#1b1c1e",
            }}
          >
            {(
              Number(settlementItem.totalPayment.value) +
              Number(settlementItem.totalFee.value)
            ).toLocaleString()}
            원
          </span>
        </Text>
      </Row>
      <Row>
        <Button onClick={handleOpenSettlementInformationModal}>
          정산 정보
        </Button>
        {type === undefined && (
          <Button
            onClick={() => {
              navigate(
                `${SETTLEMENT_DETAIL_PATH_DEFAULT}/${settlementItem.id}`
              );
            }}
            style={{ padding: "6px 8px 6px 14px" }}
          >
            판매 내역
            <img src={ICON_ARROW_BLACK} alt="icon arrow black" />
          </Button>
        )}
      </Row>
    </Container>
  );
};

export default SettlementItem;
