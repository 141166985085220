import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Row from 'components/common/Row';
import Column from 'components/common/Column';
import SelectionButton from './components/SelectionButton';
import Text from 'components/common/Text';
import QuestionModal from './components/QuestionModal';
import { useInquiriesHooks } from './hooks/useInquiriesHooks';
import { isEmpty } from 'lodash';
import { ICON_MENU_BUTTON, ICON_PLUS_GRAY } from 'assets/icons';
import Divider from 'components/Divider';
import {
  InquiryTemplate,
  ItemInquiryInputKind,
  ItemInquiryInputPattern,
} from 'types/api';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import { ConvertingInquiryTemplates } from 'utils/ConvertingInquiryTemplates';
import ConfirmDialog from 'components/Modal/ConfirmDialog';
import ModalContainer from '../common/ModalContainer';
import HackleManager from 'utils/hackle';
import OptionSelectBox from '../common/OptionSelectBox';

const RegistButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  width: 100%;

  border-radius: 8px;
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);

  color: var(--gray-gray-09, #1b1c1e);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.14px;
`;

const InquiryForm = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 12px;
  width: 100%;

  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;
  gap: 12px;
  color: #1b1c1e;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.6px;
`;

const AddInquiresButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 12px;
  width: 100%;

  border-radius: 8px;
  border: 1px dashed var(--gray-gray-04, #dbdcdf);
  background: var(--gray-gray-01, #f7f7f8);

  color: #70737c;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
`;

interface ChapterSixIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  inquiryList: InquiryTemplate;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
  handelGetInquiryTemplate: (id: string) => void;
  handleRefetchInquiryTemplate: (id: string) => void;
}

const ChapterSix: React.FC<ChapterSixIProps> = props => {
  const {
    isMobile,
    productParamInput,
    inquiryList,
    handleOnChangeProductParamInput,
    handelGetInquiryTemplate,
    handleRefetchInquiryTemplate,
  } = props;

  const {
    inquiryTemplate,
    inquiries,
    inputPattern,
    setInquiries,
    setInputPattern,
    setInquiryTemplate,
    handleSetInquiries,
    handleSetInquiryTemplate,
    handleResetInquiries,
    handleCreateInquiryTemplate,
    handleModifyInquiryTemplate,
    handleDeleteInquiryTemplate,
    handleUpdateInquiryTemplate,
    handleSetItemInputPattern,
  } = useInquiriesHooks();

  const params = useParams<{ id: string }>();

  const [isOpenQuestionModal, setIsOpenQuestionModal] =
    useState<boolean>(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false);
  const [isUpdateInquiryTemplates, setIsUpdateInquiryTemplates] =
    useState<boolean>(false);
  const [selectId, setSelectId] = useState<number>(0);
  const [modifyInquiriesIdx, setModifyInquiresIdx] = useState<number | null>(
    null,
  );

  const isAbled = inquiries.title !== '';

  useEffect(() => {
    HackleManager.logCreateStepThreeParticipantInfoView(
      params.id !== 'draft' ? Number(params.id) : undefined,
    );

    if (inquiryList) {
      const covertingInquiryTemplates = new ConvertingInquiryTemplates();

      const inquiryParamInput =
        covertingInquiryTemplates.convertInquiryTemplateParamInput(inquiryList);

      if (!isEmpty(inquiryParamInput.inquiries)) {
        setInputPattern(inquiryParamInput.inquiries[0].inputPattern);
      }
      setInquiryTemplate(inquiryParamInput);
    }
  }, []);

  const handleAcceptInquiryTemplates = () => {
    if (productParamInput.inquiryTemplateId === null) {
      handleCreateInquiryTemplate(inquiryTemplate, (id: string) => {
        handleRefetchInquiryTemplate(id);
        handleOnChangeProductParamInput('inquiryTemplateId', id);
      });

      return;
    }

    handleUpdateInquiryTemplate(
      productParamInput.inquiryTemplateId,
      inquiryTemplate,
      () => {
        handleRefetchInquiryTemplate(productParamInput.inquiryTemplateId);
        handleOnChangeProductParamInput(
          'inquiryTemplateId',
          productParamInput.inquiryTemplateId,
        );
      },
    );
  };

  // useEffect(() => {
  //   if (isUpdateInquiryTemplates) {
  //     if (productParamInput.inquiryTemplateId === null) {
  //       handleCreateInquiryTemplate(inquiryTemplate, (id: string) => {
  //         handleRefetchInquiryTemplate(id);
  //         handleOnChangeProductParamInput('inquiryTemplateId', id);
  //       });

  //       setIsUpdateInquiryTemplates(false);
  //       return;
  //     }

  //     handleUpdateInquiryTemplate(
  //       productParamInput.inquiryTemplateId,
  //       inquiryTemplate,
  //       () => {
  //         handleRefetchInquiryTemplate(productParamInput.inquiryTemplateId);
  //         handleOnChangeProductParamInput(
  //           'inquiryTemplateId',
  //           productParamInput.inquiryTemplateId,
  //         );
  //       },
  //     );

  //     setIsUpdateInquiryTemplates(false);
  //   }
  // }, [isUpdateInquiryTemplates]);

  const renderContents = (): JSX.Element => {
    return (
      <Column gap="32px" align="flex-start">
        <Column gap="16px" align="flex-start">
          <SelectionButton />
          <Column gap="8px" align="flex-start">
            <Text color="#1b1c1e" size="24px" style={{ lineHeight: '32px' }}>
              질문을 등록하시면 참여 신청한 크루로부터 답변을 받을 수 있어요.
            </Text>
            <Text color="#70737C" size="16px" weight={400}>
              이를 작성하면 크루들이 프립의 내용을 더 쉽게 이해하고 준비하는 데
              도움이 됩니다.
            </Text>
          </Column>
        </Column>
        <Column gap="16px" align="flex-start">
          <Row gap="8px" style={{ width: '100%' }}>
            <OptionSelectBox
              title="1개 이상 답변 받기"
              description="구매 옵션의 개수만큼 답변을 받을게요"
              isSelected={
                inputPattern === ItemInquiryInputPattern.PER_ITEM_COUNT
              }
              onClick={() => {
                handleSetItemInputPattern(
                  ItemInquiryInputPattern.PER_ITEM_COUNT,
                );
              }}
            />
            <OptionSelectBox
              title="1개만 답변 받기"
              description="구매 옵션의 개수와 상관 없이 1개의 답변을 받을게요"
              isSelected={inputPattern === ItemInquiryInputPattern.PER_PRODUCT}
              onClick={() => {
                handleSetItemInputPattern(ItemInquiryInputPattern.PER_PRODUCT);
              }}
            />
          </Row>
          {isEmpty(inquiryTemplate.inquiries) ? (
            <RegistButton onClick={() => setIsOpenQuestionModal(true)}>
              등록
            </RegistButton>
          ) : (
            <Column gap="16px" align="flex-start">
              {inquiryTemplate.inquiries.map((item, idx) => {
                return (
                  <InquiryForm key={idx}>
                    {/* <img src={ICON_MENU_BUTTON} alt="icon menu" /> */}
                    <Column gap="12px" align="flex-start">
                      <Column gap="4px" align="flex-start">
                        <Text color="#989BA2">질문{idx + 1}</Text>
                        <Text color="#1B1C1E" size="16px" weight={400}>
                          {item.title}
                        </Text>
                      </Column>
                      <Divider />
                      {item.inputKind === ItemInquiryInputKind.TEXT ? (
                        <Column gap="4px" align="flex-start">
                          <Text color="#989ba2">답변 유형</Text>
                          <Text color="#1b1c1e" size="16px" weight={400}>
                            주관식
                          </Text>
                        </Column>
                      ) : (
                        <React.Fragment>
                          {!isEmpty(item.items) && (
                            <Column gap="2px" align="flex-start">
                              {item.items.map((selectItem, selectIdx) => {
                                return (
                                  <Column
                                    gap="4px"
                                    align="flex-start"
                                    key={selectIdx}
                                  >
                                    <Text color="#989ba2">
                                      객관식 답변{selectIdx + 1}
                                    </Text>
                                    <Text
                                      color="#1b1c1e"
                                      size="16px"
                                      weight={400}
                                    >
                                      {selectItem.label}
                                    </Text>
                                  </Column>
                                );
                              })}
                            </Column>
                          )}
                        </React.Fragment>
                      )}
                      <Divider />
                      <Column gap="4px" align="flex-start">
                        <Text color="#989BA2">답변 필수 여부</Text>
                        <Text color="#1B1C1E" size="16px" weight={400}>
                          {item.required
                            ? '필수로 받을게요'
                            : '필수가 아니에요'}
                        </Text>
                      </Column>
                      <Row
                        gap="8px"
                        justifyContent="flex-end"
                        style={{ width: '100%' }}
                      >
                        <Text
                          color="#FF4242"
                          onClick={() => {
                            setIsOpenDeleteDialog(true);
                            setSelectId(idx);
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          삭제
                        </Text>
                        <Text color="#C2C4C8">|</Text>
                        <Text
                          color="#70737C"
                          onClick={() => {
                            setInquiries(item);
                            setIsOpenQuestionModal(true);
                            setModifyInquiresIdx(idx);
                          }}
                        >
                          수정하기
                        </Text>
                      </Row>
                    </Column>
                  </InquiryForm>
                );
              })}
              <AddInquiresButton onClick={() => setIsOpenQuestionModal(true)}>
                <img src={ICON_PLUS_GRAY} alt="icon plus gray" /> 질문 추가
              </AddInquiresButton>
            </Column>
          )}
          <Row gap="8px">
            <Text color="#1b1c1e" size="16px">
              질문
            </Text>
            <Text color="#989BA2" size="14px" weight={400}>
              {`예시) 이전에 활동 경력이 있으신가요?`}
            </Text>
          </Row>
        </Column>
      </Column>
    );
  };

  return (
    <React.Fragment>
      {renderContents()}
      {isOpenQuestionModal && (
        <ModalContainer
          isAbled={isAbled}
          isMobile={isMobile}
          onCloseText="닫기"
          onAcceptText="확인"
          onClose={() => {
            setIsOpenQuestionModal(false);
            handleResetInquiries();
          }}
          onClick={() => {
            if (modifyInquiriesIdx !== null) {
              handleModifyInquiryTemplate(inquiries.id);

              setModifyInquiresIdx(null);
            } else {
              handleSetInquiryTemplate('inquiries', [
                ...inquiryTemplate.inquiries,
                inquiries,
              ]);
            }

            handleAcceptInquiryTemplates();
            setIsOpenQuestionModal(false);
            handleResetInquiries();
            // setIsUpdateInquiryTemplates(true);
          }}
        >
          <QuestionModal
            isMobile={isMobile}
            inquiries={inquiries}
            inquiryTemplate={inquiryTemplate}
            setInquiries={setInquiries}
            handleSetInquiries={handleSetInquiries}
            handleSetInquiryTemplate={handleSetInquiryTemplate}
            onClose={() => {
              setIsOpenQuestionModal(false);
              handleResetInquiries();
            }}
          />
        </ModalContainer>
      )}
      {isOpenDeleteDialog && (
        <ConfirmDialog
          title="선택한 질문을 삭제할까요?"
          description="질문은 저장되지 않고 삭제됩니다."
          onCloseText="아니요"
          onAcceptText="네, 삭제할게요"
          onClose={() => setIsOpenDeleteDialog(false)}
          onAccept={() => {
            handleDeleteInquiryTemplate(selectId);

            setIsOpenDeleteDialog(false);
            setIsUpdateInquiryTemplates(true);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default ChapterSix;
