import { FripLocationParam } from 'containers/ProductRegist/hooks/types';
import React, { useCallback, useEffect, useRef } from 'react';

interface GoogleMapIProps {
  item: FripLocationParam;
}

const GoogleMap: React.FC<GoogleMapIProps> = props => {
  const { item } = props;

  const mapRef = useRef(null);

  const initMap = useCallback(
    (lat: number, lng: number) => {
      if (mapRef.current) {
        return new window.google.maps.Map(mapRef.current, {
          center: { lat, lng },
          zoom: 10,
          disableDefaultUI: true, // 기본 UI 요소 비활성화
          zoomControl: false, // 줌 컨트롤 비활성화
          gestureHandling: 'none', // 모든 제스처 비활성화
        });
      }
    },
    [mapRef],
  );

  useEffect(() => {
    if (item) {
      initMap(item.geoPoint.latitude, item.geoPoint.longitude);
    }
  }, [item]);

  return (
    <div
      className="map"
      draggable={false}
      style={{
        width: '100%',
        height: '185px',
        borderRadius: '8px',
      }}
      ref={mapRef}
    />
  );
};

export default GoogleMap;
