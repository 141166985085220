import React from 'react';
import styled from 'styled-components';
import { flatten } from 'lodash';

import BasicButton from 'components/Button/BasicButton';
import Row from 'components/common/Row';
import { useMutationTicketsSale } from 'containers/SaleDetail/graphQL/hooks/useMutationTicketsSale';
import { Ticket, TicketState } from 'types/api';
import HackleManager from 'utils/hackle';
import { TicketFilterInput } from '../hooks/useBookingListHooks';

const Container = styled.div<{ $isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid ${props => (props.$isMobile ? '#dbdcdf' : '#eaebec')};
  box-shadow: ${props =>
    props.$isMobile ? ' 0px -4px 20px 0px rgba(0, 0, 0, 0.1)' : '0'};

  background: #fff;

  z-index: 21;
`;

interface BookingBottomSheetIProps {
  isMobile: boolean;
  selectTickets: { date: string; tickets: Ticket[] };
  ticketFilterInput: TicketFilterInput;
  handleRefetchBookingList: () => void;
  handleResetSelectTickets: () => void;
}

const BookingBottomSheet: React.FC<BookingBottomSheetIProps> = props => {
  const {
    isMobile,
    selectTickets,
    ticketFilterInput,
    handleRefetchBookingList,
    handleResetSelectTickets,
  } = props;

  const {
    handleRestoreTickets,
    handleUseTickets,
    handleRestoreUseTickets,
    handleAbsentTickets,
    handleRestoreTicketsAbsence,
  } = useMutationTicketsSale();

  const ticketIds = flatten(selectTickets.tickets.map(item => item.id));

  const renderStatusButton = (): JSX.Element => {
    const ticketStatus = ticketFilterInput.filter.statusIn;
    const ticketItems = selectTickets.tickets[0];

    const isShowActionButton = selectTickets.tickets.every(ticketItem => {
      const startedAt = new Date(ticketItem.scheduleTerm.startedAt); // 예약 시작 시간
      const currentTime = new Date(); // 현재 시간

      const actionTime = new Date(startedAt.getTime() - 60 * 60 * 1000);

      return currentTime > actionTime;
    });

    if (ticketStatus.includes(TicketState.CONFIRMED)) {
      return (
        <Row gap="8px">
          <BasicButton
            content="예약 확정 취소"
            onClick={() => {
              handleRestoreTickets(ticketIds, () => {
                handleRefetchBookingList();
                handleResetSelectTickets();

                HackleManager.logSalesBookingReverseComplete(
                  Number(ticketItems.bookingItem.booking.order.id),
                  ticketItems.bookingItem.booking.customer.id,
                  ticketIds,
                  '예약상세',
                  '예약상세',
                );
              });
            }}
            style={{ borderRadius: '8px' }}
          />
          <BasicButton
            content="불참처리"
            onClick={() => {
              if (isShowActionButton) {
                handleAbsentTickets(ticketIds, () => {
                  HackleManager.logBookingAbsenceComplete(
                    Number(ticketItems.bookingItem.booking.order.id),
                    ticketItems.bookingItem.booking.customer.id,
                    ticketItems.bookingItem.booking.info.product.title,
                    ticketItems.bookingItem.booking.info.name,
                    ticketIds,
                    '예약상세',
                  );

                  handleRefetchBookingList();
                  handleResetSelectTickets();
                });
              }
            }}
            style={{
              borderRadius: '8px',
              color: isShowActionButton ? '#1b1c1e' : '#dbdcdf',
            }}
          />
          <BasicButton
            content="사용처리"
            onClick={() => {
              if (isShowActionButton) {
                handleUseTickets(ticketIds, () => {
                  HackleManager.logBookingAttendanceComplete(
                    Number(ticketItems.bookingItem.booking.order.id),
                    ticketItems.bookingItem.booking.customer.id,
                    ticketItems.bookingItem.booking.info.product.title,
                    ticketItems.bookingItem.booking.info.name,
                    ticketIds,
                    '예약상세',
                  );

                  handleRefetchBookingList();
                  handleResetSelectTickets();
                });
              }
            }}
            style={{
              borderRadius: '8px',
              color: isShowActionButton ? '#7126FF' : '#dbdcdf',
            }}
          />
        </Row>
      );
    }

    if (ticketStatus.includes(TicketState.USED)) {
      return (
        <BasicButton
          content="사용 처리 취소"
          onClick={() => {
            handleRestoreUseTickets(ticketIds, () => {
              HackleManager.logBookingAttendanceReverseComplete(
                Number(ticketItems.bookingItem.booking.order.id),
                ticketItems.bookingItem.booking.customer.id,
                ticketItems.bookingItem.booking.info.product.title,
                ticketItems.bookingItem.booking.info.name,
                ticketIds,
                '예약상세',
              );

              handleRefetchBookingList();
              handleResetSelectTickets();
            });
          }}
          style={{ borderRadius: '8px', color: '#FF4242' }}
        />
      );
    }

    if (ticketStatus.includes(TicketState.ABSENT)) {
      return (
        <BasicButton
          content="불참 처리 취소"
          onClick={() => {
            handleRestoreTicketsAbsence(ticketIds, () => {
              HackleManager.logBookingAbsenceReverseComplete(
                Number(ticketItems.bookingItem.booking.order.id),
                ticketItems.bookingItem.booking.customer.id,
                ticketItems.bookingItem.booking.info.product.title,
                ticketItems.bookingItem.booking.info.name,
                ticketIds,
                '예약상세',
              );

              handleRefetchBookingList();
              handleResetSelectTickets();
            });
          }}
          style={{ borderRadius: '8px', color: '#FF4242' }}
        />
      );
    }

    return;
  };

  return (
    <Container $isMobile={isMobile}>
      <Row
        justifyContent="flex-end"
        style={{
          width: '100%',
          maxWidth: '1280px',
          padding: isMobile ? '16px 20px 24px' : '16px 40px 24px',
        }}
      >
        {renderStatusButton()}
      </Row>
      {/* <ConfirmDialog title="das" description="da" onAcceptText={'ad'} /> */}
    </Container>
  );
};

export default BookingBottomSheet;
