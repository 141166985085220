export enum NoticeType {
  SERVICE_COMMON = 'SERVICE_COMMON',
  SERVICE_EVENT = 'SERVICE_EVENT',
  SERVICE_NOTICE = 'SERVICE_NOTICE',
  HOST_NOTICE = 'HOST_NOTICE',
  HOST_COMMON = 'HOST_COMMON',
  HOST_EVENT = 'HOST_EVENT',
}

export interface NoticeFilter {
  filter: {
    forHostOnly: boolean;
    titleLike: string;
    type?: NoticeType;
  };
  page: number;
  size: number;
}

export interface NoticeBoard {
  board: Board;
}

export interface Board {
  notices: Notices;
}

export interface Notices {
  totalCount: number;
  edges: NoticeEdgesEntity[];
  pageInfo: PageInfo;
}

export interface NoticeEdgesEntity {
  node: NoticeNode;
}

export interface NoticeNode {
  id: string;
  title: string;
  content: string;
  createdAt: number;
  type: NoticeType;
}

export interface PageInfo {
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  endCursor: string;
}
