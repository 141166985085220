import React from 'react';
import styled from 'styled-components';
import { ICON_NOTICE_RED, ICON_TOAST_FAILED } from 'assets/icons';
import Column from 'components/common/Column';
import Text from 'components/common/Text';
import ModalContainer from '../common/ModalContainer';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import { useNavigate } from 'react-router-dom';
import { PRODUCT_REGIST_PATH } from 'constants/path';

const ErrorContainer = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  padding: 0 20px;
  list-style: disc;
  list-style-position: outside;

  color: #0071e9;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.6px;

  li {
    color: #0071e9;
    margin-left: 15px;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.6px;
  }
`;

interface InspectionFailedModalIProps {
  productParamInput: ProductParamInput;
  errorMessage: string[];
}

const InspectionFaildeModal: React.FC<InspectionFailedModalIProps> = props => {
  const { productParamInput, errorMessage } = props;

  const navigate = useNavigate();

  return (
    <ModalContainer
      isMobile={false}
      isAbled={true}
      onCloseText=" "
      onAcceptText="네, 확인했어요"
      onClose={() => {}}
      onClick={() => {
        navigate(`${PRODUCT_REGIST_PATH}/${productParamInput.id}`, {
          replace: true,
        });
        window.location.reload();
      }}
      style={{ gap: 0 }}
    >
      <Column gap="16px" style={{ padding: '20px 24px' }}>
        <img
          src={ICON_NOTICE_RED}
          alt="regist complete"
          style={{ width: '64px' }}
        />
        <Column style={{ width: 'auto' }}>
          <Text color="#1b1c1e" size="20px" weight={600}>
            검수 요청을 완료하지 못했어요.
          </Text>
          <ErrorContainer>
            {errorMessage.map((item, idx) => {
              return <li key={idx}>{item}</li>;
            })}
          </ErrorContainer>
          <Text color="#1b1c1e" size="16px" weight={400}>
            확인 후, 다시 검수 요청해 주시기 바랍니다.
          </Text>
        </Column>
      </Column>
    </ModalContainer>
  );
};

export default InspectionFaildeModal;
