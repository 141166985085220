import React, { useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import Divider from 'components/Divider';
import { ProductState } from 'types/api';
import { EdgesEntity } from '../graphQL/types';
import NavigateForm from './NavigateForm';

import {
  ICON_CLOSE,
  ICON_NOTICE_RED,
  ICON_PHONE,
  ICON_PLUS_BLACK,
  ICON_PRODUCTLIST_COPY,
  ICON_SETTLEMENT_IMAGE_UPLOAD,
  ICON_SHARE,
  ICON_PRODUCT_LIST,
  ICON_PRODUCT_STAR,
  ICON_PRODUCT_EDIT,
  ICON_CALENDAR,
  ICON_PRODUCT_DELETE,
} from 'assets/icons';
import { useNavigate } from 'react-router-dom';
import {
  BOOKING_LIST_PATH,
  KAKAO_CUSTOMER_SERVICE,
  PRODUCT_DETAIL_PATH,
  PRODUCT_REGIST_PATH,
  QNA_LIST_PATH,
  REVIEW_LIST_PATH,
  SALE_LIST_PATH,
} from 'constants/path';
import { useMutationDeleteProduct } from '../graphQL/hooks/useMutationDeleteProduct';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import CommonDialog from 'components/Modal/CommonDialog';
import ConfirmDialog from 'components/Modal/ConfirmDialog';
import {
  callWebviewHandler,
  isWebview,
  WebviewOperatorType,
} from 'utils/webview';
import HackleManager from 'utils/hackle';
import { formatProductStatus } from 'utils/hackle/parameterFormatting';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  padding: 64px 0 0 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  padding-bottom: 64px;
  width: 100%;
  /* max-width: 375px; */
  overflow-y: scroll !important;

  background-color: #fff;
  border-radius: 16px 16px 0 0;
`;

const RejectContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 20px;
  width: 100%;

  border-radius: 12px;
  border: 1px solid var(--gray-gray-03, #eaebec);
  background: var(--gray-gray-01, #f7f7f8);
`;

const EmptyThumbnailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 76px;
  height: 76px;
  border-radius: 16px;
  background: var(--gray-gray-02, #f4f4f5);
`;

const ProductThumbnail = styled.img`
  width: 76px;
  height: 76px;
  border-radius: 9.5px;
  border: 1.81px solid rgba(0, 0, 0, 0.1);
`;

const IdBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  border-radius: 4px;
  background: var(--Grayscale-Nomal-Gray-100, #eee);

  color: var(--Grayscale-Nomal-Gray-800, #333);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px; /* 130% */
  letter-spacing: -0.1px;
`;

interface ProductItemModalIProps {
  isMobile: boolean;
  selectProduct: EdgesEntity;
  onClose: () => void;
}

const ProductItemModal: React.FC<ProductItemModalIProps> = props => {
  const { isMobile, selectProduct, onClose } = props;

  const { handleDeleteProduct } = useMutationDeleteProduct();

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleOnClickHackleEvent = (menuName: string) => {
    HackleManager.logProductMenuItemClick(
      Number(selectProduct.node.id),
      selectProduct.node.title,
      selectProduct.node.createdAt,
      selectProduct.node.saleTerm.endedAt,
      formatProductStatus([selectProduct.node.status]),
      menuName,
    );
  };

  const renderProductItem = (): JSX.Element => {
    switch (selectProduct.node.status) {
      case ProductState.REJECTED: {
        return (
          <Column gap="24px" align="flex-start">
            <RejectContainer>
              <img src={ICON_NOTICE_RED} alt="icon notice red" />
              <Column gap="16px" align="flex-start">
                <Row
                  gap="4px"
                  justifyContent="flex-start"
                  style={{ flexWrap: 'wrap' }}
                >
                  <Text color="#1B1C1E" size="14px" weight={600}>
                    반려 사유:
                  </Text>
                  {selectProduct.node.lastInspection.items.map((item, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        {item.reasons.map((reasonItem, reasonIdx) => {
                          return (
                            <Column key={reasonIdx} style={{ width: 'auto' }}>
                              <Text color="#1B1C1E" size="14px" weight={600}>
                                {reasonItem.name}
                                {item.reasons.length !== reasonIdx + 1 && ','}
                              </Text>
                            </Column>
                          );
                        })}
                        <Text color="#46474c" size="13px" weight={400}>
                          {item.comment}
                        </Text>
                      </React.Fragment>
                    );
                  })}
                </Row>
              </Column>
            </RejectContainer>
            <Divider />
            <Row gap="16px">
              <ProductThumbnail
                src={
                  isEmpty(selectProduct.node.headerContents)
                    ? ICON_SETTLEMENT_IMAGE_UPLOAD
                    : selectProduct.node.headerContents[0].content.thumbnail
                }
                alt="product Thumbnail"
              />
              <Text color="#1b1c1e" size="16px">
                프립 등록중: {selectProduct.node.title}
              </Text>
            </Row>
            <Divider />
            <Row
              gap="16px"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleOnClickHackleEvent('이어서 등록 완료하기');
                navigate(`${PRODUCT_REGIST_PATH}/${selectProduct.node.id}`);
              }}
            >
              <img
                src={ICON_PLUS_BLACK}
                alt="icon plus black"
                style={{ width: '24px', height: '24px' }}
              />
              <Text color="#1b1c1e" size="16px" weight={400}>
                이어서 등록 완료하기
              </Text>
            </Row>
            <Row
              gap="16px"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleOnClickHackleEvent('삭제하기');

                setIsOpenDialog(true);
              }}
            >
              <img src={ICON_PRODUCT_DELETE} alt="icon plus black" />
              <Text color="#1b1c1e" size="16px" weight={400}>
                삭제하기
              </Text>
            </Row>
          </Column>
        );
      }
      case ProductState.EDITING: {
        return (
          <Column gap="24px" align="flex-start">
            <Row gap="16px">
              {isEmpty(selectProduct.node.headerContents) ? (
                <EmptyThumbnailContainer>
                  <img
                    src={ICON_SETTLEMENT_IMAGE_UPLOAD}
                    alt="product Thumbnail"
                    style={{ width: '18px', height: '18px' }}
                  />
                </EmptyThumbnailContainer>
              ) : (
                <ProductThumbnail
                  src={selectProduct.node.headerContents[0].content.thumbnail}
                  alt="product Thumbnail"
                />
              )}
              <Text color="#1b1c1e" size="16px">
                {selectProduct.node.title}
                {/* : `상품 등록중: ${dayjs(selectProduct.node.updatedAt).format(
                      'YYYY년 M월 D일 hh:mm',
                    )}`} */}
              </Text>
            </Row>
            <Divider />
            <Row
              gap="16px"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (selectProduct.node.status === ProductState.EDITING) {
                  handleOnClickHackleEvent('이어서 등록 완료하기');

                  navigate(`${PRODUCT_REGIST_PATH}/${selectProduct.node.id}`);

                  return;
                }
              }}
            >
              <img
                src={ICON_PLUS_BLACK}
                alt="icon plus black"
                style={{ width: '24px', height: '24px' }}
              />
              <Text color="#1b1c1e" size="16px" weight={400}>
                이어서 등록 완료하기
              </Text>
            </Row>
            <Row
              gap="16px"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleOnClickHackleEvent('삭제하기');

                setIsOpenDialog(true);
              }}
            >
              <img src={ICON_PRODUCT_DELETE} alt="icon plus black" />
              <Text color="#1b1c1e" size="16px" weight={400}>
                삭제하기
              </Text>
            </Row>
          </Column>
        );
      }
      case ProductState.CLOSED: {
        return (
          <Column gap="24px">
            {renderProductDefault()}
            <Divider />
            <NavigateForm
              icon={ICON_PHONE}
              title={
                <a href={KAKAO_CUSTOMER_SERVICE} target="_blank">
                  고객센터 문의하기
                </a>
              }
              onClick={() => {
                handleOnClickHackleEvent('고객센터 문의하기');
              }}
            />
          </Column>
        );
      }
      case ProductState.INSPECTING: {
        return (
          <Column gap="24px" align="flex-start">
            {renderProductDefault()}
            <Divider />
            <NavigateForm
              icon={ICON_PRODUCT_EDIT}
              title="수정하기"
              onClick={() => {
                handleOnClickHackleEvent('수정하기');

                navigate(`${PRODUCT_DETAIL_PATH}/${selectProduct.node.id}`, {
                  state: selectProduct.node.id,
                });
              }}
            />
            {isWebview() && (
              <NavigateForm
                icon={ICON_SHARE}
                title="앱에서 상품 확인하기"
                onClick={() => {
                  handleOnClickHackleEvent('앱에서 상품 확인하기');

                  callWebviewHandler(WebviewOperatorType.OpenActionUrl, {
                    actionUrl: `frip://products/${selectProduct.node.id}`,
                  });
                }}
              />
            )}
          </Column>
        );
      }
      default: {
        return (
          <Column gap="24px" align="flex-start">
            {renderProductDefault()}
            <Divider />
            <NavigateForm
              icon={ICON_PRODUCT_EDIT}
              title="수정하기"
              onClick={() => {
                handleOnClickHackleEvent('수정하기');

                navigate(`${PRODUCT_DETAIL_PATH}/${selectProduct.node.id}`, {
                  state: selectProduct.node.id,
                });
              }}
            />
            <NavigateForm
              icon={ICON_PRODUCT_LIST}
              title="판매 관리"
              onClick={() => {
                handleOnClickHackleEvent('판매관리');

                navigate(SALE_LIST_PATH, {
                  state: { productId: selectProduct.node.id },
                });
              }}
            />
            <NavigateForm
              icon={ICON_CALENDAR}
              title="예약 관리"
              onClick={() => {
                handleOnClickHackleEvent('예약관리');

                navigate(BOOKING_LIST_PATH, {
                  state: { productId: selectProduct.node.id },
                });
              }}
            />
            <NavigateForm
              icon={ICON_PHONE}
              title="문의 관리"
              onClick={() => {
                handleOnClickHackleEvent('문의관리');

                navigate(`${QNA_LIST_PATH}?productId=${selectProduct.node.id}`);
              }}
            />
            <NavigateForm
              icon={ICON_PRODUCT_STAR}
              title="후기 관리"
              onClick={() => {
                handleOnClickHackleEvent('후기관리');

                navigate(
                  `${REVIEW_LIST_PATH}?productId=${selectProduct.node.id}`,
                );
              }}
            />
            <NavigateForm
              icon={ICON_PRODUCTLIST_COPY}
              title="해당 프립 복사하기"
              onClick={() => {
                handleOnClickHackleEvent('해당 프립 복사하기');

                navigate(`${PRODUCT_REGIST_PATH}/${selectProduct.node.id}`, {
                  state: { isCopy: true },
                });
              }}
            />
            {isWebview() && (
              <NavigateForm
                icon={ICON_SHARE}
                title="앱에서 상품 확인하기"
                onClick={() => {
                  handleOnClickHackleEvent('앱에서 상품 확인하기');

                  callWebviewHandler(WebviewOperatorType.OpenActionUrl, {
                    actionUrl: `frip://products/${selectProduct.node.id}`,
                  });
                }}
              />
            )}
          </Column>
        );
      }
    }
  };

  const renderProductDefault = (): JSX.Element => {
    return (
      <Column gap="16px" align="flex-start">
        <Row gap="16px">
          <ProductThumbnail
            src={
              isEmpty(selectProduct.node.headerContents)
                ? ICON_SETTLEMENT_IMAGE_UPLOAD
                : selectProduct.node.headerContents[0].content.thumbnail
            }
            alt="product Thumbnail"
          />
          <Column gap="8px" align="flex-start">
            <IdBox>ID {selectProduct.node.id}</IdBox>
            <Text color="#1b1c1e" size="16px" weight={600}>
              {selectProduct.node.title}
            </Text>
          </Column>
        </Row>
        <Row gap="4px">
          <Text color="#46474c" size="14px">
            상품 등록일:
          </Text>
          <Text color="#70737C" size="14px">
            {dayjs(selectProduct.node.createdAt).format('YY년 MM월 DD일')}
          </Text>
          <Text color="#C2C4C8" size="14px">
            |
          </Text>
          <Text color="#46474c" size="14px">
            판매 종료일:
          </Text>
          <Text color="#70737C" size="14px">
            {dayjs(selectProduct.node.saleTerm?.endedAt).format(
              'YY년 MM월 DD일',
            )}
          </Text>
        </Row>
      </Column>
    );
  };

  return (
    <ModalContainer
      isAbled={true}
      isMobile={isMobile}
      isBottomSheet={true}
      onClick={() => {}}
      onClose={onClose}
      style={{ paddingBottom: '64px' }}
    >
      <Column align="flex-start" style={{ padding: '0 20px' }}>
        <Row
          style={{
            position: 'sticky',
            top: 0,
            padding: '16px 0',
            width: '100%',
            background: '#fff',
          }}
          justifyContent={
            selectProduct.node.status !== ProductState.REJECTED && 'flex-start'
          }
        >
          <img
            src={ICON_CLOSE}
            alt="close icon"
            style={{
              position:
                selectProduct.node.status === ProductState.REJECTED
                  ? 'absolute'
                  : 'static',
              left: '0',
              top: '16px',
              cursor: 'pointer',
            }}
            onClick={onClose}
          />
          {selectProduct.node.status === ProductState.REJECTED && (
            <Text color="#000" size="16px" weight={600}>
              더보기
            </Text>
          )}
        </Row>
        {renderProductItem()}
      </Column>
      {isOpenDialog && (
        <ConfirmDialog
          title="등록중인 프립을 삭제할까요"
          description="상품 내용은 저장되지 않고 삭제됩니다."
          onAcceptText="네, 삭제할게요"
          onCloseText="아니요"
          onClose={() => {
            setIsOpenDialog(false);
          }}
          onAccept={() => {
            handleDeleteProduct(selectProduct.node.id, () => {
              HackleManager.logProductDeleteComplete(
                Number(selectProduct.node.id),
                selectProduct.node.title,
              );
            });
          }}
        />
      )}
    </ModalContainer>
  );
};

export default ProductItemModal;
