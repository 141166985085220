import React, { SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

import Row from 'components/common/Row';
import Text from 'components/common/Text';
import OptionContainer from './OptionContainer';
import { ScheduleParam } from 'containers/ProductRegist/hooks/types';
import BasicButton from 'components/Button/BasicButton';
import TimeInput from 'components/Input/TimeInput';
import { ONLY_NUMBER_REGEX } from 'utils/regExp';

const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  width: 24px;
  height: 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff; /* 기본 배경색 */
  border: 1px solid #ddd; /* 테두리 */
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: 5px;

  &:checked {
    background-color: #000;
  }

  &:checked::before {
    content: '✓';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
`;

const OptionInput = styled.input`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 27px 8px 12px;
  width: 100%;

  text-align: right;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    justify-content: flex-end;
  }

  &:focus {
    border: 1.5px solid #1b1c1e;
  }
`;

const DateBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2px;
  padding: 8px 12px;
  position: relative;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;
  cursor: pointer;
`;

interface OptionTermDateIProps {
  scheduleItem: ScheduleParam;
  isOverDay: boolean;
  termDate: { startDate: string; endDate: string; durationDate: string };
  isDisable: boolean;
  setTermDate: React.Dispatch<
    SetStateAction<{ startDate: string; endDate: string; durationDate: string }>
  >;
  setIsOverDay: React.Dispatch<SetStateAction<boolean>>;
}

const OptionTermDate: React.FC<OptionTermDateIProps> = props => {
  dayjs.locale('ko');
  const {
    scheduleItem,
    isOverDay,
    termDate,
    isDisable,
    setTermDate,
    setIsOverDay,
  } = props;

  const twentyFourHoursMs = 24 * 60 * 60 * 1000;

  useEffect(() => {
    if (scheduleItem.term.startedAt) {
      setTermDate({
        ...termDate,
        startDate: dayjs(scheduleItem.term.startedAt).format('HH:mm'),
        endDate: dayjs(
          scheduleItem.term.startedAt + scheduleItem.term.duration,
        ).format('HH:mm'),
        durationDate:
          scheduleItem.term.duration &&
          Math.round(scheduleItem.term.duration / twentyFourHoursMs).toString(),
      });
    }
  }, [scheduleItem.term]);

  const renderContents = (): JSX.Element => {
    if (isDisable) {
      return (
        <OptionContainer>
          <Row justifyContent="space-between" style={{ width: '100%' }}>
            <Text color="#1b1c1e" size="16px">
              진행시간
            </Text>
            <Row gap="4px">
              <CheckBox
                type="checkbox"
                checked={false}
                disabled
                onChange={() => {}}
                style={{ width: '16px', height: '16px' }}
              />
              <Text size="14px">24시간 이상 진행 시</Text>
            </Row>
          </Row>
          <Row
            gap="8px"
            justifyContent="space-between"
            style={{
              flexDirection: isOverDay ? 'column' : 'row',
              justifyContent: isOverDay ? 'flex-end' : 'center',
              alignItems: isOverDay ? 'flex-end' : 'center',
              width: '100%',
            }}
          >
            <BasicButton
              content={
                <Text weight={400}>
                  {dayjs(scheduleItem.term.startedAt).format('A hh시 • mm분')}{' '}
                  부터
                </Text>
              }
              style={{
                padding: '8px 12px',
                width: '100%',
                borderRadius: '8px',
              }}
            />
            <BasicButton
              content={
                <Text weight={400}>
                  {dayjs(
                    scheduleItem.term.startedAt + scheduleItem.term.duration,
                  ).format('A hh시 • mm분')}{' '}
                  까지
                </Text>
              }
              style={{
                padding: '8px 12px',
                width: '100%',
                borderRadius: '8px',
              }}
            />
          </Row>
        </OptionContainer>
      );
    }

    return (
      <OptionContainer>
        <Row justifyContent="space-between" style={{ width: '100%' }}>
          <Text color="#1b1c1e" size="16px">
            진행시간
          </Text>
          <Row gap="4px">
            <CheckBox
              type="checkbox"
              checked={isOverDay}
              onChange={() => {
                if (isOverDay) {
                  setTermDate({ ...termDate, durationDate: '0' });
                  setIsOverDay(false);

                  return;
                }

                setIsOverDay(true);
              }}
            />
            <Text color="#1b1c1e" size="14px">
              24시간 이상 진행 시
            </Text>
          </Row>
        </Row>
        <Row
          gap="8px"
          justifyContent="space-between"
          style={{
            flexDirection: isOverDay ? 'column' : 'row',
            justifyContent: isOverDay ? 'flex-end' : 'center',
            alignItems: isOverDay ? 'flex-end' : 'center',
            width: '100%',
          }}
        >
          <DateBox>
            <Text
              color={termDate.startDate ? '#1b1c1e' : '#c2c4c8'}
              size="14px"
              weight={400}
              style={{ zIndex: 2 }}
            >
              {termDate.startDate ? termDate.startDate : '00시 • 00분'}
            </Text>
            <TimeInput
              onChange={e => {
                setTermDate({ ...termDate, startDate: e.target.value });

                if (isOverDay) {
                  return;
                }

                if (termDate.endDate && termDate.endDate < e.target.value) {
                  window.showToast(
                    '종료시간은 시작 시간보다 빠를 수 없어요.',
                    'failed',
                    5000,
                  );
                }
              }}
            />
          </DateBox>
          {isOverDay && (
            <Row style={{ position: 'relative', width: '50%' }}>
              <OptionInput
                type="text"
                value={termDate.durationDate}
                onChange={e => {
                  setTermDate({
                    ...termDate,
                    durationDate: e.target.value.replaceAll(
                      ONLY_NUMBER_REGEX,
                      '',
                    ),
                  });
                }}
                style={{ padding: '8px 39px 8px 12px' }}
              />
              <Text
                color="#1b1c1e"
                weight={400}
                style={{ position: 'absolute', right: '12px' }}
              >
                일 뒤
              </Text>
            </Row>
          )}
          <DateBox>
            <Text
              color={termDate.endDate ? '#1b1c1e' : '#c2c4c8'}
              size="14px"
              weight={400}
              style={{ zIndex: 2 }}
            >
              {termDate.endDate ? termDate.endDate : '00시 • 00분'}
            </Text>
            <TimeInput
              onChange={e => {
                setTermDate({ ...termDate, endDate: e.target.value });

                if (isOverDay) {
                  return;
                }

                if (termDate.startDate && termDate.startDate > e.target.value) {
                  window.showToast(
                    '종료시간은 시작 시간보다 빠를 수 없어요.',
                    'failed',
                    5000,
                  );
                }
              }}
            />
          </DateBox>
        </Row>
      </OptionContainer>
    );
  };

  return renderContents();
};

export default OptionTermDate;
