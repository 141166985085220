import React from 'react';
import { styled } from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 1px;
  background-color: #eaebec;
`;

interface DividerIProps {
  style?: React.CSSProperties;
}

const Divider: React.FC<DividerIProps> = (props) => {
  const { style } = props;

  return <Container style={{ ...style }} />;
};

export default Divider;
