import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import dayjs from 'dayjs';
import { useOptionBottomSheetHooks } from 'containers/ProductRegist/components/OptionSelectRegist/hooks/useOptionBottomSheetHooks';
import {
  Items,
  ProductParamInput,
  ScheduleParam,
} from 'containers/ProductRegist/hooks/types';
import { OptionSheetType } from 'containers/ProductRegist/components/OptionSelectRegist/hooks/useCalenderHooks';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import AuthButton from 'components/Button/AuthButton';
import Column from 'components/common/Column';
import OptionSelectForm from 'containers/ProductRegist/components/OptionSelectRegist/components/OptionSelectForm';
import OptionTermDate from 'containers/ProductRegist/components/OptionSelectRegist/components/OptionTermDate';
import OptionRecruitPeople from 'containers/ProductRegist/components/OptionSelectRegist/components/OptionRecruitPeople';
import OptionDueDateForm from 'containers/ProductRegist/components/OptionSelectRegist/components/OptionDueDateForm';
import OptionModal from 'containers/ProductRegist/components/OptionSelectRegist/components/OptionModal';
import { InventoryTargetType, ScheduleState } from 'types/api';
import ConfirmDialog from 'components/Modal/ConfirmDialog';

const Container = styled.div<{ $isModalVisible: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  position: fixed;
  left: 0;
  bottom: 0;
  /* padding: 16px 0 0 0; */
  padding: ${props => (props.$isModalVisible ? '16px 0 50px' : '0')};
  width: 100%;
  max-height: ${window.innerHeight - 90}px;
  overflow: auto;

  border-radius: 16px 16px 0px 0px;
  background: var(--Static-White, #fff);
  box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
  z-index: 20;
`;

const BottomSheetButton = styled.div`
  width: 40px;
  height: 4px;
  border-radius: 99px;
  background: var(--gray-gray-04, #dbdcdf);
  transform: translateY(-80%);
`;

const FloatingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  padding: 16px 20px 24px 20px;
  margin-top: auto;

  width: 100%;
  background-color: #fff;
  border-radius: 0 0 16px 16px;
  border-top: 1px solid #dbdcdf;
  z-index: 21;
`;

interface CalendarModifyFormIProps {
  productParamInput: ProductParamInput;
  scheduleItem: ScheduleParam;
  checkedDate: any[];
  isMobile: boolean;
  items: Items[];
  optionSheetType: OptionSheetType;
  setScheduleItem: React.Dispatch<React.SetStateAction<ScheduleParam>>;
  setCheckedDate: React.Dispatch<React.SetStateAction<any[]>>;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
  handleClearCalenderData: (isClear: boolean) => void;
  handleOnCloseOptionModal?: () => void;
  handleChangeScheduleItem: (key: string, value: any) => void;
  handleInitScheduleItem: () => void;
  handleNavigateSaveButton: () => void;
}

const CalendarCreateForm: React.FC<CalendarModifyFormIProps> = props => {
  const {
    productParamInput,
    scheduleItem,
    checkedDate,
    isMobile,
    items,
    optionSheetType,
    setScheduleItem,
    setCheckedDate,
    handleOnChangeProductParamInput,
    handleClearCalenderData,
    handleOnCloseOptionModal,
    handleChangeScheduleItem,
    handleInitScheduleItem,
    handleNavigateSaveButton,
  } = props;

  const {
    endDateFormat,
    isModalVisible,
    isOpenOptionModal,
    selectedItems,
    termDate,
    endDueDate,
    setEndDueDate,
    setTermDate,
    setSelectedItems,
    handleButtonClick,
    setIsOpenOptionModal,
    setEndDateFormat,
  } = useOptionBottomSheetHooks();

  const [isOpenOptionDeleteDialog, setIsOpenOptionDeleteDialog] =
    useState<boolean>(false);
  const twentyFourHoursMs = 24 * 60 * 60 * 1000;

  const [isOverDay, setIsOverDay] = useState<boolean>(
    scheduleItem.term.duration > twentyFourHoursMs,
  );

  const handleCreateScheduleItems = () => {
    if (
      productParamInput.inventoryTargetType !== InventoryTargetType.BY_ITEM &&
      scheduleItem.minimumQuota > scheduleItem.quota
    ) {
      window.showToast('최대 인원은 최소 인원보다 적을 수 없습니다.', 'failed');

      return;
    }

    if (
      productParamInput.inventoryTargetType !== InventoryTargetType.BY_ITEM &&
      (scheduleItem.minimumQuota === 0 || scheduleItem.quota === 0)
    ) {
      window.showToast('최소/최대인원은 0명으로 설정할 수 없어요.', 'failed');

      return;
    }

    let schedulItems: ScheduleParam[] = productParamInput.frip
      ? productParamInput.frip.schedules
      : [];

    checkedDate.forEach(item => {
      const startedTime = termDate.startDate.split(':');
      const endedTime = termDate.endDate.split(':');
      const startDate = new Date(item);
      const endDate = new Date(item);

      startDate.setHours(Number(startedTime[0]));
      startDate.setMinutes(Number(startedTime[1]));
      endDate.setHours(Number(endedTime[0]));
      endDate.setMinutes(Number(endedTime[1]));

      if (isOverDay) {
        endDate.setDate(endDate.getDate() + Number(termDate.durationDate));
      }

      let applicationEndDate = cloneDeep(startDate);

      if (endDateFormat === '1day') {
        applicationEndDate.setDate(applicationEndDate.getDate() - 1);
      }

      if (endDateFormat === '2day') {
        applicationEndDate.setDate(applicationEndDate.getDate() - 2);
      }

      if (endDateFormat === '3day') {
        applicationEndDate.setDate(applicationEndDate.getDate() - 3);
      }

      const date = new Date().getTime();
      if (date > applicationEndDate.getTime()) {
        window.showToast(
          '과거 날짜가 있어요. 신청 가능 기간을 미래의 날짜로 다시 선택해주세요.',
          'failed',
        );

        return;
      }

      let applicationStartDate = cloneDeep(applicationEndDate);
      applicationStartDate.setMonth(applicationStartDate.getMonth() - 3);

      const result: ScheduleParam = {
        ...scheduleItem,
        planParamId: `plan${Math.random()}`,
        term: {
          duration: dayjs(endDate).diff(startDate),
          startedAt: startDate.getTime(),
        },
        title: dayjs(startDate).format('YYYY년 M월 D일 HH:mm'),
        saleTerm: {
          startedAt: applicationStartDate.getTime(),
          endedAt: applicationEndDate.getTime(),
        },
        status: ScheduleState.EDITING,
        itemParamIds: scheduleItem.appliedAllItems
          ? []
          : selectedItems.map(item => item.paramId),
      };

      schedulItems = [...schedulItems, result];
    });

    handleOnChangeProductParamInput('frip', {
      ...productParamInput.frip,
      schedules: schedulItems,
    });

    setCheckedDate([]);
    handleClearCalenderData(true);
    handleInitScheduleItem();
    handleNavigateSaveButton();
    handleOnCloseOptionModal && handleOnCloseOptionModal();
  };

  const handleDeleteScheduleItems = () => {
    handleOnChangeProductParamInput('frip', {
      ...productParamInput,
      schedules: productParamInput.frip?.schedules.filter(
        item => item.planParamId !== scheduleItem.planParamId,
      ),
    });

    setCheckedDate([]);
    handleOnCloseOptionModal && handleOnCloseOptionModal();

    setIsOpenOptionDeleteDialog(false);
  };

  useEffect(() => {
    if (optionSheetType === OptionSheetType.UPDATE) {
      let result: Items[] = [];
      items.filter((item: Items) => {
        if (scheduleItem.itemParamIds.includes(item.paramId))
          result = [...result, item];
      });

      setSelectedItems(result);
    }
  }, [optionSheetType]);

  const renderBottomSheet = (): JSX.Element => {
    if (isMobile) {
      return (
        <React.Fragment>
          <Column
            gap="16px"
            style={{
              position: 'sticky',
              top: 0,
              padding: '16px 0',
              background: '#fff',
              zIndex: 3,
            }}
            onClick={() => {
              handleButtonClick();
              handleClearCalenderData(false);
            }}
          >
            <BottomSheetButton />
            <Row style={{ width: '100%', position: 'relative' }}>
              <Text color="#1b1c1e" size="16px" weight={600}>
                선택된 {checkedDate.length}일 설정하기
              </Text>
            </Row>
          </Column>
          {isModalVisible && renderContents()}
          {isModalVisible && (
            <FloatingContainer>
              <Text
                color="#1b1c1e"
                size="16px"
                style={{ textDecoration: 'underline' }}
                onClick={() => {
                  handleButtonClick();
                }}
              >
                닫기
              </Text>
              <AuthButton
                name="확인"
                isAbled={
                  (selectedItems.length !== 0 ||
                    scheduleItem.appliedAllItems) &&
                  termDate.startDate !== '' &&
                  termDate.endDate !== ''
                }
                onClick={() => {
                  handleCreateScheduleItems();
                }}
                style={{ width: '76px' }}
              />
            </FloatingContainer>
          )}
        </React.Fragment>
      );
    }

    return (
      <Column>
        <Row
          justifyContent="flex-start"
          style={{
            position: 'sticky',
            top: 0,
            padding: '32px 20px',
            width: '100%',
            background: '#fff',
            borderLeft: '1px solid #eaebec',
            borderBottom: '1px solid #eaebec',
            zIndex: 3,
          }}
        >
          <Text color="#1b1c1e" size="24px">
            선택된 {checkedDate.length}일 설정하기
          </Text>
        </Row>
        <Column align="flex-end" gap="16px" style={{ padding: '32px 20px' }}>
          {renderContents()}
          <AuthButton
            name="적용"
            isAbled={
              (selectedItems.length !== 0 || scheduleItem.appliedAllItems) &&
              termDate.startDate !== '' &&
              termDate.endDate !== ''
            }
            onClick={() => {
              handleCreateScheduleItems();
            }}
            style={{ width: '82px' }}
          />
        </Column>
      </Column>
    );
  };

  const renderContents = (): JSX.Element => {
    const handleDeleteSelectForm = (item: Items) => {
      setSelectedItems(
        selectedItems.filter(filterItem => filterItem.paramId !== item.paramId),
      );
      setScheduleItem({
        ...scheduleItem,
        itemParamIds: scheduleItem.itemParamIds.filter(
          paramItem => paramItem !== item.paramId,
        ),
      });
    };

    return (
      <Column
        gap="16px"
        align="flex-start"
        style={{ padding: isMobile ? '0 20px' : '0' }}
      >
        <OptionSelectForm
          scheduleItem={scheduleItem}
          selectedItems={selectedItems}
          isMobile={isMobile}
          isDisable={false}
          handleDeleteSelectForm={handleDeleteSelectForm}
          handleIsOpenModal={() => {
            setIsOpenOptionModal(true);
          }}
        />
        <OptionTermDate
          scheduleItem={scheduleItem}
          termDate={termDate}
          isOverDay={isOverDay}
          isDisable={false}
          setIsOverDay={setIsOverDay}
          setTermDate={setTermDate}
        />
        {!(
          productParamInput.frip.attachedToSchedule &&
          productParamInput.inventoryTargetType === InventoryTargetType.BY_ITEM
        ) && (
          <OptionRecruitPeople
            scheduleItem={scheduleItem}
            isDisable={false}
            handleChangeScheduleItem={handleChangeScheduleItem}
          />
        )}
        <OptionDueDateForm
          scheduleItem={scheduleItem}
          endDateFormat={endDateFormat}
          termDate={termDate}
          endDueDate={endDueDate}
          isDisable={false}
          setEndDueDate={setEndDueDate}
          setEndDateFormat={setEndDateFormat}
        />
      </Column>
    );
  };

  const renderOptionModal = (): JSX.Element => {
    if (isOpenOptionModal) {
      return (
        <OptionModal
          isMobile={isMobile}
          items={items}
          scheduleItem={scheduleItem}
          selectedItems={selectedItems}
          setScheduleItem={setScheduleItem}
          setSelectedItems={setSelectedItems}
          handleChangeScheduleItem={handleChangeScheduleItem}
          onClose={() => {
            // handleChangeScheduleItem('itemParamIds', []);
            // setSelectedItems([]);
            setIsOpenOptionModal(false);
          }}
        />
      );
    }
  };

  return (
    <React.Fragment>
      {isMobile ? (
        <Container
          onScroll={e => e.stopPropagation()}
          $isModalVisible={!isModalVisible && optionSheetType === 'create'}
        >
          {renderBottomSheet()}
        </Container>
      ) : (
        <Row
          align="flex-start"
          justifyContent="flex-start"
          style={{
            position: 'sticky',
            top: 0,
            overflowY: 'scroll',
            minWidth: '375px',
            height: '79vh',
          }}
        >
          {renderBottomSheet()}
        </Row>
      )}
      {renderOptionModal()}
      {isOpenOptionDeleteDialog && (
        <ConfirmDialog
          title="선택한 일정을 삭제할까요?"
          description="일정은 저장되지 않고 삭제됩니다."
          onCloseText="아니요"
          onClose={() => {
            setIsOpenOptionDeleteDialog(false);
          }}
          onAcceptText="네, 삭제할게요"
          onAccept={handleDeleteScheduleItems}
        />
      )}
    </React.Fragment>
  );
};

export default CalendarCreateForm;
