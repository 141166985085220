import { gql } from "@apollo/client";

export const GET_HOST_ATTACHEMENT_DOWNLOAD_URL = gql`
  mutation GenerateHostAttachmentDownloadUrl($hostAttachmentId: ID!) {
    generateHostAttachmentDownloadUrl(hostAttachmentId: $hostAttachmentId) {
      message
      success
      url
      __typename
    }
  }
`;
