import Row from 'components/common/Row';
import Text from 'components/common/Text';
import Divider from 'components/Divider';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import { isEmpty } from 'lodash';
import React from 'react';
import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 0;
  width: 100%;
`;

const AdditionalForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 0 24px;
  width: 100%;
`;

const AdditionalOptionButton = styled.div<{
  border: string;
  background: string;
  color: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 11px;
  border-radius: 20px;
  border: 1px solid ${props => props.border};
  background-color: ${props => props.background};
  color: ${props => props.color};
  font-size: 14px;
`;

interface AdditionalInformationContainerIProps {
  productParamInput: ProductParamInput;
}

const AdditionalInformationContainer: React.FC<
  AdditionalInformationContainerIProps
> = props => {
  const { productParamInput } = props;

  const fripInfo = productParamInput.frip;

  const renderInclusionsContainer = (): JSX.Element => {
    return (
      <AdditionalForm>
        <Text color="#000" size="18px" weight={700}>
          포함사항
        </Text>
        <Row gap="8px" style={{ flexWrap: 'wrap' }}>
          {fripInfo?.inclusions.map((item, idx) => {
            return (
              <AdditionalOptionButton
                border="#9bd491"
                background="#fbfff5"
                color="#004518"
                key={idx}
              >
                {item}
              </AdditionalOptionButton>
            );
          })}
        </Row>
      </AdditionalForm>
    );
  };

  const renderExclusionsContainer = (): JSX.Element => {
    return (
      <AdditionalForm>
        <Text color="#000" size="18px" weight={700}>
          불포함사항
        </Text>
        <Row gap="8px" style={{ flexWrap: 'wrap' }}>
          {fripInfo?.exclusions.map((item, idx) => {
            return (
              <AdditionalOptionButton
                border="#d49991"
                background="#fff9f7"
                color="#4e0000"
                key={idx}
              >
                {item}
              </AdditionalOptionButton>
            );
          })}
        </Row>
      </AdditionalForm>
    );
  };

  const renderStuffsToPrepareContainer = (): JSX.Element => {
    return (
      <AdditionalForm>
        <Text color="#000" size="18px" weight={700}>
          준비물
        </Text>
        <Row gap="8px" style={{ flexWrap: 'wrap' }}>
          {fripInfo?.stuffsToPrepare.map((item, idx) => {
            return (
              <AdditionalOptionButton
                border="#dddddd"
                background="#ffffff"
                color="#666666"
                key={idx}
              >
                {item}
              </AdditionalOptionButton>
            );
          })}
        </Row>
      </AdditionalForm>
    );
  };

  return (
    <Container>
      {!isEmpty(fripInfo?.inclusions) && renderInclusionsContainer()}
      {!isEmpty(fripInfo?.exclusions) && renderExclusionsContainer()}
      {!isEmpty(fripInfo?.stuffsToPrepare) && renderStuffsToPrepareContainer()}
    </Container>
  );
};

export default AdditionalInformationContainer;
