import React, { useState } from "react";
import { styled } from "styled-components";

import Row from "components/common/Row";
import Text from "components/common/Text";
import Column from "components/common/Column";

const ImageContainer = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 9px;
`;

interface RegistDescriptionFormIProps {
  number: number;
  title: string;
  description: string;
  thumbnail: string;
}

const RegistDescriptionForm: React.FC<RegistDescriptionFormIProps> = (
  props
) => {
  const { number, title, description, thumbnail } = props;

  const [isSelected, setIsSelected] = useState<boolean>(false);

  return (
    <Row justifyContent="space-between" gap="24px" style={{ width: "100%" }}>
      <Row gap="8px" align="flex-start">
        <Text color="#1b1c1e" size="16px">
          {number}
        </Text>
        <Column gap="4px" align="flex-start">
          <Text color="#1b1c1e" size="16px">
            {title}
          </Text>
          <Text color="#70737C" size="14px" weight={400}>
            {description}
          </Text>
        </Column>
      </Row>
      <ImageContainer src={thumbnail} alt="description thumbnail" />
    </Row>
  );
};

export default RegistDescriptionForm;
