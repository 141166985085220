import Column from "components/common/Column";
import Text from "components/common/Text";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  position: fixed;
  bottom: 64px;
  width: 100%;
  max-width: 335px;

  border-radius: 12px;
  border: 1px solid #dbdcdf;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
`;

interface BottomToastIProps {
  title: string;
  description: string;
  onAcceptText: string;
  onAccept: () => void;
}

const BottomToast: React.FC<BottomToastIProps> = (props) => {
  const { title, description, onAcceptText, onAccept } = props;

  return (
    <Container>
      <Column
        gap="4px"
        align="flex-start"
        style={{ maxWidth: "239px", lineHeight: "16px" }}
      >
        <Text color="#1B1C1E">{title}</Text>
        <Text color="#70737c" size="12px" weight={400}>
          {description}
        </Text>
      </Column>
      <Text
        color="#70737c"
        size="14px"
        style={{ textDecoration: "underline" }}
        onClick={onAccept}
      >
        {onAcceptText}
      </Text>
    </Container>
  );
};

export default BottomToast;
