import { gql } from '@apollo/client';

export const GET_NOTICE_BOARD = gql`
  query ($filter: NoticeFilter, $page: Int, $size: Int) {
    board {
      notices(filter: $filter, page: $page, size: $size) {
        totalCount
        edges {
          node {
            id
            title
            content
            createdAt
            type
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          endCursor
        }
      }
    }
  }
`;
