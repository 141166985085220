import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useSettlementHooks } from './hooks/useSettlementHooks';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import { TaxPayerType } from './graphQL/types';
import SettlementFAQ from './components/SettlementFAQ';

import {
  ICON_ARROW_LEFT,
  ICON_SETTLEMENT_ALERT,
  ICON_SETTLEMENT_BANK,
  ICON_SETTLEMENT_REGIST_REQUEST,
} from 'assets/icons';
import HostInformationModal from './components/HostInformationModal';
import {
  SETTLEMENT_BANK_REGIST_PATH,
  SETTLEMENT_REGIST_PATH,
} from 'constants/path';
import { useResizeDetector } from 'react-resize-detector';
import CommonImage from 'components/common/CommonImage';
import CommonRow from 'components/common/Row';
import BasicButton from 'components/Button/BasicButton';
import MenuBackwardButton from 'components/Button/MenuBackwardButton';
import HackleManager from 'utils/hackle';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 768px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  width: 100%;
  background-color: #fff;
`;

const ContentContainer = styled(Container)`
  align-items: flex-start;
  gap: 24px;
  padding: 16px 20px;
`;

const BoxContainer = styled.div`
  display: flex;
  gap: 12px;
  padding: 16px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #eaebec;
  background-color: #fff;
`;

const RegistHostButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  margin-top: 8px;

  border-radius: 8px;
  border: 1px solid #1b1c1e;
  background: #fff;
  color: #1b1c1e;

  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.6px;
`;

const RegistCalculateButton = styled.button<{
  $isActiveButton: boolean | null;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;

  background: ${props => (props.$isActiveButton ? '#1B1C1E' : '#f4f4f5')};
  color: ${props => (props.$isActiveButton ? '#fff' : '#dbdcdf')};
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.6px;
`;

const Badge = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 999px;
  background: #f4f4f5;

  color: ${props => props.color};

  font-family: Pretendard;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: -0.6px;
`;

const SettlementPage = () => {
  const {
    myInfo,
    isCheckBusinessInformation,
    isCheckRegistState,
    isRegistRequest,
    isOpenHostInformationModal,
    setIsOpenHostInformationModal,
    handleNavigateBack,
    requestMessage,
    handleCalculateRegistNavigate,
    handleRegistBankPageNavigate,
  } = useSettlementHooks();

  const navigate = useNavigate();

  const { width, ref } = useResizeDetector();

  const isMobile = width ? width < 768 : window.innerWidth < 768;

  useEffect(() => {
    HackleManager.logSettlementView();
  }, []);

  const renderHeader = () => {
    if (isMobile) {
      return (
        <Header>
          <CommonImage src={ICON_ARROW_LEFT} onClick={handleNavigateBack} />
          {myInfo.me.host?.settlement?.businessInfo !== null && (
            <Row
              onClick={() => {
                setIsOpenHostInformationModal(true);
              }}
            >
              <Text color="#1b1c1e" size="14px" weight={400}>
                호스트 정보
              </Text>
            </Row>
          )}
        </Header>
      );
    }

    return (
      <Header style={{ padding: '40px 20px 24px' }}>
        <CommonRow gap="16px">
          <MenuBackwardButton onClick={handleNavigateBack} />
          <Text color="#1b1c1e" size="24px">
            정산 정보 관리
          </Text>
        </CommonRow>
        {myInfo.me.host?.settlement?.businessInfo !== null && (
          <CommonRow gap="12px">
            <BasicButton
              content={
                <CommonRow gap="4px">
                  <Text color="#1b1c1e">내 호스트 정보</Text>
                </CommonRow>
              }
              onClick={() => {
                setIsOpenHostInformationModal(true);
              }}
            />
          </CommonRow>
        )}
      </Header>
    );
  };

  const renderCalculateInformation = () => {
    if (
      myInfo.me.host &&
      myInfo.me.host.settlement?.businessInfo !== null &&
      myInfo.me.host.settlement?.bankAccount !== null
    ) {
      return (
        <React.Fragment>
          {isCheckRegistState && (
            <BoxContainer>
              <Row align="flex-start">
                <img
                  src={ICON_SETTLEMENT_REGIST_REQUEST}
                  alt="calculate regist request"
                />
              </Row>
              {localStorage.getItem('isRegistRequest') === 'yes' ||
              isRegistRequest ? (
                <Column align="flex-start">
                  <Text color="#1b1c1e" size="14px" weight={600}>
                    제출하신 정보를 검수중에 있어요
                  </Text>
                  <Text color="#1b1c1e" size="14px" weight={400}>
                    제출하신 호스트 정보를 확인하는데 영업일 기준 최대 5일이
                    소요될 수 있습니다.
                  </Text>
                  <RegistHostButton>
                    <a href="http://pf.kakao.com/_NQxnQxd" target="_blank">
                      고객센터 문의하기
                    </a>
                  </RegistHostButton>
                </Column>
              ) : (
                <Column align="flex-start">
                  <Text color="#1b1c1e" size="14px" weight={600}>
                    제출하신 정보를 검수 요청해주세요
                  </Text>
                  <Text color="#1b1c1e" size="14px" weight={400}>
                    제출하신 호스트 정보를 확인 후 검수가 완료되야 정산을 받을
                    수 있어요.
                  </Text>
                  <RegistHostButton
                    onClick={() => {
                      requestMessage();
                    }}
                  >
                    검수 요청
                  </RegistHostButton>
                </Column>
              )}
            </BoxContainer>
          )}
          <Column align="flex-start" gap="8px">
            <Text color="#1b1c1e" size="24px" style={{ lineHeight: '36px' }}>
              등록한 계좌 정보
            </Text>
            {!isCheckRegistState && (
              <Row style={{ alignItems: 'center', gap: '6px' }}>
                <Text color="#70737c" size="16px" weight={400}>
                  등록된 계좌로 정산을 받을 수 있어요.
                </Text>
              </Row>
            )}
            <Row
              gap="12px"
              style={{
                justifyContent: 'space-between',
                marginTop: '24px',
                width: '100%',
              }}
            >
              <Row gap="12px" align="flex-start">
                <img src={ICON_SETTLEMENT_BANK} alt="icon calculate bank" />
                <Column align="flex-start" gap="10px">
                  <Row gap="12px">
                    <Text color="#1b1c1e" size="13px" weight={400}>
                      {
                        myInfo.me.host?.settlement?.bankAccount.account.bank
                          .name
                      }
                    </Text>
                    <Row style={{ gap: '4px' }}>
                      <Badge color="#70737C">
                        {myInfo.me.host?.settlement?.businessInfo
                          .taxPayerType === TaxPayerType.INDIVIDUAL
                          ? '개인'
                          : '사업자'}
                      </Badge>
                    </Row>
                  </Row>
                  <Text color="#1b1c1e" size="14px" weight={400}>
                    {myInfo.me.host?.settlement?.bankAccount.account.holder},{' '}
                    {myInfo.me.host?.settlement?.bankAccount.account.accountNo.replace(
                      /^(\d{9})(\d{4})$/,
                      '*********$2',
                    )}
                  </Text>
                </Column>
              </Row>
              <RegistHostButton
                style={{
                  marginTop: '0px',
                }}
                onClick={() => {
                  navigate(SETTLEMENT_BANK_REGIST_PATH, {
                    state: {
                      isModify: true,
                    },
                  });
                }}
              >
                수정
              </RegistHostButton>
            </Row>
          </Column>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <BoxContainer>
          <Row>
            <img src={ICON_SETTLEMENT_ALERT} alt="calculate alert" />
          </Row>
          <Column gap="4px" align="flex-start">
            {isCheckBusinessInformation ? (
              <React.Fragment>
                <Text color="#1b1c1e" size="14px" weight={600}>
                  정산 계좌 등록이 필요해요
                </Text>
                <Text color="#1b1c1e" size="14px" weight={400}>
                  판매 내역에 대한 정산 및 대금 입금을 위해 정산 계좌 등록이
                  필요합니다.
                </Text>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Text color="#1b1c1e" size="14px" weight={600}>
                  호스트 정보 등록이 필요해요
                </Text>
                <Text color="#1b1c1e" size="14px" weight={400}>
                  정산 요청을 위해 먼저 호스트 정보(개인 또는 사업자) 등록이
                  필요합니다.
                </Text>
                <RegistHostButton onClick={handleCalculateRegistNavigate}>
                  호스트 정보 등록
                </RegistHostButton>
              </React.Fragment>
            )}
          </Column>
        </BoxContainer>
        <Column align="flex-start" gap="8px">
          <Text color="#1b1c1e" size="24px">
            정산 계좌 등록
          </Text>
          <Text color="#1b1c1e" size="16px" weight={400}>
            {isCheckBusinessInformation
              ? '정산 받을 계좌를 등록해 주세요.'
              : '호스트 정보 등록 후, 정산 계좌를 등록할 수 있어요.'}
          </Text>
        </Column>
        <RegistCalculateButton
          $isActiveButton={isCheckBusinessInformation}
          onClick={() => {
            if (isCheckBusinessInformation) handleRegistBankPageNavigate();
          }}
        >
          정산 계좌 등록
        </RegistCalculateButton>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {myInfo && (
        <Container ref={ref}>
          {renderHeader()}
          <ContentContainer>
            {renderCalculateInformation()}
            <SettlementFAQ />
          </ContentContainer>
          {isOpenHostInformationModal && (
            <HostInformationModal
              host={myInfo.me.host}
              onClose={() => {
                setIsOpenHostInformationModal(false);
              }}
            />
          )}
        </Container>
      )}
    </React.Fragment>
  );
};

export default SettlementPage;
