import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Column from 'components/common/Column';
import IndividualUpdateContainer from 'containers/Menu/components/IndividualUpdateContainer';
import Row from 'components/common/Row';
import MenuBackwardButton from 'components/Button/MenuBackwardButton';
import Text from 'components/common/Text';
import HackleManager from 'utils/hackle';

const IndividualPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    HackleManager.logIndividualView();
  }, []);

  return (
    <Column style={{ maxWidth: '728px' }}>
      <Row
        gap="16px"
        justifyContent="flex-start"
        style={{ width: '100%', padding: '40px 20px 16px' }}
      >
        <MenuBackwardButton
          onClick={() => {
            navigate(-1);
          }}
        />
        <Text size="24px" color="#1b1c1e">
          호스트 연락처 설정
        </Text>
      </Row>
      <Column>
        <IndividualUpdateContainer isMobile={false} />
      </Column>
    </Column>
  );
};

export default IndividualPage;
