import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useOptionUpdateHooks } from 'containers/ProductDetail/hooks/useOptionUpdateHooks';
import {
  Items,
  ProductParamInput,
  SalesType,
} from 'containers/ProductRegist/hooks/types';
import Column from 'components/common/Column';
import OptionBox from 'containers/ProductRegist/components/OptionSelectRegist/components/OptionBox';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import CheckBox from 'components/common/CheckBox';
import { ICON_PLUS_GRAY } from 'assets/icons';
import {
  CommissionType,
  CurrencyCode,
  InventoryTargetType,
  ItemState,
  Product,
  ProductState,
} from 'types/api';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import { ModifyValue } from 'containers/ProductDetail/hooks/useProductDetaillHooks';
import AuthButton from 'components/Button/AuthButton';
import SaveButton from '../SaveButton';
import HackleManager from 'utils/hackle';

const OptionPlusButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background-color: #fff;

  color: #70737c;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
  cursor: pointer;
`;

interface OptionSelectModifyFormIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  productInfo: Product;
  modifyValue: ModifyValue;
  setModifyValue: Dispatch<SetStateAction<ModifyValue>>;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
  handleRefetchProduct: (productId: string) => void;
}

const OptionSelectModifyForm: React.FC<
  OptionSelectModifyFormIProps
> = props => {
  const {
    isMobile,
    productParamInput,
    productInfo,
    modifyValue,
    setModifyValue,
    handleOnChangeProductParamInput,
    handleRefetchProduct,
  } = props;

  const [copyProductParamInput, setCopyProductParamInput] =
    useState<ProductParamInput>(productParamInput);

  const handleOnChangeCopyProductParamInput = (key: string, value: any) => {
    setCopyProductParamInput({ ...copyProductParamInput, [key]: value });
  };

  const {
    items,
    selectedItems,
    setItems,
    handleAddOptions,
    handleSelectedItems,
    handleDeleteItmes,
    handleSetRepresentativeItems,
  } = useOptionUpdateHooks(
    copyProductParamInput,
    handleOnChangeCopyProductParamInput,
  );

  const isAbled =
    productParamInput.items !== copyProductParamInput.items &&
    productParamInput.items.some(paramItem =>
      copyProductParamInput.items.some(
        copyItem => copyItem.options !== paramItem.options,
      ),
    ) &&
    copyProductParamInput.items.every(copyItem =>
      copyItem.options.every(optionItem => optionItem.name !== ''),
    );

  const [saleClosedFilter, setSaleClosedFilter] = useState<boolean>(false);

  const handleResetItems = () => {
    setItems(productParamInput.items);
    setCopyProductParamInput(productParamInput);
  };

  const handleRegistItems = () => {
    if (
      copyProductParamInput.frip.attachedToSchedule &&
      copyProductParamInput.inventoryTargetType === InventoryTargetType.BY_ITEM
    ) {
      if (
        copyProductParamInput.items.some(item => item.minimumQuota > item.quota)
      ) {
        window.showToast(
          '최대 인원은 최소 인원보다 적을 수 없습니다. 최소 인원보다 높은 인원을 선택해주세요.',
          'failed',
        );

        return;
      }

      if (copyProductParamInput.items.some(item => item.quota === 0)) {
        window.showToast('최대인원은 0명으로 설정할 수 없어요', 'failed');

        return;
      }
    }

    if (!copyProductParamInput.items.some(item => item.representative)) {
      window.showToast('대표 옵션을 설정해주세요.', 'failed');

      return;
    }

    handleOnChangeProductParamInput('items', copyProductParamInput.items);
  };

  useEffect(() => {
    handleResetItems();
  }, [productParamInput]);

  useEffect(() => {
    HackleManager.logProductModifySalesOptionView(
      Number(productInfo.id),
      productInfo.title,
    );
  }, []);

  const renderOptionBox = (): JSX.Element => {
    return (
      <React.Fragment>
        {copyProductParamInput.items.map((item: Items, idx: number) => {
          return (
            <React.Fragment key={idx}>
              {!saleClosedFilter ? (
                item.status !== ItemState.CLOSED && (
                  <Column gap="16px" align="flex-end" key={idx}>
                    <OptionBox
                      type="modify"
                      idx={idx}
                      isMobile={isMobile}
                      item={item}
                      selectedItems={selectedItems}
                      productParamInput={productParamInput}
                      setItems={setItems}
                      handleSelectedItems={handleSelectedItems}
                      handleDeleteItmes={handleDeleteItmes}
                      handleSetRepresentativeItems={
                        handleSetRepresentativeItems
                      }
                      handleRefetchProduct={handleRefetchProduct}
                    />
                  </Column>
                )
              ) : (
                <Column gap="16px" align="flex-end" key={idx}>
                  <OptionBox
                    type="modify"
                    idx={idx}
                    isMobile={isMobile}
                    item={item}
                    selectedItems={selectedItems}
                    productParamInput={productParamInput}
                    setItems={setItems}
                    handleSelectedItems={handleSelectedItems}
                    handleDeleteItmes={handleDeleteItmes}
                    handleSetRepresentativeItems={handleSetRepresentativeItems}
                    handleRefetchProduct={handleRefetchProduct}
                  />
                </Column>
              )}
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };

  const renderItems = (): JSX.Element => {
    return (
      <Column gap="16px" align="flex-start">
        <Row
          justifyContent="space-between"
          style={{
            width: '100%',
          }}
        >
          <Text color="#46474c" size="14px">
            총 옵션 {items.length}개
          </Text>
          <Row gap="6px">
            <CheckBox
              isChecked={saleClosedFilter}
              onChange={() => {
                setSaleClosedFilter(!saleClosedFilter);
              }}
              style={{ width: '16px', height: '16px' }}
            />
            <Text>판매중지된 옵션 보기</Text>
          </Row>
        </Row>
        {renderOptionBox()}
        <Column align="flex-end">
          <OptionPlusButton onClick={handleAddOptions}>
            <img src={ICON_PLUS_GRAY} alt="plus icon" />
            옵션추가
          </OptionPlusButton>
        </Column>
      </Column>
    );
  };

  const renderContents = (): JSX.Element => {
    if (isMobile) {
      return (
        <ModalContainer
          isAbled={productInfo.status !== ProductState.CLOSED && isAbled}
          isMobile={isMobile}
          onAcceptText="적용"
          onClose={() => {
            setModifyValue({ ...modifyValue, modifyStatus: '' });
          }}
          onClick={() => {
            if (isAbled) {
              HackleManager.logProductModifyItemSaveComplete(
                Number(productInfo.id),
                productInfo.title,
                '옵션',
              );

              handleRegistItems();

              setModifyValue({
                ...modifyValue,
                isModify: true,
                modifyStatus: '',
              });
            }
          }}
        >
          <Column>
            <Row
              style={{
                position: 'sticky',
                top: 0,
                padding: '16px 20px',
                width: '100%',
                background: '#fff',
                borderBottom: '1px solid #eaebec',
                zIndex: 5,
              }}
            >
              <Text color="#000" size="16px" weight={600}>
                옵션 수정하기
              </Text>
            </Row>
            <Column style={{ padding: '16px 20px 0' }}>{renderItems()}</Column>
          </Column>
        </ModalContainer>
      );
    }

    return (
      <Column>
        <Row
          justifyContent="flex-start"
          style={{
            position: 'sticky',
            top: 0,
            padding: '32px 40px',
            width: '100%',
            background: '#fff',
            borderBottom: '1px solid #eaebec',
            zIndex: 5,
          }}
        >
          <Text color="#1B1C1E" size="24px">
            옵션 수정하기
          </Text>
        </Row>
        <Column gap="24px" style={{ padding: '32px 40px' }}>
          {renderItems()}
          {productInfo.status !== ProductState.CLOSED && (
            <SaveButton
              isAbled={isAbled}
              modifyValue={modifyValue}
              setModifyValue={setModifyValue}
              handleResetItem={handleResetItems}
              handleRegistItem={() => {
                HackleManager.logProductModifyItemSaveComplete(
                  Number(productInfo.id),
                  productInfo.title,
                  '옵션',
                );
                handleRegistItems();
              }}
            />
          )}
        </Column>
      </Column>
    );
  };

  return renderContents();
};

export default OptionSelectModifyForm;
