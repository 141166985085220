import React, { useEffect } from 'react';

import Column from 'components/common/Column';
import SelectionButton from './components/SelectionButton';
import Text from 'components/common/Text';
import AdditionalInformationForm from './components/AdditionalInformationForm';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import { useParams } from 'react-router-dom';
import HackleManager from 'utils/hackle';

interface ChapterFiveIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  type?: string;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
}

const ChapterFive: React.FC<ChapterFiveIProps> = props => {
  const { isMobile, productParamInput, type, handleOnChangeProductParamInput } =
    props;

  const params = useParams<{ id: string }>();

  const handleAddAdditionalValue = (type: string, inputValue: string) => {
    if (type === 'keywords') {
      handleOnChangeProductParamInput('keywords', [
        ...productParamInput.keywords,
        ...inputValue.split(',').filter(item => item !== ''),
      ]);

      return;
    }

    handleOnChangeProductParamInput('frip', {
      ...productParamInput.frip,
      [type]: [
        ...(productParamInput.frip as any)[type],
        ...inputValue.split(',').filter(item => item !== ''),
      ],
    });
  };

  const handleDeleteAdditionalValue = (type: string, value: string) => {
    if (type === 'keywords') {
      handleOnChangeProductParamInput(
        'keywords',
        productParamInput.keywords.filter(item => item !== value),
      );

      return;
    }

    handleOnChangeProductParamInput('frip', {
      ...productParamInput.frip,
      [type]: (productParamInput.frip as any)[type].filter(
        (item: string) => item !== value,
      ),
    });
  };

  useEffect(() => {
    HackleManager.logCreateStepThreeAdditionalInfoView(
      params.id !== 'draft' ? Number(params.id) : undefined,
    );
  }, []);

  const renderContents = (): JSX.Element => {
    return (
      <Column gap="32px" align="flex-start">
        <Column gap="16px" align="flex-start">
          {!type && <SelectionButton />}
          <Column gap="12px" align="flex-start">
            {!type && (
              <Column gap="8px" align="flex-start">
                <Text color="#1b1c1e" size="24px">
                  추가 정보를 알려주세요.
                </Text>
                <Text color="#70737C" size="16px" weight={400}>
                  각 항목에 맞는 정보를 직접 입력하여 추가해 주세요.
                </Text>
              </Column>
            )}
            <Text color="#0071e9" size="14px" weight={400}>
              입력 후 Enter 또는 쉼표(,)로 구분하여 최대 20개의 키워드를 등록할
              수 있어요.
            </Text>
          </Column>
        </Column>
        <AdditionalInformationForm
          title="준비물"
          placeholder="예시) 볼펜, 앞치마"
          type="stuffsToPrepare"
          additionalList={productParamInput.frip?.stuffsToPrepare!}
          handleAddAdditionalValue={handleAddAdditionalValue}
          handleDeleteAdditionalValue={handleDeleteAdditionalValue}
        />
        <AdditionalInformationForm
          title="참가비 포함사항"
          placeholder="예시) 음료 1잔 , 재료비"
          type="inclusions"
          additionalList={productParamInput.frip?.inclusions!}
          handleAddAdditionalValue={handleAddAdditionalValue}
          handleDeleteAdditionalValue={handleDeleteAdditionalValue}
        />
        <AdditionalInformationForm
          title="참가비 불포함사항"
          placeholder="예시) 교통비, 의류 및 장비"
          type="exclusions"
          additionalList={productParamInput.frip?.exclusions!}
          handleAddAdditionalValue={handleAddAdditionalValue}
          handleDeleteAdditionalValue={handleDeleteAdditionalValue}
        />
        <AdditionalInformationForm
          title="검색 키워드"
          placeholder="예시) 케이크, 비건"
          type="keywords"
          additionalList={productParamInput.keywords}
          handleAddAdditionalValue={handleAddAdditionalValue}
          handleDeleteAdditionalValue={handleDeleteAdditionalValue}
        />
      </Column>
    );
  };

  return renderContents();
};

export default ChapterFive;
