import React from 'react';
import dayjs from 'dayjs';
import BasicButton from 'components/Button/BasicButton';
import { useMutationTicketSale } from 'containers/SaleDetail/graphQL/hooks/useMutationTicketSale';
import { Ticket, TicketState } from 'types/api';
import HackleManager from 'utils/hackle';

interface BookingStatusButtonIProps {
  item: Ticket;
  isModal: boolean;
  page: string;
  handleRefetchBookingList: () => void;
}

const BookingStatusButton: React.FC<BookingStatusButtonIProps> = props => {
  const { item, isModal, page, handleRefetchBookingList } = props;

  const {
    handleRestoreTicket,
    handleUseTicket,
    handleRestoreUseTicket,
    handleAbsentTicket,
    handleRestoreTicketAbsence,
  } = useMutationTicketSale();

  const startedAt = new Date(item.scheduleTerm.startedAt); // 예약 시작 시간
  const currentTime = new Date(); // 현재 시간

  // 예약 시작 1시간 전으로 조정
  const actionTime = new Date(startedAt.getTime() - 60 * 60 * 1000);

  // 조건: 현재 시간이 actionTime 이후이고, startedAt 이전인지 확인
  const isShowActionButton = currentTime > actionTime;

  const renderStatusButton = (): JSX.Element => {
    switch (item.status) {
      case TicketState.CONFIRMED: {
        return (
          <React.Fragment>
            {isModal && (
              <BasicButton
                content="예약 확정 취소"
                onClick={() => {
                  handleRestoreTicket(item.id, () => {
                    HackleManager.logSalesBookingReverseComplete(
                      Number(item.bookingItem.booking.order.id),
                      item.bookingItem.booking.customer.id,
                      [item.id],
                      '판매상세',
                      '예약상세',
                    );

                    handleRefetchBookingList();
                  });
                }}
                style={{ borderRadius: '8px' }}
              />
            )}
            {isShowActionButton && (
              <React.Fragment>
                <BasicButton
                  content="불참처리"
                  onClick={() => {
                    handleAbsentTicket(item.id, () => {
                      handleRefetchBookingList();

                      HackleManager.logBookingAbsenceComplete(
                        Number(item.bookingItem.booking.order.id),
                        item.bookingItem.booking.customer.id,
                        item.bookingItem.booking.info.product.title,
                        item.bookingItem.booking.info.name,
                        [item.id],
                        page === 'list' ? '예약관리' : '예약상세',
                      );
                    });
                  }}
                  style={{ borderRadius: '8px' }}
                />
                <BasicButton
                  content="사용처리"
                  onClick={() => {
                    handleUseTicket(item.id, () => {
                      HackleManager.logBookingAttendanceComplete(
                        Number(item.bookingItem.booking.order.id),
                        item.bookingItem.booking.customer.id,
                        item.bookingItem.booking.info.product.title,
                        item.bookingItem.booking.info.name,
                        [item.id],
                        page === 'list' ? '예약관리' : '예약상세',
                      );

                      handleRefetchBookingList();
                    });
                  }}
                  style={{ borderRadius: '8px', color: '#7126FF' }}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        );
      }
      case TicketState.USED: {
        return (
          <BasicButton
            content="사용 처리 취소"
            onClick={() => {
              handleRestoreUseTicket(item.id, () => {
                HackleManager.logBookingAttendanceReverseComplete(
                  Number(item.bookingItem.booking.order.id),
                  item.bookingItem.booking.customer.id,
                  item.bookingItem.booking.info.product.title,
                  item.bookingItem.booking.info.name,
                  [item.id],
                  page === 'list' ? '예약관리' : '예약상세',
                );

                handleRefetchBookingList();
              });
            }}
            style={{ borderRadius: '8px', color: '#FF4242' }}
          />
        );
      }
      case TicketState.ABSENT: {
        return (
          <BasicButton
            content="불참 처리 취소"
            onClick={() => {
              handleRestoreTicketAbsence(item.id, () => {
                HackleManager.logBookingAbsenceReverseComplete(
                  Number(item.bookingItem.booking.order.id),
                  item.bookingItem.booking.customer.id,
                  item.bookingItem.booking.info.product.title,
                  item.bookingItem.booking.info.name,
                  [item.id],
                  page === 'list' ? '예약관리' : '예약상세',
                );

                handleRefetchBookingList();
              });
            }}
            style={{ borderRadius: '8px', color: '#FF4242' }}
          />
        );
      }
    }

    return;
  };

  return renderStatusButton();
};

export default BookingStatusButton;
