import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';

import {
  BookingState,
  ItemType,
  Ticket,
  TicketOrderType,
  TicketState,
} from 'types/api';
import { useQueryGetBookingTickets } from '../graphQL/hooks/useQueryGetBookingTickets';
import { BookingTermType } from '../graphQL/types';

export interface BookingFilterItem {
  page: number;
  size: number;
  filter?: {
    approved?: boolean;
    customerId?: string;
    customerSearchKeyword?: string;
    hostId?: string;
    itemNameLike?: string;
    itemType?: ItemType;
    orderIds?: string[];
    productIds?: string[];
    productTitleLike?: string;
    scheduleId?: string;
    statusIn?: BookingState[];
    term?: {
      startedAt: number;
      endedAt: number;
    };
    termTypes?: BookingTermType[];
    ticketStatusIn?: TicketState[];
    updatedAt?: number;
  };
}

export interface TicketFilterInput {
  page: number;
  size: number;
  filter?: {
    bookingIds?: string[];
    customerId?: string;
    hostId?: string;
    scheduleIds?: string[];
    statusIn?: TicketState[];
    itemNameLike?: string;
    productId?: string;
    customerSearchKeyword?: string;
    productTitleLike?: string;
    termWithType?: {
      term?: {
        startedAt?: number;
        endedAt?: number;
      };
      type?: BookingTermType;
    };
    orderType?: TicketOrderType;
    unscheduled?: boolean;
  };
}

export const useBookingListHooks = () => {
  const location = useLocation();

  const {
    bookingTickets,
    ticketFilterInput,
    loading,
    isLoading,
    bookingInfo,
    setIsLoading,
    setTicketFilterInput,
    handleRefetchBooikingFilter,
    handleLoadMoreBookingTickets,
  } = useQueryGetBookingTickets();

  const [searchValue, setSearchValue] = useState<string>('');
  const [isActiveSearchButton, setIsActiveSearchButton] =
    useState<boolean>(true);
  const [isAcceptFilter, setIsAcceptFilter] = useState<boolean>(
    location.state ? true : false,
  );
  const [searchType, setSearchType] = useState<string>(
    location.state ? 'fripId' : 'productName',
  );
  const [filterSearchValue, setFilterSearchValue] = useState<string>(
    location.state ? location.state.productId : '',
  );
  const [selectTickets, setSelectTickets] = useState<{
    date: string;
    tickets: Ticket[];
  }>({ date: '', tickets: [] });

  const handleAllSelectTickets = (bookingTickets: {
    date: string;
    value: Ticket[];
  }) => {
    if (selectTickets.date === bookingTickets.date) {
      if (selectTickets.tickets.length === bookingTickets.value.length) {
        setSelectTickets({ date: '', tickets: [] });

        return;
      }

      if (!isEmpty(selectTickets.tickets)) {
        setSelectTickets({
          date: bookingTickets.date,
          tickets: bookingTickets.value,
        });

        return;
      }
    }

    setSelectTickets({
      date: bookingTickets.date,
      tickets: bookingTickets.value,
    });
  };

  const handleSelectTickets = (date: string, ticket: Ticket) => {
    if (selectTickets.tickets.some(item => item.id === ticket.id)) {
      setSelectTickets({
        date:
          selectTickets.tickets.filter(item => item.id !== ticket.id).length ===
          0
            ? ''
            : date,
        tickets: selectTickets.tickets.filter(item => item.id !== ticket.id),
      });

      return;
    }

    setSelectTickets({
      date,
      tickets: [...selectTickets.tickets, ticket],
    });
  };

  const handleResetSelectTickets = () => {
    setSelectTickets({ date: '', tickets: [] });
  };

  const handleSetTicekFilterInput = (key: string, value: any) => {
    setTicketFilterInput({ ...ticketFilterInput, [key]: value });
  };

  const handleRefetchBookingList = () => {
    handleRefetchBooikingFilter(ticketFilterInput);
  };

  useEffect(() => {
    if (bookingTickets) {
      handleRefetchBookingList();
      setIsLoading(true);
    }
  }, [ticketFilterInput.filter]);

  return {
    isLoading,
    loading,
    bookingTickets,
    ticketFilterInput,
    bookingInfo,
    searchValue,
    searchType,
    filterSearchValue,
    isActiveSearchButton,
    isAcceptFilter,
    selectTickets,
    setIsLoading,
    setSearchType,
    setFilterSearchValue,
    setTicketFilterInput,
    setSearchValue,
    setIsActiveSearchButton,
    setIsAcceptFilter,
    handleSetTicekFilterInput,
    handleRefetchBooikingFilter,
    handleRefetchBookingList,
    handleLoadMoreBookingTickets,
    handleAllSelectTickets,
    handleSelectTickets,
    handleResetSelectTickets,
  };
};
