import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_CURRENT_BANNER } from '..';
import { CurrentBanner } from '../types';

export function useQueryGetCurrentBanner() {
  const { data } = useQuery<CurrentBanner>(GET_CURRENT_BANNER, {
    variables: { platform: 'Mobile' },
  });

  const [currentBanners, setCurrentBanners] = useState<CurrentBanner>();

  useEffect(() => {
    if (data) {
      setCurrentBanners(data);
    }
  }, [data]);

  return { currentBanners };
}
