import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import Divider from 'components/Divider';
import QnaStatusButton from 'containers/QnaList/components/QnaStatusButton';
import { ProductQnaState } from 'containers/QnaList/graphQL/types';
import { ProductReviewEdge } from 'types/api';
import { ICON_ARROW_GRAY_BOLD, ICON_STAR_YELLOW } from 'assets/icons';
import { useMutationReviewComment } from '../graphQL/hooks/useMutationReviewComment';
import ReportModal from './ReportModal';
import ImageThumbnailSlider from './ImageThumbnailSlider';
import HackleManager from 'utils/hackle';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px;
  gap: 16px;
  width: 100%;
`;

const StarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 9px;
  border-radius: 14px;
  border: 1px solid #dbdcdf;
  background: #f7f7f8;

  color: #1b1c1e;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 140% */
  letter-spacing: -0.2px;
`;

const ReviewAnswerTextarea = styled.textarea`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 16px 16px 32px 16px;
  width: 100%;
  height: auto;
  min-height: 132px;
  overflow: auto;

  border-radius: 8px;
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--gray-gray-01, #f7f7f8);
  font-size: 14px;

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  &:focus {
    border: 1.5px solid #1b1c1e;
  }
`;

const UpdateButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);
`;

const ProductName = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  color: #70737c;
`;

const QuestionBox = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  color: #1b1c1e;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
`;

const ProductThumbnail = styled.img`
  min-width: 80px;
  max-width: 80px;
  height: 80px;
  border-radius: 6px;
`;

interface ReviewItemIProps {
  isMobile: boolean;
  item: ProductReviewEdge;
  handleSelectedReviewItem: (item: ProductReviewEdge) => void;
  handleRefetchList: () => void;
}

const ReviewItem: React.FC<ReviewItemIProps> = props => {
  const { isMobile, item, handleSelectedReviewItem, handleRefetchList } = props;
  const { handleWriteComment, handleModifyComment, handleDeleteComment } =
    useMutationReviewComment(handleRefetchList);

  const [value, setValue] = useState<string>(
    isEmpty(item.node.latestComments.edges)
      ? ''
      : item.node.latestComments.edges[0].node.content,
  );
  const [isOpenReportModal, setIsOpenReportModal] = useState<boolean>(false);
  const [isOpenThumbnailSlider, setIsOpenThumbnailSlider] =
    useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [questionHeight, setQuestionHeight] = useState<number>();
  const [activeTextarea, setActiveTextarea] = useState<boolean>(false);

  const textRef = useRef(null);

  const handleShowButton = () => {
    if (textRef.current) {
      const { scrollHeight } = textRef.current;

      if (scrollHeight > 100) {
        setQuestionHeight(scrollHeight);
        setShowButton(false);
      }
    }
  };

  useEffect(() => {
    if (textRef.current) {
      const { scrollHeight } = textRef.current;

      if (scrollHeight > 100) {
        setShowButton(true);
        setQuestionHeight(100);
      } else {
        setQuestionHeight(scrollHeight);
      }
    }
  }, [value]);

  return (
    <Container>
      <Row justifyContent="space-between" style={{ width: '100%' }}>
        <Row gap="8px">
          <StarContainer>
            <img src={ICON_STAR_YELLOW} alt="icon star yellow" />
            {item.node.rating}점
          </StarContainer>
          {/* <QnaStatusButton status={ProductQnaState.NEW} /> */}
        </Row>
        <Text
          color="#46474c"
          weight={400}
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => {
            setIsOpenReportModal(true);
          }}
        >
          후기 신고하기
        </Text>
      </Row>
      <Column gap="8px" align="flex-start">
        <Row
          gap="4px"
          style={{ width: '100%', cursor: 'pointer' }}
          onClick={() => handleSelectedReviewItem(item)}
        >
          <Text
            color="#46474c"
            style={{
              width: '43px',
            }}
          >
            프립명:
          </Text>
          <Row
            gap="4px"
            justifyContent="space-between"
            style={{ width: '100%' }}
          >
            <ProductName>{item.node.target.product.title}</ProductName>
            <img
              src={ICON_ARROW_GRAY_BOLD}
              alt="arrow gray"
              style={{ cursor: 'pointer' }}
              onClick={() => handleSelectedReviewItem(item)}
            />
          </Row>
        </Row>
        <Row gap="4px">
          <Text color="#46474c">구매 옵션:</Text>
          <Text color="#70737C">{item.node.target.orderItem?.info.name}</Text>
        </Row>
        <Row gap="4px">
          <Text color="#46474c">크루명:</Text>
          <Text color="#70737C">{item.node.writer.nickname}</Text>
        </Row>
      </Column>
      <Divider />
      <Column gap="8px" align="flex-start">
        <Row gap="4px" justifyContent="space-between" style={{ width: '100%' }}>
          <Text color="#46474c">후기 내용</Text>
          <Text size="12px" weight={400}>
            {dayjs(item.node.createdAt).format('YYYY년 MM월 DD일 HH:mm')}
          </Text>
        </Row>
        <Row gap="4px">
          <QuestionBox ref={textRef} style={{ height: `${questionHeight}px` }}>
            {item.node.content}
          </QuestionBox>
        </Row>
        {showButton && (
          <Text
            color="#46474c"
            size="14px"
            weight={400}
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={handleShowButton}
          >
            후기 전체보기
          </Text>
        )}
      </Column>
      {!isEmpty(item.node.attachmentContents) && (
        <Row
          gap="8px"
          justifyContent="flex-start"
          style={{ width: '100%', overflowX: 'auto' }}
        >
          {item.node.attachmentContents.map((attachmentItem, idx) => {
            return (
              <ProductThumbnail
                src={attachmentItem.uri}
                alt="thumbnail"
                key={idx}
                onClick={() => setIsOpenThumbnailSlider(true)}
              />
            );
          })}
        </Row>
      )}
      <Row gap="4px">
        <Text color="#989BA2" size="12px" weight={400}>
          좋아요: {item.node.thumbsUpCount}
        </Text>
        <Text color="#989BA2" size="12px" weight={400}>
          |
        </Text>
        <Text color="#989BA2" size="12px" weight={400}>
          신고: {item.node.reportCount}
        </Text>
      </Row>
      <Divider />
      <Column gap="8px" align="flex-start">
        <Row gap="4px" justifyContent="space-between" style={{ width: '100%' }}>
          <Text color="#46474c">답변 내용</Text>
        </Row>
        <Column gap="12px" align="flex-end">
          <ReviewAnswerTextarea
            placeholder="문의에 대한 답변을 입력하세요"
            value={value}
            onChange={e => setValue(e.target.value)}
            disabled={
              !isEmpty(item.node.latestComments.edges) && !activeTextarea
            }
            style={{
              background: isEmpty(item.node.latestComments.edges)
                ? '#fff'
                : value === '' || activeTextarea
                ? '#FFF'
                : '#F7F7F8',
            }}
          />
          {isEmpty(item.node.latestComments.edges) ? (
            <UpdateButton
              onClick={() => {
                HackleManager.logReviewResponseComplete(
                  Number(item.node.id),
                  '저장하기',
                );
                handleWriteComment(item.node.id, value);
              }}
            >
              <Text color={value !== '' ? '#1b1c1e' : '#C2C4C8'}>저장하기</Text>
            </UpdateButton>
          ) : (
            <Row gap="8px">
              <UpdateButton
                onClick={() => {
                  HackleManager.logReviewResponseComplete(
                    Number(item.node.id),
                    '삭제하기',
                  );

                  handleDeleteComment(
                    item.node.latestComments.edges[0].node.id,
                  );

                  setValue('');
                }}
              >
                <Text color="#FF4242">삭제하기</Text>
              </UpdateButton>
              <UpdateButton
                onClick={() => {
                  if (activeTextarea) {
                    HackleManager.logReviewResponseComplete(
                      Number(item.node.id),
                      '수정하기',
                    );

                    handleModifyComment(
                      item.node.latestComments.edges[0].node.id,
                      value,
                    );
                    setActiveTextarea(false);

                    return;
                  }

                  setActiveTextarea(true);
                }}
              >
                <Text color="#1b1c1e">수정하기</Text>
              </UpdateButton>
            </Row>
          )}
        </Column>
      </Column>
      {isOpenReportModal && (
        <ReportModal
          isMobile={isMobile}
          reportItem={item}
          onClose={() => setIsOpenReportModal(false)}
        />
      )}
      {isOpenThumbnailSlider && (
        <ImageThumbnailSlider
          imageList={item.node.attachmentContents}
          onClose={() => setIsOpenThumbnailSlider(false)}
        />
      )}
    </Container>
  );
};

export default ReviewItem;
