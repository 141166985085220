import React from 'react';
import styled from 'styled-components';

import Row from 'components/common/Row';
import OptionContainer from './OptionContainer';
import Text from 'components/common/Text';
import { ScheduleParam } from 'containers/ProductRegist/hooks/types';
import { ONLY_NUMBER_REGEX } from 'utils/regExp';

const OptionInput = styled.input`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 27px 8px 12px;
  width: 100%;

  text-align: right;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    justify-content: flex-end;
  }

  &:focus {
    border: 1.5px solid #1b1c1e;
  }
`;

interface OptionRecruitPeopleIProps {
  scheduleItem: ScheduleParam;
  isDisable: boolean;
  handleChangeScheduleItem: (key: string, value: any) => void;
}

const OptionRecruitPeople: React.FC<OptionRecruitPeopleIProps> = props => {
  const { scheduleItem, isDisable, handleChangeScheduleItem } = props;

  const renderContents = (): JSX.Element => {
    if (isDisable) {
      return (
        <OptionContainer>
          <Text color="#1b1c1e" size="16px">
            모집인원
          </Text>
          <Row gap="8px" style={{ width: '100%' }}>
            <Row style={{ position: 'relative', width: '50%' }}>
              <Text
                size="14px"
                weight={400}
                style={{ position: 'absolute', left: '12px' }}
              >
                최소
              </Text>
              <OptionInput
                type="text"
                disabled
                style={{ color: '#c2c2c8' }}
                value={scheduleItem.minimumQuota}
                onChange={(e: any) => {
                  handleChangeScheduleItem(
                    'minimumQuota',
                    e.target.value.replaceAll(',', ''),
                  );
                }}
              />
              <Text
                size="14px"
                weight={400}
                style={{ position: 'absolute', right: '12px' }}
              >
                명
              </Text>
            </Row>
            <Row style={{ position: 'relative', width: '50%' }}>
              <Text
                size="14px"
                weight={400}
                style={{ position: 'absolute', left: '12px' }}
              >
                최대
              </Text>
              <OptionInput
                type="text"
                value={scheduleItem.quota}
                disabled
                style={{ color: '#c2c2c8' }}
                onChange={(e: any) => {
                  handleChangeScheduleItem(
                    'quota',
                    e.target.value.replaceAll(',', ''),
                  );
                }}
              />
              <Text
                size="14px"
                weight={400}
                style={{ position: 'absolute', right: '12px' }}
              >
                명
              </Text>
            </Row>
          </Row>
        </OptionContainer>
      );
    }

    return (
      <OptionContainer>
        <Text color="#1b1c1e" size="16px">
          모집인원
        </Text>
        <Row gap="8px" style={{ width: '100%' }}>
          <Row style={{ position: 'relative', width: '50%' }}>
            <Text
              color="#1b1c1e"
              size="14px"
              weight={400}
              style={{ position: 'absolute', left: '12px' }}
            >
              최소
            </Text>
            <OptionInput
              type="text"
              value={scheduleItem.minimumQuota}
              onChange={(e: any) => {
                handleChangeScheduleItem(
                  'minimumQuota',
                  Number(e.target.value.replaceAll(ONLY_NUMBER_REGEX, '')),
                );
              }}
            />
            <Text
              color="#1b1c1e"
              size="14px"
              weight={400}
              style={{ position: 'absolute', right: '12px' }}
            >
              명
            </Text>
          </Row>
          <Row style={{ position: 'relative', width: '50%' }}>
            <Text
              color="#1b1c1e"
              size="14px"
              weight={400}
              style={{ position: 'absolute', left: '12px' }}
            >
              최대
            </Text>
            <OptionInput
              type="text"
              value={scheduleItem.quota}
              onChange={(e: any) => {
                handleChangeScheduleItem(
                  'quota',
                  Number(e.target.value.replaceAll(ONLY_NUMBER_REGEX, '')),
                );
              }}
            />
            <Text
              color="#1b1c1e"
              size="14px"
              weight={400}
              style={{ position: 'absolute', right: '12px' }}
            >
              명
            </Text>
          </Row>
        </Row>
      </OptionContainer>
    );
  };

  return renderContents();
};

export default OptionRecruitPeople;
