import React, { useState } from 'react';
import styled from 'styled-components';
import Column from 'components/common/Column';
import Text from 'components/common/Text';
import Divider from 'components/Divider';
import Row from 'components/common/Row';
import Textarea from 'containers/ProductRegist/components/common/Textarea';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import { Booking, BookingItem, OrderItem } from 'types/api';
import HackleManager from 'utils/hackle';
import { useParams } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px 20px 64px;
`;

const RadioBox = styled.input`
  width: 24px;
  height: 24px;
  appearance: none;
  border: 3px solid #eee;
  border-radius: 50%;
  cursor: pointer;

  &:checked {
    border: 8px solid #1b1c1e;
  }
`;

interface CancelOrderContainerIProps {
  isMobile: boolean;
  orderItems: OrderItem;
  bookingItem: BookingItem;
  handleRefetchOrderItem: () => void;
  handleRequestCancelOrder: (param: any, callback: () => void) => void;
  onClose: () => void;
}

const CancelOrderContainer: React.FC<CancelOrderContainerIProps> = props => {
  const {
    isMobile,
    orderItems,
    bookingItem,
    handleRefetchOrderItem,
    handleRequestCancelOrder,
    onClose,
  } = props;

  const [cancelOrderParam, setCancelOrderParam] = useState({
    orderId: bookingItem.booking.order.id,
    items: [
      {
        orderItemId: orderItems.id,
        quantity: -1,
        indices: [bookingItem.indexNo],
      },
    ],
    forced: false,
    manualRefund: false,
    kind: 'USER',
    fullyCancel: false,
    reason: '',
    refunds: [],
    refundAccount: null,
  });

  const handleSetCancelOrderParam = (key: string, value: any) => {
    setCancelOrderParam({ ...cancelOrderParam, [key]: value });
  };

  return (
    <ModalContainer
      isAbled={
        cancelOrderParam.kind === 'USER' ||
        (cancelOrderParam.kind === 'HOST' && cancelOrderParam.reason !== '')
      }
      isMobile={isMobile}
      onAcceptText="취소 요청하기"
      onClose={() => {
        onClose();
      }}
      onClick={() => {
        handleRequestCancelOrder(cancelOrderParam, () => {
          HackleManager.logSalesCancelRequestComplete(
            Number(orderItems.booking.order.id),
            orderItems.booking.order.customer.id,
            [bookingItem.booking.id],
            orderItems.booking.tickets.length,
            cancelOrderParam.kind === 'USER' ? '고객변심' : '호스트사정',
            cancelOrderParam.reason,
            orderItems.info.origin.product.frip.attachedToSchedule
              ? '일정지정형'
              : '일정조율형',
            '판매상세',
          );

          handleRefetchOrderItem();

          onClose();
        });
      }}
    >
      <Column style={{ paddingTop: '16px' }}>
        <Text color="#1b1c1e" size="16px" weight={600}>
          주문 취소 요청
        </Text>
      </Column>
      <Container>
        <Column gap="8px" align="flex-start">
          <Text color="#1b1c1e" size="20px" weight={600}>
            주문 취소는 관리자의 확인 후 취소가 완료됩니다.
          </Text>
          <Text color="#1B1C1E" size="16px" weight={400}>
            취소 사유 선택 후 상세 사유를 입력해 주세요.
          </Text>
        </Column>
        <Divider />
        <Column gap="12px" align="flex-start">
          <Text color="#1b1c1e" size="16px">
            주문 취소 사유
          </Text>
          <Row gap="16px">
            <Row
              gap="8px"
              onClick={() => handleSetCancelOrderParam('kind', 'USER')}
            >
              <RadioBox
                type="radio"
                checked={cancelOrderParam.kind === 'USER'}
              />
              <Text color={cancelOrderParam.kind === 'USER' && '#1b1c1e'}>
                고객 변심
              </Text>
            </Row>
            <Row
              gap="8px"
              onClick={() => handleSetCancelOrderParam('kind', 'HOST')}
            >
              <RadioBox
                type="radio"
                checked={cancelOrderParam.kind === 'HOST'}
              />
              <Text color={cancelOrderParam.kind === 'HOST' && '#1b1c1e'}>
                호스트 사정
              </Text>
            </Row>
          </Row>
        </Column>
        {cancelOrderParam.kind === 'HOST' && (
          <React.Fragment>
            <Divider />
            <Column gap="12px" align="flex-start">
              <Text color="#1b1c1e" size="16px">
                상세 사유
              </Text>
              <Textarea
                target="reason"
                value={cancelOrderParam.reason}
                placeholder="자세한 사유를 입력해 주세요"
                onChange={handleSetCancelOrderParam}
                style={{ minHeight: '110px' }}
              />
            </Column>
          </React.Fragment>
        )}
      </Container>
    </ModalContainer>
  );
};

export default CancelOrderContainer;
