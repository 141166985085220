export interface HostVerifiedBusinessParamInput {
  address: string;
  businessItem: string;
  businessType: string;
  foreign: boolean;
  representativeName: string;
  taxPayerName: string;
  taxPayerType: TaxPayerType | string;
}

export enum TaxPayerType {
  CORPORATION = "CORPORATION",
  INDIVIDUAL = "INDIVIDUAL",
}
