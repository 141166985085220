import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import {
  ICON_CATEGORY_SEARCH,
  ICON_CATEGORY_SEARCH_ACTIVE,
  ICON_CLOSE,
} from 'assets/icons';
import Column from 'components/common/Column';
import { QnaTermWithType, useQnaFilterHooks } from '../hooks/useQnaFilterHooks';
import { TermWithType } from 'containers/SaleList/hooks/useSaleListHooks';
import { QnaFilter } from '../hooks/useQnaListHooks';
import { isEmpty } from 'lodash';

const SearchInputContainer = styled.div`
  display: flex;
  gap: 8px;
  padding: 12px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: var(--Static-White, #fff);
`;

const SearchInput = styled.textarea`
  width: 100%;
  border: none;

  color: var(--gray-gray-09, #1b1c1e);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;

  ::placeholder {
    color: #989ba2;
  }
`;

const RegistDateSelectButton = styled.button<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  border-radius: 8px;
  border: ${props =>
    props.$isSelected ? '1.5px solid #1b1c1e' : '1px solid #DBDCDF'};
  background: ${props => (props.$isSelected ? '#f7f7f8' : '#fff')};

  color: var(--gray-gray-09, #1b1c1e);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
`;

interface QnaFilterBottomSheetIProps {
  isMobile: boolean;
  qnaFilter: QnaFilter;
  searchValue: string;
  termWithType: QnaTermWithType;
  termType: string;
  statusIn: string;
  setStatusIn: Dispatch<SetStateAction<string>>;
  setTermType: Dispatch<SetStateAction<string>>;
  handleSetTermWithType: (type: string) => void;
  handlerSetSearchValue: (e: any) => void;
  handleResetFilterInput: () => void;
  onClose: () => void;
  handleAcceptFilter: (
    searchValue: string,
    statusIn: string,
    term: {
      startedAt: number;
      endedAt: number;
      type: string;
    },
  ) => void;
}

const QnaFilterBottomSheet: React.FC<QnaFilterBottomSheetIProps> = props => {
  const {
    isMobile,
    qnaFilter,
    searchValue,
    termWithType,
    termType,
    statusIn,
    setStatusIn,
    setTermType,
    handleSetTermWithType,
    handlerSetSearchValue,
    handleResetFilterInput,
    onClose,
    handleAcceptFilter,
  } = props;

  const renderProductStatus = (): JSX.Element => {
    const handleSetTermType = (type: string) => {
      handleSetTermWithType(type);

      setTermType(type);
    };

    return (
      <Column gap="12px" align="flex-start">
        <Text color="#1b1c1e" size="16px">
          상품 등록일
        </Text>
        <Row
          gap="12px"
          justifyContent="flex-start"
          style={{ width: '100%', flexWrap: 'wrap' }}
        >
          <RegistDateSelectButton
            $isSelected={termType === 'all'}
            onClick={() => {
              handleSetTermType('all');
            }}
          >
            전체
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={termType === '1month'}
            onClick={() => {
              handleSetTermType('1month');
            }}
          >
            최근 1개월
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={termType === '6month'}
            onClick={() => {
              handleSetTermType('6month');
            }}
          >
            최근 6개월
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={termType === '1year'}
            onClick={() => {
              handleSetTermType('1year');
            }}
          >
            최근 1년
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={termType === '5year'}
            onClick={() => {
              handleSetTermType('5year');
            }}
          >
            최근 5년
          </RegistDateSelectButton>
        </Row>
      </Column>
    );
  };

  const renderQnaStatus = (): JSX.Element => {
    return (
      <Column gap="12px" align="flex-start">
        <Text color="#1b1c1e" size="16px">
          상품 상태
        </Text>
        <Row
          gap="12px"
          justifyContent="flex-start"
          style={{ width: '100%', flexWrap: 'wrap' }}
        >
          <RegistDateSelectButton
            $isSelected={statusIn === 'ALL'}
            onClick={() => {
              setStatusIn('ALL');
            }}
          >
            전체
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={statusIn === 'NEW'}
            onClick={() => {
              setStatusIn('NEW');
            }}
          >
            미답변
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={statusIn === 'COMPLETE'}
            onClick={() => {
              setStatusIn('COMPLETE');
            }}
          >
            답변
          </RegistDateSelectButton>
        </Row>
      </Column>
    );
  };

  return (
    <ModalContainer
      isAbled={true}
      isMobile={isMobile}
      onCloseText="초기화"
      onAcceptText="적용"
      onClose={() => {
        setTermType('all');
        handleResetFilterInput();
        setStatusIn('ALL');
        // handleAcceptFilter(searchValue, statusIn, termWithType);
      }}
      onClick={() => {
        handleAcceptFilter(searchValue, statusIn, termWithType);

        onClose();
      }}
      style={{ maxWidth: '600px' }}
    >
      <Column gap="24px" style={{ padding: '16px 20px' }}>
        <Row style={{ position: 'relative', width: '100%' }}>
          <img
            src={ICON_CLOSE}
            alt="close icon"
            style={{ position: 'absolute', left: 0, cursor: 'pointer' }}
            onClick={() => {
              onClose();

              // handleAcceptFilter(searchValue, statusIn, termWithType);
              // setTermType('all');
              // handleResetFilterInput();
              // handleAcceptFilter(searchValue, termWithType);
            }}
          />
          <Text color="#000" size="16px" weight={600}>
            필터
          </Text>
        </Row>
        <Column gap="12px" align="flex-start">
          <Text color="#1b1c1e" size="16px">
            검색어
          </Text>
          <SearchInputContainer>
            <img
              src={
                searchValue !== ''
                  ? ICON_CATEGORY_SEARCH_ACTIVE
                  : ICON_CATEGORY_SEARCH
              }
              alt="search icon"
            />
            <SearchInput
              placeholder="프립명을 입력해 주세요"
              value={searchValue}
              onChange={handlerSetSearchValue}
              rows={1}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            />
          </SearchInputContainer>
        </Column>
        {renderProductStatus()}
        {renderQnaStatus()}
      </Column>
    </ModalContainer>
  );
};

export default QnaFilterBottomSheet;
