import { PRODUCT_LIST_PATH } from 'constants/path';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  CurriculumKind,
  CurriculumStyle,
  FripKind,
  InventoryTargetType,
  LevelOfDifficulty,
  Product,
  ProductKind,
} from 'types/api';
import { ConvertingProductParamInput } from 'utils/ConvertingProductParamInput';
import { isWebview } from 'utils/webview';
import { useQueryGetInquiryTemplate } from '../components/InformationRegist/graphQL/hooks/useQueryGetInquiryTemplate';
import { useMutationProductItems } from '../graphQL/hooks/useMutationProductItems';
import { useMutationRequestInspection } from '../graphQL/hooks/useMutationRequestInspection';
import { useMutationSaveProductRefundPolicy } from '../graphQL/hooks/useMutationSaveProductRefundPolicy';
import { useQueryGetProductScheduleTerm } from '../graphQL/hooks/useQueryGetProductScheduleTerm';
import { useQueryProduct } from '../graphQL/hooks/useQueryProduct';
import { ProductParamInput } from './types';

export interface RegistPageNumber {
  page: number;
  basicPageNumber: { page: number };
  optionPageNumber: { page: number };
  informationPageNumber: { page: number };
}

export const useProductRegistHooks = () => {
  const location = useLocation();
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const {
    inquiryList,
    handelGetInquiryTemplate,
    handleRefetchInquiryTemplate,
  } = useQueryGetInquiryTemplate();

  const { data, handleGetProduct } = useQueryProduct();
  const { handleCreateProduct, handleSaveProduct } = useMutationProductItems();
  const { isOpenModal, errorMessage, handleRequestInspection } =
    useMutationRequestInspection();
  const { scheduleList } = useQueryGetProductScheduleTerm();
  const { handleSaveProductRefundPolicy } =
    useMutationSaveProductRefundPolicy();

  const convertingProductParamInput = new ConvertingProductParamInput();

  const [registPageNumber, setRegistPageNumber] = useState<RegistPageNumber>({
    page: 1,
    basicPageNumber: { page: 0 },
    optionPageNumber: { page: 0 },
    informationPageNumber: { page: 0 },
  });
  const [isShowCompleteView, setIsShowCompleteView] = useState<boolean>(false);
  const [productParamInput, setProductParamInput] = useState<ProductParamInput>(
    {
      kind: null,
      attributeIds: [],
      catchphrase: '',
      categories: [],
      title: '',
      standardCategoryId: '',
      inventoryTargetType: InventoryTargetType.BY_SCHEDULE,
      items: [],
      grossPurchaseLimitation: { count: 0 },
      notice:
        '[신청 시 유의사항]\n· 구매시 호스트 연락처를 카톡 혹은 문자로 보내드립니다.\n· 호스트 연락처로 진행 가능한 날짜 예약 바랍니다.\n· 예약 확정 시 환불이 불가합니다.\n· 예약 시간에 맞추어 늦지 않게 도착해주시기 바랍니다.\n',
      keywords: [],
      htmlDetailContent: '',
      headerContents: [],
      cancelingRestricted: false,
      exposedChannelIds: ['category', 'recommend', 'search'],
      externalProvisionIds: [],
      inventoryTargetOptionLevel: 0,
      maximumPurchasableCount: 0,
      inquiryTemplateId: null,
      options: [],
      tagIds: [],
      underageRestricted: false,
      frip: {
        curriculum: {
          style: CurriculumStyle.NONE,
          sections: [],
          kind: CurriculumKind.SCHEDULE,
        },
        schedules: [],
        plans: [],
        locationOfGathering: null,
        locationsOfVenue: [],
        inclusions: [],
        exclusions: [],
        stuffsToPrepare: [],
        kind: FripKind.COMMON,
        interactionType: null,
        companions: [],
        attachedToSchedule: true,
        bookingConfirmationEnabled: false,
        daysOfExpiration: 90,
        difficulty: LevelOfDifficulty.VERY_EASY,
        recommendedAge: 0,
        schedulingTerm: {
          startedAt: 0,
          endedAt: 0,
        },
        isIndoor: false,
        isOutdoor: false,
      },
    },
  );
  const [imageThumbnail, setImageThumbnail] = useState<string[]>([]);
  const [isOpenGuideModal, setIsOpenGuideModal] = useState<boolean>(false);

  const handleOnChangeProductParamInput = (key: string, value: any) => {
    setProductParamInput({ ...productParamInput, [key]: value });
  };

  const handleProductTemporarySave = () => {
    const convertProductParamInput = new ConvertingProductParamInput();

    const convertProductParam = convertProductParamInput.convertProductParam(
      productParamInput,
      scheduleList,
    );

    if (location.state?.isCopy) {
      handleCreateProduct(convertProductParam, (productId: string) => {
        handleRequestInspection(productId);
        navigate(PRODUCT_LIST_PATH);
      });

      return;
    }

    if (params.id === 'draft') {
      handleCreateProduct(convertProductParam, (id: string) => {
        handleSaveProductRefundPolicy(id);
        navigate(PRODUCT_LIST_PATH);
      });

      return;
    }

    handleSaveProduct(convertProductParam, undefined, (id: string) => {
      handleSaveProductRefundPolicy(id);
      navigate(PRODUCT_LIST_PATH);
    });
  };

  const isVaildCheckBasicPage = (): boolean => {
    if (registPageNumber.page === 1) {
      switch (registPageNumber.basicPageNumber.page) {
        case 1: {
          if (
            productParamInput.attributeIds.length > 0 ||
            (productParamInput.attributeIds.length > 0 &&
              productParamInput.kind !== null) ||
            (productParamInput.kind === ProductKind.GOODS &&
              productParamInput.kind !== null)
          ) {
            return true;
          }

          return false;
        }
        case 2: {
          if (productParamInput.kind !== null) {
            if (productParamInput.kind === ProductKind.GOODS) {
              if (productParamInput.attributeIds.length > 0) {
                return true;
              }

              return false;
            }

            return true;
          }

          return false;
        }
        case 3: {
          if (productParamInput.kind === ProductKind.ONLINE) {
            if (productParamInput.frip.interactionType !== null) {
              return true;
            }

            return false;
          }

          if (!isEmpty(productParamInput.frip?.locationsOfVenue)) {
            return true;
          }

          return false;
        }
        case 4: {
          if (productParamInput.standardCategoryId !== '') {
            return true;
          }

          return false;
        }
        default:
          return true;
      }
    }

    if (registPageNumber.page === 2) {
      switch (registPageNumber.optionPageNumber.page) {
        case 1: {
          if (productParamInput.frip.attachedToSchedule !== null) {
            return true;
          }

          return false;
        }
        case 2: {
          const filterItem = productParamInput.items.filter(
            item => item.name !== '' && item.price.sale !== 0,
          );

          return filterItem.length === productParamInput.items.length;
        }
        case 3: {
          if (
            !isEmpty(productParamInput.frip.schedules) ||
            !isEmpty(productParamInput.frip.plans)
          ) {
            return true;
          }

          return false;
        }
        case 4: {
          if (productParamInput.saleTerm) {
            return true;
          }

          return false;
        }
        default:
          return true;
      }
    }

    if (registPageNumber.page === 3) {
      switch (registPageNumber.informationPageNumber.page) {
        case 1: {
          if (imageThumbnail.length > 0 && productParamInput.title !== '') {
            return true;
          }

          return false;
        }
        case 2: {
          if (productParamInput.htmlDetailContent !== '') {
            return true;
          }

          return false;
        }
        case 3: {
          if (
            productParamInput.frip.difficulty !== null &&
            productParamInput.frip.recommendedAge !== null &&
            !isEmpty(productParamInput.frip.companions) &&
            (productParamInput.frip.isIndoor ||
              productParamInput.frip.isOutdoor)
          ) {
            return true;
          }

          return false;
        }
        case 6: {
          const curriculum = productParamInput.frip.curriculum;

          if (curriculum.style === CurriculumStyle.NONE) {
            return true;
          }

          switch (curriculum.style) {
            case CurriculumStyle.DURATION: {
              return curriculum.sections.every(
                item =>
                  ((item.isOpenEtc && item.etc !== '') || !item.isOpenEtc) &&
                  item.title !== '' &&
                  item.items.every(
                    scheduleItem => scheduleItem.content !== '',
                    // scheduleItem.duration !== 0,
                  ),
              );
            }
            case CurriculumStyle.TIMETABLE: {
              return curriculum.sections.every(
                item =>
                  ((item.isOpenEtc && item.etc !== '') || !item.isOpenEtc) &&
                  item.title !== '' &&
                  item.items.every(
                    scheduleItem => scheduleItem.content !== '',
                    // scheduleItem.term.startingTime.hour !== 0 &&
                    // scheduleItem.term.startingTime.minute !== 0 &&
                    // scheduleItem.term.endingTime.hour !== 0 &&
                    // scheduleItem.term.endingTime.hour !== 0,
                  ),
              );
            }
            case CurriculumStyle.LEGACY: {
              return curriculum.sections.every(
                item =>
                  ((item.isOpenEtc && item.etc !== '') || !item.isOpenEtc) &&
                  item.title !== '' &&
                  item.items.every(
                    scheduleItem =>
                      scheduleItem.textTime !== '' &&
                      scheduleItem.content !== '',
                  ),
              );
            }
            default:
              return false;
          }
        }

        default:
          return true;
      }
    }
  };

  const handleNavigateBackwardPage = () => {
    switch (registPageNumber.page) {
      case 1: {
        if (isWebview() && registPageNumber.basicPageNumber.page === 0) {
          navigate(-1);
        }

        if (
          registPageNumber.basicPageNumber.page === 4 &&
          productParamInput.kind === ProductKind.GOODS
        ) {
          setRegistPageNumber({
            ...registPageNumber,
            basicPageNumber: {
              page: registPageNumber.basicPageNumber.page - 2,
            },
          });

          return;
        }

        setRegistPageNumber({
          ...registPageNumber,
          basicPageNumber: {
            page: registPageNumber.basicPageNumber.page - 1,
          },
        });

        return;
      }
      case 2: {
        if (registPageNumber.optionPageNumber.page === 0) {
          setRegistPageNumber({
            ...registPageNumber,
            page: registPageNumber.page - 1,
            basicPageNumber: {
              page: registPageNumber.basicPageNumber.page - 1,
            },
          });

          return;
        }

        if (
          registPageNumber.optionPageNumber.page === 2 &&
          productParamInput.kind === ProductKind.GOODS
        ) {
          setRegistPageNumber({
            ...registPageNumber,
            optionPageNumber: {
              page: registPageNumber.optionPageNumber.page - 2,
            },
          });

          return;
        }

        if (
          registPageNumber.optionPageNumber.page === 4 &&
          !productParamInput.frip!.attachedToSchedule
        ) {
          setRegistPageNumber({
            ...registPageNumber,
            optionPageNumber: {
              page: registPageNumber.optionPageNumber.page - 2,
            },
          });

          return;
        }

        setRegistPageNumber({
          ...registPageNumber,
          optionPageNumber: {
            page: registPageNumber.optionPageNumber.page - 1,
          },
        });

        return;
      }
      case 3: {
        if (registPageNumber.informationPageNumber.page === 0) {
          setRegistPageNumber({
            ...registPageNumber,
            page: registPageNumber.page - 1,
            optionPageNumber: {
              page: registPageNumber.optionPageNumber.page - 1,
            },
          });

          return;
        }

        setRegistPageNumber({
          ...registPageNumber,
          informationPageNumber: {
            page: registPageNumber.informationPageNumber.page - 1,
          },
        });

        return;
      }
      default:
        return;
    }
  };

  const handleNavigatePage = () => {
    switch (registPageNumber.page) {
      case 1: {
        if (registPageNumber.basicPageNumber.page === 4) {
          setRegistPageNumber({
            ...registPageNumber,
            page: registPageNumber.page + 1,
            basicPageNumber: {
              page: registPageNumber.basicPageNumber.page + 1,
            },
          });

          return;
        }

        if (
          registPageNumber.basicPageNumber.page === 2 &&
          productParamInput.kind === ProductKind.GOODS
        ) {
          setRegistPageNumber({
            ...registPageNumber,
            basicPageNumber: {
              page: registPageNumber.basicPageNumber.page + 2,
            },
          });

          return;
        }

        setRegistPageNumber({
          ...registPageNumber,
          basicPageNumber: {
            page: registPageNumber.basicPageNumber.page + 1,
          },
        });

        return;
      }
      case 2: {
        if (
          registPageNumber.optionPageNumber.page === 0 &&
          productParamInput.kind === ProductKind.GOODS
        ) {
          setRegistPageNumber({
            ...registPageNumber,
            optionPageNumber: {
              page: registPageNumber.optionPageNumber.page + 2,
            },
          });

          setProductParamInput({
            ...productParamInput,
            frip: { ...productParamInput.frip, attachedToSchedule: false },
          });

          return;
        }

        if (registPageNumber.optionPageNumber.page === 2) {
          if (
            productParamInput.frip.attachedToSchedule &&
            productParamInput.inventoryTargetType ===
              InventoryTargetType.BY_ITEM
          ) {
            if (
              productParamInput.items.some(
                item => item.minimumQuota > item.quota,
              )
            ) {
              window.showToast(
                '최대 인원은 최소 인원보다 적을 수 없습니다. 최소 인원보다 높은 인원을 선택해주세요.',
                'failed',
              );

              return;
            }

            if (productParamInput.items.some(item => item.quota === 0)) {
              window.showToast('최대인원은 0명으로 설정할 수 없어요', 'failed');

              return;
            }
          }

          if (!productParamInput.items.some(item => item.representative)) {
            window.showToast('대표 옵션을 설정해주세요.', 'failed');

            return;
          }
        }

        if (
          registPageNumber.optionPageNumber.page === 2 &&
          !productParamInput.frip!.attachedToSchedule
        ) {
          setRegistPageNumber({
            ...registPageNumber,
            optionPageNumber: {
              page: registPageNumber.optionPageNumber.page + 2,
            },
          });

          return;
        }

        if (registPageNumber.optionPageNumber.page === 4) {
          setRegistPageNumber({
            ...registPageNumber,
            page: registPageNumber.page + 1,
            optionPageNumber: {
              page: registPageNumber.optionPageNumber.page + 1,
            },
          });

          return;
        }

        setRegistPageNumber({
          ...registPageNumber,
          optionPageNumber: {
            page: registPageNumber.optionPageNumber.page + 1,
          },
        });

        return;
      }
      case 3: {
        if (registPageNumber.informationPageNumber.page === 7) {
          const convertParam = convertingProductParamInput.convertProductParam(
            productParamInput,
            scheduleList,
          );

          if (productParamInput.id) {
            handleSaveProduct(convertParam, undefined, () => {
              setIsShowCompleteView(true);
            });

            return;
          }

          handleCreateProduct(convertParam, (productId: string) => {
            handleOnChangeProductParamInput('id', productId);
            setIsShowCompleteView(true);
          });

          return;
        }
        setRegistPageNumber({
          ...registPageNumber,
          informationPageNumber: {
            page: registPageNumber.informationPageNumber.page + 1,
          },
        });

        return;
      }
      default:
        return;
    }
  };

  const handleOpenGuideModal = (isOpen: boolean) => {
    setIsOpenGuideModal(isOpen);
  };

  useEffect(() => {
    if (params.id !== 'draft') {
      handleGetProduct(params.id);
    }
  }, []);

  useEffect(() => {
    if (params.id !== 'draft' && data) {
      const productInfo = data.product.product;

      const paramInput =
        convertingProductParamInput.convertRegistProductParamInput(
          productInfo,
          scheduleList,
        );

      if (paramInput.inquiryTemplateId !== null) {
        handelGetInquiryTemplate(paramInput.inquiryTemplateId);
      }
      setProductParamInput(paramInput);

      setImageThumbnail(
        productInfo.headerContents.map(item => item.content.uri),
      );
    }
  }, [data]);

  useEffect(() => {
    window.handlePageBackwardNavigate = handleNavigateBackwardPage;
  }, [registPageNumber]);

  return {
    registPageNumber,
    productParamInput,
    imageThumbnail,
    isShowCompleteView,
    inquiryList,
    scheduleList,
    isOpenGuideModal,
    isOpenModal,
    errorMessage,
    setIsShowCompleteView,
    setRegistPageNumber,
    setProductParamInput,
    setImageThumbnail,
    handleOnChangeProductParamInput,
    handleProductTemporarySave,
    isVaildCheckBasicPage,
    handleNavigateBackwardPage,
    handleNavigatePage,
    handleCreateProduct,
    handleSaveProduct,
    handleRequestInspection,
    handelGetInquiryTemplate,
    handleRefetchInquiryTemplate,
    handleOpenGuideModal,
  };
};
