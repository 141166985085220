import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { HostVerifiedBusinessParamInput, TaxPayerType } from '../graphQL/type';
import { ICON_SETTLEMENT_IMAGE_UPLOAD, ICON_CLOSE } from 'assets/icons';
import {
  WebviewOperatorType,
  callWebviewHandler,
  isWebview,
} from 'utils/webview';
import Text from 'components/common/Text';
import CommonDialog from 'components/Modal/CommonDialog';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  gap: 12px;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  position: relative;
  padding-top: 20px;
`;

const UploadImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  position: relative;
  padding: 61px;
  width: 100%;

  border-radius: 8px;
  border: 1.5px dashed #c2c4c8;
  background: #f7f7f8;

  color: #c2c4c8;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 18.2px */
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 30px;
  border-radius: 12px;
  border: 1px solid #eaebec;
  background: #eee;
`;

const IdentityImage = styled.img`
  width: 100%;
`;

const DeleteButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5%;
  right: 5%;
  padding: 8px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid #c2c4c8;
  opacity: 0.8;
  background: #fff;
`;

interface RegistIdentityIProps {
  businessInformation: HostVerifiedBusinessParamInput;
  previewImage: any;
  handleGetImageUrl: (file: File) => void;
  setPreviewImage: React.Dispatch<any>;
}

const RegistIdentity: React.FC<RegistIdentityIProps> = props => {
  const {
    businessInformation,
    previewImage,
    handleGetImageUrl,
    setPreviewImage,
  } = props;

  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = (link: { link: string }) => {
    const file = new File(
      [Uint8Array.from(atob(link.link), m => m.codePointAt(0) as any)],
      'filename.jpg',
      { type: 'image/jpg' },
    );

    const reader = new FileReader();

    reader.onloadend = e => {
      setPreviewImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }

    handleGetImageUrl(file);
  };

  const handleClick = () => {
    if (isWebview()) {
      callWebviewHandler(
        WebviewOperatorType.UploadSettlementIdentityHandler,
        undefined,
        'handleSettlementIdentityUpload',
      );

      return;
    }

    if (fileInputRef.current) fileInputRef.current.click();
  };

  const handleUploadImageFile = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = e => {
        setPreviewImage(reader.result);
      };
      if (file) {
        reader.readAsDataURL(file);
      }

      handleGetImageUrl(file);
    }
  };

  useEffect(() => {
    window.handleSettlementIdentityUpload = handleImageUpload;
  }, []);

  const renderTitleItem = () => {
    switch (businessInformation.taxPayerType) {
      case TaxPayerType.INDIVIDUAL: {
        if (businessInformation.foreign) {
          return (
            <React.Fragment>
              <Text color="#1b1c1e" size="24px">
                국내거소신고증 사진 추가하기
              </Text>
              <Text color="#70737c" size="16px" weight={400}>
                내용 전체를 확인할 수 있는 국내거소신고증을 업로드해 주세요.
              </Text>
            </React.Fragment>
          );
        }

        return (
          <React.Fragment>
            <Text color="#1b1c1e" size="24px">
              신분증/운전면허증 사진 추가하기
            </Text>
            <Text color="#70737c" size="16px" weight={400}>
              주민등록번호 전체를 확인할 수 있는 주민등록증 혹은 운전면허증을
              업로드해 주세요.(여권 불가능)
            </Text>
          </React.Fragment>
        );
      }
      case TaxPayerType.CORPORATION: {
        return (
          <React.Fragment>
            <Text color="#1b1c1e" size="24px">
              사업자 등록증 사진 추가하기
            </Text>
            <Text color="#70737c" size="16px" weight={400}>
              내용 전체를 확인할 수 있는 사업자 등록증을 업로드해 주세요.
            </Text>
          </React.Fragment>
        );
      }
      default:
        return <></>;
    }
  };

  return (
    <React.Fragment>
      <Container>
        {renderTitleItem()}
        <Column>
          {previewImage === '' ? (
            <UploadImageContainer onClick={handleClick}>
              <img
                src={ICON_SETTLEMENT_IMAGE_UPLOAD}
                alt="calculate image upload"
              />
              <span>
                {businessInformation.taxPayerType === TaxPayerType.CORPORATION
                  ? '사업자 등록증 추가'
                  : businessInformation.foreign
                  ? '국내거소신고증 추가'
                  : '신분증/운전 면허증 사진 추가'}
              </span>
              <input
                ref={fileInputRef}
                name="file"
                type="file"
                accept=".jpg, .png"
                style={{
                  display: 'none',
                  position: 'absolute',
                  width: '100%',
                  height: '150px',
                  border: '1px solid red',
                }}
                onChange={event => handleUploadImageFile(event)}
              />
            </UploadImageContainer>
          ) : (
            <ImageContainer>
              <IdentityImage src={previewImage} alt="thumbnail image" />
              <DeleteButton
                onClick={() => {
                  setIsOpenDeleteDialog(true);
                }}
              >
                <img
                  src={ICON_CLOSE}
                  alt="close icon"
                  style={{ width: '16px' }}
                />
              </DeleteButton>
            </ImageContainer>
          )}
          <Text color="#70737c" size="12px" weight={400}>
            JPG,PNG 이미지파일을 업로드 해주세요.
          </Text>
        </Column>
      </Container>
      {isOpenDeleteDialog && (
        <CommonDialog
          title="추가한 사진을 삭제할까요?"
          onClose={() => {
            setIsOpenDeleteDialog(false);
          }}
          onAcceptText="네,삭제할게요"
          onAccept={() => {
            setPreviewImage('');
            setIsOpenDeleteDialog(false);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default RegistIdentity;
