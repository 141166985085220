import React, { useEffect } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import Text from 'components/common/Text';
import HeaderContents from './components/HeaderContents';
import QnaItem from './components/QnaItem';
import { useQnaListHooks } from './hooks/useQnaListHooks';
import QnaItemBottomSheet from './components/QnaItemBottomSheet';
import Divider from 'components/Divider';
import QnaFilterBottomSheet from './components/QnaFilterBottomSheet';
import TotalCountContainer from './components/TotalCountContainer';
import PaginataionContainer from './components/PaginataionContainer';
import Column from 'components/common/Column';
import { useQnaFilterHooks } from './hooks/useQnaFilterHooks';
import { useResizeDetector } from 'react-resize-detector';
import Loading from 'components/Loading/Loading';
import dayjs from 'dayjs';
import HackleManager from 'utils/hackle';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 0 0 90px;
  width: 100%;
  max-width: 768px;
`;

const QnAListPage = () => {
  const {
    productId,
    isOpenCountFilter,
    qnaList,
    selectedQnaItem,
    isOpenQnaBottomSheet,
    isOpenQnaFilterBottomSheet,
    qnaFilter,
    // qnaCount,
    handleSetQnaFilter,
    setIsOpenQnaFilterBottomSheet,
    setIsOpenQnaBottomSheet,
    handleSelectedQnaItem,
    handleIsOpenCountFilter,
    handleSetProductQnaItem,
    handleAcceptFilter,
    handleReFetechList,
  } = useQnaListHooks();

  const {
    searchValue,
    termWithType,
    termType,
    statusIn,
    setStatusIn,
    setTermType,
    handleSetTermWithType,
    handlerSetSearchValue,
    handleResetFilterInput,
  } = useQnaFilterHooks();

  const { width, ref } = useResizeDetector();

  const isMobile = width ? width < 728 : window.innerWidth < 728;

  useEffect(() => {
    if (qnaList) {
      HackleManager.logQnaView(
        qnaList.totalCount,
        statusIn === 'ALL' ? '전체' : statusIn === 'NEW' ? '미답변' : '답변',
      );
    }
  }, [qnaList]);

  return (
    <Container ref={ref}>
      {qnaList ? (
        <React.Fragment>
          <HeaderContents
            isMobile={isMobile}
            type="qna"
            // itemCount={qnaCount}
            qnaFilter={qnaFilter}
            isAcceptFilter={
              searchValue !== '' || termType !== 'all' || statusIn !== 'ALL'
            }
            setIsOpenQnaFilterBottomSheet={setIsOpenQnaFilterBottomSheet}
            handleSetQnaFilter={handleSetQnaFilter}
          />
          <Column style={{ padding: '0 20px' }}>
            <TotalCountContainer
              totalCount={qnaList.totalCount}
              isOpenCountFilter={isOpenCountFilter}
              filterItem={qnaFilter}
              handleSetFilterItem={handleSetQnaFilter}
              handleIsOpenCountFilter={handleIsOpenCountFilter}
            />
          </Column>
          {!isEmpty(qnaList.edges) ? (
            qnaList.edges.map((item, idx) => {
              return (
                <React.Fragment key={idx}>
                  <QnaItem
                    isMobile={isMobile}
                    item={item}
                    handleSelectedQnaItem={handleSelectedQnaItem}
                    handleRefetchList={handleReFetechList}
                  />
                  {qnaList.edges.length !== idx + 1 && (
                    <Divider style={{ height: '6px', background: '#f4f4f5' }} />
                  )}
                </React.Fragment>
              );
            })
          ) : (
            <Column gap="8px" style={{ minHeight: '60vh' }}>
              <Text color="#1b1c1e" size="16px">
                조회되는 문의가 없어요.
              </Text>
              <Text color="#70737c" weight={400}>
                필터를 다시 확인해 주세요
              </Text>
            </Column>
          )}
          <PaginataionContainer
            totalCount={qnaList.totalCount}
            page={qnaFilter.page}
            size={qnaFilter.size}
            handleSetQnaFilter={handleSetQnaFilter}
          />
          {isOpenQnaBottomSheet && (
            <QnaItemBottomSheet
              type="qna"
              isMobile={isMobile}
              productInfo={selectedQnaItem.node.product}
              handleSetProductQnaItem={handleSetProductQnaItem}
              onClose={() => setIsOpenQnaBottomSheet(false)}
            />
          )}
          {isOpenQnaFilterBottomSheet && (
            <QnaFilterBottomSheet
              isMobile={isMobile}
              qnaFilter={qnaFilter}
              searchValue={searchValue}
              termWithType={termWithType}
              termType={termType}
              statusIn={statusIn}
              setStatusIn={setStatusIn}
              setTermType={setTermType}
              handleSetTermWithType={handleSetTermWithType}
              handlerSetSearchValue={handlerSetSearchValue}
              handleResetFilterInput={handleResetFilterInput}
              onClose={() => setIsOpenQnaFilterBottomSheet(false)}
              handleAcceptFilter={handleAcceptFilter}
            />
          )}
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </Container>
  );
};

export default QnAListPage;
