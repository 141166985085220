import { ICON_CLOSE } from 'assets/icons';
import AuthButton from 'components/Button/AuthButton';
import DashButton from 'components/Button/DashButton';
import Column from 'components/common/Column';
import CommonImage from 'components/common/CommonImage';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import Divider from 'components/Divider';
import { ModifyValue } from 'containers/ProductDetail/hooks/useProductDetaillHooks';
import { useMutationUpdateLocation } from 'containers/ProductRegist/components/BasicInformation/graphQL/hooks/useMutationUpdateLocation';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import {
  FripLocationParam,
  ProductParamInput,
} from 'containers/ProductRegist/hooks/types';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Product, ProductKind, ProductState } from 'types/api';
import { formatInteractionType } from 'utils/formatting';
import HackleManager from 'utils/hackle';
import AddressForm from '../AddressForm';
import ModifyContainer from '../ModifyContainer';
import SaveButton from '../SaveButton';
import AddressModifyModal from './AddressModifyModal';
import { useAddressModifyHooks } from './hooks/useAddressModifyHooks';

interface AddressModifyFormIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  productInfo: Product;
  modifyValue: ModifyValue;
  setModifyValue: Dispatch<SetStateAction<ModifyValue>>;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
}

const AddressModifyForm: React.FC<AddressModifyFormIProps> = props => {
  const {
    isMobile,
    productParamInput,
    productInfo,
    modifyValue,
    setModifyValue,
    handleOnChangeProductParamInput,
  } = props;

  const {
    copyProductParamInput,
    locationVenueItems,
    setLocationVenueItems,
    handleResetLocationVenueItems,
    handleOnChangeCopyProductParamInput,
    handleResetAddress,
    handleRegistAddress,
  } = useAddressModifyHooks(productParamInput, handleOnChangeProductParamInput);

  const { handleDeleteLocation } = useMutationUpdateLocation();

  const isAbled = productParamInput.frip !== copyProductParamInput.frip;
  const fripInfo = copyProductParamInput.frip;

  const [type, setType] = useState<'address' | 'meeting'>('address');
  const [isOpenModifyModal, setIsOpenModifyModal] = useState<boolean>(false);

  const handleIsOpenModifyModal = (item: FripLocationParam) => {
    setLocationVenueItems(item);

    setIsOpenModifyModal(true);
  };

  useEffect(() => {
    HackleManager.logProductModifyAddLocationView(
      Number(productInfo.id),
      productInfo.title,
    );
  }, []);

  const renderModifyModal = (): JSX.Element => {
    return (
      <ModalContainer
        isMobile={isMobile}
        isAbled={true}
        isBottomSheet={true}
        onClick={() => {}}
        onClose={() => {
          setIsOpenModifyModal(false);
        }}
      >
        <AddressModifyModal
          isMobile={isMobile}
          productParamInput={copyProductParamInput}
          locationVenueItems={locationVenueItems}
          handleOnChangeProductParamInput={handleOnChangeCopyProductParamInput}
          handleResetLocationVenueItems={handleResetLocationVenueItems}
          type={type}
          onClose={() => setIsOpenModifyModal(false)}
        />
      </ModalContainer>
    );
  };

  const renderLocationVenue = (): JSX.Element => {
    return (
      <React.Fragment>
        {fripInfo.locationsOfVenue.map((item, idx) => {
          return (
            <React.Fragment key={idx}>
              <Row justifyContent="space-between" style={{ width: '100%' }}>
                <Text color="#46474C">진행하는 장소</Text>
                <Row gap="8px">
                  {fripInfo.locationsOfVenue.length !== 1 && (
                    <React.Fragment>
                      <Text
                        color="#FF4242"
                        style={{ textDecoration: 'underline' }}
                        onClick={() => {
                          handleDeleteLocation(item.locationId, () => {
                            handleOnChangeCopyProductParamInput('frip', {
                              ...copyProductParamInput.frip,
                              locationsOfVenue:
                                copyProductParamInput.frip.locationsOfVenue.filter(
                                  venueItem => venueItem.id !== item.id,
                                ),
                            });
                          });
                        }}
                      >
                        삭제
                      </Text>
                      <Text color="#70737c">|</Text>
                    </React.Fragment>
                  )}
                  <Text
                    color="#70737c"
                    style={{ minWidth: '42px', textDecoration: 'underline' }}
                    onClick={() => {
                      setType('address');
                      handleIsOpenModifyModal(item);
                    }}
                  >
                    수정하기
                  </Text>
                </Row>
              </Row>
              <AddressForm item={item} />
            </React.Fragment>
          );
        })}
        <DashButton
          text="진행 장소 추가"
          onClick={() => {
            setIsOpenModifyModal(true);
            setLocationVenueItems(null);
            setType('address');
          }}
        />
      </React.Fragment>
    );
  };

  const renderLocationGathering = (): JSX.Element => {
    return (
      <React.Fragment>
        {fripInfo.locationOfGathering ? (
          <React.Fragment>
            <Divider />
            <Row justifyContent="space-between" style={{ width: '100%' }}>
              <Text color="#46474C">모이는 장소</Text>
              <Row gap="8px">
                <Text
                  color="#FF4242"
                  style={{ textDecoration: 'underline' }}
                  onClick={() => {
                    handleOnChangeCopyProductParamInput('frip', {
                      ...copyProductParamInput.frip,
                      locationOfGathering: null,
                    });
                  }}
                >
                  삭제
                </Text>
                <Text color="#70737c">|</Text>
                <Text
                  color="#70737c"
                  style={{ minWidth: '42px', textDecoration: 'underline' }}
                  onClick={() => {
                    setType('meeting');
                    handleIsOpenModifyModal(fripInfo.locationOfGathering);
                  }}
                >
                  수정하기
                </Text>
              </Row>
            </Row>
            <AddressForm item={fripInfo.locationOfGathering} />
          </React.Fragment>
        ) : (
          <DashButton
            text="모이는 장소 추가"
            onClick={() => {
              setIsOpenModifyModal(true);
              setLocationVenueItems(null);
              setType('meeting');
            }}
          />
        )}
      </React.Fragment>
    );
  };

  const renderContents = (): JSX.Element => {
    if (isMobile) {
      return (
        <ModalContainer
          isAbled={productInfo.status !== ProductState.CLOSED && isAbled}
          isMobile={isMobile}
          onAcceptText="적용"
          onClose={() => {
            HackleManager.logProductModifyItemSaveComplete(
              Number(productInfo.id),
              productInfo.title,
              '장소',
            );

            setModifyValue({
              ...modifyValue,
              isModify: true,
              modifyStatus: '',
            });
          }}
          onClick={() => {}}
        >
          <Column
            gap="24px"
            style={{
              padding: '16px 20px 0',
            }}
          >
            <Row>
              <Text color="#000" size="16px" weight={600}>
                장소
              </Text>
            </Row>
            {renderLocationVenue()}
            {renderLocationGathering()}
          </Column>
          {isOpenModifyModal && renderModifyModal()}
        </ModalContainer>
      );
    }

    return (
      <Column>
        <Row
          justifyContent="flex-start"
          style={{
            position: 'sticky',
            top: 0,
            padding: '32px 40px',
            width: '100%',
            background: '#fff',
            borderBottom: '1px solid #eaebec',
            zIndex: 2,
          }}
        >
          <Text color="#1B1C1E" size="24px">
            장소 수정하기
          </Text>
        </Row>
        <Column gap="24px" style={{ padding: '32px 40px' }}>
          {renderLocationVenue()}
          {renderLocationGathering()}
          {productInfo.status !== ProductState.CLOSED && (
            <SaveButton
              isAbled={isAbled}
              modifyValue={modifyValue}
              setModifyValue={setModifyValue}
              handleResetItem={handleRegistAddress}
              handleRegistItem={() => {
                HackleManager.logProductModifyItemSaveComplete(
                  Number(productInfo.id),
                  productInfo.title,
                  '장소',
                );
                handleRegistAddress();
              }}
            />
          )}
        </Column>
        {isOpenModifyModal && renderModifyModal()}
      </Column>
    );
  };

  return renderContents();
};

export default AddressModifyForm;
