import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Loading from './Loading';

const NavigateLoading = () => {
  const [isOpenLoading, setIsOpenLoading] = useState<boolean>(false);

  const handleOpenLoading = () => {
    setIsOpenLoading(true);
  };

  const handleCloseLoading = () => {
    setIsOpenLoading(false);
  };

  useEffect(() => {
    window.handleOpenLoading = handleOpenLoading;
    window.handleCloseLoading = handleCloseLoading;
  }, []);

  return ReactDOM.createPortal(isOpenLoading && <Loading />, document.body);
};

export default NavigateLoading;
