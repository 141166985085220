import React, { useState } from 'react';
import { styled } from 'styled-components';

import Column from 'components/common/Column';
import Text from 'components/common/Text';
import Row from 'components/common/Row';
import {
  ICON_AUTH_CLOSE_BUTTON,
  ICON_CLOSE,
  ICON_PLUS_GRAY,
} from 'assets/icons';
import CommonImage from 'components/common/CommonImage';

const Input = styled.input`
  display: flex;
  align-items: center;
  padding: 16px 12px;
  width: 100%;

  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;

  color: #1b1c1e;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.6px;

  &:focus {
    border: 1.5px solid #1b1c1e;
  }

  &::placeholder {
    color: #c2c4c8;
  }
`;

const AdditionalOptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
`;

const AdditionalOption = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 8px 6px 12px;
  width: auto;
  border-radius: 99px;
  border: 1px solid var(--gray-gray-09, #1b1c1e);
  background: var(--gray-gray-01, #f7f7f8);

  color: #1b1c1e;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.4px;

  img {
    width: 14px;
    height: 14px;
  }
`;

interface AdditionalInformationFormIProps {
  title: string;
  placeholder: string;
  type: string;
  additionalList: string[];
  handleAddAdditionalValue: (type: string, inputValue: string) => void;
  handleDeleteAdditionalValue: (type: string, value: string) => void;
}

const AdditionalInformationForm: React.FC<
  AdditionalInformationFormIProps
> = props => {
  const {
    title,
    placeholder,
    type,
    additionalList,
    handleAddAdditionalValue,
    handleDeleteAdditionalValue,
  } = props;

  const [inputValue, setInputValue] = useState<string>('');

  const handleSetInputValue = (e: any) => {
    setInputValue(e.target.value);
  };

  return (
    <Column gap="16px" align="flex-start">
      <Column gap="8px" align="flex-start">
        <Text color="#1b1c1e" size="16px">
          {title}
        </Text>
        <Row
          justifyContent="space-between"
          gap="16px"
          style={{ width: '100%' }}
        >
          <Input
            placeholder={placeholder}
            value={inputValue}
            onChange={handleSetInputValue}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                setInputValue('');

                handleAddAdditionalValue(type, inputValue);
              }
            }}
          />
          <Row
            gap="2px"
            style={{ minWidth: '50px' }}
            onClick={() => {
              handleAddAdditionalValue(type, inputValue);
              setInputValue('');
            }}
          >
            <img src={ICON_PLUS_GRAY} alt="icon plus gray" />
            <Text
              color={inputValue ? '#1b1c1e' : '#c2c4c8'}
              size="16px"
              weight={400}
            >
              등록
            </Text>
          </Row>
        </Row>
      </Column>
      <AdditionalOptionContainer>
        {additionalList.map((item, idx) => {
          return (
            <AdditionalOption key={idx}>
              {item}{' '}
              <CommonImage
                src={ICON_CLOSE}
                onClick={() => handleDeleteAdditionalValue(type, item)}
                style={{ width: '14px' }}
              />
            </AdditionalOption>
          );
        })}
      </AdditionalOptionContainer>
    </Column>
  );
};

export default AdditionalInformationForm;
