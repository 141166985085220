import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import Calendar from 'react-calendar';
import dayjs from 'dayjs';
import { Value, View } from 'react-calendar/dist/cjs/shared/types';

import 'react-calendar/dist/Calendar.css';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import CommonImage from 'components/common/CommonImage';
import { ICON_ARROW_BLACK } from 'assets/icons';
import Text from 'components/common/Text';
import { useBookingCalendarHooks } from '../hooks/useBookingCalendarHooks';
import { Ticket, TicketState } from 'types/api';
import CalendarStatusButton from './CalendarStatusButton';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import BookingDetail from './BookingDetail';
import { BookingTermType } from '../graphQL/types';
import HackleManager from 'utils/hackle';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;

  .react-calendar {
    width: 100%;
    border: none;

    /* .react-calendar__navigation {
      display: none;
    } */

    .react-calendar__month-view__weekdays {
      display: none !important;

      :first-child {
        /* display: flex !important; */
      }
    }

    .react-calendar__month-view__days {
      display: flex;
      align-items: flex-start;
    }

    .react-calendar__tile--active {
      background-color: #f7f7f8;
    }

    .react-calendar__tile {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 16px;
      padding: 8px 16px 48px 16px;
      width: 54px;
      background-color: #fff;
      color: #1b1c1e;

      .selected {
        .react-calendar__tile {
          color: #fff !important;
        }
      }

      &:disabled {
        background-color: #eaebec;
        color: #c2c4c8;
      }

      &:enabled:focus {
        /* color: #fff; */
      }

      &:focus {
        background-color: #fff;
      }
    }
  }

  .react-calendar__month-view__weekdays__weekday--weekend abbr[title='일요일'] {
    color: #ff4242;
  }

  .react-calendar__month-view__weekdays__weekday--weekend abbr[title='토요일'] {
    color: #0071e9;
  }
  .react-calendar__tile .selected {
    color: #fff !important;
  }

  .selected {
    abbr {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px !important;
      height: 20px !important;
      color: #fff !important;
      border-radius: 50%;
      background-color: #1b1c1e !important;
      font-size: 0.833em !important;
    }

    background-color: #fff !important;
    /* color: #fff !important; */
  }

  .sunday {
    color: #ff4242 !important;
  }

  .saturday {
    color: #0071e9 !important;
  }
`;

const ArrowButton = styled.button`
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 50%;
  border: 1px solid #c2c4c8;
  background: var(--Static-White, #fff);
`;

const RegistDateSelectButton = styled.button<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  border-radius: 8px;
  border: ${props =>
    props.$isSelected ? '1.5px solid #1b1c1e' : '1px solid #DBDCDF'};
  background: ${props => (props.$isSelected ? '#f7f7f8' : '#fff')};

  color: var(--gray-gray-09, #1b1c1e);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
`;

const CalendarContainer = () => {
  const {
    bookingTickets,
    ticketFilterInput,
    handleSetTicekFilterInput,
    handleRefetchBookingTickets,
  } = useBookingCalendarHooks();

  const [date, setDate] = useState<Date>(new Date());
  const [ticketItem, setTicketItem] = useState<Ticket>();
  const [isOpenBookingDetail, setIsOpenBookingDetail] =
    useState<boolean>(false);

  const handlePrevMonth = () => {
    const convertDate = new Date(date);
    convertDate.setMonth(convertDate.getMonth() - 1);

    setDate(convertDate);

    const startedAt = new Date(
      convertDate.getFullYear(),
      convertDate.getMonth(),
      1,
    ).getTime();
    const endedAt = new Date(
      convertDate.getFullYear(),
      convertDate.getMonth() + 1,
      0,
      23,
      59,
      59,
      999,
    ).getTime();

    handleSetTicekFilterInput('filter', {
      ...ticketFilterInput.filter,
      termWithType: {
        term: { startedAt, endedAt },
        type: BookingTermType.STARTING,
      },
    });
  };

  const handleNextMonth = () => {
    const convertDate = new Date(date);
    convertDate.setMonth(convertDate.getMonth() + 1);

    setDate(convertDate);

    const startedAt = new Date(
      convertDate.getFullYear(),
      convertDate.getMonth(),
      1,
    ).getTime();
    const endedAt = new Date(
      convertDate.getFullYear(),
      convertDate.getMonth() + 1,
      0,
      23,
      59,
      59,
      999,
    ).getTime();

    handleSetTicekFilterInput('filter', {
      ...ticketFilterInput.filter,
      termWithType: {
        term: { startedAt, endedAt },
        type: BookingTermType.STARTING,
      },
    });
  };

  const tileClassName = ({
    date,
    view,
  }: {
    date: Date;
    view: View;
  }): string | null => {
    if (view === 'month') {
      let classNames = '';

      // 요일별 스타일
      if (date.getDay() === 0) {
        classNames += 'sunday';
      } else if (date.getDay() === 6) {
        classNames += 'saturday';
      } else {
        classNames += 'weekday';
      }

      return classNames.trim(); // 공백 제거
    }

    return null;
  };

  useEffect(() => {
    HackleManager.logBookingCalendarView();
  }, []);

  const renderTileContent = ({ date, view }: { date: Date; view: View }) => {
    if (view === 'month') {
      const formattedDate = dayjs(date).format('YYYY년 MM월 DD일');

      return (
        <Column>
          {bookingTickets.map((bookingItem, idx) => {
            return (
              <React.Fragment key={idx}>
                {bookingItem.date === formattedDate && (
                  <Column gap="4px">
                    {bookingItem.value.map((item, idx) => {
                      return (
                        <CalendarStatusButton
                          key={idx}
                          status={item.status}
                          customerName={
                            item.bookingItem.booking.customer.nickname
                          }
                          scheduleTerm={item.scheduleTerm}
                          onClick={() => {
                            setTicketItem(item);
                            setIsOpenBookingDetail(true);
                          }}
                        />
                      );
                    })}
                  </Column>
                )}
              </React.Fragment>
            );
          })}
        </Column>
      );
    }

    return null;
  };

  const renderBookingDetail = (): JSX.Element => {
    return (
      <ModalContainer
        isAbled={true}
        isMobile={true}
        isBottomSheet={true}
        onClose={() => {}}
        onClick={() => {}}
      >
        <BookingDetail
          item={ticketItem}
          onClose={() => {
            setIsOpenBookingDetail(false);
          }}
          handleRefetchBookingList={handleRefetchBookingTickets}
        />
      </ModalContainer>
    );
  };

  const renderHeader = () => {
    return (
      <Row
        justifyContent="space-between"
        style={{ padding: '0 20px', width: '100%' }}
      >
        <Text
          color="#1b1c1e"
          size="16px"
          weight={600}
        >{`${date.getFullYear()}년 ${date.getMonth() + 1}월`}</Text>
        <Row gap="8px">
          <ArrowButton onClick={handlePrevMonth}>
            <CommonImage
              src={ICON_ARROW_BLACK}
              onClick={handlePrevMonth}
              style={{ rotate: '180deg' }}
            />
          </ArrowButton>
          <ArrowButton onClick={handleNextMonth}>
            <CommonImage src={ICON_ARROW_BLACK} onClick={handleNextMonth} />
          </ArrowButton>
        </Row>
      </Row>
    );
  };

  const renderStatusButton = (): JSX.Element => {
    return (
      <Row gap="8px" style={{ padding: '0 20px' }}>
        <RegistDateSelectButton
          $isSelected={ticketFilterInput.filter.statusIn.length === 3}
          onClick={() => {
            handleSetTicekFilterInput('filter', {
              ...ticketFilterInput.filter,
              statusIn: [
                TicketState.CONFIRMED,
                TicketState.USED,
                TicketState.ABSENT,
              ],
            });
          }}
        >
          전체
        </RegistDateSelectButton>
        <RegistDateSelectButton
          $isSelected={
            ticketFilterInput.filter.statusIn.length === 1 &&
            ticketFilterInput.filter.statusIn.includes(TicketState.CONFIRMED)
          }
          onClick={() => {
            handleSetTicekFilterInput('filter', {
              ...ticketFilterInput.filter,
              statusIn: [TicketState.CONFIRMED],
            });
          }}
        >
          예약확정
        </RegistDateSelectButton>{' '}
        <RegistDateSelectButton
          $isSelected={
            ticketFilterInput.filter.statusIn.length === 1 &&
            ticketFilterInput.filter.statusIn.includes(TicketState.USED)
          }
          onClick={() => {
            handleSetTicekFilterInput('filter', {
              ...ticketFilterInput.filter,
              statusIn: [TicketState.USED],
            });
          }}
        >
          사용완료
        </RegistDateSelectButton>{' '}
        <RegistDateSelectButton
          $isSelected={
            ticketFilterInput.filter.statusIn.length === 1 &&
            ticketFilterInput.filter.statusIn.includes(TicketState.ABSENT)
          }
          onClick={() => {
            handleSetTicekFilterInput('filter', {
              ...ticketFilterInput.filter,
              statusIn: [TicketState.ABSENT],
            });
          }}
        >
          불참
        </RegistDateSelectButton>
      </Row>
    );
  };

  return (
    <Container>
      <Column>
        <Column gap="24px" align="flex-start">
          {renderHeader()}
          {renderStatusButton()}
          <Calendar
            value={date}
            showNeighboringMonth={false}
            calendarType="gregory"
            tileContent={renderTileContent}
            prevLabel={null}
            nextLabel={null}
            showNavigation={false}
            tileClassName={tileClassName}
            formatDay={(locale, date) => date.getDate().toString()}
            onChange={() => {}}
          />
        </Column>
      </Column>
      {isOpenBookingDetail && renderBookingDetail()}
    </Container>
  );
};

export default CalendarContainer;
