import React, { SetStateAction } from 'react';
import styled from 'styled-components';

import {
  ICON_PRODUCT_CHECKMARK,
  ICON_PRODUCT_CHECKMARK_BLACK,
} from 'assets/icons';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import { Items, ScheduleParam } from 'containers/ProductRegist/hooks/types';
import { isDisabled } from '@testing-library/user-event/dist/utils';
import Column from 'components/common/Column';

const OptionSelectButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 12px;
  width: 100%;

  border-radius: 8px;
  border: 1px dashed var(--gray-gray-04, #dbdcdf);
  background: var(--gray-gray-01, #f7f7f8);

  color: var(--gray-gray-07, #70737c);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.6px;
`;

const OptionNameBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px;
  width: 100%;

  border-radius: 8px;
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);
`;

interface OptionSelectFormIProps {
  scheduleItem: ScheduleParam;
  selectedItems: Items[];
  isMobile: boolean;
  isDisable: boolean;
  handleDeleteSelectForm: (item: Items) => void;
  handleIsOpenModal: () => void;
}

const OptionSelectForm: React.FC<OptionSelectFormIProps> = props => {
  const {
    scheduleItem,
    selectedItems,
    isMobile,
    isDisable,
    handleDeleteSelectForm,
    handleIsOpenModal,
  } = props;

  const renderContents = (): JSX.Element => {
    if (isDisable) {
      return (
        <Column gap="12px" align="flex-start">
          <Column gap="6px">
            {selectedItems.map((item, idx) => {
              return (
                <Row key={idx} gap="24px" style={{ width: '100%' }}>
                  <OptionNameBox>{item.name}</OptionNameBox>
                </Row>
              );
            })}
          </Column>
        </Column>
      );
    }

    return (
      <React.Fragment>
        {scheduleItem.appliedAllItems && (
          <OptionSelectButton style={{ border: '1.5px solid #1b1c1e' }}>
            <img
              src={ICON_PRODUCT_CHECKMARK_BLACK}
              alt="icon product checkmark black"
            />
            옵션 전체 선택됨
          </OptionSelectButton>
        )}
        {selectedItems.length > 0 && (
          <React.Fragment>
            {selectedItems.map((item, idx) => {
              return (
                <Row key={idx} gap="24px" style={{ width: '100%' }}>
                  <OptionNameBox>{item.name}</OptionNameBox>
                  <Text
                    color="#70737c"
                    style={{
                      textDecoration: 'underline',
                      minWidth: '25px',
                    }}
                    onClick={() => handleDeleteSelectForm(item)}
                  >
                    삭제
                  </Text>
                </Row>
              );
            })}
          </React.Fragment>
        )}
        <OptionSelectButton
          onClick={handleIsOpenModal}
          style={{ height: isMobile ? 'auto' : '114px' }}
        >
          <img src={ICON_PRODUCT_CHECKMARK} alt="icon product checkmark" />
          옵션 선택
        </OptionSelectButton>
      </React.Fragment>
    );
  };

  return renderContents();
};

export default OptionSelectForm;
