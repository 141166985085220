import React from 'react';
import { styled } from 'styled-components';
import { ProductState } from 'types/api';

const Container = styled.div<{ $isSelected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 6px 12px;

  border-radius: 99px;
  border: ${(props) =>
    props.$isSelected ? '1.5px solid #1b1c1e' : '1px solid #dbdcdf'};
  background: ${(props) => (props.$isSelected ? '#F7F7F8' : '#fff')};

  color: #1b1c1e;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
  cursor: pointer;
`;

const StatusColorBox = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

interface StatusButtonIProps {
  status: ProductState;
  isSelected?: boolean;
  style?: React.CSSProperties;
}

const StatusButton: React.FC<StatusButtonIProps> = (props) => {
  const { status, isSelected, style } = props;

  const renderStatusButton = () => {
    switch (status) {
      case ProductState.APPLIED: {
        return (
          <React.Fragment>
            <StatusColorBox color="#32A06E" />
            검수 요청
          </React.Fragment>
        );
      }
      case ProductState.INSPECTING: {
        return (
          <React.Fragment>
            <StatusColorBox color="#32A06E" />
            검수중
          </React.Fragment>
        );
      }
      case ProductState.EDITING: {
        return (
          <React.Fragment>
            <StatusColorBox color="#32A06E" />
            등록중
          </React.Fragment>
        );
      }
      case ProductState.READY: {
        return (
          <React.Fragment>
            <StatusColorBox color="#0071E9" />
            판매 대기
          </React.Fragment>
        );
      }
      case ProductState.SALE: {
        return (
          <React.Fragment>
            <StatusColorBox color="#0071E9" />
            판매중
          </React.Fragment>
        );
      }
      case ProductState.SOLD_OUT: {
        return (
          <React.Fragment>
            <StatusColorBox color="#70737C" />
            품절
          </React.Fragment>
        );
      }
      case ProductState.PAUSED: {
        return (
          <React.Fragment>
            <StatusColorBox color="#FF9200" />
            일시 중지
          </React.Fragment>
        );
      }
      case ProductState.REJECTED: {
        return (
          <React.Fragment>
            <StatusColorBox color="#FF4242" />
            검수 반려
          </React.Fragment>
        );
      }
      case ProductState.CLOSED: {
        return (
          <React.Fragment>
            <StatusColorBox color="#FF4242" />
            판매 종료
          </React.Fragment>
        );
      }
      case ProductState.SUSPENDED: {
        return (
          <React.Fragment>
            <StatusColorBox color="#FF9200" />
            관리자 일시 중지
          </React.Fragment>
        );
      }
      default: {
        return <></>;
      }
    }
  };

  return (
    <Container style={{ ...style }} $isSelected={isSelected}>
      {renderStatusButton()}
    </Container>
  );
};

export default StatusButton;
