import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import { ICON_CLOSE } from 'assets/icons';
import Column from 'components/common/Column';
import CommonImage from 'components/common/CommonImage';
import Row from 'components/common/Row';
import SearchForm from 'components/common/SearchForm';
import Text from 'components/common/Text';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import { TicketFilterInput } from '../hooks/useBookingListHooks';
import HackleManager from 'utils/hackle';
import { formatBookingSearchType } from 'utils/hackle/parameterFormatting';

const RegistDateSelectButton = styled.button<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  border-radius: 8px;
  border: ${props =>
    props.$isSelected ? '1.5px solid #1b1c1e' : '1px solid #DBDCDF'};
  background: ${props => (props.$isSelected ? '#f7f7f8' : '#fff')};

  color: var(--gray-gray-09, #1b1c1e);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
`;

interface BookingSearchModalIProps {
  isMobile: boolean;
  searchType: string;
  filterSearchValue: string;
  ticketFilterInput: TicketFilterInput;
  handleSetTicekFilterInput: (key: string, value: any) => void;
  setSearchType: Dispatch<SetStateAction<string>>;
  setFilterSearchValue: Dispatch<SetStateAction<string>>;
  setIsAcceptFilter: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}

const BookingSearchModal: React.FC<BookingSearchModalIProps> = props => {
  const {
    isMobile,
    searchType,
    filterSearchValue,
    ticketFilterInput,
    handleSetTicekFilterInput,
    setSearchType,
    setFilterSearchValue,
    setIsAcceptFilter,
    onClose,
  } = props;

  const handleAcceptFilter = () => {
    if (filterSearchValue === '' && searchType === 'productName') {
      handleSetTicekFilterInput('filter', {
        ...ticketFilterInput.filter,
        itemNameLike: null,
        productId: null,
        productTitleLike: null,
      });

      setIsAcceptFilter(false);

      return;
    }

    if (filterSearchValue !== '') {
      HackleManager.logBookingFilterComplete(
        formatBookingSearchType(searchType),
        filterSearchValue,
      );

      setIsAcceptFilter(true);

      switch (searchType) {
        case 'productName': {
          handleSetTicekFilterInput('filter', {
            ...ticketFilterInput.filter,
            productTitleLike: filterSearchValue,
            productId: null,
            itemNameLike: null,
          });

          return;
        }
        case 'fripId': {
          handleSetTicekFilterInput('filter', {
            ...ticketFilterInput.filter,
            productId: filterSearchValue,
            productTitleLike: null,
            itemNameLike: null,
          });

          return;
        }
        case 'optionName': {
          handleSetTicekFilterInput('filter', {
            ...ticketFilterInput.filter,
            itemNameLike: filterSearchValue,
            productId: null,
            productTitleLike: null,
          });

          return;
        }
        default:
          return;
      }
    }
  };

  const renderSearchForm = (): JSX.Element => {
    return (
      <Column align="flex-start" gap="12px">
        <Text color="#1b1c1e" size="16px">
          검색어
        </Text>
        <Row gap="12px">
          <RegistDateSelectButton
            $isSelected={searchType === 'productName'}
            onClick={() => {
              setSearchType('productName');
              setFilterSearchValue('');
            }}
          >
            프립명
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={searchType === 'fripId'}
            onClick={() => {
              setSearchType('fripId');
              setFilterSearchValue('');
            }}
          >
            프립ID
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={searchType === 'optionName'}
            onClick={() => {
              setSearchType('optionName');
              setFilterSearchValue('');
            }}
          >
            옵션명
          </RegistDateSelectButton>
        </Row>
        <SearchForm
          placeholder="검색어를 입력해 주세요."
          searchValue={filterSearchValue}
          setSearchValue={setFilterSearchValue}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        />
      </Column>
    );
  };

  return (
    <ModalContainer
      isMobile={isMobile}
      isAbled={true}
      onAcceptText="적용"
      onCloseText="초기화"
      onClick={() => {
        handleAcceptFilter();

        onClose();
      }}
      onClose={() => {
        setSearchType('productName');

        setFilterSearchValue('');
      }}
      style={{ gap: 0 }}
      modalStyle={{ zIndex: 22 }}
    >
      <Row style={{ padding: '16px 0' }}>
        <CommonImage
          src={ICON_CLOSE}
          style={{ position: 'absolute', left: '20px' }}
          onClick={onClose}
        />
        <Text color="#000" size="16px" weight={600}>
          검색
        </Text>
      </Row>
      <Column
        align="flex-start"
        gap="32px"
        style={{ padding: '12px 20px 32px' }}
      >
        {renderSearchForm()}
      </Column>
    </ModalContainer>
  );
};

export default BookingSearchModal;
