import { Items, ScheduleParam } from 'containers/ProductRegist/hooks/types';
import { useState } from 'react';

export const useOptionBottomSheetHooks = () => {
  const [endDateFormat, setEndDateFormat] = useState<string>('3day');
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isOpenOptionModal, setIsOpenOptionModal] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<Items[]>([]);
  const [termDate, setTermDate] = useState<{
    startDate: string;
    endDate: string;
    durationDate: string;
  }>({ startDate: '', endDate: '', durationDate: '1' });
  const [endDueDate, setEndDueDate] = useState<{ date: number; time: number }>({
    date: 0,
    time: 0,
  });

  const handleButtonClick = () => {
    setIsModalVisible(!isModalVisible);
  };

  return {
    endDateFormat,
    isModalVisible,
    isOpenOptionModal,
    selectedItems,
    termDate,
    endDueDate,
    setEndDueDate,
    setTermDate,
    setSelectedItems,
    setIsOpenOptionModal,
    handleButtonClick,
    setEndDateFormat,
  };
};
