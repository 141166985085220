import React, { useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Value } from 'react-calendar/dist/cjs/shared/types';

import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import Divider from 'components/Divider';
import ModalContainer from '../../common/ModalContainer';
import OptionButton from '../../common/OptionButton';
import {
  RepeatDateType,
  useSelectDateHooks,
} from '../hooks/useSelectDateHooks';
import { DayOfWeek } from 'types/api';
import CustomCalendar from 'components/Calendar/Calendar';
import { ICON_CLOSE } from 'assets/icons';
import { PlanParam } from 'containers/ProductRegist/hooks/types';
import DateInput from 'components/Input/DateInput';

const DateBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  width: 50%;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;
  cursor: pointer;
`;

const datOfWeekList = [
  { text: '일', value: DayOfWeek.SUNDAY },
  { text: '월', value: DayOfWeek.MONDAY },
  { text: '화', value: DayOfWeek.TUESDAY },
  { text: '수', value: DayOfWeek.WEDNESDAY },
  { text: '목', value: DayOfWeek.THURSDAY },
  { text: '금', value: DayOfWeek.FRIDAY },
  { text: '토', value: DayOfWeek.SATURDAY },
];

interface SelectDateModalIProps {
  isMobile: boolean;
  onClose: () => void;
  handleGetSpecificDaysInRange: (
    startDate: number,
    endDate: number,
    selectedDays: DayOfWeek[],
  ) => void;
  handleSetPlanParam: (planParam: PlanParam) => void;
}

const SelectDateModal: React.FC<SelectDateModalIProps> = props => {
  const {
    isMobile,
    onClose,
    handleGetSpecificDaysInRange,
    handleSetPlanParam,
  } = props;

  const {
    planParam,
    repeatDateType,
    repeatTerm,
    handleSetPlanParams,
    handleSetRepeatDateType,
    handleSetRepeatTerm,
    handleResetPlanParam,
  } = useSelectDateHooks();

  const [isOpenCalendarModal, setIsOpenCalendarModal] =
    useState<boolean>(false);
  const [time, setTime] = useState<number>(0);

  const handleChangePlanWeeks = (weekDay: DayOfWeek) => {
    if (planParam.weeks.includes(weekDay)) {
      const filterItem = planParam.weeks.filter(item => item !== weekDay);

      handleSetPlanParams('weeks', filterItem);

      return;
    }

    handleSetPlanParams('weeks', [...planParam.weeks, weekDay]);
  };

  const handleSetStartDate = (value: Value) => {
    const date = new Date(value!.toString());

    const formatTime = date.getTime();

    if (time === formatTime) {
      setTime(0);

      return;
    }
    setTime(formatTime);
  };

  const isAbled =
    planParam.term.startedAt !== 0 &&
    planParam.term.endedAt !== 0 &&
    planParam.weeks.length > 0;

  const renderRepeatForm = (): JSX.Element => {
    return (
      <Column gap="12px" align="flex-start">
        <Text color="#1b1c1e" size="16px">
          반복 주기
        </Text>
        <Row gap="8px" style={{ width: '100%' }}>
          <OptionButton
            text="매주"
            isSelected={repeatDateType === RepeatDateType.WEEKDAY}
            onClick={() => {
              handleSetRepeatDateType(RepeatDateType.WEEKDAY);
              handleSetPlanParams('weeks', []);
            }}
          />
          <OptionButton
            text="매일"
            isSelected={repeatDateType === RepeatDateType.DAY}
            onClick={() => {
              handleSetRepeatDateType(RepeatDateType.DAY);
              handleSetPlanParams(
                'weeks',
                datOfWeekList.map(item => item.value),
              );
            }}
          />
        </Row>
      </Column>
    );
  };

  const renderSelectWeekDayForm = (): JSX.Element => {
    return (
      <Column gap="12px" align="flex-start">
        <Text color="#1b1c1e" size="16px">
          요일 선택
        </Text>
        <Row gap="8px" style={{ width: '100%' }}>
          {datOfWeekList.map((item, idx) => {
            return (
              <OptionButton
                key={idx}
                text={
                  item.text === '일' ? (
                    <Text color="#FF4242" size="14px">
                      일
                    </Text>
                  ) : item.text === '토' ? (
                    <Text color="#0071E9" size="14px">
                      토
                    </Text>
                  ) : (
                    item.text
                  )
                }
                isSelected={planParam.weeks.includes(item.value)}
                onClick={() => {
                  handleChangePlanWeeks(item.value);
                }}
              />
            );
          })}
        </Row>
      </Column>
    );
  };

  const renderRepeatTermForm = (): JSX.Element => {
    return (
      <Column gap="16px" align="flex-start">
        <Column gap="2px" align="flex-start">
          <Text color="#1b1c1e" size="16px">
            반복 기간
          </Text>
          <Text color="#70737c" size="12px" weight={400}>
            종료일은 현재로부터 3개월을 초과할 수 없습니다.
          </Text>
        </Column>
        <Column gap="8px">
          <Row justifyContent="space-between" style={{ width: '100%' }}>
            <Text color="#1b1c1e" size="14px" weight={400}>
              반복 시작일
            </Text>
            <DateBox
              onClick={() => {
                // setIsOpenCalendarModal(true);
              }}
            >
              <Text
                color={planParam.term?.startedAt ? '#1b1c1e' : '#c2c4c8'}
                size="14px"
                weight={400}
              >
                {planParam.term?.startedAt
                  ? dayjs(planParam.term.startedAt).format('YY년 M월 D일')
                  : '년 • 월 • 일'}
              </Text>
              <Text color="#1b1c1e" size="12px" weight={400}>
                부터
              </Text>
              <DateInput
                onChange={e => {
                  if (
                    planParam.term.endedAt !== 0 &&
                    planParam.term.endedAt <
                      new Date(e.target.value).setHours(0)
                  ) {
                    window.showToast(
                      '반복 종료일을 다시 확인해주세요.',
                      'failed',
                    );

                    return;
                  }

                  handleSetPlanParams('term', {
                    ...planParam.term,
                    startedAt: new Date(e.target.value).setHours(0),
                  });
                }}
              />
            </DateBox>
          </Row>
          <Row justifyContent="space-between" style={{ width: '100%' }}>
            <Text color="#1b1c1e" size="14px" weight={400}>
              반복 종료일
            </Text>
            <DateBox>
              <Text
                color={planParam.term?.endedAt ? '#1b1c1e' : '#c2c4c8'}
                size="14px"
                weight={400}
              >
                {planParam.term.endedAt
                  ? dayjs(planParam.term.endedAt).format('YY년 M월 D일')
                  : '년 • 월 • 일'}
              </Text>

              <Text color="#1b1c1e" size="12px" weight={400}>
                까지
              </Text>
              <DateInput
                onChange={e => {
                  if (
                    planParam.term.startedAt !== 0 &&
                    new Date(e.target.value).setHours(0) <
                      planParam.term.startedAt
                  ) {
                    window.showToast(
                      '반복 시작일을 다시 확인해주세요.',
                      'failed',
                    );

                    return;
                  }

                  handleSetRepeatTerm('select');

                  handleSetPlanParams('term', {
                    ...planParam.term,
                    endedAt: new Date(e.target.value).setHours(0),
                  });
                }}
              />
            </DateBox>
          </Row>
        </Column>
        <Column gap="8px">
          <Row gap="8px" style={{ width: '100%' }}>
            <OptionButton
              text="1개월"
              isSelected={repeatTerm === 1}
              onClick={() => {
                handleSetRepeatTerm(1);
              }}
            />
            <OptionButton
              text="2개월"
              isSelected={repeatTerm === 2}
              onClick={() => {
                handleSetRepeatTerm(2);
              }}
            />
            <OptionButton
              text={
                <Text color="#1b1c1e" size="13px">
                  3개월 <span style={{ color: '#7126FF' }}>(권장)</span>
                </Text>
              }
              isSelected={repeatTerm === 3}
              onClick={() => {
                handleSetRepeatTerm(3);
              }}
            />
          </Row>
          <Row justifyContent="flex-start" gap="8px" style={{ width: '100%' }}>
            {/* <OptionButton
              text={
                <Row gap="4px">
                  날짜 지정
                  <DateInput
                    onChange={e => {
                      handleSetPlanParams('term', {
                        ...planParam.term,
                        endedAt: dayjs(new Date(e.target.value).setHours(0)),
                      });
                    }}
                  />
                </Row>
              }
              isSelected={repeatTerm === 'select'}
              onClick={() => {
                handleSetRepeatTerm('select');

                // setIsOpenCalendarModal(true);
              }}
            /> */}
          </Row>
        </Column>
      </Column>
    );
  };

  // const renderCalendarModal = (): JSX.Element => {
  //   return (
  //     <ModalContainer
  //       isMobile={isMobile}
  //       isAbled={time !== 0}
  //       onAcceptText="일정 선택"
  //       onClose={() => {
  //         setIsOpenCalendarModal(false);
  //       }}
  //       onClick={() => {
  //         if (time !== 0) {
  //           setIsOpenCalendarModal(false);
  //           setTime(0);

  //           if (repeatTerm === 'select') {
  //             handleSetPlanParams('term', {
  //               ...planParam.term,
  //               endedAt: time,
  //             });

  //             return;
  //           }

  //           handleSetPlanParams('term', { ...planParam.term, startedAt: time });
  //         }
  //       }}
  //     >
  //       <Row
  //         style={{
  //           padding: '16px 0',
  //           position: 'relative',
  //           width: '100%',
  //         }}
  //       >
  //         <img
  //           src={ICON_CLOSE}
  //           alt="close icon"
  //           style={{ position: 'absolute', left: '20px', cursor: 'pointer' }}
  //           onClick={() => {
  //             setIsOpenCalendarModal(false);
  //           }}
  //         />
  //         <Text color="#1b1c1e" size="16px" weight={600}>
  //           반복 시작일
  //         </Text>
  //       </Row>
  //       <CustomCalendar onChange={handleSetStartDate} />
  //     </ModalContainer>
  //   );
  // };

  return (
    <React.Fragment>
      <ModalContainer
        isMobile={isMobile}
        isAbled={isAbled}
        onClose={onClose}
        onClick={() => {
          if (isAbled) {
            handleGetSpecificDaysInRange(
              planParam.term.startedAt,
              planParam.term.endedAt,
              planParam.weeks,
            );

            handleSetPlanParam(planParam);
            handleResetPlanParam();

            onClose();
          }
        }}
      >
        <Row style={{ padding: '16px 20px 0', width: '100%' }}>
          <Text color="#1b1c1e" size="16px" weight={600}>
            날짜 선택
          </Text>
        </Row>
        <Column gap="24px" style={{ padding: '0 20px' }}>
          {renderRepeatForm()}
          <Divider />
          {repeatDateType !== RepeatDateType.DAY && (
            <>
              {renderSelectWeekDayForm()}
              <Divider />
            </>
          )}
          {renderRepeatTermForm()}
        </Column>
      </ModalContainer>
      {/* {isOpenCalendarModal && renderCalendarModal()} */}
    </React.Fragment>
  );
};

export default SelectDateModal;
