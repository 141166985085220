import AuthButton from 'components/Button/AuthButton';
import Text from 'components/common/Text';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { callWebviewHandler, WebviewOperatorType } from 'utils/webview';

const Container = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${props => (props.$isMobile ? 'flex-end' : 'center')};
  position: fixed;
  top: 0;
  left: 0;
  padding: ${props => (props.$isMobile ? '64px 0 0 0' : '64px 20px')};
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
`;

const ContentContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
  max-width: 768px;
  overflow-y: scroll;

  background-color: #fff;
  border-radius: ${props => (props.$isMobile ? '16px 16px 0 0' : '16px')};
  z-index: 20;
`;

const FloatingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  padding: 16px 20px 24px 20px;
  margin-top: auto;

  width: 100%;
  background-color: #fff;
  border-radius: 0 0 16px 16px;
  border-top: 1px solid #dbdcdf;
`;

interface ModalContainerIProps {
  isMobile: boolean;
  children: React.ReactNode;
  isAbled: boolean;
  onCloseText?: string;
  onAcceptText?: string;
  isBottomSheet?: boolean;
  modalStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  onClose: () => void;
  onClick: () => void;
}

const ModalContainer: React.FC<ModalContainerIProps> = props => {
  const {
    isMobile,
    children,
    isAbled,
    onCloseText,
    onAcceptText,
    isBottomSheet,
    modalStyle,
    style,
    onClose,
    onClick,
  } = props;

  const modalRef = useRef(null);

  const handleMobileModalClose = () => {
    onClose();

    callWebviewHandler(WebviewOperatorType.SetMobileModalNavigate, {
      isOpenModal: 'N',
    });
  };

  useEffect(() => {
    const preventScroll = e => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        e.preventDefault();
      }
    };

    callWebviewHandler(WebviewOperatorType.SetMobileModalNavigate, {
      isOpenModal: 'Y',
    });
    document.body.style.overflow = 'hidden'; // body 스크롤 차단
    window.addEventListener('wheel', preventScroll, { passive: false });
    window.addEventListener('touchmove', preventScroll, { passive: false });
    window.handleMobileModalClose = handleMobileModalClose;

    return () => {
      callWebviewHandler(WebviewOperatorType.SetMobileModalNavigate, {
        isOpenModal: 'N',
      });
      document.body.style.overflow = ''; // 컴포넌트가 언마운트될 때 스크롤 복구
      window.removeEventListener('wheel', preventScroll);
      window.removeEventListener('touchmove', preventScroll);
    };
  }, []);

  return (
    <Container
      $isMobile={isMobile}
      style={{ ...modalStyle }}
      onClick={e => e.stopPropagation()}
    >
      <ContentContainer
        $isMobile={isMobile}
        style={{ ...style }}
        ref={modalRef}
      >
        {children}
        {!isBottomSheet && (
          <FloatingContainer>
            <Text
              color="#1b1c1e"
              size="16px"
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={onClose}
            >
              {onCloseText ? onCloseText : '닫기'}
            </Text>
            <AuthButton
              name={onAcceptText ? onAcceptText : '확인'}
              isAbled={isAbled}
              onClick={() => {
                if (isAbled) onClick();
              }}
              style={{ width: 'auto', minWidth: '76px' }}
            />
          </FloatingContainer>
        )}
      </ContentContainer>
    </Container>
  );
};

export default ModalContainer;
