import React, { ReactElement } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 768px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 20;
  font-family: Pretendard;
`;

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  padding: 20px 24px;
  width: 340px;

  background-color: #fff;
  border-radius: 28px;
`;

const ContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: -0.6px;
  color: #000;
`;

const Description = styled.div`
  display: flex;
  justify-content: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.6px;
  color: #777;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 7px;
  align-self: stretch;
`;

const CloseButton = styled.div`
  display: flex;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 9999px;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: -0.6px;
  color: #000;
`;

const AcceptButton = styled.div`
  display: flex;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  background-color: #000;
  border-radius: 9999px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: -0.6px;
  color: #fff;
`;

interface MessageDialogIProps {
  title: string;
  description?: string;
  onCloseText?: string;
  onAcceptText: string | ReactElement;
  onClose?: () => void;
  onAccept?: () => void;
}

const CommonDialog: React.FC<MessageDialogIProps> = (props) => {
  const { title, description, onAcceptText, onCloseText, onClose, onAccept } =
    props;

  return (
    <Container>
      <DialogContainer>
        <ContentsContainer>
          <Title>{title}</Title>
          {description && <Description>{description}</Description>}
        </ContentsContainer>
        <ButtonContainer>
          {onClose && (
            <CloseButton onClick={onClose}>
              {onCloseText ? onCloseText : '닫기'}
            </CloseButton>
          )}
          {onAccept && (
            <AcceptButton onClick={onAccept}>{onAcceptText}</AcceptButton>
          )}
        </ButtonContainer>
      </DialogContainer>
    </Container>
  );
};

export default CommonDialog;
