import React from 'react';
import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 16px 32px 16px;
  width: 100%;

  border-radius: 8px;
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--gray-gray-01, #f7f7f8);

  color: var(--gray-gray-06, #989ba2);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.6px;
  white-space: pre-wrap;
`;

const RefundForm = () => {
  return (
    <Container>
      1. 결제 후 14일 이내 취소 시 : 전액 환불 <br /> (단, 결제 후 14일 이내라도
      호스트와 프립 진행일 예약 확정 후 환불 불가) <br /> 2. 결제 후 14일 이후
      취소 시 : 환불 불가 <br />※ 상품의 유효기간 만료 시 연장은 불가하며, 기간
      내 호스트와 예약 확정 되지 않은 프립은 프립 에너지로 환불 됩니다. <br />※
      환불된 에너지의 유효기간은 지급일로부터 180일이며, 유효기간 종료 후
      기간연장 및 환불이 불가합니다. <br />※ 배송상품의 경우 배송 준비 전 전액
      환불 가능, 배송 준비 후 환불 불가 합니다. <br />※ 다회권의 경우, 1회라도
      사용시 부분 환불이 불가하며, 기간 내 호스트와 예약 확정 되지 않은 프립은
      프립 에너지로 환불 됩니다. <br />
      [환불 신청 방법] <br />
      1. 해당 프립 결제한 계정으로 로그인 <br />
      2. 마이프립 - 신청내역 or 결제내역 <br />
      3. 취소를 원하는 프립 상세 정보 버튼 - 취소 <br />※ 결제 수단에 따라
      예금주, 은행명, 계좌번호 입력
    </Container>
  );
};

export default RefundForm;
