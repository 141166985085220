import React, { Dispatch, SetStateAction } from 'react';
import AuthButton from 'components/Button/AuthButton';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import { ModifyValue } from '../hooks/useProductDetaillHooks';

interface SaveButtonIProps {
  isAbled: boolean;
  modifyValue: ModifyValue;
  handleResetItem: () => void;
  handleRegistItem: () => void;
  setModifyValue: Dispatch<SetStateAction<ModifyValue>>;
}

const SaveButton: React.FC<SaveButtonIProps> = props => {
  const {
    isAbled,
    modifyValue,
    handleRegistItem,
    handleResetItem,
    setModifyValue,
  } = props;

  return (
    <Row gap="24px" justifyContent="flex-end" style={{ width: '100%' }}>
      <Text
        color="#989BA2"
        size="16px"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          handleResetItem();

          setModifyValue({ ...modifyValue, isModify: false });
        }}
      >
        원래대로
      </Text>
      <AuthButton
        name="적용"
        isAbled={isAbled}
        onClick={() => {
          if (isAbled) {
            handleRegistItem();

            setModifyValue({ ...modifyValue, isModify: true });
          }
        }}
        style={{ width: '82px' }}
      />
    </Row>
  );
};

export default SaveButton;
