import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { STORAGE_KEY } from 'utils/localStoarge';
import Header from 'components/common/Header';
import {
  LOGIN_PATH,
  PRODUCT_DETAIL_PATH,
  PRODUCT_REGIST_PATH,
  SETTLEMENT_BANK_REGIST_PATH,
  SETTLEMENT_REGIST_PATH,
} from 'constants/path';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import HackleManager from 'utils/hackle';
import { MeData } from 'graphQL/Auth/types';

const PrivateRoute = () => {
  const isLoggedIn = localStorage.getItem(STORAGE_KEY.AUTHORIZATION);
  const userInfo: MeData = useUserInformation(state => state.userInfo);
  const locaion = useLocation();

  const isShowHeader =
    !locaion.pathname.includes(PRODUCT_REGIST_PATH) &&
    !locaion.pathname.includes(PRODUCT_DETAIL_PATH) &&
    !location.pathname.includes(SETTLEMENT_BANK_REGIST_PATH) &&
    !location.pathname.includes(SETTLEMENT_REGIST_PATH);

  if ((userInfo.me && userInfo.me.host === null) || !isLoggedIn) {
    return <Navigate replace to={LOGIN_PATH} />;
  }

  useEffect(() => {
    HackleManager.setUserId(
      userInfo.me.host.id,
      userInfo.me.host.name,
      userInfo.me.host.super,
    );
  }, []);

  return (
    <React.Fragment>
      {isShowHeader && <Header />}
      <Outlet />
    </React.Fragment>
  );
};

export default PrivateRoute;
