import React, { useState } from 'react';
import styled from 'styled-components';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import Row from 'components/common/Row';
import Column from 'components/common/Column';
import Text from 'components/common/Text';
import {
  ICON_CATEGORY_SEARCH,
  ICON_CATEGORY_SEARCH_ACTIVE,
  ICON_CLOSE,
} from 'assets/icons';
import { ProductState } from 'types/api';
import StatusButton from './StatusButton';
import { ProductFilterInput, ProductTermWithTypeInput } from '../graphQL/types';
import { useProductFilterHooks } from '../hooks/useProductFilterHooks';

const SearchInputContainer = styled.div`
  display: flex;
  gap: 8px;
  padding: 12px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: var(--Static-White, #fff);
`;

const SearchInput = styled.input`
  width: 100%;
  border: none;

  color: var(--gray-gray-09, #1b1c1e);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;

  ::placeholder {
    color: #989ba2;
  }
`;

const RegistDateSelectButton = styled.button<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  border-radius: 8px;
  border: ${props =>
    props.$isSelected ? '1.5px solid #1b1c1e' : '1px solid #DBDCDF'};
  background: ${props => (props.$isSelected ? '#f7f7f8' : '#fff')};

  color: var(--gray-gray-09, #1b1c1e);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
`;

const productStatusList = [
  ProductState.APPLIED,
  ProductState.INSPECTING,
  ProductState.EDITING,
  ProductState.READY,
  ProductState.SALE,
  ProductState.SOLD_OUT,
  ProductState.PAUSED,
  ProductState.REJECTED,
  ProductState.CLOSED,
];

interface ProductFilterModalIProps {
  isMobile: boolean;
  productfilterInput: ProductFilterInput;
  searchValue: string;
  productStatus: {
    type: 'all' | 'select';
    statusIn: ProductState[];
  };
  termWithType: ProductTermWithTypeInput;
  termType: string;
  setTermType: React.Dispatch<React.SetStateAction<string>>;
  handlerSetSearchValue: (e: any) => void;
  handleSetProductStatus: (
    type: 'select' | 'all',
    value?: ProductState,
  ) => void;
  handleSetTermWithType: (type: string) => void;
  handleResetFilterInput: () => void;
  setProductFilterInput: React.Dispatch<
    React.SetStateAction<ProductFilterInput>
  >;
  setIsAcceptFilter: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
}

const ProductFilterModal: React.FC<ProductFilterModalIProps> = props => {
  const {
    isMobile,
    productfilterInput,
    searchValue,
    productStatus,
    termWithType,
    termType,
    setTermType,
    handlerSetSearchValue,
    handleSetProductStatus,
    handleSetTermWithType,
    handleResetFilterInput,
    setProductFilterInput,
    setIsAcceptFilter,
    onClose,
  } = props;

  // const {
  //   searchValue,
  //   productStatus,
  //   termWithType,
  //   handlerSetSearchValue,
  //   handleSetProductStatus,
  //   handleSetTermWithType,
  //   handleResetFilterInput,
  // } = useProductFilterHooks();

  const handleAcceptFilter = () => {
    setProductFilterInput({
      ...productfilterInput,
      statusIn: productStatus.statusIn,
      titleLike: searchValue ? searchValue : null,
      termWithType,
    });
  };

  const renderSearchForm = (): JSX.Element => {
    return (
      <Column gap="12px" align="flex-start">
        <Text color="#1b1c1e" size="16px">
          검색어
        </Text>
        <SearchInputContainer>
          <img
            src={
              searchValue !== ''
                ? ICON_CATEGORY_SEARCH_ACTIVE
                : ICON_CATEGORY_SEARCH
            }
            alt="search icon"
          />
          <SearchInput
            placeholder="프립명을 입력해 주세요"
            value={searchValue}
            onChange={handlerSetSearchValue}
          />
        </SearchInputContainer>
      </Column>
    );
  };

  const renderProductRegistDate = (): JSX.Element => {
    const handleSetTermType = (type: string) => {
      setTermType(type);

      handleSetTermWithType(type);
    };

    return (
      <Column gap="12px" align="flex-start">
        <Text color="#1b1c1e" size="16px">
          상품 등록일
        </Text>
        <Row
          gap="12px"
          justifyContent="flex-start"
          style={{ width: '100%', flexWrap: 'wrap' }}
        >
          <RegistDateSelectButton
            $isSelected={termType === 'all'}
            onClick={() => {
              handleSetTermType('all');
            }}
          >
            전체
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={termType === '1month'}
            onClick={() => {
              handleSetTermType('1month');
            }}
          >
            최근 1개월
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={termType === '6month'}
            onClick={() => {
              handleSetTermType('6month');
            }}
          >
            최근 6개월
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={termType === '1year'}
            onClick={() => {
              handleSetTermType('1year');
            }}
          >
            최근 1년
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={termType === '5year'}
            onClick={() => {
              handleSetTermType('5year');
            }}
          >
            최근 5년
          </RegistDateSelectButton>
        </Row>
      </Column>
    );
  };

  const renderProductStatus = (): JSX.Element => {
    return (
      <Column gap="12px" align="flex-start">
        <Text color="#1b1c1e" size="16px">
          상품 상태
        </Text>
        <Row
          gap="12px"
          justifyContent="flex-start"
          style={{ width: '100%', flexWrap: 'wrap' }}
        >
          <RegistDateSelectButton
            $isSelected={productStatus.type === 'all'}
            style={{ borderRadius: '999px' }}
            onClick={() => handleSetProductStatus('all')}
          >
            전체
          </RegistDateSelectButton>
          {productStatusList.map((item, idx) => {
            return (
              <Row
                onClick={() => handleSetProductStatus('select', item)}
                key={idx}
              >
                <StatusButton
                  status={item}
                  key={idx}
                  isSelected={
                    productStatus.type !== 'all' &&
                    productStatus.statusIn.includes(item)
                  }
                />
              </Row>
            );
          })}
        </Row>
      </Column>
    );
  };

  return (
    <ModalContainer
      isAbled={true}
      isMobile={isMobile}
      onCloseText="초기화"
      onAcceptText="적용"
      onClose={() => {
        setIsAcceptFilter(false);
        handleResetFilterInput();
        setTermType('all');
        handleAcceptFilter();
      }}
      onClick={() => {
        handleAcceptFilter();
        if (
          searchValue !== '' ||
          termType !== 'all' ||
          productStatus.type !== 'all'
        ) {
          setIsAcceptFilter(true);
        } else {
          setIsAcceptFilter(false);
        }

        onClose();
      }}
    >
      <Column gap="12px" align="flex-start" style={{ padding: '0 20px' }}>
        <Row style={{ position: 'relative', padding: '16px 0', width: '100%' }}>
          <img
            src={ICON_CLOSE}
            alt="close icon"
            style={{
              position: 'absolute',
              left: '0',
              top: '16px',
              cursor: 'pointer',
            }}
            onClick={onClose}
          />
          <Text color="#000" size="16px" weight={600}>
            필터
          </Text>
        </Row>
        <Column gap="32px" align="flex-start">
          {renderSearchForm()}
          {renderProductRegistDate()}
          {renderProductStatus()}
        </Column>
      </Column>
    </ModalContainer>
  );
};

export default ProductFilterModal;
