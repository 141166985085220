import { useEffect, useState } from 'react';
import { getEnvConstant } from 'constants/env';
import { SignupParam } from 'containers/Signup/hooks/types';
import { useMutationCreateHost } from 'containers/Login/graphQL/hooks/useMutationCreateHost';
import { useCertifictationHooks } from 'containers/Signup/hooks/useCertificationHooks';
import HackleManager from 'utils/hackle';

export const useGuideHooks = () => {
  const { IMP } = window;

  const { handleHostCertification } = useCertifictationHooks();

  const {
    handleCreateHost,
    isOpenSuccessModal,
    isOpenFailModal,
    setIsOpenSuccessModal,
    setIsOpenFailModal,
  } = useMutationCreateHost();
  const [isOpenHostGuideToast, setIsOpenHostGuideToast] =
    useState<boolean>(false);
  const [isOpenSignupHostToast, setIsOpenSignupHostToast] =
    useState<boolean>(false);
  const [signupParam, setSignupParam] = useState<SignupParam>({
    email: '',
    password: '',
    nickname: '프립 크루',
    mobileNumber: '',
    verificationKey: '',
    certificationKey: '',
    agreement: {
      marketing: false,
    },
    store: 'frip',
  });
  const [isMobileCertification, setIsMobileCertification] =
    useState<boolean>(false);

  const handleChangeSignupParam = (key: string, value: string) => {
    setSignupParam({ ...signupParam, [key]: value });
  };

  const handelCheckedMarketing = (checked: boolean) => {
    setSignupParam({ ...signupParam, agreement: { marketing: checked } });
  };

  const handleMobileCertification = () => {
    IMP.certification({ phone: signupParam.mobileNumber }, (response: any) => {
      if (response.success) {
        handleHostCertification(response.imp_uid, () => {
          HackleManager.logLandingSignupVerificationComplete();

          window.showToast('인증이 완료되었습니다.', 'success');

          setIsMobileCertification(true);
        });
      } else {
        setIsMobileCertification(false);
      }
    });
  };

  const handleIsOpenHostGuideToast = () => {
    setIsOpenHostGuideToast(!isOpenHostGuideToast);
  };

  const handleIsOpenSignupHostToast = () => {
    setIsOpenSignupHostToast(!isOpenSignupHostToast);
  };

  const handleCloseFailModal = () => {
    setIsOpenFailModal(false);
  };

  useEffect(() => {
    IMP.init(getEnvConstant('iamport'));
  }, []);

  return {
    isOpenHostGuideToast,
    isOpenSignupHostToast,
    signupParam,
    isMobileCertification,
    isOpenSuccessModal,
    isOpenFailModal,
    handleChangeSignupParam,
    handelCheckedMarketing,
    handleMobileCertification,
    handleIsOpenHostGuideToast,
    handleIsOpenSignupHostToast,
    handleCreateHost,
    handleCloseFailModal,
  };
};
