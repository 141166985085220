import { gql } from '@apollo/client';

export const CREATE_PRODUCT = gql`
  mutation createProduct($hostId: String!, $param: ProductParamInput!) {
    createProduct(hostId: $hostId, param: $param) {
      id
      title
      status
    }
  }
`;

export const SAVE_PRODUCT = gql`
  mutation saveProduct($id: ID!, $param: ProductParamInput!) {
    saveProduct(id: $id, param: $param) {
      id
      title
      status
    }
  }
`;

export const GET_CONVENIENCES = gql`
  query conveniences {
    product {
      conveniences {
        id
        name
      }
    }
  }
`;

export const STANDARD_CATEGORY = gql`
  query standard($id: ID!, $filter: ChildrenCategoryFilter) {
    category {
      standard(id: $id) {
        id
        depth
        status
        label {
          id
          name
        }
        parent {
          id
          label {
            id
            name
          }
          parent {
            id
            label {
              id
              name
            }
            parent {
              id
              label {
                id
                name
              }
            }
          }
        }
        children(filter: $filter) {
          id
          depth
          status
          label {
            id
            name
          }
        }
      }
    }
  }
`;

export const SEARCH_CATEGORY = gql`
  query standardLeaves($page: Int, $size: Int, $filter: LeafCategoryFilter) {
    category {
      standardLeaves(page: $page, size: $size, filter: $filter) {
        edges {
          node {
            id
            parent {
              id
              label {
                id
                name
              }
              status
              parent {
                id
                label {
                  id
                  name
                }
                status
                parent {
                  id
                  label {
                    id
                    name
                  }
                  parent {
                    id
                    label {
                      id
                      name
                    }
                    parent {
                      id
                      label {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
            label {
              id
              name
            }
            status
          }
        }
        totalCount
      }
    }
  }
`;

export const GET_PRODUCT = gql`
  query product($id: ID!) {
    product {
      product(id: $id) {
        id
        title
        catchphrase
        kind
        status
        saleTerm {
          startedAt
          endedAt
        }
        firstOpenedAt
        underageRestricted
        unverifiedUserRestricted
        cancelingRestricted
        host {
          id
          name
          super
          commission {
            default {
              bias
              charge
              type
            }
            absent {
              bias
              charge
              type
            }
          }
        }
        manager {
          id
          name
        }
        attributes {
          id
          name
        }
        standardCategories {
          id
          label {
            id
            name
          }
        }
        categories {
          displayCategory {
            id
            label {
              id
              name
            }
          }
          representative
        }
        headerContents {
          id
          content {
            id
            uri
          }
        }
        contents {
          id
          content
        }
        options {
          id
          title
          names
        }
        items {
          id
          options {
            id
            name
            title
          }
          saleTerm {
            startedAt
            endedAt
          }
          status
          minimumQuota
          maximumPurchasableCount
          quota
          representative
          daysOfExpiration
          description
          name
          ticketCount
          scheduledBySale
          salesType
          absentFee {
            bias
            charge
            type
          }
          commission {
            bias
            charge
            type
          }
          price {
            currencyCode
            discountRate
            sale
            retail
          }
          digitalContent {
            id
            uri
          }
        }
        externalProvisions {
          id
        }
        grossPurchaseLimitation {
          count
        }
        lastInspection {
          id
          completedAt
          createdAt
          flowVersion
          host {
            id
            name
          }
          items {
            id
            comment
            part
            reasons {
              id
              name
              description
              part
              requiredComment
              status
            }
            status
            worker {
              id
              nickname
            }
          }
          mode
          status
        }
        maximumPurchasableCount
        tags(includeInactive: true) {
          id
          kind
        }
        inquiryTemplate {
          id
        }
        inventoryTargetOptionLevel
        inventoryTargetType
        frip {
          kind
          attachedToSchedule
          bookingConfirmationEnabled
          difficulty
          isIndoor
          isOutdoor
          companions
          interactionType
          locationsOfVenue {
            id
            label
            address
            geoPoint {
              latitude
              longitude
            }
            overseas
            location {
              id
              name
            }
          }
          locationOfGathering {
            id
            label
            address
            geoPoint {
              latitude
              longitude
            }
            overseas
            location {
              id
              name
            }
          }
          plans {
            id
            appliedAllItems
            name
            minimumQuota
            preparatoryPeriod
            quota
            overNight
            term {
              startedAt
              endedAt
            }
            targetItems {
              id
            }
            times {
              starting {
                hour
                minute
              }
              duration
            }
            weeks
          }
          curriculum {
            kind
            sections {
              id
              title
              etc
              status
              items {
                id
                term {
                  startingTime {
                    hour
                    minute
                  }
                  endingTime {
                    hour
                    minute
                  }
                }
                duration
                textTime
                content
              }
            }
            style
          }
          exclusions
          inclusions
          stuffsToPrepare
          daysOfExpiration
          recommendedAge
        }
        keywords
        refundPolicy {
          id
          name
          extraNotice
          description
          representative
          status
          type
        }
        exposures {
          id
        }
        notice
      }
    }
  }
`;

export const GET_PRODUCT_SCHEDULESTERM = gql`
  query productSchedulesByTerm(
    $productId: ID!
    $schedulingTerm: FixedTermInput!
  ) {
    product {
      schedulesByTerm(productId: $productId, schedulingTerm: $schedulingTerm) {
        id
        appliedAllItems
        items {
          id
          name
        }
        plan {
          id
        }
        term {
          startedAt
          endedAt
          duration
        }
        saleTerm {
          duration
          startedAt
          endedAt
        }
        minimumQuota
        quota
        maximumPurchasableCount
        title
        status
      }
    }
  }
`;

export const SAVE_PRODUCT_REFUND_POLICY = gql`
  mutation saveProductRefundPolicy(
    $id: ID!
    $param: ProductRefundPolicyParamInput!
  ) {
    saveProductRefundPolicy(id: $id, param: $param) {
      refundPolicy {
        id
        name
        extraNotice
      }
      message
    }
  }
`;

export const REQUEST_INSPECTION = gql`
  mutation requestInspection($productId: ID!) {
    requestInspection(productId: $productId) {
      id
      completedAt
    }
  }
`;

// 검수 취소
export const CANCEL_INSPECTION = gql`
  mutation cancelInspection($productId: ID!) {
    cancelInspection(productId: $productId) {
      id
      completedAt
    }
  }
`;

// 상품 오픈
export const OPEN_PRODUCT = gql`
  mutation openProduct($id: ID!) {
    openProduct(id: $id) {
      id
      status
    }
  }
`;

// 상품 판매 일시 중지
export const PAUSE_PRODUCT = gql`
  mutation pauseProduct($id: ID!) {
    pauseProduct(id: $id) {
      id
      status
    }
  }
`;

// 상품 판매 종료
export const CLOSE_PRODUCT = gql`
  mutation closeProduct($id: ID!) {
    closeProduct(id: $id) {
      id
      status
    }
  }
`;

// 상품 재오픈
export const REEDIT_PRODUCT = gql`
  mutation reeditProduct($id: ID!) {
    reeditProduct(id: $id) {
      id
      status
    }
  }
`;

// 상품 판매중지
export const SUSPEND_PRODUCT = gql`
  mutation suspendProduct($id: ID!) {
    suspendProduct(id: $id) {
      id
      status
    }
  }
`;

// 상품 판매재개
export const RESUME_PRODUCT = gql`
  mutation resumeProduct($id: ID!) {
    resumeProduct(id: $id) {
      id
      status
    }
  }
`;

// 상품 운영 종료
export const TERMINATE_PRODUCT = gql`
  mutation terminateProduct($id: ID!) {
    terminateProduct(id: $id) {
      id
      status
    }
  }
`;

export const OPEN_ITEM = gql`
  mutation openItem($id: ID!) {
    openItem(id: $id) {
      id
      name
      status
    }
  }
`;

export const CLOSE_ITEM = gql`
  mutation closeItem($id: ID!) {
    closeItem(id: $id) {
      id
      name
      status
    }
  }
`;

export const TERMINATE_ITEM = gql`
  mutation terminateItem($id: ID!) {
    terminateItem(id: $id) {
      id
      name
      status
    }
  }
`;
