import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useQueryGetAccountListByFilter } from "../graphQL/hooks/useQueryGetAccountListByFilter";
import { useQueryGetSettlement } from "../graphQL/hooks/useQueryGetSettlement";
import { cloneDeep } from "lodash";
import { referredType } from "../components/SettlementTable";
import { SettlementsAccountEdges } from "containers/SettlementList/graphQL/types";

export enum FilterType {
  DATE = "date",
  TYPE = "type",
  PRODUCT = "product",
}

export const useSettlementDetailHooks = () => {
  const params = useParams<{ id: string }>();

  const { settlementItem } = useQueryGetSettlement(params.id as string);
  const { accountList } = useQueryGetAccountListByFilter(params.id as string);

  const [filterType, setFilterType] = useState<FilterType>(FilterType.DATE);
  const [sortedAccountList, setSortedAccountList] = useState<
    SettlementsAccountEdges[]
  >([]);

  const handleAccountListFilter = (filterType: FilterType) => {
    setFilterType(filterType);
  };

  useEffect(() => {
    if (accountList) {
      const sortedItems = cloneDeep(accountList.settlement.accounts.edges);

      switch (filterType) {
        case FilterType.DATE: {
          return setSortedAccountList(
            sortedItems.sort((prev, curr) =>
              prev.node.orderItem.transaction.firmedAt >
              curr.node.orderItem.transaction.firmedAt
                ? -1
                : 1
            )
          );
        }
        case FilterType.TYPE: {
          return setSortedAccountList(
            sortedItems.sort((prev, curr) =>
              referredType[prev.node.referredType] >
              referredType[curr.node.referredType]
                ? -1
                : 1
            )
          );
        }
        case FilterType.PRODUCT: {
          return setSortedAccountList(
            sortedItems.sort((prev, curr) =>
              prev.node.title > curr.node.title ? -1 : 1
            )
          );
        }
        default:
          return;
      }
    }
  }, [accountList, filterType]);

  return {
    settlementItem,
    accountList,
    sortedAccountList,
    filterType,
    handleAccountListFilter,
  };
};
