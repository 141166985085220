import React from 'react';
import styled from 'styled-components';

const Container = styled.button<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 99px;
  background-color: ${(props) => (props.$isSelected ? '#1b1c1e' : '#F7F7F8')};

  color: ${(props) => (props.$isSelected ? '#fff' : '#1b1c1e')};

  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.4px;
`;

interface FilterButtonIProps {
  isSelected: boolean;
  content: string | React.ReactElement;
  onClick: () => void;
}

const FilterButton: React.FC<FilterButtonIProps> = (props) => {
  const { isSelected, content, onClick } = props;

  return (
    <Container $isSelected={isSelected} onClick={onClick}>
      {content}
    </Container>
  );
};

export default FilterButton;
