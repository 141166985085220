import { MeData } from 'graphQL/Auth/types';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ProductQnaEdge } from 'types/api';
import { useLazyQueryGetQnaProducts } from '../graphQL/hooks/useLazyQueryGetQnaProducts';
import { useQueryGetQnaCount } from '../graphQL/hooks/useQueryGetQnaCount';
import { useQueryQnaList } from '../graphQL/hooks/useQueryQnaList';
import {
  ProductQanSearchTimeOptions,
  ProductQnaFilter,
  ProductQnaState,
} from '../graphQL/types';

export interface QnaFilter {
  filter?: ProductQnaFilter;
  page: number;
  size: number;
}

export const useQnaListHooks = () => {
  const myInfo: MeData = useUserInformation((state: any) => state.userInfo);
  const location = useLocation();
  const productId = location.search.split('=')[1];

  const { qnaList, qnaFilter, setQnaFilter, handleRefetchQnaList } =
    useQueryQnaList();
  // const { qnaCount } = useQueryGetQnaCount(productId);

  const { handleGetQnaProducts } = useLazyQueryGetQnaProducts();

  const [isOpenCountFilter, setIsOpenCountFilter] = useState<boolean>(false);
  const [selectedQnaItem, setSelectedQnaItem] = useState<ProductQnaEdge>();
  const [isOpenQnaBottomSheet, setIsOpenQnaBottomSheet] =
    useState<boolean>(false);
  const [isOpenQnaFilterBottomSheet, setIsOpenQnaFilterBottomSheet] =
    useState<boolean>(false);

  const handleSetQnaFilter = (key: string, value: any) => {
    setQnaFilter({ ...qnaFilter, page: 1, [key]: value });
  };

  const handleSelectedQnaItem = (item: ProductQnaEdge) => {
    setIsOpenQnaBottomSheet(true);

    setSelectedQnaItem(item);
  };

  const handleIsOpenCountFilter = () => {
    setIsOpenCountFilter(!isOpenCountFilter);
  };

  const handleSetProductQnaItem = (productId: string) => {
    setQnaFilter({
      ...qnaFilter,
      filter: { ...qnaFilter.filter, productIds: [productId] },
    });
  };

  const handleAcceptFilter = (
    searchValue: string,
    statusIn: string,
    term: {
      startedAt: number;
      endedAt: number;
      type: ProductQanSearchTimeOptions;
    } | null,
  ) => {
    const statusItem =
      statusIn === 'NEW'
        ? [ProductQnaState.NEW]
        : statusIn === 'COMPLETE'
        ? [ProductQnaState.COMPLETE]
        : [
            ProductQnaState.NEW,
            ProductQnaState.BLIND,
            ProductQnaState.CONFIRM,
            ProductQnaState.COMPLETE,
          ];

    if (term !== null && searchValue === '') {
      setQnaFilter({
        filter: {
          ...qnaFilter.filter,
          productIds: null,
          hostIds: [myInfo.me.host.id],
          term: term,
          statusIn: statusItem,
        },
        page: 1,
        size: 10,
      });

      return;
    }

    if (searchValue !== '') {
      handleGetQnaProducts(
        myInfo.me.host.id,
        searchValue,
        (param: string[]) => {
          if (term !== null) {
            handleRefetchQnaList({
              ...qnaFilter,
              filter: {
                ...qnaFilter.filter,
                term: term,
                productIds: param,
                statusIn: statusItem,
              },
            });

            return;
          }

          handleRefetchQnaList({
            ...qnaFilter,
            filter: {
              ...qnaFilter.filter,
              productIds: isEmpty(param) ? null : param,
              statusIn: statusItem,
            },
          });
        },
      );

      return;
    }

    setQnaFilter({
      ...qnaFilter,
      filter: { hostIds: [myInfo.me.host.id], statusIn: statusItem },
      page: 1,
      size: 10,
    });
  };

  const handleReFetechList = () => {
    handleRefetchQnaList(qnaFilter);
  };

  return {
    productId,
    isOpenCountFilter,
    qnaList,
    selectedQnaItem,
    isOpenQnaBottomSheet,
    isOpenQnaFilterBottomSheet,
    qnaFilter,
    // qnaCount,
    handleSetQnaFilter,
    setIsOpenQnaFilterBottomSheet,
    setIsOpenQnaBottomSheet,
    handleSelectedQnaItem,
    handleIsOpenCountFilter,
    handleSetProductQnaItem,
    handleAcceptFilter,
    handleReFetechList,
  };
};
