import React, { useEffect } from 'react';
import styled from 'styled-components';

import Column from 'components/common/Column';
import Row from 'components/common/Row';
import {
  ICON_ARROW_BLACK,
  ICON_CLOSE,
  ICON_PRODUCTLIST_COPY,
  ICON_PRODUCTLIST_REGIST,
} from 'assets/icons';
import Text from 'components/common/Text';
import { MeData } from 'graphQL/Auth/types';
import { ProductState } from 'types/api';
import ProductCard from './ProductCard';
import Divider from 'components/Divider';
import { useProductRegistModalHooks } from '../hooks/useProductRegistModalHooks';
import { InView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import { PRODUCT_REGIST_PATH } from 'constants/path';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import HackleManager from 'utils/hackle';

interface ProductRegistModalIProps {
  myInfo: MeData;
  isMobile: boolean;
  onClose: () => void;
}

const ProductRegistModal: React.FC<ProductRegistModalIProps> = props => {
  const { myInfo, isMobile, onClose } = props;
  const navigate = useNavigate();

  const isEditingProduct = myInfo.me.host.productInfo.counts.find(
    item => item.status === ProductState.EDITING,
  );
  const {
    productList,
    isLoadMore,
    viewType,
    setViewType,
    handleLoadMoreProductList,
    handleSetRegistProductList,
    handleGetCopyProductList,
  } = useProductRegistModalHooks(myInfo);

  const renderEditingProductList = (): JSX.Element => {
    if (isEditingProduct !== undefined) {
      return (
        <React.Fragment>
          <Text color="#1b1c1e" size="20px">
            프립 등록 완료하기
          </Text>
          {productList && (
            <Column gap="12px">
              {productList.product.products.edges.map((item, idx) => {
                return <ProductCard key={idx} item={item} type="update" />;
              })}
            </Column>
          )}
          {!isLoadMore && (
            <Text
              color="#1b1c1e"
              size="16px"
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={handleLoadMoreProductList}
            >
              더보기
            </Text>
          )}
          <Divider />
        </React.Fragment>
      );
    }

    return <></>;
  };

  const renderRegistSelectForm = (): JSX.Element => {
    return (
      <Column gap="32px" align="flex-start">
        <Row
          gap="16px"
          justifyContent="space-between"
          style={{ width: '100%', cursor: 'pointer' }}
        >
          <Row
            gap="16px"
            onClick={() => {
              HackleManager.logProductCreateTypeClick('새로운 프립 등록하기');
              navigate(`${PRODUCT_REGIST_PATH}/draft`);
            }}
          >
            <img src={ICON_PRODUCTLIST_REGIST} alt="icon productlist regist" />
            <Text color="#1b1c1e" size="16px" weight={400}>
              새로운 프립 등록하기
            </Text>
          </Row>
          <img src={ICON_ARROW_BLACK} alt="icon arrow black" />
        </Row>
        <Row
          gap="16px"
          justifyContent="space-between"
          style={{ width: '100%', cursor: 'pointer' }}
        >
          <Row
            gap="16px"
            onClick={() => {
              HackleManager.logProductCreateTypeClick('기존 프립 복사하기');
              handleGetCopyProductList();
            }}
          >
            <img src={ICON_PRODUCTLIST_COPY} alt="icon productlist regist" />
            <Text color="#1b1c1e" size="16px" weight={400}>
              기존 프립 복사하기
            </Text>
          </Row>
          <img src={ICON_ARROW_BLACK} alt="icon arrow black" />
        </Row>
      </Column>
    );
  };

  const renderCopyProductList = (): JSX.Element => {
    return (
      <React.Fragment>
        {productList && (
          <Column gap="12px">
            {productList.product.products.edges.map((item, idx) => {
              return <ProductCard key={idx} item={item} type="copy" />;
            })}
            <InView
              onChange={inView => {
                if (
                  inView &&
                  productList.product.products.pageInfo.hasNextPage
                ) {
                  handleLoadMoreProductList();
                }
              }}
            ></InView>
          </Column>
        )}
      </React.Fragment>
    );
  };

  return (
    <ModalContainer
      isAbled={true}
      isMobile={isMobile}
      isBottomSheet={true}
      onClose={onClose}
      onClick={() => {}}
    >
      <Column
        gap="12px"
        align="flex-start"
        style={{ padding: '20px 20px 32px' }}
      >
        <Row
          style={{
            position: 'sticky',
            top: 0,
            padding: '16px 0',
            width: '100%',
            background: '#fff',
          }}
        >
          <img
            src={ICON_CLOSE}
            alt="close icon"
            style={{
              position: 'absolute',
              left: '0',
              top: '16px',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (viewType === 'regist') {
                onClose();

                return;
              }

              setViewType('regist');
              handleSetRegistProductList();
            }}
          />
          <Text color="#000" size="16px" weight={600}>
            {viewType === 'regist' ? '등록하기' : '복사하기'}
          </Text>
        </Row>
        {viewType === 'regist' ? (
          <Column gap="24px" align="flex-start">
            {renderEditingProductList()}
            {renderRegistSelectForm()}
          </Column>
        ) : (
          renderCopyProductList()
        )}
      </Column>
    </ModalContainer>
  );
};

export default ProductRegistModal;
