import React from 'react';
import { isEmpty } from 'lodash';

import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import Divider from 'components/Divider';
import { OrderItem } from 'types/api';

interface InquiresTemplateIProps {
  orderItems: OrderItem;
  type?: string;
}

const InquiresTemplate: React.FC<InquiresTemplateIProps> = props => {
  const { orderItems, type } = props;

  return (
    <Row gap="32px" align="flex-start" style={{ width: '100%' }}>
      {!type && (
        <Text
          color="#46474c"
          size="14px"
          weight={400}
          style={{ width: 'auto', textWrap: 'nowrap' }}
        >
          질문
        </Text>
      )}
      <Column gap="8px" align="flex-start">
        {orderItems.units[0].replies.map((item, idx) => {
          return (
            <Column gap="8px" align="flex-start" key={idx}>
              <Column gap="4px" align="flex-start">
                <Text size="12px">질문</Text>
                <Text color="#46474C" size="14px" weight={400}>
                  {item.title}
                </Text>
              </Column>
              <Column gap="4px" align="flex-start">
                <Text size="12px">답변</Text>
                {!isEmpty(item.selections) && (
                  <Column gap="4px" align="flex-start">
                    {item.selections.map((selectionItem, selectionIdx) => {
                      return (
                        <Text
                          color="#46474C"
                          size="14px"
                          weight={400}
                          key={selectionIdx}
                        >
                          {selectionItem.label}
                        </Text>
                      );
                    })}
                  </Column>
                )}
                {item.answer === '' && isEmpty(item.selections) ? (
                  <Text color="#46474C" size="14px" weight={400}>
                    -
                  </Text>
                ) : (
                  <Text color="#46474C" size="14px" weight={400}>
                    {item.answer}
                  </Text>
                )}
              </Column>
              {orderItems.units[0].replies.length !== idx + 1 && <Divider />}
            </Column>
          );
        })}
      </Column>
    </Row>
  );
};

export default InquiresTemplate;
