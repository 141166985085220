import { gql } from '@apollo/client';

export const GET_QNA_LIST = gql`
  query productQnas($filter: ProductQnaFilter, $page: Int, $size: Int) {
    qna {
      productQnas(filter: $filter, page: $page, size: $size) {
        totalCount
        edges {
          node {
            id
            question
            product {
              host {
                id
                name
              }
              manager {
                id
                name
              }
              createdAt
              saleTerm {
                duration
                startedAt
                endedAt
              }
              headerContents {
                id
                style
                content {
                  id
                  thumbnail(
                    fetchFormat: AUTO
                    crop: FILL
                    devicePixelRatio: 2.0
                    width: 375
                    aspectRatio: "1:1"
                  )
                }
              }
              id
              title
              status
            }
            user {
              id
              nickname
            }
            answer {
              answer
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            secret
            status
          }
          cursor
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const GET_QNA_COUNT = gql`
  query productQnas($filter: ProductQnaFilter, $page: Int, $size: Int) {
    qna {
      productQnas(filter: $filter, page: $page, size: $size) {
        totalCount
      }
    }
  }
`;

export const REGISTER_ANSWER = gql`
  mutation registerProductAnswer($param: AnswerParam!) {
    registerProductAnswer(param: $param) {
      host {
        id
        name
      }
      answer
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_ANSWER = gql`
  mutation modifyProductAnswer($param: AnswerParam!) {
    modifyProductAnswer(param: $param) {
      host {
        id
        name
      }
      answer
      createdAt
      updatedAt
    }
  }
`;

export const GET_QNA_PRODUCTS = gql`
  query product($filter: ProductFilterInput, $page: Int, $size: Int) {
    product {
      products(filter: $filter, page: $page, size: $size) {
        edges {
          node {
            id
          }
        }
        totalCount
      }
    }
  }
`;
