import React, { useEffect } from 'react';
import FripRegistButton from '../common/FripRegistButton';
import SelectButton from '../common/SelectButton';

import Column from 'components/common/Column';
import Text from 'components/common/Text';
import Row from 'components/common/Row';
import {
  ICON_PRODUCT_HOSTING_CLASS,
  ICON_PRODUCT_HOSTING_MEET,
  ICON_PRODUCT_HOSTING_SERVICE,
  ICON_PRODUCT_HOSTING_SHIP,
  ICON_PRODUCT_HOSTING_TOUR,
  ICON_QUESTION,
} from 'assets/icons';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import { ProductKind } from 'types/api';
import HackleManager from 'utils/hackle';
import { useParams } from 'react-router-dom';

interface ChapterTwoIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  setProductParamInput: React.Dispatch<React.SetStateAction<ProductParamInput>>;
}

const ChapterTwo: React.FC<ChapterTwoIProps> = props => {
  const { isMobile, productParamInput, setProductParamInput } = props;

  const params = useParams<{ id: string }>();

  const handleChangeParamInput = (value: any) => {
    if (productParamInput.kind === ProductKind.GOODS) {
      setProductParamInput({
        ...productParamInput,
        attributeIds: [...value],
        kind: null,
      });

      return;
    }

    if (productParamInput.attributeIds.includes(value)) {
      const filterItem = productParamInput.attributeIds.filter(
        item => item !== value,
      );

      setProductParamInput({
        ...productParamInput,
        attributeIds: filterItem,
      });

      return;
    }

    setProductParamInput({
      ...productParamInput,
      attributeIds: [...productParamInput.attributeIds, value],
    });
  };

  useEffect(() => {
    HackleManager.logCreateStepOneHostTypeView(
      params.id !== 'draft' ? Number(params.id) : undefined,
    );
  }, []);

  const renderContents = (): JSX.Element => {
    return (
      <React.Fragment>
        <FripRegistButton
          title="모임"
          description="같은 관심사를 가진 사람들과의 만남을 위한 프립 (독서 모임, 와인 파티, 러닝 모임 등)"
          thumbnail={ICON_PRODUCT_HOSTING_MEET}
          isSelected={
            productParamInput.kind !== ProductKind.GOODS &&
            (productParamInput.attributeIds.includes('1') ||
              productParamInput.attributeIds.includes('5'))
          }
          onClick={() => {
            handleChangeParamInput('1');
          }}
        />
        <FripRegistButton
          title="클래스"
          description="배우고 익히는 수업 형태의 프립 (원데이 클래스, 요가 클래스, 보컬 레슨 등)"
          thumbnail={ICON_PRODUCT_HOSTING_CLASS}
          isSelected={
            productParamInput.attributeIds.includes('2') ||
            productParamInput.attributeIds.includes('6')
          }
          onClick={() => {
            handleChangeParamInput('2');
          }}
        />
        <FripRegistButton
          title="체험/서비스"
          description="특별한 체험이나 서비스를 제공하는 프립 (낚시 체험, 박물관 관람, 피부 개선 프로그램 등)"
          thumbnail={ICON_PRODUCT_HOSTING_SERVICE}
          isSelected={
            productParamInput.attributeIds.includes('3') ||
            productParamInput.attributeIds.includes('7')
          }
          onClick={() => {
            handleChangeParamInput('3');
          }}
        />
        <FripRegistButton
          title="여행(투어)"
          description="국내 또는 해외 여행과 관련된 프립 (버스 투어, 일출 여행, 현지 가이드 동행 투어 등)"
          thumbnail={ICON_PRODUCT_HOSTING_TOUR}
          isSelected={
            productParamInput.attributeIds.includes('4') ||
            productParamInput.attributeIds.includes('8')
          }
          onClick={() => {
            handleChangeParamInput('4');
          }}
        />

        <FripRegistButton
          title="배송"
          description="상품을 배송하는 프립 (DIY 키트, 밀키트 등)"
          thumbnail={ICON_PRODUCT_HOSTING_SHIP}
          isSelected={productParamInput.kind === ProductKind.GOODS}
          onClick={() => {
            if (productParamInput.kind !== ProductKind.GOODS) {
              setProductParamInput({
                ...productParamInput,
                attributeIds: [],
                kind: ProductKind.GOODS,
                // standardCategoryId: '0003',
              });
            } else {
              setProductParamInput({
                ...productParamInput,
                kind: null,
              });
            }
          }}
        />
      </React.Fragment>
    );
  };

  const renderResponsivePage = (): JSX.Element => {
    if (isMobile) {
      return (
        <React.Fragment>
          <Text size="24px" color="#1b1c1e" style={{ lineHeight: '32px' }}>
            어떤 프립을 호스팅 할까요?
          </Text>
          <Column gap="12px" align="flex-start">
            {renderContents()}
            <Text color="#989ba2" size="12px" weight={400}>
              중복 선택이 가능해요
            </Text>
          </Column>
        </React.Fragment>
      );
    }

    return (
      <Row
        gap="64px"
        style={{ padding: '32px 0', width: '100%' }}
        align="flex-start"
      >
        <Column gap="32px" align="flex-start">
          <Text size="24px" color="#1b1c1e" style={{ lineHeight: '32px' }}>
            어떤 프립을 호스팅 할까요?
          </Text>
          <Column gap="12px" align="flex-start">
            {renderContents()}
            <Text color="#989ba2" size="12px" weight={400}>
              중복 선택이 가능해요
            </Text>
          </Column>
        </Column>
        {/* <PreviewProductDetail /> */}
      </Row>
    );
  };

  return renderResponsivePage();
};

export default ChapterTwo;
