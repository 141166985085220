import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useResizeDetector } from 'react-resize-detector';
import styled from 'styled-components';

import { SETTLEMENT_PATH } from 'constants/path';
import { callWebviewHandler, WebviewOperatorType } from 'utils/webview';
import { useRegistBankInformationHook } from './hooks/useRegistBankInformationHook';
import CommonDialog from 'components/Modal/CommonDialog';
import Header from 'containers/SettlementRegist/components/Header';
import RegistBankInformation from './components/RegistBankInformation';
import Column from 'components/common/Column';
import CommonRow from 'components/common/Row';
import CommonImage from 'components/common/CommonImage';
import { ICON_FRIP_LOGO } from 'assets/icons';
import SelectButton from 'containers/ProductRegist/components/common/SelectButton';
import HackleManager from 'utils/hackle';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* max-width: 768px; */
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 85px;
`;

const BottomContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  padding: 16px 20px;
  margin: 0 auto;
  max-width: 768px;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #ddd;
  z-index: 5;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #1b1c1e;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.6px;

  span {
    text-decoration-line: underline;
  }
`;

const NextButton = styled.div<{ $isActiveButton: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  color: ${props => (props.$isActiveButton ? '#fff' : '#dbdcdf')};
  font-weight: 700;
  text-decoration-line: none;
  border-radius: 8px;
  background: ${props => (props.$isActiveButton ? '#1B1C1E' : '#f4f4f5')};
`;

const SettlementBankRegistPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    bankInformation,
    isVerifiedFailed,
    isOpenBankModal,
    setIsVerifiedFailed,
    checkValidation,
    handleAcceptBankCode,
    handleOnChangeAccountNo,
    handleOnClickVerifyBankAccount,
    handleChangeOpenBankModal,
  } = useRegistBankInformationHook();

  const { width, ref } = useResizeDetector();

  const isMobile = width ? width < 768 : window.innerWidth < 768;

  const handleOnCloseWebview = () => {
    // if (isWebview()) {
    if (isOpenBankModal) {
      handleChangeOpenBankModal(false);

      return;
    }

    if (history.length > 1) {
      navigate(SETTLEMENT_PATH);

      return;
    }

    // callWebviewHandler(WebviewOperatorType.CloseWebView);
    // } else {
    //   history.goBack();
    // }
  };

  useEffect(() => {
    window.handlePageBackwardNavigate = handleOnCloseWebview;
  }, [isOpenBankModal]);

  useEffect(() => {
    HackleManager.logSettlementReigstBankView();
  }, []);

  const renderContents = () => {
    if (isMobile) {
      return (
        <Column>
          <Header title="정산 계좌 등록" />
          <RegistBankInformation
            bankInformation={bankInformation}
            isOpenBankModal={isOpenBankModal}
            handleAcceptBankCode={handleAcceptBankCode}
            handleOnChangeAccountNo={handleOnChangeAccountNo}
            handleChangeOpenBankModal={handleChangeOpenBankModal}
          />
          <BottomContainer
            style={{
              padding: window.webkit && '16px 20px 32px 20px',
            }}
          >
            <Row>
              <span onClick={handleOnCloseWebview}>뒤로</span>
              <NextButton
                $isActiveButton={checkValidation()}
                onClick={handleOnClickVerifyBankAccount}
              >
                {location.state && location.state.isModify ? '수정' : '등록'}
              </NextButton>
            </Row>
          </BottomContainer>
        </Column>
      );
    }

    return (
      <Column>
        <CommonRow
          justifyContent="center"
          style={{
            position: 'sticky',
            top: 0,
            padding: '24px',
            width: '100%',
            background: '#fff',
            borderBottom: '1px solid #dbdcdf',
          }}
        >
          <CommonRow
            justifyContent="space-between"
            style={{ padding: '0 40px', width: '100%', maxWidth: '1280px' }}
          >
            <CommonImage
              src={ICON_FRIP_LOGO}
              onClick={() => {
                navigate('/');
              }}
            />
            <SelectButton
              text="나가기"
              onClick={() => {
                handleOnCloseWebview();
              }}
            />
          </CommonRow>
        </CommonRow>
        <Column style={{ padding: '0 20px', maxWidth: '768px' }}>
          <RegistBankInformation
            bankInformation={bankInformation}
            isOpenBankModal={isOpenBankModal}
            handleAcceptBankCode={handleAcceptBankCode}
            handleOnChangeAccountNo={handleOnChangeAccountNo}
            handleChangeOpenBankModal={handleChangeOpenBankModal}
          />
        </Column>
        <BottomContainer
          style={{
            justifyContent: 'center',
            maxWidth: '100%',
          }}
        >
          <CommonRow
            justifyContent="flex-end"
            style={{ padding: '0 40px', width: '100%', maxWidth: '1280px' }}
          >
            <NextButton
              $isActiveButton={checkValidation()}
              onClick={handleOnClickVerifyBankAccount}
            >
              {location.state && location.state.isModify ? '수정' : '등록'}
            </NextButton>
          </CommonRow>
        </BottomContainer>
      </Column>
    );
  };

  return (
    <Container ref={ref}>
      {renderContents()}
      {isVerifiedFailed && (
        <CommonDialog
          title="계좌번호를 다시 확인해주세요"
          onAcceptText="확인했어요"
          onAccept={() => setIsVerifiedFailed(false)}
        />
      )}
    </Container>
  );
};

export default SettlementBankRegistPage;
