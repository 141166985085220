import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ProductQnaEdge, ProductReviewEdge } from 'types/api';
import { useQueryReviewList } from '../graphQL/hooks/useQueryReviewList';

export const useReviewListHooks = () => {
  const location = useLocation();
  const productId = location.search.split('=')[1];

  const { reviewList, handleRefetchReviewList } = useQueryReviewList(productId);

  const [reviewFilter, setReviewFilter] = useState<{
    page: number;
    size: number;
    filter?: any;
  }>({ page: 1, size: 10 });
  const [isOpenCountFilter, setIsOpenCountFilter] = useState<boolean>(false);
  const [selectedReviewItem, setSelectedReviewItem] =
    useState<ProductReviewEdge>();

  const [isOpenReviewBottomSheet, setIsOpenReviewBottomSheet] =
    useState<boolean>(false);
  const [isOpenReviewFilterBottomSheet, setIsOpenReviewFilterBottomSheet] =
    useState<boolean>(false);

  const handleSetReviewFilter = (key: string, value: any) => {
    setReviewFilter({ ...reviewFilter, page: 1, [key]: value });
  };

  const handleIsOpenCountFilter = () => {
    setIsOpenCountFilter(!isOpenCountFilter);
  };

  const handleSelectedReviewItem = (item: ProductReviewEdge) => {
    setIsOpenReviewBottomSheet(true);

    setSelectedReviewItem(item);
  };

  const handleAcceptFilter = (content: string, ratingsIn: number[]) => {
    if (content !== '') {
      handleRefetchReviewList({
        ...reviewFilter,
        filter: { ...reviewFilter.filter, contentLike: content, ratingsIn },
      });

      return;
    }

    handleRefetchReviewList({
      ...reviewFilter,
      filter: { ...reviewFilter.filter, ratingsIn },
    });
  };

  const handleRefetchList = () => {
    handleRefetchReviewList(reviewFilter);
  };

  useEffect(() => {
    handleRefetchList();
  }, [reviewFilter]);

  return {
    reviewFilter,
    reviewList,
    isOpenCountFilter,
    isOpenReviewBottomSheet,
    selectedReviewItem,
    isOpenReviewFilterBottomSheet,
    handleSetReviewFilter,
    setIsOpenReviewBottomSheet,
    setIsOpenReviewFilterBottomSheet,
    handleIsOpenCountFilter,
    handleSelectedReviewItem,
    handleAcceptFilter,
    handleRefetchList,
  };
};
