import React from 'react';
import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;

  border-radius: 4px;
  border: 1px solid var(--gray-gray-03, #eaebec);
  background: var(--gray-gray-01, #f7f7f8);

  color: var(--gray-gray-09, #1b1c1e);

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.4px;
`;

const SelectionButton = () => {
  return <Container>선택사항</Container>;
};

export default SelectionButton;
