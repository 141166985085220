import { useLazyQuery, useQuery } from "@apollo/client";
import { MeData } from "graphQL/Auth/types";
import { useUserInformation } from "graphQL/Zustand/useUserInformation";
import { useEffect } from "react";
import { GET_HOST_DASHBOARD } from "..";
import { HostDashBoard } from "../types";

export function useQueryGetHostDashBoard() {
  const myInfo: MeData = useUserInformation((state) => state.userInfo);

  const [getHostDashBoard, { data }] =
    useLazyQuery<HostDashBoard>(GET_HOST_DASHBOARD);

  useEffect(() => {
    if (myInfo.me.host) {
      getHostDashBoard({ variables: { hostId: myInfo.me.host.id } });
    }
  }, [myInfo.me.host]);

  return { hostDashBoard: data };
}
