import {
  CompanionType,
  InteractionType,
  ItemInquiryInputKind,
  LevelOfDifficulty,
  ProductState,
} from 'types/api';
import { PHONE_SECURITY_REGEX } from './regExp';

export const formatDifficulty = (difficulty: LevelOfDifficulty): string => {
  switch (difficulty) {
    case LevelOfDifficulty.VERY_EASY: {
      return '매우 쉬움';
    }
    case LevelOfDifficulty.EASY: {
      return '쉬움';
    }
    case LevelOfDifficulty.NORMAL: {
      return '중간';
    }
    case LevelOfDifficulty.HIGH: {
      return '어려움';
    }
    default:
      return '없음';
  }
};

export const formatInquiriesKind = (
  inputKind: ItemInquiryInputKind,
): string => {
  switch (inputKind) {
    case ItemInquiryInputKind.TEXT: {
      return '주관식';
    }
    case ItemInquiryInputKind.SINGLE_CHOICE: {
      return '객관식';
    }
    case ItemInquiryInputKind.MULTI_CHOICE: {
      return '객관식(다중)';
    }
    default:
      return '없음';
  }
};

export const formatCompanions = (companions: CompanionType): string => {
  switch (companions) {
    case CompanionType.ALONE: {
      return '혼자';
    }
    case CompanionType.WITH_FRIENDS: {
      return '친구와';
    }
    case CompanionType.WITH_LOVER: {
      return '연인과';
    }
    case CompanionType.WITH_CHILDREN: {
      return '아이와';
    }
    case CompanionType.WITH_DOG: {
      return '반려견과';
    }
  }
};

export const formatInteractionType = (
  interactionType: InteractionType,
): string => {
  switch (interactionType) {
    case InteractionType.VIDEO_CHAT: {
      return '화상 채팅';
    }
    case InteractionType.PHONE_CALL: {
      return '전화';
    }
    case InteractionType.VIDEO: {
      return '영상 제공';
    }
    case InteractionType.CHATTING_GUIDE: {
      return '채팅을 통한 별도 안내';
    }
  }
};

export const dateFormattingForm = (): string => {
  return 'YYYY-MM-DD HH:mm';
};

export const formatModifyText = (status: ProductState): string => {
  switch (status) {
    case ProductState.INSPECTING: {
      return '검수 취소';
    }
    case ProductState.READY: {
      return '판매 시작';
    }
    case ProductState.SALE: {
      return '일시 중지';
    }
    case ProductState.PAUSED: {
      return '판매 재시작';
    }
    case ProductState.PAUSED: {
      return '판매 재시작';
    }
    case ProductState.SOLD_OUT: {
      return '옵션 수정';
    }
    case ProductState.SUSPENDED: {
      return '고객센터 문의하기';
    }
  }

  return '일시 중지';
};

export const formatDialogText = (status: ProductState): string => {
  switch (status) {
    case ProductState.INSPECTING: {
      return '네, 취소할게요';
    }
    case ProductState.READY: {
      return '네, 시작할게요';
    }
    case ProductState.SALE: {
      return '네, 일시 중지 할게요';
    }
    case ProductState.PAUSED: {
      return '네, 시작할게요';
    }
    case ProductState.SUSPENDED: {
      return '네, 문의할게요';
    }
  }

  return '네, 시작할게요';
};

export const renderDialogTitle = (status: ProductState): string => {
  switch (status) {
    case ProductState.INSPECTING: {
      return '검수 요청을 취소할까요?';
    }
    case ProductState.READY: {
      return '판매를 시작할까요?';
    }
    case ProductState.SALE: {
      return '판매를 일시 중지 할까요?';
    }
    case ProductState.PAUSED: {
      return '판매를 다시 시작할까요?';
    }
    case ProductState.SUSPENDED: {
      return '고객센터에 문의할까요?';
    }
  }

  return;
};

export const renderDialogDescription = (status: ProductState): string => {
  switch (status) {
    case ProductState.INSPECTING: {
      return `검수 요청을 취소하면 '등록 중' 상태로 변경되며, 언제든지 다시 검수 요청을 할 수 있어요.`;
    }
    case ProductState.READY: {
      return `판매를 시작하면 '판매중' 상태로 변경되며, 크루에게 상품이 노출돼요.`;
    }
    case ProductState.SALE: {
      return '일시 중지 시 크루에게 상품이 노출되지 않아요. 판매중 상태로 변경 시 다시 판매를 시작할 수 있어요.';
    }
    case ProductState.PAUSED: {
      return `판매를 다시 시작하면 '판매중' 상태로 변경되며, 크루에게 상품이 노출돼요.`;
    }
    case ProductState.SUSPENDED: {
      return '관리자가 판매를 일시중지한 상태입니다. 자세한 문의는 고객센터로 문의해 주세요.';
    }
  }

  return;
};

export const formatPhoneNumber = (phoneNumber): string => {
  return phoneNumber.replace(PHONE_SECURITY_REGEX, '$1-$2-$3');
};
