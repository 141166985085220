import React from 'react';
import { styled } from 'styled-components';
import { RegistPageNumber } from '../hooks/useProductRegistHooks';

import Row from 'components/common/Row';
import Text from 'components/common/Text';
import AuthButton from 'components/Button/AuthButton';
import { useLocation, useParams } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  /* z-index: 15; */
`;

const ProgressBar = styled.div`
  position: relative;
  width: 100%;
  height: 5px;
  background: #eaebec;
`;

const FillProgressBar = styled.div<{ $pageNumber: number }>`
  position: absolute;
  width: ${props => props.$pageNumber * 20}%;
  height: 5px;
  background-color: #1b1c1e;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 16px 20px;
  width: 100%;
`;

const StartButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  border-radius: 8px;
  background: #7126ff;

  color: #fff;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.6px;
`;

const TooltipButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: -30px;
  padding: 8px 10px;

  border-radius: 6px;
  background: #7126ff;
  color: var(--White_10, #fff);

  /* Label/L-13-Medium */
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.4px;

  &::after {
    content: '';
    position: absolute;
    bottom: -18px; /* 삼각형 위치 */
    right: 10px;
    transform: translateX(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: #7126ff transparent transparent transparent;
  }
`;

interface FloatingContainerIProps {
  registPageNumber: RegistPageNumber;
  setRegistPageNumber: React.Dispatch<React.SetStateAction<RegistPageNumber>>;
  isVaildCheckBasicPage: () => boolean;
  handleNavigateBackwardPage: () => void;
  handleNavigatePage: () => void;
}

const FloatingContainer: React.FC<FloatingContainerIProps> = props => {
  const {
    registPageNumber,
    setRegistPageNumber,
    isVaildCheckBasicPage,
    handleNavigateBackwardPage,
    handleNavigatePage,
  } = props;

  const location = useLocation();
  const params = useParams<{ id: string }>();

  const renderButtonContainer = (): JSX.Element => {
    if (registPageNumber.page === 1) {
      switch (registPageNumber.basicPageNumber.page) {
        case 0: {
          return (
            <ButtonContainer style={{ justifyContent: 'flex-end' }}>
              {location.state && !location.state.isCopy ? (
                <StartButton
                  style={{ background: '#1b1c1e' }}
                  onClick={() => {
                    setRegistPageNumber({
                      ...registPageNumber,
                      basicPageNumber: {
                        page: registPageNumber.basicPageNumber.page + 1,
                      },
                    });
                  }}
                >
                  이어서 등록하기
                </StartButton>
              ) : (
                <StartButton
                  onClick={() => {
                    setRegistPageNumber({
                      ...registPageNumber,
                      basicPageNumber: {
                        page: registPageNumber.basicPageNumber.page + 1,
                      },
                    });
                  }}
                >
                  시작하기
                </StartButton>
              )}
            </ButtonContainer>
          );
        }
        default: {
          return (
            <ButtonContainer>
              <Text
                color="#1b1c1e"
                size="16px"
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => {
                  handleNavigateBackwardPage();
                }}
              >
                뒤로
              </Text>
              <AuthButton
                name="다음"
                isAbled={isVaildCheckBasicPage()}
                onClick={() => {
                  if (isVaildCheckBasicPage()) {
                    handleNavigatePage();
                  }
                }}
                style={{ width: '76px' }}
              />
            </ButtonContainer>
          );
        }
      }
    }

    return (
      <ButtonContainer>
        <Text
          color="#1b1c1e"
          size="16px"
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => {
            handleNavigateBackwardPage();
          }}
        >
          뒤로
        </Text>
        {registPageNumber.informationPageNumber.page === 6 && (
          <TooltipButton>상품 등록의 마지막 단계에요</TooltipButton>
        )}
        <AuthButton
          name={
            registPageNumber.informationPageNumber.page === 7
              ? params.id !== 'draft'
                ? '상품 저장하기'
                : '상품 등록하기'
              : '다음'
          }
          isAbled={isVaildCheckBasicPage()}
          onClick={() => {
            if (isVaildCheckBasicPage()) {
              handleNavigatePage();
            }
          }}
          style={{
            width:
              registPageNumber.informationPageNumber.page === 7
                ? 'auto'
                : '76px',
          }}
        />
      </ButtonContainer>
    );
  };

  return (
    <Container>
      <Row gap="6px">
        <ProgressBar>
          <FillProgressBar
            $pageNumber={registPageNumber.basicPageNumber.page}
          />
        </ProgressBar>
        <ProgressBar>
          <FillProgressBar
            $pageNumber={registPageNumber.optionPageNumber.page}
          />
        </ProgressBar>
        <ProgressBar>
          <FillProgressBar
            $pageNumber={registPageNumber.informationPageNumber.page}
            style={{
              width: `${registPageNumber.informationPageNumber.page * 14.286}%`,
            }}
          />
        </ProgressBar>
      </Row>
      {renderButtonContainer()}
    </Container>
  );
};

export default FloatingContainer;
