import { useQuery } from '@apollo/client';
import { GET_BANK_INFORMATION } from '..';

export function useQueryGetBankInformation() {
  const { data, loading } = useQuery(GET_BANK_INFORMATION);

  return {
    bankInformation: data,
    isLoading: loading,
  };
}
