import { useMutation } from '@apollo/client';

import { VERIFY_BANK_ACCOUNT } from '..';
import { useMutationSaveHostBankAccount } from './useMutationSaveHostBankAccount';
import { BankAccountVerifyParamInput } from '../type';
import { errorHandler } from 'utils/ErrorHandler';
import HackleManager from 'utils/hackle';

export function useMutationVerifyBankAccount() {
  const { handleSaveHostBankAccount } = useMutationSaveHostBankAccount();

  const [verifyBankAccount] = useMutation(VERIFY_BANK_ACCOUNT);

  const handleVerifyBankAccount = async (
    hostId: string,
    param: BankAccountVerifyParamInput,
    callback: () => void,
  ) => {
    try {
      const response = await verifyBankAccount({
        variables: {
          param,
        },
      });

      if (response.data.verifyBankAccount.success) {
        handleSaveHostBankAccount(hostId, { ...param, verified: true });

        HackleManager.logSettlementReigstBankUpdateComplete();
      } else {
        callback();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  return {
    handleVerifyBankAccount,
  };
}
