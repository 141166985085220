import React from 'react';
import { useNavigate } from 'react-router-dom';

import { IMAGE_REGIST_PRODUCT_COMPLETE } from 'assets/images';
import Column from 'components/common/Column';
import Text from 'components/common/Text';
import ModalContainer from '../common/ModalContainer';
import { PRODUCT_LIST_PATH } from 'constants/path';
import { ICON_TOAST_SUCCESS } from 'assets/icons';

const InspectionCompleteModal = () => {
  const navigate = useNavigate();

  return (
    <ModalContainer
      isMobile={false}
      isAbled={true}
      onCloseText=" "
      onAcceptText="네, 확인했어요"
      onClose={() => {}}
      onClick={() => {
        navigate(PRODUCT_LIST_PATH);
      }}
      style={{ gap: 0 }}
    >
      <Column gap="16px" style={{ padding: '20px 24px' }}>
        <img
          src={ICON_TOAST_SUCCESS}
          alt="regist complete"
          style={{ width: '64px' }}
        />
        <Column gap="8px" align="flex-start">
          <Text color="#1b1c1e" size="20px" weight={600}>
            검수요청이 완료되었습니다.
          </Text>
          <Text color="#1b1c1e" size="16px" weight={400}>
            검수는 영업일 기준 3~7일이 소요되며, 설정하신 판매 기간에 따라
            판매가 시작됩니다.
          </Text>
        </Column>
      </Column>
    </ModalContainer>
  );
};

export default InspectionCompleteModal;
