import { REGISTER_ANSWER, UPDATE_ANSWER } from './../index';
import { useMutation } from '@apollo/client';

export function useMutationAnswer() {
  const [registAnswer] = useMutation(REGISTER_ANSWER);
  const [modifyAnswer] = useMutation(UPDATE_ANSWER);

  const handleRegistAnswer = async (
    id: string,
    answer: string,
    callback: () => void
  ) => {
    try {
      const response = await registAnswer({
        variables: { param: { id, answer } },
      });

      if (response) {
        window.showToast('답변이 완료되었습니다.', 'success');

        callback();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateAnswer = async (
    id: string,
    answer: string,
    callback: () => void
  ) => {
    try {
      const response = await modifyAnswer({
        variables: { param: { id, answer } },
      });

      if (response) {
        window.showToast('답변이 완료되었습니다.', 'success');

        callback();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return { handleRegistAnswer, handleUpdateAnswer };
}
