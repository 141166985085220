import React, { ReactElement } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  padding: 0 12px;
  width: 100%;
  max-width: 768px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 20;
  font-family: Pretendard;
`;

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  padding: 20px 0;
  width: 100%;

  background-color: #fff;
  border-radius: 8px;
`;

const Title = styled.div`
  color: #1b1c1e;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.096px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  border-top: 1px solid #dbdcdf;
  border-bottom: 1px solid #dbdcdf;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
`;

const CloseText = styled.div`
  color: #1b1c1e;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.6px;
  text-decoration-line: underline;
`;

const AcceptButton = styled.div`
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  background: #1b1c1e;

  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  letter-spacing: -0.6px;
`;

interface CommonDialogIProps {
  title: string;
  description: ReactElement;
  acceptText: string | ReactElement;
  onClose: () => void;
  onAccept?: () => void;
}

const CommonElementDialog: React.FC<CommonDialogIProps> = (props) => {
  const { title, description, acceptText, onClose, onAccept } = props;

  return (
    <Container>
      <DialogContainer>
        <Title>{title}</Title>
        <DescriptionContainer>{description}</DescriptionContainer>
        <ActionContainer>
          <CloseText onClick={onClose}>닫기</CloseText>
          <AcceptButton
            onClick={() => {
              onAccept && onAccept();
              onClose();
            }}
          >
            {acceptText}
          </AcceptButton>
        </ActionContainer>
      </DialogContainer>
    </Container>
  );
};

export default CommonElementDialog;
