import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';

import Column from 'components/common/Column';
import Row from 'components/common/Row';

import {
  ICON_TERM_CHECKED_ABLE,
  ICON_TERM_CHECKED_DISABLE,
  ICON_SIGNUP_TERMCHECKED,
  ICON_ARROW_GRAY_BOLD,
  ICON_SIGNUP_TERMCHECKED_DISABLED,
} from 'assets/icons';
import Text from 'components/common/Text';
import { cloneDeep } from 'lodash';
import { INDIVIDUAL_NOTICE, TERM_NOTICE_PATH } from 'constants/path';

const Container = styled.div<{ checked: boolean }>`
  display: flex;
  align-items: center;
  padding: 16px 20px;
  gap: 5px;
  width: 100%;

  border-radius: 12px;
  border: 1px solid ${props => (props.checked ? '#1B1C1E' : '#dbdcdf')};
  background: ${props => (props.checked ? '#F7F7F8' : '#fff')};

  color: var(--gray-gray-08, #1b1c1e);

  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.4px;
`;

const ImageContainer = styled.img`
  cursor: pointer;
`;

interface SignupTermIProps {
  marketingChecked: boolean;
  handleChangeMarketing: (checked: boolean) => void;
  callback?: (isTermChecked: boolean) => void;
}

const SignupTerm: React.FC<SignupTermIProps> = props => {
  const { marketingChecked, handleChangeMarketing, callback } = props;

  const [termAllChecked, setTermAllchecked] = useState<boolean>(false);
  const [termChecked, setTermChecked] = useState<string[]>([]);

  const handleAllChecked = () => {
    if (!termAllChecked) {
      setTermAllchecked(true);

      setTermChecked(['hostTerm', 'personalTerm', 'adultTerm']);
      handleChangeMarketing(true);
      callback && callback(true);

      return;
    }

    setTermAllchecked(false);

    setTermChecked([]);
    handleChangeMarketing(false);
    callback && callback(false);
  };

  const handleTermChecked = (termName: string) => {
    if (termChecked.includes(termName)) {
      const filterItem = cloneDeep(
        termChecked.filter(item => item !== termName),
      );

      setTermChecked(filterItem);

      return;
    }

    setTermChecked([...termChecked, termName]);
  };

  useEffect(() => {
    if (termChecked.length === 3) {
      callback && callback(true);

      if (marketingChecked) {
        setTermAllchecked(true);
      } else {
        setTermAllchecked(false);
      }

      return;
    } else {
      setTermAllchecked(false);
    }

    callback && callback(false);
  }, [termChecked, marketingChecked]);

  return (
    <Column gap="12px">
      <Container checked={termAllChecked}>
        <ImageContainer
          src={
            termAllChecked ? ICON_TERM_CHECKED_ABLE : ICON_TERM_CHECKED_DISABLE
          }
          alt="term disable icon"
          onClick={handleAllChecked}
        />
        <span>전체 동의</span>
      </Container>
      <Column gap="8px">
        <Row style={{ width: '100%' }} justifyContent="space-between">
          <Row gap="8px">
            <ImageContainer
              src={
                termChecked.includes('hostTerm')
                  ? ICON_SIGNUP_TERMCHECKED
                  : ICON_SIGNUP_TERMCHECKED_DISABLED
              }
              alt="icon signup term checked"
              onClick={() => {
                handleTermChecked('hostTerm');
              }}
            />
            <a href={TERM_NOTICE_PATH} target="_blank">
              <Text color="#1b1c1e" size="12px" weight={400}>
                호스트 이용약관 및 운영 정책에 동의합니다.{' '}
                <span style={{ color: '#C2C4C8' }}>(필수)</span>
              </Text>
            </a>
          </Row>
          <a href={TERM_NOTICE_PATH} target="_blank">
            <ImageContainer src={ICON_ARROW_GRAY_BOLD} alt="icon arrow bold" />
          </a>
        </Row>
        <Row style={{ width: '100%' }} justifyContent="space-between">
          <Row gap="8px">
            <ImageContainer
              src={
                termChecked.includes('personalTerm')
                  ? ICON_SIGNUP_TERMCHECKED
                  : ICON_SIGNUP_TERMCHECKED_DISABLED
              }
              alt="icon signup term checked"
              onClick={() => {
                handleTermChecked('personalTerm');
              }}
            />
            <a href={INDIVIDUAL_NOTICE} target="_blank">
              <Text color="#1b1c1e" size="12px" weight={400}>
                개인정보 수집 및 처리에 동의합니다.{' '}
                <span style={{ color: '#C2C4C8' }}>(필수)</span>
              </Text>
            </a>
          </Row>
          <a href={INDIVIDUAL_NOTICE} target="_blank">
            <ImageContainer src={ICON_ARROW_GRAY_BOLD} alt="icon arrow bold" />
          </a>
        </Row>
        <Row style={{ width: '100%' }} justifyContent="space-between">
          <Row gap="8px">
            <ImageContainer
              src={
                termChecked.includes('adultTerm')
                  ? ICON_SIGNUP_TERMCHECKED
                  : ICON_SIGNUP_TERMCHECKED_DISABLED
              }
              alt="icon signup term checked"
              onClick={() => {
                handleTermChecked('adultTerm');
              }}
            />
            <Text color="#1b1c1e" size="12px" weight={400}>
              본인은 만 19세 이상입니다.{' '}
              <span style={{ color: '#C2C4C8' }}>(필수)</span>
            </Text>
          </Row>
        </Row>
        <Row style={{ width: '100%' }} justifyContent="space-between">
          <Row gap="8px">
            <ImageContainer
              src={
                marketingChecked
                  ? ICON_SIGNUP_TERMCHECKED
                  : ICON_SIGNUP_TERMCHECKED_DISABLED
              }
              alt="icon signup term checked"
              onClick={() => {
                handleChangeMarketing(!marketingChecked);
              }}
            />
            <Text color="#1b1c1e" size="12px" weight={400}>
              광고성 정보 수신에 동의합니다.{' '}
              <span style={{ color: '#C2C4C8' }}>(선택)</span>
            </Text>
          </Row>
        </Row>
      </Column>
    </Column>
  );
};

export default SignupTerm;
