import { useMutation } from '@apollo/client';
import { SAVE_PRODUCT_REFUND_POLICY } from '..';

export function useMutationSaveProductRefundPolicy() {
  const [saveProductRefundPolicy] = useMutation(SAVE_PRODUCT_REFUND_POLICY);

  const handleSaveProductRefundPolicy = async (id: string) => {
    try {
      const response = await saveProductRefundPolicy({
        variables: {
          id,
          param: {
            refundPolicyId: 'FLEXIBLE',
          },
        },
      });

      if (response) {
      }
    } catch (error) {
      let message = 'Unknown Error';
      if (error instanceof Error) message = error.message;

      (window as any).showToast(message, 3000);
    }
  };

  return { handleSaveProductRefundPolicy };
}
