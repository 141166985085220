import { useEffect, useState } from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import { useInquiriesHooks } from 'containers/ProductRegist/components/InformationRegist/hooks/useInquiriesHooks';
import { InquiryTemplate } from 'types/api';
import { ConvertingInquiryTemplates } from 'utils/ConvertingInquiryTemplates';

export const useQuestionModifyHooks = (inquiryList: InquiryTemplate) => {
  const {
    inquiryTemplate,
    inquiries,
    inputPattern,
    setInputPattern,
    setInquiries,
    setInquiryTemplate,
    handleSetInquiries,
    handleCreateInquiryTemplate,
    handleDeleteInquiryTemplate,
    handleUpdateInquiryTemplate,
    handleResetInquiries,
    handleSetInquiryTemplate,
    handleSetItemInputPattern,
  } = useInquiriesHooks();

  const covertingInquiryTemplates = new ConvertingInquiryTemplates();

  const [isOpenQuestionModifyModal, setIsOpenQuestionModifyModal] =
    useState<boolean>(false);
  const [modifyInquiriesIdx, setModifyInquiresIdx] = useState<number | null>(
    null,
  );

  const isAbled =
    inquiryTemplate.inquiries !==
    covertingInquiryTemplates.convertInquiryTemplateParamInput(inquiryList)
      .inquiries;

  const handleOpenQuestionModifyModal = (isOpen: boolean) => {
    setIsOpenQuestionModifyModal(isOpen);
  };

  const handleModifyInquiryTemplates = () => {
    const copyInquiryTemplate = cloneDeep(inquiryTemplate);
    const modifyIndex = inquiryTemplate.inquiries.findIndex(
      (item, inquiryIdx) => inquiryIdx === modifyInquiriesIdx,
    );

    copyInquiryTemplate.inquiries[modifyIndex] = inquiries;

    setInquiryTemplate(copyInquiryTemplate);
  };

  const handleResetQuestion = () => {
    const inquiryParamInput =
      covertingInquiryTemplates.convertInquiryTemplateParamInput(inquiryList);

    setInquiryTemplate(inquiryParamInput);
  };

  useEffect(() => {
    if (inquiryList) {
      const inquiryParamInput =
        covertingInquiryTemplates.convertInquiryTemplateParamInput(inquiryList);

      if (!isEmpty(inquiryParamInput.inquiries)) {
        setInputPattern(inquiryParamInput.inquiries[0].inputPattern);
      }
      setInquiryTemplate(inquiryParamInput);
    }
  }, [inquiryList]);

  return {
    inquiryTemplate,
    inquiries,
    isOpenQuestionModifyModal,
    modifyInquiriesIdx,
    isAbled,
    inputPattern,
    setInquiries,
    handleResetInquiries,
    setModifyInquiresIdx,
    handleSetInquiryTemplate,
    handleSetInquiries,
    handleCreateInquiryTemplate,
    handleModifyInquiryTemplates,
    handleDeleteInquiryTemplate,
    handleUpdateInquiryTemplate,
    handleOpenQuestionModifyModal,
    handleResetQuestion,
    handleSetItemInputPattern,
  };
};
