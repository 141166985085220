import Text from 'components/common/Text';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ $isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  padding: 16px;
  width: 100%;
  min-height: 90px;

  border-radius: 12px;
  border: ${props =>
    props.$isSelected ? '1.5px solid #1B1C1E' : '1px solid #dbdcdf'};
  background: ${props => (props.$isSelected ? '#f7f7f8' : '#fff')};
  cursor: pointer;
`;

interface OptionSelectBoxIProps {
  title: string;
  description: string;
  isSelected: boolean;
  style?: React.CSSProperties;
  onClick: () => void;
}

const OptionSelectBox: React.FC<OptionSelectBoxIProps> = props => {
  const { title, description, isSelected, style, onClick } = props;

  return (
    <Container style={{ ...style }} $isSelected={isSelected} onClick={onClick}>
      <Text color="#1b1c1e" size="14px">
        {title}
      </Text>
      <Text
        color="#70737c"
        size="12px"
        weight={400}
        style={{ lineHeight: '16px' }}
      >
        {description}
      </Text>
    </Container>
  );
};

export default OptionSelectBox;
