import React from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import Divider from 'components/Divider';
import { dateFormattingForm, formatPhoneNumber } from 'utils/formatting';
import {
  BookingItem,
  BookingState,
  Order,
  OrderState,
  TransactionType,
} from 'types/api';
import BasicButton from 'components/Button/BasicButton';
import CommonImage from 'components/common/CommonImage';
import { ICON_MESSAGE_BLACK } from 'assets/icons';
import {
  callWebviewHandler,
  isWebview,
  WebviewOperatorType,
} from 'utils/webview';
import { getEnvConstant } from 'constants/env';
import HackleManager from 'utils/hackle';
import { CancelItems } from '../hooks/useSaleDetailHooks';
import { isEmpty } from 'lodash';

interface OrderResultContainerIProps {
  orderSheet: Order;
  cancelItems: CancelItems;
}

const OrderResultContainer: React.FC<OrderResultContainerIProps> = props => {
  const { orderSheet, cancelItems } = props;
  const params = useParams<{ id: string }>();

  const formatOrderState = (status: OrderState): string => {
    switch (status) {
      case OrderState.CANCELED: {
        return '취소완료';
      }
      case OrderState.COMPLETED: {
        return '결제완료';
      }
      default:
        return '';
    }
  };

  const formatBookingState = (item: BookingItem): string => {
    const isShowCancelRequest =
      cancelItems.request.orderId.includes(item.booking.id) &&
      cancelItems.request.indexNo.includes(item.indexNo);

    if (isShowCancelRequest) {
      return '취소요청';
    }

    switch (item.status) {
      case BookingState.CANCELED: {
        return '취소완료';
      }
      default:
        return '결제완료';
    }
  };

  const convertTotalCount = (): number => {
    let totalCount: number = 0;

    orderSheet.items.map(orderItem => {
      totalCount += orderItem.booking.counts.total;
    });

    return totalCount;
  };

  return (
    <Column
      align="flex-start"
      gap="16px"
      style={{ padding: params.id ? '0 20px' : '0 40px' }}
    >
      <Row justifyContent="space-between" style={{ width: '100%' }}>
        <Text
          color={
            orderSheet.status === OrderState.CANCELED ? '#FF4242' : '#1b1c1e'
          }
        >
          {formatOrderState(orderSheet.status)}
        </Text>
        <Text color="#70737C" size="12px" weight={400}>
          주문ID: {orderSheet.id}
        </Text>
      </Row>
      {orderSheet.status === OrderState.CANCELED && (
        <Column gap="4px" align="flex-start">
          <Row>
            <Text color="#70737c" size="12px" weight={400}>
              결제일:
            </Text>
            <Text color="#1b1c1e" size="12px" weight={400}>
              {dayjs(orderSheet.items[0].transaction.firmedAt).format(
                'YYYY-MM-DD HH:mm',
              )}
            </Text>
          </Row>
          {orderSheet.transactions[0].type === TransactionType.CANCELLATION && (
            <Row gap="2px">
              <Text color="#70737c" size="12px" weight={400}>
                취소일:
              </Text>
              <Text color="#1b1c1e" size="12px" weight={400}>
                {dayjs(orderSheet.transactions[0].firmedAt).format(
                  'YYYY-MM-DD HH:mm',
                )}
              </Text>
            </Row>
          )}
        </Column>
      )}
      <Divider />
      <Column gap="8px" align="flex-start">
        <Column gap="4px" align="flex-start">
          <Text color="#46474C">{orderSheet.items[0].info.product.title}</Text>
          <Text color="#46474C" size="13px">
            {orderSheet.customer.nickname} •{' '}
            {formatPhoneNumber(orderSheet.customer.mobileNumber)}
          </Text>
        </Column>
      </Column>
      <Divider />
      <Column gap="12px">
        {orderSheet.items.map(orderItem => {
          return (
            <React.Fragment key={orderItem.id}>
              {orderItem.booking.items.map(bookingItem => {
                return (
                  <Column gap="8px" key={bookingItem.id} align="flex-start">
                    <Row
                      justifyContent="space-between"
                      style={{ width: '100%' }}
                    >
                      <Row gap="8px">
                        <Text
                          size="10px"
                          color={
                            cancelItems.request.orderId.includes(
                              bookingItem.booking.id,
                            ) &&
                            cancelItems.request.indexNo.includes(
                              bookingItem.indexNo,
                            )
                              ? '#FF4242'
                              : '#0071E9'
                          }
                        >
                          {formatBookingState(bookingItem)}
                        </Text>
                        <Text
                          color="#46474c"
                          size="13px"
                          style={{
                            textDecoration:
                              bookingItem.status === BookingState.CANCELED
                                ? 'line-through'
                                : 'none',
                          }}
                        >
                          {orderItem.info.name}
                        </Text>
                      </Row>
                      <Text color="#46474C" size="13px">
                        {bookingItem.booking.info.ticketCount}개
                      </Text>
                    </Row>
                    {bookingItem.booking.schedule && (
                      <Text color="#70737C" size="12px" weight={400}>
                        {dayjs(
                          bookingItem.booking.schedule.term.startedAt,
                        ).format(dateFormattingForm())}{' '}
                        ~{' '}
                        {dayjs(
                          bookingItem.booking.schedule.term.endedAt,
                        ).format(dateFormattingForm())}
                      </Text>
                    )}
                  </Column>
                );
              })}
            </React.Fragment>
          );
        })}
      </Column>
      <Divider />
      <Row justifyContent="space-between" style={{ width: '100%' }}>
        <Text color="#1B1C1E" size="14px" weight={600}>
          {Number(orderSheet.totalAmount.value).toLocaleString()}원{' '}
          <span style={{ fontWeight: 400 }}>
            ({convertTotalCount().toLocaleString()}건)
          </span>
        </Text>
        <BasicButton
          content={
            <Row gap="2px">
              <CommonImage src={ICON_MESSAGE_BLACK} onClick={() => {}} />
              <Text color="#1b1c1e">메시지</Text>
            </Row>
          }
          onClick={() => {
            HackleManager.logSalesMessageClick(
              Number(orderSheet.id),
              orderSheet.customer.id,
              orderSheet.items[0].info.origin.product.frip.attachedToSchedule
                ? '일정 지정형'
                : '일정 조율형',
              '판매상세',
            );

            if (isWebview()) {
              callWebviewHandler(WebviewOperatorType.OpenActionUrl, {
                actionUrl: `frip://chattingDetail/O${orderSheet.id}`,
              });

              return;
            }

            window.open(
              `${getEnvConstant('webClientUrl')}/messageDetail/O${
                orderSheet.id
              }`,
            );
          }}
          style={{ borderRadius: '8px' }}
        />
      </Row>
    </Column>
  );
};

export default OrderResultContainer;
