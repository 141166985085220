import React, { useState } from 'react';
import styled from 'styled-components';
import ModifyContainer from '../ModifyContainer';
import Row from 'components/common/Row';

import {
  ICON_PRODUCTDETAIL_LEVEL,
  ICON_PRODUCTDETAIL_PEOPLE,
  ICON_PRODUCTDETAIL_AGE,
  ICON_CLOSE,
  ICON_LOCATION,
} from 'assets/icons';
import Text from 'components/common/Text';
import Column from 'components/common/Column';
import {
  FripSaleInfoParam,
  ProductParamInput,
} from 'containers/ProductRegist/hooks/types';
import { formatCompanions, formatDifficulty } from 'utils/formatting';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import ChapterFour from 'containers/ProductRegist/components/InformationRegist/ChapterFour';
import { ModifyValue } from '../../hooks/useProductDetaillHooks';

interface MoreInformationContainerIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  modifyValue: ModifyValue;
  setModifyValue: React.Dispatch<React.SetStateAction<ModifyValue>>;
}

const MoreInformationContainer: React.FC<
  MoreInformationContainerIProps
> = props => {
  const { isMobile, productParamInput, modifyValue, setModifyValue } = props;

  const fripInfo = productParamInput.frip;

  return (
    <ModifyContainer
      title="참여 정보"
      isSelected={!isMobile && modifyValue.modifyStatus === 'moreInformation'}
      onClick={() => {
        setModifyValue({ ...modifyValue, modifyStatus: 'moreInformation' });
      }}
    >
      <Column align="flex-start" gap="12px">
        <Row gap="8px">
          <img src={ICON_PRODUCTDETAIL_LEVEL} alt="product level" />
          <Text color="#46474c" size="14px" weight={400}>
            난이도: {formatDifficulty(fripInfo.difficulty)}
          </Text>
        </Row>
        <Row gap="8px" align="flex-start">
          <img src={ICON_PRODUCTDETAIL_PEOPLE} alt="product level" />
          <Row gap="4px" align="flex-start">
            <Text
              color="#46474c"
              size="14px"
              weight={400}
              style={{ textWrap: 'nowrap' }}
            >
              인원 구성:
            </Text>
            <Row
              gap="4px"
              justifyContent="flex-start"
              style={{ flexWrap: 'wrap' }}
            >
              {fripInfo.companions.map((item, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <Text
                      color="#46474c"
                      size="14px"
                      weight={400}
                      style={{ textWrap: 'wrap' }}
                    >
                      {formatCompanions(item)}
                    </Text>
                    {fripInfo.companions.length !== idx + 1 && (
                      <Text color="#46474c" size="14px" weight={400}>
                        •
                      </Text>
                    )}
                  </React.Fragment>
                );
              })}
            </Row>
          </Row>
        </Row>
        <Row gap="8px">
          <img src={ICON_LOCATION} alt="location icon" />
          <Text color="#46474c" size="14px" weight={400}>
            실내외: {fripInfo.isIndoor && '실내'}{' '}
            {fripInfo.isIndoor && fripInfo.isOutdoor && '• '}
            {fripInfo.isOutdoor && '실외'}
          </Text>
        </Row>
        <Row gap="8px">
          <img src={ICON_PRODUCTDETAIL_AGE} alt="product level" />
          <Text color="#46474c" size="14px" weight={400}>
            권장 연령:{' '}
            {fripInfo.recommendedAge === 0
              ? '누구나'
              : `${fripInfo.recommendedAge}대 이상`}
          </Text>
        </Row>
      </Column>
    </ModifyContainer>
  );
};

export default MoreInformationContainer;
