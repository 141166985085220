import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { errorHandler } from 'utils/ErrorHandler';
import { CLOSE_ITEM, OPEN_ITEM, TERMINATE_ITEM } from '..';
import { useQueryProduct } from './useQueryProduct';

export function useMutationItemStatus(
  handleRefetchProduct: (productId) => void,
) {
  const [openItem] = useMutation(OPEN_ITEM);
  const [closeItem] = useMutation(CLOSE_ITEM);
  const [terminateItem] = useMutation(TERMINATE_ITEM);

  const params = useParams<{ id: string }>();

  const handleOpenItem = async (id: string) => {
    try {
      const response = await openItem({ variables: { id } });

      if (response) {
        handleRefetchProduct(params.id);
        // window.location.reload();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleCloseItem = async (id: string) => {
    try {
      const response = await closeItem({ variables: { id } });

      if (response) {
        handleRefetchProduct(params.id);
        // window.location.reload();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleTerminateItem = async (id: string) => {
    try {
      const response = await terminateItem({ variables: { id } });

      if (response) {
        handleRefetchProduct(params.id);
        // window.location.reload();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  return { handleOpenItem, handleCloseItem, handleTerminateItem };
}
