import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_PRODUCT_SCHEDULESTERM } from '..';
import { errorHandler } from 'utils/ErrorHandler';

export function useQueryGetProductScheduleTerm() {
  const params = useParams<{ id: string }>();
  const today = new Date();

  const ninetyDaysLater = new Date();
  ninetyDaysLater.setDate(today.getDate() + 90);
  const [scheduleList, setScheduleList] = useState();

  const [getProductScheduleTerm, { data, refetch }] = useLazyQuery(
    GET_PRODUCT_SCHEDULESTERM,
  );

  const handleGetProductScheduleTerm = async () => {
    try {
      const response = await getProductScheduleTerm({
        variables: {
          productId: params.id,
          schedulingTerm: {
            startedAt: today.getTime(),
            endedAt: ninetyDaysLater.getTime(),
          },
        },
      });

      if (response) {
        setScheduleList(response.data.product.schedulesByTerm);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleRefetchScheduleTerm = async () => {
    try {
      const response = await refetch({
        productId: params.id,
        schedulingTerm: {
          startedAt: today.getTime(),
          endedAt: ninetyDaysLater.getTime(),
        },
      });

      if (response) {
        setScheduleList(response.data.product.schedulesByTerm);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (params.id !== 'draft') {
      handleGetProductScheduleTerm();
    }
  }, []);

  return {
    scheduleList,
    handleGetProductScheduleTerm,
    handleRefetchScheduleTerm,
  };
}
