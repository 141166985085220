import { gql } from '@apollo/client';

export const GET_PRODUCT_LIST = gql`
  query products($filter: ProductFilterInput, $page: Int, $size: Int) {
    product {
      products(filter: $filter, page: $page, size: $size) {
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            id
            title
            createdAt
            updatedAt
            frip {
              kind
              interactionType
            }
            host {
              id
              name
            }
            manager {
              id
              name
            }
            standardCategories {
              id
              label {
                id
                name
              }
            }
            exposures {
              id
              description
              name
              status
            }
            headerContents {
              id
              style
              content {
                id
                thumbnail(
                  fetchFormat: AUTO
                  crop: FILL
                  devicePixelRatio: 2.0
                  width: 375
                  aspectRatio: "1:1"
                )
              }
            }
            lastInspection {
              id
              status
            }
            saleTerm {
              duration
              startedAt
              endedAt
            }
            lastInspection {
              id
              status
              items {
                comment
                reasons {
                  name
                  description
                }
              }
            }
            status
          }
        }
        totalCount
      }
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation deleteProduct($id: ID!) {
    deleteProduct(id: $id) {
      success
      message
    }
  }
`;
