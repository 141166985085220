import { useMutation } from '@apollo/client';
import { errorHandler } from 'utils/ErrorHandler';
import HackleManager from 'utils/hackle';

import { SAVE_VERIFIED_HOST_BUSINESS_INFO } from '..';
import { HostVerifiedBusinessParamInput } from '../type';
import { useMutationSaveHostRegistrationNo } from './useMutationSaveHostRegistrationNo';

export function useMutationSaveVerifiedHostBusinessInfo() {
  const [saveVerifiedHostBusinessInfo] = useMutation(
    SAVE_VERIFIED_HOST_BUSINESS_INFO,
  );

  const { handleSaveHostRegistrationNo } = useMutationSaveHostRegistrationNo();

  const handleSaveVerifiedHostBusinessInfo = async (
    hostId: string,
    param: HostVerifiedBusinessParamInput,
    registrationNo: string,
  ) => {
    try {
      const response = await saveVerifiedHostBusinessInfo({
        variables: {
          hostId,
          param,
        },
      });

      if (response) {
        handleSaveHostRegistrationNo(hostId, registrationNo);

        HackleManager.logSettlementRegistInfoUpdateComplete('사업자');
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  return {
    handleSaveVerifiedHostBusinessInfo,
  };
}
