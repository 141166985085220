import React, { useState } from 'react';
import styled from 'styled-components';

import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';

const Container = styled.div<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 16px;
  width: 100%;

  border-radius: 12px;
  border: ${(props) =>
    props.$isSelected ? '1.5px solid #1b1c1e' : '1px solid #dbdcdf'};
  background: ${(props) => (props.$isSelected ? '#F7F7F8' : '#fff')};

  cursor: pointer;
`;

const ImageContainer = styled.img`
  width: 42px;
  height: 42px;
`;

interface FripRegistButtonIProps {
  title: string;
  description: string;
  thumbnail: string;
  isSelected: boolean;
  onClick: () => void;
}

const FripRegistButton: React.FC<FripRegistButtonIProps> = (props) => {
  const { title, description, thumbnail, isSelected, onClick } = props;

  return (
    <Container
      $isSelected={isSelected}
      onClick={() => {
        onClick();
      }}
    >
      <Row gap="12px" justifyContent="space-between" style={{ width: '100%' }}>
        <Column gap="4px" align="flex-start">
          <Text color="#1b1c1e" size="16px">
            {title}
          </Text>
          <Text color="#70737C" size="13px" weight={400}>
            {description}
          </Text>
        </Column>
        <ImageContainer src={thumbnail} alt="frip regist thumbnail" />
      </Row>
    </Container>
  );
};

export default FripRegistButton;
