import React, { SetStateAction, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useResizeDetector } from 'react-resize-detector';
import styled from 'styled-components';
import Lottie from 'lottie-react';
import Resizer from 'react-image-file-resizer';

import Column from 'components/common/Column';
import Text from 'components/common/Text';
import { ICON_CLOSE, ICON_PRODUCT_CAMERA } from 'assets/icons';
import Row from 'components/common/Row';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import Textarea from '../common/Textarea';
import { useMutationGenerateContentUpload } from './graphQL/hooks/useMutationGenerateContentUpload';
import {
  callWebviewHandler,
  isWebview,
  WebviewOperatorType,
} from 'utils/webview';
import HackleManager from 'utils/hackle';
import lottie from 'assets/lotties/image_upload_lottie.json';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 32px;
  width: 100%;
`;

const ProductNameForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 0 12px 0 0;
  width: 100%;

  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;
`;

const ImageUploadForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  position: relative;
  border-radius: 8px;
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);

  color: var(--gray-gray-06, #989ba2);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.6px;
  cursor: pointer;

  input {
    position: absolute;
    display: none;
    border: 1px solid red;
    width: 100%;
    height: 100%;
  }
`;

const ImageContainer = styled.div<{ $width: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  width: ${props => (props.$width - 12) / 2}px;
  height: ${props => (props.$width - 12) / 2}px;

  border-radius: 8px;
  background-color: #f4f4f5;
`;

const DeleteContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid #1b1c1e;
  background: #fff;
  cursor: pointer;
`;

const RepresentativeBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 8px;
  top: 8px;
  padding: 6px 8px;

  border-radius: 4px;
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);

  color: #1b1c1e;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 140% */
  letter-spacing: -0.2px;
`;

interface ChapterTwoIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  imageThumbnail: string[];
  setImageThumbnail: React.Dispatch<SetStateAction<string[]>>;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
}

const ChapterTwo: React.FC<ChapterTwoIProps> = props => {
  const {
    isMobile,
    productParamInput,
    imageThumbnail,
    setImageThumbnail,
    handleOnChangeProductParamInput,
  } = props;

  const params = useParams<{ id: string }>();

  const {
    isLoading,
    setIsLoading,
    handleGenerateContentUpload,
    handleDeleteImage,
  } = useMutationGenerateContentUpload({
    productParamInput,
    imageThumbnail,
    setImageThumbnail,
    handleOnChangeProductParamInput,
  });

  const { width, ref } = useResizeDetector();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const MAX_FILES = 5; // 파일 최대 개수

  const handleFileChange = async (event: any) => {
    await Resizer.imageFileResizer(
      event.target.files[0],
      375 * 3,
      375 * 3,
      'png',
      80,
      0,
      (file: File) => {
        setIsLoading(true);
        handleGenerateContentUpload({
          file,
          fileName: file.name,
        });
      },
      'file',
    );
  };

  const handleImageUploadClick = () => {
    if (imageThumbnail.length >= MAX_FILES) {
      (window as any).showToast('최대 5개만 업로드 가능합니다', 3000);

      return;
    }

    if (fileInputRef.current) {
      if (isWebview()) {
        callWebviewHandler(
          WebviewOperatorType.UpdateMessageImageFileHandler,
          undefined,
          'handleImageUpload',
        );
      } else {
        fileInputRef.current.click();
      }
    }
  };

  useEffect(() => {
    HackleManager.logCreateStepThreeProductNameImageView(
      params.id !== 'draft' ? Number(params.id) : undefined,
    );
  }, []);

  const renderContents = (): JSX.Element => {
    return (
      <Container ref={ref}>
        <React.Fragment>
          <Text color="#1b1c1e" size="24px">
            프립명과 사진을 추가해 주세요
          </Text>
          <Column gap="8px" align="flex-start">
            <Text color="#1b1c1e" size="16px">
              프립명
            </Text>
            <ProductNameForm>
              <Textarea
                placeholder="프립명을 입력해 주세요"
                target="title"
                value={productParamInput.title}
                maxLength={40}
                onChange={handleOnChangeProductParamInput}
                style={{ border: 'none' }}
              />
              <Text color="#989ba2" size="14px" weight={400}>
                {productParamInput.title.length}/40
              </Text>
            </ProductNameForm>
          </Column>
        </React.Fragment>
        <Column gap="24px" align="flex-start">
          <Column gap="8px" align="flex-start">
            <Text color="#1b1c1e" size="16px">
              사진 추가하기
            </Text>
            <Text color="#70737C" size="16px" weight={400}>
              활동이 잘 드러나는 사진을 최대 5장까지 등록해주세요. 첫번째
              이미지는 대표 이미지로 사용돼요.
            </Text>
          </Column>
          <Row
            gap="12px"
            justifyContent={isMobile ? 'space-between' : 'flex-start'}
            style={{
              display: 'grid',
              // flexWrap: 'wrap',
              gridTemplateColumns: `repeat(auto-fill, minmax(${
                (width - 12) / 2
              }px, auto))`,
              width: '100%',
            }}
          >
            <ImageUploadForm
              onClick={() => {
                handleImageUploadClick();
              }}
              style={{
                width: `${(width - 12) / 2}px`,
                height: `${(width - 12) / 2}px`,
              }}
            >
              <img src={ICON_PRODUCT_CAMERA} alt="image upload icon" />
              {imageThumbnail.length}/5
              <input
                ref={fileInputRef}
                type="file"
                multiple={false}
                onChange={handleFileChange}
                accept=".jpg, .png, .jpeg"
              />
            </ImageUploadForm>
            {imageThumbnail.map((item, idx) => {
              return (
                <ImageContainer $width={width} key={idx}>
                  {idx === 0 && (
                    <RepresentativeBadge>대표이미지</RepresentativeBadge>
                  )}
                  <img
                    src={item}
                    alt="image thumbnail"
                    key={idx}
                    style={{
                      width: `${(width - 12) / 2}px`,
                      height: `${(width - 12) / 2}px`,
                      borderRadius: '8px',
                      objectFit: 'cover',
                    }}
                  />
                  <DeleteContainer
                    onClick={() => {
                      handleDeleteImage(idx);
                    }}
                  >
                    <img src={ICON_CLOSE} alt="delete icon" />
                  </DeleteContainer>
                </ImageContainer>
              );
            })}
            {isLoading && (
              <ImageContainer $width={width}>
                <Lottie animationData={lottie} style={{ width: '50px' }} />
              </ImageContainer>
            )}
          </Row>
        </Column>
      </Container>
    );
  };

  return renderContents();
};

export default ChapterTwo;
