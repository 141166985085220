import { ProductTermWithTypeInput } from 'containers/ProductList/graphQL/types';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ProductQanSearchTimeOptions } from '../graphQL/types';

export interface QnaTermWithType {
  startedAt: number;
  endedAt: number;
  type: ProductQanSearchTimeOptions;
}

export const useQnaFilterHooks = () => {
  const location = useLocation();
  const productId = location.search.split('=')[1];

  const [searchValue, setSearchValue] = useState<string>(
    productId ? productId : '',
  );
  const [termWithType, setTermWithType] = useState<QnaTermWithType | null>(
    null,
  );
  const [termType, setTermType] = useState<string>('all');
  const [statusIn, setStatusIn] = useState<string>('ALL');

  const handlerSetSearchValue = (e: any) => {
    setSearchValue(e.target.value);
  };

  const handleSetTermWithType = (type: string) => {
    const today = new Date();

    switch (type) {
      case 'all': {
        setTermWithType(null);

        return;
      }
      case '1month': {
        const startedAt = new Date(today);
        startedAt.setMonth(startedAt.getMonth() - 1);

        setTermWithType({
          type: ProductQanSearchTimeOptions.QUESTION_TIME,
          startedAt: startedAt.getTime(),
          endedAt: today.getTime(),
        });

        return;
      }
      case '6month': {
        const startedAt = new Date(today);
        startedAt.setMonth(startedAt.getMonth() - 1);

        setTermWithType({
          type: ProductQanSearchTimeOptions.QUESTION_TIME,
          startedAt: startedAt.getTime(),
          endedAt: today.getTime(),
        });

        return;
      }
      case '1year': {
        const startedAt = new Date(today);
        startedAt.setFullYear(startedAt.getFullYear() - 1);

        setTermWithType({
          type: ProductQanSearchTimeOptions.QUESTION_TIME,
          startedAt: startedAt.getTime(),
          endedAt: today.getTime(),
        });

        return;
      }
      case '5year': {
        const startedAt = new Date(today);
        startedAt.setFullYear(startedAt.getFullYear() - 5);

        setTermWithType({
          type: ProductQanSearchTimeOptions.QUESTION_TIME,
          startedAt: startedAt.getTime(),
          endedAt: today.getTime(),
        });

        return;
      }
      default:
        return;
    }
  };

  const handleResetFilterInput = () => {
    setSearchValue('');
    setTermWithType(null);
  };

  return {
    searchValue,
    termWithType,
    termType,
    statusIn,
    setTermType,
    setStatusIn,
    handleSetTermWithType,
    handlerSetSearchValue,
    handleResetFilterInput,
  };
};
