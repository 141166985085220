import BasicButton from 'components/Button/BasicButton';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import ConfirmDialog from 'components/Modal/ConfirmDialog';
import { useMutationItemStatus } from 'containers/ProductRegist/graphQL/hooks/useMutationItemStatus';
import { Items } from 'containers/ProductRegist/hooks/types';
import React, { useState } from 'react';
import { styled } from 'styled-components';
import { ItemState } from 'types/api';

const StatusButton = styled.div<{ background: string }>`
  width: 8px;
  height: 8px;
  background-color: ${props => props.background};
  border-radius: 50%;
`;

interface ItemStatusButtonIProps {
  item: Items;
  handleRefetchProduct: (productId: string) => void;
}

const ItemsStatusButton: React.FC<ItemStatusButtonIProps> = props => {
  const { item, handleRefetchProduct } = props;

  const { handleOpenItem, handleCloseItem, handleTerminateItem } =
    useMutationItemStatus(handleRefetchProduct);

  const [isOpenConfirmDialog, setIsOpenConfirmDialog] =
    useState<boolean>(false);
  const [closeType, setCloseType] = useState<string>('');

  const renderStatusButton = (): JSX.Element => {
    switch (item.status) {
      case ItemState.OPENED: {
        return (
          <Row justifyContent="space-between" style={{ width: '100%' }}>
            <Row gap="8px">
              <StatusButton background="#0071E9" />
              <Text color="#1B1C1E">판매 중</Text>
            </Row>
            <BasicButton
              content={
                <Row gap="8px">
                  <StatusButton background="#FF9200" />
                  <Text color="#1B1C1E">판매 중지</Text>
                </Row>
              }
              onClick={() => {
                setIsOpenConfirmDialog(true);
              }}
              style={{ borderRadius: '8px' }}
            />
          </Row>
        );
      }
      case ItemState.CLOSED: {
        return (
          <Row justifyContent="space-between" style={{ width: '100%' }}>
            <Row gap="8px">
              <StatusButton background="#FF9200" />
              <Text color="#1B1C1E">판매 중지</Text>
            </Row>
            <Row gap="8px">
              <BasicButton
                content={
                  <Row gap="8px">
                    <StatusButton background="#FF4242" />
                    <Text color="#1B1C1E">판매 종료</Text>
                  </Row>
                }
                onClick={() => {
                  setCloseType('close');
                  setIsOpenConfirmDialog(true);
                }}
                style={{ borderRadius: '8px' }}
              />
              <BasicButton
                content={
                  <Row gap="8px">
                    <Text color="#1B1C1E">다시 판매하기</Text>
                  </Row>
                }
                onClick={() => {
                  setCloseType('resale');
                  setIsOpenConfirmDialog(true);
                }}
                style={{ borderRadius: '8px' }}
              />
            </Row>
          </Row>
        );
      }
    }

    return;
  };

  const renderConfirmModal = (): JSX.Element => {
    switch (item.status) {
      case ItemState.OPENED: {
        return (
          <ConfirmDialog
            title="선택한 옵션의 판매를 일시 중지 할까요?"
            description="일시 중지 시 옵션은 크루에게 상품이 노출되지 않아요. 판매중 상태로 변경 시 다시 판매를 시작할 수 있어요."
            onAcceptText="일시 중지 할게요"
            onCloseText="아니요"
            onClose={() => setIsOpenConfirmDialog(false)}
            onAccept={() => {
              handleCloseItem(item.id);

              setIsOpenConfirmDialog(false);
            }}
          />
        );
      }
      case ItemState.CLOSED: {
        if (closeType === 'close') {
          return (
            <ConfirmDialog
              title="선택한 옵션의 판매를 종료할까요?"
              description="판매 종료 시 선택한 옵션은 재판매가 불가능해요. "
              onAcceptText="판매 종료 할게요"
              onCloseText="아니요"
              onClose={() => setIsOpenConfirmDialog(false)}
              onAccept={() => {
                handleTerminateItem(item.id);

                setIsOpenConfirmDialog(false);
              }}
            />
          );
        }
        return (
          <ConfirmDialog
            title="판매를 다시 시작할까요?"
            description="판매를 다시 시작하면 '판매중' 상태로 변경되며, 크루에게 상품이 노출돼요."
            onAcceptText="네, 시작할게요"
            onCloseText="아니요"
            onClose={() => setIsOpenConfirmDialog(false)}
            onAccept={() => {
              handleOpenItem(item.id);

              setIsOpenConfirmDialog(false);
            }}
          />
        );
      }
    }
    return;
  };

  return (
    <React.Fragment>
      {renderStatusButton()}
      {isOpenConfirmDialog && renderConfirmModal()}
    </React.Fragment>
  );
};

export default ItemsStatusButton;
