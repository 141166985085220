import { useMutation } from '@apollo/client';
import { errorHandler } from 'utils/ErrorHandler';
import { REPORT_PRODUCT_REVIEW } from '..';

export function useMutationReportProductReview() {
  const [reportProductReview] = useMutation(REPORT_PRODUCT_REVIEW);

  const handleReportProductReview = async (
    reviewId: string,
    typeId: number,
    reason: string,
    callback: () => void,
  ) => {
    try {
      const response = await reportProductReview({
        variables: {
          param: { productReviewId: Number(reviewId), typeId, reason },
        },
      });

      if (response) {
        callback();

        window.location.reload();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  return { handleReportProductReview };
}
