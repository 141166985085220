import { useMutation } from '@apollo/client';

import { HostVerifiedBusinessParamInput } from './../type';
import { SAVE_HOST_BUSINESS_INFO } from './../index';
import { useMutationSaveVerifiedHostBusinessInfo } from './useMutationSaveVerifiedHostBusinessInfo';
import HackleManager from 'utils/hackle';
import { errorHandler } from 'utils/ErrorHandler';

export function useMutationSaveHostBusinessInfo() {
  const [saveHostBusinessInfo] = useMutation(SAVE_HOST_BUSINESS_INFO);

  const { handleSaveVerifiedHostBusinessInfo } =
    useMutationSaveVerifiedHostBusinessInfo();

  const handleSaveHostBusinessInfo = async (
    hostId: string,
    businessInformation: HostVerifiedBusinessParamInput,
    registrationNo: string,
  ) => {
    try {
      const response = await saveHostBusinessInfo({
        variables: {
          hostId,
          param: {
            taxPayerName: businessInformation.taxPayerName,
            taxPayerType: businessInformation.taxPayerType,
          },
        },
      });

      if (response) {
        handleSaveVerifiedHostBusinessInfo(
          hostId,
          businessInformation,
          registrationNo,
        );

        HackleManager.logSettlementRegistInfoUpdateComplete('개인');
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  return { handleSaveHostBusinessInfo };
}
