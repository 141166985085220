import { gql } from '@apollo/client';

export const PAUSE_SCHEDULE = gql`
  mutation pauseSchedule($id: ID!) {
    pauseSchedule(id: $id) {
      id
      status
    }
  }
`;

export const OPEN_SCHEDULE = gql`
  mutation openSchedule($id: ID!) {
    openSchedule(id: $id) {
      id
      status
    }
  }
`;
