export const LOGIN_PATH = '/sign-in';
export const SIGNUP_PATH = '/sign-up';
export const GUIDE_PATH = '/guide';
export const START_PATH = '/start';
export const SETTLEMENT_PATH = '/settlement/info';
export const SETTLEMENT_REGIST_PATH = '/settlement/info/host';
export const SETTLEMENT_BANK_REGIST_PATH = '/settlement/info/bank';
export const SETTLEMENT_LIST_PATH = '/settlement';
export const SETTLEMENT_DETAIL_PATH_DEFAULT = '/settlement/detail';
export const SETTLEMENT_DETAIL_PATH = `${SETTLEMENT_DETAIL_PATH_DEFAULT}/:id`;
export const PRODUCT_LIST_PATH = '/product';
export const PRODUCT_REGIST_PATH = '/product/create';
export const PRODUCT_DETAIL_PATH = '/product/detail';
export const MESSAGE_LIST_PATH = '/message';
export const QNA_LIST_PATH = '/qna';
export const REVIEW_LIST_PATH = '/review';
export const SALE_LIST_PATH = '/sales';
export const SALE_DETAIL_PATH = '/sales/detail';
export const BOOKING_LIST_PATH = '/booking';
export const MENU_PATH = '/overview';
export const NOTICE_PATH = '/notice';
export const NOTICE_DETAIL_PATH = '/notice/detail';
export const PROFILE_PATH = '/profile-preferences';
export const INDIVIDUAL_PATH = '/contact-preferences';

export const KAKAO_CUSTOMER_SERVICE = 'https://pf.kakao.com/_NQxnQxd';
export const PRODUCT_CREATE_GUIDE =
  'https://frip.notion.site/12b6682a45df80e9af02fbab0061d03a';
export const CONCIERGE_PATH = 'https://www.frip.co.kr/magazines/10157202';
export const QUESTION_PATH =
  'https://frip.notion.site/cb12fdd6682245668a7ceca382f7953f';
export const SUPER_HOST_NOTICE =
  'https://frip.notion.site/13c6682a45df80b2adf6ed536e0e2917';
export const HOST_GUIDE_NOTICE =
  'https://frip.notion.site/94c1339b75e2437a9f6cff8e41c006e9';
export const TERM_NOTICE_PATH =
  'https://frip.notion.site/33af6cc136c84ac2abf66e6c64248995';
export const INDIVIDUAL_NOTICE =
  'https://frip.notion.site/608c6840a029449bad2f823eac545767';
