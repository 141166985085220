import { useMutation } from '@apollo/client';
import { errorHandler } from 'utils/ErrorHandler';
import {
  ABSENT_TICKET,
  CONFIRM_TICKET,
  REQUEST_CANCEL_ORDER,
  RESOTRE_USE_TICKET,
  RESTORE_TICKET,
  RESTORE_TICKET_ABSENCE,
  RETRACT_CANCEL_ORDER_REQUEST,
  USE_TICKET,
} from '..';

export function useMutationTicketSale() {
  const [confirmTicket] = useMutation(CONFIRM_TICKET); //? 예약 확정 단일
  const [restoreTicket] = useMutation(RESTORE_TICKET); //? 예약 확정 취소 단일
  const [requestCancelOrder] = useMutation(REQUEST_CANCEL_ORDER); //? 주문 취소 요청
  const [retractCancelOrderRequest] = useMutation(RETRACT_CANCEL_ORDER_REQUEST); //? 주문 취소 철회
  const [useTicket] = useMutation(USE_TICKET); //? 사용 처리 단일
  const [restoreUseTicket] = useMutation(RESOTRE_USE_TICKET); //? 사용 처리 취소 단일
  const [absentTicket] = useMutation(ABSENT_TICKET); //? 불참 처리 단일
  const [restoreTicketAbsence] = useMutation(RESTORE_TICKET_ABSENCE); //? 불참 처리 취소 단일

  const handleConfirmTicket = async (
    ticketId: string,
    term: { startedAt: number; duration: number } | null,
    callback: () => void,
  ) => {
    try {
      const response = await confirmTicket({
        variables: {
          ticketId,
          term,
        },
      });

      if (response) {
        window.showToast('예약일이 확정되었습니다.', 'success');

        callback();
        // window.location.reload();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleRestoreTicket = async (
    ticketId: string,
    callback: () => void,
  ) => {
    try {
      const response = await restoreTicket({
        variables: { ticketId, reason: '사유 입력 안함' },
      });

      if (response) {
        window.showToast(
          '예약 확정이 취소 되었습니다. 판매 내역을 확인해 주세요',
          'cancel',
        );

        callback();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleRequestCancelOrder = async (param: any, callback: () => void) => {
    try {
      const response = await requestCancelOrder({ variables: { param } });

      if (response) {
        window.showToast('주문 취소 요청이 완료되었습니다.', 'cancel');

        callback();
        // window.location.reload();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleRetractCancelOrderRequest = async (
    id: string,
    callback: () => void,
  ) => {
    try {
      const response = await retractCancelOrderRequest({
        variables: { tid: id },
      });

      if (response) {
        window.showToast('주문 취소 철회가 완료되었습니다.', 'success');

        callback();
        // window.location.reload();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleUseTicket = async (id: string, callback: () => void) => {
    try {
      const response = await useTicket({ variables: { ticketId: id } });

      if (response) {
        window.showToast('사용처리가 완료되었습니다.', 'success');

        callback();
        // window.location.reload();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleRestoreUseTicket = async (id: string, callback: () => void) => {
    try {
      const response = await restoreUseTicket({
        variables: { ticketId: id, reason: '사유 입력 안함' },
      });

      if (response) {
        window.showToast(
          '사용 처리가 취소 되었습니다. 예약 확정 탭에서 취소된 내역을 확인해 주세요.',
          'cancel',
        );

        callback();
        // window.location.reload();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleAbsentTicket = async (ticketId: string, callback: () => void) => {
    try {
      const response = await absentTicket({
        variables: {
          ticketId,
        },
      });

      if (response) {
        window.showToast('불참처리가 완료되었습니다.', 'success');

        if (callback) {
          callback();

          return;
        }
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleRestoreTicketAbsence = async (
    ticketId: string,
    callback: () => void,
  ) => {
    try {
      const response = await restoreTicketAbsence({
        variables: {
          ticketId,
          reason: '사유 입력 안함',
        },
      });

      if (response) {
        window.showToast(
          '불참 처리가 취소 되었습니다. 예약 확정 탭에서 취소된 내역을 확인해 주세요.',
          'cancel',
        );

        if (callback) {
          callback();

          return;
        }

        // window.location.reload();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  return {
    handleConfirmTicket,
    handleRestoreTicket,
    handleRequestCancelOrder,
    handleRetractCancelOrderRequest,
    handleUseTicket,
    handleRestoreUseTicket,
    handleAbsentTicket,
    handleRestoreTicketAbsence,
  };
}
