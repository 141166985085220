import axios from 'axios';
import { getEnvConstant } from 'constants/env';
import { v4 as uuidv4 } from 'uuid';
import { STORAGE_KEY } from './localStoarge';

export const instance = axios.create({
  baseURL: getEnvConstant('identityApiUrl'),
  headers: {
    'Frip-Request-ID': uuidv4(),
  },
});

instance.interceptors.request.use(
  (config) => {
    // 로컬 스토리지 또는 다른 저장소에서 토큰 가져오기
    const token = localStorage.getItem(STORAGE_KEY.AUTHORIZATION);
    if (token) {
      config.headers['Authorization'] = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
