/**
 * GraphQL API scheme 을 모두 명세한 파일입니다.
 *
 * !!) 서버 response 의 원형 타입으로만 활용하고,
 *     클라이언트에서 실제 참조하는 데이터모델은 별도로 만들어 converting 시켜주어 사용하는 게 좋습니다.
 *
 * - Convention
 * - 1. Enum -> interface 순
 * - 2. A B C D 정렬
 */

import {
  SalesType,
  ScheduleTimeParam,
} from 'containers/ProductRegist/hooks/types';
import { ProductQnaState } from 'containers/QnaList/graphQL/types';
import { GeoPointRequest } from './geoPoint';

export enum AddressType {
  JIBUN = 'JIBUN',
  ROAD = 'ROAD',
}

export enum BadgeType {
  CATEGORY = 'CATEGORY',
  NORMAL = 'NORMAL',
}

export enum BookingState {
  ABSENT = 'ABSENT',
  APPLIED = 'APPLIED',
  CANCELED = 'CANCELED',
  CONFIRMED = 'CONFIRMED',
  INIT = 'INIT',
  USED = 'USED',
}

export enum CancelReasonKind {
  AUTO = 'AUTO',
  HOST = 'HOST',
  SYSTEM = 'SYSTEM',
  USER = 'USER',
}

export enum CategoryVersion {
  V1 = 'V1',
  V2 = 'V2',
}

export enum CancelInformationType {
  COUPON = 'COUPON',
  DELIVERY = 'DELIVERY',
  FEE = 'FEE',
  ITEM = 'ITEM',
  REFUND = 'REFUND',
}

export enum ChattingChannelMemberRole {
  USER = 'USER',
  HOST = 'HOST',
  CAPTAIN = 'CAPTAIN',
}

export enum ChattingChannelState {
  ACTIVATED = 'ACTIVATED',
  EXPIRED = 'EXPIRED',
  DELETED = 'DELETED',
}

export enum ChattingChannelType {
  SCHEDULE = 'SCHEDULE',
  TICKET = 'TICKET',
}

export enum ClientPlatform {
  Android = 'Android',
  Mobile = 'Mobile',
  Web = 'Web',
  iOS = 'iOS',
}

export enum CommissionType {
  FIXED = 'FIXED',
  RATIO = 'RATIO',
}

export enum CouponCalculatingType {
  PERCENT = 'PERCENT',
  PRICE = 'PRICE',
}

export enum ContentType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  HTML = 'HTML',
  TEXT = 'TEXT',
}

export enum ContentTarget {
  USER_PROFILE = 'USER_PROFILE',
  HOST_PROFILE = 'HOST_PROFILE',
  HOST_ID_CARD = 'HOST_ID_CARD',
  PRODUCT_HEADER = 'PRODUCT_HEADER',
  BANNER = 'BANNER',
  COLLECTION = 'COLLECTION',
  SHORTCUT = 'SHORTCUT',
  ETC = 'ETC',
}

export enum CommonState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
}

export enum CouponApplyType {
  CART = 'CART',
  OPTION = 'OPTION',
}

export enum CouponIssueType {
  DOWNLOAD = 'DOWNLOAD',
  KEYWORD = 'KEYWORD',
  SERIAL = 'SERIAL',
}

export enum CouponUseType {
  ADDITIONAL = 'ADDITIONAL',
  NORMAL = 'NORMAL',
}

export enum CouponPolicyReferenceType {
  CATEGORY = 'CATEGORY',
  HOST = 'HOST',
  PRODUCT = 'PRODUCT',
}

export enum CollectionType {
  PRODUCT = 'PRODUCT',
  THEME = 'THEME',
}

export enum CurrencyCode {
  KRW = 'KRW',
  USD = 'USD',
}

export enum CurriculumStyle {
  DURATION = 'DURATION',
  NONE = 'NONE',
  TIMETABLE = 'TIMETABLE',
  // deprecated
  LEGACY = 'LEGACY',
}

export enum CurriculumKind {
  CURRICULUM = 'CURRICULUM',
  SCHEDULE = 'SCHEDULE',
}

export enum DayOfWeek {
  FRIDAY = 'FRIDAY',
  MONDAY = 'MONDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
}

export enum DeliveryState {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  CONFIRMED = 'CONFIRMED',
  DELETED = 'DELETED',
  ISSUED = 'ISSUED',
  PACKING = 'PACKING',
  PAID = 'PAID',
  PREPARING = 'PREPARING',
}

export enum FeedbackAnswer {
  SOLVED = 'SOLVED',
  UNSOLVED = 'UNSOLVED',
}

export enum FetchFormatType {
  JPEG = 'JPEG',
  PNG = 'PNG',
  WEBP = 'WEBP',
  TIFF = 'TIFF',
  HEIF = 'HEIF',
  AUTO = 'AUTO',
}

export enum GravityType {
  NORTH = 'NORTH',
  NORTHEAST = 'NORTHEAST',
  EAST = 'EAST',
  SOUTHEAST = 'SOUTHEAST',
  SOUTH = 'SOUTH',
  SOUTHWEST = 'SOUTHWEST',
  WEST = 'WEST',
  NORTHWEST = 'NORTHWEST',
  CENTER = 'CENTER',
}

export enum GrossPurchaseLimitationTarget {
  ITEM = 'ITEM',
  PRODUCT = 'PRODUCT',
}

export enum HostGrade {
  NORMAL = 'NORMAL',
  SUPER = 'SUPER',
}

export enum ItemInquiryInputKind {
  MULTI_CHOICE = 'MULTI_CHOICE',
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  TEXT = 'TEXT',
}

export enum ItemInquiryInputPattern {
  PER_ITEM = 'PER_ITEM',
  PER_ITEM_COUNT = 'PER_ITEM_COUNT',
  PER_PRODUCT = 'PER_PRODUCT',
  PER_PURCHASE = 'PER_PURCHASE',
}

export enum ItemState {
  CLOSED = 'CLOSED',
  DELETED = 'DELETED',
  OPENED = 'OPENED',
  SOLD_OUT = 'SOLD_OUT',
  EDITING = 'EDITING',
}

export enum ItemType {
  DIGITAL = 'DIGITAL',
  FRIP = 'FRIP',
  GOODS = 'GOODS',
  FRIP_MEETUP = 'FRIP_MEETUP',
}

export enum IntroPopupContentType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
}

export enum InventoryTargetType {
  BY_ITEM = 'BY_ITEM',
  BY_OPTION = 'BY_OPTION',
  BY_SCHEDULE = 'BY_SCHEDULE',
}

export enum LevelOfDifficulty {
  EASY = 'EASY',
  HIGH = 'HIGH',
  NONE = 'NONE',
  NORMAL = 'NORMAL',
  VERY_EASY = 'VERY_EASY',
}

export enum ListProductOrderType {
  POPULAR = 'POPULAR',
  LATEST = 'LATEST',
  HIGH_RATING = 'HIGH_RATING',
  HIGH_PRICE = 'HIGH_PRICE',
  LOW_PRICE = 'LOW_PRICE',
  HIGH_PURCHASE = 'HIGH_PURCHASE',
}

export enum ListingProductType {
  WHOLE = 'WHOLE',
  NEW = 'NEW',
  DEAL = 'DEAL',
  HOT = 'HOT',
  NEARBY = 'NEARBY',
}

export enum LocationKind {
  GATHERING = 'GATHERING',
  VENUE = 'VENUE',
}

export enum Month {
  APRIL = 'APRIL',
  AUGUST = 'AUGUST',
  DECEMBER = 'DECEMBER',
  FEBRUARY = 'FEBRUARY',
  JANUARY = 'JANUARY',
  JULY = 'JULY',
  JUNE = 'JUNE',
  MARCH = 'MARCH',
  MAY = 'MAY',
  NOVEMBER = 'NOVEMBER',
  OCTOBER = 'OCTOBER',
  SEPTEMBER = 'SEPTEMBER',
}

export enum NoticeMediaChannel {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  PUSH = 'PUSH',
}

export enum OrderState {
  CANCELED = 'CANCELED',
  CANCELING = 'CANCELING',
  CANCEL_REQUESTED = 'CANCEL_REQUESTED',
  COMPLETED = 'COMPLETED',
  PREPARED = 'PREPARED',
  STAND_BY = 'STAND_BY',
}

export enum PaymentAgencyType {
  BENEPIA = 'BENEPIA',
  FRIP_COUPON = 'FRIP_COUPON',
  FRIP_POINT = 'FRIP_POINT',
  IAMPORT = 'IAMPORT',
  KAKAO_PAY = 'KAKAO_PAY',
  NAVER_PAY = 'NAVER_PAY',
  NICE_PAY = 'NICE_PAY',
  REFUND_BANK = 'REFUND_BANK',
  REFUND_POINT = 'REFUND_POINT',
  TOSS_PAY = 'TOSS_PAY',
}

export enum PaymentMethod {
  ACCOUNT_TRANSFER = 'ACCOUNT_TRANSFER',
  BENEPIA = 'BENEPIA',
  CARD = 'CARD',
  COUPON = 'COUPON',
  KAKAO = 'KAKAO',
  MOBILE_PHONE = 'MOBILE_PHONE',
  NAVER = 'NAVER',
  PAYCO = 'PAYCO',
  PAYNOW = 'PAYNOW',
  POINT = 'POINT',
  REFUND_BANK = 'REFUND_BANK',
  REFUND_POINT = 'REFUND_POINT',
  TOSS = 'TOSS',
  VIRTUAL_BANK = 'VIRTUAL_BANK',
}

export enum PaymentState {
  DELETED = 'DELETED',
  FAILURE = 'FAILURE',
  PREPARED = 'PREPARED',
  ROLLBACK = 'ROLLBACK',
  SUCCESS = 'SUCCESS',
  WAITING = 'WAITING',
}

export enum PointAccountingType {
  PROMOTION = 'PROMOTION',
  BUSINESS = 'BUSINESS',
  NONE = 'NONE',
}

export enum PointKind {
  SAVED = 'SAVED',
  USED = 'USED',
  EXPIRED = 'EXPIRED',
}

export enum PointRefType {
  PURCHASE = 'PURCHASE',
  ATTENDANCE = 'ATTENDANCE',
  PURCHASED_ITEM = 'PURCHASED_ITEM',
  POINT = 'POINT',
  SERIAL = 'SERIAL',
  MANUALLY_GIVE_POINT = 'MANUALLY_GIVE_POINT',
  REVIEW = 'REVIEW',
  POINT_COUPON_VALUE = 'POINT_COUPON_VALUE',
}

export enum ProductContextStyle {
  DETAIL = 'DETAIL',
}

export enum ProductContextType {
  HTML = 'HTML',
  TEXT = 'TEXT',
}

export enum ProductHeaderContentStyle {
  SQUARE = 'SQUARE',
  STANDARD = 'STANDARD',
}

export enum ProductKind {
  GOODS = 'GOODS',
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
  // deprecated
  EVENT = 'EVENT',
}

export enum FripKind {
  COMMON = 'COMMON',
  MEETUP = 'MEETUP',
}

export enum InteractionType {
  CHATTING_GUIDE = 'CHATTING_GUIDE',
  PHONE_CALL = 'PHONE_CALL',
  VIDEO = 'VIDEO',
  VIDEO_CHAT = 'VIDEO_CHAT',
}

export enum CompanionType {
  ALONE = 'ALONE',
  WITH_CHILDREN = 'WITH_CHILDREN',
  WITH_DOG = 'WITH_DOG',
  WITH_FRIENDS = 'WITH_FRIENDS',
  WITH_LOVER = 'WITH_LOVER',
  WITH_PARENTS = 'WITH_PARENTS',
}

export enum ProductReviewOrderType {
  ATTACHMENT_COUNT_DESC = 'ATTACHMENT_COUNT_DESC',
  RATING_DESC = 'RATING_DESC',
  RATING_ASC = 'RATING_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  THUMBS_UP_COUNT_ASC = 'THUMBS_UP_COUNT_ASC',
  THUMBS_UP_COUNT_DESC = 'THUMBS_UP_COUNT_DESC',
  REPORT_COUNT_ASC = 'REPORT_COUNT_ASC',
  REPORT_COUNT_DESC = 'REPORT_COUNT_DESC',
  COMMENT_COUNT_ASC = 'COMMENT_COUNT_ASC',
  COMMENT_COUNT_DESC = 'COMMENT_COUNT_DESC',
}

export enum ProductState {
  EDITING = 'EDITING',
  APPLIED = 'APPLIED',
  INSPECTING = 'INSPECTING',
  REJECTED = 'REJECTED',
  READY = 'READY',
  SALE = 'SALE',
  SOLD_OUT = 'SOLD_OUT',
  PAUSED = 'PAUSED',
  CLOSED = 'CLOSED',
  TERMINATED = 'TERMINATED',
  DELETED = 'DELETED',
  SUSPENDED = 'SUSPENDED',
}

export enum ProductType {
  FRIP_ITINERARY = 'FRIP_ITINERARY',
  FRIP_TICKET = 'FRIP_TICKET',
  FRIP_ETC = 'FRIP_ETC',
}

export enum RefundPolicyType {
  FLEXIBLE = 'FLEXIBLE',
  GOODS = 'GOODS',
  RESTRICTED = 'RESTRICTED',
  SCHEDULED_LOCAL = 'SCHEDULED_LOCAL',
  SCHEDULED_OVERSEAS = 'SCHEDULED_OVERSEAS',
  EVENT = 'EVENT',
}

export enum ReviewState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
  TEMP_BLOCKED = 'TEMP_BLOCKED',
  BLOCKED = 'BLOCKED',
}

export enum ScheduleState {
  CANCELED = 'CANCELED',
  CLOSED = 'CLOSED',
  DELETED = 'DELETED',
  FINISHED = 'FINISHED',
  EDITING = 'EDITING',
  OPENED = 'OPENED',
  PAUSED = 'PAUSED',
  SOLD_OUT = 'SOLD_OUT',
}

export enum SerialBundleType {
  COUPON = 'COUPON',
  POINT = 'POINT',
}

export enum SocialLinkProviderType {
  KAKAO_SYNC = 'KAKAO_SYNC',
  FACEBOOK = 'FACEBOOK',
  APPLE = 'APPLE',
}

export enum TagKind {
  NORMAL = 'NORMAL',
  PRICE = 'PRICE',
}

export enum ThumbnailCropType {
  FILL = 'FILL',
  CROP = 'CROP',
  SCALE = 'SCALE',
  FIT = 'FIT',
}

export enum TicketState {
  ABSENT = 'ABSENT',
  APPLIED = 'APPLIED',
  CANCELED = 'CANCELED',
  CONFIRMED = 'CONFIRMED',
  USED = 'USED',
}

export enum TransactionState {
  COMPLETED = 'COMPLETED',
  DELETED = 'DELETED',
  FAILED = 'FAILED',
  REQUESTED = 'REQUESTED',
  RETRACTED = 'RETRACTED',
  WAITING = 'WAITING',
}

export enum TransactionType {
  CANCELLATION = 'CANCELLATION',
  EXCHANGE = 'EXCHANGE',
  PURCHASE = 'PURCHASE',
  RETURN = 'RETURN',
}

export enum UserState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  WITHDRAWAL = 'WITHDRAWAL',
}

export enum WaitingTarget {
  ITEM = 'ITEM',
  SCHEDULE = 'SCHEDULE',
  SCHEDULEITEM = 'SCHEDULEITEM',
}

export enum WritableProductReviewState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  ABSENTED = 'ABSENTED',
  CANCELLED = 'CANCELLED',
  DONE = 'DONE',
}

export enum IssuedCouponState {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  INACTIVE = 'INACTIVE',
  WITHDRAW = 'WITHDRAW',
}

export enum DownloadCouponTargetType {
  ListingThemeCollection = 'ListingThemeCollection',
  Magazine = 'Magazine',
  FripMagazine = 'FripMagazine',
  Product = 'Product',
}

export enum DownloadCouponButtonDisplayPosition {
  DOWN = 'DOWN',
  UP = 'UP',
}

export enum TicketOrderType {
  LATEST = 'LATEST',
  OLDEST = 'OLDEST',
  SCHEDULE_EARLIEST = 'SCHEDULE_EARLIEST',
  SCHEDULE_NEAREST = 'SCHEDULE_NEAREST',
}
// ----------------------------------------

export interface Badge {
  id: string;
  activeContent: Content;
  inactiveContent: Content;
  group: BadgeMonthlyGroup;
  coupon: Coupon;
  deepLinkUrl: string;
  isAcquired: boolean;
  issuedBadgeInfo: IssuedBadgeInfo;
  name: string;
  type: BadgeType;
}

export interface BadgeMenuInfo {
  currentGroupBadge: BadgeMonthlyGroup;
  participant: number;
}

export interface BadgeMonthlyGroup {
  badges: Badge[];
  id: string;
  indexNo: number;
  isCurrent: boolean;
  isOpened: boolean;
  representativeContent?: Content;
  subTitle: string;
  title: string;
}

export interface Banner {
  id: string;
  actionUri: string;
  actionUris: BannerActionUri[];
  indexNo: number;
  title: string;
  description: string;
  content: Content;
  imageInfo: BannerImageInfo;
  contents: BannerContent[];
  beginShowingAt?: string;
  endShowingAt?: string;
  createdAt: string;
  updatedAt: string;
  status: CommonState;
}

export interface BannerActionUri {
  id: string;
  actionUri: string;
  platforms: ClientPlatform[];
}

export interface BannerImageInfo {
  id: string;
  width: number;
  height: number;
  platforms: ClientPlatform[];
  maximumFileSize: number;
  createdAt: string;
  updatedAt: string;
  status: CommonState;
}

export interface BannerContent {
  id: string;
  testType: string;
  content: Content;
  imageInfo: BannerImageInfo;
}

export interface Booking {
  counts: BookingCountInfo;
  createdAt?: string;
  customer: User;
  firmedAt: string;
  approvedAt: string;
  id: string;
  info: ItemInfoForBooking;
  items: BookingItem[];
  minimumQuota: number;
  purchaseCount: number;
  quota: number;
  schedule?: Schedule;
  status: BookingState;
  tickets: Ticket[];
  order: Order;
}

export interface BookingConnection {
  edges: BookingEdge[];
  pageInfo: PageInfoEx;
  totalCount: number;
}

export interface BookingCountInfo {
  absent: number;
  applied: number;
  confirmed: number;
  total: number;
  used: number;
}

export interface BookingEdge {
  cursor: string;
  node: Booking;
}

export interface BookingItem {
  id: string;
  indexNo: number;
  replies: ReplyForInquiryOnBooking[];
  status: BookingState;
  ticketCount: number;
  tickets: Ticket[];
  counts: BookingCountInfo;
  booking: Booking;
}

export interface BookingStateCountInfo {
  absent: number;
  applied: number;
  confirmed: number;
  total: number;
  used: number;
}

export interface BundleOfInquiries {
  additionalInquiries: ItemAdditionalInquiry[];
}

export interface Category {
  id: string;
  name: string;
  code: string;
  areaUnrelated: boolean;
  depth: number;
  content?: Content;
  children: Category[];
  countOfProduct: number;
}

export interface CategoryLabel {
  id: string;
  code: string;
  name: string;
  status: CommonState;
}

export interface CancelInformation {
  amount: Money;
  label: string;
  type: CancelInformationType;
}

export interface CancelInformationDetail {
  amount: Money;
  items: OrderItemInfo[];
  payment?: FripCouponPayment;
  title: string;
  type: CancelInformationType;
}

export interface CancelRequestParamInput {
  forced?: boolean;
  fullyCancel: boolean;
  items?: CancelRequestItemParamInput[];
  kind: CancelReasonKind;
  manualRefund?: boolean;
  orderId: string;
  reason: string;
  refundAccount?: RefundBankAccountParamInput;
  refunds?: CancelRequestItemRefundInfoInput[];
}

export interface CancelRequestItemParamInput {
  indices?: number[];
  orderItemId: string;
  quantity: number;
  refundInfo?: CancelRequestItemRefundInfoInput;
}

export interface CancelRequestItemRefundInfoInput {
  ratio: number;
  targetOrderItemId: string;
}

export interface ChattingChannel {
  id: string;
  chattingKey: string;
  title: string;
  status: ChattingChannelState;
  type: ChattingChannelType;
  metadata: ChattingChannelMetadata;
  createdAt: string;
  members: ChattingChannelMember[];
}

export interface ChattingChannelMember {
  id: string;
  status: ChattingChannelState;
  role: ChattingChannelMemberRole;
  leftAt?: string;
  createdAt: string;
  updatedAt: string;
}

export interface ChattingChannelMetadata {
  product?: Product;
  item?: Item;
  schedule?: Schedule;
}

export interface Checkout {
  customer: User;
  id: string;
  items: CheckoutItem[];
  status: CommonState;
}

export interface CheckoutItem {
  bundleOfInquires: BundleOfInquiries[];
  count: number;
  item: Item;
  schedule?: Schedule;
}

export interface Commission {
  bias: number;
  charge: number;
  type: CommissionType;
}

export interface Content {
  id: string;
  type: ContentType;
  width: number;
  height: number;
  uri: string;
  thumbnail: string;
  contentId: string;
}

export interface ContentUploadParam {
  fileName: string;
  target?: ContentTarget;
  targetId?: string;
}

export interface ContentUploadInfo {
  contentId: string;
  uploadUrl: string;
  formData: ContentUploadInfoItem[];
}

export interface ContentUploadInfoItem {
  key: string;
  value: string;
}

export interface ContentTargetWithIdParam {
  contentId: string;
  target: ContentTarget;
  targetId: string;
}

export interface Convenience {
  id: string;
  name: string;
}

export interface CountsOfProductState {
  count: number;
  label: string;
  status: ProductState;
}

export interface Coupon {
  applyType: CouponApplyType;
  calculatingElement: CouponCalculatingElement;
  catchphrase: string;
  description: string;
  hostBurdenRatio: number;
  id: string;
  issuableCountByMe: number;
  issuableCountPerUser: number;
  issuableTerm: FixedTerm;
  issueType: CouponIssueType;
  issuedCount: number;
  keywords: string[];
  maxBenefit?: number;
  maxIssuableCount: number;
  minApplicationAmount: number;
  policies: CouponPolicy[];
  title: string;
  usableTermElement: UsableTermElement;
  usedCount: number;
  duplicateUseAllowed: boolean;
  useType: CouponUseType;
}

export interface CouponCalculatingElement {
  calculatingType: CouponCalculatingType;
  calculatingValue: number;
}

export interface CouponPageInfo {
  endCursor?: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor?: string;
}

export interface CouponPolicy {
  allowed: boolean;
  coupon: Coupon;
  id: string;
  reference: CouponPolicyReference;
}

export interface CouponPolicyReference {
  id: number;
  type: CouponPolicyReferenceType;
}

export interface DownloadCouponButtonInfo {
  buttonImage: Content;
  displayPosition: DownloadCouponButtonDisplayPosition;
  id: string;
  status: CommonState;
}

export interface DownloadCouponTargetInfo {
  id: string;
  status: CommonState;
  targetId: string;
  targetType: DownloadCouponTargetType;
  createdAt: number;
  updatedAt: number;
  description: string;
  downloadCouponButtonInfos: DownloadCouponButtonInfo[];
  downloadableCoupons: Coupon[];
}

export interface Courier {
  id: string;
  name: string;
  oversea: boolean;
}

export interface Curriculum {
  kind: CurriculumKind;
  sections: CurriculumSection[];
  style: CurriculumStyle;
  title?: string;
}

export interface CurriculumHourMinute {
  hour: number;
  minute: number;
}

export interface CurriculumSection {
  etc: string;
  id?: string;
  items: CurriculumSectionItem[];
  status?: CommonState;
  title: string;
  isOpenEtc?: boolean;
}

export interface CurriculumSectionItem {
  content: string;
  duration: number;
  id?: string;
  status?: CommonState;
  term: CurriculumTimeTerm;
  textTime: string;
}

export interface CurriculumTimeTerm {
  endingTime: CurriculumHourMinute;
  startingTime: CurriculumHourMinute;
}

export interface CollectionCondition {
  id: string;
  maxImageSize: number;
  imageWidth: number;
  imageHeight: number;
  platform: ClientPlatform[];
}

export interface CollectionSlot {
  id: string;
  description: string;
  maximumCountOfCollection: number;
  collectionType: CollectionType;
  conditions: CollectionCondition[];
  collections: Collection[];
  countOfVisibleCollections: number;
}

export interface CollectionProductEdge {
  node: ListingProduct;
  cursor: string;
}

export interface CollectionProductConnection {
  totalCount: number;
  edges: CollectionProductEdge[];
  pageInfo: PageInfo;
}

export interface CollectionImage {
  platform: ClientPlatform[];
  content: Content;
}

export interface Collection {
  id: string;
  title: string;
  products: CollectionProductConnection;
  slot: CollectionSlot;
  seqNo: number;
  description: string;
  homeShowStartAt?: string;
  homeShowEndAt?: string;
  listShowStartAt?: string;
  listShowEndAt?: string;
  status: CommonState;
  isShownAtHome: boolean;
  content?: Content;
  detailContent?: Content;
  images: CollectionImage[];
}

export interface Delivery {
  cost: Money;
  courier?: Courier;
  customer: User;
  host: Host;
  id: string;
  items: DeliveryItem[];
  recipient: Recipient;
  status: DeliveryState;
  trackingNo?: string;
}

export interface DeliveryConnection {
  edges: DeliveryEdge[];
  pageInfo: PageInfoEx;
  totalCount: number;
}

export interface DeliveryEdge {
  cursor: string;
  node: Delivery;
}

export interface DeliveryItem {
  count: number;
  id: string;
  info: ItemInfoForDelivery;
}

export interface DisplayArea {
  id: string;
  name: string;
  depth: number;
  seqNo: number;
  status: CommonState;
  parent?: DisplayArea;
  children: DisplayArea[];
  mappedStandardAreas: StandardArea[];
}

export interface DisplayCategory {
  id: string;
  label: CategoryLabel;
  depth: number;
  seqNo: number;
  status: CommonState;
  parent?: DisplayCategory;
  children: DisplayCategory[];
  relatedStandards: StandardCategory[];
  countOfProduct: number;
  areaUnrelated: boolean;
  content: Content;
}

export interface DisplayCategoryConnection {
  totalCount: number;
  pageInfo: PageInfo;
  edges: DisplayCategoryEdge[];
}

export interface DisplayCategoryEdge {
  cursor: string;
  node: DisplayCategory;
}

export interface ExposedChannel {
  description: string;
  id: string;
  name: string;
  status: CommonState;
}

export interface ExternalProvision {
  id: string;
  name: string;
  status: CommonState;
}

export interface FixedTerm {
  endedAt: number;
  startedAt: number;
}

export interface FixedTermInput {
  endedAt: string;
  startedAt: string;
}

export interface FripInfo {
  attachedToSchedule: boolean;
  bookingApprovalEnabled: boolean;
  bookingConfirmationEnabled: boolean;
  kind: FripKind;
  countRestrictionBySchedule: boolean;
  curriculum: Curriculum;
  daysOfExpiration: number;
  difficulty: LevelOfDifficulty;
  exclusions: string[];
  inclusions: string[];
  locationOfGathering?: FripLocation;
  locationsOfVenue: FripLocation[];
  plans: FripPlan[];
  recommendedAge: number;
  stuffsToPrepare: string[];
  interactionType: InteractionType;
  companions: CompanionType[];
  isIndoor: boolean;
  isOutdoor: boolean;
}

export interface FripLocation {
  address: string;
  geoPoint?: GeoPoint;
  id?: string;
  kind: LocationKind;
  label: string;
  location?: Location;
  overseas: boolean;
  status: CommonState;
}

export interface FripPlan {
  appliedAllItems: boolean;
  id: string;
  minimumQuota: number;
  name: string;
  overNight: boolean;
  quota: number;
  status: CommonState;
  preparatoryPeriod: number;
  term: Term;
  targetItems: { id: string }[];
  times: ScheduleTimeParam[];
  weeks: DayOfWeek[];
}

export interface FripCouponPayment {
  amount: Money;
  canceled: boolean;
  completedAt?: number;
  id: string;
  issuedCoupon: IssuedCoupon;
  status: PaymentState;
  targets: FripCouponPaymentTarget[];
}

export interface FripCouponPaymentTarget {
  benefit: string;
  item: Item;
  orderItem: OrderItem;
  quantity: number;
}

export interface GeoPoint {
  latitude: number;
  longitude: number;
}

export interface GrossPurchaseLimitation {
  count: number;
  days: number;
  target: GrossPurchaseLimitationTarget;
}

export interface Host {
  id: string;
  name: string;
  description: string;
  phoneNumber: string;
  personalInfo: HostPersonalInfo;
  memo?: string;
  super: boolean;
  concerned: boolean;
  introducer: HostIntroducer;
  managerName?: string;
  user: User;
  createdAt: string;
  updatedAt: string;
  profileImage?: Content;
  grade: HostGrade;
  approved: boolean;
  approvedAt?: string;
  expiredAt?: string;
  approver?: User;
  productInfo: ProductInfoByHost;
  chattingUserId?: string;
  wishInfo: HostWishInfo;
  productReviewInfo: ProductReviewInfo;
}

export interface HostIntroducer {
  typeId: string;
}

export interface HostPersonalInfo {
  name: string;
  phoneNumber: string;
  email: string;
}

export interface HostWishConnection {
  totalCount: number;
  pageInfo: PageInfo;
  edges: HostWishEdge[];
}

export interface HostWishEdge {
  cursor: string;
  node: Host;
}

export interface HostWishInfo {
  count: number;
  isWishedByMe: boolean;
}

export interface HotKeyword {
  id: string;
  exposureText: string;
  text: string;
  seqNo: number;
}

export interface InquiryTemplate {
  host: Host;
  id: string;
  inquiries: InquiryTemplateItem[];
  name: string;
  status: CommonState;
}

export interface InquiryTemplateChoosingItem {
  id: string;
  label: string;
  value: string;
}

export interface InquiryTemplateItem {
  id: string;
  inputKind: ItemInquiryInputKind;
  inputPattern: ItemInquiryInputPattern;
  items: InquiryTemplateChoosingItem[];
  required: boolean;
  status: CommonState;
  title: string;
}

export interface IssuedCoupon {
  coupon: Coupon;
  id: string;
  status: IssuedCouponState;
  usableTerm: FixedTerm;
  used: boolean;
  userId: number;
}

export interface IssuedCouponConnection {
  edges: IssuedCouponEdge[];
  pageInfo: CouponPageInfo;
  totalCount: number;
}

export interface IssuedCouponEdge {
  cursor: string;
  node: IssuedCoupon;
}

export interface IssuedBadge {
  badge: Badge;
  createdAt: number;
  id: string;
  order: IssuedBadgeOrder;
  product: IssuedBadgeProduct;
  user: User;
}

export interface IssuedBadgeOrder {
  completedAt: string;
  id: string;
}

export interface IssuedBadgeProduct {
  id: string;
  title: string;
}

export interface Item {
  absentFee: Commission;
  additionalInquiries: ItemAdditionalInquiry[];
  canSale: boolean;
  commission: Commission;
  daysOfExpiration: number;
  description: string;
  id: string;
  indexNo: number;
  inquiryTemplate?: InquiryTemplate;
  maximumPurchasableCount: number;
  minimumQuota: number;
  quota: number;
  name: string;
  options: ItemOption[];
  price: Price;
  product: Product;
  representative: boolean;
  saleTerm?: Term;
  salesType: SalesType;
  scheduledBySale: boolean;
  status: ItemState;
  stockCount: number;
  ticketCount: number;
  type: ItemType;
  waitingInfo?: WaitingInfo;
  digitalContent: { id: string; uri: string };
}

export interface ItemAdditionalInquiry {
  id: string;
  inputKind: ItemInquiryInputKind;
  inputPattern: ItemInquiryInputPattern;
  items: ItemInquiryChoosingItem[];
  required: boolean;
  status: CommonState;
  title: string;
  relatedInquiryIds: string[];
}

export interface ItemInfoForBooking {
  host: Host;
  id: string;
  name: string;
  options: ItemOptionName[];
  origin: Item;
  price: Price;
  product: ProductForBooking;
  ticketCount: number;
}

export interface ItemInfoForDelivery {
  host: Host;
  id: string;
  name: string;
  options: ItemOptionName[];
  origin: Item;
  price: Price;
  product: ProductForDelivery;
  ticketCount: number;
}

export interface ItemInfoForOrder {
  absentFee: Commission;
  commission: Commission;
  host: Host;
  id: string;
  name: string;
  options: ItemOptionName[];
  origin: Item;
  price: Price;
  product: ProductForOrder;
  ticketCount: number;
  type: ItemType;
  saleTerm: Term | null;
}

export interface ItemInquiryChoosingItem {
  id: string;
  label: string;
  status: CommonState;
  value: string;
}

export interface ItemOption {
  id: string;
  level: number;
  name: string;
  status: CommonState;
  title: string;
}

export interface ItemOptionName {
  id: string;
  level: number;
  name: string;
}

export interface IntroPopup {
  id: string;
  title: string;
  urls: IntroPopupUrl[];
  url: string;
  image: Content;
  content: string;
  startedAt: string;
  endedAt: string;
  exposed: boolean;
  contentType: IntroPopupContentType;
  indexNo: number;
}

interface IntroPopupUrl {
  id: string;
  url: string;
  platform: ClientPlatform;
}

export interface IssuedBadgeInfo {
  order: IssuedBadgeOrder;
  product: IssuedBadgeProduct;
}

export interface ListingProduct {
  id: string;
  title: string;
  catchphrase?: string;
  price: number;
  salePrice: number;
  type: ProductType;
  reviewCount: number;
  wishCount: number;
  rating: number;
  headerContents: ProductHeaderContent[];
  almostSoldOut: boolean;
  status: ProductState;
  kind: ProductKind;
  tags: Tag[];
  defaultArea: DisplayArea;
  wished: boolean;
  isBenepia?: boolean;
  scheduleFirstDate: string;
  scheduleLastDate: string;
  representativeQuota: number;
  representativeSaleCount: number;
  geoLocationPoint: GeoPointRequest;
  displayCategories: DisplayCategory[];
}

export interface ListingProductConnection {
  totalCount: number;
  pageInfo: PageInfo;
  edges: ListingProductEdge[];
}

export interface ListingProductEdge {
  cursor: string;
  node: ListingProduct;
}

export interface ListingProductFilter {
  isInstant?: boolean;
  isSuperHost?: boolean;
  locationTag?: number;
  socialTypeTags?: number[];
  priceRange?: ListingProductPriceRange;
  term?: ListingProductTerm;
  searchString?: string;
  displayAreaId?: string;
}

export interface ListingProductPriceRange {
  minPrice: number;
  maxPrice: number;
}

export interface ListingProductTerm {
  startDate: number;
  endDate: number;
}

export interface ListingProductsWithTitle {
  title: string;
  products: ListingProductConnection;
}

export interface Location {
  address?: string;
  conveniences: Convenience[];
  geoPoint: GeoPoint;
  id: string;
  name: string;
  overseas: boolean;
  parking: LocationParkingInfo;
  status: CommonState;
  useByDefault: boolean;
}

export interface LocationParkingInfo {
  available: boolean;
  note?: string;
}

export interface Manager {
  departmentName: string;
  id: string;
  name: string;
  staffNo: string;
  status: CommonState;
}

export interface Money {
  currencyCode: CurrencyCode;
  value: number;
}

export interface MyBooking {
  counts: BookingStateCountInfo;
  list: BookingConnection;
}

export interface MyBookingFilterInput {
  ticketStatusIn: TicketState[];
}

export interface MyBadge {
  acquiredPercent: number;
  count: number;
  fripCount: number;
}

export interface MyCouponFilterInput {
  usableTerm?: FixedTermInput;
  used?: boolean;
}

export interface MyDeliveryFilterInput {
  statusIn: DeliveryState[];
}

export interface MyFeed {
  count: number;
}

export interface MyInfo {
  id: string;
  user: User;
  host?: Host;
  orders: OrderConnection;
  issuedCoupons: IssuedCouponConnection;
  deliveries: DeliveryConnection;
  bookings: BookingConnection;
  wishList: WishList;
  bookmarkedDisplayAreas: DisplayArea[];
  writableProductReviews: WritableProductReviewConnection;
}

export interface MyPurchaseItemFilterInput {
  excludedNothingLeft?: boolean;
  includedCancel?: boolean;
  lastPurchaseDays?: number;
}

export interface NoticeReceiveAgreement {
  id: string;
  channel: NoticeMediaChannel;
  advertised: boolean;
  agreed: boolean;
  agreedAt?: string;
  updatedAt: string;
}

export interface NoticeReceiveParam {
  channel: NoticeMediaChannel;
  advertised: boolean;
  agreed: boolean;
  store?: string;
}

export interface Order {
  customer: User;
  discountAmount: Money;
  id: string;
  items: OrderItem[];
  netAmount: Money;
  payments: OrderPayment[];
  recipient?: Recipient;
  status: OrderState;
  totalAmount: Money;
  transactions: OrderTransaction[];
}

export interface OrderConnection {
  edges: OrderEdge[];
  pageInfo: PageInfoEx;
  totalCount: number;
}

export interface OrderEdge {
  cursor: string;
  node: Order;
}

export interface OrderItem {
  amount: Money;
  canceled: boolean;
  count: number;
  dependedItems: OrderItem[];
  expiredAt?: string;
  id: string;
  info: ItemInfoForOrder;
  policy: PolicyIntegration;
  remains: number;
  schedule?: Schedule;
  transaction: OrderTransaction;
  units: OrderItemUnit[];
  booking?: Booking;
  reviewInfo: OrderItemReviewInfo;
}

export interface OrderItemConnection {
  edges: OrderItemEdge[];
  pageInfo: PageInfoEx;
  totalCount: number;
}

export interface OrderItemEdge {
  cursor: string;
  node: OrderItem;
}

export interface OrderItemReviewInfo {
  writableReview?: WritableProductReview;
  review?: ProductReview;
}

export interface OrderItemUnit {
  id: string;
  indexNo: number;
  replies: ReplyForInquiry[];
  status: CommonState;
}

export interface OrderItemInfo {
  count: number;
  info: ItemInfoForOrder;
  schedule?: Schedule;
}

export interface OrderPayment {
  agencyType: PaymentAgencyType;
  amount: Money;
  balance: Money;
  canceled: boolean;
  dependedItems: OrderPayment[];
  id: string;
  method: PaymentMethod;
  methodLabel: string;
  status: PaymentState;
}

export interface OrderTransaction {
  cancelInfos: CancelInformation[];
  cancelReason?: string;
  cancelReasonKind?: CancelReasonKind;
  discountAmount: Money;
  firm: boolean;
  firmedAt: string;
  id: string;
  items: OrderItem[];
  netAmount: Money;
  order: Order;
  payments: OrderPayment[];
  platForm: ClientPlatform;
  status: TransactionState;
  store: string;
  totalAmount: Money;
  type: TransactionType;
  worker?: User;
}

export interface PageInfo {
  hasNextPage: boolean;
  endCursor?: string;
}

export interface PageInfoEx {
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
}

export interface Point {
  id: string;
  kind: PointKind;
  type: PointType;
  amount: number;
  usedAmount?: number;
  refType?: PointRefType;
  refId?: string;
  user: User;
  title: string;
  description?: string;
  createdAt: string;
  updatedAt?: string;
  appliedAt?: string;
  expiredAt?: string;
  accountingType: PointAccountingType;
  worker?: User;
  cancelled: boolean;
  deleted: boolean;
  deletedAt?: string;
  usedHistories: PointUsedHistory[];
  savedLinks: PointLink[];
}

export interface PointConnection {
  totalCount: number;
  pageInfo: PageInfo;
  edges: PointEdge[];
}

export interface PointEdge {
  cursor: string;
  node: Point;
}

export interface PointLink {
  saved: Point;
  amount: number;
}

export interface PointType {
  id: string;
  name: string;
  code: string;
  label?: string;
  amount?: number;
  minAmount?: number;
  maxAmount?: number;
  onlyAdmin: boolean;
  status: string;
  worker: User;
}

export interface PointUsedHistory {
  used: Point;
  amount: number;
}

export interface Price {
  currencyCode: CurrencyCode;
  discountRate: number;
  retail: number;
  sale: number;
}

export interface Product {
  attributes: ProductAttribute[];
  cancelingRestricted: boolean;
  productBanner?: ProductBanner;
  canSale: boolean;
  catchphrase: string;
  categories: ProductCategory[];
  contents: ProductContent[];
  exposures: ExposedChannel[];
  externalProvisions: ExternalProvision[];
  grossPurchaseLimitation: { count: number };
  frip?: FripInfo;
  headerContents: ProductHeaderContentV2[];
  inventoryTargetOptionLevel: number;
  host: Host;
  id: string;
  inquiryTemplate?: InquiryTemplate;
  inventoryTargetType: InventoryTargetType;
  items: Item[];
  keywords: string[];
  kind: ProductKind;
  manager?: Manager;
  maximumPurchasableCount: number;
  notice: string;
  options: ProductOption[];
  period?: SchedulePeriod;
  representativePrice: Price;
  saleTerm?: Term;
  staff?: Manager;
  standardCategories: StandardCategory[];
  status: ProductState;
  tags: Tag[];
  title: string;
  underageRestricted: boolean;
  deliveryInfo?: ProductDeliveryInfo;
  wishInfo: ProductWishInfo;
  reviewInfo: ProductReviewInfo;
  refundPolicy?: RefundPolicy;
  updatedAt: string;
  firstOpenedAt: number;
  createdAt: number;
}

export interface ProductAnswer {
  host?: Host;
  answer: string;
  createdAt: string;
  updatedAt?: string;
}

export interface ProductAttribute {
  description: string;
  id: string;
  kind: ProductKind;
  name: string;
  status: CommonState;
}

export interface ProductBanner {
  content: Content;
  id: string;
}

export interface ProductCategory {
  displayCategory: DisplayCategory;
  representative: boolean;
}

export interface ProductContent {
  content: string;
  id: string;
  status: CommonState;
  style: ProductContextStyle;
  type: ProductContextType;
}

export interface ProductDeliveryInfo {
  cost: Money;
}

export interface ProductForBooking {
  catchphrase: string;
  content?: Content;
  id: string;
  title: string;
}

export interface ProductForDelivery {
  catchphrase: string;
  content?: Content;
  id: string;
  title: string;
}

export interface ProductForOrder {
  catchphrase: string;
  content?: Content;
  id: string;
  title: string;
}

export interface ProductGeoLocationPoint {
  latitude: number;
  longitude: number;
}

export interface ProductHeaderContent {
  style: ProductHeaderContentStyle;
  content: Content;
}

export interface ProductHeaderContentV2 {
  content: Content;
  id: string;
  style: ProductHeaderContentStyle;
}

export interface ProductInfoByHost {
  counts: CountsOfProductState[];
}

export interface ProductOption {
  id: number;
  level: number;
  names: string[];
  status: CommonState;
  title: string;
}

export interface ProductQna {
  id: string;
  product: Product;
  question: string;
  user: User;
  answer?: ProductAnswer;
  createdAt: string;
  secret: boolean;
  status: CommonState | ProductQnaState;
}

export interface ProductQnaConnection {
  totalCount: number;
  edges: ProductQnaEdge[];
  pageInfo: QnaPageInfo;
}

export interface ProductQnaEdge {
  node: ProductQna;
  cursor: string;
}

export interface ProductReview {
  id: string;
  content: string;
  rating: number;
  status: ReviewState;
  writer: User;
  createdAt: string;
  updatedAt: string;
  target?: TargetOfProductReview;
  thumbsUpCount: number;
  commentCount: number;
  reportCount: number;
  attachmentCount: number;
  latestComments: ProductReviewCommentConnection;
  reported: boolean;
  thumbsUp: boolean;
  modifiable: boolean;
  attachmentContents: Content[];
}

export interface ProductReviewWritingParam {
  content: string;
  rating: number;
  attachmentContentIds: string[];
  writableReviewId: string;
}

export interface ProductReviewModifyingParam {
  id: string;
  content: string;
  rating: number;
  attachmentContentIds: string[];
}

export interface ProductReviewComment {
  id: string;
  content: string;
  writer: User;
  host?: Host;
  status: ReviewState;
  createdAt: string;
  updatedAt: string;
}

export interface ProductReviewCommentConnection {
  totalCount: number;
  pageInfo: PageInfo;
  edges: ProductReviewCommentEdge[];
}

export interface ProductReviewCommentEdge {
  cursor: string;
  node: ProductReviewComment;
}

export interface ProductReviewConnection {
  totalCount: number;
  pageInfo: PageInfo;
  edges: ProductReviewEdge[];
}

export interface ProductReviewEdge {
  cursor: string;
  node: ProductReview;
}

export interface ProductReviewInfo {
  count: number;
  rating: number;
  ratingRatio: ProductReviewRatingRatio[];
  reviews: ProductReviewConnection;
}

export interface ProductReviewRatingRatio {
  rating: number;
  count: number;
  ratio: number;
}

export interface ProductWishConnection {
  totalCount: number;
  pageInfo: PageInfo;
  edged: ProductWishEdge[];
}

export interface ProductWishEdge {
  cursor: string;
  node: Product;
}

export interface ProductWishInfo {
  count: number;
  isWishedByMe: boolean;
}

export interface QnaPageInfo {
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface Recipient {
  address: RecipientAddress;
  email?: string;
  keeping?: boolean;
  mobileNo: string;
  name: string;
  note?: string;
  phoneNo?: string;
}

export interface RecipientAddress {
  addressDetail: string;
  buildingName: string;
  jibunAddress: string;
  roadAddress: string;
  selectedType?: AddressType;
  zoneCode: string;
}

export interface RefundBankAccountParamInput {
  accountNo: string;
  code: string;
  holder: string;
  verified: boolean;
}

export interface RefundPolicy {
  description: string;
  extraNotice?: string;
  id: string;
  name: string;
  notice: string;
  representative: boolean;
  status: CommonState;
  type: RefundPolicyType;
}

export interface RecommendationFilter {
  relatedProductId: number;
}

export interface ReplyForAdditionalInquiryParamInput {
  answer: string;
  inquiryId: string;
  inquiryTitle: string;
  relatedInquiryIds: string[];
  selections: ReplyOptionOfAdditionalInquiryParamInput[];
}

export interface ReplyForInquiry {
  answer: string;
  id: string;
  indexNo: number;
  inquiry: ItemAdditionalInquiry;
  selections: ReplyOptionForInquiry[];
  title: string;
}

export interface ReplyForInquiryOnBooking {
  answer: string;
  id: string;
  indexNo: number;
  selections: ReplyOptionForInquiryOnBooking[];
  title: string;
}

export interface ReplyOptionForInquiry {
  label: string;
  value: number;
}

export interface ReplyOptionForInquiryOnBooking {
  label: string;
  value: number;
}

export interface ReplyOptionOfAdditionalInquiryParamInput {
  label: string;
  optionId: string;
}

export interface PolicyIntegration {
  bookingConfirmationEnabled?: boolean;
  cancelingRestricted: boolean;
  grossPurchaseLimitation: GrossPurchaseLimitation;
  maximumPurchasableCount: number;
  underageRestricted: boolean;
}

export interface Result {
  success: boolean;
  message: string;
}

export interface Schedule {
  appliedAllItems: boolean;
  canSale: boolean;
  counts: ScheduleCounts;
  id: string;
  items: Item[];
  maximumPurchasableCount: number;
  minimumQuota: number;
  quota: number;
  saleTerm?: Term;
  status: ScheduleState;
  term: ScheduleTerm;
  title: string;
  product?: { frip: { kind: FripKind; bookingApprovalEnabled: boolean } };
  waitingInfo?: WaitingInfo;
  booking?: { approvedCounts: number };
}

export interface ScheduleCounts {
  quota: number;
  remains: number;
  sale: number;
}

export interface ScheduleDayWrapper {
  date: string;
  day: number;
  schedules: Schedule[];
  month: Month;
  status: ScheduleState;
  year: number;
}

export interface SchedulePeriod {
  endedAt: string;
  startedAt: string;
}

export interface ScheduleTerm {
  duration: number;
  endedAt: number;
  startedAt: number;
}

export interface SelectedItem {
  countOfSale: number;
  id: string;
  item?: Item;
  level?: number;
  name: string;
  quota: number;
  remains: number;
  saleTerm?: Term;
  status: ItemState;
  title?: string;
}

export interface ServiceAnswer {
  answer: string;
  createdAt: string;
  updatedAt: string;
}

export interface ServiceQuestionType {
  id: string;
  typeName: string;
}

export interface ServiceQna {
  id: string;
  question: string;
  user: User;
  answer: ServiceAnswer;
  createdAt: string;
  questionType: ServiceQuestionType;
  images: Content[];
  title: string;
  isVisibleFeedback: boolean;
  feedback: FeedbackAnswer;
  status: CommonState;
}

export interface ServiceQnaFilter {
  userId: string;
  answer: string;
  question: string;
  title: string;
  typeIds: string[];
  statusIn: CommonState;
  isAnswering: boolean;
}

export interface ServiceQnaConnection {
  totalCount: number;
  edges: ServicQnaEdge[];
  pageInfo: QnaPageInfo;
}

export interface ServicQnaEdge {
  node: ServiceQna;
  cursor: string;
}

export interface ServiceQuestionParam {
  question: string;
  id?: string;
  secret?: boolean;
  imageIds?: string[];
  title: string;
  typeId: string;
}

export interface SocialLink {
  id: string;
  socialUserId: string;
  provider: SocialLinkProviderType;
}

export interface SocialLinkFilter {
  provider?: SocialLinkProviderType;
}

export interface StandardArea {
  id: string;
  name: string;
  depth: number;
  seqNo: number;
  status: CommonState;
  parent?: StandardArea;
  children: StandardArea[];
  mappedDisplayAreas: DisplayArea[];
}

export interface StandardCategory {
  id: string;
  label: CategoryLabel;
  path: string[];
  depth: number;
  seqNo: number;
  parent?: StandardCategory;
  status: CommonState;
  children: StandardCategory[];
  relatedDisplays: DisplayCategory[];
}

export interface Tag {
  backgroundColor: string;
  id: string;
  kind: TagKind;
  name: string;
  status: CommonState;
  textColor: string;
}

export interface TargetOfProductReview {
  id: string;
  product?: Product;
  orderItem?: OrderItem;
}

export interface Term {
  duration?: number;
  endedAt: number;
  startedAt: number;
}

export interface BookingTicket {
  booking: {
    tickets: { totalCount: number; pageInfo: PageInfo; edges: TicketEdge[] };
  };
}

export interface TicketEdge {
  node: Ticket;
}

export interface Ticket {
  absentAt?: string;
  absentBy?: User;
  cancelable: boolean;
  canceledAt?: string;
  canceledBy?: User;
  confirmedAt?: string;
  confirmedBy?: User;
  expiredAt?: string;
  id: string;
  scheduleTerm?: ScheduleTerm;
  sequence: number;
  status: TicketState;
  usedAt?: string;
  usedBy?: User;
  bookingItem: BookingItem;
}

export interface UsablePoint {
  total: number;
  availables: number;
}

export interface UsableTermElement {
  usableDays?: number;
  usableTerm?: FixedTerm;
}

export interface UseSerialResponse {
  code: string;
  message: string;
  success: boolean;
  type?: SerialBundleType;
  useType?: CouponUseType;
}

export interface User {
  id: string;
  email: string;
  mobileNumber: string;
  nickname: string;
  birth: string;
  gender: string;
  noticeReceiveAgreements: NoticeReceiveAgreement[];
  socialLinks: SocialLink[];
  privateProfile: boolean;
  joinedAt: string;
  updatedAt: string;
  certificated: boolean;
  invitationCode?: string;
  referee?: User;
  status: UserState;
  profileImage?: Content;
  chattingUserId?: string;
  usablePoint: UsablePoint;
  points: PointConnection;
  productReviews: ProductReviewConnection;
}

export interface WaitingNotificationInfo {
  id: string;
  schedule?: Schedule;
}

export interface WaitingInfo {
  count: number;
  isWaiting: boolean;
}

export interface WishList {
  hosts: HostWishConnection;
  products: ProductWishConnection;
}

export interface WritableProductReview {
  id: string;
  status: WritableProductReviewState;
  startedAt: string;
  endedAt: string;
  target: TargetOfProductReview;
  createdAt: string;
  updatedAt: string;
  cancelledAt?: string;
}

export interface WritableProductReviewConnection {
  totalCount: number;
  pageInfo: PageInfo;
  edges: WritableProductReviewEdge[];
}

export interface WritableProductReviewEdge {
  cursor: string;
  node: WritableProductReview;
}
