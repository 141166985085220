import { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { MeData } from 'graphQL/Auth/types';
import { FripKind, ProductState } from 'types/api';
import { GET_PRODUCT_LIST } from '../graphQL';
import { ProductFilterInput, ProductList } from '../graphQL/types';
import { useMutationDeleteProduct } from '../graphQL/hooks/useMutationDeleteProduct';

export const useProductRegistModalHooks = (myInfo: MeData) => {
  const [pageSize, setPageSize] = useState<number>(3);
  const [productFilterInput, setProductFilterInput] =
    useState<ProductFilterInput>({
      hostId: myInfo.me.host.id,
      statusIn: [ProductState.EDITING],
      fripKind: FripKind.COMMON,
    });

  const { data, refetch } = useQuery(GET_PRODUCT_LIST, {
    variables: {
      page: 1,
      size: pageSize,
      filter: productFilterInput,
    },
    fetchPolicy: 'no-cache',
  });
  const [productList, setProductList] = useState<ProductList>();
  const [isLoadMore, setIsLoadMore] = useState<boolean>(false);
  const [viewType, setViewType] = useState<'regist' | 'copy'>('regist');

  const handleLoadMoreProductList = async () => {
    try {
      const response = await refetch({
        page: 1,
        size: pageSize + 3,
        filter: productFilterInput,
      });
      setPageSize(pageSize + 3);

      if (response) {
        if (!response.data.product.products.pageInfo.hasNextPage) {
          setIsLoadMore(true);
        }

        setProductList(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSetRegistProductList = () => {
    setPageSize(3);
    setProductFilterInput({
      hostId: myInfo.me.host.id,
      statusIn: [ProductState.EDITING],
      fripKind: FripKind.COMMON,
    });
  };

  const handleGetCopyProductList = () => {
    setViewType('copy');
    setPageSize(9);
    setProductFilterInput({
      hostId: myInfo.me.host!.id,
      statusIn: [
        ProductState.READY,
        ProductState.SALE,
        ProductState.SOLD_OUT,
        ProductState.PAUSED,
        ProductState.SUSPENDED,
        ProductState.CLOSED,
      ],
      fripKind: FripKind.COMMON,
    });
  };

  const handleRefetchProductList = async () => {
    try {
      await refetch({
        page: 1,
        size: viewType === 'copy' ? 9 : pageSize,
        filter: productFilterInput,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleRefetchProductList();
  }, [productFilterInput]);

  useEffect(() => {
    if (data) {
      setProductList(data);
    }
  }, [data]);

  return {
    productFilterInput,
    productList,
    isLoadMore,
    viewType,
    setProductFilterInput,
    setViewType,
    handleLoadMoreProductList,
    handleSetRegistProductList,
    handleGetCopyProductList,
  };
};
