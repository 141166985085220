import { useMutation } from '@apollo/client';
import { CHANGE_NOTICE_RECEIVER_AGREEMENT } from '..';

enum NoticeMediaChannel {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  PUSH = 'PUSH',
}

export const useMutationChangeNoticeAgreement = () => {
  const [changeNoticeAgreement] = useMutation(CHANGE_NOTICE_RECEIVER_AGREEMENT);

  const handleChangeNoticeAgreement = async (isAgreementMarketing: boolean) => {
    const params = isAgreementMarketing
      ? [
          { channel: NoticeMediaChannel.EMAIL, advertised: true, agreed: true },
          { channel: NoticeMediaChannel.SMS, advertised: true, agreed: true },
          { channel: NoticeMediaChannel.PUSH, advertised: true, agreed: true },
        ]
      : [
          {
            channel: NoticeMediaChannel.EMAIL,
            advertised: false,
            agreed: false,
          },
          { channel: NoticeMediaChannel.SMS, advertised: false, agreed: false },
          {
            channel: NoticeMediaChannel.PUSH,
            advertised: false,
            agreed: false,
          },
        ];

    await changeNoticeAgreement({ variables: { params } });
  };

  return { handleChangeNoticeAgreement };
};
