import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import Column from 'components/common/Column';
import { MOBILE_REGEX } from 'utils/regExp';
import { ICON_AUTH_CLOSE_BUTTON, ICON_CHECK_COMPELETE } from 'assets/icons';
import Text from 'components/common/Text';
import { useCertifictationHooks } from '../hooks/useCertificationHooks';

const Container = styled.div<{ $vaildCheck: boolean; $focused: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  width: 100%;

  border: ${props =>
    !props.$vaildCheck
      ? '1px solid #D63A16 !important'
      : props.$focused
      ? '2px solid #1B1C1E !important'
      : '1px solid #C2C4C8'};
  background-color: ${props => (props.$vaildCheck ? '#fff' : '#FDF4F2')};
  border-radius: 8px;
`;

const Label = styled.div<{ $focused: boolean }>`
  display: flex;
  align-items: center;
  gap: 3px;

  top: ${props => props.$focused && '10px'};

  font-family: Pretendard;
  font-size: ${props => (props.$focused ? '12px' : '16px')};
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  z-index: 6;

  ${({ $focused }) =>
    $focused
      ? css`
          position: static;
        `
      : css`
          position: absolute;
          left: 12px;
        `}
`;

const Input = styled.input`
  width: 100%;
  height: 22px;

  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  caret-color: #c2c4c8;
  line-height: 150%;
  z-index: 7;
  border: none;
  background: none;

  ::placeholder {
    color: #c2c4c8;
  }
`;

const ClearIcon = styled.img`
  display: flex;
  align-items: center;
  position: absolute;
  right: 12px;
  width: 20px;
  z-index: 8;
  cursor: pointer;
`;

const CertificationButton = styled.button<{
  $abled: boolean;
  $isMobileCertification: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 12px 0;
  width: 100%;

  border-radius: 8px;
  border: none;
  background: ${props =>
    props.$isMobileCertification
      ? '#F7F7F8'
      : props.$abled
      ? '#1B1C1E'
      : '#f4f4f5'};
  color: ${props =>
    props.$isMobileCertification
      ? '#989BA2'
      : props.$abled
      ? '#fff'
      : '#dbdcdf'};

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.6px;
`;

interface SignupCertificationIProps {
  mobileNumber: string;
  isMobileCertification: boolean;
  handleChangeSignupParam: (key: string, value: string) => void;
  handleMobileCertification: () => void;
}

const SignupCertification: React.FC<SignupCertificationIProps> = props => {
  const {
    mobileNumber,
    isMobileCertification,
    handleChangeSignupParam,
    handleMobileCertification,
  } = props;

  const [value, setValue] = useState<string>(mobileNumber);
  const [isFocused, setIsFocused] = useState(false);
  const [isVaildCheck, setIsValidCheck] = useState<boolean>(true);

  const handleChange = (e: any) => {
    e.preventDefault();

    setValue(e.target.value);
    handleChangeSignupParam('mobileNumber', e.target.value);
    handleValidCheck(e.target.value);
  };

  const handleValidCheck = (value: string) => {
    if (MOBILE_REGEX.test(value) || value === '') {
      setIsValidCheck(true);
    } else {
      setIsValidCheck(false);
    }
  };

  const handleClearValue = (e: any) => {
    e.preventDefault();

    setValue('');
    handleChangeSignupParam('mobileNumber', '');
  };

  const handleFocus = (e: any) => {
    e.preventDefault();

    setIsFocused(true);
  };

  const handleOnBlur = (e: any) => {
    setIsFocused(false);
  };

  return (
    <Column gap="12px">
      <Column gap="8px" align="flex-start">
        <Container
          $focused={isFocused}
          $vaildCheck={isVaildCheck}
          style={{
            padding: isFocused || value !== '' ? '8px 12px' : '16px 12px',
          }}
        >
          <Label
            $focused={isFocused || value !== ''}
            style={{ color: isVaildCheck ? '#C2C4C8' : '#D63A16' }}
          >
            휴대폰 번호
          </Label>
          <Input
            style={{ color: isMobileCertification ? '#989BA2' : '#1b1c1e' }}
            disabled={isMobileCertification}
            type="text"
            value={value}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleOnBlur}
          />
          {isFocused && value !== '' && (
            <ClearIcon
              src={ICON_AUTH_CLOSE_BUTTON}
              alt="reset icon"
              onMouseDown={handleClearValue}
            />
          )}
        </Container>
        {mobileNumber !== '' && !MOBILE_REGEX.test(mobileNumber) && (
          <Text color="#FF4242" size="12px" weight={400}>
            휴대폰 번호를 다시 확인해 주세요.
          </Text>
        )}
      </Column>
      <CertificationButton
        $isMobileCertification={isMobileCertification}
        $abled={MOBILE_REGEX.test(mobileNumber)}
        onClick={() => {
          if (MOBILE_REGEX.test(mobileNumber) && !isMobileCertification) {
            handleMobileCertification();
          }
        }}
      >
        {isMobileCertification && (
          <img src={ICON_CHECK_COMPELETE} alt="certification check" />
        )}
        {isMobileCertification
          ? '본인 인증이 완료되었습니다.'
          : '본인 인증하기'}
      </CertificationButton>
    </Column>
  );
};

export default SignupCertification;
