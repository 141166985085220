import React, { useState } from 'react';
import styled from 'styled-components';

import ModifyContainer from './ModifyContainer';
import Column from 'components/common/Column';
import Text from 'components/common/Text';
import Row from 'components/common/Row';
import { ICON_CLOSE } from 'assets/icons';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import OptionButton from 'containers/ProductRegist/components/common/OptionButton';
import OnceLimitModifyForm from './ModifyForm/OnceLimitModifyForm';
import { ModifyValue } from '../hooks/useProductDetaillHooks';

const OptionSettingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
  width: 100%;

  border-radius: 12px;
  border: 1px solid #dbdcdf;
  background: #f7f7f8;
`;

const OptionInput = styled.input`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 27px 8px 12px;
  width: 100%;

  text-align: right;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    justify-content: flex-end;
  }

  &:focus {
    border: 1.5px solid #1b1c1e;
  }
`;

interface OnceLimitContainerIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  modifyValue: ModifyValue;
  setModifyValue: React.Dispatch<React.SetStateAction<ModifyValue>>;
}

const OnceLimitContainer: React.FC<OnceLimitContainerIProps> = (props) => {
  const { isMobile, productParamInput, modifyValue, setModifyValue } = props;
  const grossPurchaseLimitation = productParamInput.grossPurchaseLimitation;

  return (
    <ModifyContainer
      title="1인당 재주문 한도"
      isSelected={!isMobile && modifyValue.modifyStatus === 'onceLimit'}
      onClick={() => {
        setModifyValue({ ...modifyValue, modifyStatus: 'onceLimit' });
      }}
    >
      <Column align="flex-start">
        <Text color="#46474c" weight={400}>
          {grossPurchaseLimitation.count === 0
            ? '제한하지 않음'
            : `${grossPurchaseLimitation.count}개`}
        </Text>
      </Column>
    </ModifyContainer>
  );
};

export default OnceLimitContainer;
