import React, { Dispatch, SetStateAction } from 'react';
import Column from 'components/common/Column';
import {
  FripLocationParam,
  ProductParamInput,
  ScheduleParam,
} from 'containers/ProductRegist/hooks/types';
import { InquiryTemplate, Product, ProductState } from 'types/api';
import ProductHeader from './ProductHeader';
import MenuSettingButton from 'components/Button/MenuSettingButton';
import RefundContainer from './ViewForm/RefundContainer';
import ImageContainer from './ViewForm/ImageContainer';
import ProfileContainer from './ViewForm/ProfileContainer';
import ProductTitleContainer from './ViewForm/ProductTitleContainer';
import MoreInformationContainer from './ViewForm/MoreInformationContainer';
import IntroduceContainer from './ViewForm/IntroduceContainer';
import ScheduleContainer from './ViewForm/ScheduleContainer';
import AddressContainer from './ViewForm/AddressContainer';
import AdditionalContainer from './ViewForm/AdditionalContainer';
import PrecautionContainer from './ViewForm/PrecautionContainer';
import QuestionContainer from './ViewForm/QuestionContainer';
import OptionContainer from './ViewForm/OptionContainer';
import SaleTermContainer from './ViewForm/SaleTermContainer';
import OnceLimitContainer from './OnceLimitContainer';
import { ModifyValue } from '../hooks/useProductDetaillHooks';
import CalendarContainer from './ViewForm/CalendarContainer';

interface ViewContainerIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  productInfo: Product;
  inquiryList: InquiryTemplate;
  modifyValue: ModifyValue;
  menuType: string;
  setModifyValue: React.Dispatch<React.SetStateAction<ModifyValue>>;
  handleSetMenuType: (type: string) => void;
  handleSetProductStatus: (status: ProductState) => string;
  setIsOpenCloseProductDetail: Dispatch<SetStateAction<boolean>>;
}

const ViewContainer: React.FC<ViewContainerIProps> = props => {
  const {
    isMobile,
    productParamInput,
    productInfo,
    inquiryList,
    menuType,
    modifyValue,
    setModifyValue,
    handleSetMenuType,
    handleSetProductStatus,
    setIsOpenCloseProductDetail,
  } = props;

  const renderMenuButton = (): JSX.Element => {
    return (
      <Column style={{ padding: '0 20px' }}>
        <MenuSettingButton
          isMobile={isMobile}
          isSelected={menuType === 'detail'}
          firstMenu="상세 페이지"
          secondMenu="옵션 • 일정"
          modifyValue={modifyValue}
          handleSetMenuType={handleSetMenuType}
          setModifyValue={setModifyValue}
        />
      </Column>
    );
  };

  const renderProductHeader = (): JSX.Element => {
    return (
      <ProductHeader
        isMobile={isMobile}
        productInfo={productInfo}
        modifyValue={modifyValue}
        handleSetProductStatus={handleSetProductStatus}
        setIsOpenCloseProductDetail={setIsOpenCloseProductDetail}
      />
    );
  };

  const renderDetailContent = (): JSX.Element => {
    return (
      <Column gap="16px" style={{ padding: '0 20px 90px 20px' }}>
        <ImageContainer
          isMobile={isMobile}
          productParamInput={productParamInput}
          modifyValue={modifyValue}
          setModifyValue={setModifyValue}
        />
        <ProductTitleContainer
          isMobile={isMobile}
          title={productParamInput.title}
          modifyValue={modifyValue}
          setModifyValue={setModifyValue}
        />
        <MoreInformationContainer
          isMobile={isMobile}
          productParamInput={productParamInput}
          modifyValue={modifyValue}
          setModifyValue={setModifyValue}
        />
        <IntroduceContainer
          isMobile={isMobile}
          editorValue={productParamInput.htmlDetailContent}
          modifyValue={modifyValue}
          setModifyValue={setModifyValue}
        />
        <ScheduleContainer
          isMobile={isMobile}
          productParamInput={productParamInput}
          modifyValue={modifyValue}
          setModifyValue={setModifyValue}
        />
        <QuestionContainer
          isMobile={isMobile}
          inquiryList={inquiryList}
          modifyValue={modifyValue}
          setModifyValue={setModifyValue}
        />
        <AddressContainer
          isMobile={isMobile}
          productParamInput={productParamInput}
          modifyValue={modifyValue}
          setModifyValue={setModifyValue}
        />
        <AdditionalContainer
          isMobile={isMobile}
          productParamInput={productParamInput}
          modifyValue={modifyValue}
          setModifyValue={setModifyValue}
        />
        <ProfileContainer
          isMobile={isMobile}
          modifyValue={modifyValue}
          setModifyValue={setModifyValue}
        />
        <PrecautionContainer
          isMobile={isMobile}
          notice={productParamInput.notice}
          modifyValue={modifyValue}
          setModifyValue={setModifyValue}
        />
        <RefundContainer />
      </Column>
    );
  };

  const renderOptionContent = (): JSX.Element => {
    return (
      <Column gap="24px">
        <Column
          gap="16px"
          style={{ padding: isMobile ? '0 20px 40px ' : '0 20px 90px' }}
        >
          <OptionContainer
            isMobile={isMobile}
            productParamInput={productParamInput}
            modifyValue={modifyValue}
            setModifyValue={setModifyValue}
          />
          {productParamInput.frip.attachedToSchedule && (
            <CalendarContainer
              isMobile={isMobile}
              productParamInput={productParamInput}
              modifyValue={modifyValue}
              setModifyValue={setModifyValue}
            />
          )}
          <SaleTermContainer
            isMobile={isMobile}
            productParamInput={productParamInput}
            modifyValue={modifyValue}
            setModifyValue={setModifyValue}
          />
          {productParamInput.grossPurchaseLimitation !== null && (
            <OnceLimitContainer
              isMobile={isMobile}
              productParamInput={productParamInput}
              modifyValue={modifyValue}
              setModifyValue={setModifyValue}
            />
          )}
        </Column>
        {/* {productParamInput.frip.attachedToSchedule && (
          <Column style={{ background: '#fff' }}>
            <UpdateCalendar
              calendarList={productParamInput.frip?.schedules}
              isClearCalendarData={isClearCalendarData}
              checkedDate={checkedDate}
              setModifyStatus={setModifyStatus}
              handleIsOpenUpdateOptionSheet={handleIsOpenUpdateOptionSheet}
              onChange={handleSetAddSetCheckedDate}
            />
          </Column>
        )} */}
      </Column>
    );
  };

  const renderContents = (): JSX.Element => {
    if (isMobile) {
      return (
        <Column
          align="flex-start"
          gap="24px"
          style={{
            maxWidth: '100%',
            borderLeft: '1px solid #eaebec',
            borderRight: '1px solid #eaebec',
          }}
        >
          {renderProductHeader()}

          {renderMenuButton()}
          {productParamInput && (
            <Column gap="16px">
              {menuType === 'detail'
                ? renderDetailContent()
                : renderOptionContent()}
            </Column>
          )}
        </Column>
      );
    }

    return (
      <Column
        align="flex-start"
        style={{
          maxWidth: '375px',
          borderLeft: '1px solid #eaebec',
          borderRight: '1px solid #eaebec',
        }}
      >
        {renderProductHeader()}
        <Column
          justifyContent="flex-start"
          gap="24px"
          style={{
            paddingTop: '24px',
            paddingBottom: menuType === 'detail' ? '0' : '30px',
            background: '#F7F7F8',
          }}
        >
          {renderMenuButton()}
          {productParamInput && (
            <Column gap="16px">
              {menuType === 'detail'
                ? renderDetailContent()
                : renderOptionContent()}
            </Column>
          )}
        </Column>
      </Column>
    );
  };

  return renderContents();
};

export default ViewContainer;
