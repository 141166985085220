import { gql } from '@apollo/client';

export const LOCATION_FRAGMENT = gql`
  fragment locationField on Location {
    id
    name
    address
    geoPoint {
      latitude
      longitude
    }
    overseas
  }
`;

export const UPDATE_LOCATION = gql`
  mutation updateLocation($id: ID!, $param: LocationParamInput!) {
    updateLocation(id: $id, param: $param) {
      ...locationField
    }
  }

  ${LOCATION_FRAGMENT}
`;

export const CREATE_LOCATION = gql`
  mutation createLocation($hostId: String!, $param: LocationParamInput!) {
    createLocation(hostId: $hostId, param: $param) {
      ...locationField
    }
  }

  ${LOCATION_FRAGMENT}
`;

export const DELETE_LOCATION = gql`
  mutation deleteLocation($id: ID!) {
    deleteLocation(id: $id) {
      success
      message
    }
  }
`;

export const GET_LOCATION_PRESETS = gql`
  query locationPresets($hostId: String!, $filter: LocationFilterInput) {
    product {
      locationPresets(filter: $filter, hostId: $hostId) {
        id
        name
        address
        conveniences {
          id
          name
        }
        geoPoint {
          latitude
          longitude
        }
        overseas
        parking {
          available
          note
        }
        useByDefault
      }
    }
  }
`;
