import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import Divider from 'components/Divider';
import { ItemInquiryInputKind, ItemInquiryInputPattern } from 'types/api';
import OptionSelectBox from 'containers/ProductRegist/components/common/OptionSelectBox';
import OptionButton from 'containers/ProductRegist/components/common/OptionButton';
import { Inquiries } from 'containers/ProductRegist/components/InformationRegist/graphQL/types';

const TextareaForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 16px 12px;
  width: 100%;

  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;
`;

const Textarea = styled.textarea`
  width: 100%;
  color: var(--gray-gray-09, #1b1c1e);

  border: none;
  background: var(--Static-White, #fff);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.6px;
  resize: none;

  &::placeholder {
    color: #dbdcdf;
  }

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const InquiryForm = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 12px;
  width: 100%;

  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;
  gap: 12px;

  input {
    width: 100%;
    border: none;
    color: var(--gray-gray-04, #1b1c1e);

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    letter-spacing: -0.6px;

    &::placeholder {
      color: #dbdcdf;
    }
  }
`;

const AddInquiresButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  width: 100%;

  border-radius: 8px;
  border: 1px dashed var(--gray-gray-04, #dbdcdf);
  background: var(--gray-gray-01, #f7f7f8);

  color: #70737c;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
`;

interface QuestionModifyFormIProps {
  isMobile: boolean;
  inquiries: Inquiries;
  setInquiries: React.Dispatch<React.SetStateAction<Inquiries>>;
  handleSetInquiries: (key: string, value: any) => void;
}

const QuestionModifyModal: React.FC<QuestionModifyFormIProps> = props => {
  const { isMobile, inquiries, setInquiries, handleSetInquiries } = props;

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleAdjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    handleAdjustTextareaHeight();
  }, []);

  const renderInquiryForm = (): JSX.Element => {
    return (
      <React.Fragment>
        <Column gap="12px" align="flex-start">
          <Text color="#1b1c1e" size="16px">
            질문
          </Text>
          <TextareaForm>
            <Textarea
              placeholder="질문을 입력해 주세요"
              ref={textareaRef}
              value={inquiries.title}
              onChange={e => {
                handleSetInquiries('title', e.target.value);
              }}
              rows={1}
            />
            <Text color="#989BA2" size="14px" weight={400}>
              {inquiries.title.length}/200
            </Text>
          </TextareaForm>
        </Column>
        {inquiries.inputKind !== ItemInquiryInputKind.TEXT && (
          <React.Fragment>
            <Divider />
            <Column gap="8px">
              {inquiries.items.map((item, idx) => {
                return (
                  <InquiryForm key={idx}>
                    {/* <img src={ICON_MENU_BUTTON} alt="menu icon" /> */}
                    <input
                      placeholder="답변을 입력해 주세요"
                      value={item.label}
                      onChange={e => {
                        const updateItems = cloneDeep(inquiries.items);

                        updateItems[idx].label = e.target.value;

                        setInquiries({ ...inquiries, items: updateItems });
                      }}
                    />
                    {idx !== 0 && (
                      <Text
                        color="#1b1c1e"
                        weight={400}
                        style={{
                          minWidth: '25px',
                          textDecoration: 'underline',
                        }}
                        onClick={() => {
                          const filterItem = inquiries.items.filter(
                            (item, filterIdx) => filterIdx !== idx,
                          );

                          setInquiries({ ...inquiries, items: filterItem });
                        }}
                      >
                        삭제
                      </Text>
                    )}
                  </InquiryForm>
                );
              })}
            </Column>
            {(inquiries.inputKind === ItemInquiryInputKind.MULTI_CHOICE ||
              inquiries.inputKind === ItemInquiryInputKind.SINGLE_CHOICE) && (
              <AddInquiresButton
                onClick={() => {
                  setInquiries({
                    ...inquiries,
                    items: [...inquiries.items, { label: '' }],
                  });
                }}
              >
                답변 추가
              </AddInquiresButton>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  const renderQuestionPattern = (): JSX.Element => {
    return (
      <Column gap="12px" align="flex-start">
        <Text color="#1b1c1e" size="16px">
          답변 규칙
        </Text>
        <Row gap="8px" style={{ width: '100%' }}>
          <OptionSelectBox
            title="1개 이상 답변 받기"
            description="구매 옵션의 개수만큼 답변을 받을게요"
            isSelected={
              inquiries.inputPattern === ItemInquiryInputPattern.PER_ITEM_COUNT
            }
            onClick={() => {
              handleSetInquiries(
                'inputPattern',
                ItemInquiryInputPattern.PER_ITEM_COUNT,
              );
            }}
          />
          <OptionSelectBox
            title="1개만 답변 받기"
            description="구매 옵션의 개수와 상관 없이 1개의 답변을 받을게요"
            isSelected={
              inquiries.inputPattern === ItemInquiryInputPattern.PER_PRODUCT
            }
            onClick={() => {
              handleSetInquiries(
                'inputPattern',
                ItemInquiryInputPattern.PER_PRODUCT,
              );
            }}
          />
        </Row>
      </Column>
    );
  };

  const renderQuestionKind = (): JSX.Element => {
    const handleSetInputKind = (inputKind: ItemInquiryInputKind) => {
      if (inputKind === ItemInquiryInputKind.TEXT) {
        setInquiries({ ...inquiries, inputKind, items: [] });
      } else {
        setInquiries({ ...inquiries, inputKind, items: [{ label: '' }] });
      }
    };

    return (
      <Column gap="12px" align="flex-start">
        <Text color="#1b1c1e" size="16px">
          답변 유형
        </Text>
        <Row gap="8px" style={{ width: '100%' }}>
          <OptionButton
            text="주관식"
            isSelected={inquiries.inputKind === ItemInquiryInputKind.TEXT}
            onClick={() => {
              handleSetInputKind(ItemInquiryInputKind.TEXT);
            }}
          />
          <OptionButton
            text="객관식"
            isSelected={
              inquiries.inputKind === ItemInquiryInputKind.SINGLE_CHOICE
            }
            onClick={() => {
              handleSetInputKind(ItemInquiryInputKind.SINGLE_CHOICE);
            }}
          />
          <OptionButton
            text="객관식(다중)"
            isSelected={
              inquiries.inputKind === ItemInquiryInputKind.MULTI_CHOICE
            }
            onClick={() => {
              handleSetInputKind(ItemInquiryInputKind.MULTI_CHOICE);
            }}
          />
        </Row>
      </Column>
    );
  };

  const renderQuestionRequire = (): JSX.Element => {
    return (
      <Column gap="12px" align="flex-start">
        <Text color="#1b1c1e" size="16px">
          답변 필수 여부
        </Text>
        <Row gap="8px" style={{ width: '100%' }}>
          <OptionButton
            text="필수가 아니에요"
            isSelected={!inquiries.required}
            onClick={() => {
              handleSetInquiries('required', false);
            }}
          />
          <OptionButton
            text="필수로 받을게요"
            isSelected={inquiries.required}
            onClick={() => {
              handleSetInquiries('required', true);
            }}
          />
        </Row>
      </Column>
    );
  };

  const renderContents = (): JSX.Element => {
    if (isMobile) {
      return (
        <React.Fragment>
          <Row
            style={{
              position: 'sticky',
              top: 0,
              padding: '16px 20px',
              width: '100%',
              background: '#fff',
            }}
          >
            <Text color="#1b1c1e" size="16px" weight={600}>
              등록하기
            </Text>
          </Row>
          {inquiries && (
            <Column gap="24px" style={{ padding: '0 20px' }}>
              {renderQuestionKind()}
              <Divider />
              {renderInquiryForm()}
              {/* <Divider />
              {renderQuestionPattern()} */}
              <Divider />
              {renderQuestionRequire()}
            </Column>
          )}
        </React.Fragment>
      );
    }

    return (
      <Column>
        <Row
          style={{
            // position: 'sticky',
            // top: 0,
            padding: '32px 0 0',
            width: '100%',
            // background: '#fff',
          }}
        >
          <Text color="#1B1C1E" size="16px">
            수정하기
          </Text>
        </Row>
        <Column style={{ padding: '32px 0px' }}>
          {inquiries && (
            <Column gap="24px" style={{ padding: '0 20px' }}>
              {renderQuestionKind()}
              <Divider />
              {renderInquiryForm()}
              {/* <Divider />
              {renderQuestionPattern()} */}
              <Divider />
              {renderQuestionRequire()}
            </Column>
          )}
        </Column>
      </Column>
    );
  };

  return renderContents();
};

export default QuestionModifyModal;
