import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useQueryGetSettlementListByFilter } from '../graphQL/hooks/useQueryGetSettlementListByFilter';
import { SettlementsEdges } from '../graphQL/types';
import { useMutaionRequestInvoice } from '../graphQL/hooks/useMutationRequestInvoice';
import HackleManager from 'utils/hackle';
import { formatInvoiceStatus } from 'utils/hackle/parameterFormatting';

export const useSettlementListHook = () => {
  const location = useLocation();

  const { data, isLoading, refetchLoading, refetchSettlementItems } =
    useQueryGetSettlementListByFilter();
  const { handleRequestInvoice } = useMutaionRequestInvoice();

  const [pageIndex, setPageIndex] = useState<number>(0);
  const [settlementList, setSettlementList] = useState<SettlementsEdges[]>([]);
  const [settlementItem, setSettlementItem] = useState<SettlementsEdges>();

  const handleNextSettlementItem = () => {
    if (
      data &&
      data.settlement.settlements.edges.length !== 1 &&
      pageIndex < data.settlement.settlements.edges.length - 1
    ) {
      setPageIndex(pageIndex + 1);

      setSettlementItem(data.settlement.settlements.edges[pageIndex + 1]);
    }
  };

  const handlePrevSettlementItem = () => {
    if (data && pageIndex !== 0) {
      setPageIndex(pageIndex - 1);

      setSettlementItem(data.settlement.settlements.edges[pageIndex - 1]);
    }
  };

  const handleSelectSettlementItem = (index: number) => {
    setPageIndex(index);

    setSettlementItem(settlementList[index]);
  };

  const handleRequestInvoiceSettlement = (id: string) => {
    handleRequestInvoice(id, data => {
      const settlementResponse = data.requestInvoice.settlements[0];

      HackleManager.logSettlementListPayoutRequestComplete(
        settlementResponse.id,
        settlementResponse.title,
        Number(settlementResponse.totalFee.value) +
          Number(settlementResponse.totalPayment.value),
        Number(settlementResponse.totalFee.value),
        Number(settlementResponse.totalPayment.value),
      );

      refetchSettlementItems();

      setSettlementItem({
        ...settlementItem,
        node: {
          ...settlementItem!.node,
          ...settlementResponse,
        },
      });
    });
  };

  useEffect(() => {
    if (data) {
      const settlementData = data.settlement.settlements.edges;

      setSettlementList(settlementData);

      if (location.state) {
        setPageIndex(
          settlementData.findIndex(item => item.node.id === location.state.id),
        );

        setSettlementItem(
          settlementData.filter(item => item.node.id === location.state.id)[0],
        );
      } else {
        if (!refetchLoading) {
          setSettlementItem(settlementData[0]);
        }
      }
    }
  }, [data]);

  return {
    isLoading,
    pageIndex,
    settlementList,
    settlementItem,
    handleNextSettlementItem,
    handlePrevSettlementItem,
    handleSelectSettlementItem,
    handleRequestInvoiceSettlement,
  };
};
