import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  Items,
  PlanParam,
  ProductParamInput,
} from 'containers/ProductRegist/hooks/types';

import CustomCalendar from 'components/Calendar/Calendar';
import Column from 'components/common/Column';
import Text from 'components/common/Text';
import Row from 'components/common/Row';
import OptionBottomSheet from './components/OptionBottomSheet';
import SelectDateModal from './components/SelectDateModal';
import { ICON_PRODUCT_CHECKMARK_BLACK } from 'assets/icons';
import { OptionSheetType, useCalendarHooks } from './hooks/useCalenderHooks';
import HackleManager from 'utils/hackle';
import dayjs from 'dayjs';

const SelectDateButton = styled.button`
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 8px 12px;

  border-radius: 99px;
  border: 1px solid var(--gray-gray-09, #dbdcdf);
  background: var(--Static-White, #fff);
`;

interface ChapterFourIProps {
  isMobile: boolean;
  height: number;
  productParamInput: ProductParamInput;
  items: Items[];
  handleOnChangeProductParamInput: (key: string, value: any) => void;
}

const ChapterFour: React.FC<ChapterFourIProps> = props => {
  const {
    isMobile,
    height,
    productParamInput,
    items,
    handleOnChangeProductParamInput,
  } = props;

  const {
    scheduleItem,
    optionSheetType,
    checkedDate,
    isOpenOptionBottomSheet,
    isClearCalendarData,
    isOpenSelectDateModal,
    setScheduleItem,
    setCheckedDate,
    setIsOpenSelectDateModal,
    setIsOpenOptionBottomSheet,
    handleSetAddSetCheckedDate,
    handleChangeScheduleItem,
    handleClearCalenderData,
    handleIsOpenUpdateOptionSheet,
    handleInitScheduleItem,
    handleGetSpecificDaysInRange,
  } = useCalendarHooks(productParamInput, handleOnChangeProductParamInput);

  const params = useParams<{ id: string }>();

  const handleSetPlanParam = (planParam: PlanParam) => {
    // handleOnChangeProductParamInput('frip', {
    //   ...productParamInput.frip,
    //   plans: [...productParamInput.frip!.plans, planParam],
    // });
  };

  useEffect(() => {
    HackleManager.logCreateStepTwoScheduleSettingView(
      params.id !== 'draft' ? Number(params.id) : undefined,
    );
  }, []);

  const renderScheduleForm = (): JSX.Element => {
    return (
      <React.Fragment>
        {checkedDate.length > 0 &&
          optionSheetType === OptionSheetType.CREATE && (
            <Column
              gap="32px"
              align="flex-start"
              justifyContent="flex-start"
              style={{
                paddingBottom: '32px',
                maxHeight: `${height - 180}px`,
                overflowY: 'scroll',
              }}
            >
              <OptionBottomSheet
                productParamInput={productParamInput}
                scheduleItem={scheduleItem}
                checkedDate={checkedDate}
                isMobile={isMobile}
                items={items}
                optionSheetType={optionSheetType}
                setScheduleItem={setScheduleItem}
                setCheckedDate={setCheckedDate}
                handleOnChangeProductParamInput={
                  handleOnChangeProductParamInput
                }
                handleClearCalenderData={handleClearCalenderData}
                handleChangeScheduleItem={handleChangeScheduleItem}
                handleInitScheduleItem={handleInitScheduleItem}
              />
            </Column>
          )}
        {isOpenOptionBottomSheet &&
          optionSheetType === OptionSheetType.UPDATE && (
            <Column
              gap="32px"
              align="flex-start"
              justifyContent="flex-start"
              style={{
                paddingBottom: '32px',
                maxHeight: '80vh',
                overflowY: 'scroll',
              }}
            >
              <OptionBottomSheet
                productParamInput={productParamInput}
                scheduleItem={scheduleItem}
                checkedDate={checkedDate}
                isMobile={isMobile}
                items={items}
                optionSheetType={optionSheetType}
                setScheduleItem={setScheduleItem}
                setCheckedDate={setCheckedDate}
                handleOnChangeProductParamInput={
                  handleOnChangeProductParamInput
                }
                handleClearCalenderData={handleClearCalenderData}
                handleChangeScheduleItem={handleChangeScheduleItem}
                handleInitScheduleItem={handleInitScheduleItem}
                handleOnCloseOptionModal={() =>
                  setIsOpenOptionBottomSheet(false)
                }
              />
            </Column>
          )}
        {isOpenSelectDateModal && (
          <SelectDateModal
            isMobile={isMobile}
            onClose={() => setIsOpenSelectDateModal(false)}
            handleGetSpecificDaysInRange={handleGetSpecificDaysInRange}
            handleSetPlanParam={handleSetPlanParam}
          />
        )}
      </React.Fragment>
    );
  };

  const renderSelectDateForm = (): JSX.Element => {
    return (
      <Row gap="12px">
        <SelectDateButton
          onClick={() => {
            if (checkedDate.length > 0) {
              setCheckedDate([]);
            }

            setIsOpenSelectDateModal(true);
          }}
        >
          <img
            src={ICON_PRODUCT_CHECKMARK_BLACK}
            alt="icon product checkmark"
          />
          <Text color="#1b1c1e" size="14px">
            날짜 일괄 선택
          </Text>
        </SelectDateButton>
        <SelectDateButton
          onClick={() => {
            if (checkedDate.length > 0) {
              setCheckedDate([]);
            }
          }}
        >
          <Text
            color={checkedDate.length > 0 ? '#1b1c1e' : '#dbdcdf'}
            size="14px"
          >
            선택 취소
          </Text>
        </SelectDateButton>
      </Row>
    );
  };

  const renderContents = (): JSX.Element => {
    if (isMobile) {
      return (
        <React.Fragment>
          <Column gap="32px" align="flex-start">
            <Column gap="24px" align="flex-start">
              <Text color="#1b1c1e" size="24px">
                일정을 추가해 주세요
              </Text>
              {renderSelectDateForm()}
            </Column>
            <CustomCalendar
              calendarList={productParamInput.frip?.schedules}
              isClearCalendarData={isClearCalendarData}
              checkedDate={checkedDate}
              setCheckedDate={setCheckedDate}
              handleInitScheduleItem={handleInitScheduleItem}
              handleIsOpenUpdateOptionSheet={handleIsOpenUpdateOptionSheet}
              onChange={handleSetAddSetCheckedDate}
            />
          </Column>
          {renderScheduleForm()}
        </React.Fragment>
      );
    }

    return (
      <Row
        align="flex-start"
        style={{
          width: '100%',
          minWidth:
            isOpenOptionBottomSheet || checkedDate.length > 0
              ? '825px'
              : '100%',
          overflowX: 'scroll',
        }}
      >
        <Column
          gap="32px"
          align="flex-start"
          justifyContent="flex-start"
          style={{
            minWidth:
              isOpenOptionBottomSheet || checkedDate.length > 0
                ? '450px'
                : '100%',
            maxHeight: `${height - 180}px`,
            overflowY: 'scroll',
          }}
        >
          <Row
            justifyContent="space-between"
            style={{
              position: 'sticky',
              top: 0,
              padding: '32px 20px',
              width: '100%',
              background: '#fff',
              zIndex: 3,
            }}
          >
            <Text color="#1b1c1e" size="24px">
              일정을 추가해 주세요
            </Text>
            {renderSelectDateForm()}
          </Row>
          <CustomCalendar
            calendarList={productParamInput.frip?.schedules}
            isClearCalendarData={isClearCalendarData}
            checkedDate={checkedDate}
            setCheckedDate={setCheckedDate}
            handleInitScheduleItem={handleInitScheduleItem}
            handleIsOpenUpdateOptionSheet={handleIsOpenUpdateOptionSheet}
            onChange={handleSetAddSetCheckedDate}
          />
        </Column>
        {renderScheduleForm()}
      </Row>
    );
  };

  return renderContents();
};

export default ChapterFour;
