// import { PageInfo, ProductState } from 'types/api';
// export interface ProductQna {
//   id: number;
//   product: Product;
//   question: string;
//   user: User;
//   answer?: ProductAnswer | null;
//   createdAt: string;
//   secret: boolean;
//   status: ProductQnaState;
// }

// export interface User {
//   id: number;
//   nickname: string;
// }

// export interface Product {
//   id: number;
//   title: string;
//   host: Host;
//   status: ProductState;
// }

// export interface ProductAnswer {
//   host?: Host | null;
//   answer: string;
//   createdAt: string;
//   updatedAt?: string;
// }

// export interface Host {
//   id: number;
//   nickname: string;
// }

// export interface ProductQnaConnection {
//   edges: ProductQnaEdge[];
//   totalCount: number;
//   pageInfo: PageInfo;
// }

// export interface ProductQnaEdge {
//   node: ProductQna;
//   cursor: string;
// }

// export interface ProductAnswerParam {
//   id?: number;
//   answer: string;
// }

export enum ProductQanSearchTimeOptions {
  QUESTION_TIME = 'QUESTION_TIME',
  ANSWER_TIME = 'ANSWER_TIME',
}

export enum ProductQnaSearchKindOptions {
  PRODUCT = 'PRODUCT',
  QUESTION = 'QUESTION',
  USER = 'USER',
}

export enum ProductQnaState {
  NEW = 'NEW',
  COMPLETE = 'COMPLETE',
  CONFIRM = 'CONFIRM',
  BLIND = 'BLIND',
  DELETED = 'DELETED',
}

// export interface ProductQnaHistory {
//   content: string;
//   createdAt: Date;
//   type: ProductQnaHistoryType;
// }

// export enum ProductQnaHistoryType {
//   QUESTION = 'QUESTION',
//   ANSWER = 'ANSWER',
// }

export interface ProductQnaFilter {
  hostId?: string;
  userId?: string;
  productId?: string;
  hostIds?: string[];
  userIds?: string[];
  productIds?: string[];
  content?: string;
  isAnswering?: boolean;
  statusIn?: ProductQnaState[];
  term?: ProductQnaTermInput;
}

export interface ProductQnaTermInput {
  type?: ProductQanSearchTimeOptions;
  endedAt?: number;
  startedAt?: number;
}
