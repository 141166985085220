import { useLazyQuery } from '@apollo/client';
import { errorHandler } from 'utils/ErrorHandler';
import { GET_PRODUCT } from '..';

export function useQueryProduct() {
  const [getProduct, { data, refetch }] = useLazyQuery(GET_PRODUCT);

  const handleGetProduct = async (productId: string) => {
    if (productId === 'draft') {
      return;
    }

    try {
      await getProduct({ variables: { id: productId } });
    } catch (error) {
      console.log(error, 'product error');
    }
  };

  const handleRefetchProduct = async (productId: string) => {
    try {
      await refetch({ id: productId });
    } catch (error) {
      errorHandler(error);
    }
  };

  return { data, handleGetProduct, handleRefetchProduct };
}
