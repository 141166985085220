import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { SETTLEMENT_PATH } from 'constants/path';
import { SAVE_HOST_BANK_ACCOUNT } from '..';
import { BankAccountParamInput } from '../type';

export function useMutationSaveHostBankAccount() {
  const [saveHostBankAccount] = useMutation(SAVE_HOST_BANK_ACCOUNT);

  const navigate = useNavigate();

  const handleSaveHostBankAccount = async (
    hostId: string,
    param: BankAccountParamInput
  ) => {
    try {
      const response = await saveHostBankAccount({
        variables: {
          hostId,
          param,
        },
      });

      if (response) {
        navigate(SETTLEMENT_PATH);
        location.reload();
      }
    } catch (error) {
      console.log(error, 'save host bank account error');
    }
  };

  return {
    handleSaveHostBankAccount,
  };
}
