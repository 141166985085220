import React from 'react';
import styled, { css } from 'styled-components';
import { useQueryGetHostDashBoard } from 'containers/Menu/graphQL/hooks/useQueryGetHostDashBoard';
import Column from 'components/common/Column';
import Text from 'components/common/Text';
import Row from 'components/common/Row';

const Container = styled.div<{ $isMobile: boolean }>`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  background: #fff;

  ${({ $isMobile }) =>
    $isMobile
      ? css`
          padding: 20px;
          border-radius: 12px;
          border: 1px solid #dbdcdf;
        `
      : css`
          padding: 20px;
          border-left: 1px solid #eaebec;
          border-right: 1px solid #eaebec;
          border-bottom: 1px solid #eaebec;
        `}
`;

interface SaleDashBoardIProps {
  isMobile: boolean;
}

const SaleDashBoard: React.FC<SaleDashBoardIProps> = props => {
  const { isMobile } = props;

  const { hostDashBoard } = useQueryGetHostDashBoard();

  const renderContents = (): JSX.Element => {
    if (isMobile) {
      return (
        <Container $isMobile={isMobile}>
          <Column gap="12px" align="flex-start">
            <Column gap="6px" align="flex-start">
              <Text color="#1b1c1e" size="13px">
                이번 달 판매금액
              </Text>
              <Text color="#1b1c1e" size="20px" weight={600}>
                {hostDashBoard.hostDashboard.hostDashboard.monthlyTurnover
                  ? Number(
                      hostDashBoard.hostDashboard.hostDashboard.monthlyTurnover,
                    ).toLocaleString()
                  : 0}
                원
              </Text>
            </Column>
            <Column gap="4px" align="flex-start">
              <Text color="#46474C" size="11px" weight={400}>
                누적 판매금액
              </Text>
              <Text color="#46474C" size="12px">
                {hostDashBoard.hostDashboard.hostDashboard.totalTurnover
                  ? Number(
                      hostDashBoard.hostDashboard.hostDashboard.totalTurnover,
                    ).toLocaleString()
                  : 0}
                원
              </Text>
            </Column>
          </Column>
          <Column gap="12px" align="flex-start">
            <Column gap="6px" align="flex-start">
              <Text color="#1b1c1e" size="13px">
                이번 달 판매
              </Text>
              <Text color="#1b1c1e" size="20px" weight={600}>
                {hostDashBoard.hostDashboard.hostDashboard.monthlySaleCount
                  ? hostDashBoard.hostDashboard.hostDashboard.monthlySaleCount.toLocaleString()
                  : 0}
                건
              </Text>
            </Column>
            <Column gap="4px" align="flex-start">
              <Row gap="4px">
                <Text color="#FF4242" size="11px" weight={400}>
                  이번 달 취소
                </Text>
                <Text color="#FF4242" size="12px">
                  {hostDashBoard.hostDashboard.hostDashboard.monthlyCancel
                    ? hostDashBoard.hostDashboard.hostDashboard.monthlyCancel.toLocaleString()
                    : 0}
                  건
                </Text>
              </Row>
              <Row gap="4px">
                <Text color="#46474C" size="11px" weight={400}>
                  누적 판매
                </Text>
                <Text color="#46474C" size="12px">
                  {hostDashBoard.hostDashboard.hostDashboard.totalSaleCount
                    ? hostDashBoard.hostDashboard.hostDashboard.totalSaleCount.toLocaleString()
                    : 0}
                  건
                </Text>
              </Row>
            </Column>
          </Column>
        </Container>
      );
    }

    return (
      <Container $isMobile={isMobile}>
        <Row
          gap="32px"
          justifyContent="flex-start"
          align="flex-start"
          style={{ width: '100%' }}
        >
          <Column gap="6px" align="flex-start" style={{ width: 'auto' }}>
            <Text color="#1b1c1e" size="13px">
              이번 달 판매금액
            </Text>
            <Text color="#1b1c1e" size="20px" weight={600}>
              {hostDashBoard.hostDashboard.hostDashboard.monthlyTurnover
                ? Number(
                    hostDashBoard.hostDashboard.hostDashboard.monthlyTurnover,
                  ).toLocaleString()
                : 0}
              원
            </Text>
          </Column>
          <Column gap="4px" align="flex-start" style={{ width: 'auto' }}>
            <Text color="#46474C" size="11px" weight={400}>
              누적 판매금액
            </Text>
            <Text color="#46474C" size="12px">
              {hostDashBoard.hostDashboard.hostDashboard.totalTurnover
                ? Number(
                    hostDashBoard.hostDashboard.hostDashboard.totalTurnover,
                  ).toLocaleString()
                : 0}
              원
            </Text>
          </Column>
        </Row>
        <Row
          gap="32px"
          justifyContent="flex-start"
          align="flex-start"
          style={{ width: '100%' }}
        >
          <Column gap="6px" align="flex-start" style={{ width: 'auto' }}>
            <Text color="#1b1c1e" size="13px">
              이번 달 판매
            </Text>
            <Text color="#1b1c1e" size="20px" weight={600}>
              {hostDashBoard.hostDashboard.hostDashboard.monthlySaleCount
                ? hostDashBoard.hostDashboard.hostDashboard.monthlySaleCount.toLocaleString()
                : 0}
              건
            </Text>
          </Column>
          <Column gap="4px" align="flex-start" style={{ width: 'auto' }}>
            <Row gap="4px">
              <Text color="#FF4242" size="11px" weight={400}>
                이번 달 취소
              </Text>
              <Text color="#FF4242" size="12px">
                {hostDashBoard.hostDashboard.hostDashboard.monthlyCancel
                  ? hostDashBoard.hostDashboard.hostDashboard.monthlyCancel.toLocaleString()
                  : 0}
                건
              </Text>
            </Row>
            <Row gap="4px">
              <Text color="#46474C" size="11px" weight={400}>
                누적 판매
              </Text>
              <Text color="#46474C" size="12px">
                {hostDashBoard.hostDashboard.hostDashboard.totalSaleCount
                  ? hostDashBoard.hostDashboard.hostDashboard.totalSaleCount.toLocaleString()
                  : 0}
                건
              </Text>
            </Row>
          </Column>
        </Row>
      </Container>
    );
  };

  return hostDashBoard && renderContents();
};

export default SaleDashBoard;
