import React, { useCallback, useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { isEmpty } from 'lodash';
import { Map, MapMarker } from 'react-kakao-maps-sdk';

import Row from 'components/common/Row';
import Column from 'components/common/Column';
import Text from 'components/common/Text';

import { ICON_ADDRESS, ICON_ADDRESS_BLACK, ICON_QUESTION } from 'assets/icons';
import AddressModal from './AddressModal';
import {
  FripLocationParam,
  ProductParamInput,
} from 'containers/ProductRegist/hooks/types';
import { FripLocation, ProductKind } from 'types/api';
import DashButton from 'components/Button/DashButton';
import OnlineSelectForm from './components/OnlineSelectForm';
import ModalContainer from '../common/ModalContainer';
import GoogleMap from './components/GoogleMap';
import { useParams } from 'react-router-dom';
import HackleManager from 'utils/hackle';

const AddressSelectBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 12px;
  width: 100%;

  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;
  cursor: pointer;
  color: #989ba2;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.6px;
`;

interface ChaterFourIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
}

const ChapterFour: React.FC<ChaterFourIProps> = props => {
  const { isMobile, productParamInput, handleOnChangeProductParamInput } =
    props;

  const params = useParams<{ id: string }>();

  const [isOpenAddressModal, setIsOpenAddressModal] = useState<boolean>(false);
  const [isOpenMeetingPlaceModal, setIsOpenMeetingPlaceModal] =
    useState<boolean>(false);

  const [locationVenueItems, setLocationVenueItems] =
    useState<FripLocationParam | null>(null);

  const handleResetLocationVenueItems = () => {
    setLocationVenueItems(null);
  };

  useEffect(() => {
    if (productParamInput.kind === ProductKind.ONLINE) {
      HackleManager.logCreateStepOneOnlineMethodView(
        params.id !== 'draft' ? Number(params.id) : undefined,
      );

      return;
    }

    if (productParamInput.kind === ProductKind.OFFLINE) {
      HackleManager.logCreateStepOneOfflineMethodView(
        params.id !== 'draft' ? Number(params.id) : undefined,
      );

      return;
    }

    if (productParamInput.kind === ProductKind.GOODS) {
      HackleManager.logCreateStepOneShippingTypeView(
        params.id !== 'draft' ? Number(params.id) : undefined,
      );

      return;
    }
  }, []);

  const renderContent = (): JSX.Element => {
    if (productParamInput.kind === ProductKind.ONLINE) {
      return (
        <OnlineSelectForm
          isMobile={true}
          productParamInput={productParamInput}
          handleOnChangeProductParamInput={handleOnChangeProductParamInput}
        />
      );
    }

    return (
      <React.Fragment>
        <Text size="24px" color="#1b1c1e" style={{ lineHeight: '32px' }}>
          오프라인 장소는 어디인가요?
        </Text>
        {renderLocationVenue()}
        {renderLocationGathering()}
      </React.Fragment>
    );
  };

  const renderLocationVenue = (): JSX.Element => {
    return (
      <Column gap="12px" align="flex-start">
        <Text color="#1b1c1e" size="16px">
          진행 장소 <span style={{ color: '#0071e9' }}>(필수)</span>
        </Text>
        {isEmpty(productParamInput.frip?.locationsOfVenue) ? (
          <AddressSelectBox
            onClick={() => {
              HackleManager.logCreateStepOneAddLocationView(
                params.id !== 'draft' ? Number(params.id) : undefined,
              );

              setIsOpenAddressModal(true);
              setLocationVenueItems(null);
            }}
          >
            <img src={ICON_ADDRESS} alt="address icon" />
            <span>진행 장소를 추가해주세요</span>
          </AddressSelectBox>
        ) : (
          <React.Fragment>
            {productParamInput.frip?.locationsOfVenue.map((item, idx) => {
              return (
                <Column gap="12px" key={idx}>
                  <AddressSelectBox>
                    <img src={ICON_ADDRESS_BLACK} alt="address icon" />
                    <Row
                      gap="8px"
                      justifyContent="space-between"
                      style={{ width: '100%' }}
                    >
                      <Text color="#1b1c1e" size="16px" weight={400}>
                        {item.address} {item.label}
                      </Text>
                      <Row gap="8px">
                        <Text
                          color="#FF4242"
                          size="12px"
                          style={{ minWidth: '22px' }}
                          onClick={() => {
                            handleOnChangeProductParamInput('frip', {
                              ...productParamInput.frip,
                              locationsOfVenue:
                                productParamInput.frip.locationsOfVenue.filter(
                                  venueItem => venueItem.id !== item.id,
                                ),
                            });
                          }}
                        >
                          삭제
                        </Text>
                        <Text color="#70737c" size="12px">
                          |
                        </Text>
                        <Text
                          color="#70737c"
                          size="12px"
                          style={{ minWidth: '42px' }}
                          onClick={() => {
                            setLocationVenueItems(item);
                            setIsOpenAddressModal(true);
                          }}
                        >
                          수정하기
                        </Text>
                      </Row>
                    </Row>
                  </AddressSelectBox>
                  {item.overseas ? (
                    <GoogleMap item={item} />
                  ) : (
                    <Map
                      zoomable={false}
                      center={{
                        lat: item.geoPoint!.latitude,
                        lng: item.geoPoint!.longitude,
                      }}
                      style={{
                        width: '100%',
                        height: '185px',
                        borderRadius: '8px',
                      }}
                    >
                      <MapMarker
                        position={{
                          lat: item.geoPoint!.latitude,
                          lng: item.geoPoint!.longitude,
                        }}
                      />
                    </Map>
                  )}
                </Column>
              );
            })}
            <DashButton
              text="진행 장소 추가"
              onClick={() => {
                setLocationVenueItems(null);
                setIsOpenAddressModal(true);
              }}
            />
          </React.Fragment>
        )}
      </Column>
    );
  };

  const renderLocationGathering = (): JSX.Element => {
    return (
      <Column gap="12px" align="flex-start">
        <Text color="#0f0f0f" size="16px">
          모이는 장소 (선택사항)
        </Text>
        {productParamInput.frip?.locationOfGathering === null ? (
          <Column gap="8px" align="flex-start">
            <AddressSelectBox
              onClick={() => {
                setIsOpenMeetingPlaceModal(true);
                setLocationVenueItems(null);
              }}
            >
              <img src={ICON_ADDRESS} alt="address icon" />
              <span>모이는 장소를 추가해주세요</span>
            </AddressSelectBox>
            <Text color="#70737c" size="12px" weight={400}>
              진행 장소와 모이는 장소가 다를 시 추가해 주세요.
            </Text>
          </Column>
        ) : (
          <Column gap="12px">
            <AddressSelectBox>
              <img src={ICON_ADDRESS_BLACK} alt="address icon" />
              <Row
                gap="8px"
                justifyContent="space-between"
                style={{ width: '100%' }}
              >
                <Text color="#1b1c1e" size="16px" weight={400}>
                  {productParamInput.frip?.locationOfGathering!.address}{' '}
                  {productParamInput.frip.locationOfGathering.label}
                </Text>
                <Row gap="8px">
                  <Text
                    color="#FF4242"
                    size="12px"
                    style={{ minWidth: '22px' }}
                    onClick={() => {
                      handleOnChangeProductParamInput('frip', {
                        ...productParamInput.frip,
                        locationOfGathering: null,
                      });
                    }}
                  >
                    삭제
                  </Text>
                  <Text color="#70737c" size="12px">
                    |
                  </Text>
                  <Text
                    color="#70737c"
                    size="12px"
                    style={{ minWidth: '42px' }}
                    onClick={() => {
                      setIsOpenMeetingPlaceModal(true);
                      setLocationVenueItems(
                        productParamInput.frip!.locationOfGathering!,
                      );
                    }}
                  >
                    수정하기
                  </Text>
                </Row>
              </Row>
            </AddressSelectBox>
            {productParamInput.frip!.locationOfGathering!.overseas ? (
              <GoogleMap item={productParamInput.frip.locationOfGathering} />
            ) : (
              <Map
                center={{
                  lat: productParamInput.frip!.locationOfGathering!.geoPoint!
                    .latitude,
                  lng: productParamInput.frip!.locationOfGathering!.geoPoint!
                    .longitude,
                }}
                zoomable={false}
                style={{
                  width: '100%',
                  height: '185px',
                  borderRadius: '8px',
                }}
              >
                <MapMarker
                  position={{
                    lat: productParamInput.frip!.locationOfGathering!.geoPoint!
                      .latitude,
                    lng: productParamInput.frip!.locationOfGathering!.geoPoint!
                      .longitude,
                  }}
                />
              </Map>
            )}
          </Column>
        )}
      </Column>
    );
  };

  const renderResponsivePage = (): JSX.Element => {
    if (isMobile) {
      return <React.Fragment>{renderContent()}</React.Fragment>;
    }

    return (
      <Row gap="64px" style={{ padding: '32px 0', width: '100%' }}>
        <Column gap="32px" align="flex-start">
          {renderContent()}
        </Column>
      </Row>
    );
  };

  return (
    <React.Fragment>
      {renderResponsivePage()}
      {isOpenAddressModal && (
        <ModalContainer
          isAbled={true}
          isMobile={isMobile}
          onCloseText=" "
          isBottomSheet={true}
          onClose={() => {}}
          onClick={() => {}}
        >
          <AddressModal
            isMobile={isMobile}
            productParamInput={productParamInput}
            locationVenueItems={locationVenueItems}
            type="address"
            handleOnChangeProductParamInput={handleOnChangeProductParamInput}
            handleResetLocationVenueItems={handleResetLocationVenueItems}
            onClose={() => {
              setIsOpenAddressModal(false);
            }}
          />
        </ModalContainer>
      )}
      {isOpenMeetingPlaceModal && (
        <ModalContainer
          isAbled={true}
          isMobile={isMobile}
          onCloseText=" "
          isBottomSheet={true}
          onClose={() => {}}
          onClick={() => {}}
        >
          <AddressModal
            isMobile={isMobile}
            productParamInput={productParamInput}
            locationVenueItems={locationVenueItems}
            type="meeting"
            handleOnChangeProductParamInput={handleOnChangeProductParamInput}
            handleResetLocationVenueItems={handleResetLocationVenueItems}
            onClose={() => setIsOpenMeetingPlaceModal(false)}
          />
        </ModalContainer>
      )}
    </React.Fragment>
  );
};

export default ChapterFour;
