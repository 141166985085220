import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useSignUpHooks } from './hooks/useSignupHooks';

import SignupEmail from './components/SignupEmail';
import SignupPassword from './components/SignupPassword';

import AuthButton from 'components/Button/AuthButton';
import BackButton from 'components/Button/BackButton';
import Column from 'components/common/Column';
import Text from 'components/common/Text';
import SignupTerm from './components/SignupTerm';
import SignupCertification from './components/SignupCertification';
import SignupSuccessModal from './components/SignupSuccessModal';
import { LOGIN_PATH, PROFILE_PATH } from 'constants/path';
import SignFailModal from './components/SignFailModal';
import { useGetMe } from 'graphQL/Auth/user/useGetMe';
import HackleManager from 'utils/hackle';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  width: 100%;
  max-width: 335px;
  min-height: 100vh;
  gap: 40px;
`;

const SignupPage = () => {
  const navigate = useNavigate();
  const {
    signupParam,
    isMobileCertification,
    isExistEmail,
    isOpenSuccessModal,
    isOpenFailModal,
    setIsOpenSuccessModal,
    setIsOpenFailModal,
    handleCheckValidationEmail,
    handleChangeSignupParam,
    handelCheckedMarketing,
    handleMobileCertification,
    handleEmailSignup,
  } = useSignUpHooks();

  const { handleRefetchUser } = useGetMe();

  const [isTermChecked, setIsTermChecked] = useState<boolean>(false);

  const handleTermCheckedCallback = (termChecked: boolean) => {
    setIsTermChecked(termChecked);
  };

  useEffect(() => {
    HackleManager.logSignupView();
  }, []);

  return (
    <Container>
      <Column gap="32px">
        <Column gap="8px" align="flex-start">
          <Text color="#1b1c1e" size="20px" weight={700}>
            이메일 회원가입
          </Text>
          <Text
            color="#70737c"
            size="14"
            weight={400}
            style={{ lineHeight: '20px' }}
          >
            호스트로 가입하시면 프립 서비스 계정이 자동으로 함께 생성되며, 프립
            앱에 로그인하여 바로 이용하실 수 있습니다.
          </Text>
        </Column>
        <Column gap="24px">
          <SignupEmail
            signupParam={signupParam}
            isExistEmail={isExistEmail}
            handleCheckValidationEmail={handleCheckValidationEmail}
            handleChangeSignupParam={handleChangeSignupParam}
          />
          <SignupPassword
            signupParam={signupParam}
            handleChangeSignupParam={handleChangeSignupParam}
          />
          <SignupCertification
            mobileNumber={signupParam.mobileNumber}
            isMobileCertification={isMobileCertification}
            handleChangeSignupParam={handleChangeSignupParam}
            handleMobileCertification={handleMobileCertification}
          />
          <SignupTerm
            marketingChecked={signupParam.agreement.marketing}
            handleChangeMarketing={handelCheckedMarketing}
            callback={handleTermCheckedCallback}
          />
        </Column>
      </Column>
      <Column gap="12px">
        <AuthButton
          name="가입하기"
          isAbled={!isExistEmail && isMobileCertification && isTermChecked}
          onClick={() => {
            handleEmailSignup();
          }}
        />
        <BackButton
          name="돌아가기"
          onClick={() => {
            navigate(LOGIN_PATH);
          }}
        />
      </Column>
      {isOpenSuccessModal && (
        <SignupSuccessModal
          onCloseNavigate={() => {
            // handleGetUserInformation();
            // navigate(PRODUCT_LIST_PATH);
          }}
          onStartNavigate={() => {
            handleRefetchUser(() => {
              navigate(`${PROFILE_PATH}`);
            });
          }}
        />
      )}
      {isOpenFailModal && (
        <SignFailModal
          content={
            <Column
              gap="8px"
              align="flex-start"
              style={{
                padding: '24px 20px',
                borderTop: '1px solid #EAEBEC',
                borderBottom: '1px solid #EAEBEC',
              }}
            >
              <Text color="#1b1c1e" size="20px" weight={600}>
                계정에 문제가 있어요
              </Text>
              <Text color="#1b1c1e" size="16px" weight={400}>
                계정에 문제가 있을 수 있어요. 고객센터에 문의해 주세요.
              </Text>
            </Column>
          }
          onClose={() => {
            setIsOpenFailModal(false);
          }}
        />
      )}
    </Container>
  );
};

export default SignupPage;
