import React, { useEffect, useState } from 'react';
import { styled, css } from 'styled-components';

import Row from 'components/common/Row';
import { ICON_AUTH_CLOSE_BUTTON } from 'assets/icons';

const Container = styled.div<{
  $focused: boolean;
  $styleType: string;
  $vaildCheck: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  width: 100%;

  border: ${props =>
    !props.$vaildCheck
      ? '1px solid #D63A16 !important'
      : props.$focused
      ? '2px solid #1B1C1E !important'
      : props.$styleType === 'large'
      ? '1px solid #C2C4C8'
      : 'none'};
  border-bottom: ${props =>
    props.$styleType !== 'bottom' && !props.$focused
      ? '1px solid #C2C4C8'
      : !props.$vaildCheck
      ? '1px solid #D63A16'
      : props.$focused && '2px solid #1B1C1E'};
  background-color: ${props => (props.$vaildCheck ? '#fff' : '#FDF4F2')};
  border-radius: ${props =>
    props.$styleType === 'top'
      ? '8px 8px 0 0'
      : props.$styleType === 'bottom'
      ? '0 0 8px 8px'
      : props.$styleType === 'large'
      ? '8px'
      : props.$focused && props.$styleType === 'top'
      ? '8px 8px 0 0'
      : props.$focused && props.$styleType === 'bottom'
      ? '0 0 8px 8px'
      : props.$focused && props.$styleType === 'large'
      ? '0'
      : props.$focused && props.$styleType === 'center'
      ? '0'
      : props.$focused
      ? '8px'
      : '0'};
`;

const Label = styled.div<{ $focused: boolean }>`
  display: flex;
  align-items: center;
  gap: 3px;

  top: ${props => props.$focused && '10px'};

  font-family: Pretendard;
  font-size: ${props => (props.$focused ? '12px' : '16px')};
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  z-index: 6;

  ${({ $focused }) =>
    $focused
      ? css`
          position: static;
        `
      : css`
          position: absolute;
          left: 12px;
        `}
`;

const Input = styled.input`
  width: 100%;
  height: 22px;

  color: #1b1c1e;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  caret-color: #c2c4c8;
  line-height: 150%;
  z-index: 7;
  border: none;
  background: none;
  -webkit-appearance: none;

  &::placeholder {
    color: #c2c4c8;
  }
`;

const ClearIcon = styled.img`
  display: flex;
  align-items: center;
  position: absolute;
  right: 12px;
  width: 20px;
  z-index: 8;
  cursor: pointer;
`;

interface InputFormIProps {
  type: string;
  styleType: string;
  label: string;
  target: string;
  isDisabled?: boolean;
  validation?: boolean;
  regExp?: RegExp;
  autoComplete?: boolean;
  passwordValue?: string;
  inputValue?: string;
  onChange: (key: string, value: string) => void;
  handleCheckIsFocusedInput?: (isFocused: boolean) => void;
  onKeyPress?: (e: any) => void;
}

const InputForm: React.FC<InputFormIProps> = props => {
  const {
    type,
    styleType,
    label,
    target,
    isDisabled,
    validation,
    regExp,
    autoComplete,
    passwordValue,
    inputValue,
    onChange,
    handleCheckIsFocusedInput,
    onKeyPress,
  } = props;

  const [value, setValue] = useState<string>(inputValue ? inputValue : '');
  const [isFocused, setIsFocused] = useState(false);
  const [isVaildCheck, setIsValidCheck] = useState<boolean>(true);

  const handleChange = (e: any) => {
    e.preventDefault();

    setValue(e.target.value);
    onChange(target, e.target.value);
    handleValidCheck(e.target.value);
  };

  const handleClearValue = (e: any) => {
    e.preventDefault();

    setValue('');
    onChange(target, '');
  };

  const handleFocus = (e: any) => {
    e.preventDefault();

    setIsFocused(true);

    if (validation) {
      setIsValidCheck(true);
    }

    if (handleCheckIsFocusedInput) {
      handleCheckIsFocusedInput(true);
    }
  };

  const handleOnBlur = (e: any) => {
    setIsFocused(false);

    if (handleCheckIsFocusedInput) {
      handleCheckIsFocusedInput(false);
    }
  };

  const handleValidCheck = (value: string) => {
    if (passwordValue !== undefined) {
      if (passwordValue === value) {
        setIsValidCheck(true);
      } else {
        setIsValidCheck(false);
      }

      return;
    }

    if (regExp) {
      if (regExp.test(value) || value === '') {
        setIsValidCheck(true);
      } else {
        setIsValidCheck(false);
      }
    }
  };

  useEffect(() => {
    if (validation) {
      setIsValidCheck(!validation);
    }
  }, [validation]);

  useEffect(() => {
    if (inputValue) {
      setValue(inputValue);
    }
  }, [inputValue]);

  return (
    <Row gap="12px" style={{ width: '100%', position: 'relative' }}>
      <Container
        $focused={isFocused}
        $vaildCheck={isVaildCheck}
        $styleType={styleType}
        style={{
          padding: isFocused || value !== '' ? '8px 12px' : '16px 12px',
        }}
      >
        <Label
          $focused={isFocused || value !== ''}
          style={{
            color: isVaildCheck ? '#C2C4C8' : '#D63A16',
          }}
        >
          {label}
        </Label>
        <Input
          autoComplete={autoComplete ? 'new-password' : 'on'}
          type={type}
          name={label}
          value={value}
          disabled={isDisabled ? isDisabled : false}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleOnBlur}
          onKeyPress={onKeyPress}
          style={
            window.webkit
              ? {
                  WebkitPaddingStart: 0,
                }
              : {}
          }
        />
      </Container>
      {isFocused && value !== '' && (
        <ClearIcon
          src={ICON_AUTH_CLOSE_BUTTON}
          alt="reset icon"
          onMouseDown={handleClearValue}
        />
      )}
    </Row>
  );
};

export default InputForm;
