import { InvoiceState } from 'containers/SettlementList/graphQL/types';
import { OrderState, ProductState, TicketState } from 'types/api';

export const formatMenuItem = (path: string): string => {
  switch (path) {
    case 'sales':
      return '판매';
    case 'booking':
      return '예약';
    case 'product':
      return '상품';
    case 'message':
      return '메시지';
    case 'overview':
      return '메뉴';
    default:
      return '';
  }
};

export const formatInvoiceStatus = (status: InvoiceState): string => {
  switch (status) {
    case InvoiceState.READY: {
      return '지급대기';
    }
    case InvoiceState.COMPLETED: {
      return '지급완료';
    }
    case InvoiceState.RECEIVED: {
      return '미지급';
    }
    default:
      return '';
  }
};

export const formatOrderStatus = (status: OrderState[]): string => {
  if (status === null) {
    return '전체';
  }

  if (status.includes(OrderState.COMPLETED)) {
    return '결제완료';
  }

  if (status.includes(OrderState.CANCELED)) {
    return '취소완료';
  }

  return '';
};

export const formatSalesSearchType = (type: string): string => {
  switch (type) {
    case 'productName': {
      return '상품명';
    }
    case 'fripId': {
      return '상품ID';
    }
    case 'optionName': {
      return '옵션명';
    }
    case 'crewName': {
      return '크루명';
    }
    case 'phoneNumber': {
      return '크루전화번호';
    }
    default:
      return;
  }
};

export const formatSalesTermType = (type: string): string => {
  switch (type) {
    case 'all': {
      return '전체';
    }
    case '1month': {
      return '최근 1개월';
    }
    case '6month': {
      return '최근 6개월';
    }
    case '1year': {
      return '최근 1년';
    }
    case '5year': {
      return '최근 5년';
    }
    default:
      return;
  }
};

export const formatBookingStatus = (status: TicketState[]): string => {
  if (status.includes(TicketState.CONFIRMED)) {
    return '예약확정';
  }

  if (status.includes(TicketState.ABSENT)) {
    return '불참';
  }

  if (status.includes(TicketState.USED)) {
    return '사용완료';
  }
};

export const formatBookingSearchType = (type: string): string => {
  switch (type) {
    case 'productName': {
      return '상품명';
    }
    case 'fripId': {
      return '상품ID';
    }
    case 'optionName': {
      return '옵션명';
    }

    default:
      return;
  }
};

export const formatProductStatus = (status: ProductState[]): string => {
  if (status.includes(ProductState.APPLIED)) {
    return '검수요청';
  }

  if (status.includes(ProductState.INSPECTING)) {
    return '검수중';
  }

  if (status.includes(ProductState.EDITING)) {
    return '등록중';
  }

  if (status.includes(ProductState.SALE)) {
    return '판매중';
  }

  if (status.includes(ProductState.PAUSED)) {
    return '일시중지';
  }

  if (status.includes(ProductState.REJECTED)) {
    return '검수반려';
  }

  if (status.includes(ProductState.CLOSED)) {
    return '판매종료';
  }

  if (status.includes(ProductState.SUSPENDED)) {
    return '관리자 일시중지';
  }
};
