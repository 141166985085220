import { ICON_PRODUCT_SELECT, ICON_PRODUCT_SELECT_DISABLE } from 'assets/icons';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import { Items, ScheduleParam } from 'containers/ProductRegist/hooks/types';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ $isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 16px 16px 20px;
  width: 100%;

  border-radius: 12px;
  border: ${props =>
    props.$isSelected ? '1.5px solid #1b1c1e' : '1px solid #dbdcdf'};
  background: #f7f7f8;
`;

const OptionSettingBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 16px;
  width: 100%;

  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;
`;

const OptionInput = styled.input`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 27px 8px 12px;
  width: 100%;

  text-align: right;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    justify-content: flex-end;
  }

  &:focus {
    border: 1.5px solid #1b1c1e;
  }
`;

interface OptionModalItemsIProps {
  item: Items;
  idx: number;
  scheduleItem: ScheduleParam;
  selectedItems: Items[];
  setSelectedItems: React.Dispatch<React.SetStateAction<Items[]>>;
  handleChangeScheduleItem: (key: string, value: any) => void;
}

const OptionModalItems: React.FC<OptionModalItemsIProps> = props => {
  const {
    item,
    idx,
    scheduleItem,
    selectedItems,
    setSelectedItems,
    handleChangeScheduleItem,
  } = props;

  const isSelected = scheduleItem.itemParamIds.includes(item.paramId);

  const handleAddOptions = () => {
    if (scheduleItem.appliedAllItems) {
      return;
    }

    if (isSelected) {
      const filterItem = scheduleItem.itemParamIds.filter(
        filterItem => filterItem !== item.paramId,
      );

      setSelectedItems(
        selectedItems.filter(filterItem => filterItem.paramId !== item.paramId),
      );

      handleChangeScheduleItem('itemParamIds', filterItem);
      return;
    }

    setSelectedItems([...selectedItems, item]);
    handleChangeScheduleItem('itemParamIds', [
      ...scheduleItem.itemParamIds,
      item.paramId,
    ]);
  };

  return (
    <Container $isSelected={isSelected || scheduleItem.appliedAllItems}>
      <Row justifyContent="space-between" style={{ width: '100%' }}>
        <Text color="#1b1c1e" size="16px">
          옵션{idx + 1}
        </Text>
        <Row gap="2px" onClick={handleAddOptions}>
          <img
            src={
              isSelected || scheduleItem.appliedAllItems
                ? ICON_PRODUCT_SELECT
                : ICON_PRODUCT_SELECT_DISABLE
            }
            alt="icon product select"
          />
          <Text
            color={
              isSelected || scheduleItem.appliedAllItems ? '#1b1c1e' : '#c2c4c8'
            }
            size="14px"
          >
            선택
          </Text>
        </Row>
      </Row>
      <Column gap="8px">
        <OptionSettingBox>{item.name}</OptionSettingBox>
        <OptionSettingBox>
          <Row justifyContent="space-between" style={{ width: '100%' }}>
            <Text color="#1b1c1e" size="14px" weight={400}>
              판매가
            </Text>
            <Row style={{ position: 'relative', width: '50%' }}>
              <OptionInput
                type="text"
                value={item.price.sale.toLocaleString()}
                readOnly={true}
              />
              <Text
                color="#1b1c1e"
                size="14px"
                weight={400}
                style={{ position: 'absolute', right: '12px' }}
              >
                원
              </Text>
            </Row>
          </Row>
        </OptionSettingBox>
      </Column>
    </Container>
  );
};

export default OptionModalItems;
