import { useEffect, useState } from 'react';
import { fromAddress } from 'react-geocode';
import {
  FripLocationParam,
  ProductParamInput,
} from 'containers/ProductRegist/hooks/types';
import { useMutationUpdateLocation } from '../graphQL/hooks/useMutationUpdateLocation';
import { CreateLocationResult, UpdateLocationResult } from '../graphQL/types';
import { useLazyQueryGetLocationPresets } from '../graphQL/hooks/useLazyQueryGetLocationPresets';
import { MeData } from 'graphQL/Auth/types';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import { isEmpty } from 'lodash';
import { errorHandler } from 'utils/ErrorHandler';

export interface AddressItems {
  locationId?: string;
  overseas: boolean;
  address: string;
  detailAddress: string;
  label: string;
  locationType: string[];
  convenienceIds: string[];
  geoPoint: { latitude: number; longitude: number };
  parking: {
    available: boolean;
    note: string;
  };
}

export const useAddressModalHooks = (
  productParamInput: ProductParamInput,
  locationVenueItems: FripLocationParam | null,
  type: 'address' | 'meeting',
  handleOnChangeProductParamInput: (key: string, value: any) => void,
  handleResetLocationVenueItems: () => void,
) => {
  const myInfo: MeData = useUserInformation(state => state.userInfo);

  const { handleCreateLocation, handleUpdateLocation, handleDeleteLocation } =
    useMutationUpdateLocation();
  const { getLocationPresets } = useLazyQueryGetLocationPresets();

  const [isOpenKakaoModal, setIsOpenKakaoModal] = useState<boolean>(false);
  const [isMapLoad, setIsMapLoad] = useState<boolean>(false);

  const [addressItems, setAddressItems] = useState<AddressItems>({
    overseas: false,
    address: '',
    detailAddress: '',
    label: '',
    locationType: [],
    geoPoint: {
      latitude: 0,
      longitude: 0,
    },
    parking: {
      available: false,
      note: '',
    },
    convenienceIds: [],
  });

  const handleSetAddressItems = (key: string, value: any) => {
    setAddressItems({ ...addressItems, [key]: value });
  };

  const handleIsOpenKakaoModal = () => {
    setIsOpenKakaoModal(!isOpenKakaoModal);
  };

  const handleFindAddress = async () => {
    try {
      const response = await fromAddress(addressItems.address);

      if (response) {
        const { lat, lng } = response.results[0].geometry.location;

        setIsMapLoad(true);
        setAddressItems({
          ...addressItems,
          geoPoint: {
            latitude: lat,
            longitude: lng,
          },
        });
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleIsActiveButton = (): boolean => {
    if (addressItems.overseas && addressItems.address !== '') {
      return true;
    }

    if (addressItems.address !== '' && addressItems.label !== '') {
      return true;
    }

    return false;
  };

  const handleRegistLocation = (callback?: () => void) => {
    if (handleIsActiveButton()) {
      if (locationVenueItems === null) {
        handleCreateLocation(
          {
            name: addressItems.label,
            address:
              addressItems.detailAddress !== ''
                ? `${addressItems.address} ${addressItems.detailAddress}`
                : addressItems.address,
            geoPoint: addressItems.geoPoint,
            parking: addressItems.parking,
            convenienceIds: addressItems.convenienceIds,
            overseas: addressItems.overseas,
            useByDefault: false,
          },
          (result: CreateLocationResult) => {
            if (type === 'address') {
              handleOnChangeProductParamInput('frip', {
                ...productParamInput.frip,
                locationsOfVenue: [
                  ...productParamInput.frip!.locationsOfVenue,
                  {
                    id: result.createLocation.id,
                    locationId: result.createLocation.id,
                    label: result.createLocation.name,
                    address: result.createLocation.address,
                    geoPoint: result.createLocation.geoPoint,
                    overseas: result.createLocation.overseas,
                  },
                ],
              });
            }

            if (type === 'meeting') {
              handleOnChangeProductParamInput('frip', {
                ...productParamInput.frip,
                locationOfGathering: {
                  id: result.createLocation.id,
                  locationId: result.createLocation.id,
                  label: result.createLocation.name,
                  address:
                    addressItems.detailAddress &&
                    addressItems.detailAddress !== ''
                      ? `${addressItems.address} ${addressItems.detailAddress}`
                      : addressItems.address,
                  geoPoint: addressItems.geoPoint,
                  overseas: addressItems.overseas,
                },
              });
            }
          },
        );

        callback && callback();

        return;
      }

      handleUpdateLocation(
        locationVenueItems!.id!,
        {
          name: addressItems.label,
          address:
            addressItems.detailAddress && addressItems.detailAddress !== ''
              ? `${addressItems.address} ${addressItems.detailAddress}`
              : addressItems.address,
          geoPoint: addressItems!.geoPoint!,
          parking: addressItems.parking,
          convenienceIds: addressItems.convenienceIds,
          overseas: addressItems!.overseas,
          useByDefault: false,
        },
        (result: UpdateLocationResult) => {
          if (type === 'address') {
            const copy: any[] = [...productParamInput.frip!.locationsOfVenue];
            const findIndex: number =
              productParamInput.frip!.locationsOfVenue.findIndex(
                item => item.locationId === locationVenueItems!.locationId,
              );

            copy[findIndex] = {
              id: result.updateLocation.id,
              label: result.updateLocation.name,
              address: result.updateLocation.address,
              geoPoint: result.updateLocation.geoPoint,
              overseas: result.updateLocation.overseas,
            };

            handleOnChangeProductParamInput('frip', {
              ...productParamInput.frip,
              locationsOfVenue: copy,
            });
          }

          if (type === 'meeting') {
            handleOnChangeProductParamInput('frip', {
              ...productParamInput.frip,
              locationOfGathering: {
                id: result.updateLocation.id,
                locationId: result.updateLocation.id,
                label: result.updateLocation.name,
                address: result.updateLocation.address,
                geoPoint: result.updateLocation.geoPoint,
                overseas: result.updateLocation.overseas,
              },
            });
          }

          handleResetLocationVenueItems();
          callback && callback();
        },
      );
    }
  };

  const handleGetLocationPresets = async () => {
    try {
      const response = await getLocationPresets({
        variables: {
          hostId: myInfo.me.host.id,
          filter: { nameLike: locationVenueItems.label },
        },
      });

      if (response) {
        const resultData = response.data.product.locationPresets;

        if (isEmpty(resultData)) {
          setAddressItems({
            ...addressItems,
            locationId: locationVenueItems.id,
            address: locationVenueItems.address,
            label: locationVenueItems.label,
            overseas: locationVenueItems.overseas,
            geoPoint: locationVenueItems.geoPoint!,
          });

          return;
        }

        setAddressItems({
          ...resultData[0],
          id: locationVenueItems.id,
          convenienceIds: resultData[0].conveniences.map(item => item.id),
        });
      }
    } catch (error) {
      console.log(error, '<<<');
    }
  };

  useEffect(() => {
    if (locationVenueItems !== null) {
      handleGetLocationPresets();

      setAddressItems({
        ...addressItems,
        locationId: locationVenueItems.id,
        address: locationVenueItems.address,
        label: locationVenueItems.label,
        overseas: locationVenueItems.overseas,
        geoPoint: locationVenueItems.geoPoint!,
      });
    } else {
      setAddressItems({
        overseas: false,
        address: '',
        detailAddress: '',
        label: '',
        locationType: [],
        geoPoint: {
          latitude: 0,
          longitude: 0,
        },
        parking: {
          available: false,
          note: '',
        },
        convenienceIds: [],
      });
    }
  }, [locationVenueItems]);

  return {
    isOpenKakaoModal,
    isMapLoad,
    addressItems,
    setAddressItems,
    setIsMapLoad,
    handleIsOpenKakaoModal,
    handleSetAddressItems,
    handleFindAddress,
    handleIsActiveButton,
    handleRegistLocation,
    handleDeleteLocation,
  };
};
