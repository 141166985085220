import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import OptionBox from './components/OptionBox';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import { ICON_PLUS_GRAY } from 'assets/icons';
import OptionSelectBox from '../common/OptionSelectBox';
import { Items, ProductParamInput } from 'containers/ProductRegist/hooks/types';
import { InventoryTargetType } from 'types/api';
import ModalContainer from '../common/ModalContainer';
import Divider from 'components/Divider';
import OptionButton from '../common/OptionButton';
import HackleManager from 'utils/hackle';

const OptionPlusButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background-color: #fff;

  color: #70737c;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
  cursor: pointer;
`;

const OptionInput = styled.input`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 27px 8px 12px;
  width: 100%;

  text-align: right;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    justify-content: flex-end;
  }

  &:focus {
    border: 1.5px solid #1b1c1e;
  }
`;

interface ChapterThreeIProps {
  type?: string;
  isMobile: boolean;
  productParamInput: ProductParamInput;
  items: Items[];
  selectedItems: Items[];
  setItems: Dispatch<SetStateAction<Items[]>>;
  handleAddOptions: () => void;
  handleSelectedItems: (item: Items) => void;
  handleChangeScheduleItems: (inventoryType: InventoryTargetType) => void;
  handleAllSettingSelectedItems: (type: 'clear' | 'allAdd') => void;
  handleDeleteSelectedItems: () => void;
  handleDeleteItmes: (deleteItems: Items) => void;
  handleSetRepresentativeItems: (paramId: string) => void;
  handleChangeAllPrice: (type: string, price: number) => void;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
  setProductParamInput: Dispatch<SetStateAction<ProductParamInput>>;
}

const ChapterThree: React.FC<ChapterThreeIProps> = props => {
  const {
    type,
    isMobile,
    productParamInput,
    items,
    selectedItems,
    setItems,
    handleAddOptions,
    handleSelectedItems,
    handleChangeScheduleItems,
    handleAllSettingSelectedItems,
    handleDeleteSelectedItems,
    handleDeleteItmes,
    handleSetRepresentativeItems,
    handleChangeAllPrice,
    handleOnChangeProductParamInput,
    setProductParamInput,
  } = props;

  const params = useParams<{ id: string }>();

  const [isOpenAllPriceSetModal, setIsOpenAllPriceSetModal] =
    useState<boolean>(false);
  const [priceSet, setPriceSet] = useState<string>('');
  const [price, setPrice] = useState<number>(0);

  useEffect(() => {
    HackleManager.logCreateStepTwoSalesOptionView(
      params.id !== 'draft' ? Number(params.id) : undefined,
    );
  }, []);

  const renderContents = (): JSX.Element => {
    return (
      <Column
        gap="32px"
        align="flex-start"
        style={{ padding: isMobile ? '0' : '32px 0' }}
      >
        {!type && (
          <Text color="#1b1c1e" size="24px">
            판매 옵션을 추가해주세요
          </Text>
        )}
        {renderOptionContents()}
        {(!productParamInput.frip.attachedToSchedule ||
          productParamInput.inventoryTargetType !== null) && (
          <Column align="flex-start" gap="8px">
            <Row
              justifyContent="space-between"
              style={{
                // position: isMobile ? 'sticky' : 'static',
                // top: '0',
                padding: '8px 0 16px 0',
                width: '100%',
                background: '#fff',
                zIndex: 10,
              }}
            >
              <Text color="#46474c" size="14px">
                총 옵션 {items.length}개
              </Text>
              <Row gap="8px">
                <Text
                  color={selectedItems.length > 0 ? '#70737c' : '#dbdcdf'}
                  size="14px"
                  onClick={() => {
                    if (selectedItems.length > 0)
                      setIsOpenAllPriceSetModal(true);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  가격 일괄 변경
                </Text>
                <Text color="#dbdcdf" size="14px">
                  |
                </Text>
                <Text
                  color={selectedItems.length > 0 ? '#70737c' : '#dbdcdf'}
                  size="14px"
                  onClick={() => {
                    if (selectedItems.length > 0) handleDeleteSelectedItems();
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  선택 옵션 삭제
                </Text>
                <Text color="#dbdcdf" size="14px">
                  |
                </Text>
                <Text
                  color="#70737c"
                  size="14px"
                  onClick={() => {
                    if (selectedItems.length > 0) {
                      handleAllSettingSelectedItems('clear');

                      return;
                    }

                    handleAllSettingSelectedItems('allAdd');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {selectedItems.length > 0 ? '선택 해제' : '전체 선택'}
                </Text>
              </Row>
            </Row>
            <Column gap="16px" align="flex-end">
              {renderOptionBox()}
              <OptionPlusButton onClick={handleAddOptions}>
                <img src={ICON_PLUS_GRAY} alt="plus icon" />
                옵션추가
              </OptionPlusButton>
            </Column>
          </Column>
        )}
      </Column>
    );
  };

  const renderOptionContents = (): JSX.Element => {
    if (!productParamInput.frip!.attachedToSchedule) {
      return <></>;
    }

    return (
      <Row gap="8px" style={{ width: '100%' }}>
        <OptionSelectBox
          title="일정별 인원 제한"
          description="각 일정에 모집할 인원 수를 정해 주세요."
          isSelected={
            productParamInput.inventoryTargetType ===
            InventoryTargetType.BY_SCHEDULE
          }
          onClick={() => {
            if (
              productParamInput.inventoryTargetType ===
              InventoryTargetType.BY_ITEM
            ) {
              setProductParamInput({
                ...productParamInput,
                frip: { ...productParamInput.frip, schedules: [] },
                inventoryTargetType: InventoryTargetType.BY_SCHEDULE,
              });

              handleChangeScheduleItems(InventoryTargetType.BY_SCHEDULE);
            }
          }}
          style={{ minHeight: 'auto' }}
        />
        <OptionSelectBox
          title="옵션별 인원 제한"
          description="각 옵션에 모집할 인원 수를 정해 주세요."
          isSelected={
            productParamInput.inventoryTargetType ===
            InventoryTargetType.BY_ITEM
          }
          onClick={() => {
            if (
              productParamInput.inventoryTargetType ===
              InventoryTargetType.BY_SCHEDULE
            ) {
              setProductParamInput({
                ...productParamInput,
                frip: { ...productParamInput.frip, schedules: [] },
                inventoryTargetType: InventoryTargetType.BY_ITEM,
              });

              handleChangeScheduleItems(InventoryTargetType.BY_ITEM);
            }
          }}
          style={{ minHeight: 'auto' }}
        />
      </Row>
    );
  };

  const renderOptionBox = (): JSX.Element => {
    return (
      <React.Fragment>
        {items.map((item: Items, idx: number) => {
          return (
            <React.Fragment key={idx}>
              <Column gap="16px" align="flex-end">
                <OptionBox
                  type={type}
                  idx={idx}
                  isMobile={isMobile}
                  item={item}
                  selectedItems={selectedItems}
                  productParamInput={productParamInput}
                  setItems={setItems}
                  handleSelectedItems={handleSelectedItems}
                  handleDeleteItmes={handleDeleteItmes}
                  handleSetRepresentativeItems={handleSetRepresentativeItems}
                />
              </Column>
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };

  const renderAllPriceSetModal = (): JSX.Element => {
    return (
      <ModalContainer
        isMobile={isMobile}
        isAbled={price !== 0 && priceSet !== ''}
        onClose={() => {
          setIsOpenAllPriceSetModal(false);
        }}
        onClick={() => {
          handleChangeAllPrice(priceSet, price);
          setPrice(0);
          setIsOpenAllPriceSetModal(false);
          setPriceSet('');
        }}
        onAcceptText="적용"
      >
        <Row style={{ padding: '16px 0' }}>
          <Text color="#1b1c1e" size="16px" weight={600}>
            가격 일괄 변경
          </Text>
        </Row>
        <Column gap="24px" style={{ padding: '0 20px' }}>
          <Row
            gap="8px"
            style={{ width: '100%' }}
            justifyContent="space-between"
          >
            <Text color="#1b1c1e" size="16px">
              선택한 옵션의 판매가를
            </Text>
            <Row style={{ position: 'relative', width: '50%' }}>
              <OptionInput
                type="text"
                value={price.toLocaleString()}
                onChange={(e: any) => {
                  setPrice(Number(e.target.value.replaceAll(',', '')));
                }}
              />
              <Text
                color="#1b1c1e"
                size="14px"
                weight={400}
                style={{ position: 'absolute', right: '12px' }}
              >
                원
              </Text>
            </Row>
          </Row>
          <Divider />
          <Row gap="8px" style={{ width: '100%' }}>
            <OptionButton
              text="높일게요"
              isSelected={priceSet === 'up'}
              onClick={() => {
                setPriceSet('up');
              }}
            />
            <OptionButton
              text="낮출게요"
              isSelected={priceSet === 'down'}
              onClick={() => {
                setPriceSet('down');
              }}
            />
          </Row>
        </Column>
      </ModalContainer>
    );
  };

  return (
    <React.Fragment>
      {renderContents()}
      {isOpenAllPriceSetModal && renderAllPriceSetModal()}
      {/* <CommonDialog
        title="선택한 옵션을 삭제할까요?"
        description="옵션은 저장되지 않고 삭제됩니다."
        onCloseText="아니요"
        onClose={() => {}}
        onAcceptText="네, 삭제할게요"
        onAccept={() => {}}
      /> */}
    </React.Fragment>
  );
};

export default ChapterThree;
