import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { debounce, isEmpty } from 'lodash';

import SelectButton from '../common/SelectButton';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import Column from 'components/common/Column';

import { useGetStandardCategory } from 'containers/ProductRegist/graphQL/hooks/useGetStandardCategory';
import { useGetSearchCategory } from 'containers/ProductRegist/graphQL/hooks/useGetSearchCategory';
import {
  EdgesEntity,
  StandardChildrenEntity,
} from 'containers/ProductRegist/graphQL/types';

import {
  ICON_AUTH_CLOSE_BUTTON,
  ICON_CATEGORY_SEARCH,
  ICON_CATEGORY_SEARCH_ACTIVE,
} from 'assets/icons';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import { CommonState, ProductKind } from 'types/api';
import OptionButton from '../common/OptionButton';
import CategorySelectForm from './components/CategorySelectForm';
import { useCategoryHooks } from './hooks/useCategoryHooks';
import { useParams } from 'react-router-dom';
import HackleManager from 'utils/hackle';

const SearchForm = styled.div<{ $isFocused: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 12px;
  width: 100%;

  border-radius: 8px;
  border: ${props =>
    props.$isFocused ? '1.5px solid #1b1c1e' : '1px solid #dbdcdf'};
  background-color: #fff;
  z-index: 2;

  img {
    z-index: 2;
  }
`;

const SearchInput = styled.input`
  display: flex;
  width: 100%;

  border: none;
  color: #1b1c1e;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.6px;
  z-index: 2;

  ::placeholder {
    color: #989ba2;
  }
`;

const SearchResultForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 72px 16px 16px 16px;
  gap: 16px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 350px;
  overflow: auto;
  border-radius: 8px;
  border: 1px solid #eaebec;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  z-index: 1;
`;

const ClearIcon = styled.img`
  display: flex;
  align-items: center;
  width: 20px;
  z-index: 8;
  cursor: pointer;
`;

interface ChapterFiveIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
}

const ChapterFive: React.FC<ChapterFiveIProps> = props => {
  const { isMobile, productParamInput, handleOnChangeProductParamInput } =
    props;

  const params = useParams<{ id: string }>();

  const {
    categoryList,
    categorySelect,
    categoryValue,
    isFocused,
    searchResult,
    isOpenSearchResultForm,
    value,
    setValue,
    setCategorySelect,
    setIsFocused,
    handleGetSearchCategory,
    setIsOpenSearchResultForm,
    handleRefetchStandardCategory,
    handleGetStandardCategory,
  } = useCategoryHooks(productParamInput);

  const defaultId =
    productParamInput.kind! === ProductKind.OFFLINE
      ? '0001'
      : productParamInput.kind! === ProductKind.ONLINE
      ? '0002'
      : '0003';

  useEffect(() => {
    HackleManager.logCreateStepOneCategoryView(
      params.id !== 'draft' ? Number(params.id) : undefined,
    );
  }, []);

  const renderContents = (): JSX.Element => {
    return (
      <Column gap="24px">
        <Row gap="8px" style={{ width: '100%' }}>
          <OptionButton
            text="검색"
            isSelected={categorySelect === 'Search'}
            onClick={() => {
              setCategorySelect('Search');
              // handleOnChangeProductParamInput('standardCategoryId', '');
              // setValue('');
            }}
          />
          <OptionButton
            text="직접 선택"
            isSelected={categorySelect === 'Select'}
            onClick={() => {
              setCategorySelect('Select');
              // handleOnChangeProductParamInput('standardCategoryId', '');
              if (productParamInput.standardCategoryId === '') {
                handleGetStandardCategory(defaultId);
              }
            }}
          />
        </Row>
        {categorySelect === 'Search'
          ? renderSearchForm()
          : renderSearchSelectForm()}
      </Column>
    );
  };

  const renderSearchForm = (): JSX.Element => {
    return (
      <Column style={{ position: 'relative' }}>
        <SearchForm $isFocused={isFocused}>
          <img
            src={isFocused ? ICON_CATEGORY_SEARCH_ACTIVE : ICON_CATEGORY_SEARCH}
            alt="category search icon"
          />
          <SearchInput
            type="text"
            placeholder="검색할 카테고리명을 입력해 주세요"
            value={value}
            onChange={e => {
              setValue(e.target.value);

              if (e.target.value !== '') {
                handleGetSearchCategory(e.target.value, defaultId);
              }
            }}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              if (value === '') {
                setIsFocused(false);
              }
            }}
          />
          {((isFocused && value !== '') || value !== '') && (
            <ClearIcon
              src={ICON_AUTH_CLOSE_BUTTON}
              alt="close icon"
              onClick={(e: any) => {
                e.preventDefault();

                setValue('');
                handleGetSearchCategory(
                  '',
                  productParamInput.standardCategoryId,
                );
                handleOnChangeProductParamInput(
                  'standardCategoryId',
                  defaultId,
                );
                setIsFocused(false);
              }}
            />
          )}
        </SearchForm>
        {isOpenSearchResultForm &&
          searchResult &&
          value !== '' &&
          !isEmpty(searchResult.category.standardLeaves.edges) && (
            <SearchResultForm>
              {searchResult.category.standardLeaves.edges.map(
                (item: EdgesEntity, idx: number) => {
                  const first = item.node.parent?.parent?.parent?.label.name;
                  const second = item.node.parent?.parent?.label.name;
                  const third = item.node.parent?.label.name;
                  const fourth = item.node.label.name;

                  if (first === '번개프립' || second === '번개프립') {
                    return;
                  }

                  if (item.node.status !== CommonState.INACTIVE) {
                    return (
                      <React.Fragment key={idx}>
                        <Row
                          gap="8px"
                          justifyContent="space-between"
                          style={{ width: '100%', cursor: 'pointer' }}
                          onClick={() => {
                            setValue(
                              `${first ? first : ''} ${`${
                                second ? `${second} >` : ''
                              }`} ${`${third} >`} ${fourth}`,
                            );

                            handleOnChangeProductParamInput(
                              'standardCategoryId',
                              item.node.id,
                            );

                            setIsOpenSearchResultForm(false);
                          }}
                        >
                          <Text color="#1b1c1e" weight={400}>
                            {`${first ? `${first} >` : ''} ${`${
                              second ? `${second} >` : ''
                            }`} ${`${third} >`} ${fourth}`}
                          </Text>
                          <Text
                            color="#70737C"
                            weight={400}
                            style={{
                              textDecoration: 'underline',
                              minWidth: '24px',
                            }}
                            onClick={() => {
                              setValue(
                                `${first ? first : ''} ${`${
                                  second ? `${second} >` : ''
                                }`} ${`${third} >`} ${fourth}`,
                              );

                              handleOnChangeProductParamInput(
                                'standardCategoryId',
                                item.node.id,
                              );

                              setIsOpenSearchResultForm(false);
                            }}
                          >
                            선택
                          </Text>
                        </Row>
                      </React.Fragment>
                    );
                  }
                },
              )}
            </SearchResultForm>
          )}
      </Column>
    );
  };

  const renderSearchSelectForm = (): JSX.Element => {
    return (
      <Column align="flex-start" gap="16px">
        <Text color="#1b1c1e" size="16px">
          1차 카테고리
        </Text>
        <CategorySelectForm
          selectCategoryNum="first"
          categoryValue={categoryValue.first}
          categoryItems={
            categoryList.first ? categoryList.first.standard.children : null
          }
          handleRefetchStandardCategory={handleRefetchStandardCategory}
          handleGetStandardCategory={() => {
            handleGetStandardCategory(defaultId);
          }}
          handleOnChangeProductParamInput={handleOnChangeProductParamInput}
        />
        {(!isEmpty(categoryList.second) || categoryValue.second) && (
          <React.Fragment>
            <Text color="#1b1c1e" size="16px">
              2차 카테고리
            </Text>
            <CategorySelectForm
              selectCategoryNum="second"
              categoryValue={categoryValue.second}
              categoryItems={
                categoryList.second
                  ? categoryList.second.standard.children
                  : null
              }
              handleRefetchStandardCategory={handleRefetchStandardCategory}
              handleOnChangeProductParamInput={handleOnChangeProductParamInput}
            />
          </React.Fragment>
        )}
        {(!isEmpty(categoryList.third) || categoryValue.third) && (
          <React.Fragment>
            <Text color="#1b1c1e" size="16px">
              3차 카테고리
            </Text>
            <CategorySelectForm
              selectCategoryNum="third"
              categoryValue={categoryValue.third}
              categoryItems={
                categoryList.third ? categoryList.third.standard.children : null
              }
              handleRefetchStandardCategory={handleRefetchStandardCategory}
              handleOnChangeProductParamInput={handleOnChangeProductParamInput}
            />
          </React.Fragment>
        )}
        {(!isEmpty(categoryList.fourth) || categoryValue.fourth) && (
          <React.Fragment>
            <Text color="#1b1c1e" size="16px">
              4차 카테고리
            </Text>
            <CategorySelectForm
              selectCategoryNum="fourth"
              categoryValue={categoryValue.fourth}
              categoryItems={
                categoryList.fourth
                  ? categoryList.fourth.standard.children
                  : null
              }
              handleRefetchStandardCategory={handleRefetchStandardCategory}
              handleOnChangeProductParamInput={handleOnChangeProductParamInput}
            />
          </React.Fragment>
        )}
      </Column>
    );
  };

  const renderResponsivePage = (): JSX.Element => {
    if (isMobile) {
      return (
        <React.Fragment>
          <Text size="24px" color="#1b1c1e" style={{ lineHeight: '32px' }}>
            카테고리를 선택해 주세요
          </Text>
          {renderContents()}
        </React.Fragment>
      );
    }

    return (
      <Row gap="64px" style={{ padding: '32px 0', width: '100%' }}>
        <Column gap="32px" align="flex-start">
          <Text size="24px" color="#1b1c1e" style={{ lineHeight: '32px' }}>
            카테고리를 선택해 주세요
          </Text>
          {renderContents()}
        </Column>
      </Row>
    );
  };

  return renderResponsivePage();
};

export default ChapterFive;
