import React, { SetStateAction, useState } from 'react';
import Calendar from 'react-calendar';
import styled from 'styled-components';
import { Value, View } from 'react-calendar/dist/cjs/shared/types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 32px;
  width: 100%;

  .react-calendar {
    width: 100%;
    border: none;

    .react-calendar__navigation button {
      font-size: 16px;
      font-weight: 500;
    }

    .react-calendar__navigation__prev-button {
      background-color: #fff !important;
    }

    .react-calendar__navigation__prev2-button {
      display: none;
    }

    .react-calendar__navigation__next2-button {
      display: none;
    }

    .react-calendar__month-view__weekdays {
      display: none !important;

      :first-child {
        /* display: flex !important; */
      }
    }

    .react-calendar__month-view__days {
      display: flex;
      align-items: flex-start;
    }

    .react-calendar__tile--active {
      background-color: #f7f7f8;
    }

    .react-calendar__tile {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 16px;
      width: 54px;
      background-color: #fff;
      color: #1b1c1e;
      font-size: 14px;

      .selected {
        .react-calendar__tile {
          color: #fff !important;
        }
      }

      &:disabled {
        /* background-color: #eaebec; */
        color: #dbdcdf;
      }

      &:enabled:focus {
        /* color: #fff; */
      }

      &:focus {
        background-color: #fff;
      }
    }
  }

  .react-calendar__month-view__weekdays__weekday--weekend abbr[title='일요일'] {
    color: #ff4242;
  }

  .react-calendar__month-view__weekdays__weekday--weekend abbr[title='토요일'] {
    color: #0071e9;
  }
  .react-calendar__tile .selected {
    color: #fff !important;
  }

  .selected {
    /* abbr { */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    border-radius: 50%;
    /* min-width: 20px;
      max-width: 50px;
      min-height: 20px;
      max-height: 50px; */
    background-color: #1b1c1e !important;
    font-size: 0.833em !important;
    /* } */
  }

  .sunday {
    color: #ff4242 !important;
  }

  .saturday {
    color: #0071e9 !important;
  }
`;

interface SaleCalendarIProps {
  saleDate: Date;
  setSaleDate: React.Dispatch<SetStateAction<Date>>;
}

const SaleCalendar: React.FC<SaleCalendarIProps> = props => {
  const { saleDate, setSaleDate } = props;

  const today = new Date();

  const handleOnClickDate = (date: Date) => {
    setSaleDate(date);
  };

  const tileClassName = ({
    date,
    view,
  }: {
    date: Date;
    view: View;
  }): string | null => {
    if (view === 'month') {
      let classNames = '';

      // 선택된 날짜에 대한 스타일
      if (saleDate && saleDate.getTime() === date.getTime()) {
        classNames += 'selected ';
      }

      return classNames.trim();
    }

    return null;
  };

  return (
    <Container>
      <Calendar
        // value={value}
        showNeighboringMonth={false}
        calendarType="gregory"
        minDate={today}
        tileClassName={tileClassName}
        formatDay={(locale, date) => date.getDate().toString()}
        onClickDay={(value, event) => {
          event.stopPropagation();

          handleOnClickDate(value);
        }}
      />
    </Container>
  );
};

export default SaleCalendar;
