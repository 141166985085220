import { Product } from "types/api";

export enum BookingTermType {
  APPLYING = "APPLYING",
  CONFIRMING = "CONFIRMING",
  DEADLINE = "DEADLINE",
  EXPIRATION = "EXPIRATION",
  STARTING = "STARTING",
  USING = "USING",
}

export interface ProductSchedules {
  schedules: Schedules;
}

export interface Schedules {
  totalCount: number;
  pageInfo: PageInfo;
  edges: ScheduleEdgesEntity[];
}

export interface PageInfo {
  hasNextPage: boolean;
}

export interface ScheduleEdgesEntity {
  node: ScheduleNode;
}

export interface ScheduleNode {
  id: string;
  term: Term;
  saleTerm: TermOrSaleTerm;
  status: string;
  booking: Booking;
  product: Product;
}

export interface Term {
  startedAt: number;
  duration: number;
  endedAt: number;
}

export interface TermOrSaleTerm {
  startedAt: number;
  endedAt: number;
}

export interface Booking {
  counts: Counts;
}

export interface Counts {
  total: number;
  applied: number;
  confirmed: number;
  used: number;
  absent: number;
}

export interface Booking {
  booking: Booking;
}
export interface Bookings {
  bookings: Bookings;
}
export interface BookingsItem {
  totalCount: number;
  edges: BookingEdgesEntity[];
  pageInfo: PageInfo;
}
export interface BookingEdgesEntity {
  node: BookingNode;
}
export interface BookingNode {
  id: string;
  status: string;
  customer: Customer;
  order: Order;
  info: Info;
  schedule?: SaleTerm | null;
  items: ItemsEntity[];
  purchaseCount: number;
  counts: Counts;
}
export interface Customer {
  id: string;
  nickname: string;
  mobileNumber: string;
  gender: string;
}
export interface Order {
  id: string;
}
export interface Info {
  id: string;
  name: string;
  options: OptionsEntityOrHost[];
  product: {
    id: string;
    title: string;
  };
  host: OptionsEntityOrHost;
  origin: Origin;
}
export interface OptionsEntityOrHost {
  id: string;
  name: string;
}

export interface Origin {
  type: string;
  product: Product;
  saleTerm?: null;
}

export interface SaleTerm {
  startedAt: number;
  endedAt: number;
}
export interface ItemsEntity {
  tickets?: TicketsEntity[] | null;
}
export interface TicketsEntity {
  confirmedAt: number;
  usedAt?: null;
  scheduleTerm: ScheduleTerm;
}
export interface ScheduleTerm {
  startedAt: number;
}
export interface Counts {
  total: number;
  applied: number;
  confirmed: number;
  used: number;
  absent: number;
}
export interface PageInfo {
  hasNextPage: boolean;
}
