import { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';

import { FripKind, ProductKind } from 'types/api';
import { STANDARD_CATEGORY } from '..';
import { SelectCategory, SelectCategoryItem } from '../types';
import { errorHandler } from 'utils/ErrorHandler';

export function useGetStandardCategory(defaultId: string) {
  // const { data, refetch } = useQuery<SelectCategory>(STANDARD_CATEGORY, {
  //   variables: {
  //     id: defaultId,
  //   },
  // });
  const [getStandardCategory, { data, refetch }] =
    useLazyQuery<SelectCategory>(STANDARD_CATEGORY);

  const handleGetStandardCategory = async (defaultId: string) => {
    try {
      const response = await getStandardCategory({
        variables: { id: defaultId, filter: { fripKind: FripKind.COMMON } },
      });

      if (response) {
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const [categoryList, setCateogryList] = useState<{
    first: SelectCategoryItem | null;
    second: SelectCategoryItem | null;
    third: SelectCategoryItem | null;
    fourth: SelectCategoryItem | null;
  }>({ first: null, second: null, third: null, fourth: null });
  const [categoryValue, setCategoryValue] = useState<{
    first: string;
    second: string;
    third: string;
    fourth: string;
  }>({
    first: '',
    second: '',
    third: '',
    fourth: '',
  });
  const [searchValue, setSearchValue] = useState<string>('');

  const handleRefetchStandardCategory = async (
    selectNum: string,
    categoryId: string,
    categoryName: string,
  ) => {
    try {
      const response = await refetch({
        id: categoryId,
      });

      if (response) {
        switch (selectNum) {
          case 'first': {
            setCateogryList({
              ...categoryList,
              second: response.data.category,
              third: null,
              fourth: null,
            });

            setCategoryValue({
              ...categoryValue,
              first: categoryName,
              second: '',
              third: '',
              fourth: '',
            });

            return;
          }
          case 'second': {
            setCateogryList({
              ...categoryList,
              third: response.data.category,
              fourth: null,
            });

            setCategoryValue({
              ...categoryValue,
              second: categoryName,
              third: '',
              fourth: '',
            });

            return;
          }
          case 'third': {
            setCateogryList({
              ...categoryList,
              fourth: response.data.category,
            });

            setCategoryValue({
              ...categoryValue,
              third: categoryName,
              fourth: '',
            });

            return;
          }
          case 'fourth': {
            setCategoryValue({ ...categoryValue, fourth: categoryName });

            return;
          }
          default: {
            return setCateogryList({ ...categoryList, first: data.category });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    data,
    categoryList,
    categoryValue,
    handleRefetchStandardCategory,
    getStandardCategory,
    refetch,
  };
}
