import { gql } from '@apollo/client';

export const FRAGMENT_INQUIRYTEMPLATE_FIELD = gql`
  fragment inquiryTemplateField on InquiryTemplate {
    id
    name
  }
`;

export const CREATE_INQUIRYTEMPLATE = gql`
  mutation createInquiryTemplate(
    $hostId: String!
    $param: InquiryTemplateParamInput!
  ) {
    createInquiryTemplate(hostId: $hostId, param: $param) {
      ...inquiryTemplateField
    }
  }

  ${FRAGMENT_INQUIRYTEMPLATE_FIELD}
`;

export const DELETE_INQURIYTEMPLATE = gql`
  mutation deleteInquiryTemplate($id: ID!) {
    deleteInquiryTemplate(id: $id) {
      message
      success
    }
  }
`;

export const UPDATE_INQUIRYTEMPLATE = gql`
  mutation updateInquiryTemplate($id: ID!, $param: InquiryTemplateParamInput!) {
    updateInquiryTemplate(id: $id, param: $param) {
      ...inquiryTemplateField
      __typename
    }
  }

  ${FRAGMENT_INQUIRYTEMPLATE_FIELD}
`;

export const GET_INQUIRYTEMPLATE = gql`
  query inquiryTemplate($id: ID!) {
    product {
      inquiryTemplate(id: $id) {
        id
        name
        status
        inquiries {
          id
          title
          inputKind
          inputPattern
          required
          status
          items {
            id
            label
          }
        }
      }
    }
  }
`;

export const UPLOAD_GENERATE_CONTENT = gql`
  mutation GenerateContentUploadInfo($param: ContentUploadParam!) {
    generateContentUploadInfo(param: $param) {
      contentId
      uploadUrl
      formData {
        key
        value
        __typename
      }
      __typename
    }
  }
`;
