export const STORAGE_KEY = {
  ALLOW_LOCATION: 'allowLocation',
  CUSTOMER_ID: 'customerId',
  RECENT_QUERY: 'recentQueries',
  ACCESS_TOKEN: 'userAccessToken', // deprecated. 구 API 호출용 하위호환.
  TOSS_PAY_TOKEN: 'TOSS_PAY_TOKEN',
  RECENT_LOGIN_METHOD: 'recentLoginMethod',
  AUTHORIZATION: 'Authorization',
  IS_FIRST_LOGIN: 'isFirstLogin',
  CHECKOUT: 'checkout',
  DEVICE_ID: 'deviceId',
  BADGE_FIRST_ENTRY: 'badgeFirstEntry',
  CHAT_USER_ID: 'chatUserId',
  CHAT_USER_TOKEN: 'chatUserToken',
};
