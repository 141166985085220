import React, { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Column from '../Column';
import CommonImage from '../CommonImage';
import Row from '../Row';
import Text from '../Text';
import { ICON_ARROW_BLACK_TOP, ICON_FRIP_LOGO } from 'assets/icons';
import { INDIVIDUAL_PATH, PROFILE_PATH } from 'constants/path';
import Divider from 'components/Divider';
import MenuItems from './MenuItems';
import ProfileThumbnail from '../ProfileThumbnail';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import { MeData } from 'graphQL/Auth/types';
import HackleManager from 'utils/hackle';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 24px 64px;
  width: 100%;
  border-bottom: 1px solid #dbdcdf;
  background: #fff;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  width: auto;
  cursor: pointer;
`;

const ActiveButton = styled.div`
  position: absolute;
  bottom: -4px;
  width: 16px;
  height: 2px;
  background-color: #1b1c1e;
`;

const MyInfoSheet = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: absolute;
  right: 0px;
  top: 55px;
  padding: 16px 20px;
  width: 200px;
  border-radius: 12px;
  background: var(--Static-White, #fff);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  z-index: 20;
`;

interface PCHeaderIProps {
  activeButton: string;
  isOpenMenuSheet: boolean;
  isOpenMyInfoSheet: boolean;
  setIsOpenMyInfoSheet: Dispatch<SetStateAction<boolean>>;
  setIsOpenMenuSheet: Dispatch<SetStateAction<boolean>>;
  handleNavigatePage: (path: string) => void;
  handleSignout: () => void;
}

const PCHeader: React.FC<PCHeaderIProps> = props => {
  const {
    activeButton,
    isOpenMenuSheet,
    isOpenMyInfoSheet,
    setIsOpenMyInfoSheet,
    setIsOpenMenuSheet,
    handleNavigatePage,
    handleSignout,
  } = props;
  const myInfo: MeData = useUserInformation(state => state.userInfo);

  const navigate = useNavigate();

  const renderMyInfoSheet = (): JSX.Element => {
    return (
      <MyInfoSheet
        onMouseLeave={() => {
          setIsOpenMyInfoSheet(false);
        }}
      >
        <Text
          color="#46474C"
          onClick={() => {
            setIsOpenMyInfoSheet(false);

            HackleManager.logProfileMenuClick('프로필 관리');
            navigate(PROFILE_PATH);
          }}
          style={{ cursor: 'pointer' }}
        >
          프로필 관리
        </Text>
        <Text
          color="#46474C"
          onClick={() => {
            setIsOpenMyInfoSheet(false);

            HackleManager.logProfileMenuClick('호스트 연락처 설정');
            navigate(INDIVIDUAL_PATH);
          }}
          style={{ cursor: 'pointer' }}
        >
          호스트 연락처 설정
        </Text>
        <Divider />
        <Text
          color="#46474C"
          onClick={() => {
            setIsOpenMyInfoSheet(false);
            handleSignout();
          }}
          style={{ cursor: 'pointer' }}
        >
          로그아웃
        </Text>
      </MyInfoSheet>
    );
  };

  return (
    <HeaderContainer
      style={{
        padding: '24px 0',
      }}
    >
      <Row
        justifyContent="space-between"
        style={{
          padding: '0 40px',
          width: '100%',
          maxWidth: '1280px',
        }}
      >
        <CommonImage
          src={ICON_FRIP_LOGO}
          onClick={() => {
            navigate('/');
            setIsOpenMenuSheet(false);
          }}
        />
        <Row gap="32px" align="flex-start" style={{ width: 'auto' }}>
          <Column
            gap="4px"
            style={{ position: 'relative', width: '42px' }}
            onClick={() => {
              handleNavigatePage('sales');
            }}
          >
            <Text
              color={activeButton.includes('sale') && '#1b1c1e'}
              style={{ cursor: 'pointer' }}
            >
              판매
            </Text>
            {activeButton.includes('sale') && <ActiveButton />}
          </Column>
          <Column
            gap="4px"
            style={{ position: 'relative', width: '42px' }}
            onClick={() => {
              handleNavigatePage('booking');
            }}
          >
            <Text
              color={activeButton === 'booking' && '#1b1c1e'}
              style={{ cursor: 'pointer' }}
            >
              예약
            </Text>
            {activeButton === 'booking' && <ActiveButton />}
          </Column>
          <Column
            gap="4px"
            style={{ position: 'relative', width: '42px' }}
            onClick={() => {
              handleNavigatePage('product');
            }}
          >
            <Text
              color={activeButton === 'product' && '#1b1c1e'}
              style={{ cursor: 'pointer' }}
            >
              상품
            </Text>
            {activeButton === 'product' && <ActiveButton />}
          </Column>
          <Column
            gap="4px"
            style={{ position: 'relative', width: '42px' }}
            onClick={() => {
              handleNavigatePage('message');
            }}
          >
            <Text
              color={activeButton === 'message' && '#1b1c1e'}
              style={{ cursor: 'pointer' }}
            >
              메시지
            </Text>
            {activeButton === 'message' && <ActiveButton />}
          </Column>
          <Column gap="4px" style={{ position: 'relative', width: '50px' }}>
            <Row
              gap="6px"
              onClick={() => {
                HackleManager.logMenuClick('메뉴');

                if (isOpenMyInfoSheet) {
                  setIsOpenMyInfoSheet(false);
                }

                setIsOpenMenuSheet(!isOpenMenuSheet);
              }}
            >
              <Text
                color={
                  (isOpenMenuSheet || activeButton === 'overview') && '#1b1c1e'
                }
                style={{ cursor: 'pointer' }}
              >
                메뉴
              </Text>
              <CommonImage
                src={ICON_ARROW_BLACK_TOP}
                onClick={() => {}}
                style={{ rotate: isOpenMenuSheet ? '' : '180deg' }}
              />
            </Row>
            {isOpenMenuSheet && (
              <MenuItems
                onClose={() => {
                  setIsOpenMenuSheet(false);
                }}
              />
            )}
          </Column>
        </Row>
        <ProfileContainer
          onClick={() => {
            if (isOpenMenuSheet) {
              setIsOpenMenuSheet(false);
            }

            setIsOpenMyInfoSheet(!isOpenMyInfoSheet);
          }}
        >
          <ProfileThumbnail />
          <Text size="16px" color="#46474c">
            <span style={{ textDecoration: 'underline' }}>
              {myInfo?.me?.host?.name}
            </span>
          </Text>
          {isOpenMyInfoSheet && renderMyInfoSheet()}
        </ProfileContainer>
      </Row>
    </HeaderContainer>
  );
};

export default PCHeader;
