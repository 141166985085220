import { useLazyQuery } from '@apollo/client';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import { useEffect } from 'react';
import { errorHandler } from 'utils/ErrorHandler';
import { STORAGE_KEY } from 'utils/localStoarge';
import { GET_ME } from '../Constants';

export function useGetMe() {
  const setUserInfo = useUserInformation((state: any) => state.setUserInfo);

  const [getUser, { data, refetch }] = useLazyQuery(GET_ME);

  const handleGetUserInformation = () => {
    if (localStorage.getItem(STORAGE_KEY.AUTHORIZATION)) {
      getUser();
    } else {
      return;
    }
  };

  const handleRefetchUser = async (callback?: () => void) => {
    try {
      const response = await refetch();

      if (response) {
        setUserInfo(response.data);

        callback && callback();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (data) {
      setUserInfo(data);
    }
  }, [data]);

  return { handleGetUserInformation, handleRefetchUser, getUser, data };
}
