import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import { useState } from 'react';

export interface AdditionalInformation {
  stuffsToPrepare: string[];
  inclusions: string[];
  exclusions: string[];
  keywords: string[];
}

export const useAdditionalModifyHooks = (
  productParamInput: ProductParamInput
) => {
  const [additionalInformation, setAdditionalInformation] =
    useState<AdditionalInformation>({
      stuffsToPrepare: productParamInput.frip.stuffsToPrepare,
      inclusions: productParamInput.frip.inclusions,
      exclusions: productParamInput.frip.exclusions,
      keywords: productParamInput.keywords,
    });

  const isAbled =
    productParamInput.frip.stuffsToPrepare.toString() !==
      additionalInformation.stuffsToPrepare.toString() ||
    productParamInput.frip.inclusions.toString() !==
      additionalInformation.inclusions.toString() ||
    productParamInput.frip.exclusions.toString() !==
      additionalInformation.exclusions.toString() ||
    productParamInput.keywords.toString() !==
      additionalInformation.keywords.toString();

  const handleAddAdditionalValue = (type: string, inputValue: string) => {
    setAdditionalInformation({
      ...additionalInformation,
      [type]: [
        ...additionalInformation[type],
        ...inputValue.split(',').filter((item) => item !== ''),
      ],
    });
  };

  const handleDeleteAdditionalValue = (type: string, value: string) => {
    setAdditionalInformation({
      ...additionalInformation,
      [type]: additionalInformation[type].filter((item) => item !== value),
    });
  };

  const handelResetAdditionalValue = () => {
    setAdditionalInformation({
      stuffsToPrepare: productParamInput.frip.stuffsToPrepare,
      inclusions: productParamInput.frip.inclusions,
      exclusions: productParamInput.frip.exclusions,
      keywords: productParamInput.keywords,
    });
  };

  return {
    isAbled,
    additionalInformation,
    handleAddAdditionalValue,
    handleDeleteAdditionalValue,
    handelResetAdditionalValue,
  };
};
