import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { ProductReviewConnection } from 'types/api';
import { GET_PRODUCT_REVIEWS_QUERY } from '..';

export function useQueryReviewList(productId?: string) {
  const { data, refetch } = useQuery<{
    productReview: { reviewsForHostAndAdmin: ProductReviewConnection };
  }>(GET_PRODUCT_REVIEWS_QUERY, {
    variables: {
      page: 1,
      size: 10,
      filter: { productId: productId ? Number(productId) : null },
    },
  });

  const [reviewList, setReviewList] = useState<ProductReviewConnection>();

  const handleRefetchReviewList = async (param: {
    page: number;
    size: number;
    filter?: any;
  }) => {
    try {
      const response = await refetch(param);

      if (response) {
        setReviewList(response.data.productReview.reviewsForHostAndAdmin);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (data) {
      setReviewList(data.productReview.reviewsForHostAndAdmin);
    }
  }, [data]);

  return { reviewList, handleRefetchReviewList };
}
