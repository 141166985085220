import { ICON_QNA_LOCK } from 'assets/icons';
import React from 'react';
import styled from 'styled-components';
import { ProductQnaState } from '../graphQL/types';

const Container = styled.button<{
  color: string;
  border: string;
  background: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 9px;

  border-radius: 14px;
  border: 1px solid ${(props) => props.border};
  background: ${(props) => props.background};

  color: ${(props) => props.color};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 140% */
  letter-spacing: -0.2px;
`;

interface QnaStatusButtonIProps {
  status: ProductQnaState;
}

const QnaStatusButton: React.FC<QnaStatusButtonIProps> = (props) => {
  const { status } = props;

  const renderStatusButton = (): JSX.Element => {
    switch (status) {
      case ProductQnaState.COMPLETE: {
        return (
          <Container color="#46474c" border="#Dbdcdf" background="#f4f4f5">
            답변완료
          </Container>
        );
      }
      case ProductQnaState.BLIND: {
        return (
          <Container color="#C2C4C8" border="#eaebec" background="#F7F7F8">
            <img src={ICON_QNA_LOCK} alt="qna lock icon" />
            비밀글
          </Container>
        );
      }
      case ProductQnaState.NEW: {
        return (
          <Container color="#E90000" border="#ff8f8f" background="#fff2f2">
            미답변
          </Container>
        );
      }
    }
    return <></>;
  };

  return renderStatusButton();
};

export default QnaStatusButton;
