import React from 'react';
import styled from 'styled-components';

const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  border-radius: 999px;
  border: 1px solid #dbdcdf;
  background-color: #fff;

  color: #1b1c1e;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.6px;
  cursor: pointer;
`;

interface BasicButtonIProps {
  content: string | React.ReactElement;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const BasicButton: React.FC<BasicButtonIProps> = props => {
  const { content, onClick, style } = props;

  return (
    <Container
      onClick={e => {
        e.stopPropagation();

        onClick();
      }}
      style={{ ...style }}
    >
      {content}
    </Container>
  );
};

export default BasicButton;
