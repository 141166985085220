import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryGetInquiryTemplate } from 'containers/ProductRegist/components/InformationRegist/graphQL/hooks/useQueryGetInquiryTemplate';
import { useMutationProductItems } from 'containers/ProductRegist/graphQL/hooks/useMutationProductItems';
import { useQueryProduct } from 'containers/ProductRegist/graphQL/hooks/useQueryProduct';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import { Product, ProductState } from 'types/api';
import { ConvertingProductParamInput } from 'utils/ConvertingProductParamInput';
import { useQueryGetProductScheduleTerm } from 'containers/ProductRegist/graphQL/hooks/useQueryGetProductScheduleTerm';
import { useMutationSaveProductRefundPolicy } from 'containers/ProductRegist/graphQL/hooks/useMutationSaveProductRefundPolicy';
import { useMutationProductStatus } from 'containers/ProductRegist/graphQL/hooks/useMutationProductStatus';
import { errorHandler } from 'utils/ErrorHandler';

export interface ModifyValue {
  modifyStatus: string;
  isModify: boolean;
}

export const useProductDetailHooks = () => {
  const params = useParams<{ id: string }>();
  const convertingProductParamInput = new ConvertingProductParamInput();

  const { data, handleGetProduct, handleRefetchProduct } = useQueryProduct();
  const {
    scheduleList,
    handleGetProductScheduleTerm,
    handleRefetchScheduleTerm,
  } = useQueryGetProductScheduleTerm();
  const {
    inquiryList,
    handelGetInquiryTemplate,
    handleRefetchInquiryTemplate,
  } = useQueryGetInquiryTemplate();
  const { saveProduct } = useMutationProductItems();
  const { handleSaveProductRefundPolicy } =
    useMutationSaveProductRefundPolicy();
  const { handleOnChangeProductStatus } = useMutationProductStatus(params.id);

  const [menuType, setMenuType] = useState<string>('detail');
  const [modifyValue, setModifyValue] = useState<ModifyValue>({
    modifyStatus: window.innerWidth < 768 ? '' : 'image',
    isModify: false,
  });
  const [productParamInput, setProductParamInput] =
    useState<ProductParamInput>();

  const [isOpenConfirmDialog, setIsOpenConfirmDialog] =
    useState<boolean>(false);
  const [isOpenCloseProductDetail, setIsOpenCloseProductDetail] =
    useState<boolean>(false);

  const handleSetMenuType = (type: string) => {
    if (type === '상세 페이지') {
      setMenuType('detail');

      return;
    }

    setMenuType('option');
  };

  const handleOnChangeProductParamInput = (key: string, value: any) => {
    setProductParamInput({ ...productParamInput, [key]: value });
  };

  const handleSetProductStatus = (status: ProductState) => {
    switch (status) {
      case ProductState.INSPECTING: {
        handleOnChangeProductStatus('cancelInspection');

        return;
      }
      case ProductState.READY: {
        handleOnChangeProductStatus('open');

        return;
      }
      case ProductState.SALE: {
        handleOnChangeProductStatus('pause');

        return;
      }
      case ProductState.PAUSED: {
        handleOnChangeProductStatus('open');

        return;
      }
      case ProductState.SUSPENDED: {
        return '고객센터에 문의할까요?';
      }
    }
  };

  const handleUpdateProduct = async () => {
    try {
      const convertProductParam =
        convertingProductParamInput.convertProductParam(
          productParamInput,
          scheduleList,
        );

      const response = await saveProduct({
        variables: { id: params.id, param: convertProductParam },
      });

      if (response) {
        window.showToast('수정사항이 적용되었습니다', 'success');

        setModifyValue({ ...modifyValue, isModify: false });
        handleSaveProductRefundPolicy(params.id);

        handleRefetchProduct(params.id);
        handleRefetchScheduleTerm();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    handleGetProduct(params.id);
  }, []);

  useEffect(() => {
    if (data && scheduleList) {
      const productInfo: Product = data.product.product;
      const paramInput = convertingProductParamInput.convertProductParamInput(
        productInfo,
        scheduleList,
      );

      setProductParamInput(paramInput);

      if (productInfo.inquiryTemplate !== null) {
        handelGetInquiryTemplate(productInfo.inquiryTemplate.id);
      }
    }
  }, [data, scheduleList]);

  return {
    productInfo: data?.product.product as Product,
    menuType,
    productParamInput,
    modifyValue,
    inquiryList,
    isOpenConfirmDialog,
    isOpenCloseProductDetail,
    setIsOpenCloseProductDetail,
    setIsOpenConfirmDialog,
    setModifyValue,
    setProductParamInput,
    handleOnChangeProductParamInput,
    handleSetProductStatus,
    handleSetMenuType,
    handleUpdateProduct,
    handleGetProductScheduleTerm,
    handelGetInquiryTemplate,
    handleRefetchInquiryTemplate,
    handleRefetchProduct,
  };
};
