import React from 'react';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import Column from 'components/common/Column';
import { IMAGE_SIGNUP_SUCCESS } from 'assets/images';
import Text from 'components/common/Text';

interface SignupSuccessModalIProps {
  onCloseNavigate: () => void;
  onStartNavigate: () => void;
}

const SignupSuccessModal: React.FC<SignupSuccessModalIProps> = props => {
  const { onCloseNavigate, onStartNavigate } = props;

  return (
    <ModalContainer
      isAbled={true}
      isMobile={false}
      onClose={onCloseNavigate}
      onClick={onStartNavigate}
      onCloseText=" "
      onAcceptText="프로필 설정하기"
      style={{ maxWidth: '375px' }}
    >
      <Column gap="32px" style={{ padding: '24px 20px' }}>
        <img
          src={IMAGE_SIGNUP_SUCCESS}
          alt="success signup"
          style={{ width: '140px' }}
        />
        <Column gap="8px" align="flex-start">
          <Text color="#1b1c1e" size="20px" weight={600}>
            호스트님, 환영해요!
          </Text>
          <Text color="#1b1c1e" size="16px" weight={400}>
            프립 호스트 가입이 완료되었습니다! 프로필 설정 후 프립에서 호스팅을
            시작해 보세요.
          </Text>
        </Column>
      </Column>
    </ModalContainer>
  );
};

export default SignupSuccessModal;
