import React, { ReactNode } from 'react';
import { styled } from 'styled-components';

import Row from 'components/common/Row';
import Text from 'components/common/Text';

const Container = styled.div<{ $isSelected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 20px; */
  padding: 0 20px 24px;
  width: 100%;
  border-radius: 16px;
  border: ${props =>
    props.$isSelected ? '1.5px solid #1b1c1e' : '1px solid  #dbdcdf'};
  background: var(--Static-White, #fff);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
`;

interface ModifyContainerIProps {
  title: string;
  children: ReactNode;
  isSelected?: boolean;
  modifyDisabled?: boolean;
  modifyText?: string;
  style?: React.CSSProperties;
  isScrollable?: boolean;
  onClick: () => void;
}

const ModifyContainer: React.FC<ModifyContainerIProps> = props => {
  const {
    title,
    children,
    isSelected,
    modifyDisabled,
    modifyText,
    style,
    isScrollable,
    onClick,
  } = props;

  return (
    <Container $isSelected={isSelected} style={{ ...style }}>
      <Row
        justifyContent="space-between"
        style={
          isScrollable
            ? {
                padding: '24px 0',
                position: 'sticky',
                top: 0,
                width: '100%',
                background: '#fff',
                zIndex: 2,
              }
            : {
                padding: '24px 0',
                width: '100%',
              }
        }
      >
        <Text color="#1b1c1e" size="16px">
          {title}
        </Text>
        <Text
          color={modifyDisabled ? '#dbdcdf' : '#70737c'}
          size="16px"
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={onClick}
        >
          {modifyText ? modifyText : '수정하기'}
        </Text>
      </Row>
      {children}
    </Container>
  );
};

export default ModifyContainer;
