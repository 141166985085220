import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import styled from 'styled-components';
import { debounce, isEmpty } from 'lodash';

import Row from 'components/common/Row';
import Text from 'components/common/Text';
import SaleCard from './components/SaleCard';
import Column from 'components/common/Column';
import BasicButton from 'components/Button/BasicButton';
import CommonImage from 'components/common/CommonImage';
import {
  ICON_CATEGORY_SEARCH,
  ICON_CATEGORY_SEARCH_ACTIVE,
  ICON_REFRESH,
} from 'assets/icons';
import TotalCountContainer from 'containers/QnaList/components/TotalCountContainer';
import { useSaleListHooks } from './hooks/useSaleListHooks';
import PaginataionContainer from 'containers/QnaList/components/PaginataionContainer';
import SearchModalContainer from './components/SearchModalContainer';
import FilterButton from './components/FilterButton';
import { OrderState } from 'types/api';
import SaleDetailPage from 'containers/SaleDetail';
import SaleDashBoard from './components/SaleDashBoard';
import Loading from 'components/Loading/Loading';

const Container = styled.div<{ $windowHeight: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  height: ${props => props.$windowHeight}px;
  max-width: 1280px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  position: sticky;
  top: 0;
  padding: 24px 20px;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #eaebec;
  z-index: 5;
`;

const SaleListPage = () => {
  const {
    orderItems,
    isLoading,
    orderFilterInput,
    isOpenCountFilter,
    isOpenSearchModal,
    selectedId,
    filterValue,
    termWithType,
    isAcceptFilter,
    handleSetTermWithType,
    handleSetFilterValue,
    setSelectedId,
    handleSetOrderFilterInput,
    setIsOpenSearchModal,
    handleIsOpenCountFilter,
    handleResetFilterValue,
    setIsAcceptFilter,
  } = useSaleListHooks();

  const { width, height, ref } = useResizeDetector();

  const isMobile = width < 768;

  const [windowHeight, setWindowHeight] = useState(
    document.documentElement.clientHeight - 81,
  );

  useEffect(() => {
    const handleResize = () => {
      const viewportHeight = document.documentElement.clientHeight;

      setWindowHeight(viewportHeight - 81);
    };

    // `resize` 이벤트 리스너 추가
    window.addEventListener('resize', debounce(handleResize, 500));

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderFilterContent = (): JSX.Element => {
    return (
      <Column gap="24px" align="flex-start" style={{ padding: '0 20px' }}>
        {isMobile && <SaleDashBoard isMobile={isMobile} />}
        <Row gap="12px">
          <FilterButton
            isSelected={orderFilterInput.filter?.statusIn === null}
            content="전체"
            onClick={() => {
              handleSetOrderFilterInput('filter', {
                ...orderFilterInput.filter,
                statusIn: null,
              });
            }}
          />
          <FilterButton
            isSelected={orderFilterInput.filter?.statusIn?.includes(
              OrderState.COMPLETED,
            )}
            content="결제완료"
            onClick={() => {
              handleSetOrderFilterInput('filter', {
                ...orderFilterInput.filter,
                statusIn: [OrderState.COMPLETED],
              });
            }}
          />
          <FilterButton
            isSelected={orderFilterInput.filter?.statusIn?.includes(
              OrderState.CANCELED,
            )}
            content="취소완료"
            onClick={() => {
              handleSetOrderFilterInput('filter', {
                ...orderFilterInput.filter,
                statusIn: [OrderState.CANCELED],
              });
            }}
          />
        </Row>
      </Column>
    );
  };

  const renderHeaderContent = (): JSX.Element => {
    return (
      <HeaderContainer
        style={{
          borderBottom: isMobile && 'none',
        }}
      >
        <Row
          justifyContent="space-between"
          style={{
            width: '100%',
            background: '#fff',
          }}
        >
          <Text color="#1b1c1e" size="24px">
            판매
          </Text>
          <Row gap="12px">
            <BasicButton
              content={
                <Row gap="4px">
                  <CommonImage
                    src={
                      isAcceptFilter
                        ? ICON_CATEGORY_SEARCH_ACTIVE
                        : ICON_CATEGORY_SEARCH
                    }
                    onClick={() => {
                      setIsOpenSearchModal(true);
                    }}
                    style={{ width: '20px', height: '20px' }}
                  />
                  <Text color="#1b1c1e">
                    {isAcceptFilter ? '검색 적용' : '검색'}
                  </Text>
                </Row>
              }
              onClick={() => {
                setIsOpenSearchModal(true);
              }}
              style={{
                height: '40px',
                border: isAcceptFilter
                  ? '1.5px solid #1b1c1e'
                  : '1px solid #dbdcdf',
                background: isAcceptFilter ? '#f4f4f5' : '#fff',
              }}
            />
            <BasicButton
              content={
                <Row gap="4px">
                  <CommonImage src={ICON_REFRESH} onClick={() => {}} />
                </Row>
              }
              onClick={() => {
                window.location.reload();
              }}
              style={{
                height: '40px',
                borderRadius: '50%',
                padding: '12px',
              }}
            />
          </Row>
        </Row>
      </HeaderContainer>
    );
  };

  const renderContents = (): JSX.Element => {
    return (
      <Column gap="24px">
        <Column style={{ padding: '0 20px' }}>
          <TotalCountContainer
            totalCount={orderItems.totalCount}
            isOpenCountFilter={isOpenCountFilter}
            filterItem={orderFilterInput}
            handleSetFilterItem={handleSetOrderFilterInput}
            handleIsOpenCountFilter={handleIsOpenCountFilter}
          />
        </Column>
        {isLoading ? (
          <Column align="center" style={{ minHeight: '50vh' }}>
            <Loading type="flex" />
          </Column>
        ) : (
          <React.Fragment>
            {!isEmpty(orderItems.edges) ? (
              <Column gap="16px" style={{ padding: '0 20px' }}>
                {orderItems.edges.map((item, idx) => {
                  return (
                    <SaleCard
                      isMobile={isMobile}
                      item={item}
                      selectedId={selectedId}
                      setSelectedId={setSelectedId}
                      key={idx}
                    />
                  );
                })}
              </Column>
            ) : (
              <Column gap="8px" style={{ minHeight: '60vh' }}>
                <Text color="#1b1c1e" size="16px">
                  판매내역이 없습니다.
                </Text>
                {/* <Text color="#70737c" weight={400}>
                  필터를 다시 확인해 주세요
                </Text> */}
              </Column>
            )}
          </React.Fragment>
        )}
        <PaginataionContainer
          totalCount={orderItems.totalCount}
          page={orderFilterInput.page}
          size={orderFilterInput.size}
          handleSetQnaFilter={handleSetOrderFilterInput}
        />
      </Column>
    );
  };

  const renderResponsiveContents = (): JSX.Element => {
    if (isMobile) {
      return (
        <Column style={{ padding: '0 0 135px' }}>
          {renderHeaderContent()}
          <Column gap="24px">
            {renderFilterContent()}
            {renderContents()}
          </Column>
        </Column>
      );
    }

    return (
      <Column style={{ padding: '0 40px' }}>
        <SaleDashBoard isMobile={isMobile} />
        <Row
          align="flex-start"
          justifyContent="space-between"
          style={{ width: '100%' }}
        >
          <Column
            gap="32px"
            justifyContent="flex-start"
            style={{
              padding: '0 0 40px',
              maxWidth: '375px',
              height: `${height - 81}px`,
              overflowY: 'auto',
              borderLeft: '1px solid #eaebec',
              borderRight: '1px solid #eaebec',
            }}
          >
            {renderHeaderContent()}
            {renderFilterContent()}
            {renderContents()}
          </Column>
          {selectedId === '' ? (
            <Column
              justifyContent="flex-start"
              gap="50px"
              style={{
                padding: '32px',
                height: `${height - 81}px`,
                overflowY: 'auto',
                borderRight: '1px solid #eaebec',
              }}
            >
              <Row justifyContent="flex-start" style={{ width: '100%' }}>
                <Text color="#1b1c1e" size="24px">
                  상세내역
                </Text>
              </Row>
              <Column gap="8px" style={{ height: '30vh' }}>
                {/* <img
                  src={IMAGE_EMPTY}
                  alt="empty sale list"
                  style={{ width: '220px' }}
                /> */}
                <Text color="#1B1C1E" size="16px">
                  선택된 판매 내역이 없습니다
                </Text>
                <Text color="#70737C" size="14px" weight={400}>
                  좌측 리스트에서 판매 내역을 선택해 주세요
                </Text>
              </Column>
            </Column>
          ) : (
            <Column
              justifyContent="flex-start"
              style={{
                // borderLeft: '1px solid #eaebec',
                borderRight: '1px solid #eaebec',
                height: `${height - 81}px`,
                overflowY: 'auto',
              }}
            >
              <SaleDetailPage id={selectedId} />
            </Column>
          )}
        </Row>
      </Column>
    );
  };

  return (
    <Container ref={ref} $windowHeight={windowHeight}>
      {orderItems ? renderResponsiveContents() : <Loading />}
      {isOpenSearchModal && (
        <SearchModalContainer
          isMobile={isMobile}
          purchaseItemFilterInput={orderFilterInput}
          filterValue={filterValue}
          termWithType={termWithType}
          handleSetFilterValue={handleSetFilterValue}
          handleSetTermWithType={handleSetTermWithType}
          handleSetPurchaseItemFilterInput={handleSetOrderFilterInput}
          onClose={() => setIsOpenSearchModal(false)}
          handleResetFilterValue={handleResetFilterValue}
          setIsAcceptFilter={setIsAcceptFilter}
        />
      )}
    </Container>
  );
};

export default SaleListPage;
