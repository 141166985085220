import React, { useState } from 'react';
import styled from 'styled-components';

import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import TimeInput from 'components/Input/TimeInput';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import SaleCalendar from './SaleCalendar';

const DateBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2px;
  padding: 8px 12px;
  min-width: 150px;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;
  cursor: pointer;
`;

interface ConfirmCalendarModalIProps {
  isMobile: boolean;
  ticketIds: string[];
  handleConfirmTickets: (
    ticketIds: string[],
    term: {
      startedAt: number;
      duration: number;
    },
    callback: () => void,
  ) => void;
  handleRefetchOrderItem: () => void;
  onClose: () => void;
}

const ConfirmCalendarModal: React.FC<ConfirmCalendarModalIProps> = props => {
  const {
    isMobile,
    ticketIds,
    handleConfirmTickets,
    handleRefetchOrderItem,
    onClose,
  } = props;

  const [saleDate, setSaleDate] = useState<Date>();
  const [saleTime, setSaleTime] = useState<string>('');

  const dateObject = new Date(saleDate);
  const [hours, minutes] = saleTime.split(':').map(Number);
  dateObject.setHours(hours, minutes);

  return (
    <ModalContainer
      isAbled={!isNaN(dateObject.getTime())}
      isMobile={isMobile}
      onAcceptText="예약일 확정하기"
      onClose={onClose}
      onClick={() => {
        handleConfirmTickets(
          ticketIds,
          {
            startedAt: dateObject.getTime(),
            duration: 0,
          },
          () => {
            handleRefetchOrderItem();

            onClose();
          },
        );
      }}
      style={{ maxWidth: isMobile ? '100%' : '375px' }}
    >
      <Column gap="16px" style={{ padding: '16px 20px' }}>
        <Text color="#1b1c1e" size="16px" weight={600}>
          예약확정
        </Text>
        <Text color="#0071E9" size="16px">
          반드시 크루와 협의된 일시를 입력해 주세요
        </Text>
        <SaleCalendar saleDate={saleDate} setSaleDate={setSaleDate} />
        <Row justifyContent="space-between" style={{ width: '100%' }}>
          <Text color="#1B1C1E" size="16px">
            시작시간
          </Text>
          <DateBox>
            <Text
              color={saleTime ? '#1b1c1e' : '#c2c4c8'}
              size="14px"
              weight={400}
              style={{ zIndex: 2 }}
            >
              {saleTime ? saleTime : '00시 • 00분'}
            </Text>
            <TimeInput
              onChange={e => {
                setSaleTime(e.target.value);
              }}
            />
          </DateBox>
        </Row>
      </Column>
    </ModalContainer>
  );
};

export default ConfirmCalendarModal;
