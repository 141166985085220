import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  WebviewOperatorType,
  callWebviewHandler,
  isWebview,
} from 'utils/webview';
import { SettlementsEdges } from 'containers/SettlementList/graphQL/types';
import { SETTLEMENT_PATH } from 'constants/path';
import { HostBusinessInfoState } from 'graphQL/Auth/types';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import ConfirmDialog from 'components/Modal/ConfirmDialog';

interface SettlementValidationModalIProps {
  settlementItem: SettlementsEdges | undefined;
}

const SettlementValidationModal: React.FC<
  SettlementValidationModalIProps
> = props => {
  const { settlementItem } = props;
  const myInfo = useUserInformation((state: any) => state.userInfo);

  const hostInfo = myInfo.me.host?.settlement;

  const navigate = useNavigate();

  const renderMessageDialog = (): JSX.Element => {
    if (hostInfo?.businessInfo === null || hostInfo?.bankAccount === null) {
      return (
        <ConfirmDialog
          title="정산 정보 등록이 필요해요"
          description="정산 요청을 위해 먼저 호스트 정보(개인 또는 사업자)와 대금 입금을 위한 정산 계좌 등록이 필요합니다."
          onAcceptText="네, 등록할게요"
          onCloseText=" "
          onClose={() => {}}
          onAccept={() => {
            navigate(SETTLEMENT_PATH);
          }}
        />
      );
    }

    if (
      hostInfo?.businessInfo &&
      hostInfo.businessInfo.status !== HostBusinessInfoState.ENROLLED
    ) {
      return (
        <ConfirmDialog
          title="정산 정보 검수가 필요해요"
          description="정산정보 검수가 완료되어야 정산 내역 확인 및 지급 요청이 가능해요. 아직 검수 요청 전이라면 검수 요청을 먼저 진행해 주세요."
          onAcceptText="네, 확인했어요"
          onCloseText=" "
          onClose={() => {}}
          onAccept={() => {
            navigate(SETTLEMENT_PATH);
          }}
        />
      );
    }

    if (settlementItem === undefined) {
      return (
        <ConfirmDialog
          title="정산 받을 내역이 없어요"
          description="정산서는 정산 받을 내역이 있을 때 확인 가능하며, 정산서는 매주 화요일 새벽 3시에 업데이트 돼요."
          onAcceptText="네, 확인했어요"
          onCloseText=" "
          onClose={() => {}}
          onAccept={() => {
            if (isWebview()) {
              callWebviewHandler(WebviewOperatorType.CloseWebView);

              return;
            }
            navigate(SETTLEMENT_PATH);
          }}
        />
      );
    }

    return <></>;
  };

  return renderMessageDialog();
};

export default SettlementValidationModal;
