import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Value } from 'react-calendar/dist/cjs/shared/types';

import Row from 'components/common/Row';
import Column from 'components/common/Column';
import Text from 'components/common/Text';
import Divider from 'components/Divider';
import OptionButton from '../common/OptionButton';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import ModalContainer from '../common/ModalContainer';
import { ICON_CLOSE } from 'assets/icons';
import CustomCalendar from 'components/Calendar/Calendar';
import DateInput from 'components/Input/DateInput';
import { useParams } from 'react-router-dom';
import HackleManager from 'utils/hackle';

const OptionSettingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
  width: 100%;

  border-radius: 12px;
  border: 1px solid #dbdcdf;
  background: #f7f7f8;
`;

const DateBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
  padding: 8px 12px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;
  cursor: pointer;
`;

const OptionInput = styled.input`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 27px 8px 12px;
  width: 100%;

  text-align: right;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    justify-content: flex-end;
  }

  &:focus {
    border: 1.5px solid #1b1c1e;
  }
`;

interface ChapterFiveIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
}

export interface SaleTermParams {
  saleTermType: string;
  isOpenCalendarModal: boolean;
  saleType: string;
  endedType: string;
  startedAt: number;
  endedAt: number;
  isGrossPurchaseLimitation: boolean;
}

const ChapterFive: React.FC<ChapterFiveIProps> = props => {
  const { isMobile, productParamInput, handleOnChangeProductParamInput } =
    props;

  const params = useParams<{ id: string }>();

  const [saleTermParams, setSaleTermParams] = useState<SaleTermParams>({
    saleTermType: 'direct',
    isOpenCalendarModal: false,
    saleType: '',
    endedType: 'one',
    startedAt: 0,
    endedAt: 0,
    isGrossPurchaseLimitation: true,
  });
  const [schedule, setSchedule] = useState<number>(0);

  const today = new Date();
  const oneYearLater = new Date(today);
  oneYearLater.setFullYear(today.getFullYear() + 1);
  const threeYearsLater = new Date(today);
  threeYearsLater.setFullYear(today.getFullYear() + 3);
  const fiveYearsLater = new Date(today);
  fiveYearsLater.setFullYear(today.getFullYear() + 5);

  const handleChangeSaleTermParams = (key: string, value: any) => {
    setSaleTermParams({ ...saleTermParams, [key]: value });
  };

  const handleSetDuration = (value: Value) => {
    const date = new Date(value!.toString());

    const time = date.getTime();

    setSchedule(time);

    if (saleTermParams.saleType === 'start') {
      handleChangeSaleTermParams('startedAt', time);

      return;
    }

    handleChangeSaleTermParams('endedAt', time);
  };

  useEffect(() => {
    HackleManager.logCreateStepTwoSalesPeriodLimitView(
      params.id !== 'draft' ? Number(params.id) : undefined,
    );

    handleOnChangeProductParamInput('saleTerm', {
      ...productParamInput.saleTerm,
      startedAt: today.getTime(),
      endedAt: oneYearLater.getTime(),
    });
  }, []);

  const renderContents = (): JSX.Element => {
    return (
      <Column
        gap="32px"
        align="flex-start"
        style={{ padding: isMobile ? '0' : '32px 0' }}
      >
        <Column gap="8px" align="flex-start">
          <Text color="#1b1c1e" size="24px" style={{ lineHeight: '32px' }}>
            판매 기간과 1인당 주문 한도를 설정해 주세요.
          </Text>
          <Text color="#70737C" size="16px" weight={400}>
            아래의 기본 설정으로 다음 단계로 진행하셔도 괜찮습니다.
          </Text>
        </Column>
        {renderSaleType()}
        {renderGrossPurchaseLimitation()}
      </Column>
    );
  };

  const renderSaleType = (): JSX.Element => {
    return (
      <OptionSettingBox>
        <Column gap="12px" align="flex-start">
          <Text color="#1b1c1e" size="16px">
            판매 시작일
          </Text>
          <Row gap="12px" style={{ width: '100%' }}>
            <OptionButton
              text="검수 후 바로 판매"
              isSelected={saleTermParams.saleTermType === 'direct'}
              onClick={() => {
                handleChangeSaleTermParams('saleTermType', 'direct');

                handleOnChangeProductParamInput('saleTerm', {
                  ...productParamInput.saleTerm,
                  startedAt: today.getTime(),
                });
              }}
              style={{ background: '#fff' }}
            />
            <OptionButton
              text="날짜 지정"
              isSelected={saleTermParams.saleTermType === 'select'}
              onClick={() => {
                handleChangeSaleTermParams('saleTermType', 'select');
              }}
              style={{ background: '#fff' }}
            />
          </Row>
          {saleTermParams.saleTermType === 'select' && (
            <DateBox>
              <Row gap="2px">
                <Text
                  color={
                    productParamInput.saleTerm?.startedAt
                      ? '#1b1c1e'
                      : '#c2c4c8'
                  }
                  size="14px"
                  weight={400}
                >
                  {productParamInput.saleTerm?.startedAt
                    ? `${dayjs(productParamInput.saleTerm.startedAt).format(
                        'YY년 • M월 • D일',
                      )}`
                    : '년 • 월 • 일'}
                </Text>
                <DateInput
                  onChange={e => {
                    handleOnChangeProductParamInput('saleTerm', {
                      ...productParamInput.saleTerm,
                      startedAt: new Date(e.target.value).getTime(),
                    });
                  }}
                />
              </Row>
              <Text color="#1b1c1e" size="12px" weight={400}>
                00시 00분 부터
              </Text>
            </DateBox>
          )}
        </Column>
        <Divider />
        <Column gap="12px" align="flex-start">
          <Text color="#1b1c1e" size="16px">
            판매 종료일
          </Text>
          <Column gap="8px">
            <Row
              gap="12px"
              style={{
                width: '100%',
              }}
            >
              <OptionButton
                text="1년"
                isSelected={saleTermParams.endedType === 'one'}
                onClick={() => {
                  handleChangeSaleTermParams('endedType', 'one');

                  handleOnChangeProductParamInput('saleTerm', {
                    ...productParamInput.saleTerm,
                    endedAt: oneYearLater.getTime(),
                  });
                }}
                style={{ background: '#fff' }}
              />
              <OptionButton
                text="3년"
                isSelected={saleTermParams.endedType === 'three'}
                onClick={() => {
                  handleChangeSaleTermParams('endedType', 'three');

                  handleOnChangeProductParamInput('saleTerm', {
                    ...productParamInput.saleTerm,
                    endedAt: threeYearsLater.getTime(),
                  });
                }}
                style={{ background: '#fff' }}
              />
            </Row>
            <Row
              gap="12px"
              style={{
                width: '100%',
              }}
            >
              <OptionButton
                text="5년"
                isSelected={saleTermParams.endedType === 'five'}
                onClick={() => {
                  handleChangeSaleTermParams('endedType', 'five');

                  handleOnChangeProductParamInput('saleTerm', {
                    ...productParamInput.saleTerm,
                    endedAt: fiveYearsLater.getTime(),
                  });
                }}
                style={{ background: '#fff' }}
              />
              <OptionButton
                text="날짜 지정"
                isSelected={saleTermParams.endedType === 'select'}
                onClick={() => {
                  handleChangeSaleTermParams('endedType', 'select');
                  handleOnChangeProductParamInput('saleTerm', {
                    ...productParamInput.saleTerm,
                    endedAt: null,
                  });
                }}
                style={{ background: '#fff' }}
              />
            </Row>
          </Column>
          {saleTermParams.endedType === 'select' && (
            <DateBox>
              <Row gap="2px">
                <Text
                  color={
                    productParamInput.saleTerm?.endedAt ? '#1b1c1e' : '#c2c4c8'
                  }
                  size="14px"
                  weight={400}
                >
                  {productParamInput.saleTerm?.endedAt
                    ? `${dayjs(productParamInput.saleTerm.endedAt).format(
                        'YY년 • M월 • D일',
                      )}`
                    : '년 • 월 • 일'}
                </Text>
                <DateInput
                  onChange={e => {
                    handleOnChangeProductParamInput('saleTerm', {
                      ...productParamInput.saleTerm,
                      endedAt: new Date(e.target.value).getTime(),
                    });
                  }}
                />
              </Row>
              <Text color="#1b1c1e" size="12px" weight={400}>
                23시 59분 까지
              </Text>
            </DateBox>
          )}
        </Column>
      </OptionSettingBox>
    );
  };

  const renderGrossPurchaseLimitation = (): JSX.Element => {
    return (
      <OptionSettingBox>
        <Column gap="12px" align="flex-start">
          <Text color="#1b1c1e" size="16px">
            1인당 재주문 한도
          </Text>
          <Row gap="12px" style={{ width: '100%' }}>
            <OptionButton
              text="제한하지않음"
              isSelected={saleTermParams.isGrossPurchaseLimitation}
              onClick={() => {
                handleChangeSaleTermParams('isGrossPurchaseLimitation', true);
              }}
              style={{ background: '#fff' }}
            />
            <OptionButton
              text="제한"
              isSelected={!saleTermParams.isGrossPurchaseLimitation}
              onClick={() => {
                handleChangeSaleTermParams('isGrossPurchaseLimitation', false);
              }}
              style={{ background: '#fff' }}
            />
          </Row>
        </Column>
        {!saleTermParams.isGrossPurchaseLimitation && (
          <Row style={{ position: 'relative', width: '100%' }}>
            <Text
              color="#1b1c1e"
              size="14px"
              weight={400}
              style={{ position: 'absolute', left: '12px' }}
            >
              최대
            </Text>
            <OptionInput
              placeholder="0"
              type="number"
              onChange={(e: any) => {
                handleOnChangeProductParamInput('grossPurchaseLimitation', {
                  count: Number(e.target.value),
                });
              }}
            />
            <Text
              color="#1b1c1e"
              size="14px"
              weight={400}
              style={{ position: 'absolute', right: '12px' }}
            >
              개
            </Text>
          </Row>
        )}
      </OptionSettingBox>
    );
  };

  const renderCalendarModal = (): JSX.Element => {
    return (
      <ModalContainer
        isMobile={isMobile}
        isAbled={schedule !== 0}
        onClose={() => handleChangeSaleTermParams('isOpenCalendarModal', false)}
        onClick={() => {
          handleChangeSaleTermParams('isOpenCalendarModal', false);
          setSchedule(0);

          if (saleTermParams.saleType === 'start') {
            handleOnChangeProductParamInput('saleTerm', {
              ...productParamInput.saleTerm,
              startedAt: saleTermParams.startedAt,
            });

            return;
          }

          handleOnChangeProductParamInput('saleTerm', {
            ...productParamInput.saleTerm,
            endedAt: saleTermParams.endedAt,
          });

          return;
        }}
      >
        <Row style={{ padding: '16px 0', position: 'relative', width: '100%' }}>
          <img
            src={ICON_CLOSE}
            alt="close icon"
            style={{ position: 'absolute', left: '20px', cursor: 'pointer' }}
            onClick={() =>
              handleChangeSaleTermParams('isOpenCalendarModal', false)
            }
          />
          <Text color="#1b1c1e" size="16px" weight={600}>
            일정 선택
          </Text>
        </Row>
        <CustomCalendar onChange={handleSetDuration} />
      </ModalContainer>
    );
  };

  return (
    <React.Fragment>
      {renderContents()}
      {saleTermParams.isOpenCalendarModal && renderCalendarModal()}
    </React.Fragment>
  );
};

export default ChapterFive;
