import React from 'react';
import styled from 'styled-components';
import { ICON_CLOSE } from 'assets/icons';

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 25;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 768px;
  width: 100%;
  height: 92%;

  background-color: #fff;
  border-radius: 16px 16px 0 0;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 16px 20px;
  width: 100%;

  border-radius: 16px 16px 0 0;
  border-bottom: 1px solid #ddd;
  background: #fff;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 24px 0px;
  width: 100%;
  overflow: auto;
  background-color: #fff;
`;

interface CommonModalContainerIProps {
  children: React.ReactNode;
  onClose: () => void;
}

const CommonModalContainer: React.FC<CommonModalContainerIProps> = (props) => {
  const { children, onClose } = props;

  return (
    <Container>
      <ModalContainer>
        <HeaderContainer>
          <img src={ICON_CLOSE} alt="close icon" onClick={onClose} />
        </HeaderContainer>
        <ContentContainer>{children}</ContentContainer>
      </ModalContainer>
    </Container>
  );
};

export default CommonModalContainer;
