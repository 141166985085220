import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MeData } from 'graphQL/Auth/types';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import HackleManager from 'utils/hackle';
import { formatOrderStatus } from 'utils/hackle/parameterFormatting';
import { useQueryGetOrders } from '../graphQL/hooks/useQueryGetOrders';
import { OrderFilterInput } from '../graphQL/types';

export interface SaleFilterValue {
  searchValue: string;
  termType: string;
  searchType: string;
}

export interface TermWithType {
  startedAt: number;
  endedAt: number;
}

export const useSaleListHooks = () => {
  const location = useLocation();
  const myInfo: MeData = useUserInformation(state => state.userInfo);

  const { orderItems, isLoading, setIsLoading, handleRefetchOrderItems } =
    useQueryGetOrders();
  const [orderFilterInput, setOrderFilterInput] = useState<OrderFilterInput>({
    page: 1,
    size: 10,
    filter: {
      hostIds: myInfo.me.host?.id,
      productIds: location.state ? [location.state.productId] : null,
      productTitleLike: null,
      customerIds: null,
      itemNameLike: null,
      itemIds: null,
      emailLike: null,
      nameLike: null,
      phoneLike: null,
      statusIn: null,
      transactionStatusIn: null,
    },
  });

  const [isAcceptFilter, setIsAcceptFilter] = useState<boolean>(
    location.state ? true : false,
  );
  const [isOpenCountFilter, setIsOpenCountFilter] = useState<boolean>(false);
  const [isOpenSearchModal, setIsOpenSearchModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>('');
  const [filterValue, setFilterValue] = useState<SaleFilterValue>({
    searchValue: location.state ? location.state.productId : '',
    termType: 'all',
    searchType: location.state ? 'fripId' : 'productName',
  });
  const [termWithType, setTermWithType] = useState<TermWithType | null>(null);

  const handleSetFilterValue = (key: string, value: any) => {
    setFilterValue({ ...filterValue, [key]: value });
  };

  const handleSetTermWithType = (type: string) => {
    const today = new Date();

    switch (type) {
      case 'all': {
        setTermWithType(null);

        return;
      }
      case '1month': {
        const startedAt = new Date(today);
        startedAt.setMonth(startedAt.getMonth() - 1);

        setTermWithType({
          startedAt: startedAt.getTime(),
          endedAt: today.getTime(),
        });

        return;
      }
      case '6month': {
        const startedAt = new Date(today);
        startedAt.setMonth(startedAt.getMonth() - 1);

        setTermWithType({
          startedAt: startedAt.getTime(),
          endedAt: today.getTime(),
        });

        return;
      }
      case '1year': {
        const startedAt = new Date(today);
        startedAt.setFullYear(startedAt.getFullYear() - 1);

        setTermWithType({
          startedAt: startedAt.getTime(),
          endedAt: today.getTime(),
        });

        return;
      }
      case '5year': {
        const startedAt = new Date(today);
        startedAt.setFullYear(startedAt.getFullYear() - 5);

        setTermWithType({
          startedAt: startedAt.getTime(),
          endedAt: today.getTime(),
        });

        return;
      }
      default:
        return;
    }
  };

  const handleSetOrderFilterInput = (key: string, value: any) => {
    setOrderFilterInput({ ...orderFilterInput, page: 1, [key]: value });
  };

  const handleIsOpenCountFilter = () => {
    setIsOpenCountFilter(!isOpenCountFilter);
  };

  const handleResetFilterValue = () => {
    setFilterValue({
      searchValue: '',
      termType: 'all',
      searchType: 'productName',
    });
  };

  useEffect(() => {
    if (orderItems) {
      handleRefetchOrderItems(orderFilterInput);
      setSelectedId('');
      setIsLoading(true);

      HackleManager.logSalesView(
        orderItems.totalCount,
        orderFilterInput.page,
        formatOrderStatus(orderFilterInput.filter.statusIn),
      );
    }
  }, [orderFilterInput]);

  return {
    orderItems,
    isLoading,
    orderFilterInput,
    isOpenCountFilter,
    isOpenSearchModal,
    selectedId,
    filterValue,
    termWithType,
    isAcceptFilter,
    handleSetTermWithType,
    handleSetFilterValue,
    setSelectedId,
    handleSetOrderFilterInput,
    setIsOpenSearchModal,
    handleIsOpenCountFilter,
    handleResetFilterValue,
    setIsAcceptFilter,
  };
};
