import { ApolloError, useMutation } from '@apollo/client';
import { PRODUCT_LIST_PATH } from 'constants/path';
import { GraphQLError } from 'graphql';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { errorHandler } from 'utils/ErrorHandler';
import HackleManager from 'utils/hackle';
import { REQUEST_INSPECTION } from '..';

export function useMutationRequestInspection() {
  const navigate = useNavigate();
  const [requestInspection] = useMutation(REQUEST_INSPECTION);

  const [isOpenModal, setisOpenModal] = useState<{
    success: boolean;
    failed: boolean;
  }>({ success: false, failed: false });
  const [errorMessage, setErrorMessage] = useState<string[]>([]);

  const handleRequestInspection = async (productId: string) => {
    try {
      const response = await requestInspection({ variables: { productId } });

      if (response) {
        setisOpenModal({ success: true, failed: false });
        // navigate(PRODUCT_LIST_PATH);

        HackleManager.logCreateStepFourReviewRequestCompleteView(
          Number(productId),
        );
      }
    } catch (error) {
      if (error instanceof ApolloError) {
        const graphQLError = error.graphQLErrors?.[0]; // 첫 번째 GraphQL 에러 가져오기
        if (graphQLError) {
          setisOpenModal({ success: false, failed: true });

          setErrorMessage(
            (graphQLError.extensions?.exception as any).errors.map(
              item => item.message,
            ),
          );
        }
      }
    }
  };

  return { isOpenModal, errorMessage, handleRequestInspection };
}
