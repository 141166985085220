import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { ICON_ARROW_BLACK } from 'assets/icons';
import Column from 'components/common/Column';
import CommonImage from 'components/common/CommonImage';
import Text from 'components/common/Text';
import { dateFormattingForm } from 'utils/formatting';
import { NoticeEdgesEntity, NoticeType } from '../graphQL/types';
import NoticeStatusButton from './NoticeStatusButton';
import { useNavigate } from 'react-router-dom';
import { NOTICE_DETAIL_PATH } from 'constants/path';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* border-radius: 12px; */
  /* border: 1px solid var(--gray-gray-04, #dbdcdf); */
  /* background: var(--gray-gray-01, #f7f7f8); */
`;

interface NoticeCardIProps {
  item: NoticeEdgesEntity;
  type: 'list' | 'detail';
}

const NoticeCard: React.FC<NoticeCardIProps> = props => {
  const navigate = useNavigate();
  const { item, type } = props;

  return (
    <Container
      onClick={() => {
        if (type === 'list') {
          navigate(`${NOTICE_DETAIL_PATH}/${item.node.id}`);
        }
      }}
    >
      <Column gap="8px" align="flex-start">
        <NoticeStatusButton status={item.node.type} />
        <Column align="flex-start" gap="4px">
          <Text color="#46474c" size="16px">
            {item.node.title}
          </Text>
          <Text color="#1b1c1e" weight={400}>
            {dayjs(item.node.createdAt).format('YYYY-MM-DD')}
          </Text>
        </Column>
      </Column>
      {type === 'list' && (
        <CommonImage
          src={ICON_ARROW_BLACK}
          onClick={() => {
            navigate(`${NOTICE_DETAIL_PATH}/${item.node.id}`);
          }}
        />
      )}
    </Container>
  );
};

export default NoticeCard;
