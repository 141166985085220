import React, { useState } from 'react';
import ModifyContainer from '../ModifyContainer';

import Text from 'components/common/Text';
import Column from 'components/common/Column';
import { ModifyValue } from '../../hooks/useProductDetaillHooks';

interface ProductTitleContainerIProps {
  isMobile: boolean;
  title: string;
  modifyValue: ModifyValue;
  setModifyValue: React.Dispatch<React.SetStateAction<ModifyValue>>;
}

const ProductTitleContainer: React.FC<ProductTitleContainerIProps> = (
  props
) => {
  const { isMobile, title, modifyValue, setModifyValue } = props;

  return (
    <ModifyContainer
      title="프립명"
      isSelected={!isMobile && modifyValue.modifyStatus === 'title'}
      onClick={() => {
        setModifyValue({ ...modifyValue, modifyStatus: 'title' });
      }}
    >
      <Column align="flex-start">
        <Text color="#46474c" size="14px" weight={400}>
          {title}
        </Text>
      </Column>
    </ModifyContainer>
  );
};

export default ProductTitleContainer;
