import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import {
  PlanParam,
  ProductParamInput,
} from 'containers/ProductRegist/hooks/types';
import { ModifyValue } from 'containers/ProductDetail/hooks/useProductDetaillHooks';
import UpdateCalendar from './UpdateCalendar';
import {
  OptionSheetType,
  useCalendarHooks,
} from 'containers/ProductRegist/components/OptionSelectRegist/hooks/useCalenderHooks';
import Text from 'components/common/Text';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import SelectDateModal from 'containers/ProductRegist/components/OptionSelectRegist/components/SelectDateModal';
import CalendarModifyForm from './CalendarModifyForm';
import CalendarCreateForm from './CalendarCreateForm';
import SaveButton from '../SaveButton';
import { ICON_PRODUCT_CHECKMARK_BLACK } from 'assets/icons';
import { Product, ProductState } from 'types/api';
import HackleManager from 'utils/hackle';

const SelectDateButton = styled.button`
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 8px 12px;

  border-radius: 99px;
  border: 1px solid var(--gray-gray-09, #dbdcdf);
  background: var(--Static-White, #fff);
`;

interface CalendarModifyContainerIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  productInfo: Product;
  modifyValue: ModifyValue;
  setModifyValue: Dispatch<SetStateAction<ModifyValue>>;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
  handleGetProductScheduleTerm: () => void;
}

const CalendarModifyContainer: React.FC<
  CalendarModifyContainerIProps
> = props => {
  const {
    isMobile,
    productParamInput,
    productInfo,
    modifyValue,
    setModifyValue,
    handleOnChangeProductParamInput,
    handleGetProductScheduleTerm,
  } = props;

  const [copyProductParamInput, setCopyProductParamInput] =
    useState<ProductParamInput>(productParamInput);

  const isAbled =
    productParamInput.frip.schedules !== copyProductParamInput.frip.schedules;

  const handleOnChangeCopyProductParamInput = (key: string, value: any) => {
    setCopyProductParamInput({ ...copyProductParamInput, [key]: value });
  };

  const {
    scheduleItem,
    optionSheetType,
    checkedDate,
    isOpenOptionBottomSheet,
    isClearCalendarData,
    isOpenSelectDateModal,
    setScheduleItem,
    setCheckedDate,
    setIsOpenSelectDateModal,
    setIsOpenOptionBottomSheet,
    handleSetAddSetCheckedDate,
    handleChangeScheduleItem,
    handleClearCalenderData,
    handleIsOpenUpdateOptionSheet,
    handleInitScheduleItem,
    handleGetSpecificDaysInRange,
  } = useCalendarHooks(
    copyProductParamInput,
    handleOnChangeCopyProductParamInput,
  );

  const saveRef = useRef<HTMLDivElement>(null);

  const handleSetPlanParam = (planParam: PlanParam) => {
    // handleOnChangeCopyProductParamInput('frip', {
    //   ...copyProductParamInput.frip,
    //   plans: [...copyProductParamInput.frip!.plans, planParam],
    // });
  };

  const handleResetSchedules = () => {
    setCopyProductParamInput(productParamInput);
  };

  const handleRegistSchedules = () => {
    handleOnChangeProductParamInput('frip', {
      ...productParamInput.frip,
      schedules: copyProductParamInput.frip.schedules,
    });
  };

  const handleNavigateSaveButton = useCallback(() => {
    if (!isMobile) {
      saveRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, [saveRef]);

  useEffect(() => {
    setCopyProductParamInput(productParamInput);
  }, [productParamInput]);

  useEffect(() => {
    HackleManager.logProductModifyScheduleSettingView(
      Number(productInfo.id),
      productInfo.title,
    );
  }, []);

  const renderSelectDateForm = (): JSX.Element => {
    return (
      <Row gap="12px" style={{ padding: isMobile ? '0 20px' : 0 }}>
        <SelectDateButton
          onClick={() => {
            if (checkedDate.length > 0) {
              setCheckedDate([]);
            }

            setIsOpenSelectDateModal(true);
          }}
        >
          <img
            src={ICON_PRODUCT_CHECKMARK_BLACK}
            alt="icon product checkmark"
          />
          <Text color="#1b1c1e" size="14px">
            날짜 일괄 선택
          </Text>
        </SelectDateButton>
        <SelectDateButton
          onClick={() => {
            if (checkedDate.length > 0) {
              setCheckedDate([]);
            }
          }}
        >
          <Text
            color={checkedDate.length > 0 ? '#1b1c1e' : '#dbdcdf'}
            size="14px"
          >
            날짜 선택 취소
          </Text>
        </SelectDateButton>
      </Row>
    );
  };

  const renderContents = (): JSX.Element => {
    if (isMobile) {
      return (
        <ModalContainer
          isAbled={productInfo.status !== ProductState.CLOSED && isAbled}
          isMobile={isMobile}
          onAcceptText="적용"
          onClose={() => {
            HackleManager.logProductModifyItemSaveComplete(
              Number(productInfo.id),
              productInfo.title,
              '판매 일정',
            );
            setModifyValue({ ...modifyValue, modifyStatus: '' });
          }}
          onClick={() => {
            handleRegistSchedules();

            setModifyValue({
              ...modifyValue,
              isModify: true,
              modifyStatus: '',
            });
          }}
        >
          <Column gap="8px">
            <Row
              style={{
                position: 'sticky',
                top: 0,
                padding: '16px 20px',
                width: '100%',
                background: '#fff',
              }}
            >
              <Text color="#000" size="16px" weight={600}>
                판매 일정 수정하기
              </Text>
            </Row>
            <Column gap="24px" align="flex-start">
              {renderSelectDateForm()}
              <UpdateCalendar
                calendarList={copyProductParamInput.frip?.schedules}
                isClearCalendarData={isClearCalendarData}
                checkedDate={checkedDate}
                setCheckedDate={setCheckedDate}
                handleIsOpenUpdateOptionSheet={handleIsOpenUpdateOptionSheet}
                onChange={handleSetAddSetCheckedDate}
                handleInitScheduleItem={handleInitScheduleItem}
              />
            </Column>
          </Column>
          {renderUpdateCalendar()}
        </ModalContainer>
      );
    }

    return (
      <Row
        align="flex-start"
        style={{
          width: '100%',
          minWidth:
            isOpenOptionBottomSheet || checkedDate.length > 0
              ? '825px'
              : '100%',
          overflowX: 'scroll',
        }}
      >
        <Column>
          <Row
            align="center"
            justifyContent="space-between"
            style={{
              position: 'sticky',
              top: 0,
              padding: '23px 40px',
              width: '100%',
              background: '#fff',
              // borderBottom: '1px solid #eaebec',
            }}
          >
            <Text color="#1B1C1E" size="24px">
              일정 수정하기
            </Text>
            {renderSelectDateForm()}
          </Row>
          <Column
            gap="24px"
            style={{
              padding: '0 0 32px',
              borderRight: '1px solid #eaebec',
            }}
          >
            <UpdateCalendar
              calendarList={copyProductParamInput.frip?.schedules}
              isClearCalendarData={isClearCalendarData}
              checkedDate={checkedDate}
              setCheckedDate={setCheckedDate}
              handleIsOpenUpdateOptionSheet={handleIsOpenUpdateOptionSheet}
              onChange={handleSetAddSetCheckedDate}
              handleInitScheduleItem={handleInitScheduleItem}
            />
            <div ref={saveRef} />
            {productInfo.status !== ProductState.CLOSED && (
              <SaveButton
                isAbled={isAbled}
                modifyValue={modifyValue}
                setModifyValue={setModifyValue}
                handleResetItem={handleResetSchedules}
                handleRegistItem={() => {
                  HackleManager.logProductModifyItemSaveComplete(
                    Number(productInfo.id),
                    productInfo.title,
                    '판매 일정',
                  );
                  handleRegistSchedules();
                }}
              />
            )}
          </Column>
        </Column>
        {renderUpdateCalendar()}
      </Row>
    );
  };

  const renderUpdateCalendar = (): JSX.Element => {
    return (
      <React.Fragment>
        {checkedDate.length > 0 &&
          optionSheetType === OptionSheetType.CREATE && (
            <CalendarCreateForm
              productParamInput={copyProductParamInput}
              scheduleItem={scheduleItem}
              checkedDate={checkedDate}
              isMobile={isMobile}
              items={copyProductParamInput.items}
              optionSheetType={optionSheetType}
              setScheduleItem={setScheduleItem}
              setCheckedDate={setCheckedDate}
              handleOnChangeProductParamInput={
                handleOnChangeCopyProductParamInput
              }
              handleClearCalenderData={handleClearCalenderData}
              handleChangeScheduleItem={handleChangeScheduleItem}
              handleInitScheduleItem={handleInitScheduleItem}
              handleNavigateSaveButton={handleNavigateSaveButton}
            />
          )}
        {isOpenOptionBottomSheet &&
          optionSheetType === OptionSheetType.UPDATE && (
            <CalendarModifyForm
              productParamInput={copyProductParamInput}
              scheduleItem={scheduleItem}
              checkedDate={checkedDate}
              isMobile={isMobile}
              items={copyProductParamInput.items}
              optionSheetType={optionSheetType}
              setScheduleItem={setScheduleItem}
              setCheckedDate={setCheckedDate}
              handleOnChangeProductParamInput={
                handleOnChangeCopyProductParamInput
              }
              handleClearCalenderData={handleClearCalenderData}
              handleChangeScheduleItem={handleChangeScheduleItem}
              handleInitScheduleItem={handleInitScheduleItem}
              handleOnCloseOptionModal={() => setIsOpenOptionBottomSheet(false)}
              handleGetProductScheduleTerm={handleGetProductScheduleTerm}
              handleNavigateSaveButton={handleNavigateSaveButton}
            />
          )}
        {isOpenSelectDateModal && (
          <SelectDateModal
            isMobile={isMobile}
            onClose={() => setIsOpenSelectDateModal(false)}
            handleGetSpecificDaysInRange={handleGetSpecificDaysInRange}
            handleSetPlanParam={handleSetPlanParam}
          />
        )}
      </React.Fragment>
    );
  };

  return renderContents();
};

export default CalendarModifyContainer;
