import React, { useState } from 'react';

import { MeData } from 'graphQL/Auth/types';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import ModifyContainer from '../ModifyContainer';
import Column from 'components/common/Column';
import Text from 'components/common/Text';
import ProfileThumbnail from 'components/common/ProfileThumbnail';
import { ModifyValue } from '../../hooks/useProductDetaillHooks';

interface ProfileContainerIProps {
  isMobile: boolean;
  modifyValue: ModifyValue;
  setModifyValue: React.Dispatch<React.SetStateAction<ModifyValue>>;
}

const ProfileContainer: React.FC<ProfileContainerIProps> = props => {
  const { isMobile, modifyValue, setModifyValue } = props;

  const myInfo: MeData = useUserInformation((state: any) => state.userInfo);

  return (
    <ModifyContainer
      title="호스트 프로필"
      isSelected={!isMobile && modifyValue.modifyStatus === 'profile'}
      onClick={() => {
        setModifyValue({ ...modifyValue, modifyStatus: 'profile' });
      }}
    >
      <Column gap="12px">
        <ProfileThumbnail style={{ width: '56px', height: '56px' }} />
        <Text color="#46474c" size="14px" weight={400}>
          {myInfo.me.host.name}
        </Text>
      </Column>
      {/* {isOpenModifyModal && renderModalContainer()} */}
    </ModifyContainer>
  );
};

export default ProfileContainer;
