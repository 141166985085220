import React from 'react';
import styled from 'styled-components';
import PlaceForm from './PlaceForm';
import Text from 'components/common/Text';
import { FripLocation } from 'types/api';
import Divider from 'components/Divider';
import { FripLocationParam } from 'containers/ProductRegist/hooks/types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 24px;
  width: 100%;
`;

interface GetherPlaceContainerIProps {
  locationOfGathering: FripLocationParam;
}

const GatherPlaceContainer: React.FC<GetherPlaceContainerIProps> = (props) => {
  const { locationOfGathering } = props;

  return (
    <React.Fragment>
      <Divider style={{ height: '8px', background: '#F4F4F4' }} />
      <Container>
        <Text color="#000" size="18px" weight={700}>
          모이는 장소
        </Text>
        <PlaceForm item={locationOfGathering} />
      </Container>
    </React.Fragment>
  );
};

export default GatherPlaceContainer;
