import { gql } from '@apollo/client';

export const CREATE_HOST = gql`
  mutation CreateHost($param: HostParam!) {
    registHost(param: $param) {
      id
    }
  }
`;

export const CHANGE_NOTICE_RECEIVER_AGREEMENT = gql`
  mutation ChangeNoticeReceiveAgreement($params: [NoticeReceiveParam!]!) {
    changeNoticeReceiveAgreement(noticeReceiveParams: $params) {
      __typename
      success
      message
    }
  }
`;
