import Text from 'components/common/Text';
import React from 'react';
import styled from 'styled-components';

import { HostVerifiedBusinessParamInput, TaxPayerType } from '../graphQL/type';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 40px 20px;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const SelectContainer = styled.div<{ $isChecked: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 100%;
  border-radius: 12px;
  border: 1.5px solid ${(props) => (props.$isChecked ? '#1b1c1e' : '#aaa')};
  background: ${(props) => (props.$isChecked ? '#f4f4f5' : '#fff')};
  cursor: pointer;

  span {
    color: #1b1c1e;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 131.25% */
    letter-spacing: -0.16px;
  }
`;

const RadioBox = styled.div<{ $isChecked: boolean }>`
  width: 24px;
  height: 24px;
  appearance: none;
  border: ${(props) =>
    props.$isChecked ? '8px solid #1b1c1e' : '3px solid #eee'};
  border-radius: 50%;
  cursor: pointer;
`;

interface HostInformationIProps {
  businessInformation: HostVerifiedBusinessParamInput;
  handleSetBusinessInformation: (target: string, value: string) => void;
}

const HostInformation: React.FC<HostInformationIProps> = (props) => {
  const { businessInformation, handleSetBusinessInformation } = props;

  return (
    <Container>
      <Text color="#1b1c1e" size="24px">
        호스트 유형을 선택해 주세요
      </Text>
      <Column>
        <SelectContainer
          $isChecked={
            businessInformation.taxPayerType === TaxPayerType.INDIVIDUAL
          }
          onClick={() => {
            handleSetBusinessInformation(
              'taxPayerType',
              TaxPayerType.INDIVIDUAL
            );
          }}
        >
          <span>개인</span>
          <RadioBox
            $isChecked={
              businessInformation.taxPayerType === TaxPayerType.INDIVIDUAL
            }
            onClick={() =>
              handleSetBusinessInformation(
                'taxPayerType',
                TaxPayerType.INDIVIDUAL
              )
            }
          />
        </SelectContainer>
        <SelectContainer
          $isChecked={
            businessInformation.taxPayerType === TaxPayerType.CORPORATION
          }
          onClick={() =>
            handleSetBusinessInformation(
              'taxPayerType',
              TaxPayerType.CORPORATION
            )
          }
        >
          <span>사업자</span>
          <RadioBox
            $isChecked={
              businessInformation.taxPayerType === TaxPayerType.CORPORATION
            }
            onClick={() =>
              handleSetBusinessInformation(
                'taxPayerType',
                TaxPayerType.CORPORATION
              )
            }
          />
        </SelectContainer>
      </Column>
    </Container>
  );
};

export default HostInformation;
