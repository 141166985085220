import React from 'react';
import styled from 'styled-components';

import { useQueryGetBankInformation } from '../graphQL/hooks/useQueryGetBankInformation';
import { ICON_CLOSE, ICON_ARROW_BLACK } from 'assets/icons';
import Text from 'components/common/Text';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 92%;

  background-color: #fff;
  border-radius: 16px 16px 0 0;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  padding: 16px 20px;
  width: 100%;

  border-radius: 16px 16px 0 0;
  border-bottom: 1px solid #ddd;
  background: #fff;

  img {
    position: absolute;
    left: 20px;
  }
`;

const Title = styled.div`
  color: #1b1c1e;
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.096px;
`;

const BankListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: auto;
  background-color: #fff;
`;

const BankItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
  z-index: 11;
`;

interface BankListModalIProps {
  onClose: () => void;
  handleAcceptBankCode: (code: string) => void;
  setBankName: React.Dispatch<React.SetStateAction<string>>;
}

const BankListModal: React.FC<BankListModalIProps> = (props) => {
  const { onClose, handleAcceptBankCode, setBankName } = props;
  const { bankInformation, isLoading } = useQueryGetBankInformation();

  return (
    <Container>
      <ModalContainer>
        <HeaderContainer>
          <img src={ICON_CLOSE} alt="close icon" onClick={onClose} />
          <Title>은행 선택</Title>
        </HeaderContainer>
        <BankListContainer>
          {!isLoading &&
            bankInformation.settlement.banks.map((item: any, idx: number) => (
              <BankItem
                key={idx}
                onClick={(e) => {
                  e.preventDefault();
                  handleAcceptBankCode(item.code);
                  setBankName(item.name);
                  onClose();
                }}
              >
                <Text color="#1b1c1e" size="16px" weight={400}>
                  {item.name}
                </Text>
                <img src={ICON_ARROW_BLACK} alt="arrow icon" />
              </BankItem>
            ))}
        </BankListContainer>
      </ModalContainer>
    </Container>
  );
};

export default BankListModal;
