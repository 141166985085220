import Row from 'components/common/Row';
import React from 'react';
import styled from 'styled-components';
import { Product, ProductKind } from 'types/api';
import {
  ADDRESS_MOCK,
  CATEGORY_MOCK,
  GuideMockData,
  HOSTING_MOCK,
  KIND_MOCK,
  ONLINE_MOCK,
  SHIP_MOCK,
} from '../hooks/GuideMock';
import { ProductParamInput } from '../hooks/types';
import { RegistPageNumber } from '../hooks/useProductRegistHooks';
import ChapterFive from './BasicInformation/ChapterFive';
import ChapterFour from './BasicInformation/ChapterFour';
import ChapterOne from './BasicInformation/ChapterOne';
import ChapterThree from './BasicInformation/ChapterThree';
import ChapterTwo from './BasicInformation/ChapterTwo';
import GuideContainer from './GuideContainer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  width: 100%;
  max-width: 1280px;
  /* min-height: 81vh; */
  overflow-y: scroll;
`;

interface BasicInformationRegistIProps {
  isMobile: boolean;
  height: number;
  productParamInput: ProductParamInput;
  registPageNumber: RegistPageNumber;
  imageThumbnail: string[];
  isOpenGuideModal: boolean;
  setProductParamInput: React.Dispatch<React.SetStateAction<ProductParamInput>>;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
  handleOpenGuideModal: (isOpen: boolean) => void;
}

const BasicInformationRegist: React.FC<
  BasicInformationRegistIProps
> = props => {
  const {
    isMobile,
    height,
    productParamInput,
    registPageNumber,
    imageThumbnail,
    isOpenGuideModal,
    setProductParamInput,
    handleOnChangeProductParamInput,
    handleOpenGuideModal,
  } = props;

  const renderBasicInformation = (): JSX.Element => {
    switch (registPageNumber.basicPageNumber.page) {
      case 0: {
        return (
          <ChapterOne
            isMobile={isMobile}
            productParamInput={productParamInput}
            imageThumbnail={imageThumbnail}
            handleOnChangeProductParamInput={handleOnChangeProductParamInput}
          />
        );
      }
      case 1: {
        return (
          <ChapterTwo
            isMobile={isMobile}
            productParamInput={productParamInput}
            setProductParamInput={setProductParamInput}
          />
        );
      }
      case 2: {
        return (
          <ChapterThree
            isMobile={isMobile}
            productParamInput={productParamInput}
            setProductParamInput={setProductParamInput}
            handleOnChangeProductParamInput={handleOnChangeProductParamInput}
          />
        );
      }
      case 3: {
        return (
          <ChapterFour
            isMobile={isMobile}
            productParamInput={productParamInput}
            handleOnChangeProductParamInput={handleOnChangeProductParamInput}
          />
        );
      }
      case 4: {
        return (
          <ChapterFive
            isMobile={isMobile}
            productParamInput={productParamInput}
            handleOnChangeProductParamInput={handleOnChangeProductParamInput}
          />
        );
      }
    }

    return <></>;
  };

  const getMockData = (): GuideMockData[] => {
    switch (registPageNumber.basicPageNumber.page) {
      case 1: {
        return HOSTING_MOCK;
      }
      case 2: {
        if (productParamInput.kind === ProductKind.GOODS) {
          return SHIP_MOCK;
        }
        return KIND_MOCK;
      }
      case 3: {
        if (productParamInput.kind === ProductKind.ONLINE) {
          return ONLINE_MOCK;
        }
        return ADDRESS_MOCK;
      }
      case 4: {
        return CATEGORY_MOCK;
      }
    }
  };

  return (
    <Container
      style={{
        justifyContent:
          isMobile || registPageNumber.basicPageNumber.page !== 0
            ? 'flex-start'
            : 'center',
        padding: isMobile ? '20px 20px 64px 20px' : '0 40px',
        height: `${height - 174}px`,
      }}
    >
      {isMobile ? (
        <React.Fragment>
          {renderBasicInformation()}
          {isOpenGuideModal && (
            <GuideContainer
              isMobile={isMobile}
              mockData={getMockData()}
              onClose={() => handleOpenGuideModal(false)}
            />
          )}
        </React.Fragment>
      ) : (
        <Row style={{ width: '100%' }} align="flex-start">
          {registPageNumber.basicPageNumber.page > 0 ? (
            <React.Fragment>
              <Row
                align="flex-start"
                style={{
                  padding: '0 40px',
                  position: 'sticky',
                  top: '89px',
                  overflowY: 'scroll',
                  width: '100%',
                  height: `${height - 174}px`,
                  borderLeft: '1px solid #eaebec',
                  borderRight: '1px solid #EAEBEC',
                  zIndex: 11,
                }}
              >
                {renderBasicInformation()}
              </Row>
              <Row
                align="flex-start"
                style={{
                  padding: '0 0 60px',
                  position: 'sticky',
                  top: '89px',
                  overflowY: 'scroll',
                  minWidth: '375px',
                  maxWidth: '375px',
                  height: `${height - 174}px`,
                  borderLeft: '1px solid #eaebec',
                  borderRight: '1px solid #EAEBEC',
                }}
              >
                <GuideContainer
                  isMobile={isMobile}
                  mockData={getMockData()}
                  onClose={() => handleOpenGuideModal(false)}
                />
              </Row>
            </React.Fragment>
          ) : (
            renderBasicInformation()
          )}
          {/* {registPageNumber.basicPageNumber.page !== 0 && (
            <PreviewProductDetail
              isMobile={isMobile}
              productParamInput={productParamInput}
              imageThumbnail={imageThumbnail}
            />
          )} */}
        </Row>
      )}
    </Container>
  );
};

export default BasicInformationRegist;
