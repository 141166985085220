import { gql } from '@apollo/client';

export const GET_SETTLEMENT_LIST_BY_FILTER = gql`
  query GetSettlementListByFilter(
    $filter: SettlementFilterInput
    $page: Int
    $size: Int
  ) {
    settlement {
      settlements(filter: $filter, page: $page, size: $size) {
        totalCount
        pageInfo {
          hasNextPage
          __typename
        }
        edges {
          node {
            id
            periodCode
            title
            totalPayment {
              value
              currencyCode
              __typename
            }
            totalFee {
              value
              currencyCode
              __typename
            }
            host {
              id
              name
              __typename
            }
            startedDate
            endedDate
            invoice {
              id
              title
              status
              createdAt
              completedAt
              __typename
            }
            createdAt
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const REQUEST_INVOICE = gql`
  mutation RequestInvoice($param: InvoiceParamInput!) {
    requestInvoice(param: $param) {
      id
      status
      __typename
      settlements {
        id
        periodCode
        title
        totalPayment {
          value
          currencyCode
          __typename
        }
        totalFee {
          value
          currencyCode
          __typename
        }
        host {
          id
          name
          __typename
        }
        startedDate
        endedDate
        invoice {
          id
          title
          status
          createdAt
          completedAt
          __typename
        }
        createdAt
      }
    }
  }
`;
