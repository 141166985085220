import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import RegistDescriptionForm from '../common/RegistDescriptionForm';

import Text from 'components/common/Text';
import Divider from 'components/Divider';
import Row from 'components/common/Row';
import Column from 'components/common/Column';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import {
  IMAGE_PRODUCT_INVITE,
  IMAGE_PRODUCT_INVITE_BASIC,
  IMAGE_PRODUCT_INVITE_CELL,
} from 'assets/images';
import { isEmpty } from 'lodash';
import { ICON_SETTLEMENT_IMAGE_UPLOAD } from 'assets/icons';
import dayjs from 'dayjs';
import { Product } from 'types/api';
import HackleManager from 'utils/hackle';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #dbdcdf;
  background: #fff;
`;

const EmptyThumbnail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 64px;
  height: 64px;
  border-radius: 8px;
  background: #f4f4f5;
`;

const ThumbnailImage = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 8px;
`;

const DividerContainer = styled.div`
  position: relative;
  width: 100%;
`;

interface ChapterOneIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  imageThumbnail: string[];
  handleOnChangeProductParamInput: (key: string, value: any) => void;
}

const ChapterOne: React.FC<ChapterOneIProps> = props => {
  const params = useParams<{ id: string }>();
  const location = useLocation();

  const {
    isMobile,
    productParamInput,
    imageThumbnail,
    handleOnChangeProductParamInput,
  } = props;

  useEffect(() => {
    HackleManager.logCreateStepOneView(
      params.id !== 'draft' ? Number(params.id) : undefined,
    );
  }, []);

  const renderContents = (): JSX.Element => {
    return (
      <React.Fragment>
        <RegistDescriptionForm
          number={1}
          title="기본 정보를 알려주세요"
          description="프립의 유형, 진행 장소 등 기본 정보를 등록해요."
          thumbnail={IMAGE_PRODUCT_INVITE_BASIC}
        />
        <Divider />
        <RegistDescriptionForm
          number={2}
          title="판매 정보를 알려주세요"
          description="판매할 상품의 옵션을 추가한 후 판매 일정을 설정해요."
          thumbnail={IMAGE_PRODUCT_INVITE_CELL}
        />
        <Divider />
        <RegistDescriptionForm
          number={3}
          title="프립을 돋보이게 해주세요"
          description="사진을 추가하고 제목과 설명을 보강하여 프립을 더욱 돋보이게 한 후 등록을 완료해요."
          thumbnail={IMAGE_PRODUCT_INVITE}
        />
      </React.Fragment>
    );
  };

  const renderCopyUpdateForm = (): JSX.Element => {
    return (
      <Column
        gap="32px"
        align="flex-start"
        style={{ maxWidth: isMobile ? '100%' : '335px' }}
      >
        {location.state?.isCopy ? (
          <Text size="24px" color="#1b1c1e" style={{ lineHeight: '32px' }}>
            아래 상품을 복사할게요,
            <br />
            판매 날짜와 일정을 선택후 상품 등록을 완료해 주세요.
          </Text>
        ) : (
          <Text size="24px" color="#1b1c1e" style={{ lineHeight: '32px' }}>
            등록 중인 상품이에요,
            <br /> 이어서 상품 등록을 완료해 주세요
          </Text>
        )}
        <Container>
          {isEmpty(imageThumbnail) ? (
            <EmptyThumbnail>
              <img src={ICON_SETTLEMENT_IMAGE_UPLOAD} alt="icon image empty" />
            </EmptyThumbnail>
          ) : (
            <ThumbnailImage src={imageThumbnail[0]} alt="product thumbnail" />
          )}
          <Text color="#1b1c1e">
            {productParamInput.title !== ''
              ? productParamInput.title
              : `상품 등록중: ${dayjs(productParamInput.updatedAt).format(
                  'YYYY년 M월 D일 hh:mm',
                )}`}
          </Text>
        </Container>
        {isMobile && (
          <DividerContainer>
            <Divider
              style={{
                height: '8px',
                width: '100%',
                position: 'absolute',
                left: 0,
              }}
            />
          </DividerContainer>
        )}
      </Column>
    );
  };

  const renderResponsivePage = (): JSX.Element => {
    if (isMobile) {
      return (
        <React.Fragment>
          {params.id === 'draft' ? (
            <Text size="24px" color="#1b1c1e" style={{ lineHeight: '32px' }}>
              프립 만들기 빌더로 손쉽게 호스팅을 시작할 수 있어요.
            </Text>
          ) : (
            renderCopyUpdateForm()
          )}
          {renderContents()}
        </React.Fragment>
      );
    }

    return (
      <Column>
        <Row align="flex-start" gap="64px">
          {params.id === 'draft' ? (
            <Text size="32px" color="#1b1c1e" style={{ lineHeight: '32px' }}>
              프립 만들기 빌더로 손쉽게 호스팅을 시작할 수 있어요.
            </Text>
          ) : (
            renderCopyUpdateForm()
          )}
          <Column gap="32px">{renderContents()}</Column>
        </Row>
      </Column>
    );
  };

  return renderResponsivePage();
};

export default ChapterOne;
