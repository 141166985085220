import React from 'react';
import styled, { css } from 'styled-components';
import { isEmpty } from 'lodash';

import ImageContainer from './PreviewProductDetail/ImageContainer';
import Column from 'components/common/Column';
import Text from 'components/common/Text';
import InformationContainer from './PreviewProductDetail/InformationContainer';
import IntroduceContainer from './PreviewProductDetail/IntroduceContainer';
import GatherPlaceContainer from './PreviewProductDetail/GatherPlaceContainer';
import ProgressPlaceContainer from './PreviewProductDetail/ProgressPlaceContainer';
import AdditionalInformationContainer from './PreviewProductDetail/AdditionalInformationContainer';
import { ProductParamInput } from '../hooks/types';
import CurriculumContainer from './PreviewProductDetail/CurriculumContainer';
import Row from 'components/common/Row';
import PrecautionView from './PreviewProductDetail/PrecautionView';
import RefundView from './PreviewProductDetail/RefundView';
import Divider from 'components/Divider';

const Container = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: ${props => (props.$isMobile ? '100%' : '375px')};
  overflow-y: scroll;

  max-height: 78vh;

  ${({ $isMobile }) =>
    $isMobile
      ? css``
      : css`
          border-left: 1px solid #eaebec;
          border-right: 1px solid #eaebec;
        `}

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

interface PreviewProductDetailIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  imageThumbnail: string[];
}

const PreviewProductDetail: React.FC<PreviewProductDetailIProps> = props => {
  const { isMobile, productParamInput, imageThumbnail } = props;

  return (
    <Container
      $isMobile={isMobile}
      onScroll={e => {
        e.stopPropagation();
      }}
      style={{ padding: window.webkit ? '0 0 84px' : '0' }}
    >
      {!isMobile && (
        <Row
          justifyContent="flex-start"
          style={{
            position: 'sticky',
            top: '0',
            width: '100%',
            padding: '32px 20px',
            background: '#fff',
            zIndex: 2,
          }}
        >
          <Text color="#1b1c1e" size="24px">
            미리보기
          </Text>
        </Row>
      )}
      <Column>
        <ImageContainer imageThumbnail={imageThumbnail} />
        <InformationContainer productParamInput={productParamInput} />
        <IntroduceContainer productParamInput={productParamInput} />
        {!isEmpty(productParamInput.frip?.curriculum.sections) && (
          <CurriculumContainer productParamInput={productParamInput} />
        )}
        {productParamInput.frip!.locationOfGathering !== null && (
          <GatherPlaceContainer
            locationOfGathering={productParamInput.frip!.locationOfGathering!}
          />
        )}
        <ProgressPlaceContainer
          productParamInput={productParamInput}
          locationsOfVenue={productParamInput.frip!.locationsOfVenue}
        />
        <Divider style={{ height: '8px', background: '#F4F4F4' }} />
        <AdditionalInformationContainer productParamInput={productParamInput} />
        <PrecautionView productParamInput={productParamInput} />
        <RefundView />
      </Column>
      {/* <FloatingButton>
        <IconButton>
          <img src={ICON_HEART_BLACK} alt="icon heart black" />
        </IconButton>
        <IconButton>
          <img src={ICON_EMAIL_BLACK} alt="icon heart black" />
        </IconButton>
        <Button>참여하기</Button>
      </FloatingButton> */}
    </Container>
  );
};

export default PreviewProductDetail;
