import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import { HostBusinessInfoState } from 'graphQL/Auth/types';
import { getEnvConstant } from 'constants/env';
import {
  SETTLEMENT_BANK_REGIST_PATH,
  SETTLEMENT_REGIST_PATH,
} from 'constants/path';
import HackleManager from 'utils/hackle';

export const useSettlementHooks = () => {
  const myInfo = useUserInformation((state: any) => state.userInfo);
  const navigate = useNavigate();
  const location = useLocation();

  const isCheckBusinessInformation =
    myInfo.me.host && myInfo.me.host.settlement?.businessInfo !== null;
  const isCheckRegistState =
    isCheckBusinessInformation &&
    (myInfo.me.host?.settlement?.businessInfo.status ===
      HostBusinessInfoState.UNROLLED ||
      myInfo.me.host?.settlement?.businessInfo.status ===
        HostBusinessInfoState.UNVERIFIED);

  const [isOpenHostInformationModal, setIsOpenHostInformationModal] =
    useState<boolean>(
      location.state && location.state.isOpenHostInformationModal
        ? location.state.isOpenHostInformationModal
        : false,
    );
  const [isRegistRequest, setIsRegistRequest] = useState<boolean>(false);

  const requestMessage = async () => {
    const url =
      getEnvConstant('envName') === 'production'
        ? '/services/T02T6BYE2/B076JC18TD3/L8Yrew680Z6qyUnqXAKxFDyn'
        : '/services/T02T6BYE2/B07666HGF17/yInMPLYdzcDWCgY6IGmge8OQ';

    const payload = {
      text: `[호스트 어드민] 호스트가 검수 요청을 하였습니다.
ID: ${myInfo.me.host?.id}
link: https://admin-v3.frip.co.kr/host/list/${myInfo.me.host?.id}`,
    };

    const form = new FormData();
    form.append('payload', JSON.stringify(payload));

    const instance = axios.create({
      baseURL: 'https://hooks.slack.com',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    try {
      const response = await instance.post(url, form);

      if (response.data === 'ok') {
        setIsRegistRequest(true);

        localStorage.setItem('isRegistRequest', 'yes');
        HackleManager.logSettlementRequestApprovalComplete();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const handleCalculateRegistNavigate = () => {
    navigate(SETTLEMENT_REGIST_PATH);
  };

  const handleRegistBankPageNavigate = () => {
    navigate(SETTLEMENT_BANK_REGIST_PATH, { state: { isModify: false } });
  };

  useEffect(() => {
    window.handlePageBackwardNavigate = handleNavigateBack;

    if (
      myInfo.me.host?.settlement?.businessInfo !== null &&
      myInfo.me.host?.settlement?.businessInfo.status ===
        HostBusinessInfoState.ENROLLED
    ) {
      localStorage.removeItem('isRegistRequest');
    }
  }, [myInfo.me.host]);

  return {
    myInfo,
    isCheckBusinessInformation,
    isCheckRegistState,
    isRegistRequest,
    isOpenHostInformationModal,
    setIsOpenHostInformationModal,
    handleNavigateBack,
    requestMessage,
    handleCalculateRegistNavigate,
    handleRegistBankPageNavigate,
  };
};
