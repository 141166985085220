import { useMutation } from '@apollo/client';
import { REQUEST_INVOICE } from '..';

export function useMutaionRequestInvoice() {
  const [requestInvoice, { data }] = useMutation(REQUEST_INVOICE);

  const handleRequestInvoice = async (
    id: string,
    callback: (data: any) => void
  ) => {
    try {
      const response = await requestInvoice({
        variables: {
          param: {
            settlementIds: [id],
          },
        },
      });

      if (response) {
        callback(response.data);
        // window.location.reload();
      }
    } catch (error) {
      console.log(error, 'request invoice');
    }
  };

  return {
    requestResult: data,
    handleRequestInvoice,
  };
}
