import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import SelectionButton from './components/SelectionButton';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import OptionButton from '../common/OptionButton';
import CurriculumForm from './components/CurriculumForm';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import { CurriculumStyle } from 'types/api';
import { useCurriculumHooks } from './hooks/useCurriculumHooks';
import { useParams } from 'react-router-dom';
import HackleManager from 'utils/hackle';
import CalloutContainer from './components/CalloutContainer';

const RegistButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  width: 100%;

  border-radius: 8px;
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);

  color: var(--gray-gray-09, #1b1c1e);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.14px;
`;

interface ChapterSevenIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
}

const ChapterSeven: React.FC<ChapterSevenIProps> = props => {
  const { isMobile, productParamInput, handleOnChangeProductParamInput } =
    props;

  const params = useParams<{ id: string }>();

  const {
    handleResetCurriculum,
    handleInitCurriculum,
    handleAddCurriculumSections,
    handleAddCurriculumItems,
    handleDeleteCurriculumItems,
    handleRemoveCurriculumItems,
  } = useCurriculumHooks(productParamInput, handleOnChangeProductParamInput);

  useEffect(() => {
    HackleManager.logCreateStepThreeCurriculumView(
      params.id !== 'draft' ? Number(params.id) : undefined,
    );
  }, []);

  const renderContents = (): JSX.Element => {
    return (
      <Column gap="32px">
        <Column gap="16px" align="flex-start">
          <SelectionButton />
          <Column gap="8px" align="flex-start">
            <Text color="#1b1c1e" size="24px" style={{ lineHeight: '32px' }}>
              스케줄표를 작성하여 진행 순서를 설명해 주세요.
            </Text>
            <Text color="#70737C" size="16px" weight={400}>
              이를 작성하면 크루들이 프립의 내용을 더 쉽게 이해하고 준비하는 데
              도움이 됩니다.
            </Text>
          </Column>
        </Column>
        <Column gap="16px" align="flex-start">
          {/* <Row gap="8px">
            <Text color="#1b1c1e" size="16px">
              커리큘럼/스케줄
            </Text>
          </Row> */}
          <Column gap="16px">
            <Row gap="8px" style={{ width: '100%' }}>
              <OptionButton
                text="시간표"
                isSelected={
                  productParamInput.frip?.curriculum.style ===
                  CurriculumStyle.DURATION
                }
                onClick={() => {
                  handleResetCurriculum(CurriculumStyle.DURATION);
                }}
              />
              <OptionButton
                text="구간표"
                isSelected={
                  productParamInput.frip?.curriculum.style ===
                  CurriculumStyle.TIMETABLE
                }
                onClick={() => {
                  handleResetCurriculum(CurriculumStyle.TIMETABLE);
                }}
              />
              <OptionButton
                text="직접 입력"
                isSelected={
                  productParamInput.frip?.curriculum.style ===
                  CurriculumStyle.LEGACY
                }
                onClick={() => {
                  handleResetCurriculum(CurriculumStyle.LEGACY);
                }}
              />
            </Row>
            {productParamInput.frip.curriculum.style !==
              CurriculumStyle.NONE && (
              <CalloutContainer
                curriculumStyle={productParamInput.frip.curriculum.style}
              />
            )}
          </Column>
          {productParamInput.frip.curriculum.style !== CurriculumStyle.NONE && (
            <CurriculumForm
              productParamInput={productParamInput}
              handleAddCurriculumItems={handleAddCurriculumItems}
              handleAddCurriculumSections={handleAddCurriculumSections}
              handleDeleteCurriculumItems={handleDeleteCurriculumItems}
              handleRemoveCurriculumItems={handleRemoveCurriculumItems}
              handleOnChangeProductParamInput={handleOnChangeProductParamInput}
              handleResetCurriculum={handleResetCurriculum}
            />
          )}
        </Column>
      </Column>
    );
  };

  return renderContents();
};

export default ChapterSeven;
