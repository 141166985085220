import React, { ReactElement } from 'react';
import styled from 'styled-components';

const Container = styled.button<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  width: 100%;

  border-radius: 8px;
  border: ${props =>
    props.$isSelected ? '1.5px solid #1b1c1e' : '1px solid #dbdcdf'};
  background: ${props => (props.$isSelected ? '#F7F7F8' : '#fff')};

  color: #1b1c1e;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
`;

interface OptionButtonIProps {
  text: string | ReactElement;
  isSelected: boolean;
  onClick: () => void;
  style?: React.CSSProperties;
}

const OptionButton: React.FC<OptionButtonIProps> = props => {
  const { text, isSelected, onClick, style } = props;

  return (
    <Container $isSelected={isSelected} onClick={onClick} style={{ ...style }}>
      {text}
    </Container>
  );
};

export default OptionButton;
