import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Column from 'components/common/Column';
import Text from 'components/common/Text';
import RefundForm from './components/RefundForm';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import { CompanionType, LevelOfDifficulty } from 'types/api';
import { useParams } from 'react-router-dom';
import HackleManager from 'utils/hackle';

const OptionForm = styled.div<{ $isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: ${props => (props.$isMobile ? 'inherit' : 'wrap')};
  gap: 8px;
  width: 100%;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const OptionSelectBox = styled.div<{
  $isSelected: boolean;
  $isMobile: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  min-width: ${props => (props.$isMobile ? '90px' : '0px')};

  border-radius: 99px;
  border: ${props =>
    props.$isSelected ? '1.5px solid #1b1c1e' : '1px solid #dbdcdf'};
  background: ${props => (props.$isSelected ? '#f7f7f8' : '#fff')};

  color: var(--gray-gray-09, #1b1c1e);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
  cursor: pointer;
`;

const TextArea = styled.textarea`
  padding: 16px 16px 32px 16px;
  width: 100%;
  height: 160px;
  color: var(--gray-gray-09, #1b1c1e);

  border-radius: 8px;
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);

  /* Body/B-16-Regular */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.6px;

  ::placeholder {
    color: #c2c4c8;
  }

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

interface ChapterFourIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  type?: string;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
}

const ChapterFour: React.FC<ChapterFourIProps> = props => {
  const { isMobile, productParamInput, type, handleOnChangeProductParamInput } =
    props;

  const params = useParams<{ id: string }>();

  const handleSetLevel = (level: LevelOfDifficulty) => {
    handleOnChangeProductParamInput('frip', {
      ...productParamInput.frip,
      difficulty: level,
    });
  };

  const handleSetAge = (age: number) => {
    handleOnChangeProductParamInput('frip', {
      ...productParamInput.frip,
      recommendedAge: age,
    });
  };

  const handleSetCompanions = (companion: CompanionType) => {
    if (productParamInput.frip.companions.includes(companion)) {
      handleOnChangeProductParamInput('frip', {
        ...productParamInput.frip,
        companions: productParamInput.frip.companions.filter(
          item => item !== companion,
        ),
      });

      return;
    }

    handleOnChangeProductParamInput('frip', {
      ...productParamInput.frip,
      companions: [...productParamInput.frip.companions, companion],
    });

    return;
  };

  useEffect(() => {
    HackleManager.logCreateStepThreeParticipantGuideView(
      params.id !== 'draft' ? Number(params.id) : undefined,
    );
  }, []);

  const renderContents = (): JSX.Element => {
    return (
      <Column gap="32px" align="flex-start">
        {!type && (
          <Column gap="8px" align="flex-start">
            <Text color="#1b1c1e" size="24px">
              참여 안내 정보를 작성해 주세요
            </Text>
            {/* <Text color="#70737C" size="16px" weight={400}>
              설명해 주세요
            </Text> */}
          </Column>
        )}
        <Column gap="40px">
          <Column gap="12px" align="flex-start">
            <Text color="#1b1c1e" size="16px">
              난이도
            </Text>
            <OptionForm $isMobile={isMobile}>
              <OptionSelectBox
                $isSelected={
                  productParamInput.frip?.difficulty ===
                  LevelOfDifficulty.VERY_EASY
                }
                $isMobile={isMobile}
                onClick={() => {
                  handleSetLevel(LevelOfDifficulty.VERY_EASY);
                }}
              >
                매우 쉬움
              </OptionSelectBox>
              <OptionSelectBox
                $isSelected={
                  productParamInput.frip?.difficulty === LevelOfDifficulty.EASY
                }
                $isMobile={isMobile}
                onClick={() => {
                  handleSetLevel(LevelOfDifficulty.EASY);
                }}
              >
                쉬움
              </OptionSelectBox>
              <OptionSelectBox
                $isSelected={
                  productParamInput.frip?.difficulty ===
                  LevelOfDifficulty.NORMAL
                }
                $isMobile={isMobile}
                onClick={() => {
                  handleSetLevel(LevelOfDifficulty.NORMAL);
                }}
              >
                보통
              </OptionSelectBox>
              <OptionSelectBox
                $isSelected={
                  productParamInput.frip?.difficulty === LevelOfDifficulty.HIGH
                }
                $isMobile={isMobile}
                onClick={() => {
                  handleSetLevel(LevelOfDifficulty.HIGH);
                }}
              >
                어려움
              </OptionSelectBox>
            </OptionForm>
          </Column>
          <Column gap="12px" align="flex-start">
            <Text color="#1b1c1e" size="16px">
              권장 연령
            </Text>
            <OptionForm $isMobile={isMobile}>
              <OptionSelectBox
                $isSelected={productParamInput.frip?.recommendedAge === 0}
                $isMobile={isMobile}
                onClick={() => handleSetAge(0)}
              >
                누구나
              </OptionSelectBox>
              <OptionSelectBox
                $isSelected={productParamInput.frip?.recommendedAge === 20}
                $isMobile={isMobile}
                onClick={() => handleSetAge(20)}
              >
                20대 이상
              </OptionSelectBox>
              <OptionSelectBox
                $isSelected={productParamInput.frip?.recommendedAge === 30}
                $isMobile={isMobile}
                onClick={() => handleSetAge(30)}
              >
                30대 이상
              </OptionSelectBox>
              <OptionSelectBox
                $isSelected={productParamInput.frip?.recommendedAge === 40}
                $isMobile={isMobile}
                onClick={() => handleSetAge(40)}
              >
                40대 이상
              </OptionSelectBox>
              <OptionSelectBox
                $isSelected={productParamInput.frip?.recommendedAge === 50}
                $isMobile={isMobile}
                onClick={() => handleSetAge(50)}
              >
                50대 이상
              </OptionSelectBox>
            </OptionForm>
          </Column>
          <Column gap="12px" align="flex-start">
            <Text color="#1b1c1e" size="16px">
              누구와 함께하기 좋아요?{' '}
              <span
                style={{ color: '#989ba2', fontSize: '14px', fontWeight: 400 }}
              >
                (중복 선택 가능)
              </span>
            </Text>
            <OptionForm $isMobile={isMobile}>
              <OptionSelectBox
                $isSelected={productParamInput.frip.companions.includes(
                  CompanionType.ALONE,
                )}
                $isMobile={isMobile}
                onClick={() => handleSetCompanions(CompanionType.ALONE)}
              >
                혼자
              </OptionSelectBox>
              <OptionSelectBox
                $isSelected={productParamInput.frip.companions.includes(
                  CompanionType.WITH_FRIENDS,
                )}
                $isMobile={isMobile}
                onClick={() => handleSetCompanions(CompanionType.WITH_FRIENDS)}
              >
                친구와
              </OptionSelectBox>
              <OptionSelectBox
                $isSelected={productParamInput.frip.companions.includes(
                  CompanionType.WITH_LOVER,
                )}
                $isMobile={isMobile}
                onClick={() => handleSetCompanions(CompanionType.WITH_LOVER)}
              >
                연인과
              </OptionSelectBox>
              <OptionSelectBox
                $isSelected={productParamInput.frip.companions.includes(
                  CompanionType.WITH_CHILDREN,
                )}
                $isMobile={isMobile}
                onClick={() => handleSetCompanions(CompanionType.WITH_CHILDREN)}
              >
                아이와
              </OptionSelectBox>
              <OptionSelectBox
                $isSelected={productParamInput.frip.companions.includes(
                  CompanionType.WITH_DOG,
                )}
                $isMobile={isMobile}
                onClick={() => handleSetCompanions(CompanionType.WITH_DOG)}
              >
                반려견과
              </OptionSelectBox>
            </OptionForm>
          </Column>
          <Column gap="12px" align="flex-start">
            <Text color="#1b1c1e" size="16px">
              실내/실외{' '}
              <span
                style={{ color: '#989ba2', fontSize: '14px', fontWeight: 400 }}
              >
                (중복 선택 가능)
              </span>
            </Text>
            <OptionForm $isMobile={isMobile}>
              <OptionSelectBox
                $isSelected={productParamInput.frip?.isIndoor}
                $isMobile={isMobile}
                onClick={() =>
                  handleOnChangeProductParamInput('frip', {
                    ...productParamInput.frip,
                    isIndoor: !productParamInput.frip.isIndoor,
                  })
                }
              >
                실내
              </OptionSelectBox>
              <OptionSelectBox
                $isSelected={productParamInput.frip?.isOutdoor}
                $isMobile={isMobile}
                onClick={() =>
                  handleOnChangeProductParamInput('frip', {
                    ...productParamInput.frip,
                    isOutdoor: !productParamInput.frip.isOutdoor,
                  })
                }
              >
                실외
              </OptionSelectBox>
            </OptionForm>
          </Column>
          {!type && (
            <Column gap="12px" align="flex-start">
              <Column gap="8px" align="flex-start">
                <Text color="#1b1c1e" size="16px">
                  유의사항
                </Text>
                <Text color="#0071e9" size="14px" weight={400}>
                  크루에게 보여지는 유의사항이에요. 확인 후 알맞게 변경해
                  주세요.
                </Text>
              </Column>
              <TextArea
                value={productParamInput.notice}
                onChange={(e: any) =>
                  handleOnChangeProductParamInput('notice', e.target.value)
                }
              />
            </Column>
          )}
        </Column>
        {!type && <RefundForm />}
      </Column>
    );
  };

  return renderContents();
};

export default ChapterFour;
