const IMAGE_LOGIN_BANNER = require("./image_login_banner.png");
const IMAGE_REGIST_PRODUCT_SELL = require("./image_regist_product_sell.png");
const IMAGE_REGIST_PRODUCT_INFORMATION = require("./image_regist_product_information.png");
const IMAGE_REGIST_PRODUCT_COMPLETE = require("./image_regist_product_complete.png");
const IMAGE_PROFILE_DEFAULT = require("./image_profile_default.png");
const IMAGE_SIGNUP_SUCCESS = require("./image_signup_success.png");
const IMAGE_EMPTY = require("./image_empty.png");

const IMAGE_PRODUCT_INVITE_BASIC = require("./image_product_invite_basic.png");
const IMAGE_PRODUCT_INVITE_CELL = require("./image_product_invite_cell.png");
const IMAGE_PRODUCT_INVITE = require("./image_product_invite.png");

export {
  IMAGE_LOGIN_BANNER,
  IMAGE_REGIST_PRODUCT_SELL,
  IMAGE_REGIST_PRODUCT_INFORMATION,
  IMAGE_REGIST_PRODUCT_COMPLETE,
  IMAGE_PROFILE_DEFAULT,
  IMAGE_SIGNUP_SUCCESS,
  IMAGE_EMPTY,
  IMAGE_PRODUCT_INVITE_BASIC,
  IMAGE_PRODUCT_INVITE_CELL,
  IMAGE_PRODUCT_INVITE,
};
