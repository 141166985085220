import { useEffect, useRef, useState } from 'react';
import Resizer from 'react-image-file-resizer';

import {
  HeaderContents,
  ProductParamInput,
} from 'containers/ProductRegist/hooks/types';
import { useMutationGenerateContentUploadInfo } from 'graphQL/Image/useMutationGenerateContentUploadInfo';
import { ProductHeaderContentStyle } from 'types/api';
import {
  callWebviewHandler,
  isWebview,
  WebviewOperatorType,
} from 'utils/webview';

export const useImageUpdateFormHooks = (
  productParamInput: ProductParamInput,
) => {
  const { handleGenerateContentUpload } =
    useMutationGenerateContentUploadInfo();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [copyHeaderContents, setCopyHeaderContents] = useState<
    HeaderContents[]
  >(productParamInput.headerContents);
  const [imageThumbnail, setImageThumbnail] = useState<string[]>(
    productParamInput.headerContents.map(item => item.uri),
  );
  const isAbled = copyHeaderContents !== productParamInput.headerContents;

  const handleFileChange = async (event: any) => {
    await Resizer.imageFileResizer(
      event.target.files[0],
      375 * 3,
      375 * 3,
      'png',
      80,
      0,
      (file: File) => {
        setIsLoading(true);
        handleGenerateContentUpload(
          {
            file,
            fileName: file.name,
          },
          (url: string, contentId: string) => {
            setImageThumbnail([...imageThumbnail, url]);
            setCopyHeaderContents([
              ...copyHeaderContents,
              {
                contentId,
                uri: url,
                style: ProductHeaderContentStyle.SQUARE,
              },
            ]);
            setIsLoading(false);
          },
        );
      },
      'file',
    );
  };

  const handleImageUploadClick = () => {
    if (imageThumbnail.length >= 5) {
      window.showToast('최대 5개만 업로드 가능합니다', 'failed', 3000);

      return;
    }

    if (fileInputRef.current) {
      if (isWebview()) {
        callWebviewHandler(
          WebviewOperatorType.UpdateMessageImageFileHandler,
          undefined,
          'handleImageUpload',
        );
      } else {
        fileInputRef.current.click();
      }
    }
  };

  const handleDeleteImage = (idx: number) => {
    const filterImageList = imageThumbnail.filter(
      (item, filterIdx) => filterIdx !== idx,
    );

    const productFilter = copyHeaderContents.filter(
      (item, filterIdx) => filterIdx !== idx,
    );

    setCopyHeaderContents(productFilter);
    setImageThumbnail(filterImageList);
  };

  const handleResetImageThumbnail = () => {
    setCopyHeaderContents(productParamInput.headerContents);
    setImageThumbnail(productParamInput.headerContents.map(item => item.uri));
  };

  const handleImageUpload = (link: { link: string }) => {
    const result = JSON.parse(link.link);

    setImageThumbnail([...imageThumbnail, result.url]);
    setCopyHeaderContents([
      ...copyHeaderContents,
      {
        uri: result.url,
        contentId: result.contentId,
        style: ProductHeaderContentStyle.SQUARE,
      },
    ]);
    setIsLoading(false);
  };

  useEffect(() => {
    window.handleImageUpload = handleImageUpload;
    window.handleImageLoading = () => {
      setIsLoading(true);
    };
  }, [copyHeaderContents, isLoading]);

  return {
    fileInputRef,
    imageThumbnail,
    copyHeaderContents,
    isAbled,
    isLoading,
    handleFileChange,
    handleImageUploadClick,
    handleDeleteImage,
    handleResetImageThumbnail,
  };
};
