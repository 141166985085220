import React from 'react';
import { styled } from 'styled-components';
import Column from 'components/common/Column';
import Text from 'components/common/Text';
import BasicButton from 'components/Button/BasicButton';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import Row from 'components/common/Row';
import { ICON_CLOSE } from 'assets/icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
`;

const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding: 16px 20px;
  width: 100%;
  border-top: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);
`;

interface HostApplicationContainerIProps {
  isMobile: boolean;
  onClose: () => void;
}

const HostApplicationContainer: React.FC<
  HostApplicationContainerIProps
> = props => {
  const { isMobile, onClose } = props;

  return (
    <ModalContainer
      isMobile={isMobile}
      isAbled={true}
      onClose={() => {}}
      onClick={() => {}}
      style={{ gap: 0 }}
      isBottomSheet={true}
    >
      <Row justifyContent="flex-start" style={{ padding: '16px' }}>
        <img src={ICON_CLOSE} alt="close icon" onClick={onClose} />
      </Row>
      <Container>
        <Column gap="12px" align="flex-start" style={{ padding: '8px 20px 0' }}>
          <Text color="#1b1c1e" size="20px" weight={700}>
            호스트 지원센터
          </Text>
          <Column gap="4px" align="flex-start">
            <Text color="#70737c" size="16px" weight={400}>
              • 운영 시간: 평일 10:00 ~ 17:00
            </Text>
            <Text color="#70737c" size="16px" weight={400}>
              • 점심 시간: 12:00 ~ 13:00
            </Text>
          </Column>
          <Text color="#46474c" size="16px">
            • 채팅 상담: 카카오톡 @프립호스트
          </Text>
        </Column>
        <BottomContainer>
          <a href="https://pf.kakao.com/_xbxmrqM" target="_blank">
            <BasicButton
              content="문의하기"
              onClick={() => {}}
              style={{ borderRadius: '8px', color: '#7126FF' }}
            />
          </a>
        </BottomContainer>
      </Container>
    </ModalContainer>
  );
};

export default HostApplicationContainer;
