import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useResizeDetector } from 'react-resize-detector';
import styled from 'styled-components';
import Lottie from 'lottie-react';

import { ModifyValue } from 'containers/ProductDetail/hooks/useProductDetaillHooks';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';

import { ICON_CLOSE, ICON_PRODUCT_CAMERA } from 'assets/icons';
import { useImageUpdateFormHooks } from './hooks/useImageUpdateFormHooks';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import SaveButton from '../SaveButton';
import { Product, ProductState } from 'types/api';
import HackleManager from 'utils/hackle';
import lottie from 'assets/lotties/image_upload_lottie.json';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ImageUploadForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  position: relative;
  border-radius: 8px;
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);

  color: var(--gray-gray-06, #989ba2);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.6px;
  cursor: pointer;

  input {
    position: absolute;
    display: none;
    border: 1px solid red;
    width: 100%;
    height: 100%;
  }
`;

const DeleteContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid #1b1c1e;
  background: #fff;
  cursor: pointer;
`;

const RepresentativeBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 8px;
  top: 8px;
  padding: 6px 8px;

  border-radius: 4px;
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);

  color: #1b1c1e;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 140% */
  letter-spacing: -0.2px;
`;

const ImageContainer = styled.div<{ $width: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  width: ${props => props.$width / 2}px;
  height: ${props => props.$width / 2}px;

  border-radius: 8px;
  background-color: #f4f4f5;
`;

interface ImageModifyContainerIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  productInfo: Product;
  modifyValue: ModifyValue;
  setModifyValue: Dispatch<SetStateAction<ModifyValue>>;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
}

const ImageModifyContainer: React.FC<ImageModifyContainerIProps> = props => {
  const {
    isMobile,
    productParamInput,
    productInfo,
    modifyValue,
    setModifyValue,
    handleOnChangeProductParamInput,
  } = props;

  const { width, ref } = useResizeDetector();

  const {
    fileInputRef,
    imageThumbnail,
    copyHeaderContents,
    isAbled,
    isLoading,
    handleFileChange,
    handleImageUploadClick,
    handleDeleteImage,
    handleResetImageThumbnail,
  } = useImageUpdateFormHooks(productParamInput);

  const handleRegistHeaderContents = () => {
    handleOnChangeProductParamInput('headerContents', copyHeaderContents);
  };

  const imageWidth = isMobile ? width - 52 : width - 92;

  useEffect(() => {
    HackleManager.logProductModifyImageView(
      Number(productParamInput.id),
      productParamInput.title,
    );
  }, []);

  const renderImageUploadForm = (): JSX.Element => {
    return (
      <Column gap="32px" align="flex-start">
        <Text color="#70737C" size="16px" weight={400}>
          활동이 잘 드러나는 이미지를 최대 5장까지 등록해주세요. 이미지는 최소
          1개 이상 등록해야 합니다.
        </Text>
        <Row
          gap="12px"
          justifyContent={isMobile ? 'space-between' : 'flex-start'}
          style={{
            display: 'grid',
            gridTemplateColumns: `repeat(auto-fill, minmax(${
              imageWidth / 2
            }px, auto))`,
            width: '100%',
          }}
        >
          <ImageUploadForm
            onClick={() => {
              handleImageUploadClick();
            }}
            style={{
              width: `${imageWidth / 2}px`,
              height: `${imageWidth / 2}px`,
            }}
          >
            <img src={ICON_PRODUCT_CAMERA} alt="image upload icon" />
            {imageThumbnail.length}/5
            <input
              ref={fileInputRef}
              type="file"
              multiple={false}
              onChange={handleFileChange}
              accept=".jpg, .png, .jpeg"
            />
          </ImageUploadForm>
          {copyHeaderContents.map((item, idx) => {
            return (
              <Row style={{ position: 'relative' }} key={idx}>
                {idx === 0 && (
                  <RepresentativeBadge>대표이미지</RepresentativeBadge>
                )}
                <img
                  src={item.uri}
                  alt="image thumbnail"
                  key={idx}
                  style={{
                    width: `${imageWidth / 2}px`,
                    height: `${imageWidth / 2}px`,
                    borderRadius: '8px',
                    objectFit: 'cover',
                  }}
                />
                <DeleteContainer
                  onClick={() => {
                    if (copyHeaderContents.length !== 1) {
                      handleDeleteImage(idx);
                    } else {
                      window.showToast(
                        '하나의 이미지는 삭제할 수 없습니다',
                        'failed',
                      );
                    }
                  }}
                >
                  <img src={ICON_CLOSE} alt="delete icon" />
                </DeleteContainer>
              </Row>
            );
          })}
          {isLoading && (
            <ImageContainer $width={imageWidth}>
              <Lottie animationData={lottie} style={{ width: '50px' }} />
            </ImageContainer>
          )}
        </Row>
      </Column>
    );
  };

  const renderContents = (): JSX.Element => {
    if (isMobile) {
      return (
        <ModalContainer
          isMobile={isMobile}
          isAbled={productInfo.status !== ProductState.CLOSED && isAbled}
          onClose={() => {
            setModifyValue({ ...modifyValue, modifyStatus: '' });
          }}
          onCloseText="닫기"
          onAcceptText="적용"
          onClick={() => {
            HackleManager.logProductModifyItemSaveComplete(
              Number(productInfo.id),
              productInfo.title,
              '사진',
            );

            handleRegistHeaderContents();
            setModifyValue({
              ...modifyValue,
              isModify: true,
              modifyStatus: '',
            });
          }}
        >
          <Column
            gap="24px"
            style={{ position: 'relative', padding: '16px 20px 0' }}
          >
            <Row>
              <Text color="#000" size="16px" weight={600}>
                이미지
              </Text>
            </Row>
            {renderImageUploadForm()}
          </Column>
        </ModalContainer>
      );
    }

    return (
      <React.Fragment>
        <Row
          justifyContent="flex-start"
          style={{
            position: 'sticky',
            top: 0,
            padding: '32px 40px',
            width: '100%',
            background: '#fff',
            borderBottom: '1px solid #eaebec',
            zIndex: 1,
          }}
        >
          <Text color="#1B1C1E" size="24px">
            이미지 수정하기
          </Text>
        </Row>
        <Column gap="32px" style={{ padding: '32px 40px' }}>
          {renderImageUploadForm()}
          {productInfo.status !== ProductState.CLOSED && (
            <SaveButton
              isAbled={isAbled}
              modifyValue={modifyValue}
              setModifyValue={setModifyValue}
              handleResetItem={handleResetImageThumbnail}
              handleRegistItem={() => {
                HackleManager.logProductModifyItemSaveComplete(
                  Number(productInfo.id),
                  productInfo.title,
                  '사진',
                );

                handleRegistHeaderContents();
              }}
            />
          )}
        </Column>
      </React.Fragment>
    );
  };

  return <Container ref={ref}>{renderContents()}</Container>;
};

export default ImageModifyContainer;
