import { ICON_NAVIGATE_BACK, ICON_PRODUCT_FILTER } from 'assets/icons';
import MenuBackwardButton from 'components/Button/MenuBackwardButton';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  position: sticky;
  top: 0;
  padding: 24px 20px;
  width: 100%;
  border-bottom: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);
  z-index: 5;
`;

const FilterButton = styled.button<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 10px 16px;
  border-radius: 999px;
  border: ${props =>
    props.$isSelected ? '1.5px solid #1b1c1e' : '1px solid  #dbdcdf'};
  background: ${props => (props.$isSelected ? '#f7f7f8' : '#fff')};
  color: #1b1c1e;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
`;

interface HeaderContentsIProps {
  isMobile: boolean;
  type: 'qna' | 'review';
  // itemCount: {
  //   new: number;
  //   complete: number;
  // };
  qnaFilter: any;
  isAcceptFilter: boolean;
  handleSetQnaFilter: (key: string, value: any) => void;
  setIsOpenQnaFilterBottomSheet: React.Dispatch<React.SetStateAction<boolean>>;
}

const HeaderContents: React.FC<HeaderContentsIProps> = props => {
  const {
    isMobile,
    type,
    // itemCount,
    qnaFilter,
    isAcceptFilter,
    handleSetQnaFilter,
    setIsOpenQnaFilterBottomSheet,
  } = props;

  const navigate = useNavigate();
  const [filterType, setFilterType] = useState<string>('');

  const handleSetFilterType = (type: string) => {
    if (filterType === type) {
      setFilterType('');
      handleSetQnaFilter('filter', {
        ...qnaFilter.filter,
        statusIn: [],
      });

      return;
    }

    setFilterType(type);
    handleSetQnaFilter('filter', {
      ...qnaFilter.filter,
      statusIn: [type],
    });

    return;
  };

  const renderQnaCount = (): JSX.Element => {
    if (type === 'qna') {
      return (
        <React.Fragment>
          {/* <FilterButton
            $isSelected={filterType === 'NEW'}
            onClick={() => {
              handleSetFilterType('NEW');
            }}
          >
            <span>미답변 {itemCount.new}</span>
          </FilterButton>
          <FilterButton
            $isSelected={filterType === 'COMPLETE'}
            onClick={() => {
              handleSetFilterType('COMPLETE');
            }}
          >
            <span>답변완료 {itemCount.complete}</span>
          </FilterButton> */}
        </React.Fragment>
      );
    }
  };

  const renderContents = (): JSX.Element => {
    if (isMobile) {
      return (
        <Container>
          <Row style={{ position: 'relative', width: '100%' }}>
            <img
              src={ICON_NAVIGATE_BACK}
              alt="navigate back icon"
              style={{ position: 'absolute', left: 0, cursor: 'pointer' }}
              onClick={() => navigate(-1)}
            />
            <Text color="#1b1c1e" size="18px">
              {type === 'qna' ? '문의 관리' : '후기 관리'}
            </Text>
          </Row>
          <Row gap="8px" justifyContent="flex-start" style={{ width: '100%' }}>
            <FilterButton
              $isSelected={isAcceptFilter}
              onClick={() => {
                setIsOpenQnaFilterBottomSheet(true);
              }}
            >
              <img src={ICON_PRODUCT_FILTER} alt="filter icon" />
              <span>{isAcceptFilter ? '필터 적용' : '필터'}</span>
            </FilterButton>
          </Row>
        </Container>
      );
    }

    return (
      <Row
        justifyContent="space-between"
        style={{ padding: '40px 20px 16px', width: '100%' }}
      >
        <Row gap="18px">
          <MenuBackwardButton
            onClick={() => {
              navigate(-1);
            }}
          />
          <Text color="#1b1c1e" size="24px">
            {type === 'qna' ? '문의 관리' : '후기 관리'}
          </Text>
        </Row>
        <Row gap="8px">
          <FilterButton
            $isSelected={isAcceptFilter}
            onClick={() => {
              setIsOpenQnaFilterBottomSheet(true);
            }}
          >
            <img src={ICON_PRODUCT_FILTER} alt="filter icon" />
            <span>{isAcceptFilter ? '필터 적용' : '필터'}</span>
          </FilterButton>
        </Row>
      </Row>
    );
  };

  return renderContents();
};

export default HeaderContents;
