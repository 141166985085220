import React, { useState } from 'react';
import styled from 'styled-components';
import { isEmpty, isNaN } from 'lodash';
import dayjs from 'dayjs';

import BasicButton from 'components/Button/BasicButton';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import Divider from 'components/Divider';
import CheckBox from 'components/common/CheckBox';
import OrderStatusButton from './OrderStatusButton';
import {
  BookingItem,
  BookingState,
  OrderItem,
  Ticket,
  TicketState,
} from 'types/api';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import SaleCalendar from './SaleCalendar';
import { useMutationTicketSale } from '../graphQL/hooks/useMutationTicketSale';
import CancelOrderContainer from './CancelOrderContainer';
import { dateFormattingForm } from 'utils/formatting';
import { useQueryCheckExistenceOfInvoice } from '../graphQL/hooks/useQueryCheckExistenceOfInvoice';
import { CancelItems } from '../hooks/useSaleDetailHooks';
import TimeInput from 'components/Input/TimeInput';
import InquiresTemplate from './InquiresTemplate';
import HackleManager from 'utils/hackle';

const Container = styled.div<{ $isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
  padding: 16px;
  width: 100%;
  border-radius: 12px;
  border: ${props =>
    props.$isSelected ? '1.5px solid #1b1c1e' : '1px solid  #dbdcdf'};
  background: var(--gray-gray-01, #f7f7f8);
`;

const DateBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2px;
  padding: 8px 12px;
  min-width: 150px;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;
  cursor: pointer;
`;

interface TicketCardIProps {
  isMobile: boolean;
  item: BookingItem;
  cancelItems: CancelItems;
  selectedTicket: BookingItem[];
  transactionId: string;
  orderItems: OrderItem;
  handleSetSelectedTicket: (bookingItem: BookingItem) => void;
  handleRefetchOrderItem: () => void;
}

const TicketCard: React.FC<TicketCardIProps> = props => {
  const {
    isMobile,
    item,
    cancelItems,
    selectedTicket,
    orderItems,
    transactionId,
    handleSetSelectedTicket,
    handleRefetchOrderItem,
  } = props;

  const {
    handleConfirmTicket,
    handleRestoreTicket,
    handleRequestCancelOrder,
    handleRetractCancelOrderRequest,
  } = useMutationTicketSale();
  const { handleCheckExistenceOfInvoice } = useQueryCheckExistenceOfInvoice();

  const isMultiTicket = item.ticketCount > 1;
  const [isOpenCalendarModal, setIsOpenCalendarModal] =
    useState<boolean>(false);
  const [isOpenCancelModal, setIsOpenCancelModal] = useState<boolean>(false);

  const [saleDate, setSaleDate] = useState<Date>();
  const [saleTime, setSaleTime] = useState<string>('');
  const [ticketId, setTicketId] = useState<string>('');

  const isShowSelectButton = (): Boolean => {
    const isHide =
      item.status === BookingState.ABSENT ||
      item.status === BookingState.CANCELED ||
      (cancelItems.request.orderId.includes(item.booking.id) &&
        cancelItems.request.indexNo.includes(item.indexNo));

    return !isHide;
  };

  const renderStatusButton = (): JSX.Element => {
    const dependedItems = orderItems.dependedItems;

    if (
      !isEmpty(dependedItems) &&
      cancelItems.request.indexNo.includes(item.indexNo)
    ) {
      return (
        <BasicButton
          content="주문 취소 철회"
          onClick={() => {
            handleRetractCancelOrderRequest(
              cancelItems.request.transactionId[
                cancelItems.request.transactionId.findIndex(
                  items => items === dependedItems[0].transaction.id,
                )
              ],
              () => {
                HackleManager.logSalesCancelRequestReverseComplete(
                  Number(orderItems.booking.order.id),
                  orderItems.booking.order.customer.id,
                  orderItems.booking.tickets.map(item => item.id),
                  '판매상세',
                );

                handleRefetchOrderItem();
              },
            );
          }}
          style={{ borderRadius: '8px' }}
        />
      );
    }

    switch (item.status) {
      case BookingState.APPLIED: {
        return (
          <React.Fragment>
            <BasicButton
              content="주문 취소 요청"
              onClick={() => {
                handleCheckExistenceOfInvoice(
                  {
                    orderItemId: item.booking.id.toString(),
                    indexNo: item.indexNo,
                  },
                  () => setIsOpenCancelModal(true),
                );
              }}
              style={{ borderRadius: '8px' }}
            />
            {!item.booking.schedule && item.tickets.length === 1 && (
              <BasicButton
                content="예약확정"
                onClick={() => {
                  if (item.booking.schedule !== null) {
                    handleConfirmTicket(item.tickets[0].id, null, () => {
                      HackleManager.logSalesBookingComplete(
                        Number(orderItems.booking.order.id),
                        orderItems.booking.order.customer.id,
                        item.tickets.map(item => item.id),
                        item.tickets[0].scheduleTerm.startedAt,
                        '판매상세',
                      );

                      handleRefetchOrderItem();
                    });

                    return;
                  }

                  setTicketId(item.tickets[0].id);
                  setIsOpenCalendarModal(true);
                }}
                style={{ borderRadius: '8px', color: '#7126FF' }}
              />
            )}
            {item.booking.schedule &&
              new Date(item.booking.schedule.saleTerm.endedAt).getTime() <
                new Date().getTime() && (
                <BasicButton
                  content="예약확정 다시하기"
                  onClick={() => {
                    if (item.booking.schedule !== null) {
                      handleConfirmTicket(item.tickets[0].id, null, () => {
                        HackleManager.logSalesBookingComplete(
                          Number(orderItems.booking.order.id),
                          orderItems.booking.order.customer.id,
                          item.tickets.map(item => item.id),
                          item.tickets[0].scheduleTerm.startedAt,
                          '판매상세',
                        );

                        handleRefetchOrderItem();
                      });

                      return;
                    }

                    setTicketId(item.tickets[0].id);
                    setIsOpenCalendarModal(true);
                  }}
                  style={{ borderRadius: '8px', color: '#7126FF' }}
                />
              )}
          </React.Fragment>
        );
      }
      case BookingState.CONFIRMED: {
        return (
          <React.Fragment>
            <BasicButton
              content="예약확정 취소"
              onClick={() => {
                handleRestoreTicket(item.tickets[0].id, () => {
                  HackleManager.logSalesBookingReverseComplete(
                    Number(orderItems.booking.order.id),
                    orderItems.booking.order.customer.id,
                    item.tickets.map(item => item.id),
                    '판매상세',
                    '판매상세',
                  );

                  handleRefetchOrderItem();
                });
              }}
              style={{ borderRadius: '8px' }}
            />
            {/* <BasicButton
              content="예약일 수정"
              onClick={() => {}}
              style={{ borderRadius: '8px' }}
            /> */}
          </React.Fragment>
        );
      }
      case BookingState.USED: {
        return <></>;
      }
      case BookingState.ABSENT: {
        return (
          <></>
          // <BasicButton
          //   content="불참 처리 취소"
          //   onClick={() => {}}
          //   style={{ borderRadius: '8px', color: '#FF4242' }}
          // />
        );
      }
    }

    return;
  };

  const renderCalendarModal = (): JSX.Element => {
    const dateObject = new Date(saleDate);
    const [hours, minutes] = saleTime.split(':').map(Number);
    dateObject.setHours(hours, minutes);

    return (
      <ModalContainer
        isAbled={!isNaN(dateObject.getTime())}
        isMobile={isMobile}
        onAcceptText="예약일 확정하기"
        onClose={() => setIsOpenCalendarModal(false)}
        onClick={() => {
          handleConfirmTicket(
            ticketId,
            {
              startedAt: dateObject.getTime(),
              duration: 0,
            },
            () => {
              handleRefetchOrderItem();
            },
          );

          setIsOpenCalendarModal(false);
        }}
        style={{ maxWidth: isMobile ? '100%' : '375px' }}
      >
        <Column gap="16px" style={{ padding: '16px 20px' }}>
          <Text color="#1b1c1e" size="16px" weight={600}>
            예약확정
          </Text>
          <Text color="#0071E9" size="16px">
            반드시 크루와 협의된 일시를 입력해 주세요
          </Text>
          <SaleCalendar saleDate={saleDate} setSaleDate={setSaleDate} />
          <Row justifyContent="space-between" style={{ width: '100%' }}>
            <Text color="#1B1C1E" size="16px">
              시작시간
            </Text>
            <DateBox>
              <Text
                color={saleTime ? '#1b1c1e' : '#c2c4c8'}
                size="14px"
                weight={400}
                style={{ zIndex: 2 }}
              >
                {saleTime ? saleTime : '00시 • 00분'}
              </Text>
              <TimeInput
                onChange={e => {
                  setSaleTime(e.target.value);
                }}
              />
            </DateBox>
          </Row>
        </Column>
      </ModalContainer>
    );
  };

  const renderTicketStatusButton = (ticket: Ticket): JSX.Element => {
    switch (item.status) {
      case BookingState.APPLIED: {
        if (ticket.status === TicketState.APPLIED) {
          return (
            <BasicButton
              content="예약확정"
              onClick={() => {
                if (item.booking.schedule !== null) {
                  handleConfirmTicket(ticket.id, null, () => {
                    handleRefetchOrderItem();
                  });

                  return;
                }

                setTicketId(ticket.id);
                setIsOpenCalendarModal(true);
              }}
              style={{ borderRadius: '8px', color: '#7126FF' }}
            />
          );
        }

        return (
          <BasicButton
            content="예약확정 취소"
            onClick={() => {
              handleRestoreTicket(ticket.id, () => {
                HackleManager.logSalesBookingReverseComplete(
                  Number(orderItems.booking.order.id),
                  orderItems.booking.order.customer.id,
                  [ticket.id],
                  '판매상세',
                  '판매상세',
                );

                handleRefetchOrderItem();
              });
            }}
            style={{ borderRadius: '8px' }}
          />
        );
      }
      case BookingState.CONFIRMED: {
        if (ticket.status === TicketState.APPLIED) {
          return (
            <BasicButton
              content="예약확정"
              onClick={() => {
                if (item.booking.schedule !== null) {
                  handleConfirmTicket(ticket.id, null, () => {
                    handleRefetchOrderItem();
                  });

                  return;
                }

                setTicketId(ticket.id);
                setIsOpenCalendarModal(true);
              }}
              style={{ borderRadius: '8px', color: '#7126FF' }}
            />
          );
        }

        return (
          <BasicButton
            content="예약확정 취소"
            onClick={() => {
              handleRestoreTicket(ticket.id, () => {
                HackleManager.logSalesBookingReverseComplete(
                  Number(orderItems.booking.order.id),
                  orderItems.booking.order.customer.id,
                  [ticket.id],
                  '판매상세',
                  '판매상세',
                );

                handleRefetchOrderItem();
              });
            }}
            style={{ borderRadius: '8px' }}
          />
        );
      }
      case BookingState.CANCELED: {
        return (
          <BasicButton
            content="예약확정"
            onClick={() => {}}
            style={{ borderRadius: '8px', color: '#C2C4C8' }}
          />
        );
      }
      default:
        return;
    }
  };

  const renderBookingDateForm = (): JSX.Element => {
    if (item.status === BookingState.CONFIRMED && item.ticketCount === 1) {
      return (
        <React.Fragment>
          <Divider />
          <Column gap="4px">
            <Row justifyContent="space-between" style={{ width: '100%' }}>
              <Text color="#46474C" size="13px" weight={400}>
                예약처리일시
              </Text>
              <Text color="#46474C" size="13px">
                {dayjs(item.tickets[0].confirmedAt).format(
                  dateFormattingForm(),
                )}
              </Text>
            </Row>
            <Row justifyContent="space-between" style={{ width: '100%' }}>
              <Text color="#46474C" size="13px" weight={400}>
                예약일시
              </Text>
              <Text color="#46474C" size="13px">
                {dayjs(item.tickets[0].scheduleTerm?.startedAt).format(
                  dateFormattingForm(),
                )}
              </Text>
            </Row>
          </Column>
        </React.Fragment>
      );
    }

    if (item.status === BookingState.USED) {
      return (
        <React.Fragment>
          <Divider />
          <Column gap="4px">
            <Row justifyContent="space-between" style={{ width: '100%' }}>
              <Text color="#46474C" size="13px" weight={400}>
                예약처리일시
              </Text>
              <Text color="#46474C" size="13px">
                {dayjs(item.tickets[0].confirmedAt).format(
                  dateFormattingForm(),
                )}
              </Text>
            </Row>
            <Row justifyContent="space-between" style={{ width: '100%' }}>
              <Text color="#46474C" size="13px" weight={400}>
                예약일시
              </Text>
              <Text color="#46474C" size="13px">
                {dayjs(item.tickets[0].scheduleTerm.startedAt).format(
                  dateFormattingForm(),
                )}
              </Text>
            </Row>
            <Row justifyContent="space-between" style={{ width: '100%' }}>
              <Text color="#46474C" size="13px" weight={400}>
                사용완료
              </Text>
              <Text color="#46474C" size="13px">
                {dayjs(item.tickets[0].usedAt).format(dateFormattingForm())}
              </Text>
            </Row>
          </Column>
        </React.Fragment>
      );
    }

    return;
  };

  return (
    <Container
      $isSelected={
        selectedTicket.find(selectedItem => selectedItem.id === item.id) !==
        undefined
      }
      onClick={() => {
        if (isMultiTicket) {
          return;
        }

        if (isShowSelectButton()) {
          handleSetSelectedTicket(item);
        }
      }}
    >
      <Column gap="12px" align="flex-start">
        <Row justifyContent="space-between" style={{ width: '100%' }}>
          <OrderStatusButton
            item={item}
            cancelItems={cancelItems}
            status={item.status}
            orderItems={orderItems}
          />
          {isShowSelectButton() && (
            <Row
              gap="6px"
              onClick={() => {
                if (isMultiTicket) {
                  return;
                }

                if (isShowSelectButton()) {
                  handleSetSelectedTicket(item);
                }
              }}
            >
              <CheckBox
                isChecked={
                  selectedTicket.find(
                    selectedItem => selectedItem.id === item.id,
                  ) !== undefined
                }
                onChange={() => {}}
                style={{ width: '20px', height: '20px' }}
              />
              <Text color={isMultiTicket ? '#dbdcdf' : '#989ba2'}>선택</Text>
            </Row>
          )}
        </Row>
        <Divider />
        <Column gap="4px" align="flex-start">
          <Text color="#46474c" size="14px">
            {item.booking.info.name}
          </Text>
          {item.booking.schedule && (
            <Text color="#70737C" size="12px" weight={400}>
              {dayjs(item.booking.schedule.term.startedAt).format(
                dateFormattingForm(),
              )}{' '}
              ~{' '}
              {dayjs(item.booking.schedule.term.endedAt).format(
                dateFormattingForm(),
              )}
            </Text>
          )}
        </Column>
        <Text color="#46474c" size="14px">
          {Number(item.booking.info.price.sale).toLocaleString()}원
        </Text>
        {renderBookingDateForm()}
      </Column>
      {item.ticketCount > 1 && (
        <React.Fragment>
          <Divider />
          <Column gap="12px">
            {item.tickets.map((ticketItem, tickteIdx) => {
              return (
                <Column key={tickteIdx}>
                  {ticketItem.status === TicketState.CONFIRMED ? (
                    <Column align="flex-start" gap="16px">
                      <Text color="#46474C" weight={400}>
                        다회권{tickteIdx + 1}
                      </Text>
                      <Column gap="4px">
                        <Row
                          justifyContent="space-between"
                          style={{ width: '100%' }}
                        >
                          <Text color="#46474C" size="13px" weight={400}>
                            예약처리일시
                          </Text>
                          <Text color="#46474C" size="13px">
                            {dayjs(ticketItem.confirmedAt).format(
                              dateFormattingForm(),
                            )}
                          </Text>
                        </Row>
                        <Row
                          justifyContent="space-between"
                          style={{ width: '100%' }}
                        >
                          <Text color="#46474C" size="13px" weight={400}>
                            예약일시
                          </Text>
                          <Text color="#46474C" size="13px">
                            {dayjs(ticketItem.scheduleTerm.startedAt).format(
                              dateFormattingForm(),
                            )}
                          </Text>
                        </Row>
                      </Column>
                      <Row justifyContent="flex-end" style={{ width: '100%' }}>
                        {renderTicketStatusButton(ticketItem)}
                      </Row>
                      <Divider />
                    </Column>
                  ) : (
                    <Row
                      justifyContent="space-between"
                      style={{ width: '100%' }}
                    >
                      <Text color="#46474C" weight={400}>
                        다회권{tickteIdx + 1}
                      </Text>
                      {renderTicketStatusButton(ticketItem)}
                    </Row>
                  )}
                </Column>
              );
            })}
          </Column>
          <Divider />
        </React.Fragment>
      )}
      {item.status === BookingState.APPLIED &&
        item.booking.schedule &&
        new Date(item.booking.schedule.saleTerm.endedAt).getTime() >
          new Date().getTime() && (
          <Column align="flex-start" gap="12px">
            <Divider />
            <Column align="flex-start" gap="2px">
              <Text color="#1b1c1e" size="13px">
                {dayjs(item.booking.schedule.saleTerm.endedAt).format(
                  dateFormattingForm(),
                )}{' '}
                자동 예약 확정 예정
              </Text>
              <Text color="#70737C" size="12px" weight={400}>
                최소 인원 미달 시 자동 취소
              </Text>
            </Column>
          </Column>
        )}
      {!isEmpty(orderItems.units) && !isEmpty(orderItems.units[0].replies) && (
        <InquiresTemplate orderItems={orderItems} />
      )}
      {isEmpty(selectedTicket) && <Row gap="8px">{renderStatusButton()}</Row>}
      {isOpenCalendarModal && renderCalendarModal()}
      {isOpenCancelModal && (
        <CancelOrderContainer
          isMobile={isMobile}
          orderItems={orderItems}
          bookingItem={item}
          handleRefetchOrderItem={handleRefetchOrderItem}
          handleRequestCancelOrder={handleRequestCancelOrder}
          onClose={() => setIsOpenCancelModal(false)}
        />
      )}
    </Container>
  );
};

export default TicketCard;
