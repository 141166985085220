import { useMutation } from '@apollo/client';
import { SALE_LIST_PATH } from 'constants/path';
import { SignInParams } from 'containers/Login/hooks/types';
import { SignupParam } from 'containers/Signup/hooks/types';
import { useGetMe } from 'graphQL/Auth/user/useGetMe';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { instance } from 'utils/axiosInstance';
import { errorHandler } from 'utils/ErrorHandler';
import { STORAGE_KEY } from 'utils/localStoarge';
import { CREATE_HOST } from '..';
import { UserLoginResponse } from '../types';

export const useMutationCreateHost = () => {
  const navigate = useNavigate();
  const [createHost] = useMutation(CREATE_HOST);

  const { handleRefetchUser } = useGetMe();
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState<boolean>(false);
  const [isOpenFailModal, setIsOpenFailModal] = useState<boolean>(false);

  const handleCreateHost = async (
    params: UserLoginResponse,
    mobileNumber: string,
    signupParam?: SignupParam,
  ) => {
    try {
      const response = await createHost({
        variables: {
          param: {
            name: params.name,
            description: '',
            phoneNumber: mobileNumber,
            personalPhoneNumber: mobileNumber,
            userId: params.userId.toString(),
            email: params.email,
            profileContentId: '',
            introducerTypeId: 'etc',
            etc: 'frip',
            saleManaerName: '',
            refereeName: '',
            refereePhoneNumber: '',
            isEvent: false,
          },
        },
      });

      if (response) {
        setIsOpenSuccessModal(true);

        if (signupParam) {
          handleEmailSignin({
            authorityLevel: 'HOST',
            email: signupParam.email,
            password: signupParam.password,
            store: 'frip',
          });
          // handleRefetchUser();
        }
      }
    } catch (error) {
      setIsOpenFailModal(true);
      errorHandler(error);
    }
  };

  const handleEmailSignin = async (params: SignInParams) => {
    try {
      const response = await instance.post('/signin', params);

      if (response) {
        localStorage.setItem(
          STORAGE_KEY.AUTHORIZATION,
          `${response.data.data.tokenType} ${response.data.data.accessToken}`,
        );

        handleRefetchUser();

        setIsOpenSuccessModal(true);
      }
    } catch (error: any) {
      console.log(error, 'login error');
    }
  };

  return {
    handleCreateHost,
    isOpenSuccessModal,
    isOpenFailModal,
    setIsOpenSuccessModal,
    setIsOpenFailModal,
  };
};
