import Divider from 'components/Divider';
import {
  CONCIERGE_PATH,
  HOST_GUIDE_NOTICE,
  MENU_PATH,
  NOTICE_PATH,
  QNA_LIST_PATH,
  QUESTION_PATH,
  REVIEW_LIST_PATH,
  SETTLEMENT_LIST_PATH,
  SETTLEMENT_PATH,
  SUPER_HOST_NOTICE,
  TERM_NOTICE_PATH,
} from 'constants/path';
import HostApplicationContainer from 'containers/Menu/components/HostApplicationContainer';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import HackleManager from 'utils/hackle';
import Row from '../Row';
import Text from '../Text';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  position: absolute;
  top: 35px;
  left: 0;
  padding: 16px 20px;
  width: 200px;
  border-radius: 12px;
  background: var(--Static-White, #fff);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  z-index: 19;
`;

const PrepareButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 4px;
  background: var(--gray-gray-03, #eaebec);

  color: var(--gray-gray-08, #46474c);
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px; /* 130% */
  letter-spacing: -0.1px;
`;

interface MenuItemsIProps {
  onClose: () => void;
}

const MenuItems: React.FC<MenuItemsIProps> = props => {
  const { onClose } = props;

  const navigate = useNavigate();

  const [isOpenHostApplication, setIsOpenHostApplication] =
    useState<boolean>(false);

  return (
    <Container onMouseLeave={onClose}>
      <Text
        color="#46474C"
        onClick={() => {
          navigate(MENU_PATH);
          onClose();
        }}
        style={{ cursor: 'pointer' }}
      >
        전체 보기
      </Text>
      <Divider style={{ color: '#dbdcdf' }} />
      <Text
        color="#46474C"
        onClick={() => {
          HackleManager.logMenuItemClick('정산 정보 등록');

          navigate(SETTLEMENT_LIST_PATH);
          onClose();
        }}
        style={{ cursor: 'pointer' }}
      >
        정산 신청
      </Text>
      <Text
        color="#46474C"
        onClick={() => {
          HackleManager.logMenuItemClick('정산 내역 및 지급 요청');

          navigate(SETTLEMENT_PATH);
          onClose();
        }}
        style={{ cursor: 'pointer' }}
      >
        정산 정보 관리
      </Text>
      <Divider style={{ color: '#dbdcdf' }} />
      <Text
        color="#46474C"
        onClick={() => {
          HackleManager.logMenuItemClick('문의 관리');

          navigate(QNA_LIST_PATH);
          onClose();
        }}
        style={{ cursor: 'pointer' }}
      >
        문의 관리
      </Text>
      <Text
        color="#46474C"
        onClick={() => {
          HackleManager.logMenuItemClick('후기 관리');

          navigate(REVIEW_LIST_PATH);
          onClose();
        }}
        style={{ cursor: 'pointer' }}
      >
        후기 관리
      </Text>
      <Divider style={{ color: '#dbdcdf' }} />
      <Row
        justifyContent="space-between"
        style={{ width: '100%' }}
        onClick={() => {
          HackleManager.logMenuItemClick('배송 관리');
        }}
      >
        <Text>배송 관리</Text>
        <PrepareButton>준비중</PrepareButton>
      </Row>
      <Divider style={{ color: '#dbdcdf' }} />
      <Text
        color="#46474C"
        onClick={() => {
          HackleManager.logMenuItemClick('공지사항');

          navigate(NOTICE_PATH);
          onClose();
        }}
        style={{ cursor: 'pointer' }}
      >
        공지사항
      </Text>
      <a href={QUESTION_PATH} target="_blank">
        <Text
          color="#46474C"
          onClick={() => {
            HackleManager.logMenuItemClick('자주 하는 질문');
          }}
          style={{ cursor: 'pointer' }}
        >
          자주 하는 질문
        </Text>
      </a>
      <Text
        color="#46474C"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          HackleManager.logMenuItemClick('호스트 지원센터');

          setIsOpenHostApplication(true);
        }}
      >
        호스트 지원센터
      </Text>
      <Divider style={{ color: '#dbdcdf' }} />
      <a href={HOST_GUIDE_NOTICE} target="_blank">
        <Text
          color="#46474C"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            HackleManager.logMenuItemClick('호스트 가이드');
          }}
        >
          호스트 가이드
        </Text>
      </a>
      <a href={SUPER_HOST_NOTICE} target="_blank">
        <Text
          color="#46474C"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            HackleManager.logMenuItemClick('슈퍼호스트 안내');
          }}
        >
          슈퍼호스트 안내
        </Text>
      </a>
      <a href={CONCIERGE_PATH} target="_blank">
        <Text
          color="#46474C"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            HackleManager.logMenuItemClick('컨시어지 서비스');
          }}
        >
          컨시어지 서비스
        </Text>
      </a>
      <Divider style={{ color: '#dbdcdf' }} />
      <a href={TERM_NOTICE_PATH} target="_blank">
        <Text
          color="#46474C"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            HackleManager.logMenuItemClick('이용약관');
          }}
        >
          이용약관
        </Text>
      </a>
      {isOpenHostApplication && (
        <HostApplicationContainer
          isMobile={false}
          onClose={() => setIsOpenHostApplication(false)}
        />
      )}
    </Container>
  );
};

export default MenuItems;
