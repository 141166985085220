import React from 'react';
import styled from 'styled-components';
import { ICON_PLUS_GRAY } from 'assets/icons';

const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 12px;
  width: 100%;

  border-radius: 8px;
  border: 1px dashed var(--gray-gray-04, #dbdcdf);
  background: var(--gray-gray-01, #f7f7f8);

  color: #70737c;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
`;

interface DashButtonIProps {
  text: string;
  onClick: () => void;
  style?: React.CSSProperties;
}

const DashButton: React.FC<DashButtonIProps> = (props) => {
  const { text, onClick, style } = props;

  return (
    <Container style={{ ...style }} onClick={onClick}>
      <img src={ICON_PLUS_GRAY} alt="plus icon" />
      <span>{text}</span>
    </Container>
  );
};

export default DashButton;
