import { useLazyQuery } from '@apollo/client';
import { GET_QNA_PRODUCTS } from '..';

export function useLazyQueryGetQnaProducts() {
  const [getQnaProducts] = useLazyQuery(GET_QNA_PRODUCTS);

  const handleGetQnaProducts = async (
    hostId: string,
    searchValue: string,
    callback: (idList: string[]) => void
  ) => {
    try {
      const response = await getQnaProducts({
        variables: {
          filter: { titleLike: searchValue, hostId },
        },
      });

      if (response) {
        const idList = response.data.product.products.edges.map(
          (item) => item.node.id
        );
        callback(idList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return { handleGetQnaProducts };
}
