import React, { useMemo, useState } from 'react';
import ReactQuill from 'react-quill';
import styled from 'styled-components';
import ModifyContainer from '../ModifyContainer';
import { ModifyValue } from '../../hooks/useProductDetaillHooks';

const ViewQuillContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 1;

  .ql-editor {
    padding: 0;
  }

  img {
    width: 100%;
  }
`;

interface IntroduceContainerIProps {
  isMobile: boolean;
  editorValue: string;
  modifyValue: ModifyValue;
  setModifyValue: React.Dispatch<React.SetStateAction<ModifyValue>>;
}

const IntroduceContainer: React.FC<IntroduceContainerIProps> = props => {
  const { isMobile, editorValue, modifyValue, setModifyValue } = props;

  return (
    <ModifyContainer
      title="상세 정보"
      isSelected={!isMobile && modifyValue.modifyStatus === 'introduce'}
      onClick={() => {
        setModifyValue({ ...modifyValue, modifyStatus: 'introduce' });
      }}
      style={{ maxHeight: '370px', overflowY: 'scroll' }}
      isScrollable={true}
    >
      <ViewQuillContainer>
        <ReactQuill
          readOnly={true}
          theme="bubble"
          style={{ width: '100%', padding: 0 }}
          value={editorValue}
        />
      </ViewQuillContainer>
    </ModifyContainer>
  );
};

export default IntroduceContainer;
