import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Column from 'components/common/Column';
import Text from 'components/common/Text';
import Row from 'components/common/Row';

import { IMAGE_REGIST_PRODUCT_INFORMATION } from 'assets/images';
import HackleManager from 'utils/hackle';

interface ChapterOneIProps {
  isMobile: boolean;
}

const ChapterOne: React.FC<ChapterOneIProps> = props => {
  const { isMobile } = props;

  const params = useParams<{ id: string }>();

  useEffect(() => {
    HackleManager.logCreateStepThreeView(
      params.id !== 'draft' ? Number(params.id) : undefined,
    );
  }, []);

  const renderResponsivePage = (): JSX.Element => {
    if (isMobile) {
      return (
        <Column gap="32px">
          <img
            src={IMAGE_REGIST_PRODUCT_INFORMATION}
            alt="image regist product sell"
            style={{ width: '220px' }}
          />
          <Column gap="12px" align="flex-start">
            <Column gap="4px" align="flex-start">
              <Text color="#1b1c1e" size="14px">
                3단계
              </Text>
              <Text color="#1b1c1e" size="24px" style={{ lineHeight: '32px' }}>
                마지막 단계에요! 프립을 돋보이게 해주세요
              </Text>
            </Column>
            <Text color="#70737C" size="16px" weight={400}>
              이 단계에서는 호스트님의 프립을 돋보이게 할 프립명, 대표 이미지,
              상세 정보, 그리고 추가 정보를 설정해요.
            </Text>
          </Column>
        </Column>
      );
    }

    return (
      <Row gap="120px" style={{ width: '100%' }}>
        <Column gap="16px" align="flex-start">
          <Column gap="12px" align="flex-start">
            <Text color="#1b1c1e" size="14px">
              3단계
            </Text>
            <Text color="#1b1c1e" size="24px" style={{ lineHeight: '32px' }}>
              마지막 단계에요! 프립을 돋보이게 해주세요
            </Text>
          </Column>
          <Text color="#70737C" size="16px" weight={400}>
            이 단계에서는 호스트님의 프립을 돋보이게 할 프립명, 대표 이미지,
            상세 정보, 그리고 추가 정보를 설정해요.
          </Text>
        </Column>
        <img
          src={IMAGE_REGIST_PRODUCT_INFORMATION}
          alt="image regist product sell"
          style={{ width: '220px' }}
        />
      </Row>
    );
  };

  return renderResponsivePage();
};

export default ChapterOne;
