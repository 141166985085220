import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { ModifyValue } from 'containers/ProductDetail/hooks/useProductDetaillHooks';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import ProfileUpdateContainer from 'containers/Menu/components/ProfileUpdateContainer';
import { Product } from 'types/api';
import HackleManager from 'utils/hackle';

interface ProfileModifyFormIProps {
  isMobile: boolean;
  modifyValue: ModifyValue;
  productInfo: Product;
  setModifyValue: Dispatch<SetStateAction<ModifyValue>>;
}

const ProfileModifyForm: React.FC<ProfileModifyFormIProps> = props => {
  const { isMobile, modifyValue, productInfo, setModifyValue } = props;

  useEffect(() => {
    HackleManager.logProductModifyHostProfileView(
      Number(productInfo.id),
      productInfo.title,
    );
  }, []);

  const renderContents = (): JSX.Element => {
    if (isMobile) {
      return (
        <ModalContainer
          isMobile={isMobile}
          isAbled={true}
          isBottomSheet={true}
          onClose={() => {
            setModifyValue({ ...modifyValue, modifyStatus: '' });
          }}
          onCloseText="닫기"
          onAcceptText="적용"
          onClick={() => {
            HackleManager.logProductModifyItemSaveComplete(
              Number(productInfo.id),
              productInfo.title,
              '프로필',
            );
            setModifyValue({ ...modifyValue, modifyStatus: 'profile' });
          }}
        >
          <Column gap="24px" style={{ padding: '16px 0 0' }}>
            <Row>
              <Text color="#000" size="16px" weight={600}>
                호스트 프로필
              </Text>
            </Row>
            <ProfileUpdateContainer
              isMobile={isMobile}
              type="modify"
              onClose={() =>
                setModifyValue({ ...modifyValue, modifyStatus: '' })
              }
            />
          </Column>
        </ModalContainer>
      );
    }

    return (
      <Column>
        <Row
          justifyContent="flex-start"
          style={{
            padding: '32px 40px',
            width: '100%',
            background: '#fff',
            borderBottom: '1px solid #eaebec',
          }}
        >
          <Text color="#1B1C1E" size="24px">
            호스트 프로필 수정하기
          </Text>
        </Row>
        <Column style={{ padding: '32px 40px' }}>
          <ProfileUpdateContainer
            isMobile={isMobile}
            type="modify"
            onClose={() => {
              HackleManager.logProductModifyItemSaveComplete(
                Number(productInfo.id),
                productInfo.title,
                '프로필',
              );
              setModifyValue({ ...modifyValue, modifyStatus: 'profile' });
            }}
          />
        </Column>
      </Column>
    );
  };

  return renderContents();
};

export default ProfileModifyForm;
