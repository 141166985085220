import { useMutation } from '@apollo/client';
import { PRODUCT_LIST_PATH } from 'constants/path';
import { useNavigate } from 'react-router-dom';
import { errorHandler } from 'utils/ErrorHandler';
import {
  CANCEL_INSPECTION,
  CLOSE_PRODUCT,
  OPEN_PRODUCT,
  PAUSE_PRODUCT,
  REEDIT_PRODUCT,
  RESUME_PRODUCT,
  SUSPEND_PRODUCT,
  TERMINATE_PRODUCT,
} from '..';

export function useMutationProductStatus(productId: string) {
  const navigate = useNavigate();

  const [cancelInspection] = useMutation(CANCEL_INSPECTION);
  const [openProduct] = useMutation(OPEN_PRODUCT);
  const [pauseProduct] = useMutation(PAUSE_PRODUCT);
  const [closeProduct] = useMutation(CLOSE_PRODUCT);
  const [reeditProduct] = useMutation(REEDIT_PRODUCT);
  const [suspendProduct] = useMutation(SUSPEND_PRODUCT);
  const [resumeProduct] = useMutation(RESUME_PRODUCT);
  const [terminateProduct] = useMutation(TERMINATE_PRODUCT);

  const handleOnChangeProductStatus = async (status: string) => {
    try {
      let response;

      switch (status) {
        case 'cancelInspection': {
          response = await cancelInspection({ variables: { productId } });

          if (response) {
            navigate(PRODUCT_LIST_PATH);
          }

          return;
        }
        case 'open': {
          response = await openProduct({ variables: { id: productId } });

          if (response) {
            window.location.reload();
          }

          return;
        }
        case 'pause': {
          response = await pauseProduct({ variables: { id: productId } });

          if (response) {
            window.location.reload();
          }

          return;
        }
        case 'close': {
          response = await closeProduct({ variables: { id: productId } });

          if (response) {
            window.location.reload();
          }

          return;
        }
        case 'reedit': {
          response = await reeditProduct({ variables: { id: productId } });

          if (response) {
            window.location.reload();
          }

          return;
        }
        case 'suspend': {
          response = await suspendProduct({ variables: { id: productId } });

          if (response) {
            window.location.reload();
          }

          return;
        }
        case 'resume': {
          response = await resumeProduct({ variables: { id: productId } });

          if (response) {
            window.location.reload();
          }

          return;
        }
        case 'terminate': {
          response = await terminateProduct({ variables: { id: productId } });

          if (response) {
            window.location.reload();
          }

          return;
        }
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  return { handleOnChangeProductStatus };
}
