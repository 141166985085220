import React, { SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import OptionButton from '../../common/OptionButton';
import OptionContainer from './OptionContainer';
import { ONLY_NUMBER_REGEX } from 'utils/regExp';
import { ScheduleParam } from 'containers/ProductRegist/hooks/types';

dayjs.extend(duration);

const SelectDateForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  padding: 8px 12px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;

  input {
    &::placeholder {
      color: #c2c2c8;
    }
  }
`;

const DateBox = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 8px 12px;
  position: relative;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;
  cursor: pointer;
`;

interface OptionDueDateFormIProps {
  scheduleItem: ScheduleParam;
  endDateFormat: string;
  termDate: { startDate: string; endDate: string; durationDate: string };
  endDueDate: {
    date: number;
    time: number;
  };
  isDisable: boolean;
  setEndDueDate: (
    value: React.SetStateAction<{
      date: number;
      time: number;
    }>,
  ) => void;
  setEndDateFormat: React.Dispatch<SetStateAction<string>>;
}

const OptionDueDateForm: React.FC<OptionDueDateFormIProps> = props => {
  const {
    scheduleItem,
    endDateFormat,
    termDate,
    endDueDate,
    isDisable,
    setEndDueDate,
    setEndDateFormat,
  } = props;

  const diff = scheduleItem.term.startedAt - scheduleItem.saleTerm.endedAt;
  const durationObj = dayjs.duration(diff); // 차이를 duration으로 변환

  // 일, 시간, 분 단위로 분리
  const days = Math.floor(durationObj.asDays());
  const hours = durationObj.subtract(days, 'days').hours();

  useEffect(() => {
    if (scheduleItem.saleTerm.startedAt !== 0) {
      setEndDateFormat('select');
    }
  }, []);

  const renderContents = (): JSX.Element => {
    if (isDisable) {
      return (
        <OptionContainer>
          <Column align="flex-start">
            <Text color="#1b1c1e" size="16px">
              신청 마감일
            </Text>
          </Column>
          <Column gap="8px">
            <Row gap="7px" style={{ width: '100%' }}>
              <OptionButton
                text="당일 1시간 전까지"
                isSelected={false}
                onClick={() => {}}
                style={{ color: '#DBDCDF' }}
              />
              <OptionButton
                text="1일 전 까지"
                isSelected={false}
                onClick={() => {}}
                style={{ color: '#DBDCDF' }}
              />
            </Row>
            <Row gap="7px" style={{ width: '100%' }}>
              <OptionButton
                text={
                  <Text color="#1b1c1e" size="13px">
                    3일 전 까지 <span style={{ color: '#7126FF' }}>(권장)</span>
                  </Text>
                }
                isSelected={false}
                onClick={() => {}}
              />
              <OptionButton
                text="날짜 지정"
                isSelected={scheduleItem.saleTerm.startedAt !== 0}
                onClick={() => {}}
                style={{ color: '#DBDCDF' }}
              />
            </Row>
          </Column>
        </OptionContainer>
      );
    }

    return (
      <OptionContainer>
        <Column align="flex-start">
          <Text color="#1b1c1e" size="16px">
            신청 마감일
          </Text>
          <Text color="#70737c" size="12px" weight={400}>
            신청 마감일이 지나면 판매가 종료되고, 최소 인원 충족 여부를 확인해
            모임의 취소 여부를 결정합니다.
          </Text>
        </Column>
        <Column gap="8px">
          <Row gap="7px" style={{ width: '100%' }}>
            <OptionButton
              text="1일 전 까지"
              isSelected={endDateFormat === '1day'}
              onClick={() => {
                setEndDateFormat('1day');
              }}
            />
            <OptionButton
              text="2일 전 까지"
              isSelected={endDateFormat === '2day'}
              onClick={() => {
                setEndDateFormat('2day');
              }}
            />
          </Row>
          <Row gap="7px" style={{ width: '100%' }}>
            <OptionButton
              text={
                <Text color="#1b1c1e" size="13px">
                  3일 전 까지 <span style={{ color: '#7126FF' }}>(권장)</span>
                </Text>
              }
              isSelected={endDateFormat === '3day'}
              onClick={() => {
                setEndDateFormat('3day');
              }}
            />
            <OptionButton
              text="날짜 지정"
              isSelected={endDateFormat === 'select'}
              onClick={() => {
                setEndDateFormat('select');
              }}
            />
          </Row>
        </Column>
        {endDateFormat === 'select' && (
          <SelectDateForm>
            <Text size="12px" color="#1B1C1E" weight={400}>
              선택한 날짜로 부터
            </Text>
            <Row gap="8px">
              <DateBox>
                <input
                  style={{
                    width: '32px',
                    border: 'none',
                    textAlign: 'right',
                  }}
                  type="text"
                  placeholder="0"
                  value={
                    scheduleItem.saleTerm.startedAt !== 0
                      ? days
                      : endDueDate.date !== 0
                      ? endDueDate.date
                      : undefined
                  }
                  onChange={e => {
                    setEndDueDate({
                      ...endDueDate,
                      date: Number(
                        e.target.value.replaceAll(ONLY_NUMBER_REGEX, ''),
                      ),
                    });
                  }}
                />
                <Text
                  color={endDueDate.date !== 0 ? '#1b1c1e' : '#c2c4c8'}
                  size="14px"
                  weight={400}
                >
                  일
                </Text>
              </DateBox>
              <DateBox>
                <input
                  style={{
                    width: '32px',
                    border: 'none',
                    textAlign: 'right',
                  }}
                  type="text"
                  placeholder="0"
                  value={
                    scheduleItem.term.startedAt !== 0
                      ? hours
                      : endDueDate.time !== 0
                      ? endDueDate.time
                      : undefined
                  }
                  onChange={e => {
                    setEndDueDate({
                      ...endDueDate,
                      time: Number(
                        e.target.value.replaceAll(ONLY_NUMBER_REGEX, ''),
                      ),
                    });
                  }}
                />
                <Text
                  color={endDueDate.time !== 0 ? '#1b1c1e' : '#c2c4c8'}
                  size="14px"
                  weight={400}
                >
                  시간
                </Text>
              </DateBox>
              <Text color="#1b1c1e" weight={400}>
                전 까지 판매할게요
              </Text>
            </Row>
          </SelectDateForm>
        )}
      </OptionContainer>
    );
  };

  return renderContents();
};

export default OptionDueDateForm;
