import React from 'react';
import { styled } from 'styled-components';

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;

  border-radius: 999px;
  border: 1px solid #dbdcdf;
  background: #fff;

  color: #1b1c1e;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.6px;
`;

interface SelectButtonIProps {
  text: string | React.ReactElement;
  onClick: () => void;
}

const SelectButton: React.FC<SelectButtonIProps> = (props) => {
  const { text, onClick } = props;

  return <Button onClick={onClick}>{text}</Button>;
};

export default SelectButton;
