import Text from 'components/common/Text';
import Divider from 'components/Divider';
import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';
import { Term, TicketState } from 'types/api';

const Container = styled.button<{ background: string; border: string }>`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 4px 6px;
  min-width: 45px;
  border-radius: 2px;
  border: 1px solid ${(props) => props.border};
  background: ${(props) => props.background};
`;

interface CalendarStatusButtonIProps {
  status: TicketState;
  scheduleTerm: Term;
  customerName: string;
  onClick: () => void;
}

const CalendarStatusButton: React.FC<CalendarStatusButtonIProps> = (props) => {
  const { status, scheduleTerm, customerName, onClick } = props;

  const renderStatusButton = (): JSX.Element => {
    switch (status) {
      case TicketState.USED: {
        return (
          <Container background="#EAEBEC" border="#C2C4C8" onClick={onClick}>
            <Text
              size="10px"
              color="#70737c"
              style={{
                display: '-webkit-box',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {customerName}
            </Text>
            <Divider style={{ background: '#70737C' }} />
            <Text
              size="10px"
              color="#70737c"
              style={{ textWrap: 'wrap', textAlign: 'left' }}
            >
              {`${dayjs(scheduleTerm.startedAt).format('HH:mm')} ~ ${dayjs(
                scheduleTerm.endedAt
              ).format('HH:mm')}`}
            </Text>
          </Container>
        );
      }
      case TicketState.CONFIRMED: {
        return (
          <Container background="#B3D5F9" border="#005DC0" onClick={onClick}>
            <Text
              size="10px"
              color="#005DC0"
              style={{
                display: '-webkit-box',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {customerName}
            </Text>
            <Divider style={{ background: '#005DC0' }} />
            <Text
              size="10px"
              color="#005DC0"
              style={{ textWrap: 'wrap', textAlign: 'left' }}
            >
              {`${dayjs(scheduleTerm.startedAt).format('HH:mm')} ~ ${dayjs(
                scheduleTerm.endedAt
              ).format('HH:mm')}`}
            </Text>
          </Container>
        );
      }
      case TicketState.ABSENT: {
        return (
          <Container background="#FCECEB" border="#FFC6C6" onClick={onClick}>
            <Text
              size="10px"
              color="#FF8484"
              style={{
                display: '-webkit-box',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {customerName}
            </Text>
            <Divider style={{ background: '#FF8484' }} />
            <Text
              size="10px"
              color="#FF8484"
              style={{ textWrap: 'wrap', textAlign: 'left' }}
            >
              {`${dayjs(scheduleTerm.startedAt).format('HH:mm')} ~ ${dayjs(
                scheduleTerm.endedAt
              ).format('HH:mm')}`}
            </Text>
          </Container>
        );
      }
    }

    return;
  };

  return renderStatusButton();
};

export default CalendarStatusButton;
