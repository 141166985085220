import {
  Inquiries,
  InquiryTemplateParamInput,
} from 'containers/ProductRegist/components/InformationRegist/graphQL/types';
import { InquiryTemplate, InquiryTemplateItem } from 'types/api';

export class ConvertingInquiryTemplates {
  public convertInquiryTemplateParamInput(
    param: InquiryTemplate,
  ): InquiryTemplateParamInput {
    const paramInput: InquiryTemplateParamInput = {
      name: param ? param.name : '',
      activated: true,
      inquiries: param ? this.convertInquries(param.inquiries) : [],
    };

    return paramInput;
  }

  public convertInquries(param: InquiryTemplateItem[]): Inquiries[] {
    const paramInput = param.map(item => {
      return {
        id: item.id ? item.id : '',
        title: item.title,
        inputKind: item.inputKind,
        inputPattern: item.inputPattern,
        required: item.required,
        activated: false,
        items: item.items.map(item => {
          return { label: item.label };
        }),
      };
    });

    return paramInput;
  }

  public convertInquriesView(param: InquiryTemplateItem): Inquiries {
    const paramInput = {
      id: param.id ? param.id : '',
      title: param.title,
      inputKind: param.inputKind,
      inputPattern: param.inputPattern,
      required: param.required,
      activated: false,
      items: param.items.map(item => {
        return { label: item.label };
      }),
    };

    return paramInput;
  }
}
