import React, { useState } from 'react';
import dayjs from 'dayjs';

import Column from 'components/common/Column';
import Text from 'components/common/Text';
import ModifyContainer from '../ModifyContainer';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import { ModifyValue } from '../../hooks/useProductDetaillHooks';

interface SaleTermContainerIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  modifyValue: ModifyValue;
  setModifyValue: React.Dispatch<React.SetStateAction<ModifyValue>>;
}

const SaleTermContainer: React.FC<SaleTermContainerIProps> = props => {
  const { isMobile, productParamInput, modifyValue, setModifyValue } = props;

  const saleTerm = productParamInput.saleTerm;

  return (
    <ModifyContainer
      title="판매 일정"
      isSelected={!isMobile && modifyValue.modifyStatus === 'saleTerm'}
      onClick={() => {
        setModifyValue({ ...modifyValue, modifyStatus: 'saleTerm' });
      }}
    >
      <Column gap="8px" align="flex-start">
        <Text color="#46474C" size="14px" weight={400}>
          판매 시작일:{' '}
          {productParamInput.firstOpenedAt
            ? dayjs(productParamInput.firstOpenedAt).format(
                'YYYY년 • M월 • D일',
              )
            : saleTerm.startedAt
            ? dayjs(saleTerm.startedAt).format('YYYY년 • M월 • D일')
            : '-'}
        </Text>
        <Text color="#46474C" size="14px" weight={400}>
          판매 종료일:{' '}
          {saleTerm.endedAt
            ? dayjs(saleTerm.endedAt).format('YYYY년 • M월 • D일')
            : '-'}
        </Text>
      </Column>
    </ModifyContainer>
  );
};

export default SaleTermContainer;
