import { useLazyQuery, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { MeData } from 'graphQL/Auth/types';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import { useEffect, useState } from 'react';
import {
  BookingConnection,
  BookingState,
  BookingTicket,
  Ticket,
  TicketEdge,
  TicketState,
} from 'types/api';
import { errorHandler } from 'utils/ErrorHandler';
import { GET_BOOKING_TICKETS } from '../graphQL';
import { BookingTermType } from '../graphQL/types';
import { TicketFilterInput } from './useBookingListHooks';

export const useBookingCalendarHooks = () => {
  const myInfo: MeData = useUserInformation(state => state.userInfo);

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  const startedAt = new Date(currentYear, currentMonth, 1).getTime();
  const endedAt = new Date(
    currentYear,
    currentMonth + 1,
    0,
    23,
    59,
    59,
    999,
  ).getTime();

  const [ticketFilterInput, setTicketFilterInput] = useState<TicketFilterInput>(
    {
      page: 1,
      size: 20,
      filter: {
        hostId: myInfo.me.host.id,
        statusIn: [TicketState.CONFIRMED, TicketState.USED, TicketState.ABSENT],
        termWithType: {
          term: { startedAt, endedAt },
          type: BookingTermType.STARTING,
        },
      },
    },
  );
  const [bookingTickets, setBookingTickets] = useState<
    {
      date: string;
      value: Ticket[];
    }[]
  >([]);

  const [getBookingTickets, { data, refetch }] = useLazyQuery<BookingTicket>(
    GET_BOOKING_TICKETS,
    {
      variables: ticketFilterInput,
    },
  );

  const handleSetTicekFilterInput = (key: string, value: any) => {
    setTicketFilterInput({ ...ticketFilterInput, [key]: value });
  };

  const convertTimestampToDate = timestamp => {
    return dayjs(timestamp).format('YYYY년 MM월 DD일');
  };

  // 데이터를 날짜별로 그룹화하는 함수
  const groupDataByDate = (data: TicketEdge[]) => {
    let result = [];

    for (let idx = 0; idx < data.length; idx++) {
      const dateString = convertTimestampToDate(
        data[idx].node.scheduleTerm.startedAt,
      );

      // result에서 해당 날짜가 존재하는지 확인
      const existingEntryIndex = result.findIndex(
        item => item.date === dateString,
      );

      if (existingEntryIndex !== -1) {
        // 이미 존재하는 날짜일 경우 value에 새로운 데이터를 추가
        result[existingEntryIndex].value.push(data[idx].node);
      } else {
        // 존재하지 않는 날짜일 경우 새로운 항목 추가
        result = [
          ...result,
          {
            date: dateString,
            value: [data[idx].node],
          },
        ];
      }
    }

    setBookingTickets(result);
  };

  const handleRefetchBookingTickets = async () => {
    try {
      await refetch({ ticketFilterInput });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getBookingTickets({ variables: { ticketFilterInput } });
  }, []);

  useEffect(() => {
    if (data) {
      groupDataByDate(data.booking.tickets.edges);
    }
  }, [data]);

  return {
    bookingTickets,
    ticketFilterInput,
    handleSetTicekFilterInput,
    handleRefetchBookingTickets,
  };
};
