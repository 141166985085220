import React from 'react';
import styled from 'styled-components';
import { SettlementsNode } from '../graphQL/types';

import { ICON_CLOSE } from 'assets/icons';
import Text from 'components/common/Text';
import HackleManager from 'utils/hackle';
import { formatInvoiceStatus } from 'utils/hackle/parameterFormatting';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 12px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  background-color: #fff;
  border-radius: 8px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px 20px 16px 20px;
  width: 100%;

  border-bottom: 1px solid #dbdcdf;
`;

const SettlementContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Divider = styled.div`
  width: 100%;
  border: 0.5px solid #eaebec;
`;

const Bottom = styled(Header)`
  justify-content: flex-end;
  padding: 16px 20px;
  border-top: 1px solid #dbdcdf;
  border-bottom: none;
`;

const RequestButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  border-radius: 8px;
  background: #1b1c1e;

  color: #fff;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  letter-spacing: -0.6px;
`;

interface SettlementRequestModalIProps {
  settlementItem: SettlementsNode;
  type: string;
  handleRequestInvoice?: (id: string) => void;
  onClose: () => void;
}

const SettlementRequestModal: React.FC<
  SettlementRequestModalIProps
> = props => {
  const { settlementItem, type, handleRequestInvoice, onClose } = props;

  const isMinusSettlement =
    Number(settlementItem.totalPayment.value) +
      Number(settlementItem.totalFee.value) <
    0;

  return (
    <Container>
      <ModalContainer>
        <Header>
          {type === 'request' && (
            <img
              src={ICON_CLOSE}
              alt="close button"
              onClick={onClose}
              style={{ position: 'absolute', left: '20px' }}
            />
          )}
          <Text color="#1b1c1e" size="16px" weight={400}>
            {type === 'request' ? '정산 지급을 요청할까요?' : '정산 정보'}
          </Text>
        </Header>
        <SettlementContainer>
          <Column style={{ gap: '4px' }}>
            <Text color="#1b1c1e" size="20px">
              {settlementItem.title.slice(6)}
            </Text>
            {type === 'request' && (
              <Text color="#70737c" size="14px" weight={400}>
                요청 시 일반적으로 가까운 수요일 날짜에 정산금이 지급됩니다.
              </Text>
            )}
          </Column>
          <Column>
            <Row>
              <Text color="#1b1c1e" size="16px" weight={400}>
                총수입
              </Text>
              <Text color="#1b1c1e" size="16px" weight={400}>
                <span
                  style={{ color: isMinusSettlement ? '#ff4242' : '#1b1c1e' }}
                >
                  {(
                    Number(settlementItem.totalPayment.value) +
                    Number(settlementItem.totalFee.value)
                  ).toLocaleString()}
                  원
                </span>
              </Text>
            </Row>
            <Row>
              <Text color="#1b1c1e" size="16px" weight={400}>
                플랫폼 수수료
              </Text>
              <Text color="#1b1c1e" size="16px" weight={400}>
                <span
                  style={{
                    color: '#FF4242',
                  }}
                >
                  {Number(settlementItem.totalFee.value).toLocaleString()}원
                </span>
              </Text>
            </Row>
          </Column>
          <Divider />
          <Row>
            <Text color="#1b1c1e" size="16px" weight={600}>
              총 지급 예정 금액
            </Text>
            <Text color="#1b1c1e" size="16px" weight={600}>
              <span
                style={{
                  color: isMinusSettlement ? '#FF4242' : '#1b1c1e',
                }}
              >
                {Number(settlementItem.totalPayment.value).toLocaleString()}원
              </span>
            </Text>
          </Row>
        </SettlementContainer>
        <Bottom>
          {type === 'request' ? (
            <RequestButton
              onClick={() => {
                HackleManager.logSettlementListPayoutRequestClick(
                  settlementItem.id,
                  settlementItem.title,
                  formatInvoiceStatus(settlementItem.invoice?.status),
                  Number(settlementItem.totalFee.value) +
                    Number(settlementItem.totalPayment.value),
                  Number(settlementItem.totalFee.value),
                  Number(settlementItem.totalPayment.value),
                );

                handleRequestInvoice && handleRequestInvoice(settlementItem.id);
                onClose();
              }}
            >
              네,요청할게요
            </RequestButton>
          ) : (
            <RequestButton
              onClick={() => {
                onClose();
              }}
            >
              확인
            </RequestButton>
          )}
        </Bottom>
      </ModalContainer>
    </Container>
  );
};

export default SettlementRequestModal;
