import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';

import Row from 'components/common/Row';
import Text from 'components/common/Text';
import { ICON_ARROW_GRAY_16, ICON_FRIP_LOGO } from 'assets/icons';
import Column from 'components/common/Column';
import InputForm from './components/InputForm';
import AuthButton from 'components/Button/AuthButton';
import { IMAGE_LOGIN_BANNER } from 'assets/images';
import { useLoginHooks } from './hooks/useLoginHooks';
import KakaoSDK from './components/KakaoSDK';
import FindPasswordModal from './components/FindPasswordModal';
import BottomToast from 'components/Modal/BottomToast';
import FaceBookSDK from './components/FaceBookSDK';
import SignupCertification from 'containers/Signup/components/SignupCertification';
import SignupTerm from 'containers/Signup/components/SignupTerm';
import {
  GUIDE_PATH,
  KAKAO_CUSTOMER_SERVICE,
  PROFILE_PATH,
  SIGNUP_PATH,
  START_PATH,
} from 'constants/path';
import BackButton from 'components/Button/BackButton';
import { isWebview } from 'utils/webview';
import SignupSuccessModal from 'containers/Signup/components/SignupSuccessModal';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import { MeData } from 'graphQL/Auth/types';
import HackleManager from 'utils/hackle';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  width: 100%;
  /* min-height: 100vh; */
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
`;

const LoginPage = () => {
  const navigate = useNavigate();
  const myInfo: MeData = useUserInformation(state => state.userInfo);

  const {
    signInParams,
    signupParam,
    isOpenFindPasswordModal,
    isOpenBottomToast,
    isShowSignupForm,
    isMobileCertification,
    isFailedLogin,
    isOpenSuccessModal,
    handleOnchangeSignInParams,
    handleEmailSignin,
    handleOnChangeFindPasswordModal,
    handleFindPassword,
    setIsOpenBottomToast,
    handleIsShowSignupForm,
    handleChangeSignupParam,
    handelCheckedMarketing,
    handleMobileCertification,
    handleSetLoginInfo,
    handleRegistHost,
    handleRefetchUser,
  } = useLoginHooks();

  const [isTermChecked, setIsTermChecked] = useState<boolean>(false);

  const handleTermCheckedCallback = (termChecked: boolean) => {
    setIsTermChecked(termChecked);
  };

  useEffect(() => {
    HackleManager.logSigninView();
  }, []);

  const renderLoginPage = (): JSX.Element => {
    if (isShowSignupForm) {
      return (
        <React.Fragment>
          <Column
            gap="24px"
            style={{ padding: '20px 20px 32px 20px', maxWidth: '335px' }}
          >
            <Column gap="8px" align="flex-start">
              <Text color="#1B1C1E" size="20px" weight={700}>
                본인인증 및 약관동의
              </Text>
              <Text
                color="#70737c"
                size="14px"
                weight={400}
                style={{ letterSpacing: '-0.8px' }}
              >
                최초 1회 본인 인증과 약관 동의가 필요합니다.
              </Text>
            </Column>
            {!myInfo.me.user.verified && (
              <SignupCertification
                mobileNumber={signupParam.mobileNumber}
                isMobileCertification={isMobileCertification}
                handleChangeSignupParam={handleChangeSignupParam}
                handleMobileCertification={handleMobileCertification}
              />
            )}
            <SignupTerm
              marketingChecked={signupParam.agreement.marketing}
              handleChangeMarketing={handelCheckedMarketing}
              callback={handleTermCheckedCallback}
            />
            <Column gap="12px">
              <AuthButton
                name="가입하기"
                isAbled={
                  myInfo.me.user.verified
                    ? isTermChecked
                    : isMobileCertification && isTermChecked
                }
                onClick={() => {
                  if (myInfo.me.user.verified && isTermChecked) {
                    handleRegistHost(myInfo.me.user.mobileNumber);

                    return;
                  }

                  if (isMobileCertification && isTermChecked) {
                    handleRegistHost(signupParam.mobileNumber);
                  }
                }}
              />
              <BackButton
                name="돌아가기"
                onClick={() => {
                  handleIsShowSignupForm();
                }}
              />
            </Column>
          </Column>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Column gap="40px" style={{ width: '100%', maxWidth: '335px' }}>
          <Row justifyContent="space-between" style={{ width: '100%' }}>
            <img src={ICON_FRIP_LOGO} alt="frip logo" />
            <a href={KAKAO_CUSTOMER_SERVICE} target="_blank">
              <Row>
                <Text color="#70737c" size="12px">
                  호스트 지원센터
                </Text>
                <img src={ICON_ARROW_GRAY_16} alt="arrow icon" />
              </Row>
            </a>
          </Row>
          <Column gap="24px">
            <Column align="flex-start">
              <Text
                color="#1b1c1e"
                size="20px"
                weight={700}
                style={{ lineHeight: '28px' }}
              >
                프립에서 새로운 일상을 꿈꾸는
              </Text>
              <Text
                color="#1b1c1e"
                size="20px"
                weight={700}
                style={{ lineHeight: '28px' }}
              >
                150만 크루와 함께하세요.
              </Text>
            </Column>
            <Column gap="8px" align="flex-start">
              <InputContainer>
                <InputForm
                  type="text"
                  styleType="top"
                  label="아이디(이메일)"
                  target="email"
                  onChange={handleOnchangeSignInParams}
                  validation={isFailedLogin}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      if (
                        signInParams.email !== '' &&
                        signInParams.password !== ''
                      ) {
                        handleEmailSignin();
                      }
                    }
                  }}
                />
                <InputForm
                  type="password"
                  styleType="bottom"
                  label="비밀번호"
                  target="password"
                  onChange={handleOnchangeSignInParams}
                  validation={isFailedLogin}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      if (
                        signInParams.email !== '' &&
                        signInParams.password !== ''
                      ) {
                        handleEmailSignin();
                      }
                    }
                  }}
                />
              </InputContainer>
              {isFailedLogin && (
                <Text color="#ff4242" size="12px" weight={400}>
                  아이디(이메일), 비밀번호를 다시 확인해 주세요
                </Text>
              )}
            </Column>
            <AuthButton
              name="로그인"
              isAbled={
                signInParams.email !== '' && signInParams.password !== ''
              }
              onClick={() => {
                if (signInParams.email !== '' && signInParams.password !== '') {
                  HackleManager.logSigninClick('email');
                  handleEmailSignin();
                }
              }}
            />
            <Row gap="12px">
              <Text
                onClick={handleOnChangeFindPasswordModal}
                style={{ cursor: 'pointer' }}
              >
                비밀번호 찾기
              </Text>
              <Text>|</Text>
              <Text
                color="#1B1C1E"
                onClick={() => {
                  navigate(SIGNUP_PATH);
                }}
                style={{ cursor: 'pointer' }}
              >
                이메일 주소로 가입하기
              </Text>
            </Row>
          </Column>
          <Column gap="12px">
            <KakaoSDK
              handleIsShowSignupForm={handleIsShowSignupForm}
              handleSetLoginInfo={handleSetLoginInfo}
            />
            <FaceBookSDK
              handleIsShowSignupForm={handleIsShowSignupForm}
              handleSetLoginInfo={handleSetLoginInfo}
            />
          </Column>
          <img
            src={IMAGE_LOGIN_BANNER}
            alt="login page banner"
            style={{ width: '100%', cursor: 'pointer' }}
            onClick={() => navigate(START_PATH)}
          />
          {isOpenFindPasswordModal && (
            <FindPasswordModal
              handleOnChangeFindPasswordModal={handleOnChangeFindPasswordModal}
              handleFindPassword={handleFindPassword}
            />
          )}
        </Column>
        {isOpenBottomToast && (
          <BottomToast
            title="비밀번호 재설정 메일이 전송되었습니다."
            description="새로운 비밀번호를 설정하신 후, 다시 로그인해 주세요."
            onAcceptText="확인"
            onAccept={() => {
              setIsOpenBottomToast(false);
            }}
          />
        )}
      </React.Fragment>
    );
  };

  return (
    <Container style={{ minHeight: isWebview() ? 'auto' : '100vh' }}>
      {renderLoginPage()}
      {isOpenSuccessModal && (
        <SignupSuccessModal
          onCloseNavigate={() => {
            // handleRefetchUser();
            // navigate(SALE_LIST_PATH);
          }}
          onStartNavigate={() => {
            handleRefetchUser(() => {
              navigate(PROFILE_PATH);
            });
          }}
        />
      )}
    </Container>
  );
};

export default LoginPage;
