import { useEffect, useState } from 'react';

import { getEnvConstant } from 'constants/env';
import { useMutationCreateHost } from 'containers/Login/graphQL/hooks/useMutationCreateHost';

import { instance } from 'utils/axiosInstance';
import { STORAGE_KEY } from 'utils/localStoarge';
import { SignupParam } from './types';
import { useCertifictationHooks } from './useCertificationHooks';
import HackleManager from 'utils/hackle';

export const useSignUpHooks = () => {
  const { IMP } = window;

  const { handleHostCertification } = useCertifictationHooks();
  const { handleCreateHost, isOpenSuccessModal, setIsOpenSuccessModal } =
    useMutationCreateHost();
  const [signupParam, setSignupParam] = useState<SignupParam>({
    email: '',
    password: '',
    nickname: '프립 크루',
    mobileNumber: '',
    verificationKey: ' ',
    certificationKey: '',
    agreement: {
      marketing: false,
    },
    store: 'frip',
  });
  const [isMobileCertification, setIsMobileCertification] =
    useState<boolean>(false);
  const [isExistEmail, setIsExistEmail] = useState<boolean>(false);
  const [isOpenFailModal, setIsOpenFailModal] = useState<boolean>(false);

  const handleCheckValidationEmail = async () => {
    try {
      const response = await instance.get(
        `/validation/email?email=${signupParam.email}`,
      );

      if (response) {
        setIsExistEmail(false);
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        setIsExistEmail(true);
      }
    }
  };

  const handleChangeSignupParam = (key: string, value: string) => {
    setSignupParam({ ...signupParam, [key]: value });
  };

  const handelCheckedMarketing = (checked: boolean) => {
    setSignupParam({ ...signupParam, agreement: { marketing: checked } });
  };

  const handleMobileCertification = () => {
    IMP.certification({ phone: signupParam.mobileNumber }, (response: any) => {
      handleCertification(response);
    });
  };

  const handleCertification = (response: any) => {
    if (response.success) {
      HackleManager.logSignupVerificationComplete('email');
      setIsMobileCertification(true);
      setSignupParam({ ...signupParam, certificationKey: response.imp_uid });
    } else {
      setIsMobileCertification(false);
      alert(response.error_msg);
    }
  };

  const handleEmailSignup = async () => {
    try {
      const response = await instance.post('/signup', signupParam);

      if (response) {
        localStorage.setItem(
          STORAGE_KEY.AUTHORIZATION,
          `${response.data.data.tokenType} ${response.data.data.accessToken}`,
        );

        HackleManager.logSignupComplete(
          'email',
          signupParam.agreement.marketing,
        );

        handleHostCertification(signupParam.certificationKey, () => {});
        handleCreateHost(
          response.data.data,
          signupParam.mobileNumber,
          signupParam,
        );
      }
    } catch (error) {
      setIsOpenFailModal(true);
      console.log(error, 'signup error');
    }
  };

  useEffect(() => {
    IMP.init(getEnvConstant('iamport'));
  }, []);

  return {
    signupParam,
    isMobileCertification,
    isExistEmail,
    isOpenSuccessModal,
    isOpenFailModal,
    setIsOpenSuccessModal,
    setIsOpenFailModal,
    handleCheckValidationEmail,
    handleChangeSignupParam,
    handelCheckedMarketing,
    handleMobileCertification,
    handleEmailSignup,
  };
};
