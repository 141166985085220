import React, { Dispatch, SetStateAction, useEffect } from 'react';
import AuthButton from 'components/Button/AuthButton';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import { ModifyValue } from 'containers/ProductDetail/hooks/useProductDetaillHooks';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import AdditionalInformationForm from 'containers/ProductRegist/components/InformationRegist/components/AdditionalInformationForm';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import { useAdditionalModifyHooks } from './hooks/useAdditionalModifyHooks';
import SaveButton from '../SaveButton';
import { Product, ProductState } from 'types/api';
import HackleManager from 'utils/hackle';

interface AdditionalModifyFormIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  productInfo: Product;
  modifyValue: ModifyValue;
  setModifyValue: Dispatch<SetStateAction<ModifyValue>>;
  setProductParamInput: Dispatch<SetStateAction<ProductParamInput>>;
}

const AdditionalModifyForm: React.FC<AdditionalModifyFormIProps> = props => {
  const {
    isMobile,
    productParamInput,
    productInfo,
    modifyValue,
    setModifyValue,
    setProductParamInput,
  } = props;

  const {
    isAbled,
    additionalInformation,
    handleAddAdditionalValue,
    handleDeleteAdditionalValue,
    handelResetAdditionalValue,
  } = useAdditionalModifyHooks(productParamInput);

  const handleRegistAdditional = () => {
    setProductParamInput({
      ...productParamInput,
      frip: {
        ...productParamInput.frip,
        stuffsToPrepare: additionalInformation.stuffsToPrepare,
        inclusions: additionalInformation.inclusions,
        exclusions: additionalInformation.exclusions,
      },
      keywords: additionalInformation.keywords,
    });
  };

  useEffect(() => {
    HackleManager.logProductModifyAdditionalInfoView(
      Number(productInfo.id),
      productInfo.title,
    );
  }, []);

  const renderAdditionalForm = (): JSX.Element => {
    return (
      <Column gap="32px" align="flex-start">
        <Text color="#0071e9" size="14px" weight={400}>
          콤마(,)로 구분하여 입력하면 최대 20개의 키워드를 한번에 등록할 수
          있어요.
        </Text>
        <AdditionalInformationForm
          title="준비물"
          placeholder="예시) 볼펜, 앞치마"
          type="stuffsToPrepare"
          additionalList={additionalInformation.stuffsToPrepare!}
          handleAddAdditionalValue={handleAddAdditionalValue}
          handleDeleteAdditionalValue={handleDeleteAdditionalValue}
        />
        <AdditionalInformationForm
          title="참가비 포함사항"
          placeholder="예시) 음료 1잔 , 재료비"
          type="inclusions"
          additionalList={additionalInformation.inclusions!}
          handleAddAdditionalValue={handleAddAdditionalValue}
          handleDeleteAdditionalValue={handleDeleteAdditionalValue}
        />
        <AdditionalInformationForm
          title="참가비 불포함사항"
          placeholder="예시) 교통비, 의류 및 장비"
          type="exclusions"
          additionalList={additionalInformation.exclusions!}
          handleAddAdditionalValue={handleAddAdditionalValue}
          handleDeleteAdditionalValue={handleDeleteAdditionalValue}
        />
        <AdditionalInformationForm
          title="검색 키워드"
          placeholder="예시) 케이크, 비건"
          type="keywords"
          additionalList={additionalInformation.keywords}
          handleAddAdditionalValue={handleAddAdditionalValue}
          handleDeleteAdditionalValue={handleDeleteAdditionalValue}
        />
      </Column>
    );
  };

  const renderContents = (): JSX.Element => {
    if (isMobile) {
      return (
        <ModalContainer
          isMobile={isMobile}
          isAbled={productInfo.status !== ProductState.CLOSED && isAbled}
          onClose={() => {
            setModifyValue({ ...modifyValue, modifyStatus: '' });
          }}
          onCloseText="닫기"
          onAcceptText="적용"
          onClick={() => {
            if (isAbled) {
              HackleManager.logProductModifyItemSaveComplete(
                Number(productInfo.id),
                productInfo.title,
                '추가정보',
              );

              handleRegistAdditional();

              setModifyValue({
                ...modifyValue,
                isModify: true,
                modifyStatus: '',
              });
            }
          }}
        >
          <Column gap="24px" style={{ padding: '16px 20px 0' }}>
            <Row
            // style={{
            //   position: 'sticky',
            //   top: 0,
            //   background: '#fff',
            //   borderBottom: '1px solid #eaebec',
            // }}
            >
              <Text color="#000" size="16px" weight={600}>
                추가 정보
              </Text>
            </Row>
            {renderAdditionalForm()}
          </Column>
        </ModalContainer>
      );
    }

    return (
      <Column>
        <Row
          justifyContent="flex-start"
          style={{
            padding: '32px 40px',
            width: '100%',
            background: '#fff',
            borderBottom: '1px solid #eaebec',
          }}
        >
          <Text color="#1B1C1E" size="24px">
            추가정보 수정하기
          </Text>
        </Row>
        <Column gap="24px" style={{ padding: '32px 40px' }}>
          {renderAdditionalForm()}
          {productInfo.status !== ProductState.CLOSED && (
            <SaveButton
              isAbled={isAbled}
              modifyValue={modifyValue}
              setModifyValue={setModifyValue}
              handleResetItem={handelResetAdditionalValue}
              handleRegistItem={() => {
                HackleManager.logProductModifyItemSaveComplete(
                  Number(productInfo.id),
                  productInfo.title,
                  '추가정보',
                );
                handleRegistAdditional();
              }}
            />
          )}
        </Column>
      </Column>
    );
  };

  return renderContents();
};

export default AdditionalModifyForm;
