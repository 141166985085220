import { ICON_ARROW_BLACK, ICON_ARROW_GRAY_BOLD } from 'assets/icons';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import React, { useEffect, useState } from 'react';

interface PaginationContainerIProps {
  totalCount: number;
  page: number;
  size: number;
  handleSetQnaFilter: (key: string, value: any) => void;
}

const PaginataionContainer: React.FC<PaginationContainerIProps> = props => {
  const { totalCount, page, size, handleSetQnaFilter } = props;

  const [activeNavgteButton, setActiveNavigateButton] = useState<{
    prev: boolean;
    next: boolean;
  }>({ prev: page !== 1, next: totalCount > size * page });

  useEffect(() => {
    if (page !== 1) {
      setActiveNavigateButton({ ...activeNavgteButton, prev: true });

      if (totalCount > size * page) {
        setActiveNavigateButton({ prev: true, next: true });
      } else {
        setActiveNavigateButton({ prev: true, next: false });
      }
    } else {
      if (totalCount <= size * page) {
        setActiveNavigateButton({ prev: false, next: false });
      } else {
        setActiveNavigateButton({ prev: false, next: true });
      }
    }
  }, [totalCount, page, size]);

  const renderConvertingPageNation = (): JSX.Element => {
    let count = 1;

    if (totalCount > size) {
      count = Math.ceil(totalCount / size);
    }

    return (
      <Row style={{ width: '100px' }}>
        {page.toLocaleString()}/{count.toLocaleString()}
      </Row>
    );
  };

  return (
    <Row gap="16px">
      <Row
        onClick={() => {
          if (page !== 1) {
            handleSetQnaFilter('page', page - 1);
          }
        }}
      >
        <img
          src={
            activeNavgteButton.prev ? ICON_ARROW_BLACK : ICON_ARROW_GRAY_BOLD
          }
          alt="icon arrow gray"
          style={{ rotate: '180deg' }}
        />
        <Text size="16px" color={activeNavgteButton.prev && '#1b1c1e'}>
          이전
        </Text>
      </Row>
      {renderConvertingPageNation()}
      <Row
        onClick={() => {
          if (activeNavgteButton.next) {
            handleSetQnaFilter('page', page + 1);
          }
        }}
      >
        <Text size="16px" color={activeNavgteButton.next && '#1b1c1e'}>
          다음
        </Text>
        <img
          src={
            activeNavgteButton.next ? ICON_ARROW_BLACK : ICON_ARROW_GRAY_BOLD
          }
          alt="icon arrow gray"
        />
      </Row>
    </Row>
  );
};

export default PaginataionContainer;
