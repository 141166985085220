import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactQuill, { Quill } from 'react-quill';
import styled from 'styled-components';
import 'react-quill/dist/quill.snow.css';

import Column from 'components/common/Column';
import Text from 'components/common/Text';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import {
  callWebviewHandler,
  isWebview,
  WebviewOperatorType,
} from 'utils/webview';
import { useMutationGenerateContentUploadInfo } from 'graphQL/Image/useMutationGenerateContentUploadInfo';
import HackleManager from 'utils/hackle';

const AlignStyle = Quill.import('attributors/style/align');
const BackgroundStyle = Quill.import('attributors/style/background');
const ColorStyle = Quill.import('attributors/style/color');
const FontStyle = Quill.import('attributors/style/font');
// const SizeStyle = Quill.import('attributors/style/size');
// SizeStyle.whitelist = ['small', 'large', 'huge', '16px', '24px'];

// 기본 스타일 속성으로 사용되도록 등록
Quill.register(AlignStyle, true);
Quill.register(BackgroundStyle, true);
Quill.register(ColorStyle, true);
Quill.register(FontStyle, true);
// Quill.register(SizeStyle, true);

const QuillContainer = styled.div`
  position: relative;
  width: 100%;

  img {
    width: 100%;
    border-radius: 7px;
  }

  .quill {
    width: 100%;
    min-height: 70vh;
    max-height: 70vh;
    overflow: auto;
    font-family: Pretendard !important;
    color: #111;

    &::placeholder {
      color: #c2c4c8;
      font-size: 14px;
      font-weight: 400;
    }

    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }

    .ql-snow {
      padding: 0 0 8px 0;
    }

    .ql-blank {
      padding: 8px 0 0 0;
    }

    .ql-editor {
      padding: 12px 0;
      border-top: 1px solid #eee;

      img {
        border-radius: 0px;
      }
    }

    .ql-size-huge {
      line-height: 40px;
    }

    .ql-tooltip {
      left: 0 !important;
    }

    .ql-editor h1 {
      font-size: 22px;
      font-weight: 700;
      line-height: 160%;
    }

    .ql-editor h2 {
      font-size: 18px;
      font-weight: 700;
      line-height: 160%;
    }

    .ql-editor h3 {
      font-size: 16px;
      font-weight: 400;
      line-height: 160%;
    }

    .ql-editor p {
      font-size: 14px;
      font-weight: 400;
      line-height: 160%;
    }

    .ql-blank {
      font-size: 16px;
      line-height: 160%;
    }

    .ql-blank::before {
      position: absolute;
      left: 0;
      font-family: Pretendard !important;
      font-style: normal !important;
      color: #999;
    }

    .ql-container {
      font-family: Pretendard !important;
      border: none;
    }

    .ql-toolbar {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
      gap: 10px;
      /* padding: 8px; */
      position: sticky;
      top: 0;

      width: 100%;
      /* height: 51px; */
      border: none;
      background-color: #fff;
      z-index: 20;

      .ql-formats:nth-child(2) {
        padding-right: 10px;
        border-right: 1px solid #eaecf0;
      }

      .ql-formats:nth-child(5) {
        padding-right: 8px;
        border-right: 1px solid #eaecf0;
      }

      button {
        width: 22px;
        padding: 0;
      }

      svg {
        width: 22px !important;
        height: 22px !important;
      }
    }

    .ql-formats {
      margin-right: 0;
    }
  }

  .ql-tooltip {
    height: 0;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  padding: 2px 0;
  z-index: 21;

  input {
    display: none;
  }
`;

interface ChapterThreeIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
}

const ChapterThree: React.FC<ChapterThreeIProps> = props => {
  const { isMobile, productParamInput, handleOnChangeProductParamInput } =
    props;

  const params = useParams<{ id: string }>();

  const { handleGenerateContentUpload } =
    useMutationGenerateContentUploadInfo();

  const containerRef = useRef<HTMLDivElement>(null);
  const quillRef = useRef<ReactQuill>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [editorValue, setEditorValue] = useState<string>(
    productParamInput.htmlDetailContent
      ? productParamInput.htmlDetailContent
      : '',
  );

  const handleImageUploadClick = () => {
    if (fileInputRef.current) {
      if (isWebview()) {
        callWebviewHandler(
          WebviewOperatorType.UpdateMessageImageFileHandler,
          undefined,
          'handleImageUpload',
        );
      } else {
        fileInputRef.current.click();
      }
    }
  };

  const handleImageUpload = (link: { link: string }) => {
    const result = JSON.parse(link.link);

    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection();

      if (link.link) {
        // const uploadText = 'upload/';
        // const uploadIndex =
        //   link.link.lastIndexOf(uploadText) + uploadText.length;

        // // const resizeLowImageUrl = 'c_limit,dpr_1.0,f_auto,q_auto:low,w_500/';
        // const resizeImageUrl = 'c_limit,dpr_3.0,f_auto,q_auto:best,w_500/';

        // const uploadUrl =
        //   link.link.slice(0, uploadIndex) +
        //   resizeImageUrl +
        //   link.link.slice(uploadIndex, link.link.length);

        // editor.insertEmbed(range ? range.index : 0, 'image', uploadUrl);
        editor.insertEmbed(range ? range.index : 0, 'image', result.url);
      }
    }
  };

  const handleWebImageUpload = (link: { link: string }) => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection();

      if (link.link) {
        editor.insertEmbed(range ? range.index : 0, 'image', link.link);
      }
    }
  };

  const handleFileChange = (event: any) => {
    handleGenerateContentUpload(
      {
        file: event.target.files[0],
        fileName: event.target.files[0].name,
      },
      (url: string, contentId: string) => {
        handleWebImageUpload({ link: url });
      },
    );
  };

  // const toolbarContainer = [
  //   ['small'],
  //   ['large'],
  // ['bold'],
  // ['italic'],
  // ['underline'],
  // ['strike'],
  //   [{ align: '' }],
  //   [{ align: 'center' }],
  //   [{ align: 'right' }],
  //   ['link', 'image'],
  // ];

  const toolbarContainer = [
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ font: [] }],
    ['bold'],
    ['italic'],
    ['underline'],
    ['strike'],
    // ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
    ['link'],
    ['video'],
    ['image'],
  ];

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: toolbarContainer,
        handlers: {
          image: () => {
            handleImageUploadClick();
          },
        },
      },
    };
  }, []);

  useEffect(() => {
    handleOnChangeProductParamInput('htmlDetailContent', editorValue);
  }, [editorValue]);

  useEffect(() => {
    HackleManager.logCreateStepThreeProductDescriptionView(
      params.id !== 'draft' ? Number(params.id) : undefined,
    );

    window.handleImageUpload = handleImageUpload;
  }, []);

  const renderContents = (): JSX.Element => {
    return (
      <Column gap="32px" align="flex-start">
        <Text color="#1b1c1e" size="24px">
          프립을 소개해 주세요
        </Text>
        <QuillContainer ref={containerRef}>
          <ReactQuill
            placeholder={`활동 중심으로 프립을 알려주세요. 소개는 언제든지 수정할 수 있어요.`}
            theme="snow"
            ref={quillRef}
            modules={modules}
            style={{
              width: '100%',
              overflow: 'auto',
              paddingBottom: '56px',
            }}
            value={productParamInput.htmlDetailContent}
            onChange={value => {
              if (value === '<p><br></p>') {
                handleOnChangeProductParamInput('htmlDetailContent', '');
                return;
              }

              handleOnChangeProductParamInput('htmlDetailContent', value);
            }}
          />
          <ImageContainer>
            {/* <CommonImage
              src={ICON_PRODUCT_IMAGE_UPLOAD}
              onClick={() => {
                handleImageUploadClick();
              }}
              style={{
                width: '22px',
              }}
            /> */}
            <input
              ref={fileInputRef}
              type="file"
              multiple={false}
              onChange={handleFileChange}
              accept=".jpg, .png, .jpeg"
            />
          </ImageContainer>
        </QuillContainer>
      </Column>
    );
  };

  return renderContents();
};

export default ChapterThree;
