import React from 'react';
import styled from 'styled-components';

import Column from 'components/common/Column';
import Text from 'components/common/Text';
import Row from 'components/common/Row';

const Container = styled.div<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 24px 16px;
  width: 100%;

  border-radius: 12px;
  border: ${props =>
    props.$isSelected ? '1.5px solid #1B1C1E' : '1px solid #dbdcdf'};
  background: ${props => (props.$isSelected ? '#f7f7f8' : '#fff')};
  cursor: pointer;
`;

const Thumbnail = styled.img`
  width: 42px;
  height: 42px;
  /* background-color: #dbdcdf; */
  border-radius: 6px;
`;

const RadioBox = styled.input`
  width: 24px;
  height: 24px;
  appearance: none;
  border: 3px solid #eee;
  border-radius: 50%;
  cursor: pointer;

  &:checked {
    border: 8px solid #1b1c1e;
  }
`;

interface SelectDateCardIProps {
  thumbnail: any;
  title: string;
  description: React.ReactElement;
  isSelected: boolean;
  onClick: () => void;
}

const SelectDateCard: React.FC<SelectDateCardIProps> = props => {
  const { thumbnail, title, description, isSelected, onClick } = props;

  return (
    <Container $isSelected={isSelected} onClick={onClick}>
      <Row align="flex-start" gap="12px" style={{ width: '100%' }}>
        <Thumbnail src={thumbnail} alt="select date card thumbnail" />
        <Column gap="2px" align="flex-start">
          <Text color="#1b1c1e" size="16px" weight={600}>
            {title}
          </Text>
          {description}
        </Column>
      </Row>
      <RadioBox type="radio" checked={isSelected} onChange={onClick} />
    </Container>
  );
};

export default SelectDateCard;
