import React, { useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import dayjs from 'dayjs';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import Divider from 'components/Divider';
import { ICON_ARROW_GRAY_BOLD } from 'assets/icons';
import { ProductQnaEdge } from 'types/api';
import QnaStatusButton from './QnaStatusButton';
import { ProductQnaState } from '../graphQL/types';
import { useMutationAnswer } from '../graphQL/hooks/useMutationAnswer';
import HackleManager from 'utils/hackle';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px;
  gap: 16px;
  width: 100%;
`;

const QnaAnswerTextarea = styled.textarea`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 16px 16px 32px 16px;
  width: 100%;
  height: auto;
  min-height: 132px;
  max-height: 85px;
  overflow: auto;

  border-radius: 8px;
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--gray-gray-01, #f7f7f8);
  font-size: 14px;

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  &:focus {
    border: 1.5px solid #1b1c1e;
  }
`;

const UpdateButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);
`;

const ProductName = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  color: #70737c;
`;

const QuestionBox = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  color: #1b1c1e;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
`;

interface QnaItemIProps {
  isMobile: boolean;
  item: ProductQnaEdge;
  handleSelectedQnaItem: (item: ProductQnaEdge) => void;
  handleRefetchList: () => void;
}

const QnaItem: React.FC<QnaItemIProps> = props => {
  const { isMobile, item, handleSelectedQnaItem, handleRefetchList } = props;

  const { handleRegistAnswer, handleUpdateAnswer } = useMutationAnswer();

  const [showButton, setShowButton] = useState<boolean>(false);
  const [activeTextarea, setActiveTextarea] = useState<boolean>(false);
  const [questionHeight, setQuestionHeight] = useState<number>();
  const [value, setValue] = useState<string>(
    item.node.answer ? item.node.answer.answer : '',
  );

  const textRef = useRef(null);

  const handleShowButton = () => {
    if (textRef.current) {
      const { scrollHeight } = textRef.current;

      if (scrollHeight > 100) {
        setQuestionHeight(scrollHeight);
        setShowButton(false);
      }
    }
  };

  useEffect(() => {
    setValue(item.node.answer ? item.node.answer.answer : '');
  }, [item.node.answer]);

  useEffect(() => {
    if (textRef.current) {
      const { scrollHeight } = textRef.current;

      if (scrollHeight > 100) {
        setShowButton(true);
        setQuestionHeight(100);
      } else {
        setQuestionHeight(scrollHeight);
      }
    }
  }, [value]);

  return (
    <Container>
      <Row gap="8px">
        <QnaStatusButton status={item.node.status as ProductQnaState} />
        {item.node.secret && <QnaStatusButton status={ProductQnaState.BLIND} />}
      </Row>
      <Column gap="8px" align="flex-start">
        <Row
          gap="4px"
          style={{ width: '100%', cursor: 'pointer' }}
          onClick={() => handleSelectedQnaItem(item)}
        >
          <Text
            color="#46474c"
            style={{
              width: '43px',
            }}
          >
            프립명:
          </Text>
          <Row
            gap="4px"
            justifyContent="space-between"
            style={{ width: '100%' }}
          >
            <ProductName>{item.node.product.title}</ProductName>
            <img
              src={ICON_ARROW_GRAY_BOLD}
              alt="arrow gray"
              style={{ cursor: 'pointer' }}
              onClick={() => handleSelectedQnaItem(item)}
            />
          </Row>
        </Row>
        <Row gap="4px">
          <Text color="#46474c">크루명:</Text>
          <Text color="#70737C">{item.node.user.nickname}</Text>
        </Row>
      </Column>
      <Divider />
      <Column gap="8px" align="flex-start">
        <Row gap="4px" justifyContent="space-between" style={{ width: '100%' }}>
          <Text color="#46474c">문의 내용</Text>
          <Text size="12px" weight={400}>
            {dayjs(item.node.createdAt).format('YYYY년 MM월 DD일 HH:mm')}
          </Text>
        </Row>
        <Row gap="4px">
          <QuestionBox ref={textRef} style={{ height: `${questionHeight}px` }}>
            {item.node.question}
          </QuestionBox>
        </Row>
        {showButton && (
          <Text
            color="#46474c"
            size="14px"
            weight={400}
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={handleShowButton}
          >
            문의 전체보기
          </Text>
        )}
      </Column>
      <Divider />
      <Column gap="8px" align="flex-start">
        <Row gap="4px" justifyContent="space-between" style={{ width: '100%' }}>
          <Text color="#46474c">답변 내용</Text>
          <Text size="12px" weight={400}>
            {dayjs(item.node.answer?.createdAt).format(
              'YYYY년 MM월 DD일 HH:mm',
            )}
          </Text>
        </Row>
        <Column gap="12px" align="flex-end">
          <QnaAnswerTextarea
            placeholder="문의에 대한 답변을 입력하세요"
            value={value}
            onChange={e => setValue(e.target.value)}
            disabled={
              item.node.status === ProductQnaState.NEW ? false : !activeTextarea
            }
            style={{
              background: value === '' || activeTextarea ? '#FFF' : '#F7F7F8',
            }}
          />
          <UpdateButton
            onClick={() => {
              HackleManager.logQnaResponseComplete(
                Number(item.node.id),
                item.node.status !== ProductQnaState.NEW
                  ? '수정하기'
                  : '저장하기',
              );

              if (item.node.status === ProductQnaState.NEW) {
                handleRegistAnswer(item.node.id, value, handleRefetchList);
              }

              if (!activeTextarea) {
                setActiveTextarea(true);

                return;
              }

              if (activeTextarea) {
                handleUpdateAnswer(item.node.id, value, handleRefetchList);
                setActiveTextarea(false);

                return;
              }
            }}
          >
            <Text
              color={
                item.node.status !== ProductQnaState.NEW
                  ? '#1b1c1e'
                  : value === ''
                  ? '#C2C4C8'
                  : '#1b1c1e'
              }
            >
              {item.node.status !== ProductQnaState.NEW
                ? '수정하기'
                : '저장하기'}
            </Text>
          </UpdateButton>
        </Column>
      </Column>
    </Container>
  );
};

export default QnaItem;
