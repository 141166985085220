import React from 'react';
import styled from 'styled-components';
import Column from 'components/common/Column';
import Text from 'components/common/Text';
import Row from 'components/common/Row';
import Divider from 'components/Divider';
import { IMAGE_PROFILE_DEFAULT } from 'assets/images';
import { ICON_SETTLEMENT_ARROW_RIGHT } from 'assets/icons';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import { MeData } from 'graphQL/Auth/types';
import { CompanionType, LevelOfDifficulty, ProductState } from 'types/api';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import { isEmpty } from 'lodash';
import { formatDifficulty } from 'utils/formatting';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 24px 0;
  width: 100%;
`;

const Image = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  object-fit: cover;
`;

interface InformationContainerIProps {
  productParamInput: ProductParamInput;
}

const InformationContainer: React.FC<InformationContainerIProps> = props => {
  const { productParamInput } = props;
  const myInfo: MeData = useUserInformation((state: any) => state.userInfo);
  const hostInfo = myInfo.me.host;

  const formatRecommendedAge = (): string => {
    switch (productParamInput.frip?.recommendedAge) {
      case 0: {
        return '누구나';
      }
      case 20: {
        return '20대 이상 권장';
      }
      case 30: {
        return '30대 이상 권장';
      }
      case 40: {
        return '40대 이상 권장';
      }
      case 50: {
        return '50대 이상 권장';
      }
      default:
        return '';
    }
  };

  const formatCompanions = (companion: CompanionType): string => {
    switch (companion) {
      case CompanionType.ALONE: {
        return '혼자';
      }
      case CompanionType.WITH_FRIENDS: {
        return '친구와';
      }
      case CompanionType.WITH_LOVER: {
        return '연인과';
      }
      case CompanionType.WITH_CHILDREN: {
        return '아이와';
      }
      case CompanionType.WITH_DOG: {
        return '반려견과';
      }
      default:
        return '';
    }
  };

  return (
    <Container>
      <Column gap="15px" align="flex-start" style={{ padding: '0 24px' }}>
        <Text color="#333" size="20px" weight={400}>
          {productParamInput.title === ''
            ? '제목 없음'
            : productParamInput.title}
        </Text>
        <Row>
          <Text color="#333" size="24px" weight={700}>
            {!isEmpty(productParamInput.items)
              ? `${productParamInput.items
                  .find(item => item.representative)
                  ?.price.sale.toLocaleString()}`
              : '0'}
          </Text>
          <Text color="#333" size="14px">
            원
          </Text>
        </Row>
      </Column>
      <Divider style={{ height: '8px', background: '#F4F4F4' }} />
      <Row gap="20px" style={{ padding: '0 24px' }}>
        <Image
          src={
            hostInfo?.profileImage
              ? hostInfo.profileImage.thumbnail
              : IMAGE_PROFILE_DEFAULT
          }
          alt="default profile"
        />
        <Column gap="3px" align="flex-start">
          <Row>
            <Text color="#333" size="16px">
              {hostInfo?.name}
            </Text>
            <img src={ICON_SETTLEMENT_ARROW_RIGHT} alt="arrow icon" />
          </Row>
          <Row gap="7px">
            <Text color="#aaa" size="12px">
              프립{' '}
              {hostInfo?.productInfo.counts.find(
                ({ status }) => status === ProductState.SALE,
              )?.count || 0}
            </Text>
            <Text color="#aaa" size="12px">
              |
            </Text>
            <Text color="#aaa" size="12px">
              후기 {hostInfo?.productReviewInfo.count}
            </Text>
            <Text color="#aaa" size="12px">
              |
            </Text>
            <Text color="#aaa" size="12px">
              저장 {hostInfo?.wishInfo.count}
            </Text>
          </Row>
        </Column>
      </Row>
      <Divider style={{ height: '8px', background: '#F4F4F4' }} />
      <Column gap="24px" align="flex-start" style={{ padding: '0 24px' }}>
        <Text color="#000" size="18px" weight={700}>
          프립 정보
        </Text>
        <Row gap="20px" justifyContent="flex-start" style={{ width: '100%' }}>
          <Column align="flex-start" gap="8px" style={{ width: 'auto' }}>
            <Text color="#999" size="14px">
              난이도
            </Text>
            <Text color="#999" size="14px">
              권장 연령
            </Text>
            {!isEmpty(productParamInput.frip.companions) && (
              <Text color="#999" size="14px">
                누구와 함께
              </Text>
            )}
            {(productParamInput.frip.isIndoor ||
              productParamInput.frip.isOutdoor) && (
              <Text color="#999" size="14px">
                실내/실외
              </Text>
            )}
          </Column>
          <Column align="flex-start" gap="8px" style={{ width: 'auto' }}>
            <Text color="#333" size="14px">
              {formatDifficulty(productParamInput.frip.difficulty)}
            </Text>
            <Text color="#333" size="14px">
              {formatRecommendedAge()}
            </Text>
            {!isEmpty(productParamInput.frip.companions) && (
              <Row gap="2px">
                {productParamInput.frip.companions.map((item, idx) => {
                  return (
                    <Text color="#333" size="14px" key={idx}>
                      {formatCompanions(item)}{' '}
                      {productParamInput.frip.companions.length !== idx + 1 &&
                        '•'}
                    </Text>
                  );
                })}
              </Row>
            )}
            {(productParamInput.frip.isIndoor ||
              productParamInput.frip.isOutdoor) && (
              <Text color="#333" size="14px">
                {productParamInput.frip.isIndoor && '실내'}{' '}
                {productParamInput.frip.isIndoor &&
                  productParamInput.frip.isOutdoor &&
                  '• '}
                {productParamInput.frip.isOutdoor && '실외'}
              </Text>
            )}
          </Column>
        </Row>
      </Column>
      <Divider style={{ height: '8px', background: '#F4F4F4' }} />
    </Container>
  );
};

export default InformationContainer;
