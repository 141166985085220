import React, { useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import styled, { css } from 'styled-components';
import { isEmpty } from 'lodash';
import { InView } from 'react-intersection-observer';

import {
  ICON_CALENDAR,
  ICON_CLOSE,
  ICON_PRODUCT_FILTER,
  ICON_REFRESH,
} from 'assets/icons';
import BasicButton from 'components/Button/BasicButton';
import CommonImage from 'components/common/CommonImage';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import BookingCard from './components/BookingCard';
import Column from 'components/common/Column';
import { useBookingListHooks } from './hooks/useBookingListHooks';
import BookingSearchModal from './components/BookingSearchModal';
import SearchForm from 'components/common/SearchForm';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import CalendarContainer from './components/CalendarContainer';
import Divider from 'components/Divider';
import Loading from 'components/Loading/Loading';
import HackleManager from 'utils/hackle';
import { formatBookingStatus } from 'utils/hackle/parameterFormatting';
import CheckBox from 'components/common/CheckBox';
import BookingBottomSheet from './components/BookingBottomSheet';
import BookingTicketStatusFilter from './components/BookingTicketStatusFilter';

const Container = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  /* padding: ${props => (props.$isMobile ? '0 0 90px' : '0 20px 90px')}; */
  width: 100%;
  max-width: 1280px;
`;

const HeaderContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  background-color: #fff;
  z-index: 5;

  ${({ $isMobile }) =>
    $isMobile
      ? css`
          position: sticky;
          top: 0;
          padding: 24px 20px;
        `
      : css`
          position: static;
          padding: 32px 20px 24px;
        `}
`;

const BookingListPage = () => {
  const {
    loading,
    isLoading,
    bookingTickets,
    ticketFilterInput,
    bookingInfo,
    searchValue,
    searchType,
    filterSearchValue,
    isActiveSearchButton,
    isAcceptFilter,
    selectTickets,
    setIsLoading,
    setSearchType,
    setFilterSearchValue,
    setTicketFilterInput,
    setSearchValue,
    setIsActiveSearchButton,
    setIsAcceptFilter,
    handleSetTicekFilterInput,
    handleRefetchBookingList,
    handleLoadMoreBookingTickets,
    handleAllSelectTickets,
    handleSelectTickets,
    handleResetSelectTickets,
  } = useBookingListHooks();

  const { width, ref } = useResizeDetector();

  const isMobile = width ? width < 768 : window.innerWidth < 768;
  const isTablet = width && width > 768 && width < 1024;

  const [isOpenSearchModal, setIsOpenSearchModal] = useState<boolean>(false);
  const [isOpenCalendarModal, setIsOpenCalendarModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (bookingInfo) {
      HackleManager.logBookingView(
        bookingInfo.totalCount,
        formatBookingStatus(ticketFilterInput.filter.statusIn),
      );
    }
  }, [bookingInfo]);

  const renderHeaderContent = (): JSX.Element => {
    return (
      <HeaderContainer $isMobile={isMobile}>
        <Row justifyContent="space-between" style={{ width: '100%' }}>
          <Text color="#1b1c1e" size="24px">
            예약
          </Text>
          <Row gap="12px">
            <BasicButton
              content={
                <Row gap="4px">
                  <CommonImage
                    src={ICON_PRODUCT_FILTER}
                    onClick={() => {
                      setIsOpenSearchModal(true);
                    }}
                  />
                  <Text color="#1b1c1e">
                    {isAcceptFilter ? '필터 적용' : '필터'}
                  </Text>
                </Row>
              }
              onClick={() => {
                setIsOpenSearchModal(true);
              }}
              style={{
                height: '40px',
                background: isAcceptFilter ? '#f7f7f8' : '#fff',
                border: isAcceptFilter
                  ? '1.5px solid #1b1c1e'
                  : '1px solid #DBDCDF',
              }}
            />
            <BasicButton
              content={
                <Row gap="4px">
                  <CommonImage
                    src={ICON_CALENDAR}
                    onClick={() => {
                      setIsOpenCalendarModal(true);
                    }}
                    style={{ width: '20px' }}
                  />
                  <Text color="#1b1c1e">캘린더</Text>
                </Row>
              }
              onClick={() => {
                setIsOpenCalendarModal(true);
              }}
              style={{ height: '40px' }}
            />
            <BasicButton
              content={
                <Row gap="4px">
                  <CommonImage src={ICON_REFRESH} onClick={() => {}} />
                </Row>
              }
              onClick={() => {
                window.location.reload();
              }}
              style={{
                height: '40px',
                borderRadius: '50%',
                padding: '12px',
              }}
            />
          </Row>
        </Row>
      </HeaderContainer>
    );
  };

  const renderSearchForm = (): JSX.Element => {
    return (
      <Row
        gap="24px"
        style={
          isMobile
            ? { padding: '12px 20px', width: '100%', zIndex: 3 }
            : {
                position: 'sticky',
                top: '81px',
                padding: '12px 20px',
                width: '100%',
                background: '#fff',
                zIndex: 3,
              }
        }
      >
        <SearchForm
          searchValue={searchValue}
          isDisabled={!isActiveSearchButton}
          setSearchValue={setSearchValue}
          placeholder="크루명이나 번호를 입력해 주세요"
          style={{ background: isActiveSearchButton ? '#fff' : '#f7f7f8' }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              HackleManager.logBookingSearchComplete(searchValue);

              handleSetTicekFilterInput('filter', {
                ...ticketFilterInput.filter,
                customerSearchKeyword: searchValue,
              });
              setIsActiveSearchButton(false);
            }
          }}
        />
        <Text
          color={searchValue ? '#1b1c1e' : '#C2C4C8'}
          style={{ minWidth: '24px', cursor: 'pointer' }}
          onClick={() => {
            HackleManager.logBookingSearchComplete(searchValue);

            if (isActiveSearchButton) {
              handleSetTicekFilterInput('filter', {
                ...ticketFilterInput.filter,
                customerSearchKeyword: searchValue,
              });
              setIsActiveSearchButton(false);

              return;
            }

            handleSetTicekFilterInput('filter', {
              ...ticketFilterInput.filter,
              customerSearchKeyword: '',
            });
            setIsActiveSearchButton(true);
            setSearchValue('');
          }}
        >
          {isActiveSearchButton ? '검색' : '취소'}
        </Text>
      </Row>
    );
  };

  const renderCalendarModal = (): JSX.Element => {
    return (
      <ModalContainer
        isAbled={false}
        isBottomSheet={true}
        isMobile={isMobile}
        onClose={() => {}}
        onClick={() => {}}
        modalStyle={{ zIndex: 22 }}
      >
        <Row style={{ padding: '16px 0', width: '100%' }}>
          <CommonImage
            src={ICON_CLOSE}
            style={{ position: 'absolute', left: '20px' }}
            onClick={() => {
              setIsOpenCalendarModal(false);
            }}
          />
          <Text color="#000" size="16px" weight={600}>
            캘린더
          </Text>
        </Row>
        <CalendarContainer />
      </ModalContainer>
    );
  };

  const renderContents = (): JSX.Element => {
    if (loading) {
      return (
        <Column
          gap="32px"
          justifyContent="center"
          style={{
            minHeight: '40vh',
          }}
        >
          <Loading type="flex" />
        </Column>
      );
    }

    return (
      <Column
        gap="32px"
        style={{
          padding: '0 20px',
        }}
      >
        {isEmpty(bookingTickets) ? (
          <Text color="#1b1c1e" size="16px" style={{ marginTop: '32px' }}>
            조회되는 예약이 없어요
          </Text>
        ) : (
          <React.Fragment>
            <Column
              gap="32px"
              justifyContent="flex-start"
              style={{
                minHeight: '60vh',
              }}
            >
              {bookingTickets.map((item, idx) => {
                return (
                  <React.Fragment key={item.date}>
                    <Column
                      gap="16px"
                      align="flex-start"
                      key={idx}
                      style={{
                        opacity:
                          isEmpty(selectTickets.tickets) ||
                          (selectTickets.date === item.date &&
                            !isEmpty(selectTickets.tickets))
                            ? '100%'
                            : '45%',
                      }}
                    >
                      <Text color="#46474C" size="16px">
                        {item.date}
                      </Text>
                      <Row
                        justifyContent="space-between"
                        style={{ width: '100%' }}
                      >
                        <Text color="#46474C" size="16px">
                          {item.value.length}건
                        </Text>
                        <Row
                          gap="4px"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            if (
                              isEmpty(selectTickets.tickets) ||
                              (selectTickets.date === item.date &&
                                !isEmpty(selectTickets.tickets))
                            ) {
                              handleAllSelectTickets(item);
                            }
                          }}
                        >
                          <CheckBox
                            isChecked={
                              item.date === selectTickets.date &&
                              item.value.length === selectTickets.tickets.length
                            }
                            onChange={() => {}}
                            style={{ width: '20px', height: '20px' }}
                          />
                          <Text color="#989ba2">전체선택</Text>
                        </Row>
                      </Row>
                      <Column
                        gap="16px"
                        style={
                          !isMobile
                            ? {
                                display: 'grid',
                                gridTemplateColumns: `repeat(${
                                  isTablet ? 2 : 3
                                }, 1fr)`,
                              }
                            : {
                                flexDirection: 'column',
                              }
                        }
                      >
                        {item.value.map(ticketItem => {
                          return (
                            <BookingCard
                              key={ticketItem.id}
                              isMobile={isMobile}
                              item={ticketItem}
                              date={bookingTickets[idx].date}
                              selectTickets={selectTickets}
                              handleSelectTickets={handleSelectTickets}
                              handleRefetchBookingList={
                                handleRefetchBookingList
                              }
                            />
                          );
                        })}
                      </Column>
                    </Column>
                    {bookingTickets.length !== idx + 1 && <Divider />}
                  </React.Fragment>
                );
              })}
            </Column>
            <InView
              onChange={inView => {
                if (inView && bookingInfo.hasNextPage) {
                  handleLoadMoreBookingTickets(ticketFilterInput.page + 1);
                  setIsLoading(true);
                }
              }}
            ></InView>
            {isLoading && (
              <Loading
                style={{
                  position: 'static',
                  height: 'auto',
                  alignItems: 'flex-end',
                }}
              />
            )}
          </React.Fragment>
        )}
      </Column>
    );
  };

  return (
    <Container ref={ref} $isMobile={isMobile}>
      <Column
        gap="12px"
        style={{
          padding: isMobile ? '0 0 90px' : '0 20px 90px',
        }}
      >
        {renderHeaderContent()}
        <BookingTicketStatusFilter
          ticketFilterInput={ticketFilterInput}
          setTicketFilterInput={setTicketFilterInput}
          handleResetSelectTickets={handleResetSelectTickets}
        />
        {renderSearchForm()}
        <Column gap="32px">{renderContents()}</Column>
        {isOpenSearchModal && (
          <BookingSearchModal
            isMobile={isMobile}
            searchType={searchType}
            filterSearchValue={filterSearchValue}
            ticketFilterInput={ticketFilterInput}
            handleSetTicekFilterInput={handleSetTicekFilterInput}
            setSearchType={setSearchType}
            setFilterSearchValue={setFilterSearchValue}
            setIsAcceptFilter={setIsAcceptFilter}
            onClose={() => setIsOpenSearchModal(false)}
          />
        )}
        {isOpenCalendarModal && renderCalendarModal()}
        {!isEmpty(selectTickets.tickets) && (
          <BookingBottomSheet
            isMobile={isMobile}
            selectTickets={selectTickets}
            ticketFilterInput={ticketFilterInput}
            handleRefetchBookingList={handleRefetchBookingList}
            handleResetSelectTickets={handleResetSelectTickets}
          />
        )}
      </Column>
    </Container>
  );
};

export default BookingListPage;
