import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import axios from 'axios';
import { UPLOAD_GENERATE_CONTENT } from 'containers/ProductRegist/components/InformationRegist/graphQL';
import { ContentUploadInfo, ContentUploadInfoItem } from 'types/api';

export function useMutationGenerateContentUploadInfo() {
  const [generateContentupload] = useMutation(UPLOAD_GENERATE_CONTENT);

  const handleMakeFormForUpload = (
    file: File,
    uploadInfo: ContentUploadInfo
  ) => {
    const form = new FormData();
    uploadInfo.formData.forEach((element: ContentUploadInfoItem) => {
      form.append(element.key, element.value);
    });
    form.append('file', file);
    return form;
  };

  const handleGenerateContentUpload = async (
    uploadInfo: {
      file: File;
      fileName: string;
    },
    callback: (url: string, contentId: string) => void
  ) => {
    try {
      const response = await generateContentupload({
        variables: { param: { fileName: uploadInfo.fileName } },
      });

      if (response) {
        const result = response.data.generateContentUploadInfo;

        const form = handleMakeFormForUpload(uploadInfo.file, result);
        const uploadedImage = await axios.post(result.uploadUrl, form, {
          headers: {},
        });

        if (uploadedImage) {
          callback(uploadedImage.data.url, result.contentId);
        }
      }
    } catch (error) {
      console.log(error, 'upload error');
    }
  };

  return { handleGenerateContentUpload };
}
