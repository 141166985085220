import Column from 'components/common/Column';
import Text from 'components/common/Text';
import Divider from 'components/Divider';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import React from 'react';
import styled from 'styled-components';
import { CurriculumStyle } from 'types/api';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding-bottom: 24px;
  width: 100%;
`;

interface CurriculumContainerIProps {
  productParamInput: ProductParamInput;
}

const CurriculumContainer: React.FC<CurriculumContainerIProps> = props => {
  const { productParamInput } = props;

  return (
    <Container>
      <Divider style={{ height: '8px', background: '#F4F4F4' }} />
      <Text color="#000" size="18px" weight={700} style={{ padding: '0 24px' }}>
        스케줄
      </Text>
      <Column gap="24px" style={{ padding: '0 24px' }}>
        {productParamInput.frip?.curriculum.sections.map(
          (sectionItem, sectionIdx) => {
            return (
              <Column align="flex-start" gap="8px" key={sectionIdx}>
                <Text color="#333" size="14px">
                  {sectionItem.title}
                </Text>
                {sectionItem.items.map((item, idx) => {
                  return (
                    <Column align="flex-start" key={idx}>
                      {productParamInput.frip?.curriculum.style ===
                      CurriculumStyle.DURATION ? (
                        <Text size="12x">{item.duration}분</Text>
                      ) : productParamInput.frip?.curriculum.style ===
                        CurriculumStyle.TIMETABLE ? (
                        <Text>
                          {item.term.startingTime.hour}시{' '}
                          {item.term.startingTime.minute}분 ~{' '}
                          {item.term.endingTime.hour}시{' '}
                          {item.term.endingTime.minute}분{' '}
                        </Text>
                      ) : (
                        <Text>{item.textTime}</Text>
                      )}

                      <Text size="12x">• {item.content}</Text>
                    </Column>
                  );
                })}
              </Column>
            );
          },
        )}
      </Column>
    </Container>
  );
};

export default CurriculumContainer;
