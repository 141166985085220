import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Column from 'components/common/Column';
import Text from 'components/common/Text';
import InputForm from 'containers/Login/components/InputForm';
import { useMutationUpdateHostProfile } from '../graphQL/hooks/useMutationUpdateHostProfile';
import AuthButton from 'components/Button/AuthButton';
import { MeData } from 'graphQL/Auth/types';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import Row from 'components/common/Row';
import ReCertification from './ReCertification';
import { getEnvConstant } from 'constants/env';
import { useCertifictationHooks } from 'containers/Signup/hooks/useCertificationHooks';
import HackleManager from 'utils/hackle';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  padding: 24px 20px 40px;
  width: 100%;
  overflow-y: scroll;
`;

const FloatingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  padding: 16px 20px 24px 20px;
  margin-top: auto;

  width: 100%;
  background-color: #fff;
  border-radius: 0 0 16px 16px;
  border-top: 1px solid #dbdcdf;
  z-index: 21;
`;

interface IndividualUpdateContainerIProps {
  isMobile: boolean;
  onClose?: () => void;
}

const IndividualUpdateContainer: React.FC<
  IndividualUpdateContainerIProps
> = props => {
  const { isMobile, onClose } = props;
  const myInfo: MeData = useUserInformation(state => state.userInfo);

  const {
    hostBaseParams,
    handleUpdateHostBaseParams,
    handleUpdateHostProfile,
  } = useMutationUpdateHostProfile();
  const { handleHostCertification } = useCertifictationHooks();

  const handleUpdateIndividual = () => {
    handleUpdateHostProfile(() => {
      HackleManager.logIndividualUpdateComplete();
    });
  };

  const handleMobileCertification = () => {
    window.IMP.certification(
      { phone: hostBaseParams.param.phoneNumber },
      (response: any) => {
        if (response.success) {
          handleHostCertification(response.imp_uid, () => {
            window.showToast('인증이 완료되었습니다.', 'success');

            handleUpdateIndividual();
          });
        }
      },
    );
  };

  const handleSetHostInfo = (key: string, value: any) => {
    handleUpdateHostBaseParams('param', {
      ...hostBaseParams.param,
      [key]: value,
    });
  };

  useEffect(() => {
    window.IMP.init(getEnvConstant('iamport'));
  }, []);

  return (
    <Column gap="32px" style={{ paddingBottom: '90px' }}>
      <Container>
        <Column gap="12px">
          <Column gap="6px" align="flex-start">
            <Text color="#000" size="16px">
              본인 인증
            </Text>
            <Text color="#989ba2" size="14px" weight={400}>
              실제 운영하시는 분의 휴대폰번호로 인증해 주세요. 프립 진행 관련
              중요한 알림이 발송됩니다.
            </Text>
          </Column>
          <ReCertification
            mobileNumber={myInfo.me.user.mobileNumber}
            isMobileCertification={myInfo.me.user.verified}
            handleMobileCertification={handleMobileCertification}
          />
        </Column>
        <Column gap="12px">
          <Column gap="6px" align="flex-start">
            <Text color="#000" size="16px">
              공개 연락처 (선택사항)
            </Text>
            <Text color="#989ba2" size="14px" weight={400}>
              프립에서 공개되는 연락처입니다. 입력하지 않으면 본인 인증에 사용한
              연락처가 노출됩니다.
            </Text>
          </Column>
          <InputForm
            type="text"
            styleType="large"
            label="연락처를 입력해 주세요"
            target="phoneNumber"
            inputValue={hostBaseParams.param.phoneNumber}
            onChange={handleSetHostInfo}
          />
        </Column>
        <Column gap="12px">
          <Column gap="6px" align="flex-start">
            <Text color="#000" size="16px">
              이메일
            </Text>
            <Text color="#989ba2" size="14px" weight={400}>
              중요한 안내 사항을 이메일로 보내드립니다.
            </Text>
          </Column>
          <InputForm
            type="text"
            styleType="large"
            label="이메일을 입력해 주세요"
            target="email"
            inputValue={hostBaseParams.param.email}
            onChange={handleSetHostInfo}
          />
        </Column>
      </Container>
      {isMobile ? (
        <FloatingContainer>
          <Text
            color="#1b1c1e"
            size="16px"
            style={{ textDecoration: 'underline' }}
            onClick={() => {
              onClose && onClose();
            }}
          >
            닫기
          </Text>
          <AuthButton
            name={'확인'}
            isAbled={true}
            onClick={() => {
              handleUpdateIndividual();
            }}
            style={{ width: 'auto', minWidth: '76px' }}
          />
        </FloatingContainer>
      ) : (
        <Row
          justifyContent="flex-end"
          style={{ padding: '0 20px', width: '100%' }}
        >
          <AuthButton
            isAbled={true}
            name="저장"
            onClick={() => {
              handleUpdateIndividual();
            }}
            style={{ width: '80px' }}
          />
        </Row>
      )}
    </Column>
  );
};

export default IndividualUpdateContainer;
