import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import Column from 'components/common/Column';
import Text from 'components/common/Text';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import { InteractionType, Product, ProductState } from 'types/api';
import Row from 'components/common/Row';
import AuthButton from 'components/Button/AuthButton';
import { ModifyValue } from 'containers/ProductDetail/hooks/useProductDetaillHooks';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import SaveButton from '../SaveButton';
import HackleManager from 'utils/hackle';

const SelectOnlineWay = styled.div<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;

  width: 100%;

  border-radius: 12px;
  border: ${props =>
    props.$isSelected ? '1.5px solid #1b1c1e' : '1px solid #dbdcdf'};
  background: ${props => (props.$isSelected ? '#f7f7f8' : '#fff')};

  color: #1b1c1e;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.6px;
  cursor: pointer;
`;

const RadioBox = styled.input`
  width: 24px;
  height: 24px;
  appearance: none;
  border: 3px solid #eee;
  border-radius: 50%;
  cursor: pointer;

  &:checked {
    border: 8px solid #1b1c1e;
  }
`;

interface OnlineSelectFormIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  productInfo: Product;
  modifyValue: ModifyValue;
  setModifyValue: Dispatch<SetStateAction<ModifyValue>>;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
}

const OnlineModifyForm: React.FC<OnlineSelectFormIProps> = props => {
  const {
    isMobile,
    productParamInput,
    productInfo,
    modifyValue,
    setModifyValue,
    handleOnChangeProductParamInput,
  } = props;

  const [interactionType, setInteractionType] = useState<InteractionType>(
    productParamInput.frip.interactionType,
  );
  const isAbled = interactionType !== productParamInput.frip.interactionType;

  const handleChangeOnlineForm = (interactionType: InteractionType) => {
    setInteractionType(interactionType);
  };

  const handleResetOnline = () => {
    setInteractionType(productParamInput.frip.interactionType);
  };

  const handleRegistOnline = () => {
    handleOnChangeProductParamInput('frip', {
      ...productParamInput.frip,
      interactionType,
    });
  };

  const renderForm = (): JSX.Element => {
    return (
      <Column gap="12px">
        <SelectOnlineWay
          $isSelected={interactionType === InteractionType.VIDEO_CHAT}
          onClick={() => handleChangeOnlineForm(InteractionType.VIDEO_CHAT)}
        >
          화상 채팅
          <RadioBox
            type="radio"
            checked={interactionType === InteractionType.VIDEO_CHAT}
            onChange={() => handleChangeOnlineForm(InteractionType.VIDEO_CHAT)}
          />
        </SelectOnlineWay>
        <SelectOnlineWay
          $isSelected={interactionType === InteractionType.PHONE_CALL}
          onClick={() => handleChangeOnlineForm(InteractionType.PHONE_CALL)}
        >
          전화
          <RadioBox
            type="radio"
            checked={interactionType === InteractionType.PHONE_CALL}
            onChange={() => handleChangeOnlineForm(InteractionType.PHONE_CALL)}
          />
        </SelectOnlineWay>
        <SelectOnlineWay
          $isSelected={interactionType === InteractionType.VIDEO}
          onClick={() => handleChangeOnlineForm(InteractionType.VIDEO)}
        >
          영상 제공
          <RadioBox
            type="radio"
            checked={interactionType === InteractionType.VIDEO}
            onChange={() => handleChangeOnlineForm(InteractionType.VIDEO)}
          />
        </SelectOnlineWay>
        <SelectOnlineWay
          $isSelected={interactionType === InteractionType.CHATTING_GUIDE}
          onClick={() => handleChangeOnlineForm(InteractionType.CHATTING_GUIDE)}
        >
          채팅을 통한 별도 안내
          <RadioBox
            type="radio"
            checked={interactionType === InteractionType.CHATTING_GUIDE}
            onChange={() =>
              handleChangeOnlineForm(InteractionType.CHATTING_GUIDE)
            }
          />
        </SelectOnlineWay>
      </Column>
    );
  };

  const renderContents = (): JSX.Element => {
    if (isMobile) {
      return (
        <ModalContainer
          isMobile={isMobile}
          isAbled={productInfo.status !== ProductState.CLOSED && isAbled}
          onClose={() => {
            setModifyValue({ ...modifyValue, modifyStatus: '' });
          }}
          onCloseText="닫기"
          onAcceptText="적용"
          onClick={() => {
            if (isAbled) {
              HackleManager.logProductModifyItemSaveComplete(
                Number(productInfo.id),
                productInfo.title,
                '온라인',
              );
              handleRegistOnline();

              setModifyValue({
                ...modifyValue,
                isModify: true,
                modifyStatus: '',
              });
            }
          }}
        >
          <Column gap="32px" align="flex-start" style={{ padding: '20px' }}>
            <Text size="24px" color="#1b1c1e" style={{ lineHeight: '32px' }}>
              온라인 진행 방법을 알려주세요
            </Text>
            {renderForm()}
          </Column>
        </ModalContainer>
      );
    }

    return (
      <Column>
        <Row
          justifyContent="flex-start"
          style={{
            position: 'sticky',
            top: 0,
            padding: '32px 40px',
            width: '100%',
            background: '#fff',
            border: '1px solid #eaebec',
            zIndex: 8,
          }}
        >
          <Text color="#1B1C1E" size="24px">
            온라인 정보 수정하기
          </Text>
        </Row>
        <Column gap="24px" style={{ padding: '32px 40px' }}>
          {renderForm()}
          {productInfo.status !== ProductState.CLOSED && (
            <SaveButton
              isAbled={isAbled}
              modifyValue={modifyValue}
              setModifyValue={setModifyValue}
              handleResetItem={handleResetOnline}
              handleRegistItem={() => {
                HackleManager.logProductModifyItemSaveComplete(
                  Number(productInfo.id),
                  productInfo.title,
                  '온라인',
                );
                handleRegistOnline();
              }}
            />
          )}
        </Column>
      </Column>
    );
  };

  return renderContents();
};

export default OnlineModifyForm;
