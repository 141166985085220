import React from 'react';
import { styled } from 'styled-components';

const Container = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;

  color: #1b1c1e;

  /* Title/T-16-Semibold */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.6px;
`;

interface BackButtonIProps {
  name: string;
  style?: React.CSSProperties;
  onClick: () => void;
}

const BackButton: React.FC<BackButtonIProps> = (props) => {
  const { name, style, onClick } = props;
  return (
    <Container style={{ ...style }} onClick={onClick}>
      {name}
    </Container>
  );
};

export default BackButton;
