import Text from 'components/common/Text';
import React from 'react';
import styled from 'styled-components';
import { HostVerifiedBusinessParamInput, TaxPayerType } from '../graphQL/type';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0px;
  gap: 20px;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 20px;
`;

const ModifyText = styled.div`
  color: #171719;
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.6px;
  text-decoration-line: underline;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px;
  border-radius: 12px;
  border: 1px solid #eee;
  background: #eee;

  img {
    width: 100%;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border: 0.5px solid #dbdcdf;
`;

interface CheckInformationIProps {
  businessInformation: HostVerifiedBusinessParamInput;
  previewImage: string;
  registrationNo: string;
  handleModifyBusinessInformation: (pageNumber: number) => void;
}

const CheckInformation: React.FC<CheckInformationIProps> = (props) => {
  const {
    businessInformation,
    previewImage,
    registrationNo,
    handleModifyBusinessInformation,
  } = props;

  const renderModifyButton = (pageNumber: number) => {
    return (
      <ModifyText
        onClick={() => {
          handleModifyBusinessInformation(pageNumber);
        }}
      >
        수정
      </ModifyText>
    );
  };

  const renderBusinessInformation = () => {
    switch (businessInformation.taxPayerType) {
      case TaxPayerType.INDIVIDUAL: {
        return (
          <React.Fragment>
            <Row>
              <Column>
                <Text color="#1b1c1e" size="16px" weight={400}>
                  주민등록번호
                </Text>
                <Text color="#1b1c1e" weight={400}>
                  {registrationNo}
                </Text>
              </Column>
              {/* {renderModifyButton(2)} */}
            </Row>
            <Divider />
            {!businessInformation.foreign && (
              <>
                <Row>
                  <Column>
                    <Text color="#1b1c1e" size="16px" weight={400}>
                      주소
                    </Text>
                    <Text color="#1b1c1e" weight={400}>
                      {businessInformation.address}
                    </Text>
                  </Column>
                  {/* {renderModifyButton(2)} */}
                </Row>
                <Divider />
              </>
            )}
          </React.Fragment>
        );
      }
      case TaxPayerType.CORPORATION: {
        return (
          <React.Fragment>
            <Row>
              <Column>
                <Text color="#1b1c1e" size="16px" weight={400}>
                  업체명
                </Text>
                <Text color="#1b1c1e" weight={400}>
                  {businessInformation.taxPayerName}
                </Text>
              </Column>
              {/* {renderModifyButton(2)} */}
            </Row>
            <Divider />
            <Row>
              <Column>
                <Text color="#1b1c1e" size="16px" weight={400}>
                  사업자등록증 번호
                </Text>
                <Text color="#1b1c1e" weight={400}>
                  {registrationNo}
                </Text>
              </Column>
              {/* {renderModifyButton(2)} */}
            </Row>
            <Divider />
            <Row>
              <Column>
                <Text color="#1b1c1e" size="16px" weight={400}>
                  사업 업태
                </Text>
                <Text color="#1b1c1e" weight={400}>
                  {businessInformation.businessType}
                </Text>
              </Column>
              {/* {renderModifyButton(2)} */}
            </Row>
            <Divider />
            <Row>
              <Column>
                <Text color="#1b1c1e" size="16px" weight={400}>
                  사업 종목
                </Text>
                <Text color="#1b1c1e" weight={400}>
                  {businessInformation.businessItem}
                </Text>
              </Column>
              {/* {renderModifyButton(2)} */}
            </Row>
            <Divider />
            <Row>
              <Column>
                <Text color="#1b1c1e" size="16px" weight={400}>
                  주소
                </Text>
                <Text color="#1b1c1e" weight={400}>
                  {businessInformation.address}
                </Text>
              </Column>
              {/* {renderModifyButton(2)} */}
            </Row>
            <Divider />
          </React.Fragment>
        );
      }

      default:
        return <></>;
    }
  };

  return (
    <Container>
      <Column style={{ gap: '8px' }}>
        <Text color="#1b1c1e" size="24px">
          정보 확인하기
        </Text>
        <Text color="#1b1c1e" size="16px" weight={400}>
          거의 완료되었습니다! 등록하기 전에 정보가 모두 정확한지 다시 한번
          확인해 주세요.
        </Text>
      </Column>
      <Row style={{ marginTop: '12px' }}>
        <Column>
          <Text color="#1b1c1e" size="16px" weight={400}>
            호스트 유형
          </Text>
          <Text color="#1b1c1e" weight={400}>
            {businessInformation.taxPayerType === TaxPayerType.INDIVIDUAL
              ? '개인'
              : '사업자'}
          </Text>
        </Column>
        {/* {renderModifyButton(1)} */}
      </Row>
      <Divider />
      <Row>
        <Column>
          <Text color="#1b1c1e" size="16px" weight={400}>
            {businessInformation.taxPayerType === TaxPayerType.INDIVIDUAL
              ? '이름'
              : '대표자명'}
          </Text>
          <Text color="#1b1c1e" weight={400}>
            {businessInformation.taxPayerType === TaxPayerType.INDIVIDUAL
              ? businessInformation.taxPayerName
              : businessInformation.representativeName}
          </Text>
        </Column>
        {/* {renderModifyButton(2)} */}
      </Row>
      <Divider />
      {renderBusinessInformation()}
      <Column style={{ gap: '12px' }}>
        <Row>
          <Text color="#1b1c1e" size="16px" weight={400}>
            신분증/운전면허증 사진
          </Text>
          {/* {renderModifyButton(3)} */}
        </Row>
        <ImageContainer>
          <img src={previewImage} alt="preview image" />
        </ImageContainer>
      </Column>
    </Container>
  );
};

export default CheckInformation;
