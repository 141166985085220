import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { STORAGE_KEY } from 'utils/localStoarge';
import { getEnvConstant } from 'constants/env';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import HackleManager from 'utils/hackle';
import { HackleProvider } from '@hackler/react-sdk';

const INIT_AUTHORIZATION = window?.__INIT_AUTHORIZATION__;

const hackleClient = HackleManager.initialize();
// const useAuthorization = () => {
//   const [token, setToken] = useState(INIT_AUTHORIZATION || "");

//   useEffect(() => {
//     const updatedToken = localStorage.getItem(STORAGE_KEY.AUTHORIZATION);

//     setToken(updatedToken);
//   }, [token, INIT_AUTHORIZATION]);

//   return token;
// };

// Apollo 클라이언트를 생성하는 컴포넌트
const ApolloClientProvider = ({ children }) => {
  // const token = useAuthorization();

  const httpLink = createHttpLink({
    uri: getEnvConstant('gqlApiUrl'),
  });

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: localStorage.getItem(STORAGE_KEY.AUTHORIZATION)
        ? `${localStorage.getItem(STORAGE_KEY.AUTHORIZATION)}`
        : '',
    },
  }));

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({ addTypename: false }),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

if (INIT_AUTHORIZATION && INIT_AUTHORIZATION !== '___INIT_AUTHORIZATION___') {
  localStorage.setItem(STORAGE_KEY.AUTHORIZATION, INIT_AUTHORIZATION);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ApolloClientProvider>
    <HackleProvider hackleClient={hackleClient}>
      <App />
    </HackleProvider>
  </ApolloClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
