export interface Settlement {
  settlement: { settlements: Settlements };
}

export interface Settlements {
  edges: SettlementsEdges[];
}

export interface SettlementsEdges {
  node: SettlementsNode;
}

export interface SettlementsAccountEdges {
  node: SettlementAccountNode;
}

export interface SettlementsNode {
  id: string;
  createdAt: string;
  startedDate: string;
  endedDate: string;
  title: string;
  periodCode: string;
  totalFee: {
    currencyCode: string;
    value: string;
  };
  totalPayment: {
    currencyCode: string;
    value: string;
  };
  invoice: Invoice | null;
}

export interface Invoice {
  id: string;
  title: string;
  status: InvoiceState;
  createdAt: string;
  completedAt: string;
}

export enum InvoiceState {
  COMPLETED = "COMPLETED",
  PROGRESSING = "PROGRESSING",
  READY = "READY",
  RECEIVED = "RECEIVED",
  RECEIVE_REQUESTED = "RECEIVE_REQUESTED",
}

export interface SettlementAccountNode extends SettlementsNode {
  payment: {
    currencyCode: string;
    value: string;
  };
  commissionFee: {
    currencyCode: string;
    value: string;
  };
  order: {
    id: string;
    customer: { nickname: string };
    transactions: { firmedAt: string }[];
  };
  orderItem: {
    id: string;
    transaction: {
      firmedAt: string;
      order: {
        id: string;
        customer: { nickname: string };
        transactions: { firmedAt: string }[];
      };
    };
  };
  product: {
    listingProduct: { title: string };
    options: { names: string[] }[];
  };
  type: SettlementAccountType;
  referredType: AccountReferredType;
  settlement: {
    id: string;
    totalFee: {
      currencyCode: CurrencyCode;
      value: number;
    };
  };
}

export interface SettlementAccount {
  settlement: {
    accounts: {
      totalCount: number;
      edges: SettlementsAccountEdges[];
      pageInfo: {
        hasNextPage: boolean;
      };
    };
  };
}

export enum SettlementAccountType {
  PAYABLE = "PAYABLE",
  RECEIVABLE = "RECEIVABLE",
}

export enum AccountReferredType {
  ABSENCE = "ABSENCE",
  CANCEL_FEE = "CANCEL_FEE",
  COUPON = "COUPON",
  DIGITAL = "DIGITAL",
  ETC = "ETC",
  FRIP = "FRIP",
  GOODS = "GOODS",
}

enum CurrencyCode {
  KRW = "KRW",
  USD = "USD",
}
