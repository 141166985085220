import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Row from 'components/common/Row';
import OptionButton from 'containers/ProductRegist/components/common/OptionButton';
import CurriculumForm from 'containers/ProductRegist/components/InformationRegist/components/CurriculumForm';
import { useCurriculumHooks } from 'containers/ProductRegist/components/InformationRegist/hooks/useCurriculumHooks';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import { CurriculumStyle, Product, ProductState } from 'types/api';
import Column from 'components/common/Column';
import Text from 'components/common/Text';
import { ModifyValue } from 'containers/ProductDetail/hooks/useProductDetaillHooks';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import SaveButton from '../SaveButton';
import HackleManager from 'utils/hackle';
import CalloutContainer from 'containers/ProductRegist/components/InformationRegist/components/CalloutContainer';
import { isEmpty } from 'lodash';

interface ScheduleModifyFormIprops {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  productInfo: Product;
  modifyValue: ModifyValue;
  setModifyValue: Dispatch<SetStateAction<ModifyValue>>;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
}

const ScheduleModifyForm: React.FC<ScheduleModifyFormIprops> = props => {
  const {
    isMobile,
    productParamInput,
    productInfo,
    modifyValue,
    setModifyValue,
    handleOnChangeProductParamInput,
  } = props;

  const [copyProductParamInput, setCopyProductParamInput] =
    useState<ProductParamInput>(productParamInput);

  const fripInfo = copyProductParamInput.frip;

  const handleOnChangeCopyProductParamInput = (key: string, value: any) => {
    setCopyProductParamInput({ ...copyProductParamInput, [key]: value });
  };

  const {
    handleResetCurriculum,
    handleInitCurriculum,
    handleAddCurriculumSections,
    handleAddCurriculumItems,
    handleDeleteCurriculumItems,
    handleRemoveCurriculumItems,
  } = useCurriculumHooks(
    copyProductParamInput,
    handleOnChangeCopyProductParamInput,
  );

  const scheduleValidation = (): boolean => {
    switch (fripInfo.curriculum.style) {
      case CurriculumStyle.DURATION: {
        return fripInfo.curriculum.sections.every(
          item =>
            ((item.isOpenEtc && item.etc !== '') || !item.isOpenEtc) &&
            item.title !== '' &&
            item.items.every(scheduleItem => scheduleItem.content !== ''),
        );
      }
      case CurriculumStyle.TIMETABLE: {
        return fripInfo.curriculum.sections.every(
          item =>
            ((item.isOpenEtc && item.etc !== '') || !item.isOpenEtc) &&
            item.title !== '' &&
            item.items.every(scheduleItem => scheduleItem.content !== ''),
        );
      }
      case CurriculumStyle.LEGACY: {
        return fripInfo.curriculum.sections.every(
          item =>
            ((item.isOpenEtc && item.etc !== '') || !item.isOpenEtc) &&
            item.title !== '' &&
            item.items.every(
              scheduleItem =>
                scheduleItem.textTime !== '' && scheduleItem.content !== '',
            ),
        );
      }
      default:
        return false;
    }
  };

  const isAbled = !isEmpty(copyProductParamInput.frip.curriculum.sections)
    ? scheduleValidation() &&
      productParamInput.frip.curriculum.sections !==
        copyProductParamInput.frip.curriculum.sections
    : productParamInput.frip.curriculum.sections !==
      copyProductParamInput.frip.curriculum.sections;

  const handleRegistSchedule = () => {
    handleOnChangeProductParamInput('frip', {
      ...productParamInput.frip,
      curriculum: copyProductParamInput.frip.curriculum,
    });
  };

  const handleResetSchedule = () => {
    setCopyProductParamInput(productParamInput);
  };

  useEffect(() => {
    HackleManager.logProductModifyCurriculumView(
      Number(productInfo.id),
      productInfo.title,
    );
  }, []);

  const renderScheduleSelectForm = (): JSX.Element => {
    return (
      <React.Fragment>
        <Row gap="8px" style={{ width: '100%' }}>
          <OptionButton
            text="시간표"
            isSelected={fripInfo.curriculum.style === CurriculumStyle.DURATION}
            onClick={() => {
              handleResetCurriculum(CurriculumStyle.DURATION);
            }}
          />
          <OptionButton
            text="구간표"
            isSelected={fripInfo.curriculum.style === CurriculumStyle.TIMETABLE}
            onClick={() => {
              handleResetCurriculum(CurriculumStyle.TIMETABLE);
            }}
          />
          <OptionButton
            text="직접 입력"
            isSelected={fripInfo.curriculum.style === CurriculumStyle.LEGACY}
            onClick={() => {
              handleResetCurriculum(CurriculumStyle.LEGACY);
            }}
          />
        </Row>
        {fripInfo.curriculum.style !== CurriculumStyle.NONE && (
          <CalloutContainer curriculumStyle={fripInfo.curriculum.style} />
        )}
      </React.Fragment>
    );
  };

  const renderCurriculumForm = (): JSX.Element => {
    return (
      fripInfo.curriculum.style !== CurriculumStyle.NONE && (
        <CurriculumForm
          productParamInput={copyProductParamInput}
          handleAddCurriculumItems={handleAddCurriculumItems}
          handleAddCurriculumSections={handleAddCurriculumSections}
          handleDeleteCurriculumItems={handleDeleteCurriculumItems}
          handleRemoveCurriculumItems={handleRemoveCurriculumItems}
          handleOnChangeProductParamInput={handleOnChangeCopyProductParamInput}
          handleResetCurriculum={handleResetCurriculum}
        />
      )
    );
  };

  const renderContents = (): JSX.Element => {
    if (isMobile) {
      return (
        <ModalContainer
          isMobile={isMobile}
          isAbled={productInfo.status !== ProductState.CLOSED && isAbled}
          onAcceptText="적용"
          onClose={() => {
            setModifyValue({ ...modifyValue, modifyStatus: '' });
          }}
          onClick={() => {
            if (isAbled) {
              HackleManager.logProductModifyItemSaveComplete(
                Number(productInfo.id),
                productInfo.title,
                '스케줄',
              );
              handleRegistSchedule();

              setModifyValue({
                ...modifyValue,
                isModify: true,
                modifyStatus: '',
              });
            }
          }}
        >
          <Column gap="24px" style={{ position: 'relative' }}>
            <Row
              style={{
                position: 'sticky',
                top: 0,
                padding: '16px 0',
                width: '100%',
                background: '#fff',
                borderBottom: '1px solid #dbdcdf',
                zIndex: 2,
              }}
            >
              <Text color="#000" size="16px" weight={600}>
                스케줄 수정하기
              </Text>
            </Row>
            <Column gap="24px" style={{ padding: '0 20px' }}>
              {renderScheduleSelectForm()}
              {renderCurriculumForm()}
            </Column>
          </Column>
        </ModalContainer>
      );
    }

    return (
      <Column>
        <Row
          justifyContent="flex-start"
          style={{
            position: 'sticky',
            top: 0,
            padding: '32px 40px',
            width: '100%',
            background: '#fff',
            border: '1px solid #eaebec',
            zIndex: 8,
          }}
        >
          <Text color="#1B1C1E" size="24px">
            스케줄 수정하기
          </Text>
        </Row>
        <Column gap="24px" style={{ padding: '32px 40px' }}>
          {renderScheduleSelectForm()}
          {renderCurriculumForm()}
          {productInfo.status !== ProductState.CLOSED && (
            <SaveButton
              isAbled={isAbled}
              modifyValue={modifyValue}
              setModifyValue={setModifyValue}
              handleResetItem={handleResetSchedule}
              handleRegistItem={() => {
                HackleManager.logProductModifyItemSaveComplete(
                  Number(productInfo.id),
                  productInfo.title,
                  '스케줄',
                );
                handleRegistSchedule();
              }}
            />
          )}
        </Column>
      </Column>
    );
  };

  return renderContents();
};

export default ScheduleModifyForm;
