import React, { Dispatch, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import Column from 'components/common/Column';
import Text from 'components/common/Text';
import {
  CompanionType,
  LevelOfDifficulty,
  Product,
  ProductState,
} from 'types/api';
import {
  FripSaleInfoParam,
  ProductParamInput,
} from 'containers/ProductRegist/hooks/types';
import Row from 'components/common/Row';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import { useMorInformationModifyHooks } from './hooks/useMorInformationModifyHooks';
import AuthButton from 'components/Button/AuthButton';
import { ModifyValue } from 'containers/ProductDetail/hooks/useProductDetaillHooks';
import SaveButton from '../SaveButton';
import HackleManager from 'utils/hackle';

const OptionForm = styled.div<{ $isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: ${props => (props.$isMobile ? 'inherit' : 'wrap')};
  gap: 8px;
  width: 100%;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const OptionSelectBox = styled.div<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  min-width: 90px;

  border-radius: 99px;
  border: ${props =>
    props.$isSelected ? '1.5px solid #1b1c1e' : '1px solid #dbdcdf'};
  background: ${props => (props.$isSelected ? '#f7f7f8' : '#fff')};

  color: var(--gray-gray-09, #1b1c1e);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
  cursor: pointer;
`;

interface MoreInforMationModifyFormIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  productInfo: Product;
  modifyValue: ModifyValue;
  setModifyValue: Dispatch<SetStateAction<ModifyValue>>;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
}

const MoreInformationModifyForm: React.FC<
  MoreInforMationModifyFormIProps
> = props => {
  const {
    isMobile,
    productParamInput,
    productInfo,
    modifyValue,
    setModifyValue,
    handleOnChangeProductParamInput,
  } = props;

  const {
    copyFripInformation,
    isAbled,
    handleSetDifficulty,
    handleSetCompanions,
    handleSetRecommendedAge,
    handleSetIsdoor,
    handleResetCopyFripInformation,
  } = useMorInformationModifyHooks(productParamInput);

  const handleRegistMoreInformation = () => {
    handleOnChangeProductParamInput('frip', {
      ...productParamInput.frip,
      difficulty: copyFripInformation.difficulty,
      companions: copyFripInformation.companions,
      recommendedAge: copyFripInformation.recommendedAge,
      isIndoor: copyFripInformation.isdoor.isIndoor,
      isOutdoor: copyFripInformation.isdoor.isOutdoor,
    });
  };

  useEffect(() => {
    HackleManager.logProductModifyParticipantGuideView(
      Number(productInfo.id),
      productInfo.title,
    );
  }, []);

  const renderLevelForm = (): JSX.Element => {
    return (
      <Column gap="12px" align="flex-start">
        <Text color="#1b1c1e" size="16px">
          난이도
        </Text>
        <OptionForm $isMobile={isMobile}>
          <OptionSelectBox
            $isSelected={
              copyFripInformation.difficulty === LevelOfDifficulty.VERY_EASY
            }
            onClick={() => {
              handleSetDifficulty(LevelOfDifficulty.VERY_EASY);
            }}
          >
            매우 쉬움
          </OptionSelectBox>
          <OptionSelectBox
            $isSelected={
              copyFripInformation.difficulty === LevelOfDifficulty.EASY
            }
            onClick={() => {
              handleSetDifficulty(LevelOfDifficulty.EASY);
            }}
          >
            쉬움
          </OptionSelectBox>
          <OptionSelectBox
            $isSelected={
              copyFripInformation.difficulty === LevelOfDifficulty.NORMAL
            }
            onClick={() => {
              handleSetDifficulty(LevelOfDifficulty.NORMAL);
            }}
          >
            보통
          </OptionSelectBox>
          <OptionSelectBox
            $isSelected={
              copyFripInformation.difficulty === LevelOfDifficulty.HIGH
            }
            onClick={() => {
              handleSetDifficulty(LevelOfDifficulty.HIGH);
            }}
          >
            어려움
          </OptionSelectBox>
        </OptionForm>
      </Column>
    );
  };

  const renderCompanionsForm = (): JSX.Element => {
    return (
      <Column gap="12px" align="flex-start">
        <Text color="#1b1c1e" size="16px">
          누구와 함께하기 좋아요?{' '}
          <span style={{ color: '#989ba2', fontSize: '14px', fontWeight: 400 }}>
            (중복 선택 가능)
          </span>
        </Text>
        <OptionForm $isMobile={isMobile}>
          <OptionSelectBox
            $isSelected={copyFripInformation.companions.includes(
              CompanionType.ALONE,
            )}
            onClick={() => handleSetCompanions(CompanionType.ALONE)}
          >
            혼자
          </OptionSelectBox>
          <OptionSelectBox
            $isSelected={copyFripInformation.companions.includes(
              CompanionType.WITH_FRIENDS,
            )}
            onClick={() => handleSetCompanions(CompanionType.WITH_FRIENDS)}
          >
            친구와
          </OptionSelectBox>
          <OptionSelectBox
            $isSelected={copyFripInformation.companions.includes(
              CompanionType.WITH_LOVER,
            )}
            onClick={() => handleSetCompanions(CompanionType.WITH_LOVER)}
          >
            연인과
          </OptionSelectBox>
          <OptionSelectBox
            $isSelected={copyFripInformation.companions.includes(
              CompanionType.WITH_CHILDREN,
            )}
            onClick={() => handleSetCompanions(CompanionType.WITH_CHILDREN)}
          >
            아이와
          </OptionSelectBox>
          <OptionSelectBox
            $isSelected={copyFripInformation.companions.includes(
              CompanionType.WITH_DOG,
            )}
            onClick={() => handleSetCompanions(CompanionType.WITH_DOG)}
          >
            반려견과
          </OptionSelectBox>
        </OptionForm>
      </Column>
    );
  };

  const renderRecommendedAgeForm = (): JSX.Element => {
    return (
      <Column gap="12px" align="flex-start">
        <Text color="#1b1c1e" size="16px">
          권장 연령
        </Text>
        <OptionForm $isMobile={isMobile}>
          <OptionSelectBox
            $isSelected={copyFripInformation.recommendedAge === 0}
            onClick={() => handleSetRecommendedAge(0)}
          >
            누구나
          </OptionSelectBox>
          <OptionSelectBox
            $isSelected={copyFripInformation.recommendedAge === 20}
            onClick={() => handleSetRecommendedAge(20)}
          >
            20대 이상
          </OptionSelectBox>
          <OptionSelectBox
            $isSelected={copyFripInformation.recommendedAge === 30}
            onClick={() => handleSetRecommendedAge(30)}
          >
            30대 이상
          </OptionSelectBox>
          <OptionSelectBox
            $isSelected={copyFripInformation.recommendedAge === 40}
            onClick={() => handleSetRecommendedAge(40)}
          >
            40대 이상
          </OptionSelectBox>
          <OptionSelectBox
            $isSelected={copyFripInformation.recommendedAge === 50}
            onClick={() => handleSetRecommendedAge(50)}
          >
            50대 이상
          </OptionSelectBox>
        </OptionForm>
      </Column>
    );
  };

  const renderIsDoorForm = (): JSX.Element => {
    return (
      <Column gap="12px" align="flex-start">
        <Text color="#1b1c1e" size="16px">
          실내/실외{' '}
          <span style={{ color: '#989ba2', fontSize: '14px', fontWeight: 400 }}>
            (중복 선택 가능)
          </span>
        </Text>
        <OptionForm $isMobile={isMobile}>
          <OptionSelectBox
            $isSelected={copyFripInformation.isdoor.isIndoor}
            onClick={() =>
              handleSetIsdoor({
                isIndoor: !copyFripInformation.isdoor.isIndoor,
                isOutdoor: copyFripInformation.isdoor.isOutdoor,
              })
            }
          >
            실내
          </OptionSelectBox>
          <OptionSelectBox
            $isSelected={copyFripInformation.isdoor.isOutdoor}
            onClick={() =>
              handleSetIsdoor({
                isIndoor: copyFripInformation.isdoor.isIndoor,
                isOutdoor: !copyFripInformation.isdoor.isOutdoor,
              })
            }
          >
            실외
          </OptionSelectBox>
        </OptionForm>
      </Column>
    );
  };

  const renderContents = (): JSX.Element => {
    if (isMobile) {
      return (
        <ModalContainer
          isMobile={isMobile}
          isAbled={productInfo.status !== ProductState.CLOSED && isAbled}
          onClose={() => {
            setModifyValue({ ...modifyValue, modifyStatus: '' });
          }}
          onCloseText="닫기"
          onAcceptText="저장"
          onClick={() => {
            if (isAbled) {
              HackleManager.logProductModifyItemSaveComplete(
                Number(productInfo.id),
                productInfo.title,
                '부가정보',
              );

              handleRegistMoreInformation();

              setModifyValue({
                ...modifyValue,
                isModify: true,
                modifyStatus: '',
              });
            }
          }}
        >
          <Column gap="24px" style={{ padding: '16px 20px 0' }}>
            <Row>
              <Text color="#000" size="16px" weight={600}>
                부가정보
              </Text>
            </Row>
            {renderLevelForm()}
            {renderCompanionsForm()}
            {renderRecommendedAgeForm()}
            {renderIsDoorForm()}
          </Column>
        </ModalContainer>
      );
    }

    return (
      <Column>
        <Row
          justifyContent="flex-start"
          style={{
            position: 'sticky',
            top: 0,
            padding: '32px 40px',
            width: '100%',
            background: '#fff',
            borderBottom: '1px solid #eaebec',
          }}
        >
          <Text color="#1B1C1E" size="24px">
            참여 정보 수정하기
          </Text>
        </Row>
        <Column gap="40px" style={{ padding: '32px 40px' }}>
          {renderLevelForm()}
          {renderCompanionsForm()}
          {renderRecommendedAgeForm()}
          {renderIsDoorForm()}
          {productInfo.status !== ProductState.CLOSED && (
            <SaveButton
              isAbled={isAbled}
              modifyValue={modifyValue}
              setModifyValue={setModifyValue}
              handleResetItem={handleResetCopyFripInformation}
              handleRegistItem={() => {
                HackleManager.logProductModifyItemSaveComplete(
                  Number(productInfo.id),
                  productInfo.title,
                  '부가정보',
                );
                handleRegistMoreInformation();
              }}
            />
          )}
        </Column>
      </Column>
    );
  };

  return renderContents();
};

export default MoreInformationModifyForm;
