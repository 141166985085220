import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import HackleManager from 'utils/hackle';
import FloatingButton from './components/FloatingButton';
import GuideHeader from './components/GuideHeader';
import HostGuideToast from './components/HostGuideToast';
import SignupHostToast from './components/SignupHostToast';
import { useGuideHooks } from './hooks/useGuideHooks';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 768px;
  /* min-height: 100vh; */
  margin: 0 auto;
`;

const GuideContainer = styled.div`
  margin: auto;
  position: relative;
  width: 100%;

  iframe {
    border: none;

    #host_switch {
      display: none;
    }
  }
`;

const GuidePage = () => {
  const {
    isOpenHostGuideToast,
    isOpenSignupHostToast,
    signupParam,
    isMobileCertification,
    isOpenSuccessModal,
    isOpenFailModal,
    handleChangeSignupParam,
    handelCheckedMarketing,
    handleMobileCertification,
    handleIsOpenHostGuideToast,
    handleIsOpenSignupHostToast,
    handleCreateHost,
    handleCloseFailModal,
  } = useGuideHooks();

  const iframePart = () => {
    return {
      __html: `<iframe src="./guide-mobile.html" width="100%" height=${
        window.innerHeight - 146
      }px ></iframe>`,
    };
  };

  useEffect(() => {
    HackleManager.logLandingView();
  }, []);

  return (
    <Container>
      <GuideHeader handleIsOpenHostGuideToast={handleIsOpenHostGuideToast} />
      <FloatingButton
        handleIsOpenSignupHostToast={handleIsOpenSignupHostToast}
      />
      <GuideContainer dangerouslySetInnerHTML={iframePart()} />
      {isOpenHostGuideToast && (
        <HostGuideToast
          handleIsOpenHostGuideToast={handleIsOpenHostGuideToast}
        />
      )}
      {isOpenSignupHostToast && (
        <SignupHostToast
          signupParam={signupParam}
          isMobileCertification={isMobileCertification}
          isOpenSuccessModal={isOpenSuccessModal}
          isOpenFailModal={isOpenFailModal}
          handleCreateHost={handleCreateHost}
          handleChangeSignupParam={handleChangeSignupParam}
          handelCheckedMarketing={handelCheckedMarketing}
          handleMobileCertification={handleMobileCertification}
          handleIsOpenSignupHostToast={handleIsOpenSignupHostToast}
          handleCloseFailModal={handleCloseFailModal}
        />
      )}
    </Container>
  );
};

export default GuidePage;
