import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { MeData } from 'graphQL/Auth/types';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import { UPDATE_HOST_PROFILE } from '..';
import { HostBaseParam } from '../types';
import { useGetMe } from 'graphQL/Auth/user/useGetMe';
import HackleManager from 'utils/hackle';

export function useMutationUpdateHostProfile() {
  const myInfo: MeData = useUserInformation((state: any) => state.userInfo);
  const { handleGetUserInformation, handleRefetchUser } = useGetMe();

  const [updateHostProfie] = useMutation(UPDATE_HOST_PROFILE);

  const [hostBaseParams, setHostBaseParams] = useState<HostBaseParam>({
    hostId: myInfo.me.host.id,
    param: {
      name: myInfo.me.host.name,
      description: myInfo.me.host.description,
      phoneNumber: myInfo.me.host.phoneNumber,
      email: myInfo.me.user.email,
    },
  });

  const handleUpdateHostBaseParams = (key: string, value: any) => {
    setHostBaseParams({ ...hostBaseParams, [key]: value });
  };

  const handleUpdateHostProfile = async (callback: () => void) => {
    try {
      const response = await updateHostProfie({
        variables: hostBaseParams,
      });

      if (response) {
        window.showToast('프로필이 저장되었습니다.', 'success', 3000);

        callback();
        handleRefetchUser();
        // window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    hostBaseParams,
    handleUpdateHostBaseParams,
    handleUpdateHostProfile,
  };
}
