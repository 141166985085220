import { useQuery } from "@apollo/client";
import { SettlementsNode } from "containers/SettlementList/graphQL/types";
import { GET_SETTLEMENT } from "..";

export function useQueryGetSettlement(id: string) {
  const { data, loading } = useQuery<{
    settlement: { settlement: SettlementsNode };
  }>(GET_SETTLEMENT, {
    variables: { id },
    fetchPolicy: "no-cache",
  });

  return { settlementItem: data, isLoading: loading };
}
