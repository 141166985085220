import Row from 'components/common/Row';
import React from 'react';
import styled from 'styled-components';
import {
  ATTACH_MOCK,
  ATTACH_OPTION_MOCK,
  CALENDAR_MOCK,
  GuideMockData,
  OPTION_MOCK,
  SALE_MOCK,
} from '../hooks/GuideMock';

import { ProductParamInput } from '../hooks/types';
import { RegistPageNumber } from '../hooks/useProductRegistHooks';
import GuideContainer from './GuideContainer';
import ChapterFive from './OptionSelectRegist/ChapterFive';
import ChapterFour from './OptionSelectRegist/ChapterFour';
import ChapterOne from './OptionSelectRegist/ChapterOne';
import ChapterThree from './OptionSelectRegist/ChapterThree';
import ChapterTwo from './OptionSelectRegist/ChapterTwo';
import { useOptionSelectRegistHooks } from './OptionSelectRegist/hooks/useOptionSelectRegistHooks';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  width: 100%;
  max-width: 1280px;
  overflow-y: scroll;
`;

interface OptionSelectRegistIProps {
  isMobile: boolean;
  height: number;
  productParamInput: ProductParamInput;
  registPageNumber: RegistPageNumber;
  imageThumbnail: string[];
  isOpenGuideModal: boolean;
  setProductParamInput: React.Dispatch<React.SetStateAction<ProductParamInput>>;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
  handleOpenGuideModal: (isOpen: boolean) => void;
}

const OptionSelectRegist: React.FC<OptionSelectRegistIProps> = props => {
  const {
    isMobile,
    height,
    productParamInput,
    registPageNumber,
    imageThumbnail,
    isOpenGuideModal,
    setProductParamInput,
    handleOnChangeProductParamInput,
    handleOpenGuideModal,
  } = props;

  const {
    items,
    selectedItems,
    setItems,
    handleAddOptions,
    handleResetOptions,
    handleSelectedItems,
    handleChangeScheduleItems,
    handleAllSettingSelectedItems,
    handleDeleteSelectedItems,
    handleDeleteItmes,
    handleSetRepresentativeItems,
    handleChangeAllPrice,
  } = useOptionSelectRegistHooks(
    productParamInput,
    registPageNumber.optionPageNumber.page,
    handleOnChangeProductParamInput,
  );

  const renderOptionSelect = (): JSX.Element => {
    switch (registPageNumber.optionPageNumber.page) {
      case 0: {
        return <ChapterOne isMobile={isMobile} />;
      }
      case 1: {
        return (
          <ChapterTwo
            isMobile={isMobile}
            productParamInput={productParamInput}
            handleOnChangeProductParamInput={handleOnChangeProductParamInput}
            handleResetOptions={handleResetOptions}
          />
        );
      }
      case 2: {
        return (
          <ChapterThree
            isMobile={isMobile}
            productParamInput={productParamInput}
            items={items}
            selectedItems={selectedItems}
            setItems={setItems}
            handleAddOptions={handleAddOptions}
            handleSelectedItems={handleSelectedItems}
            handleChangeScheduleItems={handleChangeScheduleItems}
            handleAllSettingSelectedItems={handleAllSettingSelectedItems}
            handleDeleteSelectedItems={handleDeleteSelectedItems}
            handleDeleteItmes={handleDeleteItmes}
            handleSetRepresentativeItems={handleSetRepresentativeItems}
            handleChangeAllPrice={handleChangeAllPrice}
            handleOnChangeProductParamInput={handleOnChangeProductParamInput}
            setProductParamInput={setProductParamInput}
          />
        );
      }
      case 3: {
        return (
          <ChapterFour
            isMobile={isMobile}
            height={height}
            productParamInput={productParamInput}
            items={items}
            handleOnChangeProductParamInput={handleOnChangeProductParamInput}
          />
        );
      }
      case 4: {
        return (
          <ChapterFive
            isMobile={isMobile}
            productParamInput={productParamInput}
            handleOnChangeProductParamInput={handleOnChangeProductParamInput}
          />
        );
      }
      default:
        return <></>;
    }
  };

  const getMockData = (): GuideMockData[] => {
    switch (registPageNumber.optionPageNumber.page) {
      case 1: {
        return ATTACH_MOCK;
      }
      case 2: {
        if (productParamInput.frip.attachedToSchedule) {
          return ATTACH_OPTION_MOCK;
        }
        return OPTION_MOCK;
      }
      case 3: {
        return CALENDAR_MOCK;
      }
      case 4: {
        return SALE_MOCK;
      }
    }
  };

  return (
    <Container
      style={{
        justifyContent:
          isMobile && registPageNumber.optionPageNumber.page === 0
            ? 'flex-end'
            : isMobile || registPageNumber.optionPageNumber.page !== 0
            ? 'flex-start'
            : 'center',
        padding: isMobile
          ? registPageNumber.optionPageNumber.page === 0
            ? '64px 20px'
            : '20px 20px 64px 20px'
          : '0 40px',
        height: `${height - 174}px`,
      }}
    >
      {isMobile ? (
        <React.Fragment>
          {renderOptionSelect()}
          {isOpenGuideModal && (
            <GuideContainer
              isMobile={isMobile}
              mockData={getMockData()}
              onClose={() => {
                handleOpenGuideModal(false);
              }}
            />
          )}
        </React.Fragment>
      ) : (
        <Row
          // gap={registPageNumber.optionPageNumber.page === 3 ? '20px' : '64px'}
          style={{ width: '100%' }}
          align="flex-start"
        >
          {registPageNumber.optionPageNumber.page > 0 ? (
            <React.Fragment>
              <Row
                align="flex-start"
                justifyContent="flex-start"
                style={{
                  padding:
                    registPageNumber.optionPageNumber.page === 3
                      ? '0'
                      : '0 40px',
                  position: 'sticky',
                  top: '89px',
                  overflowY: 'scroll',
                  width: '100%',
                  height: `${height - 174}px`,
                  zIndex: 8,
                  borderLeft: '1px solid #eaebec',
                  borderRight: '1px solid #EAEBEC',
                }}
              >
                {renderOptionSelect()}
              </Row>
              <Row
                align="flex-start"
                style={{
                  padding: '0 0 60px',
                  position: 'sticky',
                  top: '89px',
                  overflowY: 'scroll',
                  minWidth: '375px',
                  maxWidth: '375px',
                  height: `${height - 174}px`,
                  borderRight: '1px solid #EAEBEC',
                }}
              >
                <GuideContainer
                  isMobile={isMobile}
                  mockData={getMockData()}
                  onClose={() => {
                    handleOpenGuideModal(false);
                  }}
                />
              </Row>
            </React.Fragment>
          ) : (
            renderOptionSelect()
          )}
        </Row>
      )}
    </Container>
  );
};

export default OptionSelectRegist;
