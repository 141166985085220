import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useResizeDetector } from 'react-resize-detector';
import styled from 'styled-components';
import { flatten, isEmpty } from 'lodash';

import { ICON_NAVIGATE_BACK, ICON_NOTICE_BLACK } from 'assets/icons';
import CommonImage from 'components/common/CommonImage';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import OrderResultContainer from './components/OrderResultContainer';
import Divider from 'components/Divider';
import Column from 'components/common/Column';
import TicketCard from './components/TicketCard';
import { useSaleDetailHooks } from './hooks/useSaleDetailHooks';
import CheckBox from 'components/common/CheckBox';
import OrderBottomSheet from './components/OrderBottomSheet';
import { BookingState, OrderState } from 'types/api';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  gap: 24px;
  padding: 0 0 124px;
  width: 100%;
  max-width: 728px;
  /* max-height: 100vh; */
  /* overflow-y: scroll; */
`;

const NotificationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 12px;
  width: 100%;
  border-radius: var(--Radius-border-radius-200, 8px);
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);
`;

interface SaleDetailPageIProps {
  id?: string;
}

const SaleDetailPage: React.FC<SaleDetailPageIProps> = props => {
  const { id } = props;
  const navigate = useNavigate();

  const {
    ordersheet,
    selectedTicket,
    cancelItems,
    isOpenSelectedBottomSheet,
    setIsOpenSelectedBottomSheet,
    handleSetSelectedTicket,
    handleAllSelectedTicket,
    handleRefetchOrderItem,
  } = useSaleDetailHooks(id);

  const { width, ref } = useResizeDetector();

  const isMobile = width ? width < 728 : window.innerWidth < 728;
  const isMultiTicket = ordersheet?.items[0].booking.items.every(
    item => item.ticketCount > 1,
  );

  const convertTotalCount = (): number => {
    let totalCount: number = 0;

    ordersheet.items.map(orderItem => {
      totalCount += orderItem.booking.counts.total;
    });

    return totalCount;
  };

  const convertConfirmedCount = (): number => {
    let totalConfirmedCount: number = 0;

    ordersheet.items.filter(bookingItem => {
      if (!isEmpty(bookingItem.dependedItems)) {
        totalConfirmedCount +=
          bookingItem.remains - bookingItem.booking.counts.confirmed;

        return;
      }

      totalConfirmedCount +=
        bookingItem.booking.counts.total - bookingItem.booking.counts.confirmed;
    });

    return totalConfirmedCount;
  };

  const isShowConfirmNotice = (): boolean => {
    const isAttachSchedule = ordersheet.items.every(
      bookingItem => !bookingItem.booking.schedule,
    );

    const isApplied = ordersheet.items.every(
      bookingItem => bookingItem.booking.counts.applied >= 1,
    );

    return isAttachSchedule && isApplied;
  };

  const isShowCheckButton = (): boolean => {
    const checkButtonVisible = flatten(
      ordersheet.items.map(orderItem =>
        orderItem.booking.items.map(bookingItem => {
          if (
            bookingItem.status !== BookingState.ABSENT &&
            bookingItem.status !== BookingState.CANCELED
          ) {
            return true;
          }

          if (bookingItem.status === BookingState.ABSENT) {
            return false;
          }

          if (bookingItem.status === BookingState.CANCELED) {
            return false;
          }

          if (
            cancelItems.request.orderId.includes(bookingItem.id) &&
            cancelItems.request.indexNo.includes(bookingItem.indexNo)
          ) {
            return false;
          }

          return true;
        }),
      ),
    );

    return checkButtonVisible.some(item => item);
    // return ordersheet.items.some(orderItem =>
    //   orderItem.booking.items.every(bookingItem => {
    //     console.log(bookingItem, '<<');
    //     if (
    //       bookingItem.status !== BookingState.ABSENT &&
    //       bookingItem.status !== BookingState.CANCELED
    //     ) {
    //       return true;
    //     }

    //     if (bookingItem.status === BookingState.ABSENT) {
    //       return false;
    //     }

    //     if (bookingItem.status === BookingState.CANCELED) {
    //       return false;
    //     }

    //     if (
    //       cancelItems.request.orderId.includes(bookingItem.id) &&
    //       cancelItems.request.indexNo.includes(bookingItem.indexNo)
    //     ) {
    //       return false;
    //     }

    //     return true;
    //   }),
    // );
  };

  const renderHeader = (): JSX.Element => {
    if (id) {
      return (
        <Row
          justifyContent="flex-start"
          align="center"
          style={{
            position: 'sticky',
            top: '0',
            padding: '24px 40px',
            width: '100%',
            borderBottom: '1px solid #eaebec',
            background: '#fff',
            zIndex: 2,
          }}
        >
          <Text
            color="#1b1c1e"
            size="24px"
            style={{ height: '40px', lineHeight: '32px' }}
          >
            상세 내역
          </Text>
        </Row>
      );
    }

    return (
      <Row
        style={{
          position: 'sticky',
          top: 0,
          padding: '24px 20px',
          width: '100%',
          background: '#fff',
          zIndex: 2,
        }}
      >
        <Text color="#1b1c1e" size="18px">
          상세 내역
        </Text>
        <Row justifyContent="space-between">
          <CommonImage
            src={ICON_NAVIGATE_BACK}
            onClick={() => {
              navigate(-1);
            }}
            style={{ position: 'absolute', left: '20px' }}
          />
        </Row>
      </Row>
    );
  };

  return (
    <Container
      ref={ref}
      style={{
        maxWidth: id ? '100%' : '728px',
      }}
    >
      {renderHeader()}
      {ordersheet && (
        <React.Fragment>
          <OrderResultContainer
            orderSheet={ordersheet}
            cancelItems={cancelItems}
          />
          <Column gap="16px" style={{ padding: id ? '0 40px' : '0 20px' }}>
            <Divider style={{ height: '6px' }} />
            {isShowConfirmNotice() && (
              <NotificationContainer>
                <img src={ICON_NOTICE_BLACK} alt="notification" />
                <Text color="#1b1c1e" size="13px">
                  {convertConfirmedCount().toLocaleString()}건의 예약 확정이
                  필요해요
                </Text>
              </NotificationContainer>
            )}
            <Row justifyContent="space-between" style={{ width: '100%' }}>
              <Text color="#1B1C1E" size="16px">
                전체 {convertTotalCount().toLocaleString()}건
              </Text>
              {isShowCheckButton() && (
                <Row
                  gap="4px"
                  onClick={() => {
                    if (isMultiTicket) {
                      return;
                    }

                    handleAllSelectedTicket();
                  }}
                >
                  <CheckBox
                    isChecked={
                      ordersheet.status !== OrderState.CANCELED &&
                      selectedTicket.length ===
                        flatten(
                          ordersheet.items.map(orderItem =>
                            orderItem.booking.items.filter(item => {
                              if (
                                cancelItems.request.orderId.includes(
                                  item.booking.id,
                                )
                              ) {
                                if (
                                  cancelItems.request.indexNo.includes(
                                    item.indexNo,
                                  ) ||
                                  item.status === BookingState.CANCELED ||
                                  item.status === BookingState.ABSENT
                                ) {
                                  return;
                                }

                                return item;
                              } else {
                                return item;
                              }
                            }),
                          ),
                        ).length
                    }
                    onChange={() => {
                      if (isMultiTicket) {
                        return;
                      }

                      handleAllSelectedTicket();
                    }}
                    style={{ width: '20px', height: '20px' }}
                  />
                  <Text color={isMultiTicket ? '#DBDCDF' : '#989ba2'}>
                    전체 선택
                  </Text>
                </Row>
              )}
            </Row>
            {ordersheet.items.map((orderItem, orderIdx) => {
              return (
                <React.Fragment key={orderIdx}>
                  {orderItem.booking.items.map((bookingItem, bookingIdx) => {
                    return (
                      <TicketCard
                        isMobile={isMobile}
                        item={bookingItem}
                        cancelItems={cancelItems}
                        selectedTicket={selectedTicket}
                        transactionId={orderItem.transaction.id}
                        orderItems={orderItem}
                        handleSetSelectedTicket={handleSetSelectedTicket}
                        handleRefetchOrderItem={handleRefetchOrderItem}
                        key={bookingIdx}
                      />
                    );
                  })}
                  {isOpenSelectedBottomSheet && (
                    <OrderBottomSheet
                      isMobile={id ? false : isMobile}
                      width={width}
                      selectedTicket={selectedTicket}
                      cancelItems={cancelItems}
                      orderItem={orderItem}
                      handleRefetchOrderItem={handleRefetchOrderItem}
                      onClose={() => setIsOpenSelectedBottomSheet(false)}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </Column>
        </React.Fragment>
      )}
    </Container>
  );
};

export default SaleDetailPage;
