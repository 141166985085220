import { gql } from '@apollo/client';

export const GET_SETTLEMENT = gql`
  query GetSettlement($id: ID!) {
    settlement {
      settlement(id: $id) {
        id
        periodCode
        title
        totalPayment {
          value
          currencyCode
        }
        totalFee {
          value
          currencyCode
        }
        host {
          id
          name
        }
        startedDate
        endedDate
        invoice {
          id
          title
          status
          createdAt
          completedAt
        }
        createdAt
      }
    }
  }
`;

export const GET_ACCOUNT_LIST_BY_FILTER = gql`
  query GetAccountListByFilter(
    $filter: SettlementAccountFilterInput
    $page: Int
    $size: Int
  ) {
    settlement {
      accounts(filter: $filter, page: $page, size: $size) {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            id
            title
            payment {
              value
              currencyCode
            }
            host {
              id
              name
            }
            commissionFee {
              value
              currencyCode
            }
            referredType
            settled
            settlement {
              id

              totalFee {
                currencyCode
                value
              }
            }
            type
            order {
              id
              customer {
                nickname
                id
              }
              transactions {
                firmedAt
              }
            }
            orderItem {
              id
              transaction {
                firmedAt
                order {
                  customer {
                    nickname
                    id
                  }
                  transactions {
                    firmedAt
                  }
                }
              }
            }
            product {
              listingProduct {
                title
              }
              options {
                names
              }
              headerContents {
                content {
                  thumbnail
                }
              }
            }
            canceled
            createdAt
          }
        }
      }
    }
  }
`;
