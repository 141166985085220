import { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { ItemInquiryInputKind, ItemInquiryInputPattern } from 'types/api';
import { useMutationInquiryTemplate } from '../graphQL/hooks/useMutationInquiryTemplate';
import { Inquiries, InquiryTemplateParamInput } from '../graphQL/types';

export const useInquiriesHooks = () => {
  const { handleCreateInquiryTemplate, handleUpdateInquiryTemplate } =
    useMutationInquiryTemplate();

  const [inquiryTemplate, setInquiryTemplate] =
    useState<InquiryTemplateParamInput>({
      name: '',
      activated: true,
      inquiries: [],
    });
  const [inputPattern, setInputPattern] = useState<ItemInquiryInputPattern>(
    ItemInquiryInputPattern.PER_ITEM_COUNT,
  );
  const [inquiries, setInquiries] = useState<Inquiries>({
    title: '',
    inputKind: ItemInquiryInputKind.TEXT,
    inputPattern,
    required: false,
    activated: true,
    items: [],
  });

  const handleSetInquiries = (key: string, value: any) => {
    setInquiries({ ...inquiries, [key]: value });
  };

  const handleSetItemInputPattern = (pattern: ItemInquiryInputPattern) => {
    const copyInquiryTemplate = cloneDeep(inquiryTemplate);

    copyInquiryTemplate.inquiries.forEach(item => {
      item.inputPattern = pattern;
    });

    setInquiryTemplate(copyInquiryTemplate);
    setInputPattern(pattern);
    handleSetInquiries('inputPattern', pattern);
  };

  const handleSetInquiryTemplate = (key: string, value: any) => {
    setInquiryTemplate({ ...inquiryTemplate, [key]: value });
  };

  const handleDeleteInquiryTemplate = (filterIdx: number, id?: string) => {
    const filterItem = inquiryTemplate.inquiries.filter((item, idx) =>
      id ? item.id !== id : idx !== filterIdx,
    );

    setInquiryTemplate({ ...inquiryTemplate, inquiries: filterItem });
  };

  const handleModifyInquiryTemplate = (id: string) => {
    const copyInquiryTemplate = cloneDeep(inquiryTemplate);
    const modifyIndex = inquiryTemplate.inquiries.findIndex(
      item => item.id === id,
    );

    copyInquiryTemplate.inquiries[modifyIndex] = inquiries;

    setInquiryTemplate(copyInquiryTemplate);
  };

  const handleResetInquiries = () => {
    setInquiries({
      title: '',
      inputKind: ItemInquiryInputKind.TEXT,
      inputPattern,
      required: false,
      activated: true,
      items: [],
    });
  };

  return {
    inquiryTemplate,
    inquiries,
    inputPattern,
    setInputPattern,
    setInquiries,
    setInquiryTemplate,
    handleSetInquiries,
    handleSetInquiryTemplate,
    handleResetInquiries,
    handleModifyInquiryTemplate,
    handleCreateInquiryTemplate,
    handleDeleteInquiryTemplate,
    handleUpdateInquiryTemplate,
    handleSetItemInputPattern,
  };
};
