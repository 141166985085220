import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { useResizeDetector } from 'react-resize-detector';

import FilterContainer from './components/FilterContainer';
import NoticeCard from './components/NoticeCard';
import { useNoticeHooks } from './hooks/useNoticeHooks';
import {
  ICON_CATEGORY_SEARCH,
  ICON_CLOSE,
  ICON_NAVIGATE_BACK,
  ICON_PRODUCT_FILTER,
} from 'assets/icons';
import BasicButton from 'components/Button/BasicButton';
import Column from 'components/common/Column';
import CommonImage from 'components/common/CommonImage';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import TotalCountContainer from 'containers/QnaList/components/TotalCountContainer';
import PaginataionContainer from 'containers/QnaList/components/PaginataionContainer';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import SearchForm from 'components/common/SearchForm';
import Divider from 'components/Divider';
import MenuBackwardButton from 'components/Button/MenuBackwardButton';
import HackleManager from 'utils/hackle';

const Container = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  padding: ${props => (props.$isMobile ? '0 0 90px' : '40px 0 ')};
  width: 100%;
  max-width: 768px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
  position: sticky;
  top: 0;
  padding: 24px 20px;
  width: 100%;
  border-bottom: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);
  z-index: 5;
`;

const NoticePage = () => {
  const {
    noticeBoard,
    noticeFilter,
    isOpenCountFilter,
    selectedIndex,
    isOpenDetailModal,
    setIsOpenDetailModal,
    handleOpenDetailModal,
    handleSetFilterItem,
    handleIsOpenCountFilter,
  } = useNoticeHooks();

  const navigate = useNavigate();

  const { width, ref } = useResizeDetector();

  const isMobile = width ? width < 768 : window.innerWidth < 768;

  const [isOpenSearchModal, setIsOpenSearchModal] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>(
    noticeFilter.filter.titleLike,
  );

  useEffect(() => {
    HackleManager.logNoticeview();
  }, []);

  const renderSearchModalContainer = (): JSX.Element => {
    return (
      <ModalContainer
        isMobile={isMobile}
        isAbled={searchValue !== ''}
        onAcceptText="적용"
        onCloseText="초기화"
        onClick={() => {
          handleSetFilterItem('filter', {
            ...noticeFilter.filter,
            titleLike: searchValue,
          });
          setIsOpenSearchModal(false);
        }}
        onClose={() => {
          setSearchValue('');
        }}
        style={{ gap: 0 }}
      >
        <Row style={{ padding: '16px 0' }}>
          <CommonImage
            src={ICON_CLOSE}
            style={{ position: 'absolute', left: '20px' }}
            onClick={() => setIsOpenSearchModal(false)}
          />
          <Text color="#000" size="16px" weight={600}>
            검색
          </Text>
        </Row>
        <Column
          align="flex-start"
          gap="12px"
          style={{ padding: '12px 20px 32px' }}
        >
          <Text color="#1b1c1e" size="16px">
            검색어
          </Text>
          <SearchForm
            placeholder="공지사항 제목을 입력해 주세요."
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
          />
        </Column>
      </ModalContainer>
    );
  };

  const renderHeader = (): JSX.Element => {
    if (isMobile) {
      return (
        <HeaderContainer>
          <Row style={{ position: 'relative', width: '100%' }}>
            <Text color="#1b1c1e" size="18px">
              공지사항
            </Text>
            <Row
              justifyContent="space-between"
              style={{ position: 'absolute', width: '100%' }}
            >
              <CommonImage
                src={ICON_NAVIGATE_BACK}
                onClick={() => {
                  navigate(-1);
                }}
              />
              <BasicButton
                content={
                  <Row gap="4px">
                    <CommonImage src={ICON_PRODUCT_FILTER} onClick={() => {}} />
                    <Text color="#1b1c1e">필터</Text>
                  </Row>
                }
                onClick={() => {
                  setIsOpenSearchModal(true);
                }}
              />
            </Row>
          </Row>
          <FilterContainer
            noticeFilter={noticeFilter}
            handleSetFilterItem={handleSetFilterItem}
          />
        </HeaderContainer>
      );
    }

    return (
      <Column gap="32px" align="flex-start">
        <Row justifyContent="space-between" style={{ width: '100%' }}>
          <Row gap="18px">
            <MenuBackwardButton
              onClick={() => {
                navigate(-1);
              }}
            />
            <Text color="#1b1c1e" size="24px">
              공지사항
            </Text>
          </Row>
          <BasicButton
            content={
              <Row gap="4px">
                <CommonImage src={ICON_PRODUCT_FILTER} onClick={() => {}} />
                <Text color="#1b1c1e">필터</Text>
              </Row>
            }
            onClick={() => {
              setIsOpenSearchModal(true);
            }}
          />
        </Row>
        <FilterContainer
          noticeFilter={noticeFilter}
          handleSetFilterItem={handleSetFilterItem}
        />
      </Column>
    );
  };

  return (
    <Container $isMobile={isMobile} ref={ref}>
      {renderHeader()}
      {noticeBoard && (
        <React.Fragment>
          <Column style={{ padding: isMobile && '0 20px' }}>
            <TotalCountContainer
              totalCount={noticeBoard.board.notices.totalCount}
              isOpenCountFilter={isOpenCountFilter}
              filterItem={noticeFilter}
              handleSetFilterItem={handleSetFilterItem}
              handleIsOpenCountFilter={handleIsOpenCountFilter}
            />
          </Column>
          <Column gap="16px" style={{ padding: isMobile && '0 20px' }}>
            {noticeBoard.board.notices.edges.map((item, idx) => {
              return (
                <React.Fragment key={idx}>
                  <NoticeCard item={item} type="list" />
                  {noticeBoard.board.notices.edges.length !== idx + 1 && (
                    <Divider />
                  )}
                </React.Fragment>
              );
            })}
          </Column>
          <PaginataionContainer
            totalCount={noticeBoard.board.notices.totalCount}
            page={noticeFilter.page}
            size={noticeFilter.size}
            handleSetQnaFilter={handleSetFilterItem}
          />
        </React.Fragment>
      )}
      {isOpenSearchModal && renderSearchModalContainer()}
    </Container>
  );
};

export default NoticePage;
