import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useResizeDetector } from 'react-resize-detector';
import { styled } from 'styled-components';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import NoticeCard from 'containers/Notice/components/NoticeCard';
import { useNoticeDetailHooks } from './hooks/useNoticeDetailHooks';
import MenuBackwardButton from 'components/Button/MenuBackwardButton';

const Container = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => (props.$isMobile ? ' 20px' : '40px')};
  width: 100%;
  max-width: 768px;
`;

const HtmlContainer = styled.div`
  width: 100%;

  img {
    width: 100%;
  }
`;

const NoticeDetailPage = () => {
  const { noticeBoard } = useNoticeDetailHooks();

  const navigate = useNavigate();

  const { width } = useResizeDetector();

  const isMobile = width ? width < 768 : window.innerWidth < 768;

  return (
    <Container $isMobile={isMobile}>
      {noticeBoard && (
        <React.Fragment>
          <Column
            gap="16px"
            style={{ paddingBottom: '40px', borderBottom: '1px solid #eaebec' }}
          >
            <Row gap="32px" style={{ width: '100%' }}>
              <MenuBackwardButton onClick={() => navigate(-1)} />
              {noticeBoard.board.notices.edges.map((item, idx) => {
                return <NoticeCard item={item} type="detail" key={idx} />;
              })}
            </Row>
          </Column>
          <Column align="flex-start" style={{ padding: '40px 0' }}>
            <HtmlContainer
              dangerouslySetInnerHTML={{
                __html: noticeBoard.board.notices.edges[0].node.content,
              }}
              style={{ width: '100%' }}
            />
          </Column>
        </React.Fragment>
      )}
    </Container>
  );
};

export default NoticeDetailPage;
