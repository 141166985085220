import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ModifyContainer from '../ModifyContainer';
import { ICON_CLOSE, ICON_SETTLEMENT_IMAGE_UPLOAD } from 'assets/icons';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import Column from 'components/common/Column';
import { ModifyValue } from '../../hooks/useProductDetaillHooks';

const ImageThumbnailContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  width: 100%;
`;

const EmptyImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 142px;
  border-radius: 8px;
  background: #f4f4f5;
  object-fit: contain;
`;

const ImageThumbnail = styled.img`
  width: 100%;
  height: 142px;
  border-radius: 8px;
  background: #f4f4f5;
  object-fit: cover;
`;

interface ImageContainerIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  modifyValue: ModifyValue;
  setModifyValue: React.Dispatch<React.SetStateAction<ModifyValue>>;
}

const ImageContainer: React.FC<ImageContainerIProps> = props => {
  const { isMobile, productParamInput, modifyValue, setModifyValue } = props;

  const [element, setElement] = useState<any[]>([]);

  useEffect(() => {
    const elementItem = [];

    for (let i = 1; i < 5; i++) {
      if (productParamInput.headerContents[i]) {
        elementItem.push(productParamInput.headerContents[i]);
      } else {
        elementItem.push('empty');
      }
    }

    setElement(elementItem);
  }, [productParamInput]);

  const renderImageThumbnail = (): JSX.Element => {
    // const element = [];

    // for (let i = 1; i < 5; i++) {
    //   if (productParamInput.headerContents[i]) {
    //     element.push(productParamInput.headerContents[i]);
    //   } else {
    //     element.push('empty');
    //   }
    // }

    return (
      <ImageThumbnailContainer>
        {element.map((item, idx) => {
          return (
            <React.Fragment key={idx}>
              {item === 'empty' ? (
                <EmptyImageContainer>
                  <img
                    src={ICON_SETTLEMENT_IMAGE_UPLOAD}
                    alt="icon image upload"
                  />
                </EmptyImageContainer>
              ) : (
                <ImageThumbnail
                  src={item.uri}
                  alt={`product${idx} thumbnail`}
                />
              )}
            </React.Fragment>
          );
        })}
      </ImageThumbnailContainer>
    );
  };

  return (
    <ModifyContainer
      title="이미지"
      isSelected={!isMobile && modifyValue.modifyStatus === 'image'}
      onClick={() => {
        setModifyValue({ ...modifyValue, modifyStatus: 'image' });
      }}
    >
      <Column gap="20px">
        {productParamInput.headerContents[0]?.uri ? (
          <ImageThumbnail src={productParamInput.headerContents[0].uri} />
        ) : (
          <EmptyImageContainer>
            <img src={ICON_SETTLEMENT_IMAGE_UPLOAD} alt="icon image upload" />
          </EmptyImageContainer>
        )}
        {renderImageThumbnail()}
      </Column>
    </ModifyContainer>
  );
};

export default ImageContainer;
