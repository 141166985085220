import React, { useCallback, useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { setDefaults, OutputFormat } from 'react-geocode';
import { Map, MapMarker } from 'react-kakao-maps-sdk';

import { ICON_CLOSE } from 'assets/icons';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import { useAddressModalHooks } from 'containers/ProductRegist/components/BasicInformation/hooks/useAddressModalHooks';
import InputForm from 'containers/Login/components/InputForm';
import { getEnvConstant } from 'constants/env';
import { useGetConveniences } from 'containers/ProductRegist/graphQL/hooks/useGetConveniences';
import { ConveniencesEntity } from 'containers/ProductRegist/graphQL/types';
import {
  FripLocationParam,
  ProductParamInput,
} from 'containers/ProductRegist/hooks/types';
import KakaoMapModal from 'containers/ProductRegist/components/BasicInformation/KakaoMapModal';
import AuthButton from 'components/Button/AuthButton';

const ContentContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  /* padding: 16px 20px 64px 20px; */
  width: 100%;
  max-width: 768px;
  height: ${window.innerHeight - 64}px;
  /* height: ${props =>
    props.$isMobile ? `${window.innerHeight - 64}px` : ''}; */
  overflow-y: scroll;

  background-color: #fff;
  border-radius: ${props => (props.$isMobile ? '16px 16px 0 0' : '16px')};
`;

const ForeignButton = styled.button<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  width: 100%;

  border-radius: 8px;
  border: ${props =>
    props.$isSelected ? '1.5px solid#1b1c1e' : ' 1px solid#DBDCDF'};
  background: ${props => (props.$isSelected ? '#f7f7f8' : '#fff')};

  color: #1b1c1e;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
`;

const AddressContainer = styled.div<{ $isEmpty: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: ${props => (props.$isEmpty ? '16px 12px' : '8px 12px')};
  width: 100%;

  color: #c2c4c8;

  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.6px;
  cursor: pointer;
`;

const CheckboxForm = styled.div<{ $isChecked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 14px;
  width: 100%;

  border-radius: 8px;
  border: ${props =>
    props.$isChecked ? '1.5px solid#1b1c1e' : ' 1px solid#DBDCDF'};
  background: ${props => (props.$isChecked ? '#f7f7f8' : '#fff')};

  color: ${props => (props.$isChecked ? '#1B1C1E' : '#dbdcdf')};

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
`;

const CheckBox = styled.input`
  width: 24px;
  height: 24px;
  -webkit-appearance: none; /* 기본 스타일 제거 */
  -moz-appearance: none;
  appearance: none;
  background-color: #fff; /* 기본 배경색 */
  border: 1px solid #ddd; /* 테두리 */
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: 5px;

  &:checked {
    background-color: #000;
  }

  &:checked::before {
    content: '✓';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
`;

const ParkingTextarea = styled.textarea`
  display: flex;
  justify-content: center;

  padding: 16px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;
  white-space: pre-wrap;

  color: #1b1c1e;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;

  &::placeholder {
    color: #c2c4c8;
  }

  &:focus {
    border: 1.5px solid #1b1c1e;
  }

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const ConvenienceButton = styled.div<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 12px;

  border-radius: 99px;
  border: ${props =>
    props.$isSelected ? '1.5px solid#1b1c1e' : ' 1px solid#DBDCDF'};
  background: ${props => (props.$isSelected ? '#f7f7f8' : '#fff')};

  color: #1b1c1e;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
    border-radius: 2px;
    background-color: #f4f4f5;
  }
`;

const FloatingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  padding: 16px 20px 24px 20px;
  margin-top: auto;

  width: 100%;
  background-color: #fff;
  border-radius: 0 0 16px 16px;
  border-top: 1px solid #dbdcdf;
`;

interface AddressModalIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  locationVenueItems: FripLocationParam | null;

  handleOnChangeProductParamInput: (key: string, value: any) => void;
  handleResetLocationVenueItems: () => void;
  type: 'address' | 'meeting';
  onClose: () => void;
}

const AddressModifyModal: React.FC<AddressModalIProps> = props => {
  const {
    isMobile,
    productParamInput,
    locationVenueItems,
    type,
    handleOnChangeProductParamInput,
    handleResetLocationVenueItems,
    onClose,
  } = props;

  const {
    isOpenKakaoModal,
    isMapLoad,
    addressItems,
    setAddressItems,
    setIsMapLoad,
    handleIsOpenKakaoModal,
    handleSetAddressItems,
    handleFindAddress,
    handleIsActiveButton,
    handleRegistLocation,
  } = useAddressModalHooks(
    productParamInput,
    locationVenueItems,
    type,
    handleOnChangeProductParamInput,
    handleResetLocationVenueItems,
  );

  const mapRef = useRef(null);

  const { conveniencesList } = useGetConveniences();
  const [isScrollAccept, setIsScrollAccept] = useState<boolean>(false);
  const [isRegistAddress, setIsRegistAddress] = useState<boolean>(false);

  const handleOnScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const scrollPosition = e.currentTarget.scrollTop;

    if (scrollPosition > 15) {
      setIsScrollAccept(true);
    } else {
      setIsScrollAccept(false);
    }
  };

  // const initMap = useCallback(
  //   (lat: number, lng: number) => {
  //     if (mapRef.current) {
  //       const mapLocation = new window.google.maps.LatLng(lat, lng);

  //       const mapOptions = {
  //         center: mapLocation,
  //         zoom: 18,
  //       };

  //       return new window.google.maps.Map(mapRef.current, mapOptions);
  //     }
  //   },
  //   [mapRef],
  // );

  // useEffect(() => {
  //   if (isMapLoad) {
  //     initMap(addressItems.geoPoint.latitude, addressItems.geoPoint.longitude);
  //   }
  // }, [isMapLoad]);

  // useEffect(() => {
  //   if (mapRef.current) {
  //     const mapLocation = new window.google.maps.LatLng(
  //       addressItems.geoPoint.latitude,
  //       addressItems.geoPoint.longitude,
  //     );

  //     const mapOptions = {
  //       center: mapLocation,
  //       zoom: 18,
  //     };

  //     const map = new window.google.maps.Map(mapRef.current, mapOptions);

  //     const marker = new window.google.maps.Marker({
  //       position: mapLocation,
  //       map: map,
  //       draggable: true,
  //       title: '장소',
  //     });

  //     marker.setMap(map);

  //     marker.addListener('dragend', (event: any) => {
  //       handleSetAddressItems('geoPoint', {
  //         ...addressItems.geoPoint,
  //         latitude: event.latLng.lat(),
  //         longitude: event.latLng.lng(),
  //       });
  //     });
  //   }
  // }, [addressItems.geoPoint.latitude]);

  useEffect(() => {
    if (mapRef.current) {
      const mapLocation = new window.google.maps.LatLng(
        addressItems.geoPoint.latitude,
        addressItems.geoPoint.longitude,
      );

      const mapOptions = {
        center: mapLocation,
        zoom: 18,
      };

      const map = new window.google.maps.Map(mapRef.current, mapOptions);

      const marker = new window.google.maps.Marker({
        position: mapLocation,
        map: map,
        draggable: true,
        title: '장소',
      });

      marker.setMap(map);

      marker.addListener('dragend', (event: any) => {
        handleSetAddressItems('geoPoint', {
          ...addressItems.geoPoint,
          latitude: event.latLng.lat(),
          longitude: event.latLng.lng(),
        });
      });
    }
  }, [addressItems.geoPoint.latitude, isMapLoad]);

  useEffect(() => {
    setDefaults({
      key: getEnvConstant('googleMapApiKey'),
      language: 'ko',
      region: 'ko',
      outputFormat: OutputFormat.JSON,
    });
  }, [addressItems.overseas]);

  const renderHeader = (): JSX.Element => {
    if (isScrollAccept) {
      return (
        <Row
          style={{
            position: 'sticky',
            top: 0,
            padding: '16px 0',
            background: '#fff',
            borderBottom: '1px solid #DBDCDF',
            zIndex: 8,
          }}
        >
          <img
            src={ICON_CLOSE}
            alt="icon close"
            style={{
              position: 'absolute',
              left: '20px',
              width: '24px',
            }}
            onClick={onClose}
          />
          <Text color="#1b1c1e" size="16px" weight={600}>
            {type === 'meeting' ? '모이는 장소 추가' : '진행 장소 추가'}
          </Text>
        </Row>
      );
    }

    return (
      <Column
        gap="32px"
        align="flex-start"
        style={{ padding: '16px 20px 0 20px' }}
      >
        <img
          src={ICON_CLOSE}
          alt="icon close"
          style={{
            width: '24px',
            position: isScrollAccept ? 'sticky' : 'static',
            top: 0,
          }}
          onClick={onClose}
        />
        <Text color="#1b1c1e" size="24px">
          {type === 'meeting'
            ? '모이는 장소를 추가해 주세요'
            : '진행 장소를 추가해 주세요'}
        </Text>
      </Column>
    );
  };

  const renderSelectForeign = (): JSX.Element => {
    return (
      <Column gap="12px" align="flex-start">
        <Text color="#1b1c1e" size="16px">
          국내외 장소 선택
        </Text>
        <Row gap="8px" style={{ width: '100%' }}>
          <ForeignButton
            $isSelected={!addressItems.overseas}
            onClick={() => {
              setAddressItems({
                ...addressItems,
                overseas: false,
                address: '',
              });
              setIsMapLoad(false);
            }}
          >
            국내
          </ForeignButton>
          <ForeignButton
            $isSelected={addressItems.overseas}
            onClick={() => {
              setAddressItems({
                ...addressItems,
                overseas: true,
                address: '',
              });
            }}
          >
            해외
          </ForeignButton>
        </Row>
      </Column>
    );
  };

  const renderAddressForm = (): JSX.Element => {
    if (addressItems.overseas) {
      return (
        <Column gap="12px" align="flex-start">
          <Text color="#1b1c1e" size="16px">
            주소 입력
          </Text>
          <Row
            gap="16px"
            justifyContent="space-between"
            style={{ width: '100%' }}
          >
            <InputForm
              type="text"
              styleType="large"
              label="장소명 또는 주소를 입력해주세요"
              target="address"
              isDisabled={isRegistAddress}
              inputValue={addressItems.address}
              onChange={handleSetAddressItems}
            />
            <Text
              color="#1b1c1e"
              size="16px"
              weight={400}
              style={{ minWidth: '45px', cursor: 'pointer' }}
              onClick={() => {
                if (isRegistAddress) {
                  handleSetAddressItems('address', '');
                  setIsRegistAddress(false);
                  setIsMapLoad(false);

                  return;
                }

                handleFindAddress();
                setIsRegistAddress(true);
                setIsMapLoad(true);
              }}
            >
              {isRegistAddress ? '수정' : '+ 등록'}
            </Text>
          </Row>
          {isRegistAddress &&
            mapRef &&
            isMapLoad &&
            addressItems.address !== '' && (
              <div
                className="map"
                style={{ width: '100%', height: '185px', borderRadius: '8px' }}
                ref={mapRef}
              />
            )}
        </Column>
      );
    }

    return (
      <Column gap="12px" align="flex-start">
        <Text color="#1b1c1e" size="16px">
          주소 입력
        </Text>
        <Column style={{ borderRadius: '8px', border: '1px solid #DBDCDF' }}>
          <AddressContainer
            $isEmpty={addressItems.address === ''}
            style={{ borderBottom: '1px solid #dbdcdf' }}
            onClick={() => {
              handleIsOpenKakaoModal();
            }}
          >
            <span>주소</span>
            {addressItems.address && (
              <Text
                color="#1b1c1e"
                size="16px"
                weight={400}
                style={{ lineHeight: '22px' }}
              >
                {addressItems.address}
              </Text>
            )}
          </AddressContainer>
          <InputForm
            label="상세주소(선택사항)"
            type="text"
            styleType="center"
            target="detailAddress"
            onChange={handleSetAddressItems}
          />
          <InputForm
            label="장소/건물명"
            type="text"
            styleType="bottom"
            target="label"
            onChange={handleSetAddressItems}
          />
        </Column>
        {addressItems.address && (
          <Map
            center={{
              lat: addressItems.geoPoint.latitude,
              lng: addressItems.geoPoint.longitude,
            }}
            style={{ width: '100%', height: '185px', borderRadius: '8px' }}
          >
            <MapMarker
              position={{
                lat: addressItems.geoPoint.latitude,
                lng: addressItems.geoPoint.longitude,
              }}
              draggable
              onDragEnd={(event: kakao.maps.Marker) => {
                handleSetAddressItems('geoPoint', {
                  ...addressItems.geoPoint,
                  latitude: event.getPosition().getLat(),
                  longitude: event.getPosition().getLng(),
                });
              }}
            />
          </Map>
        )}
      </Column>
    );
  };

  const renderParkingType = (): JSX.Element => {
    if (addressItems.overseas) {
      return <></>;
    }

    return (
      <Column gap="12px" align="flex-start">
        <Text color="#1b1c1e" size="16px">
          주차 가능 여부
        </Text>
        <Row gap="8px" style={{ width: '100%' }}>
          <ForeignButton
            $isSelected={!addressItems.parking.available}
            onClick={() => {
              handleSetAddressItems('parking', {
                ...addressItems.parking,
                available: false,
              });
            }}
          >
            불가능
          </ForeignButton>
          <ForeignButton
            $isSelected={addressItems.parking.available}
            onClick={() => {
              handleSetAddressItems('parking', {
                ...addressItems.parking,
                available: true,
              });
            }}
          >
            가능
          </ForeignButton>
        </Row>
        {addressItems.parking.available && (
          <ParkingTextarea
            placeholder={`주차 안내사항을 입력해 주세요. (선택사항)
ex. 건물 내 1대 주차 가능,공영주차장을 이용해 주세요.`}
            value={addressItems.parking.note}
            onChange={e => {
              handleSetAddressItems('parking', {
                ...addressItems.parking,
                note: e.target.value,
              });
            }}
          />
        )}
      </Column>
    );
  };

  const renderConveniences = (): JSX.Element => {
    const acceptConveniencesId = (id: string) => {
      if (addressItems.convenienceIds.includes(id)) {
        handleSetAddressItems(
          'convenienceIds',
          addressItems.convenienceIds.filter(item => item !== id),
        );

        return;
      }

      handleSetAddressItems('convenienceIds', [
        ...addressItems.convenienceIds,
        id,
      ]);
    };

    if (type === 'meeting') {
      return <></>;
    }

    return (
      <Column gap="12px" align="flex-start">
        <Text color="#1b1c1e" size="16px">
          기타 편의 정보 (선택사항)
        </Text>
        <Row
          gap="8px"
          justifyContent="space-between"
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(100px, auto))',
            width: '100%',
          }}
        >
          {conveniencesList?.product.conveniences.map(
            (item: ConveniencesEntity, idx: number) => {
              return (
                <ConvenienceButton
                  $isSelected={addressItems.convenienceIds.includes(item.id)}
                  key={idx}
                  onClick={() => {
                    acceptConveniencesId(item.id);
                  }}
                >
                  {item.name}
                </ConvenienceButton>
              );
            },
          )}
        </Row>
      </Column>
    );
  };

  return (
    <React.Fragment>
      {isMobile ? (
        <ContentContainer $isMobile={isMobile} onScroll={handleOnScroll}>
          {renderHeader()}
          <Column
            gap="32px"
            style={{
              padding: '16px 20px 64px 20px',
            }}
            justifyContent={type === 'meeting' ? 'flex-start' : 'center'}
          >
            {renderSelectForeign()}
            {renderAddressForm()}
            {renderParkingType()}
            {renderConveniences()}
          </Column>
          <FloatingContainer>
            <AuthButton
              name="확인"
              isAbled={handleIsActiveButton()}
              onClick={() => {
                handleRegistLocation();
                onClose();
              }}
              style={{ width: '76px' }}
            />
          </FloatingContainer>
        </ContentContainer>
      ) : (
        <ContentContainer $isMobile={isMobile} onScroll={handleOnScroll}>
          {renderHeader()}
          <Column
            gap="32px"
            align="flex-start"
            style={{
              padding: '0 20px 64px 20px',
            }}
            justifyContent={type === 'meeting' ? 'flex-start' : 'center'}
          >
            <Text color="#1b1c1e" size="16px" weight={600}>
              {type === 'meeting' ? '모이는 장소 추가' : '진행 장소 추가'}
            </Text>
            {renderSelectForeign()}
            {renderAddressForm()}
            {renderParkingType()}
            {renderConveniences()}
          </Column>
          <FloatingContainer>
            <AuthButton
              name="확인"
              isAbled={handleIsActiveButton()}
              onClick={() => {
                handleRegistLocation();
                onClose();
              }}
              style={{ width: '76px' }}
            />
          </FloatingContainer>
        </ContentContainer>
      )}
      {isOpenKakaoModal && (
        <KakaoMapModal
          isMobile={isMobile}
          addressItems={addressItems}
          setAddressItems={setAddressItems}
          onClose={handleIsOpenKakaoModal}
        />
      )}
    </React.Fragment>
  );
};

export default AddressModifyModal;
