import { useLazyQuery } from '@apollo/client';
import { errorHandler } from 'utils/ErrorHandler';
import {
  CHECK_EXISTENCEOF_INVOICE,
  CHECK_EXISTENCEOF_INVOICE_TICKET,
} from '..';

export function useQueryCheckExistenceOfInvoice() {
  const [checkExistenceOfInvoice] = useLazyQuery(CHECK_EXISTENCEOF_INVOICE);
  const [checkExistenceOfInvoiceTicket] = useLazyQuery(
    CHECK_EXISTENCEOF_INVOICE_TICKET,
  );

  const handleCheckExistenceOfInvoice = async (
    params: {
      orderItemId: string;
      indexNo: number;
    },
    callback: () => void,
  ) => {
    try {
      const response = await checkExistenceOfInvoice({
        variables: params,
      });

      if (response) {
        if (
          response.data.settlement.checkExistenceOfInvoiceByOrderItemUnitInfo
        ) {
          return;
        }

        callback();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleCheckTicketsExistenceOfInvoice = async (params: {
    orderItemId: string;
    indexNo: number;
  }) => {
    try {
      const response = await checkExistenceOfInvoice({ variables: params });

      if (response) {
        if (
          response.data.settlement.checkExistenceOfInvoiceByOrderItemUnitInfo
        ) {
          return;
        }

        return true;
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  return {
    handleCheckExistenceOfInvoice,
    handleCheckTicketsExistenceOfInvoice,
  };
}
