import ConfirmDialog from 'components/Modal/ConfirmDialog';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Container = styled.textarea`
  padding: 12px;
  width: 100%;
  color: var(--gray-gray-09, #1b1c1e);

  border-radius: 8px;
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);

  /* Body/B-16-Regular */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.6px;
  resize: none;

  &::placeholder {
    color: #c2c4c8;
  }

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  &:focus {
    border: 1.5px solid #1b1c1e;
  }
`;

interface TextareaIProps {
  target: string;
  value: string;
  placeholder: string;
  maxLength?: number;
  isFocusInput?: boolean;
  style?: React.CSSProperties;
  onChange: (key: string, value: any) => void;
  onFocus?: () => void;
}

const Textarea: React.FC<TextareaIProps> = props => {
  const {
    target,
    value,
    placeholder,
    maxLength,
    isFocusInput,
    style,
    onChange,
    onFocus,
  } = props;

  const [text, setText] = useState<string>(value);
  const [isOpenOptionDialog, setIsOpenOptionDialog] = useState<boolean>(false);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleAdjustTextareaHeight = () => {
    const textarea = textareaRef.current;

    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    handleAdjustTextareaHeight();
  }, []);

  const handleChange = (e: any) => {
    if (maxLength && text.length <= maxLength) {
      handleAdjustTextareaHeight();

      setText(e.target.value);

      onChange(target, e.target.value);

      return;
    }

    handleAdjustTextareaHeight();

    setText(e.target.value);

    onChange(target, e.target.value);
  };

  useEffect(() => {
    setText(value);
  }, [value]);

  return (
    <React.Fragment>
      <Container
        placeholder={placeholder}
        ref={textareaRef}
        value={text}
        onChange={handleChange}
        rows={1}
        maxLength={maxLength && maxLength}
        onFocus={() => {
          isFocusInput && setIsOpenOptionDialog(true);
        }}
        style={{ ...style }}
      />
      {isOpenOptionDialog && (
        <ConfirmDialog
          title="옵션 타이틀을 변경 할까요?"
          description="옵션 타이틀을 변경하면, 해당 옵션뿐만 아니라 다른 옵션의 타이틀도 함께 변경돼요."
          onCloseText="아니요"
          onClose={() => setIsOpenOptionDialog(false)}
          onAcceptText="네, 변경할게요"
          onAccept={() => {
            textareaRef.current.focus();
            setIsOpenOptionDialog(false);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default Textarea;
