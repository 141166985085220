import { gql } from '@apollo/client';

export const UPDATE_HOST_PROFILE = gql`
  mutation updateHostProfile($hostId: ID!, $param: HostBaseParam!) {
    updateHostBaseInfo(hostId: $hostId, param: $param) {
      id
      description
      name
      phoneNumber
      personalInfo {
        name
        phoneNumber
        email
      }
      super
      managerName
      user {
        id
      }
      createdAt
      updatedAt
      profileImage {
        id
        uri
      }
    }
  }
`;

export const CHANGE_HOST_PROFILEIMAGE = gql`
  mutation changeHostProfileImage($hostId: ID!, $contentId: ID!) {
    changeHostProfileImage(hostId: $hostId, contentId: $contentId) {
      id
    }
  }
`;

export const GET_CURRENT_BANNER = gql`
  query GetCurrentBanners($platform: ClientPlatform) {
    banner {
      currents(slotId: "host_admin", platform: $platform) {
        id
        actionUri
        title
        content {
          thumbnail(fetchFormat: AUTO)
        }
      }
    }
  }
`;

export const GET_HOST_DASHBOARD = gql`
  query DashboardData($hostId: ID!) {
    hostDashboard {
      hostDashboard(hostId: $hostId) {
        runningFrip
        monthlyCancel
        totalTurnover
        monthlyTurnover
        totalSaleCount
        monthlySaleCount
        answerRate
        reviewCount
        rating
      }
    }
  }
`;
