import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Column from 'components/common/Column';
import Text from 'components/common/Text';
import SelectDateCard from './components/SelectDateCard';
import Row from 'components/common/Row';
import {
  ICON_PRODUCT_SCHEDULE_CHOICE,
  ICON_PRODUCT_SCHEDULE_SELECT,
  ICON_QUESTION_RED,
} from 'assets/icons';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import HackleManager from 'utils/hackle';

const DescriptionItem = styled.li`
  list-style-position: inside;
  padding-left: 1em;
  text-indent: -1em;
  color: var(--gray-gray-07, #70737c);

  /* Label/Ll-12-Regular */
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: -0.4px;

  &::marker {
    color: #70737c;
  }
`;

interface ChapterTwoIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
  handleResetOptions: (attacedSchedule: boolean) => void;
}

const ChapterTwo: React.FC<ChapterTwoIProps> = props => {
  const {
    isMobile,
    productParamInput,
    handleOnChangeProductParamInput,
    handleResetOptions,
  } = props;

  const params = useParams<{ id: string }>();

  useEffect(() => {
    HackleManager.logCreateStepTwoReservationMethodView(
      params.id !== 'draft' ? Number(params.id) : undefined,
    );
  }, []);

  const renderContents = (): JSX.Element => {
    return (
      <Column
        gap="32px"
        align="flex-start"
        style={{ padding: isMobile ? '0' : '32px 0' }}
      >
        <Text color="#1b1c1e" size="24px">
          예약은 어떻게 받을까요?
        </Text>
        <Column gap="12px" align="flex-start">
          <SelectDateCard
            thumbnail={ICON_PRODUCT_SCHEDULE_SELECT}
            title="일정 지정형"
            description={
              <Column align="flex-start">
                <Text color="#70737c" size="13px" weight={400}>
                  크루가 참여 가능한 날짜의 프립을 선택하여 구매합니다.
                </Text>
              </Column>
            }
            isSelected={productParamInput.frip!.attachedToSchedule}
            onClick={() => {
              handleOnChangeProductParamInput('frip', {
                ...productParamInput.frip,
                attachedToSchedule: true,
                bookingConfirmationEnabled: false,
              });
              handleResetOptions(true);
            }}
          />
          <SelectDateCard
            thumbnail={ICON_PRODUCT_SCHEDULE_CHOICE}
            title="일정 조율형"
            description={
              <Column align="flex-start">
                <Text color="#70737c" size="13px" weight={400}>
                  구매자와 직접 연락해서 진행 날짜 정할게요
                </Text>
                {/* <DescriptionItem>
                  크루가 이용권을 먼저 구매합니다.
                </DescriptionItem>{' '}
                <DescriptionItem>
                  구매 후,{' '}
                  <span style={{ color: '#7126ff' }}>
                    크루와 호스트가 직접 연락하여 진행 날짜를 정합니다.
                  </span>
                </DescriptionItem>
                <DescriptionItem>
                  지정한 날에 프립을 진행합니다.
                </DescriptionItem> */}
              </Column>
            }
            isSelected={
              productParamInput.frip!.attachedToSchedule !== null &&
              !productParamInput.frip!.attachedToSchedule
            }
            onClick={() => {
              handleOnChangeProductParamInput('frip', {
                ...productParamInput.frip,
                schedules: [],
                attachedToSchedule: false,
                bookingConfirmationEnabled: true,
              });
              handleResetOptions(false);
            }}
          />

          <Row gap="4px">
            <img src={ICON_QUESTION_RED} alt="icon question red" />
            <Text color="#FF4242" size="12px" weight={400}>
              ‘검수요청’을 한 시점부터는 변경이 불가능하니 신중히 선택해 주세요.
            </Text>
          </Row>
        </Column>
      </Column>
    );
  };

  return renderContents();
};

export default ChapterTwo;
