import React, { SetStateAction, Dispatch, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import IntroduceModifyForm from './ModifyForm/IntroduceModifyForm';
import ProductTitleModifyForm from './ModifyForm/ProductTitleModifyForm';
import ScheduleModifyForm from './ModifyForm/ScheduleModifyForm';
import PrecautionModifyForm from './ModifyForm/PrecautionModifyForm';
import OptionSelectModifyForm from './ModifyForm/OptionSelectModifyForm';
import SaleTermModifyForm from './ModifyForm/SaleTermModifyForm';
import OnceLimitModifyForm from './ModifyForm/OnceLimitModifyForm';
import QuestionModifyForm from './ModifyForm/QuestionModifyForm';
import { InquiryTemplate, Product } from 'types/api';
import { ModifyValue } from '../hooks/useProductDetaillHooks';
import ImageModifyContainer from './ModifyForm/ImageModifyContainer';
import MoreInformationModifyForm from './ModifyForm/MoreInformationModifyForm';
import ProfileModifyForm from './ModifyForm/ProfileModifyForm';
import AdditionalModifyForm from './ModifyForm/AdditionalModifyForm';
import OnlineModifyForm from './ModifyForm/OnlineModfyForm';
import AddressModifyForm from './ModifyForm/AddressModifyForm';
import CalendarModifyContainer from './ModifyForm/CalendarModifyContainer';

interface ModifyContentsContainerIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  productInfo: Product;
  inquiryList: InquiryTemplate;
  modifyValue: ModifyValue;
  menuType: string;
  height: number;
  setModifyValue: Dispatch<SetStateAction<ModifyValue>>;
  setProductParamInput: Dispatch<SetStateAction<ProductParamInput>>;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
  handleRefetchInquiryTemplate: (id: string) => void;
  handleGetProductScheduleTerm: () => void;
  handleRefetchProduct: (productId: string) => void;
}

const ModifyContentsContainer: React.FC<
  ModifyContentsContainerIProps
> = props => {
  const {
    menuType,
    isMobile,
    productParamInput,
    productInfo,
    inquiryList,
    modifyValue,
    height,
    setModifyValue,
    setProductParamInput,
    handleOnChangeProductParamInput,
    handleRefetchInquiryTemplate,
    handleGetProductScheduleTerm,
    handleRefetchProduct,
  } = props;

  const renderProductImageForm = (): JSX.Element => {
    return (
      <ImageModifyContainer
        isMobile={isMobile}
        productParamInput={productParamInput}
        productInfo={productInfo}
        modifyValue={modifyValue}
        setModifyValue={setModifyValue}
        handleOnChangeProductParamInput={handleOnChangeProductParamInput}
      />
    );
  };

  const renderProductTitleForm = (): JSX.Element => {
    return (
      <ProductTitleModifyForm
        isMobile={isMobile}
        title={productParamInput.title}
        productInfo={productInfo}
        modifyValue={modifyValue}
        setModifyValue={setModifyValue}
        handleOnChangeProductParamInput={handleOnChangeProductParamInput}
      />
    );
  };

  const renderMoreInformationForm = (): JSX.Element => {
    return (
      <MoreInformationModifyForm
        isMobile={isMobile}
        productParamInput={productParamInput}
        productInfo={productInfo}
        modifyValue={modifyValue}
        setModifyValue={setModifyValue}
        handleOnChangeProductParamInput={handleOnChangeProductParamInput}
      />
    );
  };

  const renderIntroduceForm = (): JSX.Element => {
    return (
      <IntroduceModifyForm
        isMobile={isMobile}
        editorValue={productParamInput.htmlDetailContent}
        productInfo={productInfo}
        modifyValue={modifyValue}
        height={height}
        setModifyValue={setModifyValue}
        handleOnChangeProductParamInput={handleOnChangeProductParamInput}
      />
    );
  };

  const renderScheduleForm = (): JSX.Element => {
    return (
      <ScheduleModifyForm
        isMobile={isMobile}
        productParamInput={productParamInput}
        productInfo={productInfo}
        modifyValue={modifyValue}
        setModifyValue={setModifyValue}
        handleOnChangeProductParamInput={handleOnChangeProductParamInput}
      />
    );
  };

  const renderQuestionForm = (): JSX.Element => {
    return (
      <QuestionModifyForm
        isMobile={isMobile}
        inquiryList={inquiryList}
        productParamInput={productParamInput}
        productInfo={productInfo}
        modifyValue={modifyValue}
        setModifyValue={setModifyValue}
        handleOnChangeProductParamInput={handleOnChangeProductParamInput}
        handleRefetchInquiryTemplate={handleRefetchInquiryTemplate}
      />
    );
  };

  const renderOnlineForm = (): JSX.Element => {
    return (
      <OnlineModifyForm
        isMobile={isMobile}
        productParamInput={productParamInput}
        productInfo={productInfo}
        modifyValue={modifyValue}
        setModifyValue={setModifyValue}
        handleOnChangeProductParamInput={handleOnChangeProductParamInput}
      />
    );
  };

  const renderAddressForm = (): JSX.Element => {
    return (
      <AddressModifyForm
        isMobile={isMobile}
        productParamInput={productParamInput}
        productInfo={productInfo}
        modifyValue={modifyValue}
        setModifyValue={setModifyValue}
        handleOnChangeProductParamInput={handleOnChangeProductParamInput}
      />
    );
  };

  const renderAdditionalForm = (): JSX.Element => {
    return (
      <AdditionalModifyForm
        isMobile={isMobile}
        productParamInput={productParamInput}
        productInfo={productInfo}
        modifyValue={modifyValue}
        setModifyValue={setModifyValue}
        setProductParamInput={setProductParamInput}
      />
    );
  };

  const renderProfileForm = (): JSX.Element => {
    return (
      <ProfileModifyForm
        isMobile={isMobile}
        modifyValue={modifyValue}
        productInfo={productInfo}
        setModifyValue={setModifyValue}
      />
    );
  };

  const renderPrecautionForm = (): JSX.Element => {
    return (
      <PrecautionModifyForm
        isMobile={isMobile}
        notice={productParamInput.notice}
        productInfo={productInfo}
        modifyValue={modifyValue}
        setModifyValue={setModifyValue}
        handleOnChangeProductParamInput={handleOnChangeProductParamInput}
      />
    );
  };

  const renderOptionForm = (): JSX.Element => {
    return (
      <OptionSelectModifyForm
        isMobile={isMobile}
        productParamInput={productParamInput}
        productInfo={productInfo}
        modifyValue={modifyValue}
        setModifyValue={setModifyValue}
        handleOnChangeProductParamInput={handleOnChangeProductParamInput}
        handleRefetchProduct={handleRefetchProduct}
      />
    );
  };

  const renderSaleForm = (): JSX.Element => {
    return (
      <SaleTermModifyForm
        isMobile={isMobile}
        productParamInput={productParamInput}
        productInfo={productInfo}
        modifyValue={modifyValue}
        setModifyValue={setModifyValue}
        handleOnChangeProductParamInput={handleOnChangeProductParamInput}
      />
    );
  };

  const renderOnceLimitForm = (): JSX.Element => {
    return (
      <OnceLimitModifyForm
        isMobile={isMobile}
        grossPurchaseLimitation={productParamInput.grossPurchaseLimitation}
        productInfo={productInfo}
        modifyValue={modifyValue}
        setModifyValue={setModifyValue}
        handleOnChangeProductParamInput={handleOnChangeProductParamInput}
      />
    );
  };

  const renderCalendarForm = (): JSX.Element => {
    return (
      <CalendarModifyContainer
        isMobile={isMobile}
        productParamInput={productParamInput}
        productInfo={productInfo}
        modifyValue={modifyValue}
        setModifyValue={setModifyValue}
        handleOnChangeProductParamInput={handleOnChangeProductParamInput}
        handleGetProductScheduleTerm={handleGetProductScheduleTerm}
      />
    );
  };

  const renderModifyContainer = (): JSX.Element => {
    switch (modifyValue.modifyStatus) {
      case 'image': {
        return renderProductImageForm();
      }
      case 'title': {
        return renderProductTitleForm();
      }
      case 'moreInformation': {
        return renderMoreInformationForm();
      }
      case 'introduce': {
        return renderIntroduceForm();
      }
      case 'online': {
        return renderOnlineForm();
      }
      case 'address': {
        return renderAddressForm();
      }
      case 'meeting': {
        return renderAddressForm();
      }
      case 'schedule': {
        return renderScheduleForm();
      }
      case 'question': {
        return renderQuestionForm();
      }
      case 'additional': {
        return renderAdditionalForm();
      }
      case 'profile': {
        return renderProfileForm();
      }
      case 'precaution': {
        return renderPrecautionForm();
      }
    }

    return;
  };

  const renderOptionModifyContainer = (): JSX.Element => {
    switch (modifyValue.modifyStatus) {
      case 'option': {
        return renderOptionForm();
      }
      case 'saleTerm': {
        return renderSaleForm();
      }
      case 'onceLimit': {
        return renderOnceLimitForm();
      }
      case 'calendar': {
        return renderCalendarForm();
      }
    }

    return;
  };

  return (
    <React.Fragment>
      {menuType === 'detail'
        ? renderModifyContainer()
        : renderOptionModifyContainer()}
    </React.Fragment>
  );
};

export default ModifyContentsContainer;
