import { useState } from "react";
import { useQueryGetCurrentBanner } from "../graphQL/hooks/useQueryGetCurrentBanner";
import { useQueryGetHostDashBoard } from "../graphQL/hooks/useQueryGetHostDashBoard";

export const useMenuHooks = () => {
  const { currentBanners } = useQueryGetCurrentBanner();
  const { hostDashBoard } = useQueryGetHostDashBoard();

  const [isOpenIndividualModal, setIsOpenIndividualModal] =
    useState<boolean>(false);
  const [isOpenProfileUpdateModal, setIsOpenProfileUpdateModal] =
    useState<boolean>(false);
  const [isOpenHostApplicationModal, setIsOpenHostApplicationModal] =
    useState<boolean>(false);

  return {
    currentBanners,
    isOpenIndividualModal,
    isOpenProfileUpdateModal,
    isOpenHostApplicationModal,
    hostDashBoard,
    setIsOpenIndividualModal,
    setIsOpenProfileUpdateModal,
    setIsOpenHostApplicationModal,
  };
};
