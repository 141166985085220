import { useQuery } from "@apollo/client";
import { SettlementAccount } from "containers/SettlementList/graphQL/types";
import { GET_ACCOUNT_LIST_BY_FILTER } from "..";

export function useQueryGetAccountListByFilter(id: string) {
  const { data } = useQuery<SettlementAccount>(GET_ACCOUNT_LIST_BY_FILTER, {
    variables: {
      page: 1,
      size: 50,
      filter: {
        settlementIds: [id],
      },
    },
    fetchPolicy: "no-cache",
  });

  return { accountList: data };
}
