import React from 'react';
import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  width: 100%;

  border-radius: 12px;
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--gray-gray-01, #f7f7f8);
  cursor: pointer;
`;

interface OptionContainerIProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const OptionContainer: React.FC<OptionContainerIProps> = ({
  children,
  style,
}) => {
  return <Container style={{ ...style }}>{children}</Container>;
};

export default OptionContainer;
