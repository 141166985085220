import { gql } from '@apollo/client';

export const GET_ORDER = gql`
  query GetOrder($id: ID!) {
    order {
      order(id: $id) {
        id
        status
        totalAmount {
          value
        }
        transactions {
          id
          type
          firm
          firmedAt
          status
          items {
            origin {
              booking {
                id
              }
            }
            booking {
              id
            }
          }
          order {
            id
          }
        }
        customer {
          id
          nickname
          mobileNumber
          gender
        }
        items {
          id
          count
          amount {
            value
          }
          dependedItems {
            id
            transaction {
              id
              type
              firm
              firmedAt
              status
            }
            canceled
            count
            units {
              id
            }
          }
          count
          remains
          units(includeCancel: true) {
            id
            indexNo
            replies {
              id
              indexNo
              title
              answer
              selections {
                value
                label
              }
            }
            status
          }
          info {
            id
            name
            ticketCount
            saleTerm {
              startedAt
              endedAt
            }
            options {
              id
              name
            }
            product {
              id
              title
            }
            host {
              id
              name
            }
            origin {
              product {
                frip {
                  attachedToSchedule
                }
              }
              type
            }
          }
          transaction {
            id
            type
            firm
            firmedAt
            status
            order {
              id
            }
          }
          booking {
            id
            status
            approvedAt
            schedule {
              saleTerm {
                endedAt
              }
            }
            counts {
              absent
              applied
              confirmed
              total
              used
            }
            tickets {
              id
              confirmedAt
            }
            order {
              id
              status
              customer {
                id
                nickname
                mobileNumber
                gender
              }
              transactions {
                id
                type
                firm
                firmedAt
                status
                items {
                  origin {
                    booking {
                      id
                    }
                  }
                  booking {
                    id
                  }
                }
                order {
                  id
                }
              }
              totalAmount {
                value
              }
            }
            items {
              id
              status
              ticketCount
              indexNo
              replies {
                id
                title
                indexNo
                answer
                selections {
                  label
                  value
                }
              }
              booking {
                id
                status
                order {
                  id
                  status
                  customer {
                    id
                  }
                  transactions {
                    id
                    type
                    firm
                    firmedAt
                    status
                    items {
                      origin {
                        booking {
                          id
                        }
                      }
                      booking {
                        id
                      }
                    }
                    order {
                      id
                    }
                  }
                  totalAmount {
                    value
                  }
                }
                info {
                  id
                  name
                  ticketCount
                  options {
                    id
                    name
                  }
                  product {
                    id
                    title
                  }
                  host {
                    id
                    name
                  }
                  origin {
                    type
                    name
                    options {
                      name
                    }
                    product {
                      title
                      frip {
                        attachedToSchedule
                      }
                      inquiryTemplate {
                        inquiries {
                          title
                          items {
                            label
                          }
                        }
                        name
                      }
                    }
                    price {
                      sale
                    }
                    saleTerm {
                      startedAt
                      endedAt
                    }
                  }
                  price {
                    sale
                  }
                }
                schedule {
                  id
                  saleTerm {
                    startedAt
                    endedAt
                  }
                  term {
                    startedAt
                    endedAt
                    duration
                  }
                }
              }
              tickets {
                id
                availableActions
                scheduleTerm {
                  startedAt
                  endedAt
                  duration
                }
                confirmedAt
                usedAt
                status
                sequence
              }
            }
            tickets {
              confirmedAt
              usedAt
              canceledAt
              scheduleTerm {
                startedAt
              }
            }
            counts {
              total
              applied
              confirmed
              used
              absent
            }
          }
        }
      }
    }
  }
`;

export const GET_BOOKING = gql`
  query GetBooking($id: ID!) {
    booking {
      booking(id: $id) {
        id
        status
        bookingConfirmationEnabled
        firmedAt
        order {
          id
          transactions {
            id
            firmedAt
          }
          totalAmount {
            value
          }
        }
        customer {
          id
          nickname
          mobileNumber
          gender
        }
        info {
          id
          name
          options {
            id
            name
          }
          product {
            id
            title
          }
          host {
            id
            name
          }
          origin {
            type
            name
            options {
              name
            }
            price {
              sale
            }
          }
          price {
            sale
          }
        }
        expiredAt
        schedule {
          id
          saleTerm {
            startedAt
            endedAt
          }
          term {
            startedAt
            endedAt
            duration
          }
        }
        purchaseCount
        items {
          id
          status
          ticketCount
          indexNo
          replies {
            id
            title
            indexNo
            answer
            selections {
              label
              value
            }
          }
          booking {
            order {
              id
              transactions {
                id
                firmedAt
              }
              totalAmount {
                value
              }
            }
            info {
              id
              name
              options {
                id
                name
              }
              product {
                id
                title
              }
              host {
                id
                name
              }
              origin {
                type
                name
                options {
                  name
                }
                price {
                  sale
                }
                saleTerm {
                  startedAt
                  endedAt
                }
              }
              price {
                sale
              }
            }
          }
          tickets {
            id
            availableActions
            scheduleTerm {
              startedAt
              endedAt
              duration
            }
            confirmedAt
            usedAt
            status
            sequence
          }
        }
        counts {
          total
          applied
          confirmed
          used
          absent
        }
      }
    }
  }
`;

export const CONFIRM_TICKET = gql`
  mutation ConfirmTicket($ticketId: ID!, $term: ScheduleTermInput) {
    confirmTicket(ticketId: $ticketId, term: $term) {
      id
      status
      availableActions
      scheduleTerm {
        startedAt
        endedAt
        duration
      }
      confirmedAt
      usedAt
      bookingItem {
        id
        status
        booking {
          id
          status
        }
      }
    }
  }
`;

export const RESTORE_TICKET = gql`
  mutation RestoreTicketConfirmation($ticketId: ID!, $reason: String!) {
    restoreTicketConfirmation(ticketId: $ticketId, reason: $reason) {
      id
      status
      availableActions
      scheduleTerm {
        startedAt
        endedAt
        duration
      }
      confirmedAt
      usedAt
      bookingItem {
        id
        status
        booking {
          id
          status
        }
      }
    }
  }
`;

export const USE_TICKET = gql`
  mutation UseTicket($ticketId: ID!) {
    useTicket(ticketId: $ticketId) {
      id
      status
      availableActions
      scheduleTerm {
        startedAt
        endedAt
        duration
      }
      confirmedAt
      usedAt
      bookingItem {
        id
        status
        booking {
          id
          status
        }
      }
    }
  }
`;

export const RESOTRE_USE_TICKET = gql`
  mutation RestoreTicketUsing($ticketId: ID!, $reason: String!) {
    restoreTicketUsing(ticketId: $ticketId, reason: $reason) {
      id
      status
      availableActions
      scheduleTerm {
        startedAt
        endedAt
        duration
      }
      confirmedAt
      usedAt
      bookingItem {
        id
        status
        booking {
          id
          status
        }
      }
    }
  }
`;

export const ABSENT_TICKET = gql`
  mutation AbsentTicket($ticketId: ID!) {
    absentTicket(ticketId: $ticketId) {
      id
      status
    }
  }
`;

export const APPLY_TICKETS = gql`
  mutation ApplyTickets($params: [TicketActionParamInput!]!) {
    applyTickets(params: $params) {
      id
      status
      bookingItem {
        booking {
          approvedAt
          status
          customer {
            id
            email
            nickname
            description
            profileImage {
              id
              uri
              thumbnail
            }
          }
          schedule {
            term {
              startedAt
              endedAt
            }
          }
          tickets {
            id
          }
        }
      }
    }
  }
`;

export const RESTORE_TICKET_ABSENCE = gql`
  mutation RestoreTicketAbsence($ticketId: ID!, $reason: String!) {
    restoreTicketAbsence(ticketId: $ticketId, reason: $reason) {
      id
      status
      bookingItem {
        booking {
          approvedAt
          status
          customer {
            id
            email
            nickname
            description
            profileImage {
              id
              uri
              thumbnail
            }
          }
          schedule {
            term {
              startedAt
              endedAt
            }
          }
          tickets {
            id
          }
        }
      }
    }
  }
`;

export const CHECK_EXISTENCEOF_INVOICE_TICKET = gql`
  query checkExistenceOfInvoiceByTicketInfo(
    $bookingId: String!
    $indexNo: Int!
    $sequence: Int!
  ) {
    settlement {
      checkExistenceOfInvoiceByTicketInfo(
        bookingId: $bookingId
        indexNo: $indexNo
        sequence: $sequence
      )
    }
  }
`;

export const CHECK_EXISTENCEOF_INVOICE = gql`
  query checkExistenceOfInvoiceByOrderItemUnitInfo(
    $orderItemId: String!
    $indexNo: Int!
  ) {
    settlement {
      checkExistenceOfInvoiceByOrderItemUnitInfo(
        orderItemId: $orderItemId
        indexNo: $indexNo
      )
    }
  }
`;

export const RETRACT_CANCEL_ORDER_REQUEST = gql`
  mutation RetractCancelOrderRequest($tid: ID!) {
    retractCancelOrderRequest(tid: $tid) {
      id
      status
    }
  }
`;

export const REQUEST_CANCEL_ORDER = gql`
  mutation ReqeustCancelOrder($param: CancelRequestParamInput!) {
    requestCancelOrder(param: $param) {
      id
      status
    }
  }
`;
