import React, { useState } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';

import { ICON_ARROW_GRAY_16 } from 'assets/icons';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import Divider from 'components/Divider';
import { Ticket, TicketState } from 'types/api';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import BookingDetail from './BookingDetail';
import BookingStatusButton from './BookingStatusButton';
import { dateFormattingForm, formatPhoneNumber } from 'utils/formatting';
import CheckBox from 'components/common/CheckBox';

const Container = styled.div<{ $isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 16px;
  width: 100%;
  border-radius: 12px;
  border: ${props =>
    props.$isSelected ? '1.5px solid #1b1c1e' : '1px solid#dbdcdf'};
  background: var(--gray-gray-01, #f7f7f8);
`;

const NotificationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 12px;
  width: 100%;
  border-radius: var(--Radius-border-radius-200, 8px);
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);
`;

interface BookingCardIProps {
  isMobile: boolean;
  item: Ticket;
  date: string;
  selectTickets: {
    date: string;
    tickets: Ticket[];
  };
  handleSelectTickets: (date: string, ticket: Ticket) => void;
  handleRefetchBookingList: () => void;
}

const BookingCard: React.FC<BookingCardIProps> = props => {
  const {
    isMobile,
    item,
    date,
    selectTickets,
    handleSelectTickets,
    handleRefetchBookingList,
  } = props;

  const [isOpenModalContainer, setIsOpenModalContainer] =
    useState<boolean>(false);

  const startedAt = new Date(item.scheduleTerm.startedAt); // 예약 시작 시간
  const currentTime = new Date(); // 현재 시간

  // 예약 시작 1시간 전으로 조정
  const actionTime = new Date(startedAt.getTime() - 60 * 60 * 1000);

  // 조건: 현재 시간이 actionTime 이후이고, startedAt 이전인지 확인
  const isShowActionButton =
    currentTime < actionTime && currentTime < startedAt;

  const handleSelectTicketItems = () => {
    if (isEmpty(selectTickets.tickets)) {
      handleSelectTickets(date, item);

      return;
    }

    if (date === selectTickets.date) {
      handleSelectTickets(date, item);

      return;
    }
  };

  const renderModalContainer = (): JSX.Element => {
    return (
      <ModalContainer
        isAbled={false}
        isMobile={isMobile}
        isBottomSheet={true}
        onClose={() => {}}
        onClick={() => {}}
        style={{ gap: 0 }}
        modalStyle={{ zIndex: 22 }}
      >
        <BookingDetail
          item={item}
          onClose={() => setIsOpenModalContainer(false)}
          handleRefetchBookingList={handleRefetchBookingList}
        />
      </ModalContainer>
    );
  };

  return (
    <Container $isSelected={selectTickets.tickets.includes(item)}>
      <Row justifyContent="space-between" style={{ width: '100%' }}>
        <Row gap="4px">
          <Text
            color={
              item.status === TicketState.CONFIRMED
                ? '#0071E9'
                : item.status === TicketState.USED
                ? '#1B1C1E'
                : '#FF4242'
            }
          >
            {item.status === TicketState.CONFIRMED
              ? '예약확정'
              : item.status === TicketState.USED
              ? '사용완료'
              : '예약불참'}
          </Text>
          <Text color="#70737C" size="12px" weight={400}>
            {item.scheduleTerm &&
              dayjs(item.scheduleTerm.startedAt).format('YYYY년 M월 D일 HH:mm')}
            {/* {item.scheduleTerm &&
              (item.scheduleTerm.startedAt === item.scheduleTerm.endedAt
                ? dayjs(item.scheduleTerm.startedAt).format('YYYY.MM.DD HH:mm')
                : `${dayjs(item.scheduleTerm.startedAt).format(
                    'YY-MM-DD HH:mm',
                  )} ~ ${dayjs(item.scheduleTerm.endedAt).format(
                    'YY-MM-DD HH:mm',
                  )}`)} */}
          </Text>
        </Row>
        <Row gap="4px" onClick={handleSelectTicketItems}>
          <CheckBox
            isChecked={
              date === selectTickets.date &&
              selectTickets.tickets.some(ticket => {
                if (ticket.id === item.id) {
                  return true;
                }

                return false;
              })
            }
            onChange={handleSelectTicketItems}
            style={{ width: '20px', height: '20px' }}
          />
          <Text color="#989ba2" style={{ cursor: 'pointer' }}>
            선택
          </Text>
        </Row>
      </Row>
      <Divider />
      <Row style={{ width: '100%' }}>
        <Column gap="8px" align="flex-start">
          <Row gap="8px">
            <Text color="#1b1c1e">
              {item.bookingItem.booking.customer.nickname}
            </Text>
            <Text>•</Text>
            <Text
              color="#1b1c1e"
              weight={400}
              style={{ textDecoration: 'underline' }}
            >
              {formatPhoneNumber(
                item.bookingItem.booking.customer.mobileNumber,
              )}
            </Text>
          </Row>
          <Text color="#70737C" size="12px" weight={400}>
            {item.bookingItem.booking.customer.gender === 'M' ? '남자' : '여자'}
          </Text>
        </Column>
        <Row
          style={{ minWidth: '65px' }}
          onClick={() => {
            setIsOpenModalContainer(true);
          }}
        >
          <Text color="#70737c">예약정보</Text>
          <img src={ICON_ARROW_GRAY_16} alt="arrow icon" />
        </Row>
      </Row>
      <Divider />
      <Column gap="4px" align="flex-start">
        <Text color="#70737C" size="12px" weight={400}>
          {item.bookingItem.booking.info.product.title}
        </Text>
        <Text color="#70737C" size="12px" weight={400}>
          옵션: {item.bookingItem.booking.info.name}
        </Text>
      </Column>
      <Row justifyContent="flex-end" gap="8px" style={{ width: '100%' }}>
        {isEmpty(selectTickets.tickets) && (
          <BookingStatusButton
            item={item}
            isModal={false}
            page="list"
            handleRefetchBookingList={handleRefetchBookingList}
          />
        )}
        {isShowActionButton && (
          <NotificationContainer>
            {/* <img src={ICON_NOTICE_BLACK} alt="notification" /> */}
            <Text color="#1b1c1e" size="12px">
              {dayjs(actionTime).format(dateFormattingForm())} 이후에 사용
              처리와 불참 처리가 가능합니다.
            </Text>
          </NotificationContainer>
        )}
      </Row>
      {isOpenModalContainer && renderModalContainer()}
    </Container>
  );
};

export default BookingCard;
