import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BookingItem, Order } from 'types/api';
import { errorHandler } from 'utils/ErrorHandler';
import { GET_ORDER } from '..';

export function useQueryGetOrder(id?: string) {
  const params = useParams<{ id: string }>();

  const { data, refetch } = useQuery<{ order: { order: Order } }>(GET_ORDER, {
    variables: { id: id ? id : params.id },
  });
  const [ordersheet, setOrderSheet] = useState<Order>();
  const [selectedTicket, setSelectedTicket] = useState<BookingItem[]>([]);

  const handleRefetchOrderItem = async () => {
    try {
      const response = await refetch({ id: id ? id : params.id });

      if (response) {
        setOrderSheet(response.data.order.order);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (data) {
      setOrderSheet(data.order.order);

      setSelectedTicket([]);
    }
  }, [data]);

  return {
    ordersheet,
    selectedTicket,
    setSelectedTicket,
    handleRefetchOrderItem,
  };
}
