import React from 'react';
import styled from 'styled-components';

const Container = styled.button<{ $abled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  width: 100%;

  border-radius: 8px;
  border: none;
  background: ${props => (props.$abled ? '#1B1C1E' : '##f4f4f5')};
  color: ${props => (props.$abled ? '#fff' : '#dbdcdf')};

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.6px;
  cursor: pointer;
`;

interface AuthButtonIProps {
  name: string | React.ReactElement;
  isAbled: boolean;
  style?: React.CSSProperties;
  onClick: () => void;
}

const AuthButton: React.FC<AuthButtonIProps> = props => {
  const { name, isAbled, style, onClick } = props;

  return (
    <Container
      $abled={isAbled}
      onClick={() => {
        if (isAbled) onClick();
      }}
      style={{ ...style }}
    >
      {name}
    </Container>
  );
};

export default AuthButton;
