export interface GuideMockData {
  title: string;
  description: { content: string; subScription?: string[] }[];
}

export const HOSTING_MOCK: GuideMockData[] = [
  {
    title:
      '📌 호스트님께서 운영할 프립의 성격을 하나 또는 그 이상 복수로 선택하실 수 있어요.',
    description: [
      {
        content: '아래 예시를 참고하여 프립의 성격을 선택해 주세요:',
        subScription: [
          '모임: 독서 모임, 와인 파티, 러닝 모임 등',
          '클래스: 휘낭시에 원데이 클래스, 요가 클래스, 영어 회화 클래스, 재테크 스터디 등',
          '체험/서비스: 낚시 체험, 박물관 관람, 피부 개선 프로그램, 심리 상담 등',
          '여행(투어): 버스 투어, 일출 여행, 현지 가이드 동행 투어 등',
          '배송: DIY 키트, 밀키트 등 실제 배송이 필요한 프립',
        ],
      },
      {
        content:
          "여러 명이 함께하는 해외 여행 프립을 운영하실 계획이라면, '모임'과 '여행(투어)'을 함께 선택하세요.",
      },
      { content: '‘배송’은 단독으로만 선택 가능합니다.' },
      {
        content:
          "프립 판매를 위해 '검수 요청'을 한 시점부터는 변경이 불가능하니 신중히 선택해 주세요.",
      },
    ],
  },
];

export const KIND_MOCK: GuideMockData[] = [
  {
    title:
      '📌 프립의 진행 방식으로 오프라인 또는 온라인 중 하나만 선택해 주세요.',
    description: [
      {
        content:
          '오프라인과 온라인을 모두 진행하고 싶으시다면, 각각 별도의 프립으로 개설해 주셔야 합니다.',
      },
      {
        content:
          "프립 판매를 위한 '검수 요청' 이후에는 진행 방식을 변경할 수 없으니, 신중히 선택해 주세요.",
      },
    ],
  },
];

export const SHIP_MOCK: GuideMockData[] = [
  {
    title: '📌 배송 상품의 종류를 선택해 주세요.',
    description: [
      {
        content: '아래 예시를 참고하여 상품의 종류를 선택해 주세요.',
        subScription: [
          '완제품: 완성된 상태로 배송되는 제품',
          'DIY 키트: 크루가 직접 조립하거나 완성하는 제품',
          '밀키트: 요리에 필요한 재료가 준비되어 있어 크루가 직접 요리할 수 있는 키트',
        ],
      },
    ],
  },
];

export const ONLINE_MOCK: GuideMockData[] = [
  {
    title:
      '📌 크루들이 프립의 진행 방식을 쉽게 이해할 수 있도록 안내해 주세요.',
    description: [
      {
        content: '화상 채팅: 실시간 화상 채팅으로 진행하는 경우',
      },
      {
        content: '전화: 전화로 직접 소통하며 진행하는 경우',
      },
      {
        content: '영상 제공: 미리 녹화된 영상을 제공하는 경우',
      },
      {
        content: '채팅 안내: 채팅을 통해 개별적으로 안내하는 경우',
      },
    ],
  },
];

export const ADDRESS_MOCK: GuideMockData[] = [
  {
    title: '📌 오프라인 진행 장소를 설정해 주세요.',
    description: [
      {
        content:
          '진행 장소가 두 곳 이상일 경우, [+진행 장소 추가] 버튼을 클릭하여 여러 장소를 추가할 수 있습니다.',
      },
      {
        content:
          '위치를 정확히 작성해 주시면 크루들이 쉽게 찾아갈 수 있습니다.',
        subScription: ['예시: 인왕산1코스'],
      },
      {
        content: '진행 장소 설정 팁',
        subScription: [
          "국내/해외 선택: 진행 장소가 국내인 경우 '국내', 해외인 경우 '해외'를 선택해 주세요.",
          '주소 입력: 장소명 또는 주소를 입력한 후 검색 결과에서 알맞은 위치를 선택해 주세요.',
          '선택한 주소와 지도상의 핀 위치가 다를 경우, 지도의 핀을 클릭하여 직접 위치를 조정할 수 있습니다.',
          "주차 가능 여부: 주차가 가능하다면 '가능'을 선택하고, 설명을 작성해 주세요. (예: 건물 내 1대 주차 가능)",
          '기타 편의 정보: 와이파이, 휴대폰 충전 등 이용 가능한 편의 사항을 선택해 주세요.',
        ],
      },
    ],
  },
  {
    title: '📌 진행 장소와 다른 장소에서 모이게 되나요?',
    description: [
      {
        content:
          '진행 장소와 다른 곳에서 모여 이동하는 경우, 모이는 장소를 별도로 설정해 주세요.',
      },
      {
        content:
          '모이는 장소는 한 곳만 설정할 수 있으며, 위치를 정확히 작성해 주셔야 크루들이 헤매지 않고 집결할 수 있습니다.',
        subScription: ['예시: 3호선 독립문역 1번 출구 앞'],
      },
    ],
  },
];

export const CATEGORY_MOCK: GuideMockData[] = [
  {
    title: '📌 진행할 프립의 카테고리를 선택해 주세요.',
    description: [
      {
        content:
          '검색: 진행할 프립에 적합한 카테고리를 검색하여 선택해 주세요.',
      },
      {
        content:
          '직접 선택: 1차 카테고리를 선택하신 후, 세부적인 2차, 3차, 4차 카테고리까지 설정해 주세요.',
      },
    ],
  },
  {
    title: '📌 원하는 카테고리를 찾지 못하셨나요?',
    description: [
      {
        content: '호스트 지원센터 카카오톡 @프립호스트로 문의해 주세요.',
      },
    ],
  },
];

// option

export const ATTACH_MOCK: GuideMockData[] = [
  {
    title: '📌 예약 방식 선택',
    description: [
      {
        content:
          '프립 운영에 적합한 예약 방식을 아래 두 가지 중에서 선택해 주세요. 판매 시작 후에는 변경이 불가능하니 신중히 결정해 주세요.',
      },
    ],
  },
  {
    title: '📌 일정 지정형 (권장)',
    description: [
      {
        content:
          '진행 가능한 일정을 미리 오픈하고, 해당 일정의 티켓을 판매하는 방식입니다.',
      },
      {
        content:
          '일정별로 최소/최대 인원을 설정할 수 있어, ‘최소 인원 미달 시 자동 취소’ 등의 설정이 가능합니다.',
      },
      {
        content:
          '구매자는 정확한 일정을 미리 확인할 수 있어, 일정 조율형에 비해 구매 전환율이 더 높을 수 있습니다.',
      },
      {
        content:
          '일정 지정형을 선택하면, 이후 과정에서 일정과 판매 옵션을 설정하게 됩니다.',
      },
    ],
  },
  {
    title: '📌 일정 조율형',
    description: [
      {
        content:
          '티켓을 먼저 판매한 후, 구매자와 일정을 직접 조율하여 진행하는 방식입니다.',
      },
      {
        content: '티켓의 유효기간을 설정할 수 있습니다.',
      },
      {
        content:
          '일정 조율형을 선택하면, 이후 과정에서 판매 옵션을 설정하게 됩니다.',
      },
    ],
  },
];

export const ATTACH_OPTION_MOCK: GuideMockData[] = [
  {
    title: '📌 먼저 판매 인원 설정의 기준을 선택해 주세요.',
    description: [
      {
        content: '일정별 모집 인원 설정',
        subScription: [
          '일정 단위로 모집 인원을 설정할 수 있습니다.',
          '옵션과 관계없이 일정에 설정된 최소 모집 인원을 충족해야 합니다. (미달 시 자동 취소)',
          '한 명의 구매자가 옵션 3개를 선택해 결제하면, 3명이 모집된 것으로 처리됩니다.',
        ],
      },
      {
        content: '옵션별 모집 인원 설정',
        subScription: [
          '옵션 단위로 모집 인원을 설정할 수 있습니다.',
          '특정 일정의 옵션 중 하나라도 최소 모집 인원을 충족하지 못하면, 해당 일정의 모든 옵션이 자동 취소됩니다.',
        ],
      },
    ],
  },
  {
    title: '📌 판매할 옵션을 추가하고 설정해 주세요.',
    description: [
      {
        content: '옵션명, 참가비, 모집 인원을 설정해 주세요.',
        subScription: [
          '옵션명: 구매자가 쉽게 이해할 수 있는 명칭으로 입력해 주세요.',
          '옵션이 1가지인 경우, "1인 참가비"와 같이 간결하고 이해하기 쉬운 명칭으로 입력해 주세요.',
          '참가비: 플랫폼 수수료를 고려하여 판매 가격을 설정해 주세요.',
        ],
      },
      {
        content: '모집 인원: 위에서 선택한 기준에 따라 설정 방법이 다릅니다.',
        subScription: [
          '일정별 모집 인원 설정: 다음 단계에서 진행 가능한 일정을 추가한 후, 일정 단위로 모집 인원(최소/최대)을 설정할 수 있습니다.',
          '옵션별 모집 인원 설정: 모집할 최소 인원과 최대 인원을 설정하세요.',
          '최소 인원은 1명 이상이어야 하며, 최대 인원을 제한하지 않으려면 0명으로 입력하세요.',
        ],
      },
      {
        content: '판매 시작 후에도 옵션을 자유롭게 추가할 수 있습니다.',
      },
    ],
  },
  {
    title: '📌 필요 시, 옵션별로 상세 설정을 할 수 있어요.',
    description: [
      {
        content:
          '1회 구매 가능 수량: 1회 구매 시 최대 구매 가능한 수량을 설정할 수 있습니다.',
      },
    ],
  },
];

export const OPTION_MOCK: GuideMockData[] = [
  {
    title: '📌 판매할 옵션을 추가하고 설정해 주세요.',
    description: [
      {
        content: '옵션명, 참가비, 판매 개수를 설정해 주세요.',
        subScription: [
          '옵션명: 구매자가 쉽게 이해할 수 있는 명칭으로 입력해 주세요.',
          '옵션이 1가지인 경우, "1인 참가비"와 같이 간결하고 이해하기 쉬운 명칭으로 입력해 주세요.',
          '참가비: 플랫폼 수수료를 고려하여 판매 가격을 설정해 주세요.',
          '판매 개수: 옵션의 최대 판매 수량(재고)을 설정하지 않으면 무제한으로 판매가 가능합니다.',
        ],
      },
      {
        content: '판매 시작 후에도 옵션을 자유롭게 추가할 수 있습니다.',
      },
    ],
  },
  {
    title: '📌 필요 시, 옵션별로 상세 설정을 할 수 있어요.',
    description: [
      {
        content:
          '기간 한정 판매: 특정 기간 동안만 판매되도록 설정할 수 있습니다.',
      },
      {
        content:
          '다회권 설정: 2회 이상 진행되는 상품의 경우, 다회권 여부를 설정할 수 있습니다.',
      },
      {
        content:
          '1회 구매 가능 수량: 1회 구매 시 최대 구매 가능한 수량을 설정할 수 있습니다.',
      },
    ],
  },
];

export const CALENDAR_MOCK: GuideMockData[] = [
  {
    title: '📌 진행할 날짜를 선택하고, 세부 사항을 설정해 주세요.',
    description: [
      {
        content:
          "캘린더에서 직접 날짜를 선택하거나, '날짜 일괄 선택' 기능으로 반복 일정을 설정할 수 있습니다.",
      },
      {
        content:
          '해당 일정에 판매할 옵션을 선택하고, 진행 시간을 설정해 주세요. (예: 오후 7시부터 오후 9시까지)',
        subScription: [
          "저녁에 시작해 새벽에 끝나거나, 1박 2일처럼 시작일과 종료일이 다른 경우 '다른 날 종료'를 선택해 주세요.",
        ],
      },
      {
        content:
          '일정별 모집 인원을 설정한 경우, 각 일정마다 모집 인원을 설정해 주세요.',
        subScription: [
          '최소 인원은 1명 이상이어야 하며, 최대 인원을 제한하지 않으려면 0명으로 입력하세요.',
        ],
      },
      {
        content:
          '한 날짜에 여러 시간대로 진행하려면, 같은 방식으로 일정을 추가해 주세요.',
        subScription: [
          '하나의 날짜에 동일한 시작 시간으로 일정을 중복 등록할 수 없습니다.',
          '추가 모집 등의 이유로 동일 시간대에 여러 일정을 등록하려면, 시작 시간을 14:01, 14:02와 같이 조금씩 다르게 설정해 주세요.',
        ],
      },
      {
        content: '당일 일정은 등록할 수 없습니다.',
      },
      {
        content: '판매 시작 후에도 일정을 자유롭게 추가할 수 있습니다.',
      },
    ],
  },
  {
    title:
      '📌 신청 마감일에 최소 인원 충족 여부에 따라 일정의 예약이 확정 또는 자동 취소됩니다.',
    description: [
      {
        content:
          '신청 마감일은 진행 시작 시간 기준 최소 1일 전까지로 설정할 수 있습니다.',
        subScription: [
          '1일 전: 진행 시작 24시간 전',
          '2일 전: 진행 시작 48시간 전',
          '3일 전: 진행 시작 72시간 전',
        ],
      },
      {
        content:
          '설정한 최소 인원에 미달하여 일정이 취소될 경우, 신청 마감 시각에 호스트님과 신청한 크루님께 일정 취소가 안내됩니다.',
        subScription: [
          '예시: 모집 인원이 4~10명일 때 최소 인원은 4명입니다. 신청 마감 시간까지 3명 이하인 경우 자동으로 일정이 취소됩니다.',
        ],
      },
      {
        content:
          "판매할 옵션, 진행 시간, 신청 마감일은 프립 판매를 위해 '검수 요청'을 한 시점부터 변경이 불가능하니 신중히 선택해 주세요.",
      },
    ],
  },
];

export const SALE_MOCK: GuideMockData[] = [
  {
    title: '📌 판매 기간을 설정해 주세요.',
    description: [
      {
        content: '판매 시작일',
        subScription: [
          '검수 후 즉시 판매: 검수가 완료되는 즉시 판매가 시작됩니다.',
          '날짜 지정: 검수 완료 후, 선택한 날짜부터 판매가 시작됩니다.',
        ],
      },
      {
        content: '판매 종료일',
        subScription: [
          '선택한 날짜까지 판매되며, 이후 자동으로 판매가 중단됩니다.',
        ],
      },
    ],
  },
  {
    title:
      '📌 1인당 재주문 제한 기능을 사용하여 동일한 사람의 반복 주문을 제한할 수 있습니다.',
    description: [
      {
        content:
          '얼리버드 상품 등 1인당 1회만 구매 가능한 특별 상품을 설정하려면 이 옵션을 사용하세요.',
      },
    ],
  },
];
