import { Commission } from 'containers/ProductRegist/hooks/types';
import { ProductState } from 'types/api';

export type MeData = {
  me: {
    chatUser: ChatUser;
    user: UserDataType;
    host: MyInfoHost;
    issuedCoupons: {
      totalCount: number;
    };
  };
};

export enum SocialLinkProviderType {
  KakaoSync = 'KAKAO_SYNC',
  Facebook = 'FACEBOOK',
}

export enum HostBusinessInfoState {
  ENROLLED = 'ENROLLED',
  MODIFIED = 'MODIFIED',
  UNROLLED = 'UNROLLED',
  UNVERIFIED = 'UNVERIFIED',
}

type NoticeMediaChannel = 'EMAIL' | 'SMS' | 'PUSH';

type NoticeReceiveAgreement = {
  id: number;
  channel: NoticeMediaChannel;
  advertised: boolean;
  agreed: boolean;
  agreedAt: Date;
  updatedAt: Date;
};

export type UserDataType = {
  id: number;
  email?: string;
  mobileNumber?: string;
  nickname?: string;
  birth?: string;
  gender?: string;
  noticeReceiveAgreements: NoticeReceiveAgreement[];
  socialLinks: {
    id: number;
    socialUserId: string;
    provider: SocialLinkProviderType;
  }[];
  privateProfile: boolean;
  joinedAt: string; // Date String
  certificated: boolean;
  verified: boolean;
  invitationCode?: string;
  profileImage: {
    thumbnail: string;
  } | null;
  usablePoint: {
    availables: number;
  };
};

export type ChatUser = {
  token: string;
  userId: string;
  userNickName: string;
};

export type MyInfoHost = {
  id: string;
  name: string;
  description: string;
  phoneNumber: string;
  profileImage: {
    thumbnail: string;
  } | null;
  super: boolean;
  settlement: HostData | null;
  commission: HostCommission;
  wishInfo: { count: number; isWishedByMe: boolean };
  productReviewInfo: { count: number };
  productInfo: { counts: { count: number; status: ProductState }[] };
};

export interface HostCommission {
  absent: Commission;
}

export interface HostData {
  attachments?: AttachmentsEntity[] | null;
  businessInfo: BusinessInfo;
  bankAccount: BankAccount;
  __typename: string;
}
export interface AttachmentsEntity {
  createdAt: number;
  fileKey: string;
  id: string;
  __typename: string;
}
export interface BusinessInfo {
  address: string;
  businessItem: string;
  businessType: string;
  representativeName: string;
  taxPayerName: string;
  taxPayerType: string;
  isRegister: boolean;
  status: HostBusinessInfoState;
  __typename: string;
}
export interface BankAccount {
  account: Account;
  verified: boolean;
  verifiedAt: number;
  __typename: string;
}
export interface Account {
  accountNo: string;
  bank: Bank;
  holder: string;
  __typename: string;
}
export interface Bank {
  code: string;
  name: string;
  __typename: string;
}
