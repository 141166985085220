import { useMutation } from '@apollo/client';
import { errorHandler } from 'utils/ErrorHandler';
import { DELETE_PRODUCT } from '..';

export function useMutationDeleteProduct() {
  const [deleteProduct] = useMutation(DELETE_PRODUCT);

  const handleDeleteProduct = async (id: string, callback?: () => void) => {
    try {
      const response = await deleteProduct({ variables: { id } });

      if (response) {
        callback && callback();
        window.location.reload();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  return { handleDeleteProduct };
}
