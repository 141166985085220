import React from 'react';
import styled from 'styled-components';
import ReactQuill from 'react-quill';

import Text from 'components/common/Text';
import Divider from 'components/Divider';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import Column from 'components/common/Column';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;

  img {
    width: 100%;
  }
`;

interface IntroduceContainerIProps {
  productParamInput: ProductParamInput;
}

const IntroduceContainer: React.FC<IntroduceContainerIProps> = (props) => {
  const { productParamInput } = props;

  return (
    <Container>
      <Text color="#000" size="18px" weight={700} style={{ padding: '0 24px' }}>
        프립 소개
      </Text>
      <Column style={{ padding: '0 12px' }}>
        <ReactQuill
          readOnly={true}
          theme="bubble"
          style={{ width: '100%' }}
          value={productParamInput.htmlDetailContent}
        />
      </Column>
    </Container>
  );
};

export default IntroduceContainer;
