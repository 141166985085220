import React from 'react';
import styled from 'styled-components';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import { ICON_ARROW_BLACK_TOP } from 'assets/icons';
import { QnaFilter } from '../hooks/useQnaListHooks';

const CountFilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;

  cursor: pointer;
`;

const CountFilterItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 16px;
  position: absolute;
  top: 25px;
  right: 20px;
  padding: 16px 20px;
  border-radius: 12px;
  background: var(--Static-White, #fff);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
`;

interface TotalCountContainerIProps {
  totalCount: number;
  isOpenCountFilter: boolean;
  filterItem: QnaFilter | any;
  handleSetFilterItem: (key: string, value: any) => void;
  handleIsOpenCountFilter: () => void;
}

const TotalCountContainer: React.FC<TotalCountContainerIProps> = props => {
  const {
    totalCount,
    isOpenCountFilter,
    filterItem,
    handleSetFilterItem,
    handleIsOpenCountFilter,
  } = props;

  return (
    <Row
      justifyContent="space-between"
      style={{
        position: 'relative',
        width: '100%',
      }}
    >
      <Text color="#1b1c1e" size="16px">
        전체 {totalCount.toLocaleString()}건
      </Text>
      <CountFilterContainer onClick={() => handleIsOpenCountFilter()}>
        <Text color="#1b1c1e">{filterItem.size}개씩 보기</Text>
        <img
          src={ICON_ARROW_BLACK_TOP}
          alt="arrow black bottom"
          style={{ rotate: isOpenCountFilter ? '0deg' : '180deg' }}
        />
      </CountFilterContainer>
      {isOpenCountFilter && (
        <CountFilterItemContainer>
          <Text
            color="#46474c"
            onClick={() => {
              handleSetFilterItem('size', 5);
              handleIsOpenCountFilter();
            }}
            style={{ cursor: 'pointer' }}
          >
            5개씩 보기
          </Text>
          <Text
            color="#46474c"
            onClick={() => {
              handleSetFilterItem('size', 10);
              handleIsOpenCountFilter();
            }}
            style={{ cursor: 'pointer' }}
          >
            10개씩 보기
          </Text>
          <Text
            color="#46474c"
            onClick={() => {
              handleSetFilterItem('size', 20);
              handleIsOpenCountFilter();
            }}
            style={{ cursor: 'pointer' }}
          >
            20개씩 보기
          </Text>
          <Text
            color="#46474c"
            onClick={() => {
              handleSetFilterItem('size', 50);
              handleIsOpenCountFilter();
            }}
            style={{ cursor: 'pointer' }}
          >
            50개씩 보기
          </Text>
        </CountFilterItemContainer>
      )}
    </Row>
  );
};

export default TotalCountContainer;
