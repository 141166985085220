import React from 'react';
import styled from 'styled-components';

const Container = styled.input`
  width: 24px;
  height: 24px;
  -webkit-appearance: none; /* 기본 스타일 제거 */
  -moz-appearance: none;
  appearance: none;
  background-color: #fff; /* 기본 배경색 */
  border: 1px solid #ddd; /* 테두리 */
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: 5px;
  z-index: 1;

  &:checked {
    background-color: #000;
  }

  &:checked::before {
    content: '✓';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
`;

interface CheckBoxIProps {
  isChecked: boolean;
  style?: React.CSSProperties;
  onChange: () => void;
}

const CheckBox: React.FC<CheckBoxIProps> = props => {
  const { isChecked, style, onChange } = props;

  return (
    <Container
      type="checkbox"
      checked={isChecked}
      onChange={() => {
        onChange();
      }}
      style={{ ...style }}
    />
  );
};

export default CheckBox;
