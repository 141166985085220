import React from 'react';
import styled from 'styled-components';
import Column from '../Column';
import Text from '../Text';
import {
  ICON_MENU_CALENDAR,
  ICON_MENU_CALENDAR_ACTIVE,
  ICON_MENU_LIST,
  ICON_MENU_LIST_ACTIVE,
  ICON_MENU_MENU,
  ICON_MENU_MENU_ACTIVE,
  ICON_MENU_MESSAGE,
  ICON_MENU_MESSAGE_ACTIVE,
  ICON_MENU_SALE,
  ICON_MENU_SALE_ACTIVE,
} from 'assets/icons';

const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  gap: 12px;
  padding: 12px 16px;
  width: 100%;
  border-top: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);
  z-index: 20;
`;

interface MobileBottomSheetIProps {
  activeButton: string;
  handleNavigatePage: (path: string) => void;
}

const MobileBottomSheet: React.FC<MobileBottomSheetIProps> = props => {
  const { activeButton, handleNavigatePage } = props;

  const isShowMobileSheet = location.pathname.includes('sales/detail');

  if (isShowMobileSheet) {
    return;
  }

  return (
    <BottomContainer>
      <Column
        gap="2px"
        onClick={() => {
          handleNavigatePage('sales');
        }}
      >
        <img
          src={
            activeButton.includes('sale')
              ? ICON_MENU_SALE_ACTIVE
              : ICON_MENU_SALE
          }
          alt="icon menu sale-list"
        />
        <Text size="12px" color={activeButton.includes('sale') && '#7126FF'}>
          판매
        </Text>
      </Column>
      <Column
        gap="2px"
        onClick={() => {
          handleNavigatePage('booking');
        }}
      >
        <img
          src={
            activeButton === 'booking'
              ? ICON_MENU_CALENDAR_ACTIVE
              : ICON_MENU_CALENDAR
          }
          alt="icon menu calendar"
        />
        <Text size="12px" color={activeButton === 'booking' && '#7126FF'}>
          예약
        </Text>
      </Column>
      <Column
        gap="2px"
        onClick={() => {
          handleNavigatePage('product');
        }}
      >
        <img
          src={
            activeButton === 'product' ? ICON_MENU_LIST_ACTIVE : ICON_MENU_LIST
          }
          alt="icon menu list"
        />
        <Text size="12px" color={activeButton === 'product' && '#7126FF'}>
          상품
        </Text>
      </Column>
      <Column
        gap="2px"
        onClick={() => {
          handleNavigatePage('message');
        }}
      >
        <img
          src={
            activeButton === 'message'
              ? ICON_MENU_MESSAGE_ACTIVE
              : ICON_MENU_MESSAGE
          }
          alt="icon menu message"
        />
        <Text size="12px" color={activeButton === 'message' && '#7126FF'}>
          메시지
        </Text>
      </Column>
      <Column
        gap="2px"
        onClick={() => {
          handleNavigatePage('overview');
        }}
      >
        <img
          src={
            activeButton === 'overview' ? ICON_MENU_MENU_ACTIVE : ICON_MENU_MENU
          }
          alt="icon menu menu"
        />
        <Text size="12px" color={activeButton === 'overview' && '#7126FF'}>
          메뉴
        </Text>
      </Column>
    </BottomContainer>
  );
};

export default MobileBottomSheet;
