import React, { SetStateAction } from 'react';
import styled from 'styled-components';

import { ProductParamInput } from '../hooks/types';
import { RegistPageNumber } from '../hooks/useProductRegistHooks';
import ChapterThree from './InformationRegist/ChapterThree';
import ChapterFive from './InformationRegist/ChapterFive';
import ChapterFour from './InformationRegist/ChapterFour';
import ChapterOne from './InformationRegist/ChapterOne';
import ChapterTwo from './InformationRegist/ChapterTwo';
import ChapterSix from './InformationRegist/ChapterSix';
import ChapterSeven from './InformationRegist/ChapterSeven';
import PreviewProductDetail from './PreviewProductDetail';
import Row from 'components/common/Row';
import { InquiryTemplate } from 'types/api';
import ChapterEight from './InformationRegist/ChapterEight';
import Column from 'components/common/Column';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  width: 100%;
  max-width: 1280px;
  /* height: 81vh; */
  overflow-y: scroll;
`;

interface InformationRegistIProps {
  isMobile: boolean;
  height: number;
  productParamInput: ProductParamInput;
  registPageNumber: RegistPageNumber;
  imageThumbnail: string[];
  inquiryList: InquiryTemplate;
  setProductParamInput: React.Dispatch<React.SetStateAction<ProductParamInput>>;
  setImageThumbnail: React.Dispatch<SetStateAction<string[]>>;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
  handelGetInquiryTemplate: (id: string) => void;
  handleRefetchInquiryTemplate: (id: string) => void;
}

const InformationRegist: React.FC<InformationRegistIProps> = props => {
  const {
    isMobile,
    height,
    productParamInput,
    registPageNumber,
    imageThumbnail,
    inquiryList,
    setProductParamInput,
    setImageThumbnail,
    handleOnChangeProductParamInput,
    handelGetInquiryTemplate,
    handleRefetchInquiryTemplate,
  } = props;

  const renderInformationRegist = (): JSX.Element => {
    switch (registPageNumber.informationPageNumber.page) {
      case 0: {
        return <ChapterOne isMobile={isMobile} />;
      }
      case 1: {
        return (
          <ChapterTwo
            isMobile={isMobile}
            productParamInput={productParamInput}
            imageThumbnail={imageThumbnail}
            setImageThumbnail={setImageThumbnail}
            handleOnChangeProductParamInput={handleOnChangeProductParamInput}
          />
        );
      }
      case 2: {
        return (
          <ChapterThree
            isMobile={isMobile}
            productParamInput={productParamInput}
            handleOnChangeProductParamInput={handleOnChangeProductParamInput}
          />
        );
      }
      case 3: {
        return (
          <ChapterFour
            isMobile={isMobile}
            productParamInput={productParamInput}
            handleOnChangeProductParamInput={handleOnChangeProductParamInput}
          />
        );
      }
      case 4: {
        return (
          <ChapterFive
            isMobile={isMobile}
            productParamInput={productParamInput}
            handleOnChangeProductParamInput={handleOnChangeProductParamInput}
          />
        );
      }
      case 5: {
        return (
          <ChapterSix
            isMobile={isMobile}
            productParamInput={productParamInput}
            inquiryList={inquiryList}
            handleOnChangeProductParamInput={handleOnChangeProductParamInput}
            handelGetInquiryTemplate={handelGetInquiryTemplate}
            handleRefetchInquiryTemplate={handleRefetchInquiryTemplate}
          />
        );
      }
      case 6: {
        return (
          <ChapterSeven
            isMobile={isMobile}
            productParamInput={productParamInput}
            handleOnChangeProductParamInput={handleOnChangeProductParamInput}
          />
        );
      }
      case 7: {
        return (
          <ChapterEight
            productParamInput={productParamInput}
            imageThumbnail={imageThumbnail}
            isMobile={isMobile}
          />
        );
      }
      default:
        return <></>;
    }
  };

  return (
    <Container
      style={{
        justifyContent:
          isMobile && registPageNumber.informationPageNumber.page === 0
            ? 'flex-end'
            : isMobile || registPageNumber.informationPageNumber.page !== 0
            ? 'flex-start'
            : 'center',
        padding: isMobile
          ? registPageNumber.informationPageNumber.page === 0
            ? '64px 20px'
            : registPageNumber.informationPageNumber.page === 7
            ? '0'
            : '20px 20px 64px'
          : '0 40px',
        height: `${height - 174}px`,
      }}
    >
      {isMobile ? (
        renderInformationRegist()
      ) : (
        <Row style={{ width: '100%' }} align="flex-start">
          {registPageNumber.informationPageNumber.page !== 0 ? (
            <Column
              justifyContent="flex-start"
              style={{
                padding:
                  registPageNumber.informationPageNumber.page === 7
                    ? '0'
                    : '32px 64px 64px 0',
                position: 'sticky',
                top: '89px',
                overflowY: 'scroll',
                width: '100%',
                height: `${height - 174}px`,
                zIndex: 8,
              }}
            >
              {renderInformationRegist()}
            </Column>
          ) : (
            <Column
              style={{
                padding: '32px 20px 64px',
              }}
            >
              {renderInformationRegist()}
            </Column>
          )}
          {registPageNumber.informationPageNumber.page !== 0 &&
            registPageNumber.informationPageNumber.page !== 7 && (
              <PreviewProductDetail
                isMobile={isMobile}
                productParamInput={productParamInput}
                imageThumbnail={imageThumbnail}
              />
            )}
        </Row>
      )}
    </Container>
  );
};

export default InformationRegist;
