import { IMAGE_REGIST_PRODUCT_SELL } from 'assets/images';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HackleManager from 'utils/hackle';

interface ChapterOneIProps {
  isMobile: boolean;
}

const ChapterOne: React.FC<ChapterOneIProps> = props => {
  const { isMobile } = props;

  const params = useParams<{ id: string }>();

  useEffect(() => {
    HackleManager.logCreateStepTwoView(
      params.id !== 'draft' ? Number(params.id) : undefined,
    );
  }, []);

  const renderResponsivePage = (): JSX.Element => {
    if (isMobile) {
      return (
        <Column gap="32px">
          <img
            src={IMAGE_REGIST_PRODUCT_SELL}
            alt="image regist product sell"
            style={{ width: '220px' }}
          />
          <Column gap="12px" align="flex-start">
            <Column gap="4px" align="flex-start">
              <Text color="#1b1c1e" size="14px">
                2단계
              </Text>
              <Text color="#1b1c1e" size="24px">
                판매 정보를 알려주세요
              </Text>
            </Column>
            <Text color="#70737C" size="16px" weight={400}>
              이 단계에서는 크루들에게 판매할 상품의 옵션을 추가한 후 판매
              일정을 설정해요.
            </Text>
          </Column>
        </Column>
      );
    }

    return (
      <Row gap="120px" style={{ width: '100%' }}>
        <Column gap="16px" align="flex-start">
          <Column gap="12px" align="flex-start">
            <Text color="#1b1c1e" size="14px">
              2단계
            </Text>
            <Text color="#1b1c1e" size="24px">
              판매 정보를 알려주세요
            </Text>
          </Column>
          <Text color="#70737C" size="16px" weight={400}>
            이 단계에서는 크루들에게 판매할 상품의 옵션을 추가한 후 판매 일정을
            설정해요.
          </Text>
        </Column>
        <img
          src={IMAGE_REGIST_PRODUCT_SELL}
          alt="image regist product sell"
          style={{ width: '220px' }}
        />
      </Row>
    );
  };

  return renderResponsivePage();
};

export default ChapterOne;
