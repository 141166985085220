import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ICON_SOCIAL_LOGIN_FACEBOOK } from 'assets/icons';
import { getEnvConstant } from 'constants/env';
import { instance } from 'utils/axiosInstance';
import { SocialLinkProviderType } from 'graphQL/Auth/types';
import { STORAGE_KEY } from 'utils/localStoarge';
import { useMutationCreateHost } from '../graphQL/hooks/useMutationCreateHost';
import { UserLoginResponse } from '../graphQL/types';
import { useGetMe } from 'graphQL/Auth/user/useGetMe';
import { SALE_LIST_PATH } from 'constants/path';
import HackleManager from 'utils/hackle';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 12px 24px;
  width: 100%;

  border-radius: 8px;
  border: 1px solid #46474c;
  background: #fff;
  cursor: pointer;

  span {
    color: #46474c;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.6px;
  }
`;

const SocialLogo = styled.img`
  position: absolute;
  left: 12px;
`;

interface FaceBookSDKIProps {
  handleIsShowSignupForm: () => void;
  handleSetLoginInfo: (info: UserLoginResponse) => void;
}

const FaceBookSDK: React.FC<FaceBookSDKIProps> = props => {
  const { handleIsShowSignupForm, handleSetLoginInfo } = props;
  const navigate = useNavigate();

  const { handleCreateHost } = useMutationCreateHost();
  const { handleRefetchUser } = useGetMe();
  const [isFacebookSDKLoaded, setIsFacebookSDKLoaded] =
    useState<boolean>(false);

  const initializeFacebook = useCallback(() => {
    window.FB.init({
      appId: getEnvConstant('facebookAppKey'),
      xfbml: true,
      cookie: true,
      version: 'v8.0',
    });

    setIsFacebookSDKLoaded(true);
  }, [isFacebookSDKLoaded]);

  const handleSignIn = async (access_token: string) => {
    try {
      const response = await instance.post('/link/signin', {
        authorityLevel: 'HOST',
        provider: SocialLinkProviderType.Facebook,
        token: access_token,
        store: 'frip',
      });

      if (response) {
        localStorage.setItem(
          STORAGE_KEY.AUTHORIZATION,
          `${response.data.data.tokenType} ${response.data.data.accessToken}`,
        );

        handleRefetchUser(() => {
          navigate(SALE_LIST_PATH);
        });
      }
    } catch (error: any) {
      const errorCode = error.response.status;
      const errorData = error.response.data;

      if (errorCode === 403) {
        handleSignup(errorData, access_token);
      }

      if (errorCode === 401) {
        const response = await instance.post('/link/signin', {
          authorityLevel: 'USER',
          provider: SocialLinkProviderType.Facebook,
          token: access_token,
          store: 'frip',
        });

        if (response) {
          localStorage.setItem(
            STORAGE_KEY.AUTHORIZATION,
            `${response.data.data.tokenType} ${response.data.data.accessToken}`,
          );

          handleRefetchUser(() => {
            handleIsShowSignupForm();
          });
          handleSetLoginInfo(response.data.data);
        }
      }
    }
  };

  const handleSignup = async (errorData: any, access_token: string) => {
    try {
      const {
        marketing,
        email,
        nickname,
        mobileNumber,
        validEmail,
        validMobileNumber,
      } = errorData.data;

      if (!validEmail || !validMobileNumber) {
        alert(
          !validEmail ? '이미 가입된 이메일입니다.' : '이미 가입된 번호입니다.',
        );

        return;
      }

      const response = await instance.post('/link/signup', {
        authorityLevel: 'HOST',
        provider: SocialLinkProviderType.Facebook,
        token: access_token,
        store: 'frip',
        email,
        nickname,
        mobileNumber,
        agreement: {
          marketing,
        },
      });

      if (response) {
        const userResponse = await instance.post('/link/signin', {
          authorityLevel: 'USER',
          provider: SocialLinkProviderType.Facebook,
          token: access_token,
          store: 'frip',
        });

        HackleManager.logSignupVerificationComplete('facebook');
        HackleManager.logSignupComplete('facebook', marketing);

        localStorage.setItem(
          STORAGE_KEY.AUTHORIZATION,
          `${response.data.data.tokenType} ${response.data.data.accessToken}`,
        );

        handleCreateHost(userResponse.data.data, mobileNumber);
      }
    } catch (error) {
      console.log(error, 'signup error');
    }
  };

  const handleOnClick = useCallback(() => {
    if (!isFacebookSDKLoaded) {
      return;
    }

    HackleManager.logSigninClick('facebook');

    window.FB.getLoginStatus((res: any) => {
      if (res.status === 'connected') {
        handleSignIn(res.authResponse.accessToken);
      } else {
        window.FB.login(
          (res: any) => {
            if (res.status === 'connected') {
              handleSignIn(res.authResponse.accessToken);
            } else {
              // raiseErrorToastAlert();
            }
          },
          {
            scope: 'email',
          },
        );
      }
    });
  }, [isFacebookSDKLoaded]);

  useEffect(() => {
    initializeFacebook();
  }, []);

  return (
    <Container onClick={handleOnClick}>
      <SocialLogo src={ICON_SOCIAL_LOGIN_FACEBOOK} alt="facebook logo" />{' '}
      <span>페이스북 계정으로 시작하기</span>
    </Container>
  );
};

export default FaceBookSDK;
