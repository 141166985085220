import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { isEmpty, cloneDeep } from "lodash";

import { ICON_SETTLEMENT_VALIDATION } from "assets/icons";
import { useMutationGetHostAttachmentDownloadUrl } from "../graphQL/hooks/useMutationGetHostAttachmentDownloadUrl";
import CommonModalContainer from "./ModalContainer";
import { HostBusinessInfoState, MyInfoHost } from "graphQL/Auth/types";
import { TaxPayerType } from "../graphQL/types";
import Text from "components/common/Text";
import { SETTLEMENT_REGIST_PATH } from "constants/path";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 24px 0;
  width: 100%;
  overflow: auto;
  background-color: #fff;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  padding: 0 20px;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const ModifyText = styled.div`
  width: 25px;
  color: #171719;
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.6px;
  text-decoration-line: underline;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px;
  border-radius: 12px;
  border: 1px solid #eee;
  background: #eee;

  img {
    width: 100%;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border: 0.5px solid #dbdcdf;
`;

interface HostInformationModalIProps {
  host: MyInfoHost | null;
  onClose: () => void;
}

const HostInformationModal: React.FC<HostInformationModalIProps> = (props) => {
  const { host, onClose } = props;
  const navigate = useNavigate();

  const hostInfo = host?.settlement;
  const { data, handleGetHostAttachmentDownloadUrl } =
    useMutationGetHostAttachmentDownloadUrl();

  useEffect(() => {
    if (hostInfo && !isEmpty(hostInfo.attachments)) {
      const sortedItem = cloneDeep(hostInfo.attachments)?.sort(
        (a: any, b: any) => b.createdAt - a.createdAt
      );

      handleGetHostAttachmentDownloadUrl(sortedItem![0].id);
    }
  }, [hostInfo]);

  const renderModifyButton = (pageNumber: number) => {
    if (
      hostInfo?.businessInfo.status === HostBusinessInfoState.UNROLLED ||
      hostInfo?.businessInfo.status === HostBusinessInfoState.UNVERIFIED
    ) {
      return (
        <ModifyText
          onClick={() => {
            navigate(SETTLEMENT_REGIST_PATH, {
              state: {
                pageNumber,
                isModify: true,
              },
            });
          }}
        >
          수정
        </ModifyText>
      );
    }

    return <></>;
  };

  const renderBusinessInformation = () => {
    switch (hostInfo?.businessInfo.taxPayerType) {
      case TaxPayerType.INDIVIDUAL: {
        return (
          <React.Fragment>
            <Row>
              <Column>
                <Text color="#1b1c1e" size="16px" weight={400}>
                  주민등록번호
                </Text>
                <Text color="#1b1c1e" weight={400}>
                  ******-*******
                </Text>
              </Column>
              {renderModifyButton(2)}
            </Row>
            <Divider />
            <Row>
              <Column>
                <Text color="#1b1c1e" size="16px" weight={400}>
                  주소
                </Text>
                <Text color="#1b1c1e" weight={400}>
                  {hostInfo?.businessInfo.address}
                </Text>
              </Column>
              {renderModifyButton(2)}
            </Row>
            <Divider />
          </React.Fragment>
        );
      }
      case TaxPayerType.CORPORATION: {
        return (
          <React.Fragment>
            <Row>
              <Column>
                <Text color="#1b1c1e" size="16px" weight={400}>
                  업체명
                </Text>
                <Text color="#1b1c1e" weight={400}>
                  {hostInfo?.businessInfo.taxPayerName}
                </Text>
              </Column>
              {renderModifyButton(2)}
            </Row>
            <Divider />
            <Row>
              <Column>
                <Text color="#1b1c1e" size="16px" weight={400}>
                  사업자등록증 번호
                </Text>
                <Text color="#1b1c1e" weight={400}>
                  ***-**-*******
                </Text>
              </Column>
              {renderModifyButton(2)}
            </Row>
            <Divider />
            <Row>
              <Column>
                <Text color="#1b1c1e" size="16px" weight={400}>
                  사업 업태
                </Text>
                <Text color="#1b1c1e" weight={400}>
                  {hostInfo?.businessInfo.businessType}
                </Text>
              </Column>
              {renderModifyButton(2)}
            </Row>
            <Divider />
            <Row>
              <Column>
                <Text color="#1b1c1e" size="16px" weight={400}>
                  사업 종목
                </Text>
                <Text color="#1b1c1e" weight={400}>
                  {hostInfo?.businessInfo.businessItem}
                </Text>
              </Column>
              {renderModifyButton(2)}
            </Row>
            <Divider />
            <Row>
              <Column>
                <Text color="#1b1c1e" size="16px" weight={400}>
                  주소
                </Text>
                <Text color="#1b1c1e" weight={400}>
                  {hostInfo?.businessInfo.address}
                </Text>
              </Column>
              {renderModifyButton(2)}
            </Row>
            <Divider />
          </React.Fragment>
        );
      }

      default:
        return <></>;
    }
  };

  return (
    <CommonModalContainer onClose={onClose}>
      <ContentContainer>
        <Column style={{ padding: "0 20px", width: "100%" }}>
          <Text color="#1b1c1e" size="24px">
            호스트 정보
          </Text>
          <Row
            style={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "0",
            }}
          >
            <img src={ICON_SETTLEMENT_VALIDATION} style={{ width: "24px" }} />
            <Text color="#1b1c1e" size="16px" weight={400}>
              검수 완료 후에는 호스트 정보를 수정할 수 없어요.
            </Text>
          </Row>
        </Column>
        <Row style={{ marginTop: "12px" }}>
          <Column>
            <Text color="#1b1c1e" size="16px" weight={400}>
              호스트 유형
            </Text>
            <Text color="#1b1c1e" weight={400}>
              {hostInfo?.businessInfo.taxPayerType === TaxPayerType.INDIVIDUAL
                ? "개인"
                : "사업자"}
            </Text>
          </Column>
          {renderModifyButton(1)}
        </Row>
        <Divider />
        <Row>
          <Column>
            <Text color="#1b1c1e" size="16px" weight={400}>
              {hostInfo?.businessInfo.taxPayerType === TaxPayerType.INDIVIDUAL
                ? "이름"
                : "대표자명"}
            </Text>
            <Text color="#1b1c1e" weight={400}>
              {hostInfo?.businessInfo.taxPayerType === TaxPayerType.INDIVIDUAL
                ? hostInfo?.businessInfo.taxPayerName
                : hostInfo?.businessInfo.representativeName}
            </Text>
          </Column>
          {renderModifyButton(2)}
        </Row>
        <Divider />
        {renderBusinessInformation()}
        <Column style={{ gap: "12px", padding: "0 20px", width: "100%" }}>
          <Row style={{ padding: "0" }}>
            <Text color="#1b1c1e" size="16px" weight={400}>
              신분증/운전면허증 사진
            </Text>
            {renderModifyButton(3)}
          </Row>
          {data && (
            <ImageContainer>
              <img
                src={data?.generateHostAttachmentDownloadUrl.url}
                alt="preview image"
              />
            </ImageContainer>
          )}
        </Column>
      </ContentContainer>
    </CommonModalContainer>
  );
};

export default HostInformationModal;
