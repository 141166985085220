import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import { useState } from 'react';
import { CompanionType, LevelOfDifficulty } from 'types/api';

export interface CopyFripInformation {
  difficulty: LevelOfDifficulty;
  recommendedAge: number;
  companions: CompanionType[];
  isdoor: { isIndoor: boolean; isOutdoor: boolean };
}

export const useMorInformationModifyHooks = (
  productParamInput: ProductParamInput
) => {
  const [copyFripInformation, setCopyFripInformation] =
    useState<CopyFripInformation>({
      difficulty: productParamInput.frip.difficulty,
      recommendedAge: productParamInput.frip.recommendedAge,
      companions: productParamInput.frip.companions,
      isdoor: {
        isIndoor: productParamInput.frip.isIndoor,
        isOutdoor: productParamInput.frip.isOutdoor,
      },
    });
  const isAbled =
    productParamInput.frip.difficulty !== copyFripInformation.difficulty ||
    productParamInput.frip.companions !== copyFripInformation.companions ||
    productParamInput.frip.recommendedAge !==
      copyFripInformation.recommendedAge ||
    productParamInput.frip.isIndoor !== copyFripInformation.isdoor.isIndoor ||
    productParamInput.frip.isOutdoor !== copyFripInformation.isdoor.isOutdoor;

  const handleSetDifficulty = (difficulty: LevelOfDifficulty) => {
    setCopyFripInformation({ ...copyFripInformation, difficulty });
  };

  const handleSetCompanions = (companion: CompanionType) => {
    if (copyFripInformation.companions.includes(companion)) {
      const filterItem = copyFripInformation.companions.filter(
        (item) => item !== companion
      );

      setCopyFripInformation({
        ...copyFripInformation,
        companions: filterItem,
      });

      return;
    }

    setCopyFripInformation({
      ...copyFripInformation,
      companions: [...copyFripInformation.companions, companion],
    });
  };

  const handleSetRecommendedAge = (recommendedAge: number) => {
    setCopyFripInformation({ ...copyFripInformation, recommendedAge });
  };

  const handleSetIsdoor = (isdoor: {
    isIndoor: boolean;
    isOutdoor: boolean;
  }) => {
    setCopyFripInformation({ ...copyFripInformation, isdoor });
  };

  const handleResetCopyFripInformation = () => {
    setCopyFripInformation({
      difficulty: productParamInput.frip.difficulty,
      recommendedAge: productParamInput.frip.recommendedAge,
      companions: productParamInput.frip.companions,
      isdoor: {
        isIndoor: productParamInput.frip.isIndoor,
        isOutdoor: productParamInput.frip.isOutdoor,
      },
    });
  };

  return {
    copyFripInformation,
    isAbled,
    handleSetDifficulty,
    handleSetCompanions,
    handleSetRecommendedAge,
    handleSetIsdoor,
    handleResetCopyFripInformation,
  };
};
