import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import {
  ICON_TOAST_CANCEL,
  ICON_TOAST_FAILED,
  ICON_TOAST_SUCCESS,
} from 'assets/icons';
import CommonImage from 'components/common/CommonImage';

const ToastContainer = styled.div`
  position: fixed;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
`;

const ToastItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  width: 335px;

  border-radius: 12px;
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Black-White-White, #fff);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  animation: fadeInOut 3s forwards;

  color: var(--gray-gray-09, #1b1c1e);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
`;

export type ToastType = 'success' | 'failed' | 'cancel';

interface Toast {
  id: number;
  message: string;
  duration: number;
  type?: ToastType;
}

const ToastManager: React.FC = () => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const addToast = useCallback(
    (message: string, type: ToastType, duration: number = 3000) => {
      // const id = Date.now();
      const id = Math.random();

      setToasts(prevToasts => [...prevToasts, { id, message, type, duration }]);

      setTimeout(() => {
        setToasts(prevToasts => prevToasts.filter(toast => toast.id !== id));
      }, duration);
    },
    [],
  );

  useEffect(() => {
    window.showToast = addToast;
  }, [addToast]);

  const renderToastItem = (item: Toast): JSX.Element => {
    switch (item.type) {
      case 'success': {
        return (
          <ToastItem key={item.id}>
            <CommonImage src={ICON_TOAST_SUCCESS} onClick={() => {}} />
            {item.message}
          </ToastItem>
        );
      }
      case 'failed': {
        return (
          <ToastItem key={item.id}>
            <CommonImage src={ICON_TOAST_FAILED} onClick={() => {}} />
            {item.message}
          </ToastItem>
        );
      }
      case 'cancel': {
        return (
          <ToastItem key={item.id}>
            <CommonImage src={ICON_TOAST_CANCEL} onClick={() => {}} />
            {item.message}
          </ToastItem>
        );
      }
    }
  };

  return ReactDOM.createPortal(
    toasts.length > 0 && ( // toasts가 있을 때만 ToastContainer를 렌더링
      <ToastContainer>
        {toasts.map(toast => (
          <React.Fragment key={toast.id}>
            {renderToastItem(toast)}
          </React.Fragment>
        ))}
      </ToastContainer>
    ),
    document.body,
  );
};

export default ToastManager;
