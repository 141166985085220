import { useQuery } from '@apollo/client';
import { GET_NOTICE_BOARD } from 'containers/Notice/graphQL';
import { NoticeBoard } from 'containers/Notice/graphQL/types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HackleManager from 'utils/hackle';

export const useNoticeDetailHooks = () => {
  const params = useParams<{ id: string }>();

  const { data } = useQuery<NoticeBoard>(GET_NOTICE_BOARD, {
    variables: {
      filter: {
        id: params.id,
        forHostOnly: true,
      },
      page: 1,
      size: 10,
    },
  });

  const [noticeBoard, setNoticeBoard] = useState<NoticeBoard>();

  useEffect(() => {
    if (data) {
      setNoticeBoard(data);
    }
  }, [data]);

  useEffect(() => {
    HackleManager.logNoticeDetailView(params.id);
  }, []);

  return { noticeBoard };
};
