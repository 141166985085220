import { useMutation } from '@apollo/client';
import { MeData } from 'graphQL/Auth/types';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import { errorHandler } from 'utils/ErrorHandler';
import {
  CREATE_INQUIRYTEMPLATE,
  DELETE_INQURIYTEMPLATE,
  UPDATE_INQUIRYTEMPLATE,
} from '..';
import { InquiryTemplateParamInput } from '../types';

export function useMutationInquiryTemplate() {
  const myInfo: MeData = useUserInformation((state: any) => state.userInfo);
  const [createInquiryTemplate] = useMutation<any>(CREATE_INQUIRYTEMPLATE);
  const [deleteInquiryTemplate] = useMutation(DELETE_INQURIYTEMPLATE);
  const [updateInquiryTemplate] = useMutation(UPDATE_INQUIRYTEMPLATE);

  const handleCreateInquiryTemplate = async (
    inquiryParamInput: InquiryTemplateParamInput,
    callback: (id: string) => void
  ) => {
    try {
      const response = await createInquiryTemplate({
        variables: {
          hostId: myInfo.me.host?.id,
          param: inquiryParamInput,
        },
      });

      if (response) {
        callback(response.data.createInquiryTemplate.id);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleDeleteInquiryTemplate = async (
    id: string,
    callback: () => void
  ) => {
    try {
      const response = await deleteInquiryTemplate({ variables: { id } });

      if (response) {
        callback();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleUpdateInquiryTemplate = async (
    id: string,
    inquiryParamInput: InquiryTemplateParamInput,
    callback: () => void
  ) => {
    try {
      const response = await updateInquiryTemplate({
        variables: { id, param: inquiryParamInput },
      });

      if (response) {
        callback();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  return {
    handleCreateInquiryTemplate,
    handleDeleteInquiryTemplate,
    handleUpdateInquiryTemplate,
  };
}
