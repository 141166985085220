import React from 'react';
import styled from 'styled-components';
import Column from 'components/common/Column';
import Text from 'components/common/Text';
import { HostDashBoard } from '../graphQL/types';

const Container = styled.div<{ $isMobile: boolean }>`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 16px 20px;
  width: 100%;
  overflow-x: scroll;
  border-radius: ${props => (props.$isMobile ? '12px 0 0 12px' : '12px')};
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Divider = styled.div`
  width: 1px;
  min-width: 1px;
  height: 24px;
  background-color: #dbdcdf;
`;

interface DashBoardContainerIProps {
  isMobile: boolean;
  hostDashBoard: HostDashBoard;
}

const DashBoardContainer: React.FC<DashBoardContainerIProps> = props => {
  const { isMobile, hostDashBoard } = props;

  const dashBoardItem = hostDashBoard.hostDashboard.hostDashboard;

  return (
    <Container $isMobile={isMobile}>
      <Column gap="6px" align="flex-start" style={{ minWidth: '80px' }}>
        <Text
          color="#000"
          size="13px"
          weight={400}
          style={{ textWrap: 'nowrap' }}
        >
          누적 후기
        </Text>
        <Text color="#000" size="16px" weight={600}>
          {dashBoardItem.reviewCount ? dashBoardItem.reviewCount : 0}개
        </Text>
      </Column>
      <Divider />
      <Column gap="6px" align="flex-start" style={{ minWidth: '80px' }}>
        <Text
          color="#000"
          size="13px"
          weight={400}
          style={{ textWrap: 'nowrap' }}
        >
          누적 평점
        </Text>
        <Text color="#000" size="16px" weight={600}>
          {dashBoardItem.rating ? dashBoardItem.rating : 0}점
        </Text>
      </Column>
      <Divider />
      <Column gap="6px" align="flex-start" style={{ minWidth: '80px' }}>
        <Text color="#000" size="13px" weight={400}>
          문의 응답률
        </Text>
        <Text
          color="#000"
          size="16px"
          weight={600}
          style={{ textWrap: 'nowrap' }}
        >
          {dashBoardItem.answerRate ? dashBoardItem.answerRate : 0}%
        </Text>
      </Column>
      <Divider />
      <Column gap="6px" align="flex-start" style={{ minWidth: '80px' }}>
        <Text
          color="#000"
          size="13px"
          weight={400}
          style={{ textWrap: 'nowrap' }}
        >
          이번 달 진행 프립
        </Text>
        <Text color="#000" size="16px" weight={600}>
          {dashBoardItem.runningFrip ? dashBoardItem.runningFrip : 0}개
        </Text>
      </Column>
    </Container>
  );
};

export default DashBoardContainer;
