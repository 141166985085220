import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import { ModifyValue } from 'containers/ProductDetail/hooks/useProductDetaillHooks';
import SaveButton from '../SaveButton';
import { Product, ProductState } from 'types/api';
import HackleManager from 'utils/hackle';

const ProductNameForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 0 12px 0 0;
  width: 100%;

  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;
`;

const TextAreaForm = styled.textarea`
  padding: 12px;
  width: 100%;
  color: var(--gray-gray-09, #1b1c1e);

  border-radius: 8px;
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);

  /* Body/B-16-Regular */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.6px;
  resize: none;

  &::placeholder {
    color: #c2c4c8;
  }

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  &:focus {
    border: 1.5px solid #1b1c1e;
  }
`;

interface ProductTitleModifyFormIProps {
  isMobile: boolean;
  productInfo: Product;
  title: string;
  modifyValue: ModifyValue;
  setModifyValue: Dispatch<SetStateAction<ModifyValue>>;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
}

const ProductTitleModifyForm: React.FC<
  ProductTitleModifyFormIProps
> = props => {
  const {
    isMobile,
    productInfo,
    title,
    modifyValue,
    setModifyValue,
    handleOnChangeProductParamInput,
  } = props;

  const [titleValue, setTitleValue] = useState<string>(title);
  const isAbled = titleValue !== title;
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleAdjustTextareaHeight = () => {
    const textarea = textareaRef.current;

    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleResetTitleValue = () => {
    setTitleValue(title);
  };

  const handleOnChangeTitleValue = (e: any) => {
    if (e.target.value.length <= 40) {
      handleAdjustTextareaHeight();

      setTitleValue(e.target.value);
    }
  };

  const handleRegistTitleValue = () => {
    handleOnChangeProductParamInput('title', titleValue);
  };

  useEffect(() => {
    HackleManager.logProductModifyProductNameView(
      Number(productInfo.id),
      productInfo.title,
    );
  }, []);

  useEffect(() => {
    handleAdjustTextareaHeight();
  }, []);

  const renderContents = (): JSX.Element => {
    if (isMobile) {
      return (
        <ModalContainer
          isMobile={isMobile}
          isAbled={productInfo.status !== ProductState.CLOSED && isAbled}
          onClose={() => {
            setModifyValue({ ...modifyValue, modifyStatus: '' });
          }}
          onCloseText="닫기"
          onAcceptText="적용"
          onClick={() => {
            if (isAbled) {
              HackleManager.logProductModifyItemSaveComplete(
                Number(productInfo.id),
                productInfo.title,
                '프립명',
              );

              handleRegistTitleValue();

              setModifyValue({
                ...modifyValue,
                isModify: true,
                modifyStatus: '',
              });
            }
          }}
        >
          <Column
            gap="24px"
            style={{
              padding: '16px 20px 0',
            }}
          >
            <Text color="#000" size="16px" weight={600}>
              프립명
            </Text>
            <ProductNameForm>
              <TextAreaForm
                placeholder="프립명을 입력해 주세요"
                ref={textareaRef}
                value={titleValue}
                onChange={handleOnChangeTitleValue}
                style={{ border: 'none' }}
                rows={1}
              />
              <Text color="#989ba2" size="14px" weight={400}>
                {titleValue.length}/40
              </Text>
            </ProductNameForm>
          </Column>
        </ModalContainer>
      );
    }

    return (
      <Column>
        <Row
          justifyContent="flex-start"
          style={{
            position: 'sticky',
            top: 0,
            padding: '32px 40px',
            width: '100%',
            background: '#fff',
            borderBottom: '1px solid #eaebec',
          }}
        >
          <Text color="#1B1C1E" size="24px">
            프립명 수정하기
          </Text>
        </Row>
        <Column gap="32px" style={{ padding: '32px 40px' }}>
          <ProductNameForm>
            <TextAreaForm
              placeholder="프립명을 입력해 주세요"
              ref={textareaRef}
              value={titleValue}
              onChange={handleOnChangeTitleValue}
              style={{ border: 'none' }}
              rows={1}
            />
            <Text color="#989ba2" size="14px" weight={400}>
              {titleValue.length}/40
            </Text>
          </ProductNameForm>
          {productInfo.status !== ProductState.CLOSED && (
            <SaveButton
              isAbled={isAbled}
              modifyValue={modifyValue}
              setModifyValue={setModifyValue}
              handleResetItem={handleResetTitleValue}
              handleRegistItem={() => {
                HackleManager.logProductModifyItemSaveComplete(
                  Number(productInfo.id),
                  productInfo.title,
                  '프립명',
                );
                handleRegistTitleValue();
              }}
            />
          )}
        </Column>
      </Column>
    );
  };

  return renderContents();
};

export default ProductTitleModifyForm;
