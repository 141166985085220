import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ $isActvieButton: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  width: 100%;
  border-radius: 8px;
  background-color: ${(props) =>
    props.$isActvieButton ? '#1b1c1e' : '#F5F5F5'};

  color: ${(props) => (props.$isActvieButton ? '#fff' : '#dfdfdf')};
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.6px;
`;

interface ButtonIProps {
  isActvieButton: boolean;
  text: string;
  onClick: () => void;
  style?: React.CSSProperties;
}

const SettlementButton: React.FC<ButtonIProps> = (props) => {
  const { isActvieButton, text, onClick, style } = props;

  return (
    <Container
      $isActvieButton={isActvieButton}
      style={{ ...style }}
      onClick={onClick}
    >
      {text}
    </Container>
  );
};

export default SettlementButton;
