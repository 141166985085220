import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Column from 'components/common/Column';
import Text from 'components/common/Text';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import { MeData } from 'graphQL/Auth/types';
import { ICON_PROFILE_CAMERA, ICON_PROFILE_DEFAULT } from 'assets/icons';
import InputForm from 'containers/Login/components/InputForm';
import { useMutationUpdateHostProfile } from '../graphQL/hooks/useMutationUpdateHostProfile';
import {
  callWebviewHandler,
  isWebview,
  WebviewOperatorType,
} from 'utils/webview';
import { useMutationGenerateContentUploadInfo } from 'graphQL/Image/useMutationGenerateContentUploadInfo';
import { useMutationChageHostProfileImage } from '../graphQL/hooks/useMutationChangeHostProfileImage';
import AuthButton from 'components/Button/AuthButton';
import Row from 'components/common/Row';
import { useGetMe } from 'graphQL/Auth/user/useGetMe';
import HackleManager from 'utils/hackle';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 0 20px;
  width: 100%;
`;

const ThumbnailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 2px;

  input {
    position: absolute;
    display: none;
    border: 1px solid red;
    width: 100%;
    height: 100%;
  }
`;

const ImageThumbnail = styled.img`
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 41.143px;
  border: 1.143px solid rgba(0, 0, 0, 0.2);
`;

const ImageUpdateContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 5px;
  width: 26px;
  height: 26px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #eaebec;
`;

const Textarea = styled.textarea`
  display: flex;
  padding: 16px;
  width: 100%;

  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;

  color: #1b1c1e;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.6px;

  &::placeholder {
    color: #c2c4c8;
  }
`;

const FloatingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  padding: 16px 20px 24px 20px;
  margin-top: auto;

  width: 100%;
  background-color: #fff;
  border-radius: 0 0 16px 16px;
  border-top: 1px solid #dbdcdf;
`;

interface ProfileUpdateContainerIProps {
  isMobile: boolean;
  type?: string;
  onClose?: () => void;
}

const ProfileUpdateContainer: React.FC<
  ProfileUpdateContainerIProps
> = props => {
  const { isMobile, type, onClose } = props;

  const myInfo: MeData = useUserInformation((state: any) => state.userInfo);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const {
    hostBaseParams,
    handleUpdateHostBaseParams,
    handleUpdateHostProfile,
  } = useMutationUpdateHostProfile();
  const { handleGenerateContentUpload } =
    useMutationGenerateContentUploadInfo();
  const { handleChangeHostProfileImage } = useMutationChageHostProfileImage();

  const [imageThumbnail, setImageThumbnail] = useState<string>(
    myInfo.me.host.profileImage
      ? myInfo.me.host.profileImage.thumbnail
      : ICON_PROFILE_DEFAULT,
  );
  const [contentId, setContentId] = useState<string>('');

  const handleSetHostName = (key: string, value: any) => {
    handleUpdateHostBaseParams('param', {
      ...hostBaseParams.param,
      [key]: value,
    });
  };

  const handleFileChange = (event: any) => {
    handleGenerateContentUpload(
      {
        file: event.target.files[0],
        fileName: event.target.files[0].name,
      },
      (url: string, contentId: string) => {
        setImageThumbnail(url);
        setContentId(contentId);
      },
    );
  };

  const handleImageUploadClick = () => {
    if (fileInputRef.current) {
      if (isWebview()) {
        callWebviewHandler(
          WebviewOperatorType.UpdateMessageImageFileHandler,
          undefined,
          'handleImageUpload',
        );
      } else {
        fileInputRef.current.click();
      }
    }
  };

  const handleImageUpload = (link: { link: string }) => {
    const result = JSON.parse(link.link);

    setImageThumbnail(result.url);
    setContentId(result.contentId);
  };

  useEffect(() => {
    window.handleImageUpload = handleImageUpload;
  }, []);

  const renderProfileImage = (): JSX.Element => {
    return (
      <Column gap="24px">
        <Column gap="6px" align="flex-start">
          <Text color="#1b1c1e" size="16px">
            프로필 사진
          </Text>
          <Text color="#989ba2" weight={400}>
            크루(회원)가 호스트님을 더 잘 이해할 수 있도록, 호스트님을 잘
            나타내는 이미지를 등록해 주세요.
          </Text>
        </Column>
        <ThumbnailContainer
          onClick={() => {
            handleImageUploadClick();
          }}
        >
          <ImageThumbnail src={imageThumbnail} alt="profile thumbnail" />
          <ImageUpdateContainer>
            <img src={ICON_PROFILE_CAMERA} alt="profile camera" />
          </ImageUpdateContainer>
          <input
            ref={fileInputRef}
            type="file"
            multiple={false}
            onChange={handleFileChange}
            accept=".jpg, .png, .jpeg"
          />
        </ThumbnailContainer>
      </Column>
    );
  };

  const renderHostName = (): JSX.Element => {
    return (
      <Column gap="12px">
        <Column gap="6px" align="flex-start">
          <Text color="#1b1c1e" size="16px">
            호스트 명
          </Text>
          <Text color="#989ba2" weight={400}>
            프립에서 활동하게 될 이름이에요.
          </Text>
        </Column>
        <InputForm
          type="text"
          styleType="large"
          label="호스트명"
          target="name"
          inputValue={hostBaseParams.param.name}
          onChange={handleSetHostName}
        />
      </Column>
    );
  };

  const renderHostIntroduce = (): JSX.Element => {
    return (
      <Column gap="12px">
        <Column gap="6px" align="flex-start">
          <Text color="#1b1c1e" size="16px">
            호스트 소개
          </Text>
          <Text color="#989ba2" weight={400}>
            호스트님만의 개성을 담거나 신뢰감을 줄 수 있는 전문적인 정보를
            입력해 주세요.
          </Text>
        </Column>
        <Textarea
          placeholder="호스트 소개글을 입력해 주세요"
          value={hostBaseParams.param.description}
          onChange={(e: any) =>
            handleUpdateHostBaseParams('param', {
              ...hostBaseParams.param,
              description: e.target.value,
            })
          }
        />
      </Column>
    );
  };

  return (
    <Column gap="32px" style={{ paddingBottom: '90px' }}>
      <Container>
        {renderProfileImage()}
        {renderHostName()}
        {renderHostIntroduce()}
      </Container>
      {isMobile ? (
        <FloatingContainer>
          <Text
            color="#1b1c1e"
            size="16px"
            style={{ textDecoration: 'underline' }}
            onClick={() => {
              onClose && onClose();
            }}
          >
            닫기
          </Text>
          <AuthButton
            name={type ? '저장' : '확인'}
            isAbled={true}
            onClick={() => {
              handleUpdateHostProfile(() => {
                HackleManager.logProfileUpdateComplete();
              });

              if (contentId !== '') {
                handleChangeHostProfileImage(myInfo.me.host.id, contentId);
              }

              onClose && onClose();
            }}
            style={{ width: 'auto', minWidth: '76px' }}
          />
        </FloatingContainer>
      ) : (
        <Row
          justifyContent="flex-end"
          style={{ padding: '0 20px', width: '100%' }}
        >
          <AuthButton
            isAbled={true}
            name="저장"
            onClick={() => {
              handleUpdateHostProfile(() => {
                HackleManager.logProfileUpdateComplete();
              });
              if (contentId !== '') {
                handleChangeHostProfileImage(myInfo.me.host.id, contentId);
              }

              onClose && onClose();
            }}
            style={{ width: '80px' }}
          />
        </Row>
      )}
    </Column>
  );
};

export default ProfileUpdateContainer;
