import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { styled } from 'styled-components';
import Calendar from 'react-calendar';
import dayjs from 'dayjs';
import { Value, View } from 'react-calendar/dist/cjs/shared/types';

import 'react-calendar/dist/Calendar.css';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import Column from 'components/common/Column';
import { ScheduleParam } from 'containers/ProductRegist/hooks/types';
import { OptionSheetType } from 'containers/ProductRegist/components/OptionSelectRegist/hooks/useCalenderHooks';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;

  .react-calendar {
    width: 100%;
    border: none;

    .react-calendar__navigation {
      display: none;
    }

    .react-calendar__month-view__weekdays {
      display: none !important;

      :first-child {
        /* display: flex !important; */
      }
    }

    .react-calendar__month-view__days {
      display: flex;
      align-items: flex-start;
    }

    .react-calendar__tile--active {
      background-color: #f7f7f8;
    }

    .react-calendar__tile {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 16px;
      padding: 8px 16px 52px 16px;
      width: 54px;
      min-height: 81px;
      background-color: #f7f7f8;
      color: #1b1c1e;
      border: 0.5px solid #eaebec;

      .selected {
        .react-calendar__tile {
          color: #fff !important;
        }
      }

      &:disabled {
        background-color: #eaebec;
        color: #c2c4c8 !important;
        text-decoration: line-through;
      }

      &:enabled:focus {
        /* color: #fff; */
      }

      &:focus {
        background-color: #f7f7f8;
      }
    }
  }

  .react-calendar__month-view__weekdays__weekday--weekend abbr[title='일요일'] {
    color: #ff4242;
  }

  .react-calendar__month-view__weekdays__weekday--weekend abbr[title='토요일'] {
    color: #0071e9;
  }

  .react-calendar__tile .selected {
    color: #fff !important;
  }

  .selected {
    abbr {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px !important;
      height: 20px !important;
      color: #fff !important;
      border-radius: 50%;
      background-color: #1b1c1e !important;
      font-size: 0.833em !important;
    }

    background-color: #f7f7f8 !important;
    /* color: #fff !important; */
  }

  .sunday {
    color: #ff4242 !important;
  }

  .saturday {
    color: #0071e9 !important;
  }
`;

const ScheduleCardBox = styled.div<{ $isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 6px;
  width: 100%;
  min-width: 40px;
  border-radius: 2px;
  border: 1px solid ${props => (props.$isSelected ? '#000' : '#7126ff')};
  background: ${props => (props.$isSelected ? '#1b1c1e' : '#beaaf1')};

  color: ${props => (props.$isSelected ? '#fff' : '#4b0ebe')};
  text-align: center;
  font-family: Pretendard;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.2px;
`;

interface CustomCalendarIProps {
  calendarList?: ScheduleParam[];
  isClearCalendarData?: boolean;
  checkedDate?: any[];
  setCheckedDate?: Dispatch<SetStateAction<any[]>>;
  handleInitScheduleItem?: () => void;
  onChange: (value: Value) => void;
  handleIsOpenUpdateOptionSheet?: (param: {
    isOpen: boolean;
    sheetType: OptionSheetType;
    scheduleItem?: ScheduleParam;
  }) => void;
}

const CustomCalendar: React.FC<CustomCalendarIProps> = props => {
  const {
    calendarList,
    isClearCalendarData,
    checkedDate,
    setCheckedDate,
    handleInitScheduleItem,
    onChange,
    handleIsOpenUpdateOptionSheet,
  } = props;

  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [selectedItem, setSeletedItem] = useState<number>();

  const monthsToShow = 4;
  const currentMonth = new Date();

  const today = new Date();

  // 90일 후의 날짜
  const ninetyDaysLater = new Date();
  ninetyDaysLater.setDate(today.getDate() + 90);

  const handleDateClick = (
    date: Date,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    handleIsOpenUpdateOptionSheet &&
      handleIsOpenUpdateOptionSheet({
        isOpen: true,
        sheetType: OptionSheetType.CREATE,
      });

    const isSelected = selectedDates.some(selectedDate =>
      dayjs(selectedDate).isSame(dayjs(date), 'day'),
    );

    if (isSelected) {
      const filteredDates = selectedDates.filter(selectedDate => {
        return !dayjs(selectedDate).isSame(dayjs(date), 'day');
      });

      setSelectedDates(filteredDates);
    } else {
      handleInitScheduleItem && handleInitScheduleItem();
      setSeletedItem(0);
      setSelectedDates([...selectedDates, date]);
    }
  };

  useEffect(() => {
    if (isClearCalendarData) {
      setSelectedDates([]);
    }
  }, [isClearCalendarData]);

  useEffect(() => {
    if (checkedDate) {
      const formatDate = checkedDate.map(item => new Date(item));

      setSelectedDates(formatDate);
    }
  }, [checkedDate]);

  const renderTileContent = ({ date, view }: { date: Date; view: View }) => {
    if (view === 'month') {
      const formattedDate = dayjs(date).format('YYYY-MM-DD');
      const item = calendarList?.map(item =>
        dayjs(item.term.startedAt).format('YYYY-MM-DD'),
      );

      if (item?.includes(formattedDate)) {
        const filterItem = calendarList?.filter(
          item =>
            dayjs(item.term.startedAt).format('YYYY-MM-DD') === formattedDate,
        );

        return (
          <Column gap="6px">
            {filterItem &&
              filterItem.map((item, idx) => {
                const isSelected =
                  selectedItem === new Date(item.term.startedAt).getTime();

                return (
                  <ScheduleCardBox
                    $isSelected={isSelected}
                    key={idx}
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();

                      setCheckedDate && setCheckedDate([]);
                      if (isSelected) {
                        setSeletedItem(0);
                        handleIsOpenUpdateOptionSheet &&
                          handleIsOpenUpdateOptionSheet({
                            isOpen: false,
                            sheetType: OptionSheetType.UPDATE,
                          });

                        return;
                      }

                      setSeletedItem(new Date(item.term.startedAt).getTime());

                      handleIsOpenUpdateOptionSheet &&
                        handleIsOpenUpdateOptionSheet({
                          isOpen: true,
                          sheetType: OptionSheetType.UPDATE,
                          scheduleItem: item,
                        });
                    }}
                  >
                    <span>등록중</span>
                    <span>{item.title.split(' ')[3]}</span>
                  </ScheduleCardBox>
                );
              })}
          </Column>
        );
      }
    }

    return null;
  };

  const tileClassName = ({
    date,
    view,
  }: {
    date: Date;
    view: View;
  }): string | null => {
    if (view === 'month') {
      let classNames = '';

      // 선택된 날짜에 대한 스타일
      if (
        selectedDates.some(selectedDate =>
          dayjs(selectedDate).isSame(dayjs(date), 'day'),
        )
      ) {
        classNames += 'selected ';
      }

      // 요일별 스타일
      if (date.getDay() === 0) {
        classNames += 'sunday';
      } else if (date.getDay() === 6) {
        classNames += 'saturday';
      } else {
        classNames += 'weekday';
      }

      return classNames.trim(); // 공백 제거
    }

    return null;
  };

  return (
    <Container>
      <Row
        gap="38px"
        justifyContent="space-around"
        style={{
          padding: '0 20px 12px 10px',
          width: '100%',
          background: '#fff',
          borderBottom: '1px solid #eaebec',
        }}
      >
        <Text color="#FF4242">일</Text>
        <Text color="#46474C">월</Text>
        <Text color="#46474C">화</Text>
        <Text color="#46474C">수</Text>
        <Text color="#46474C">목</Text>
        <Text color="#46474C">금</Text>
        <Text color="#0071e9">토</Text>
      </Row>
      {Array.from({ length: monthsToShow }).map((_, index) => {
        const date = new Date(
          currentMonth.getFullYear(),
          currentMonth.getMonth() + index,
          1,
        );

        return (
          <Column key={index}>
            <Column key={index} gap="24px" align="flex-start">
              <Text
                color="#1b1c1e"
                size="16px"
                weight={600}
                style={{ padding: '0 20px' }}
              >{`${date.getFullYear()}년 ${date.getMonth() + 1}월`}</Text>
              <Calendar
                // value={value}
                activeStartDate={date}
                showNeighboringMonth={false}
                calendarType="gregory"
                minDate={today}
                maxDate={ninetyDaysLater}
                tileContent={renderTileContent}
                tileClassName={tileClassName}
                formatDay={(locale, date) => date.getDate().toString()}
                onChange={(value: Value) => {
                  onChange(value);
                }}
                onClickDay={handleDateClick}
              />
            </Column>
          </Column>
        );
      })}
    </Container>
  );
};

export default CustomCalendar;
