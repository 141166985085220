import { ICON_ARROW_BLACK } from "assets/icons";
import Column from "components/common/Column";
import Row from "components/common/Row";
import Text from "components/common/Text";
import React from "react";
import { styled } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #eaebec;
  background-color: #fff;
`;

const SettlementFAQ = () => {
  return (
    <Container>
      <Text color="#1b1c1e" size="16px" weight={600}>
        도움이 필요하세요?
      </Text>
      <Column gap="8px">
        <Row
          gap="12px"
          align="flex-start"
          justifyContent="space-between"
          style={{ width: "100%" }}
        >
          <Text
            color="#1b1c1e"
            size="16px"
            weight={400}
            style={{ lineHeight: "24px" }}
          >
            <a
              href="https://frip.notion.site/b40e11763f9249688e6f28de5454219e"
              target="_blank"
              style={{ fontSize: "15px" }}
            >
              정산 정보 등록 방법
            </a>
          </Text>
          <img src={ICON_ARROW_BLACK} alt="arrow icon" />
        </Row>
        <Row
          gap="12px"
          align="flex-start"
          justifyContent="space-between"
          style={{ width: "100%" }}
        >
          <Text
            color="#1b1c1e"
            size="16px"
            weight={400}
            style={{ lineHeight: "24px" }}
          >
            <a
              href="https://frip.notion.site/9b183479bf5f4c178668f517f8625d76"
              target="_blank"
              style={{ fontSize: "15px" }}
            >
              정산 정보 변경 방법
            </a>
          </Text>
          <img src={ICON_ARROW_BLACK} alt="arrow icon" />
        </Row>
        <Row
          gap="12px"
          align="flex-start"
          justifyContent="space-between"
          style={{ width: "100%" }}
        >
          <Text
            color="#1b1c1e"
            size="16px"
            weight={400}
            style={{ lineHeight: "24px" }}
          >
            <a
              href="https://frip.notion.site/521b0cdc272f4e4090642d9324a0d9df"
              target="_blank"
              style={{ fontSize: "15px" }}
            >
              정산 내역 확인 및 정산 요청 방법
            </a>
          </Text>
          <img src={ICON_ARROW_BLACK} alt="arrow icon" />
        </Row>
      </Column>
    </Container>
  );
};

export default SettlementFAQ;
