import { useLazyQuery, useQuery } from '@apollo/client';
import { MeData } from 'graphQL/Auth/types';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { OrderConnection } from 'types/api';
import { errorHandler } from 'utils/ErrorHandler';
import { GET_ORDERS } from '..';
import { OrderFilterInput } from '../types';

export function useQueryGetOrders() {
  const location = useLocation();

  const myInfo: MeData = useUserInformation(state => state.userInfo);

  const [getOrderItems, { data, loading, refetch }] = useLazyQuery<{
    order: { orders: OrderConnection };
  }>(GET_ORDERS);
  const [orderItems, setOrderItems] = useState<OrderConnection>();
  const [isLoading, setIsLoading] = useState<boolean>(loading);

  const handleRefetchOrderItems = async (param: OrderFilterInput) => {
    try {
      const response = await refetch(param);

      if (response) {
        setOrderItems(response.data.order.orders);
        setIsLoading(false);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (data) {
      setOrderItems(data.order.orders);
    }
  }, [data]);

  useEffect(() => {
    if (myInfo.me) {
      getOrderItems({
        variables: {
          page: 1,
          size: 10,
          filter: {
            hostIds: myInfo.me.host?.id,
            productIds: location.state ? [location.state.productId] : null,
            productTitleLike: null,
            customerIds: null,
            itemNameLike: null,
            itemIds: null,
            emailLike: null,
            nameLike: null,
            phoneLike: null,
          },
        },
      });
    }
  }, [myInfo]);

  return { orderItems, isLoading, setIsLoading, handleRefetchOrderItems };
}
