import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';

import Column from 'components/common/Column';
import Text from 'components/common/Text';
import Row from 'components/common/Row';
import OptionButton from 'containers/ProductRegist/components/common/OptionButton';
import { ModifyValue } from 'containers/ProductDetail/hooks/useProductDetaillHooks';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import AuthButton from 'components/Button/AuthButton';
import SaveButton from '../SaveButton';
import { Product, ProductState } from 'types/api';
import HackleManager from 'utils/hackle';

const OptionSettingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
  width: 100%;

  border-radius: 12px;
  border: 1px solid #dbdcdf;
  background: #f7f7f8;
`;

const OptionInput = styled.input`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 27px 8px 12px;
  width: 100%;

  text-align: right;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    justify-content: flex-end;
  }

  &:focus {
    border: 1.5px solid #1b1c1e;
  }
`;

interface OnceLimitModifyFormIProps {
  isMobile: boolean;
  grossPurchaseLimitation: { count: number };
  productInfo: Product;
  modifyValue: ModifyValue;
  setModifyValue: Dispatch<SetStateAction<ModifyValue>>;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
}

const OnceLimitModifyForm: React.FC<OnceLimitModifyFormIProps> = props => {
  const {
    isMobile,
    grossPurchaseLimitation,
    productInfo,
    modifyValue,
    setModifyValue,
    handleOnChangeProductParamInput,
  } = props;

  const [grossPurchaseCount, setGrossPurchaseCount] = useState<number>(
    grossPurchaseLimitation.count,
  );

  const isAbled = grossPurchaseCount !== grossPurchaseLimitation.count;

  const [isGrossPurchaseLimitation, setIsGrossPurchaseLimitation] =
    useState<boolean>(grossPurchaseLimitation.count > 0);

  const handleResetGrossPurchaseCount = () => {
    setGrossPurchaseCount(grossPurchaseLimitation.count);
  };

  const handleRegistGrossPurchaseCount = () => {
    handleOnChangeProductParamInput('grossPurchaseLimitation', {
      count: grossPurchaseCount,
    });
  };

  useEffect(() => {
    HackleManager.logProductModifySalesLimitView(
      Number(productInfo.id),
      productInfo.title,
    );
  }, []);

  const renderGrossPurchase = (): JSX.Element => {
    return (
      <OptionSettingBox>
        <Column gap="12px" align="flex-start">
          <Text color="#1b1c1e" size="16px">
            1인당 재주문 한도
          </Text>
          <Row gap="12px" style={{ width: '100%' }}>
            <OptionButton
              text="제한하지않음"
              isSelected={!isGrossPurchaseLimitation}
              onClick={() => {
                setIsGrossPurchaseLimitation(false);
              }}
            />
            <OptionButton
              text="제한"
              isSelected={isGrossPurchaseLimitation}
              onClick={() => {
                setIsGrossPurchaseLimitation(true);
              }}
            />
          </Row>
        </Column>
        {isGrossPurchaseLimitation && (
          <Row style={{ position: 'relative', width: '100%' }}>
            <Text
              color="#1b1c1e"
              size="14px"
              weight={400}
              style={{ position: 'absolute', left: '12px' }}
            >
              최대
            </Text>
            <OptionInput
              placeholder="0"
              type="number"
              value={grossPurchaseCount}
              onChange={(e: any) => {
                setGrossPurchaseCount(Number(e.target.value));
              }}
            />
            <Text
              color="#1b1c1e"
              size="14px"
              weight={400}
              style={{ position: 'absolute', right: '12px' }}
            >
              개
            </Text>
          </Row>
        )}
      </OptionSettingBox>
    );
  };

  const renderContents = (): JSX.Element => {
    if (isMobile) {
      return (
        <ModalContainer
          isAbled={productInfo.status !== ProductState.CLOSED && isAbled}
          isMobile={isMobile}
          onAcceptText="적용"
          onClose={() => {
            setModifyValue({ ...modifyValue, modifyStatus: '' });
          }}
          onClick={() => {
            HackleManager.logProductModifyItemSaveComplete(
              Number(productInfo.id),
              productInfo.title,
              '1인당 재주문 한도',
            );

            handleRegistGrossPurchaseCount();
            setModifyValue({
              ...modifyValue,
              isModify: true,
              modifyStatus: '',
            });
          }}
        >
          <Column gap="24px" style={{ padding: '16px 20px 0' }}>
            <Row>
              <Text color="#000" size="16px" weight={600}>
                1인당 재주문 한도
              </Text>
            </Row>
            {renderGrossPurchase()}
          </Column>
        </ModalContainer>
      );
    }

    return (
      <Column>
        <Row
          justifyContent="flex-start"
          style={{
            position: 'sticky',
            top: 0,
            padding: '32px 40px',
            width: '100%',
            background: '#fff',
            borderBottom: '1px solid #eaebec',
          }}
        >
          <Text color="#1B1C1E" size="24px">
            1인당 재주문 한도 수정하기
          </Text>
        </Row>
        <Column gap="24px" style={{ padding: '32px 40px' }}>
          {renderGrossPurchase()}
          {productInfo.status !== ProductState.CLOSED && (
            <SaveButton
              isAbled={isAbled}
              modifyValue={modifyValue}
              setModifyValue={setModifyValue}
              handleResetItem={handleResetGrossPurchaseCount}
              handleRegistItem={() => {
                HackleManager.logProductModifyItemSaveComplete(
                  Number(productInfo.id),
                  productInfo.title,
                  '1인당 재주문 한도',
                );
                handleRegistGrossPurchaseCount();
              }}
            />
          )}
        </Column>
      </Column>
    );
  };

  return renderContents();
};

export default OnceLimitModifyForm;
