import React from 'react';
import ModalContainer from '../../common/ModalContainer';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import { ICON_CLOSE } from 'assets/icons';
import OptionModalItems from './OptionModalItems';
import Column from 'components/common/Column';
import { Items, ScheduleParam } from 'containers/ProductRegist/hooks/types';

interface OptionModalIProps {
  isMobile: boolean;
  items: Items[];
  scheduleItem: ScheduleParam;
  selectedItems: Items[];
  setScheduleItem: React.Dispatch<React.SetStateAction<ScheduleParam>>;
  setSelectedItems: React.Dispatch<React.SetStateAction<Items[]>>;
  handleChangeScheduleItem: (key: string, value: any) => void;
  onClose: () => void;
}

const OptionModal: React.FC<OptionModalIProps> = props => {
  const {
    isMobile,
    items,
    scheduleItem,
    selectedItems,
    setScheduleItem,
    setSelectedItems,
    handleChangeScheduleItem,
    onClose,
  } = props;

  return (
    <ModalContainer
      isMobile={isMobile}
      isAbled={
        scheduleItem.appliedAllItems || scheduleItem.itemParamIds.length !== 0
      }
      onClose={onClose}
      onClick={onClose}
      onAcceptText="옵션 선택"
      style={{ maxHeight: '600px', gap: '16px' }}
      modalStyle={{ zIndex: 25 }}
    >
      <Row
        style={{
          position: 'sticky',
          top: 0,
          padding: '16px 20px',
          background: '#fff',
          zIndex: 5,
        }}
      >
        <Row
          style={{
            position: 'relative',
            width: '100%',
          }}
        >
          <img
            src={ICON_CLOSE}
            alt="close icon"
            style={{ position: 'absolute', left: 0 }}
            onClick={onClose}
          />
          <Text color="#1b1c1e" size="16px" weight={600}>
            옵션 선택
          </Text>
        </Row>
      </Row>
      <Column gap="16px" style={{ padding: '0 20px' }}>
        <Row justifyContent="space-between" style={{ width: '100%' }}>
          <Text color="#1B1C1E" size="14px">
            {scheduleItem.appliedAllItems
              ? items.length
              : scheduleItem.itemParamIds.length}
            개 선택됨
          </Text>
          <Text
            color="#989BA2"
            size="14px"
            onClick={() => {
              if (scheduleItem.appliedAllItems) {
                handleChangeScheduleItem('appliedAllItems', false);

                return;
              }

              if (!scheduleItem.appliedAllItems) {
                setScheduleItem({
                  ...scheduleItem,
                  appliedAllItems: true,
                  itemParamIds: [],
                });

                return;
              }
            }}
          >
            {scheduleItem.appliedAllItems ? '전체선택 취소' : '전체선택'}
          </Text>
        </Row>
        {items.map((item: Items, idx: number) => {
          return (
            <React.Fragment key={idx}>
              <OptionModalItems
                item={item}
                idx={idx}
                scheduleItem={scheduleItem}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                handleChangeScheduleItem={handleChangeScheduleItem}
              />
            </React.Fragment>
          );
        })}
      </Column>
    </ModalContainer>
  );
};

export default OptionModal;
