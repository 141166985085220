import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  bottom: 0;
  padding: 16px 20px;
  width: 100%;
  max-width: 768px;

  border-top: 1px solid #dbdcdf;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 100%;

  border: none;
  border-radius: 8px;
  background: #7126ff;
  color: var(--00, #fff);

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.6px;
`;

interface FloatingButtonIProps {
  handleIsOpenSignupHostToast: () => void;
}

const FloatingButton: React.FC<FloatingButtonIProps> = props => {
  const { handleIsOpenSignupHostToast } = props;

  return (
    <Container>
      <Button onClick={handleIsOpenSignupHostToast}>호스트 시작하기</Button>
    </Container>
  );
};

export default FloatingButton;
