import React from 'react';
import styled from 'styled-components';
import { NoticeType } from '../graphQL/types';

const Container = styled.div<{
  color: string;
  background: string;
  border: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 9px;

  color: ${(props) => props.color};
  text-align: center;
  font-family: Pretendard;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 140% */
  letter-spacing: -0.2px;

  border-radius: 14px;
  border: 1px solid ${(props) => props.border};
  background: ${(props) => props.background};
`;

interface NoticeStatusButtonIProps {
  status: NoticeType;
}

const NoticeStatusButton: React.FC<NoticeStatusButtonIProps> = (props) => {
  const { status } = props;

  const renderStatusButton = (): JSX.Element => {
    switch (status) {
      case NoticeType.HOST_NOTICE: {
        return (
          <Container background="#E2D3FE" color="#7126FF" border="#7126FF">
            공지
          </Container>
        );
      }
      case NoticeType.HOST_EVENT: {
        return (
          <Container background="#DFF1DC" color="#32A06E" border="#32A06E">
            이벤트
          </Container>
        );
      }
      case NoticeType.HOST_COMMON: {
        return (
          <Container background="#74B0F9" color="#0071E9" border="#0071E9">
            일반
          </Container>
        );
      }
      default:
        return (
          <Container background="#EAEBEC" color="#989BA2" border="#DBDCDF">
            일반
          </Container>
        );
    }
  };

  return renderStatusButton();
};

export default NoticeStatusButton;
