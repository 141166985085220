import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { InvoiceState } from '../graphQL/types';

const Container = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 4px 8px;

  border-radius: 9999px;
  background: #f4f4f5;

  color: ${props => props.color};

  font-family: Pretendard;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.6px;
`;

interface SettlementBadgeIProps {
  status: InvoiceState | null;
}

const SettlementBadge: React.FC<SettlementBadgeIProps> = props => {
  const { status } = props;

  const [badgeInformation, setBadgeInformation] = useState<{
    text: string;
    color: string;
  }>({ text: '', color: '' });

  const handleSetColor = () => {
    switch (status) {
      case InvoiceState.READY: {
        return setBadgeInformation({ text: '지급 대기', color: '#0071E9' });
      }
      case InvoiceState.COMPLETED: {
        return setBadgeInformation({ text: '지급 완료', color: '#32A06E' });
      }
      default: {
        return setBadgeInformation({ text: '미지급', color: '#70737C' });
      }
    }
  };

  useEffect(() => {
    handleSetColor();
  }, [status]);

  return (
    <Container color={badgeInformation.color}>
      {badgeInformation.text}
    </Container>
  );
};

export default SettlementBadge;
