import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import Column from 'components/common/Column';
import Text from 'components/common/Text';
import Row from 'components/common/Row';
import AuthButton from 'components/Button/AuthButton';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import { ModifyValue } from 'containers/ProductDetail/hooks/useProductDetaillHooks';
import SaveButton from '../SaveButton';
import { Product, ProductState } from 'types/api';
import HackleManager from 'utils/hackle';

const TextArea = styled.textarea`
  padding: 16px 16px 32px 16px;
  width: 100%;
  height: 160px;
  color: var(--gray-gray-09, #1b1c1e);

  border-radius: 8px;
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);

  /* Body/B-16-Regular */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.6px;

  &::placeholder {
    color: #c2c4c8;
  }

  &:focus {
    border: 1.5px solid #1b1c1e;
  }

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

interface PrecautionModifyFormIProps {
  isMobile: boolean;
  notice: string;
  productInfo: Product;
  modifyValue: ModifyValue;
  setModifyValue: Dispatch<SetStateAction<ModifyValue>>;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
}

const PrecautionModifyForm: React.FC<PrecautionModifyFormIProps> = props => {
  const {
    isMobile,
    notice,
    productInfo,
    modifyValue,
    setModifyValue,
    handleOnChangeProductParamInput,
  } = props;

  const [value, setValue] = useState<string>(notice);
  const isAbled = value !== notice;

  const handleRegistNotice = () => {
    handleOnChangeProductParamInput('notice', value);
  };

  const handleResetNotice = () => {
    setValue(notice);
  };

  useEffect(() => {
    HackleManager.logProductModifyNoticeView(
      Number(productInfo.id),
      productInfo.title,
    );
  }, []);

  const renderNoticeForm = (): JSX.Element => {
    return (
      <Column gap="12px" align="flex-start">
        <Text color="#0071E9" weight={400}>
          크루에게 보여질 유의사항입니다. 확인 후 적절히 수정해 주세요.
        </Text>
        <TextArea
          value={value}
          onChange={(e: any) => setValue(e.target.value)}
        />
      </Column>
    );
  };

  const renderContents = (): JSX.Element => {
    if (isMobile) {
      return (
        <ModalContainer
          isMobile={isMobile}
          isAbled={productInfo.status !== ProductState.CLOSED && isAbled}
          onClose={() => {
            handleResetNotice();

            setModifyValue({ ...modifyValue, modifyStatus: '' });
          }}
          onCloseText="닫기"
          onAcceptText="적용"
          onClick={() => {
            if (isAbled) {
              HackleManager.logProductModifyItemSaveComplete(
                Number(productInfo.id),
                productInfo.title,
                '유의사항',
              );

              handleRegistNotice();

              setModifyValue({
                ...modifyValue,
                isModify: true,
                modifyStatus: '',
              });
            }
          }}
        >
          <Column gap="24px" style={{ padding: '16px 20px 0' }}>
            <Row>
              <Text color="#000" size="16px" weight={600}>
                유의사항
              </Text>
            </Row>
            {renderNoticeForm()}
          </Column>
        </ModalContainer>
      );
    }

    return (
      <Column>
        <Row
          justifyContent="flex-start"
          style={{
            padding: '32px 40px',
            width: '100%',
            background: '#fff',
            borderBottom: '1px solid #eaebec',
          }}
        >
          <Text color="#1B1C1E" size="24px">
            유의사항 수정하기
          </Text>
        </Row>
        <Column gap="24px" style={{ padding: '32px 40px' }}>
          {renderNoticeForm()}
          {productInfo.status !== ProductState.CLOSED && (
            <SaveButton
              isAbled={isAbled}
              modifyValue={modifyValue}
              setModifyValue={setModifyValue}
              handleResetItem={handleResetNotice}
              handleRegistItem={() => {
                HackleManager.logProductModifyItemSaveComplete(
                  Number(productInfo.id),
                  productInfo.title,
                  '유의사항',
                );
                handleRegistNotice();
              }}
            />
          )}
        </Column>
      </Column>
    );
  };

  return renderContents();
};

export default PrecautionModifyForm;
