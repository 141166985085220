import { gql } from '@apollo/client';

export const GET_PRODUCT_REVIEWS_QUERY = gql`
  query GetProductReviews(
    $page: Int
    $size: Int
    $filter: ProductReviewFilter
  ) {
    productReview {
      reviewsForHostAndAdmin(page: $page, size: $size, filter: $filter) {
        totalCount
        edges {
          node {
            id
            content
            rating
            status
            writer {
              id
              nickname
            }
            createdAt
            updatedAt
            reported
            target {
              id
              product {
                host {
                  id
                  name
                }
                scheduleFirstDate
                scheduleLastDate
                headerContents {
                  id
                  style
                  content {
                    id
                    thumbnail(
                      fetchFormat: AUTO
                      crop: FILL
                      devicePixelRatio: 2.0
                      width: 375
                      aspectRatio: "1:1"
                    )
                  }
                }
                id
                title
                status
              }
              orderItem {
                info {
                  name
                  product {
                    id
                    title
                  }
                  host {
                    id
                    name
                  }
                  options {
                    id
                    level
                    name
                  }
                }
                schedule {
                  term {
                    duration
                    startedAt
                  }
                }
                booking {
                  info {
                    origin {
                      product {
                        id
                        headerContents {
                          id
                          style
                          content {
                            id
                            thumbnail(
                              fetchFormat: AUTO
                              crop: FILL
                              devicePixelRatio: 2.0
                              width: 375
                              aspectRatio: "1:1"
                            )
                          }
                        }
                        createdAt
                        saleTerm {
                          endedAt
                        }
                      }
                    }
                  }
                }
              }
            }
            thumbsUpCount
            commentCount
            reportCount
            attachmentCount
            attachmentContents {
              id
              type
              width
              height
              uri
              thumbnail(fetchFormat: AUTO, crop: FILL, width: 100)
            }
            latestComments {
              totalCount
              pageInfo {
                hasNextPage
                endCursor
              }
              edges {
                cursor
                node {
                  id
                  content
                  status
                  writer {
                    id
                    nickname
                  }
                  host {
                    id
                    name
                  }
                  createdAt
                  updatedAt
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const WRITE_COMMENT = gql`
  mutation WriteComment($param: ProductReviewCommentWritingParam) {
    writeProductReviewComment(param: $param) {
      id
      content
    }
  }
`;

export const MODIFY_COMMENT = gql`
  mutation ModifyComment($param: ProductReviewCommentModifyingParam) {
    modifyProductReviewComment(param: $param) {
      id
      content
    }
  }
`;

export const DELETE_COMMENT = gql`
  mutation DeleteProductReviewComment($id: ID!) {
    deleteProductReviewComment(id: $id) {
      success
      message
    }
  }
`;

export const GET_PRODUCT_REVIEW_REPORTTYPE = gql`
  query GetProductReviewReportTypes($filter: ProductReviewReportingTypeFilter) {
    productReview {
      reportTypes(filter: $filter) {
        id
        name
        description
      }
    }
  }
`;

export const REPORT_PRODUCT_REVIEW = gql`
  mutation ReportProductReview($param: ProductReviewReportingParam) {
    reportProductReview(param: $param) {
      id
      reason
      status
      createdAt
      __typename
    }
  }
`;
