import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { flatten, isEmpty } from 'lodash';

import {
  BookingItem,
  BookingState,
  TransactionState,
  TransactionType,
} from 'types/api';
import HackleManager from 'utils/hackle';
import { useQueryGetOrder } from '../graphQL/hooks/useQueryGetOrder';

export interface CancelItems {
  request: {
    indexNo: number[];
    transactionId: string[];
    orderId: string[];
  };
  complete: number[];
}

export const useSaleDetailHooks = (id?: string) => {
  const params = useParams<{ id: string }>();
  const {
    ordersheet,
    selectedTicket,
    setSelectedTicket,
    handleRefetchOrderItem,
  } = useQueryGetOrder(id);

  // const [selectedTicket, setSelectedTicket] = useState<BookingItem[]>([]);
  const [cancelItems, setCancelItems] = useState<CancelItems>({
    request: { indexNo: [], transactionId: [], orderId: [] },
    complete: [],
  });
  const [isOpenSelectedBottomSheet, setIsOpenSelectedBottomSheet] =
    useState<boolean>(false);

  const handleSetSelectedTicket = (bookingItem: BookingItem) => {
    setIsOpenSelectedBottomSheet(true);

    if (selectedTicket.find(item => item.id === bookingItem.id)) {
      setSelectedTicket(
        selectedTicket.filter(filterItem => filterItem.id !== bookingItem.id),
      );

      return;
    }

    setSelectedTicket([...selectedTicket, bookingItem]);
  };

  const handleAllSelectedTicket = () => {
    const selectItems = flatten(
      ordersheet.items.map(orderItem =>
        orderItem.booking.items.filter(bookingItem => {
          return !(
            bookingItem.status === BookingState.CANCELED ||
            bookingItem.status === BookingState.ABSENT ||
            (!isEmpty(orderItem.dependedItems) &&
              cancelItems.request.indexNo.includes(bookingItem.indexNo))
          );
        }),
      ),
    );

    if (selectItems.length === selectedTicket.length) {
      setSelectedTicket([]);

      return;
    }

    setIsOpenSelectedBottomSheet(true);
    setSelectedTicket(selectItems);
  };

  const handleFindCancelItem = () => {
    if (ordersheet) {
      const result: { request: string[]; complete: string[] } = {
        request: [],
        complete: [],
      };
      const indexNoResult: {
        request: {
          indexNo: number[];
          transactionId: string[];
          orderId: string[];
        };
        complete: number[];
      } = {
        request: { indexNo: [], transactionId: [], orderId: [] },
        complete: [],
      };

      ordersheet.items.map(orderItem => {
        orderItem.dependedItems.filter(item => {
          if (
            item.transaction.status === TransactionState.REQUESTED &&
            item.transaction.type === TransactionType.CANCELLATION
          ) {
            result.request = [
              ...result.request,
              ...item.units.map(item => item.id),
            ];
            indexNoResult.request.transactionId.push(item.transaction.id);
          }

          if (
            item.transaction.status === TransactionState.COMPLETED &&
            item.transaction.type === TransactionType.CANCELLATION
          ) {
            result.complete = item.units.map(item => item.id);
          }

          return;
        });
      });

      ordersheet.items.map(orderItem => {
        orderItem.units.filter(item => {
          if (result.request.includes(item.id)) {
            indexNoResult.request.indexNo.push(item.indexNo);
            indexNoResult.request.orderId.push(orderItem.id);
          }

          if (result.complete.includes(item.id)) {
            indexNoResult.complete.push(item.indexNo);
          }
        });
      });

      setCancelItems(indexNoResult);
    }
  };

  useEffect(() => {
    if (ordersheet) {
      handleFindCancelItem();

      HackleManager.logSalesDetailView(
        id ? Number(id) : Number(params.id),
        ordersheet.customer.id,
        ordersheet.items[0].booking.tickets.length,
        ordersheet.totalAmount.value,
        ordersheet.items[0].booking.tickets.map(item => item.id),
        ordersheet.items[0].info.origin.product.frip.attachedToSchedule
          ? '일정 지정형'
          : '일정 조율형',
      );
    }
  }, [ordersheet]);

  return {
    ordersheet,
    selectedTicket,
    cancelItems,
    isOpenSelectedBottomSheet,
    setIsOpenSelectedBottomSheet,
    handleSetSelectedTicket,
    handleAllSelectedTicket,
    handleRefetchOrderItem,
  };
};
