import React, { useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import { useQueryGetProductReportType } from '../graphQL/hooks/useQueryGetProductReportType';
import Divider from 'components/Divider';
import { ProductReviewEdge } from 'types/api';
import { useMutationReportProductReview } from '../graphQL/hooks/useMutationReportProductReview';
import HackleManager from 'utils/hackle';

const OptionSelectBox = styled.div<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  min-width: 90px;

  border-radius: 99px;
  border: ${props =>
    props.$isSelected ? '1.5px solid #1b1c1e' : '1px solid #dbdcdf'};
  background: ${props => (props.$isSelected ? '#f7f7f8' : '#fff')};

  color: var(--gray-gray-09, #1b1c1e);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
  cursor: pointer;
`;

const ReviewContent = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  color: #1b1c1e;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
`;

const ReportReasonTextarea = styled.textarea`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 16px 16px 32px 16px;
  width: 100%;
  height: auto;
  max-height: 85px;
  overflow: auto;

  border-radius: 8px;
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--gray-gray-01, #fff);
`;

interface ReportModalIProps {
  isMobile: boolean;
  reportItem: ProductReviewEdge;
  onClose: () => void;
}

const ReportModal: React.FC<ReportModalIProps> = props => {
  const { isMobile, reportItem, onClose } = props;

  const { reportTypeList } = useQueryGetProductReportType();
  const { handleReportProductReview } = useMutationReportProductReview();

  const [reportParam, setReportParam] = useState<{
    typeId: number;
    reason: string;
  }>({ typeId: 1, reason: '' });

  return (
    <ModalContainer
      isAbled={true}
      isMobile={isMobile}
      onCloseText="닫기"
      onAcceptText="신고하기"
      onClose={onClose}
      onClick={() => {
        handleReportProductReview(
          reportItem.node.id,
          reportParam.typeId,
          reportParam.reason,
          () => {
            HackleManager.logReviewReportComplete(
              Number(reportItem.node.id),
              reportParam.reason,
            );
          },
        );
      }}
    >
      <Column gap="32px" style={{ padding: '16px 20px' }}>
        <Row style={{ position: 'relative', width: '100%' }}>
          <Text color="#000" size="16px" weight={600}>
            후기 신고하기
          </Text>
        </Row>
        <Column gap="20px">
          <Column gap="8px" align="flex-start">
            <Row justifyContent="space-between" style={{ width: '100%' }}>
              <Text color="#46474C">후기 내용</Text>
              <Text size="12px" weight={400}>
                {dayjs(reportItem.node.createdAt).format(
                  'YYYY년 MM월 DD일 HH:mm',
                )}
              </Text>
            </Row>
            <Column gap="4px" align="flex-start">
              <ReviewContent>{reportItem.node.content}</ReviewContent>
              <Text
                color="#46474c"
                size="14px"
                weight={400}
                style={{ textDecoration: 'underline' }}
              >
                후기 전체보기
              </Text>
            </Column>
          </Column>
        </Column>
        <Divider />
        <Column gap="12px" align="flex-start">
          <Text color="#000" size="16px" weight={600}>
            신고사유
          </Text>
          {reportTypeList && (
            <Row
              gap="8px"
              justifyContent="flex-start"
              style={{ flexWrap: 'wrap' }}
            >
              {reportTypeList.map((item, idx) => {
                return (
                  <OptionSelectBox
                    $isSelected={reportParam.typeId === Number(item.id)}
                    key={idx}
                    onClick={() =>
                      setReportParam({
                        ...reportParam,
                        typeId: Number(item.id),
                      })
                    }
                  >
                    {item.name}
                  </OptionSelectBox>
                );
              })}
            </Row>
          )}
        </Column>
        <Divider />
        <Column gap="12px" align="flex-start">
          <Text color="#000" size="16px" weight={600}>
            상세사유 (선택사항)
          </Text>
          <ReportReasonTextarea
            placeholder="자세한 신고 사유를 입력해 주세요"
            value={reportParam.reason}
            onChange={e =>
              setReportParam({ ...reportParam, reason: e.target.value })
            }
          />
        </Column>
      </Column>
    </ModalContainer>
  );
};

export default ReportModal;
