import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Value } from 'react-calendar/dist/cjs/shared/types';

import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import { Items, ProductParamInput } from 'containers/ProductRegist/hooks/types';
import Textarea from '../../common/Textarea';

import {
  ICON_ARROW_BLACK_BOTTOM,
  ICON_CLOSE,
  ICON_PRODUCT_DELETE,
  ICON_PRODUCT_SELECT,
  ICON_PRODUCT_SELECT_DISABLE,
  ICON_PRODUCT_SETTING,
} from 'assets/icons';
import { InventoryTargetType, ItemState } from 'types/api';
import Divider from 'components/Divider';
import ModalContainer from '../../common/ModalContainer';
import CustomCalendar from 'components/Calendar/Calendar';
import ItemsStatusButton from './ItemsStatusButton';
import { ONLY_NUMBER_REGEX } from 'utils/regExp';
import BasicButton from 'components/Button/BasicButton';
import CommonImage from 'components/common/CommonImage';
import DateInput from 'components/Input/DateInput';
import ConfirmDialog from 'components/Modal/ConfirmDialog';

const Container = styled.div<{ $isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 16px 16px 20px;
  width: 100%;

  border-radius: 12px;
  border: ${props =>
    props.$isSelected ? '1.5px solid #1b1c1e' : '1px solid #dbdcdf'};
  background: #f7f7f8;
`;

const OptionSettingBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 16px;
  width: 100%;

  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;
`;

const MutipleTicket = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 12px;
  /* padding: 8px 27px 8px 12px; */
  width: 100%;

  font-size: 14px;
  text-align: right;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;
`;

const OptionInput = styled.input`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 27px 8px 12px;
  width: 100%;

  font-size: 14px;
  text-align: right;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    justify-content: flex-end;
    color: #c2c4c8;
  }

  &:focus {
    border: 1.5px solid #1b1c1e;
  }
`;

const CheckBox = styled.input`
  width: 18px;
  height: 18px;
  border-radius: 5px;

  -webkit-appearance: none; /* 기본 스타일 제거 */
  -moz-appearance: none;
  appearance: none;
  background-color: #fff; /* 기본 배경색 */
  border: 1px solid #ddd; /* 테두리 */
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: 5px;

  &:checked {
    background-color: #0071e9;
  }

  &:checked::before {
    content: '✓';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
`;

const DateBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
  padding: 8px 12px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;
  cursor: pointer;
`;

interface OptionBoxIProps {
  type?: string;
  idx: number;
  isMobile: boolean;
  item: Items;
  selectedItems: Items[];
  productParamInput: ProductParamInput;
  setItems: React.Dispatch<React.SetStateAction<Items[]>>;
  handleSelectedItems: (item: Items) => void;
  handleDeleteItmes: (deleteItems: Items) => void;
  handleSetRepresentativeItems: (paramId: string) => void;
  handleRefetchProduct?: (productId: string) => void;
}

const OptionBox: React.FC<OptionBoxIProps> = props => {
  const {
    type,
    idx,
    isMobile,
    item,
    selectedItems,
    productParamInput,
    setItems,
    handleSelectedItems,
    handleDeleteItmes,
    handleSetRepresentativeItems,
    handleRefetchProduct,
  } = props;

  const [isOpenOptionDetailSettingModal, setIsOpenOptionDetailSettingModal] =
    useState<boolean>(false);
  const [isOpenCalendarModal, setIsOpenCalendarModal] =
    useState<boolean>(false);
  const [schedule, setScedule] = useState<{ type: string; value: number }>({
    type: '',
    value: 0,
  });

  const isSelected =
    selectedItems.filter(filterItem => filterItem.paramId === item.paramId)
      .length > 0;

  const handleChangeItems = (key: string, value: any) => {
    setItems(prevItems => {
      const updatedItems = [...prevItems];
      updatedItems[idx] = {
        ...updatedItems[idx],
        [key]: value,
      };
      return updatedItems;
    });
  };

  const handleSetDuration = (value: Value) => {
    const date = new Date(value!.toString());

    const time = date.getTime();

    setScedule({ ...schedule, value: time });
  };

  const handleSetOptionCount = (e: any) => {
    if (productParamInput.firstOpenedAt === null) {
      handleChangeItems(
        'quota',
        Number(e.target.value.replaceAll(ONLY_NUMBER_REGEX, '')),
      );

      return;
    }

    if (productParamInput.firstOpenedAt !== null && !item.status) {
      handleChangeItems(
        'quota',
        Number(e.target.value.replaceAll(ONLY_NUMBER_REGEX, '')),
      );

      return;
    }

    if (type && item.quota !== 0) {
      if (Number(e.target.value) === 0) {
        handleChangeItems('quota', 1);

        return;
      }

      handleChangeItems(
        'quota',
        Number(e.target.value.replaceAll(ONLY_NUMBER_REGEX, '')),
      );

      return;
    }

    handleChangeItems(
      'quota',
      Number(e.target.value.replaceAll(ONLY_NUMBER_REGEX, '')),
    );
  };

  const renderOptionName = (): JSX.Element => {
    if (item.options.length > 1) {
      return (
        <Column gap="12px">
          {item.options.map((optionItem, optionIdx) => {
            const handleChangeOptionTitle = (key: string, value: any) => {
              for (let i = 0; i < productParamInput.items.length; i++) {
                setItems(prevItems => {
                  const updatedItems = [...prevItems];
                  updatedItems[i].options[optionIdx][key] = value;

                  return updatedItems;
                });
              }
            };

            const handleChangeOptionName = (key: string, value: any) => {
              optionItem[key] = value;
            };

            return (
              <Row
                key={optionIdx}
                gap="8px"
                align="flex-start"
                style={{ width: '100%' }}
              >
                <Column align="flex-start" gap="8px" style={{ width: '35%' }}>
                  <Text color="#989ba2">{optionIdx + 1}차 옵션</Text>
                  <Textarea
                    target="title"
                    value={optionItem.title}
                    placeholder="옵션 타이틀을 입력해주세요."
                    onChange={handleChangeOptionTitle}
                    isFocusInput={true}
                  />
                </Column>
                <Column align="flex-start" gap="8px">
                  <Text color="#989ba2">옵션명</Text>
                  <Textarea
                    target="name"
                    value={optionItem.name}
                    placeholder="옵션명을 입력해주세요."
                    onChange={handleChangeOptionName}
                  />
                </Column>
              </Row>
            );
          })}
          <Divider />
        </Column>
      );
    }

    return (
      <Textarea
        target="name"
        value={item.name}
        placeholder="옵션명을 입력해주세요. 예) 1인 참가비"
        onChange={handleChangeItems}
      />
    );
  };

  const renderOptionSettingBox = (): JSX.Element => {
    if (productParamInput.frip!.attachedToSchedule) {
      if (
        productParamInput.inventoryTargetType ===
        InventoryTargetType.BY_SCHEDULE
      ) {
        return renderOptionSchedule();
      }

      return renderOptionItems();
    }

    return (
      <OptionSettingBox>
        <Column align="flex-start" gap="8px">
          <Text color="#1b1c1e" size="14px" weight={400}>
            판매 개수
          </Text>
          <Row style={{ position: 'relative', width: '100%' }}>
            <OptionInput
              placeholder="제한없음"
              type="text"
              disabled={
                type &&
                item.status &&
                productParamInput.firstOpenedAt !== null &&
                item.quota === 0
              }
              value={item.quota === 0 ? '' : item.quota}
              onChange={(e: any) => {
                handleSetOptionCount(e);
              }}
              style={{
                padding: item.quota === 0 ? '8px 12px' : '8px 27px 8px 12px',
              }}
            />
            {item.quota !== 0 && (
              <Text
                color="#1b1c1e"
                size="14px"
                weight={400}
                style={{ position: 'absolute', right: '12px' }}
              >
                개
              </Text>
            )}
          </Row>
        </Column>
        <Column align="flex-start" gap="8px">
          <Text color="#1b1c1e" size="14px" weight={400}>
            참가비
          </Text>
          <Row style={{ position: 'relative', width: '100%' }}>
            <OptionInput
              type="text"
              value={item.price.sale.toLocaleString()}
              onChange={(e: any) => {
                handleChangeItems('price', {
                  ...item.price,
                  retail: Number(
                    e.target.value.replaceAll(ONLY_NUMBER_REGEX, ''),
                  ),
                  sale: Number(
                    e.target.value.replaceAll(ONLY_NUMBER_REGEX, ''),
                  ),
                });
              }}
            />
            <Text
              color="#1b1c1e"
              size="14px"
              weight={400}
              style={{ position: 'absolute', right: '12px' }}
            >
              원
            </Text>
          </Row>
        </Column>
      </OptionSettingBox>
    );
  };

  const renderOptionSchedule = (): JSX.Element => {
    return (
      <OptionSettingBox>
        <Row justifyContent="space-between" style={{ width: '100%' }}>
          <Text color="#1b1c1e" size="14px" weight={400}>
            참가비
          </Text>
          <Row style={{ position: 'relative', width: '50%' }}>
            <OptionInput
              type="text"
              value={item.price.sale.toLocaleString()}
              onChange={(e: any) => {
                handleChangeItems('price', {
                  ...item.price,
                  retail: Number(
                    e.target.value.replaceAll(ONLY_NUMBER_REGEX, ''),
                  ),
                  sale: Number(
                    e.target.value.replaceAll(ONLY_NUMBER_REGEX, ''),
                  ),
                });
              }}
            />
            <Text
              color="#1b1c1e"
              size="14px"
              weight={400}
              style={{ position: 'absolute', right: '12px' }}
            >
              원
            </Text>
          </Row>
        </Row>
      </OptionSettingBox>
    );
  };

  const renderOptionItems = (): JSX.Element => {
    return (
      <OptionSettingBox>
        <Column gap="16px">
          <Row justifyContent="space-between" style={{ width: '100%' }}>
            <Text color="#1b1c1e" size="14px" weight={400}>
              참가비
            </Text>
            <Row style={{ position: 'relative', width: '50%' }}>
              <OptionInput
                type="text"
                value={item.price.sale.toLocaleString()}
                onChange={(e: any) => {
                  handleChangeItems('price', {
                    ...item.price,
                    retail: Number(
                      e.target.value.replaceAll(ONLY_NUMBER_REGEX, ''),
                    ),
                    sale: Number(
                      e.target.value.replaceAll(ONLY_NUMBER_REGEX, ''),
                    ),
                  });
                }}
              />
              <Text
                color="#1b1c1e"
                size="14px"
                weight={400}
                style={{ position: 'absolute', right: '12px' }}
              >
                원
              </Text>
            </Row>
          </Row>
          <Divider />
          <Column gap="8px" align="flex-start">
            <Text color="#1b1c1e" size="14px" weight={400}>
              모집 인원
            </Text>
            <Row gap="8px" style={{ width: '100%' }}>
              <Row style={{ position: 'relative', width: '50%' }}>
                <Text
                  color="#1b1c1e"
                  size="14px"
                  weight={400}
                  style={{ position: 'absolute', left: '12px' }}
                >
                  최소
                </Text>
                <OptionInput
                  placeholder="제한없음"
                  type="text"
                  value={
                    item.minimumQuota === 0 ? '제한 없음' : item.minimumQuota
                  }
                  onChange={(e: any) => {
                    handleChangeItems(
                      'minimumQuota',
                      Number(e.target.value.replaceAll(ONLY_NUMBER_REGEX, '')),
                    );
                  }}
                  style={{
                    padding:
                      item.minimumQuota === 0
                        ? '8px 12px'
                        : '8px 27px 8px 12px',
                  }}
                />
                {item.minimumQuota !== 0 && (
                  <Text
                    color="#1b1c1e"
                    size="14px"
                    weight={400}
                    style={{ position: 'absolute', right: '12px' }}
                  >
                    명
                  </Text>
                )}
              </Row>
              <Row style={{ position: 'relative', width: '50%' }}>
                <Text
                  color="#1b1c1e"
                  size="14px"
                  weight={400}
                  style={{ position: 'absolute', left: '12px' }}
                >
                  최대
                </Text>
                <OptionInput
                  placeholder="0"
                  type="text"
                  value={item.quota}
                  onChange={(e: any) => {
                    handleChangeItems(
                      'quota',
                      Number(e.target.value.replaceAll(ONLY_NUMBER_REGEX, '')),
                    );
                  }}
                />
                <Text
                  color="#1b1c1e"
                  size="14px"
                  weight={400}
                  style={{ position: 'absolute', right: '12px' }}
                >
                  명
                </Text>
              </Row>
            </Row>
          </Column>
        </Column>
      </OptionSettingBox>
    );
  };

  const renderAttachedToSchedule = (): JSX.Element => {
    if (productParamInput.frip!.attachedToSchedule) {
      return <></>;
    }

    return (
      <React.Fragment>
        <Container $isSelected={false}>
          <Column gap="18px" align="flex-end">
            <Column gap="2px" align="flex-start">
              <Text color="#1b1c1e" size="16px">
                기간 한정 판매
              </Text>
              <Text color="#70737C" size="12px" weight={400}>
                특정 기간 동안만 판매하는 옵션입니다.
              </Text>
            </Column>
            <Row gap="8px" style={{ width: '100%' }}>
              <Column gap="8px" align="flex-start">
                <Text color="#1b1c1e" weight={400}>
                  시작 기간
                </Text>
                <DateBox
                  onClick={() => {
                    // setIsOpenCalendarModal(true);
                    setScedule({ ...schedule, type: 'start' });
                  }}
                >
                  <Row gap="2px">
                    <Text
                      color={item.saleTerm?.startedAt ? '#1b1c1e' : '#c2c4c8'}
                      size="14px"
                      weight={400}
                    >
                      {item.saleTerm?.startedAt
                        ? `${dayjs(item.saleTerm.startedAt).format(
                            'YY년 • M월 • D일',
                          )}`
                        : '년 • 월 • 일'}
                    </Text>
                    <DateInput
                      onChange={e => {
                        handleChangeItems('saleTerm', {
                          ...item.saleTerm,
                          startedAt: new Date(e.target.value).getTime(),
                        });
                      }}
                    />
                  </Row>
                  <Text
                    color={item.saleTerm?.startedAt ? '#1b1c1e' : '#c2c4c8'}
                    size="12px"
                    weight={400}
                  >
                    00시 00분 부터
                  </Text>
                </DateBox>
              </Column>
              <Column gap="8px" align="flex-start">
                <Text color="#1b1c1e" weight={400}>
                  마감 기간
                </Text>
                <DateBox
                  onClick={() => {
                    // setIsOpenCalendarModal(true);
                    setScedule({ ...schedule, type: 'end' });
                  }}
                >
                  <Row gap="2px">
                    <Text
                      color={item.saleTerm?.endedAt ? '#1b1c1e' : '#c2c4c8'}
                      size="14px"
                      weight={400}
                    >
                      {item.saleTerm?.endedAt
                        ? `${dayjs(item.saleTerm.endedAt).format(
                            'YY년 • M월 • D일',
                          )}`
                        : '년 • 월 • 일'}
                    </Text>
                    <DateInput
                      onChange={e => {
                        handleChangeItems('saleTerm', {
                          ...item.saleTerm,
                          endedAt: new Date(e.target.value).getTime(),
                        });
                      }}
                    />
                  </Row>
                  <Text
                    color={item.saleTerm?.endedAt ? '#1b1c1e' : '#c2c4c8'}
                    size="12px"
                    weight={400}
                  >
                    23시 59분 까지
                  </Text>
                </DateBox>
              </Column>
            </Row>
          </Column>
        </Container>
        <Container $isSelected={false}>
          <Column gap="16px" align="flex-end">
            <Column gap="2px" align="flex-start">
              <Text color="#1b1c1e" size="16px">
                다회권 설정
              </Text>
              <Text color="#70737C" size="12px" weight={400}>
                2회 이상 진행 시 설정에 따라 입력한 개수만큼 사용 가능한 상품이
                생성됩니다.
              </Text>
            </Column>
            <Row gap="8px" style={{ position: 'relative', width: '50%' }}>
              <MutipleTicket
                onChange={(e: any) => {
                  handleChangeItems('ticketCount', Number(e.target.value));
                }}
              >
                <Text color="#1b1c1e" size="14px" weight={400}>
                  {item.ticketCount} 회권
                </Text>
              </MutipleTicket>
              <BasicButton
                content={
                  <CommonImage
                    src={ICON_ARROW_BLACK_BOTTOM}
                    onClick={() => {}}
                  />
                }
                style={{ padding: '8px', borderRadius: '8px' }}
                onClick={() => {
                  if (item.ticketCount > 1) {
                    handleChangeItems('ticketCount', item.ticketCount - 1);
                  }
                }}
              />
              <BasicButton
                content={
                  <CommonImage
                    src={ICON_ARROW_BLACK_BOTTOM}
                    onClick={() => {}}
                    style={{ rotate: '180deg' }}
                  />
                }
                style={{ padding: '8px', borderRadius: '8px' }}
                onClick={() => {
                  handleChangeItems('ticketCount', item.ticketCount + 1);
                }}
              />
            </Row>
          </Column>
        </Container>
      </React.Fragment>
    );
  };

  const renderOptionSettingModal = (): JSX.Element => {
    return (
      <ModalContainer
        isMobile={isMobile}
        isAbled={true}
        onClose={() => setIsOpenOptionDetailSettingModal(false)}
        onClick={() => setIsOpenOptionDetailSettingModal(false)}
      >
        <Row style={{ padding: '16px 0' }}>
          <Text color="#1b1c1e" size="16px" weight={600}>
            옵션 상세 설정
          </Text>
        </Row>
        <Column gap="16px" style={{ padding: '0 20px' }}>
          {renderAttachedToSchedule()}
          <Container $isSelected={false}>
            <Column gap="16px" align="flex-end">
              <Column gap="2px" align="flex-start">
                <Text color="#1b1c1e" size="16px">
                  1회 구매 가능 상품 수
                </Text>
                <Text color="#70737C" size="12px" weight={400}>
                  구매자가 한 번 구매시 구매할 수 있는 최대 수량을 설정합니다.
                </Text>
              </Column>
              <Row style={{ position: 'relative', width: '50%' }}>
                <OptionInput
                  placeholder="0"
                  type="number"
                  value={
                    item.maximumPurchasableCount !== 0
                      ? item.maximumPurchasableCount
                      : ''
                  }
                  onChange={(e: any) => {
                    handleChangeItems(
                      'maximumPurchasableCount',
                      Number(e.target.value),
                    );
                  }}
                />
                <Text
                  color="#1b1c1e"
                  size="14px"
                  weight={400}
                  style={{ position: 'absolute', right: '12px' }}
                >
                  개
                </Text>
              </Row>
            </Column>
          </Container>
        </Column>
      </ModalContainer>
    );
  };

  const renderCalendarModal = (): JSX.Element => {
    return (
      <ModalContainer
        isMobile={isMobile}
        isAbled={schedule.value !== 0}
        onClose={() => setIsOpenCalendarModal(false)}
        onClick={() => {
          setIsOpenCalendarModal(false);

          if (schedule.type === 'start') {
            handleChangeItems('saleTerm', {
              ...item.saleTerm,
              startedAt: schedule.value,
            });

            return;
          }

          handleChangeItems('saleTerm', {
            ...item.saleTerm,
            endedAt: schedule.value,
          });

          return;
        }}
      >
        <Row style={{ padding: '16px 0', position: 'relative', width: '100%' }}>
          <img
            src={ICON_CLOSE}
            alt="close icon"
            style={{ position: 'absolute', left: '20px', cursor: 'pointer' }}
            onClick={() => setIsOpenCalendarModal(false)}
          />
          <Text color="#1b1c1e" size="16px" weight={600}>
            일정 선택
          </Text>
        </Row>
        <CustomCalendar onChange={handleSetDuration} />
      </ModalContainer>
    );
  };

  const renderAddOptions = (): JSX.Element => {
    return (
      <Column gap="12px">
        {item.saleTerm && (
          <Row
            gap="8px"
            justifyContent="space-between"
            style={{ width: '100%' }}
          >
            <Column gap="2px" align="flex-start">
              <Text color="#46474C" size="14px">
                기간 한정 판매
              </Text>
              <Text color="#70737C" size="14px" weight={400}>{`${dayjs(
                item.saleTerm?.startedAt,
              ).format('YY년 M월 D일')}부터 ~ ${dayjs(
                item.saleTerm?.endedAt,
              ).format('YY년 M월 D일')}까지`}</Text>
            </Column>
            <Text
              color="#70737c"
              size="14px"
              weight={400}
              style={{ width: '30px' }}
              onClick={() => {
                handleChangeItems('saleTerm', null);
              }}
            >
              삭제
            </Text>
          </Row>
        )}
        {item.ticketCount > 1 && (
          <>
            <Divider />
            <Row
              gap="8px"
              justifyContent="space-between"
              style={{ width: '100%' }}
            >
              <Column gap="2px" align="flex-start">
                <Text color="#46474C" size="14px">
                  다회 이용권
                </Text>
                <Text color="#70737C" size="14px" weight={400}>
                  {item.ticketCount}회 권
                </Text>
              </Column>
              <Text
                color="#70737c"
                size="14px"
                weight={400}
                style={{ width: '30px' }}
                onClick={() => {
                  handleChangeItems('ticketCount', 1);
                }}
              >
                삭제
              </Text>
            </Row>
          </>
        )}
        {item.maximumPurchasableCount !== 0 && (
          <>
            <Divider />
            <Row
              gap="8px"
              justifyContent="space-between"
              style={{ width: '100%' }}
            >
              <Column gap="2px" align="flex-start">
                <Text color="#46474C" size="14px">
                  1회 구매 가능 상품 수
                </Text>
                <Text color="#70737C" size="14px" weight={400}>
                  {item.maximumPurchasableCount}개
                </Text>
              </Column>
              <Text
                color="#70737c"
                size="14px"
                weight={400}
                style={{ width: '30px', cursor: 'pointer' }}
                onClick={() => {
                  handleChangeItems('maximumPurchasableCount', 0);
                }}
              >
                삭제
              </Text>
            </Row>
          </>
        )}
      </Column>
    );
  };

  return (
    <React.Fragment>
      <Container $isSelected={isSelected}>
        <Row justifyContent="space-between" style={{ width: '100%' }}>
          <Text color="#1b1c1e" size="16px">
            옵션{idx + 1}
          </Text>
          {!type && (
            <Row gap="2px" onClick={() => handleSelectedItems(item)}>
              <img
                src={
                  isSelected ? ICON_PRODUCT_SELECT : ICON_PRODUCT_SELECT_DISABLE
                }
                alt="icon product select"
              />
              <Text color={isSelected ? '#1b1c1e' : '#c2c4c8'} size="14px">
                선택
              </Text>
            </Row>
          )}
        </Row>
        <Column gap={item.options.length === 1 ? '8px' : '12px'}>
          {renderOptionName()}
          {renderOptionSettingBox()}
        </Column>
        {renderAddOptions()}
        <Row justifyContent="space-between" style={{ width: '100%' }}>
          <Row gap="8px">
            <Row
              gap="2px"
              style={{ cursor: 'pointer' }}
              onClick={() => setIsOpenOptionDetailSettingModal(true)}
            >
              <img src={ICON_PRODUCT_SETTING} alt="product setting icon" />
              <Text color="#70737c" size="14px" weight={400}>
                상세 설정
              </Text>
            </Row>
            {!item.status && productParamInput.items.length > 1 && (
              <React.Fragment>
                <Text color="#70737c" size="14px" weight={400}>
                  |
                </Text>
                <Row
                  gap="2px"
                  onClick={() => handleDeleteItmes(item)}
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    src={ICON_PRODUCT_DELETE}
                    alt="product delete icon"
                    style={{ width: '16px' }}
                  />
                  <Text color="#70737c" size="14px" weight={400}>
                    옵션 삭제
                  </Text>
                </Row>
              </React.Fragment>
            )}
          </Row>
          <Row gap="2px">
            <CheckBox
              type="checkbox"
              checked={item.representative}
              onChange={() => handleSetRepresentativeItems(item.paramId)}
            />
            <Text color="#70737c" size="14px" weight={400}>
              대표 옵션 설정
            </Text>
          </Row>
        </Row>
        {item.status && item.status !== ItemState.EDITING && (
          <React.Fragment>
            <Divider />
            <ItemsStatusButton
              item={item}
              handleRefetchProduct={handleRefetchProduct}
            />
          </React.Fragment>
        )}
      </Container>
      {isOpenOptionDetailSettingModal && renderOptionSettingModal()}
      {isOpenCalendarModal && renderCalendarModal()}
    </React.Fragment>
  );
};

export default OptionBox;
