import React from 'react';
import { styled } from 'styled-components';
import { ICON_CLOSE } from 'assets/icons';
import AuthButton from 'components/Button/AuthButton';
import Column from 'components/common/Column';
import CommonImage from 'components/common/CommonImage';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import { CALENDAR_MOCK, GuideMockData } from '../hooks/GuideMock';
import ModalContainer from './common/ModalContainer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 375px;
  max-width: 375px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  position: sticky;
  top: 0;
  padding: 32px 20px;
  width: 100%;

  background-color: #fff;
  border-bottom: 1px solid #eaebec;
  color: #1b1c1e;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
  letter-spacing: -0.6px;
`;

const ContentContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 20px;
  list-style: disc;
  list-style-position: outside;

  color: #171719;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.6px;

  li {
    color: #46474c;
    margin-left: 15px;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.6px;
  }
`;

interface GuideContainerIProps {
  isMobile: boolean;
  mockData: GuideMockData[];
  onClose: () => void;
}

const GuideContainer: React.FC<GuideContainerIProps> = props => {
  const { isMobile, mockData, onClose } = props;

  const renderItems = (): JSX.Element => {
    return (
      <Column align="flex-start" style={{ padding: isMobile ? '0' : '16px 0' }}>
        {mockData.map((item, idx) => {
          return (
            <ContentContainer key={idx}>
              {item.title}
              {item.description.map((descItem, descIdx) => {
                return (
                  <li key={descIdx}>
                    {descItem.content}
                    {descItem.subScription &&
                      descItem.subScription.map((subItem, subIdx) => {
                        return <li key={subIdx}>{subItem}</li>;
                      })}
                  </li>
                );
              })}
            </ContentContainer>
          );
        })}
      </Column>
    );
  };

  const renderContenst = (): JSX.Element => {
    if (isMobile) {
      return (
        <ModalContainer
          isAbled={true}
          isMobile={true}
          isBottomSheet={true}
          onClose={() => {}}
          onClick={() => {}}
          style={{ gap: 0 }}
        >
          <Row style={{ position: 'sticky', top: 0, background: '#fff' }}>
            <Row
              style={{ padding: '16px', width: '100%', position: 'relative' }}
            >
              <CommonImage
                src={ICON_CLOSE}
                onClick={onClose}
                style={{ position: 'absolute', left: '16px' }}
              />
              <Text color="#1b1c1e" size="16px" weight={600}>
                작성 가이드
              </Text>
            </Row>
          </Row>
          {renderItems()}
          <Row
            style={{
              padding: '16px',
              position: 'sticky',
              bottom: 0,
              borderTop: '1px solid #eaebec',
              background: '#fff',
            }}
          >
            <AuthButton
              isAbled={true}
              name="확인"
              onClick={() => {
                onClose();
              }}
            />
          </Row>
        </ModalContainer>
      );
    }

    return (
      <Container>
        <HeaderContainer>가이드</HeaderContainer>
        {renderItems()}
      </Container>
    );
  };

  return renderContenst();
};

export default GuideContainer;
