import { getEnvConstant } from 'constants/env';
import { SALE_LIST_PATH } from 'constants/path';
import { SignupParam } from 'containers/Signup/hooks/types';
import { useGetMe } from 'graphQL/Auth/user/useGetMe';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { instance } from 'utils/axiosInstance';
import { STORAGE_KEY } from 'utils/localStoarge';
import { useMutationChangeNoticeAgreement } from '../graphQL/hooks/useMutationChangeNoticeAgreement';
import { useMutationCreateHost } from '../graphQL/hooks/useMutationCreateHost';
import { UserLoginResponse } from '../graphQL/types';
import { SignInParams } from './types';

export const useLoginHooks = () => {
  const navigate = useNavigate();
  const { IMP } = window;

  const { handleRefetchUser } = useGetMe();
  const { isOpenSuccessModal, handleCreateHost } = useMutationCreateHost();
  const { handleChangeNoticeAgreement } = useMutationChangeNoticeAgreement();

  const [signInParams, setSignInParams] = useState<SignInParams>({
    authorityLevel: 'HOST',
    email: '',
    password: '',
    store: 'frip',
  });
  const [signupParam, setSignupParam] = useState<SignupParam>({
    email: '',
    password: '',
    nickname: '프립 크루',
    mobileNumber: '',
    verificationKey: '',
    certificationKey: '',
    agreement: {
      marketing: false,
    },
    store: 'frip',
  });
  const [loginInfo, setLoginInfo] = useState<UserLoginResponse>();
  const [isMobileCertification, setIsMobileCertification] =
    useState<boolean>(false);

  const [isOpenFindPasswordModal, setIsOpenFindPasswordModal] =
    useState<boolean>(false);
  const [isOpenBottomToast, setIsOpenBottomToast] = useState<boolean>(false);
  const [isShowSignupForm, setIsShowSignupForm] = useState<boolean>(false);
  const [isFailedLogin, setIsFailedLogin] = useState<boolean>(false);

  const handleOnchangeSignInParams = (key: string, value: string) => {
    setSignInParams({ ...signInParams, [key]: value });

    if (isFailedLogin) {
      setIsFailedLogin(false);
    }
  };

  const handleEmailSignin = async () => {
    try {
      window.handleOpenLoading();
      const response = await instance.post('/signin', signInParams);

      if (response) {
        localStorage.setItem(
          STORAGE_KEY.AUTHORIZATION,
          `${response.data.data.tokenType} ${response.data.data.accessToken}`,
        );

        handleRefetchUser(() => {
          window.handleCloseLoading();
          navigate(SALE_LIST_PATH);
        });
      }
    } catch (error: any) {
      if (error.response.status === 401) {
        window.showToast(error.response.data.message, 'failed');

        const response = await instance.post('/signin', {
          ...signInParams,
          authorityLevel: 'USER',
        });

        if (response) {
          localStorage.setItem(
            STORAGE_KEY.AUTHORIZATION,
            `${response.data.data.tokenType} ${response.data.data.accessToken}`,
          );

          handleSetLoginInfo(response.data.data);
          handleRefetchUser(() => {
            setIsShowSignupForm(true);
            window.handleCloseLoading();
          });
        }

        return;
      }

      setIsFailedLogin(true);
      window.handleCloseLoading();
    }
  };

  const handleFindPassword = async (email: string, callback: () => void) => {
    try {
      const response = await instance.post('/password/reset/request', {
        email,
      });

      if (response) {
        callback();

        setIsOpenBottomToast(true);
      }
    } catch (error: any) {
      window.showToast(error.response.data.message, 'failed');
    }
  };

  const handleOnChangeFindPasswordModal = () => {
    setIsOpenFindPasswordModal(!isOpenFindPasswordModal);
  };

  const handleIsShowSignupForm = () => {
    setIsShowSignupForm(!isShowSignupForm);
  };

  const handleChangeSignupParam = (key: string, value: string) => {
    setSignupParam({ ...signupParam, [key]: value });
  };

  const handelCheckedMarketing = (checked: boolean) => {
    setSignupParam({ ...signupParam, agreement: { marketing: checked } });
  };

  const handleMobileCertification = () => {
    IMP.certification({ phone: signupParam.mobileNumber }, (response: any) => {
      handleCertification(response);
    });
  };

  const handleCertification = async (response: any) => {
    if (response.success) {
      try {
        const certificationResponse = await instance.post(
          '/users/certification',
          {
            provider: 'IAMPORT',
            key: response.imp_uid,
          },
        );

        if (certificationResponse) {
          setIsMobileCertification(true);
        }
      } catch (error) {
        const message = error.response.data.message;

        window.showToast(message, 'failed');
      }
    } else {
      setIsMobileCertification(false);
    }
  };

  const handleSetLoginInfo = (info: UserLoginResponse) => {
    if (info.email === '') {
      setLoginInfo({ ...info, email: 'test' });

      return;
    }
    setLoginInfo(info);
  };

  const handleRegistHost = async (mobileNumber: string) => {
    try {
      handleChangeNoticeAgreement(signupParam.agreement.marketing);

      await handleCreateHost(loginInfo!, mobileNumber);
    } catch (error) {
      console.log(error, 'regist host error');
    }
  };

  useEffect(() => {
    IMP.init(getEnvConstant('iamport'));
  }, []);

  return {
    signInParams,
    signupParam,
    isOpenFindPasswordModal,
    isOpenBottomToast,
    isShowSignupForm,
    isMobileCertification,
    isFailedLogin,
    isOpenSuccessModal,
    handleOnchangeSignInParams,
    handleEmailSignin,
    handleOnChangeFindPasswordModal,
    handleFindPassword,
    setIsOpenBottomToast,
    handleIsShowSignupForm,
    handleChangeSignupParam,
    handelCheckedMarketing,
    handleMobileCertification,
    handleSetLoginInfo,
    handleRegistHost,
    handleRefetchUser,
  };
};
