import React from 'react';
import styled from 'styled-components';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import Divider from 'components/Divider';
import Text from 'components/common/Text';
import Row from 'components/common/Row';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 24px;
  width: 100%;
`;

interface PrecautionViewIProps {
  productParamInput: ProductParamInput;
}

const PrecautionView: React.FC<PrecautionViewIProps> = props => {
  const { productParamInput } = props;

  return (
    <React.Fragment>
      <Divider style={{ height: '8px', background: '#F4F4F4' }} />
      <Container>
        <Text color="#000" size="18px" weight={700}>
          유의 사항
        </Text>
        <Text
          color="#70737c"
          size="14px"
          weight={400}
          style={{ whiteSpace: 'pre-line' }}
        >
          {productParamInput.notice}
        </Text>
      </Container>
    </React.Fragment>
  );
};

export default PrecautionView;
