import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useResizeDetector } from 'react-resize-detector';
import styled from 'styled-components';

import Column from 'components/common/Column';
import Row from 'components/common/Row';
import { getEnvConstant } from 'constants/env';
import { useProductDetailHooks } from './hooks/useProductDetaillHooks';
import ModifyContentsContainer from './components/ModifyContentsContainer';
import AuthButton from 'components/Button/AuthButton';
import ViewContainer from './components/ViewContainer';
import ProductDetailHeader from './components/ProductDetailHeader';
import ProductBottom from './components/ProductBottom';
import ConfirmDialog from 'components/Modal/ConfirmDialog';
import {
  formatDialogText,
  formatModifyText,
  renderDialogDescription,
  renderDialogTitle,
} from 'utils/formatting';
import { useNavigate } from 'react-router-dom';
import Loading from 'components/Loading/Loading';
import HackleManager from 'utils/hackle';
import { formatProductStatus } from 'utils/hackle/parameterFormatting';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
`;

const ProductDetailPage = () => {
  const {
    productInfo,
    menuType,
    productParamInput,
    inquiryList,
    modifyValue,
    isOpenConfirmDialog,
    isOpenCloseProductDetail,
    setIsOpenCloseProductDetail,
    setIsOpenConfirmDialog,
    setModifyValue,
    setProductParamInput,
    handleOnChangeProductParamInput,
    handleSetProductStatus,
    handleSetMenuType,
    handleUpdateProduct,
    handleGetProductScheduleTerm,
    handleRefetchInquiryTemplate,
    handleRefetchProduct,
  } = useProductDetailHooks();

  const navigate = useNavigate();
  const { width, height, ref } = useResizeDetector();

  const isMobile = width ? width < 768 : window.innerWidth < 768;

  useEffect(() => {
    if (isMobile) {
      document.body.style.overflow = '';

      return;
    }

    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = ''; // 컴포넌트가 언마운트될 때 스크롤 복구
    };
  }, [isMobile]);

  const renderViewForm = (): JSX.Element => {
    return (
      <ViewContainer
        isMobile={isMobile}
        productParamInput={productParamInput}
        productInfo={productInfo}
        inquiryList={inquiryList}
        menuType={menuType}
        modifyValue={modifyValue}
        setModifyValue={setModifyValue}
        handleSetProductStatus={handleSetProductStatus}
        handleSetMenuType={handleSetMenuType}
        setIsOpenCloseProductDetail={setIsOpenCloseProductDetail}
      />
    );
  };

  const renderModifyForm = (): JSX.Element => {
    return (
      <ModifyContentsContainer
        isMobile={isMobile}
        productParamInput={productParamInput}
        productInfo={productInfo}
        modifyValue={modifyValue}
        menuType={menuType}
        inquiryList={inquiryList}
        height={height}
        setModifyValue={setModifyValue}
        setProductParamInput={setProductParamInput}
        handleOnChangeProductParamInput={handleOnChangeProductParamInput}
        handleRefetchInquiryTemplate={handleRefetchInquiryTemplate}
        handleGetProductScheduleTerm={handleGetProductScheduleTerm}
        handleRefetchProduct={handleRefetchProduct}
      />
    );
  };

  const renderContents = (): JSX.Element => {
    HackleManager.logProductModifyView(
      Number(productInfo.id),
      productInfo.title,
      productInfo.createdAt,
      productInfo.saleTerm.endedAt,
      formatProductStatus([productInfo.status]),
    );

    if (isMobile) {
      return (
        <Column>
          {renderViewForm()}
          {renderModifyForm()}
          <Column
            style={{
              padding: '16px 20px 32px',
              position: 'sticky',
              bottom: 0,
              background: '#fff',
              borderTop: '1px solid #eaebec',
              zIndex: 3,
            }}
          >
            <AuthButton
              isAbled={modifyValue.isModify}
              name="저장"
              onClick={() => {
                HackleManager.logProductModifySaveComplete(
                  Number(productInfo.id),
                  productInfo.title,
                );

                handleUpdateProduct();
              }}
            />
          </Column>
        </Column>
      );
    }

    return (
      <Column>
        <ProductDetailHeader productInfo={productInfo} />
        <Row
          align="flex-start"
          justifyContent="space-between"
          style={{
            padding: isMobile ? '0' : '0 40px',
            width: '100%',
            maxWidth: '1280px',
          }}
        >
          <Row
            align="flex-start"
            style={{
              position: 'sticky',
              top: 0,
              overflowY: 'scroll',
              width: '100%',
              maxWidth: '375px',
              height: `${height - 190}px`,
              zIndex: 1,
            }}
          >
            {renderViewForm()}
          </Row>
          <Column
            justifyContent="space-between"
            align="flex-start"
            style={{
              position: 'sticky',
              top: '89px',
              overflowY: 'scroll',
              // paddingBottom: '32px',
              width: '100%',
              height: `${height - 190}px`,
              borderRight: '1px solid #EAEBEC',
              zIndex: 1,
            }}
          >
            {renderModifyForm()}
          </Column>
        </Row>
        <ProductBottom
          productInfo={productInfo}
          modifyValue={modifyValue}
          handleSetProductStatus={handleSetProductStatus}
          handleUpdateProduct={handleUpdateProduct}
          setIsOpenConfirmDialog={setIsOpenConfirmDialog}
          setIsOpenCloseProductDetail={setIsOpenCloseProductDetail}
        />
      </Column>
    );
  };

  return (
    <Container ref={ref}>
      <Helmet>
        <script
          src={`//dapi.kakao.com/v2/maps/sdk.js?appkey=${getEnvConstant(
            'kakaoAppKey',
          )}&autoload=false&libraries=services`}
        />
        <script
          src={`https://maps.googleapis.com/maps/api/js?language=ko&key=${getEnvConstant(
            'googleMapApiKey',
          )}`}
        />
      </Helmet>
      {productInfo && productParamInput ? renderContents() : <Loading />}
      {isOpenConfirmDialog && (
        <ConfirmDialog
          title={renderDialogTitle(productInfo.status)}
          description={renderDialogDescription(productInfo.status)}
          onClose={() => {
            setIsOpenConfirmDialog(false);
          }}
          onCloseText="아니요"
          onAcceptText={formatDialogText(productInfo.status)}
          onAccept={() => {
            HackleManager.logProductModifyStatusSaveComplete(
              Number(productInfo.id),
              productInfo.title,
              formatProductStatus([productInfo.status]),
              formatModifyText(productInfo.status),
            );

            handleSetProductStatus(productInfo.status);

            setIsOpenConfirmDialog(false);
          }}
        />
      )}
      {isOpenCloseProductDetail && (
        <ConfirmDialog
          title="수정사항을 저장하지 않았어요"
          description="수정사항을 저장하지 않으면 상품에 반영되지 않아요."
          onClose={() => {
            navigate(-1);
            setIsOpenCloseProductDetail(false);
          }}
          onCloseText="괜찮아요"
          onAcceptText="저장하고 나가기"
          onAccept={() => {
            handleUpdateProduct();
            navigate(-1);
            setIsOpenCloseProductDetail(false);
          }}
        />
      )}
    </Container>
  );
};

export default ProductDetailPage;
