import { useMutation } from "@apollo/client";
import { GET_HOST_ATTACHEMENT_DOWNLOAD_URL } from "..";

export function useMutationGetHostAttachmentDownloadUrl() {
  const [getHostAttachmentDownloadUrl, { data }] = useMutation(
    GET_HOST_ATTACHEMENT_DOWNLOAD_URL
  );

  const handleGetHostAttachmentDownloadUrl = async (
    hostAttachmentId: string,
    callback?: (url: any) => void
  ) => {
    try {
      const response = await getHostAttachmentDownloadUrl({
        variables: {
          hostAttachmentId,
        },
      });

      if (response && callback) {
        callback(response.data.generateHostAttachmentDownloadUrl.url);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    data,
    handleGetHostAttachmentDownloadUrl,
  };
}
