import React from 'react';
import { CSSProperties, styled } from 'styled-components';

const Input = styled.input`
  border: none;
  width: 16px !important;
  height: 16px !important;
  cursor: pointer;
  font-size: 14px;

  background: url('icon_calendar.svg') center center no-repeat;
  background-size: 16px 16px !important;
  will-change: transform;

  /* 텍스트 숨기기 */
  color: transparent;
  caret-color: transparent; /* 커서가 나타나지 않도록 */
  text-indent: -9999px;

  &::-webkit-calendar-picker-indicator {
    opacity: 0;
    cursor: pointer; /* 커서 모양 변경 */
  }

  &::-webkit-inner-spin-button {
    display: none; /* 스핀 버튼 숨기기 */
  }

  &::placeholder {
    color: transparent; /* placeholder 텍스트 투명화 */
  }
`;

interface TimeInputIProps {
  style?: CSSProperties;
  onChange: (e: any) => void;
}

const TimeInput: React.FC<TimeInputIProps> = props => {
  const { style, onChange } = props;

  return <Input type="time" onChange={e => onChange(e)} style={{ ...style }} />;
};

export default TimeInput;
