import { ToastType } from 'components/Modal/ToastManager';

interface EnvConstantSet {
  envName: string;

  // service url
  webClientUrl: string;
  webHostUrl: string;
  apiUrl: string;
  gqlApiUrl: string;
  identityApiUrl: string;
  marketingApiUrl: string;
  contentApiUrl: string;
  feedApiUrl: string;
  inviteFriendsPromotionDynamicLink: string;
  inviteFriendsPromotionImageUrl: string;

  // third party lib
  iamport: string;
  tossPaymentsClientKey: String;
  googleMapApiKey: string;
  kakaoAppKey: string;
  kakaoPixelKey: string;
  facebookAppKey: string;
  facebookPixelKey: string;
  amplitudeKey: string;
  airbridgeKey: string;
  hackleKey: string;
  airbridgeAppName: string;
  googleOptimizeKey: string;

  // tracking lib
  gaTrackingId: string;
  gtmId: string;
  naverAnalyticsId: string;
  brazeAppKey: string;
  hjId?: number; // beta 환경과 prd 환경에만 적용합니다.
  hjSv?: number;
  firehoseDeliveryStreamName: string;

  //talkplus
  talkPlusAppId: string;

  // firebase object
  firebaseConfig: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
}

declare global {
  interface Window {
    __ENV_STATE__: string;
    REACT_APP_ENV: string;
    __INIT_AUTHORIZATION__: string;
    kakao: any;
    FB: any;
    IMP: any;
    webkit?: any;
    Android?: any;
    google: any;
    showToast: (message: string, type: ToastType, duration?: number) => void;
    handlePageBackwardNavigate(): void;
    handleOnClosePage(): void;
    // handleSetAuthorization(authToken: string): void
    handleSetAuthorization(param: { authToken: string }): void;
    handleImageUpload(link: { link: string }): void;
    handleSettlementIdentityUpload(link: { link: string }): void;
    handleImageLoading(): void;
    handleMobileModalClose: () => void;
    handleOpenLoading: () => void;
    handleCloseLoading: () => void;
  }

  let Kakao: {
    init: any;
    Auth: any;
    Link: any;
    isInitialized: any;
    cleanup: any;
  };
}

const IAMPORT_KEY_DEV = 'imp18257751';
const IAMPORT_KEY_PROD = 'imp17508840';

const TOSS_PAYMENTS_CLIENT_KEY_DEV = 'test_ck_YoEjb0gm23PXA1ZZa6j3pGwBJn5e';
const TOSS_PAYMENTS_CLIENT_KEY_PROD = 'live_ck_D4yKeq5bgrpbAkYD46Z3GX0lzW6Y';

const GA_TRACKING_ID_DEV = '386891172';
const GA_TRACKING_ID_PROD = '395352815';

// const GA_TRACKING_ID_DEV = 'G-9RYW1FG3Q9';
// const GA_TRACKING_ID_PROD = 'G-7LQVXDJBSD';

const GTM_ID_DEV = 'GTM-KPK3CS5';
const GTM_ID_PROD = 'GTM-WWT995C';

const NAVER_ANALYTICS_ID_DEV = '260061932e76e0';
const NAVER_ANALYTICS_ID_PROD = 's_4d71b4f50d81';

const BRAZE_APP_KEY_DEV = '704d2173-fa22-4bf3-8469-1271e18875e1';
const BRAZE_APP_KEY_PROD = '9a0d5f22-904d-42d6-95cb-437725cde55a';

const GOOGLE_MAP_API_KEY_DEV = 'AIzaSyCzSyopq0__ErDzrnOC5D9Jn39yut1GsFc'; // TODO: 주의. 사용 가능 도메인 제한이 없는 API key. 가급적 사용을 지양해야 합니다.
const GOOGLE_MAP_API_KEY_PROD = 'AIzaSyB0q9cEbGkf4vYW-ZEEthcWu0jyO-Lqrv8';

const KAKAO_APP_KEY_DEV = 'b2774bb606e7431f85f5604e11fb127a';
const KAKAO_APP_KEY_PROD = '90a1878dbc5b1c03f9c7db732ac4b97f';

const KAKAO_PIXEL_KEY_DEV = '1943028351134382123';
const KAKAO_PIXEL_KEY_PROD = '4165588018247283822';

const FACEBOOK_APP_KEY_DEV = '544244172382640';
const FACEBOOK_APP_KEY_PROD = '488654004608324';

const FACEBOOK_PIXEL_KEY_DEV = '484780612469878';
const FACEBOOK_PIXEL_KEY_PROD = '1940417819522842';

const FIREHOSE_DELIVERY_STREAM_NAME_DEV = 'frip-action-web-dev';
const FIREHOSE_DELIVERY_STREAM_NAME_PROD = 'frip-action-web-prod';

const TALKPLUS_APP_ID = {
  DEV: '933d87b5-ea2b-4922-9f1b-11bd40a8a03b',
  STAGING: '8b5705aa-accf-48bd-aeb6-6b8848948063',
  PROD: '8b5705aa-accf-48bd-aeb6-6b8848948063',
};

const INVITE_FRIENDS_PROMOTION_DEV = {
  DYNAMIC_LINK: 'https://do.frip.co.kr/invite-friends-beta',
  IMAGE_URL:
    'https://res.cloudinary.com/frientrip/image/upload/v1672904098/invite-friends-og-image_kxg2h5.jpg',
};
const INVITE_FRIENDS_PROMOTION_PROD = {
  DYNAMIC_LINK: 'https://do.frip.co.kr/invite-friends',
  IMAGE_URL:
    'https://res.cloudinary.com/frientrip/image/upload/v1672922999/invite-friends-og-image_aioxpb.jpg',
};

const AMPLITUDE_KEY = {
  DEV: 'bb29340fada5880a4b719021abd96d84',
  STAGING: '3fe3b61e404c81b0dca2b62ec5976fca',
  PROD: '5ea4cadb4d2940d09631bd2bcae477c0',
};

const AIRBRIDGE_KEY = {
  DEV: 'bc69de8129554eb3b43aa8846123b19f',
  STAGING: '708159cc93c34674a0331cba0eb30e04',
  PROD: '47b86ddb65134e9086adf40a229bcb4a',
};

const HACKLE_KEY = {
  DEV: 'q2pNFlua5pagOfXc4evwTozPKnmyZzCA',
  PROD: 'Ut0mL5sSw4b6VmHJcQQM682DpzmwK63M',
};

const AIRBRIDGE_APPNAME = {
  DEV: 'fripbeta',
  STAGING: 'fripstaging',
  PROD: 'frip',
};

const GOOGLE_OPTIMIZE_KEY = {
  DEV: 'OPT-WNZVLRK',
  PROD: 'OPT-P2M6GVL',
};

const FIREBASE_CONFIG_KEY = {
  DEV: {
    apiKey: 'AIzaSyCzSyopq0__ErDzrnOC5D9Jn39yut1GsFc',
    authDomain: 'frip-99e38.firebaseapp.com',
    databaseURL: 'https://frip-99e38.firebaseio.com',
    projectId: 'frip-99e38',
    storageBucket: 'frip-99e38.appspot.com',
    messagingSenderId: '581020766903',
    appId: '1:581020766903:web:ff9df8673199d264587332',
    measurementId: 'G-9RYW1FG3Q9',
  },
  STAGING: {
    apiKey: 'AIzaSyCzSyopq0__ErDzrnOC5D9Jn39yut1GsFc',
    authDomain: 'frip-99e38.firebaseapp.com',
    databaseURL: 'https://frip-99e38.firebaseio.com',
    projectId: 'frip-99e38',
    storageBucket: 'frip-99e38.appspot.com',
    messagingSenderId: '581020766903',
    appId: '1:581020766903:web:affa13180fe22a66587332',
    measurementId: 'G-LFF8SL5RC7',
  },
  PROD: {
    apiKey: 'AIzaSyCzSyopq0__ErDzrnOC5D9Jn39yut1GsFc',
    authDomain: 'frip-99e38.firebaseapp.com',
    databaseURL: 'https://frip-99e38.firebaseio.com',
    projectId: 'frip-99e38',
    storageBucket: 'frip-99e38.appspot.com',
    messagingSenderId: '581020766903',
    appId: '1:581020766903:web:67527625d95b92b8587332',
    measurementId: 'G-6B2FSCRNCK',
  },
};

// staging | prod
const productionEnv: EnvConstantSet = {
  envName: 'production',
  webClientUrl: 'https://www.frip.co.kr',
  webHostUrl: 'https://new.frip.host',
  apiUrl: 'https://api.frientrip.com',
  gqlApiUrl: 'https://gql.frip.co.kr/graphql',
  identityApiUrl: 'https://identity.frip.co.kr',
  marketingApiUrl: 'https://marketing.frip.co.kr',
  contentApiUrl: 'https://content.frip.co.kr',
  feedApiUrl: 'https://social-feed.frip.co.kr',
  inviteFriendsPromotionDynamicLink: INVITE_FRIENDS_PROMOTION_PROD.DYNAMIC_LINK,
  inviteFriendsPromotionImageUrl: INVITE_FRIENDS_PROMOTION_PROD.IMAGE_URL,
  iamport: IAMPORT_KEY_PROD,
  tossPaymentsClientKey: TOSS_PAYMENTS_CLIENT_KEY_PROD,
  gaTrackingId: GA_TRACKING_ID_PROD,
  gtmId: GTM_ID_PROD,
  naverAnalyticsId: NAVER_ANALYTICS_ID_PROD,
  brazeAppKey: BRAZE_APP_KEY_PROD,
  googleMapApiKey: GOOGLE_MAP_API_KEY_PROD,
  kakaoAppKey: KAKAO_APP_KEY_PROD,
  kakaoPixelKey: KAKAO_PIXEL_KEY_PROD,
  facebookAppKey: FACEBOOK_APP_KEY_PROD,
  facebookPixelKey: FACEBOOK_PIXEL_KEY_PROD,
  hjId: 1637843,
  hjSv: 6,
  firehoseDeliveryStreamName: FIREHOSE_DELIVERY_STREAM_NAME_PROD,
  amplitudeKey: AMPLITUDE_KEY.PROD,
  airbridgeKey: AIRBRIDGE_KEY.PROD,
  airbridgeAppName: AIRBRIDGE_APPNAME.PROD,
  hackleKey: HACKLE_KEY.PROD,
  googleOptimizeKey: GOOGLE_OPTIMIZE_KEY.PROD,
  firebaseConfig: FIREBASE_CONFIG_KEY.PROD,
  talkPlusAppId: TALKPLUS_APP_ID.PROD,
};

const environmentVariables: { [key: string]: EnvConstantSet } = {
  development: {
    envName: 'development',
    webClientUrl: 'https://dev.frip.co.kr',
    webHostUrl: 'https://new-dev.frip.host',
    apiUrl: 'https://dev2-api.frientrip.com',
    gqlApiUrl: 'https://gql-dev.frip.co.kr/graphql',
    identityApiUrl: 'https://identity-dev.frip.co.kr',
    marketingApiUrl: 'https://marketing-dev.frip.co.kr',
    contentApiUrl: 'https://content-dev.frip.co.kr',
    feedApiUrl: 'https://social-feed-dev.frip.co.kr',
    inviteFriendsPromotionDynamicLink:
      INVITE_FRIENDS_PROMOTION_DEV.DYNAMIC_LINK,
    inviteFriendsPromotionImageUrl: INVITE_FRIENDS_PROMOTION_DEV.IMAGE_URL,
    iamport: IAMPORT_KEY_DEV,
    tossPaymentsClientKey: TOSS_PAYMENTS_CLIENT_KEY_DEV,
    gaTrackingId: GA_TRACKING_ID_DEV,
    gtmId: GTM_ID_DEV,
    naverAnalyticsId: NAVER_ANALYTICS_ID_DEV,
    brazeAppKey: BRAZE_APP_KEY_DEV,
    googleMapApiKey: GOOGLE_MAP_API_KEY_DEV,
    kakaoAppKey: KAKAO_APP_KEY_DEV,
    kakaoPixelKey: KAKAO_PIXEL_KEY_DEV,
    facebookAppKey: FACEBOOK_APP_KEY_DEV,
    facebookPixelKey: FACEBOOK_PIXEL_KEY_DEV,
    firehoseDeliveryStreamName: FIREHOSE_DELIVERY_STREAM_NAME_DEV,
    amplitudeKey: AMPLITUDE_KEY.DEV,
    airbridgeKey: AIRBRIDGE_KEY.DEV,
    airbridgeAppName: AIRBRIDGE_APPNAME.DEV,
    hackleKey: HACKLE_KEY.DEV,
    googleOptimizeKey: GOOGLE_OPTIMIZE_KEY.DEV,
    firebaseConfig: FIREBASE_CONFIG_KEY.DEV,
    talkPlusAppId: TALKPLUS_APP_ID.DEV,
  },

  staging: {
    ...productionEnv,
    envName: 'staging',
    webClientUrl: 'https://staging.frip.co.kr',
    webHostUrl: 'https://new-staging.frip.host',
    apiUrl: 'https://staging-api.frientrip.com',
    gqlApiUrl: 'https://gql-staging.frip.co.kr/graphql',
    identityApiUrl: 'https://identity-staging.frip.co.kr',
    marketingApiUrl: 'https://marketing-staging.frip.co.kr',
    contentApiUrl: 'https://content-staging.frip.co.kr',
    googleMapApiKey: GOOGLE_MAP_API_KEY_DEV,
    amplitudeKey: AMPLITUDE_KEY.STAGING,
    airbridgeKey: AIRBRIDGE_KEY.STAGING,
    airbridgeAppName: AIRBRIDGE_APPNAME.STAGING,
    hackleKey: HACKLE_KEY.DEV,
    firebaseConfig: FIREBASE_CONFIG_KEY.STAGING,
    talkPlusAppId: TALKPLUS_APP_ID.STAGING,
  },
  production: productionEnv,
};

export function getEnvConstant<T extends keyof EnvConstantSet>(key: T) {
  const systemEnv =
    window.REACT_APP_ENV === '___ENV_STATE___'
      ? 'development'
      : window.REACT_APP_ENV;

  if (systemEnv === undefined) {
    throw new Error('NODE_ENV 가 정의되지 않았습니다.');
  }

  const constantSet = environmentVariables[systemEnv];

  if (constantSet === undefined) {
    throw new Error(`허용하지 않는 NODE_ENV 입니다: ${systemEnv}`);
  }

  return constantSet[key];
}

export const IS_PRODUCTION =
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_ENV === 'production'; // 사용자가 실제로 사용하는 환경이다.
