import React from 'react';
import { ModifyValue } from 'containers/ProductDetail/hooks/useProductDetaillHooks';
import ModifyContainer from '../ModifyContainer';
import {
  ProductParamInput,
  ScheduleParam,
} from 'containers/ProductRegist/hooks/types';
import { ScheduleState } from 'types/api';
import styled from 'styled-components';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

const ScheduleCardBox = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 6px;
  border-radius: 4px;
  background: #f4f4f5;

  color: #46474c;
  text-align: center;
  font-family: Pretendard;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.2px;
`;

const StatusBox = styled.div<{ background: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${props => props.background};
`;

interface CalendarContainerIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  modifyValue: ModifyValue;
  setModifyValue: React.Dispatch<React.SetStateAction<ModifyValue>>;
}

const CalendarContainer: React.FC<CalendarContainerIProps> = props => {
  const { isMobile, productParamInput, modifyValue, setModifyValue } = props;

  const renderStatusItem = (item: ScheduleParam): JSX.Element => {
    switch (item.status) {
      case ScheduleState.OPENED: {
        return (
          <ScheduleCardBox>
            <StatusBox background="#0071E9" />
            <span>판매중</span>
          </ScheduleCardBox>
        );
      }
      case ScheduleState.PAUSED: {
        return (
          <ScheduleCardBox>
            <StatusBox background="#FF9200" />
            <span>판매중지</span>
          </ScheduleCardBox>
        );
      }
      case ScheduleState.SOLD_OUT: {
        return (
          <ScheduleCardBox>
            <StatusBox background="#DBDCDF" />
            <span>매진</span>
          </ScheduleCardBox>
        );
      }
      case ScheduleState.CANCELED: {
        return (
          <ScheduleCardBox>
            <StatusBox background="#FF4242" />
            <span>취소</span>
          </ScheduleCardBox>
        );
      }
    }

    return;
  };

  return (
    <ModifyContainer
      title="일정"
      isSelected={!isMobile && modifyValue.modifyStatus === 'calendar'}
      onClick={() => {
        setModifyValue({ ...modifyValue, modifyStatus: 'calendar' });
      }}
    >
      {isEmpty(productParamInput.frip.schedules) ? (
        <Column>
          <Text color="#46474C" weight={400}>
            등록된 일정이 없습니다.
          </Text>
          <Text color="#46474C" weight={400}>
            일정을 1개 이상 등록해야 상품 수정 및
          </Text>
          <Text color="#46474C" weight={400}>
            재판매가 가능해요.
          </Text>
        </Column>
      ) : (
        <Column gap="20px">
          {productParamInput.frip.schedules.map((item, idx) => {
            return (
              <Column align="flex-start" gap="6px" key={idx}>
                {renderStatusItem(item)}
                <Text color="#46474c" size="14px" weight={400}>
                  {dayjs(item.term.startedAt).format('YYYY년 M월 D일 HH:mm')} ~{' '}
                  {dayjs(item.term.startedAt + item.term.duration).format(
                    'YYYY년 M월 D일 HH:mm',
                  )}
                </Text>
                <Column gap="4px" align="flex-start">
                  {productParamInput.items.map((items, itemsIdx) => {
                    return (
                      <React.Fragment key={itemsIdx}>
                        {item.itemParamIds.includes(items.paramId) && (
                          <Text color="#70737C" size="14px" weight={400}>
                            • {items.name}
                          </Text>
                        )}
                      </React.Fragment>
                    );
                  })}
                </Column>
              </Column>
            );
          })}
        </Column>
      )}
    </ModifyContainer>
  );
};

export default CalendarContainer;
