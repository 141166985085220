import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { isEmpty, cloneDeep } from 'lodash';

import { HostVerifiedBusinessParamInput, TaxPayerType } from '../graphQL/type';
import { useMutationSaveVerifiedHostBusinessInfo } from '../graphQL/hooks/useMutationSaveVerifiedHostBusinessInfo';
import { useMutationGenerateHostAttachmentUploadUrl } from '../graphQL/hooks/useMutationGenerateHostAttachmentDownloadUrl';

import { useMutationCompleteHostAttachmentUpload } from '../graphQL/hooks/useMutatinonCompleteHostAttachmentUpload';
import { useMutationSaveHostBusinessInfo } from '../graphQL/hooks/useMutationSaveHostBusinessInfo';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import { useMutationGetHostAttachmentDownloadUrl } from 'containers/Settlement/graphQL/hooks/useMutationGetHostAttachmentDownloadUrl';
import { HostBusinessInfoState } from 'graphQL/Auth/types';
import { BUSINESSNUMBER_REGEX, IDNUMBER_REGEX } from 'utils/regExp';
import { SETTLEMENT_PATH } from 'constants/path';
import HackleManager from 'utils/hackle';

export const useSettlementRegist = () => {
  const myInfo = useUserInformation((state: any) => state.userInfo);
  const hostInfo = myInfo.me.host?.settlement;

  const location = useLocation();
  const navigate = useNavigate();

  const isCheckBusinessInformation =
    myInfo.me.host && myInfo.me.host.settlement?.businessInfo !== null;

  const { handleSaveVerifiedHostBusinessInfo } =
    useMutationSaveVerifiedHostBusinessInfo();
  const { handleGenerateHostAttachmentUploadUrl } =
    useMutationGenerateHostAttachmentUploadUrl();
  const { handleCompleteHostAttachmentUpload } =
    useMutationCompleteHostAttachmentUpload();
  const { handleSaveHostBusinessInfo } = useMutationSaveHostBusinessInfo();
  const { handleGetHostAttachmentDownloadUrl } =
    useMutationGetHostAttachmentDownloadUrl();

  const [pageNumber, setPageNumber] = useState<number>(
    location.state && location.state.pageNumber ? location.state.pageNumber : 1,
  );
  const [businessInformation, setBusinessInformation] =
    useState<HostVerifiedBusinessParamInput>({
      address: isCheckBusinessInformation
        ? myInfo.me.host!.settlement!.businessInfo.address
        : '',
      businessItem: isCheckBusinessInformation
        ? myInfo.me.host!.settlement!.businessInfo.businessItem
        : '',
      businessType: isCheckBusinessInformation
        ? myInfo.me.host!.settlement!.businessInfo.businessType
        : '',
      foreign: false,
      representativeName: isCheckBusinessInformation
        ? myInfo.me.host!.settlement!.businessInfo.representativeName
        : '',
      taxPayerName: isCheckBusinessInformation
        ? myInfo.me.host!.settlement!.businessInfo.taxPayerName
        : '',
      taxPayerType: isCheckBusinessInformation
        ? myInfo.me.host!.settlement!.businessInfo.taxPayerType
        : '',
    });
  const [registrationNo, setRegistrationNo] = useState<string>('');
  const [imageUrl, setImageUrl] = useState<string>('');
  const [previewImage, setPreviewImage] = useState<any>('');
  const [isClosePage, setIsClosePage] = useState<boolean>(false);

  const handleOnClosePage = () => {
    setIsClosePage(!isClosePage);
  };

  const handleResetBusinessInformation = (value: string) => {
    setBusinessInformation({
      address: '',
      businessItem: '',
      businessType: '',
      foreign: false,
      representativeName: '',
      taxPayerName: '',
      taxPayerType: value,
    });
  };

  const handleSetBusinessInformation = (target: string, value: string) => {
    if (target === '') {
      setRegistrationNo(value);
    } else {
      if (
        location.state &&
        !location.state.isModify &&
        target === 'taxPayerType'
      ) {
        handleResetBusinessInformation(value);
      } else {
        setBusinessInformation({ ...businessInformation, [target]: value });
      }
    }
  };

  const handlePageForwardNavigate = () => {
    if (location.state && location.state.isModify) {
      if (pageNumber === 1) {
        handleResetBusinessInformation(businessInformation.taxPayerType);
      }

      if (location.state.pageNumber !== 1 && pageNumber === 2) {
        if (businessInformation.taxPayerType === TaxPayerType.INDIVIDUAL) {
          handleSaveHostBusinessInfo(
            myInfo.me.host!.id,
            businessInformation,
            registrationNo,
          );
        } else {
          handleSaveVerifiedHostBusinessInfo(
            myInfo.me.host!.id,
            businessInformation,
            registrationNo,
          );
        }

        if (
          myInfo.me.host?.settlement?.businessInfo &&
          myInfo.me.host.settlement.businessInfo.status ===
            HostBusinessInfoState.UNROLLED
        ) {
          localStorage.removeItem('isRegistRequest');
        }
        return;
      }

      if (location.state.pageNumber !== 1 && pageNumber === 3) {
        handleCompleteHostAttachmentUpload(
          'modify',
          myInfo.me.host!.id,
          imageUrl,
        );

        if (
          myInfo.me.host?.settlement?.businessInfo &&
          myInfo.me.host.settlement.businessInfo.status ===
            HostBusinessInfoState.UNROLLED
        ) {
          localStorage.removeItem('isRegistRequest');
        }

        return;
      }
    }

    if (pageNumber === 4) {
      handleRegistCalculateInformation();

      if (
        myInfo.me.host?.settlement?.businessInfo &&
        myInfo.me.host.settlement.businessInfo.status ===
          HostBusinessInfoState.UNROLLED
      ) {
        localStorage.removeItem('isRegistRequest');
      }

      return;
    }
    setPageNumber(pageNumber + 1);
  };

  const handlePageBackwardNavigate = () => {
    if (pageNumber === 1) {
      handleOnClosePage();
    } else {
      if (location.state && location.state.isModify) {
        if (location.state.pageNumber === 1) {
          setPageNumber(pageNumber - 1);
        } else {
          navigate(SETTLEMENT_PATH, {
            state: {
              isOpenHostInformationModal: true,
            },
          });
        }

        return;
      }

      setPageNumber(pageNumber - 1);

      return;
    }
  };

  const handleGetImageUrl = (file: File) => {
    handleGenerateHostAttachmentUploadUrl(
      myInfo.me.host!.id,
      file,
      file.name,
      (link: string) => {
        setImageUrl(link);
      },
    );
  };

  const handleCheckForeign = () => {
    setBusinessInformation({
      ...businessInformation,
      foreign: !businessInformation.foreign,
    });
  };

  const handleRegistCalculateInformation = () => {
    if (businessInformation.taxPayerType === TaxPayerType.INDIVIDUAL) {
      handleSaveHostBusinessInfo(
        myInfo.me.host!.id,
        businessInformation,
        registrationNo,
      );
    } else {
      handleSaveVerifiedHostBusinessInfo(
        myInfo.me.host!.id,
        businessInformation,
        registrationNo,
      );
    }

    handleCompleteHostAttachmentUpload('create', myInfo.me.host!.id, imageUrl);
  };

  const handleModifyBusinessInformation = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const handleValidationCheck = (): boolean => {
    switch (pageNumber) {
      case 1: {
        if (
          businessInformation.taxPayerType !==
          myInfo.me.host?.settlement?.businessInfo.taxPayerType
        ) {
          return true;
        }

        return false;
      }
      case 2: {
        if (businessInformation.taxPayerType === TaxPayerType.INDIVIDUAL) {
          if (
            businessInformation.taxPayerName !== '' &&
            businessInformation.address !== '' &&
            IDNUMBER_REGEX.test(registrationNo)
          ) {
            return true;
          }

          return false;
        }

        if (
          businessInformation.representativeName !== '' &&
          businessInformation.taxPayerName !== '' &&
          BUSINESSNUMBER_REGEX.test(registrationNo) &&
          businessInformation.address !== '' &&
          businessInformation.businessItem !== '' &&
          businessInformation.businessType !== ''
        ) {
          return true;
        }

        return false;
      }
      case 3: {
        if (previewImage !== '') {
          return true;
        }

        return false;
      }
      default:
        return true;
    }
  };

  useEffect(() => {
    window.handlePageBackwardNavigate = handlePageBackwardNavigate;
  }, [pageNumber]);

  useEffect(() => {
    if (
      hostInfo &&
      !isEmpty(hostInfo.attachments) &&
      location.state &&
      location.state.isModify
    ) {
      const sortedItem = cloneDeep(hostInfo.attachments)?.sort(
        (a: any, b: any) => b.createdAt - a.createdAt,
      );

      handleGetHostAttachmentDownloadUrl(sortedItem![0].id, setPreviewImage);
    }
  }, [hostInfo]);

  return {
    pageNumber,
    businessInformation,
    registrationNo,
    previewImage,
    isClosePage,
    handlePageForwardNavigate,
    handlePageBackwardNavigate,
    handleSetBusinessInformation,
    handleCheckForeign,
    handleGetImageUrl,
    setPreviewImage,
    handleModifyBusinessInformation,
    handleValidationCheck,
    handleOnClosePage,
  };
};
