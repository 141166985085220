import React, { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ICON_NAVIGATE_BACK } from 'assets/icons';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import StatusButton from 'containers/ProductList/components/StatusButton';
import { Product, ProductState } from 'types/api';
import ModifyButton from './ModifyButton';
import {
  formatDialogText,
  formatModifyText,
  renderDialogDescription,
  renderDialogTitle,
} from 'utils/formatting';
import ConfirmDialog from 'components/Modal/ConfirmDialog';
import { ModifyValue } from '../hooks/useProductDetaillHooks';

const HeaderContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  position: sticky;
  top: 0;
  padding: ${props => (props.$isMobile ? '24px 20px' : '32px 20px')};

  width: 100%;
  border-bottom: 1px solid #dbdcdf;
  background: #fff;
  z-index: 6;
`;

const BackwardButton = styled.button`
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  background-color: #f4f4f5;

  img {
    width: 16px;
    height: 16px;
  }
`;

interface ProductHeaderIProps {
  isMobile: boolean;
  productInfo: Product;
  modifyValue: ModifyValue;
  handleSetProductStatus: (status: ProductState) => void;
  setIsOpenCloseProductDetail: Dispatch<SetStateAction<boolean>>;
}

const ProductHeader: React.FC<ProductHeaderIProps> = props => {
  const {
    isMobile,
    productInfo,
    modifyValue,
    handleSetProductStatus,
    setIsOpenCloseProductDetail,
  } = props;

  const navigate = useNavigate();

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);

  const handleNavigateBack = () => {
    if (modifyValue.isModify) {
      setIsOpenCloseProductDetail(true);

      return;
    }
    navigate(-1);
  };

  const renderContents = (): JSX.Element => {
    if (isMobile) {
      return (
        <HeaderContainer $isMobile={isMobile}>
          <Row
            justifyContent="center"
            style={{
              position: 'relative',
              width: '100%',
            }}
          >
            <img
              src={ICON_NAVIGATE_BACK}
              alt="naviate back"
              style={{ position: 'absolute', left: 0 }}
              onClick={handleNavigateBack}
            />

            <Text color="#1b1c1e" size="18px">
              프립 수정
            </Text>
          </Row>
          <Row justifyContent="space-between" style={{ width: '100%' }}>
            <StatusButton status={productInfo.status} />
            <ModifyButton
              text={formatModifyText(productInfo.status)}
              onClick={() => setIsOpenDialog(true)}
            />
          </Row>
        </HeaderContainer>
      );
    }

    return (
      <HeaderContainer $isMobile={isMobile}>
        <Row
          justifyContent="flex-start"
          gap="24px"
          style={{
            width: '100%',
          }}
        >
          <BackwardButton style={{ position: 'static' }}>
            <img
              src={ICON_NAVIGATE_BACK}
              alt="naviate back"
              onClick={handleNavigateBack}
            />
          </BackwardButton>
          <Text color="#1b1c1e" size="24px">
            프립 수정
          </Text>
        </Row>
      </HeaderContainer>
    );
  };

  return (
    <React.Fragment>
      {renderContents()}
      {isOpenDialog && (
        <ConfirmDialog
          title={renderDialogTitle(productInfo.status)}
          description={renderDialogDescription(productInfo.status)}
          onClose={() => {
            setIsOpenDialog(false);
          }}
          onCloseText="아니요"
          onAcceptText={formatDialogText(productInfo.status)}
          onAccept={() => {
            handleSetProductStatus(productInfo.status);

            setIsOpenDialog(false);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default ProductHeader;
