import { gql } from '@apollo/client';

export const GET_ME = gql`
  query {
    me {
      chatUser {
        token
        userId
        userNickName
      }
      user {
        id
        email
        mobileNumber
        nickname
        birth
        gender
        verified
        noticeReceiveAgreements {
          id
          channel
          advertised
          agreed
          agreedAt
          updatedAt
        }
        socialLinks {
          id
          socialUserId
          provider
        }
        privateProfile
        joinedAt
        certificated
        invitationCode
        profileImage {
          type
          width
          height
          uri
          thumbnail(
            width: 64
            aspectRatio: "1:1"
            crop: FILL
            fetchFormat: WEBP
          )
        }
        usablePoint {
          availables
        }
      }
      host {
        id
        name
        description
        phoneNumber
        super
        wishInfo {
          count
          isWishedByMe
        }
        productReviewInfo {
          count
        }
        productInfo {
          counts {
            count
            status
          }
        }
        profileImage {
          thumbnail(width: 48, aspectRatio: "1:1", crop: FILL)
        }
        settlement {
          attachments(statusIn: [ACTIVE]) {
            createdAt
            fileKey
            id
            __typename
          }
          businessInfo {
            businessItem
            businessType
            address
            status
            representativeName
            taxPayerName
            taxPayerType
            isRegister
            __typename
          }
          bankAccount {
            account {
              accountNo
              bank {
                code
                name
                __typename
              }
              holder
              __typename
            }
            verified
            verifiedAt
            __typename
          }
          __typename
        }
        commission {
          absent {
            bias
            charge
            type
          }
        }
      }
      issuedCoupons(filter: { used: false }, page: 1, size: 1) {
        totalCount
      }
    }
  }
`;
