import { gql } from '@apollo/client';

export const SAVE_HOST_REGISTRATION_NO = gql`
  mutation SaveHostRegistrationNo($hostId: ID!, $registrationNo: String!) {
    saveHostRegistrationNo(hostId: $hostId, registrationNo: $registrationNo) {
      status
    }
  }
`;

export const SAVE_VERIFIED_HOST_BUSINESS_INFO = gql`
  mutation SaveVerifiedHostBusinessInfo(
    $hostId: ID!
    $param: HostVerifiedBusinessParamInput!
  ) {
    saveVerifiedHostBusinessInfo(hostId: $hostId, param: $param) {
      status
    }
  }
`;

export const SAVE_HOST_BUSINESS_INFO = gql`
  mutation saveHostBusinessInfo($hostId: ID!, $param: HostBusinessParamInput!) {
    saveHostBusinessInfo(hostId: $hostId, param: $param) {
      taxPayerName
      taxPayerType
    }
  }
`;

export const GENERATE_HOST_ATTACHMENT_UPLOAD_URL = gql`
  mutation GenerateHostAttachmentUploadUrl($hostId: ID!, $fileName: String!) {
    generateHostAttachmentUploadUrl(hostId: $hostId, fileName: $fileName) {
      message
      success
      url
      __typename
    }
  }
`;

export const COMPLETE_HOST_ATTACHMENT_UPLOAD = gql`
  mutation completeHostAttachmentUpload($hostId: ID!, $url: String!) {
    completeHostAttachmentUpload(hostId: $hostId, url: $url) {
      createdAt
      fileKey
      id
    }
  }
`;
