import React, { useState } from 'react';
import ModifyContainer from '../ModifyContainer';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import Column from 'components/common/Column';
import Divider from 'components/Divider';
import Text from 'components/common/Text';
import { ModifyValue } from '../../hooks/useProductDetaillHooks';
import { ItemState } from 'types/api';

interface OptionContainerIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  modifyValue: ModifyValue;
  setModifyValue: React.Dispatch<React.SetStateAction<ModifyValue>>;
}

const OptionContainer: React.FC<OptionContainerIProps> = props => {
  const { isMobile, productParamInput, modifyValue, setModifyValue } = props;

  const filterItem = productParamInput.items.filter(
    item => item.status !== ItemState.CLOSED,
  );

  return (
    <ModifyContainer
      title="옵션"
      isSelected={!isMobile && modifyValue.modifyStatus === 'option'}
      onClick={() => {
        setModifyValue({ ...modifyValue, modifyStatus: 'option' });
      }}
    >
      <Column gap="20px" align="flex-start">
        {filterItem.map((item, idx) => {
          return (
            <React.Fragment key={idx}>
              <Column gap="8px" align="flex-start">
                <Text color="#46474C" size="14px" weight={400}>
                  옵션명: {item.name}
                </Text>
                <Text color="#46474C" size="14px" weight={400}>
                  참가비: {Number(item.price.sale).toLocaleString()}원
                </Text>
                <Text color="#46474C" size="14px" weight={400}>
                  판매개수: {item.quota === 0 ? '제한없음' : `${item.quota}개`}
                </Text>
              </Column>
              {filterItem.length !== idx + 1 && <Divider />}
            </React.Fragment>
          );
        })}
      </Column>
    </ModifyContainer>
  );
};

export default OptionContainer;
