import { useLazyQuery } from '@apollo/client';
import { InquiryTemplate } from 'types/api';
import { errorHandler } from 'utils/ErrorHandler';
import { GET_INQUIRYTEMPLATE } from '..';

export function useQueryGetInquiryTemplate() {
  const [getInquiryTemplate, { data, refetch }] = useLazyQuery<{
    product: { inquiryTemplate: InquiryTemplate };
  }>(GET_INQUIRYTEMPLATE);

  const handelGetInquiryTemplate = async (id: string) => {
    try {
      await getInquiryTemplate({ variables: { id } });
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleRefetchInquiryTemplate = async (id: string) => {
    try {
      await refetch({ id });
    } catch (error) {
      errorHandler(error);
    }
  };

  return {
    handelGetInquiryTemplate,
    handleRefetchInquiryTemplate,
    inquiryList: data ? data.product.inquiryTemplate : undefined,
  };
}
