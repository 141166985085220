import React from 'react';
import Row from 'components/common/Row';
import { ICON_ARROW_BLACK } from 'assets/icons';
import Text from 'components/common/Text';

interface NavigateFormIProps {
  icon: any;
  title: string | React.ReactElement;
  badgeContent?: React.ReactElement;
  onClick: () => void;
}

const NavigateForm: React.FC<NavigateFormIProps> = props => {
  const { icon, title, badgeContent, onClick } = props;

  return (
    <Row
      gap="16px"
      justifyContent="space-between"
      style={{ width: '100%', cursor: 'pointer' }}
      onClick={onClick}
    >
      <Row gap="16px">
        <img
          src={icon}
          alt="navigation icon"
          style={{ width: '24px', height: '24px' }}
        />
        <Text color="#1b1c1e" size="16px" weight={400}>
          {title}
        </Text>
      </Row>
      <Row gap="12px">
        {badgeContent && badgeContent}
        <img src={ICON_ARROW_BLACK} alt="navigation icon" />
      </Row>
    </Row>
  );
};

export default NavigateForm;
