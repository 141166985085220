import React from 'react';
import { styled } from 'styled-components';

import AuthButton from 'components/Button/AuthButton';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import { callWebviewHandler, WebviewOperatorType } from 'utils/webview';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import CommonImage from 'components/common/CommonImage';
import { ICON_ARROW_BLUE } from 'assets/icons';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: fixed;
  width: 100%;
  height: 100%;
  border: 1px solid #dbdcdf;
  background: rgba(0, 0, 0, 0.5);
`;

const ToastContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin: 0 auto;
  width: 100%;
  max-width: 375px;

  background: #fff;
  border-radius: 16px 16px 0 0;
`;

interface HostGuideToastIProps {
  handleIsOpenHostGuideToast: () => void;
}

const HostGuideToast: React.FC<HostGuideToastIProps> = props => {
  const { handleIsOpenHostGuideToast } = props;

  return (
    <ModalContainer
      isAbled={true}
      isMobile={true}
      isBottomSheet={true}
      onClose={() => {}}
      onClick={() => {}}
      style={{ gap: '0' }}
    >
      <Row style={{ padding: '16px 20px', borderBottom: '1px solid #EAEBEC' }}>
        <Text color="#1b1c1e" size="16px" weight={600}>
          안내
        </Text>
      </Row>
      <Column gap="24px" align="flex-start" style={{ padding: '24px 20px' }}>
        <Column gap="8px" align="flex-start">
          <Text
            color="#1b1c1e"
            size="20px"
            weight={600}
            style={{ lineHeight: '28px' }}
          >
            호스트로 활동하셨던 계정으로 로그인해 주세요.
          </Text>
          <Text
            color="#1b1c1e"
            size="16px"
            weight={400}
            style={{ lineHeight: '24px' }}
          >
            현재 로그인한 계정에는 호스트 가입 이력이 없습니다. 자세한 내용은
            고객센터로 문의해 주시기 바랍니다.
          </Text>
        </Column>
        <a href="https://pf.kakao.com/_xbxmrqM" target="_blank">
          <Row align="flex-end">
            <Text color="#0071E9" size="16px">
              고객센터 문의하기
            </Text>
            <CommonImage src={ICON_ARROW_BLUE} onClick={() => {}} />
          </Row>
        </a>
      </Column>
      <Row
        style={{
          padding: '16px 20px',
          borderTop: '1px solid #EAEBEC',
        }}
        justifyContent="space-between"
      >
        <Text
          color="#1b1c1e"
          size="16px"
          style={{ textDecoration: 'underline' }}
          onClick={handleIsOpenHostGuideToast}
        >
          닫기
        </Text>
        <AuthButton
          name="다른 계정으로 로그인하기"
          isAbled={true}
          onClick={() => {
            callWebviewHandler(WebviewOperatorType.SubmitLogoutHandler);
          }}
          style={{ width: '188px' }}
        />
      </Row>
    </ModalContainer>
  );
};

export default HostGuideToast;
