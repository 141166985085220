import { useMutation } from '@apollo/client';
import { MeData } from 'graphQL/Auth/types';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import { errorHandler } from 'utils/ErrorHandler';
import { CREATE_LOCATION, DELETE_LOCATION, UPDATE_LOCATION } from '..';
import {
  CreateLocationResult,
  LocationParam,
  UpdateLocationResult,
} from '../types';

export function useMutationUpdateLocation() {
  const [createLocation] = useMutation<CreateLocationResult>(CREATE_LOCATION);
  const [updateLocation] = useMutation<UpdateLocationResult>(UPDATE_LOCATION);
  const [deleteLocation] = useMutation(DELETE_LOCATION);

  const myInfo: MeData = useUserInformation((state: any) => state.userInfo);

  const handleCreateLocation = async (
    param: LocationParam,
    callback: (result: CreateLocationResult) => void
  ) => {
    try {
      const response = await createLocation({
        variables: { hostId: myInfo.me.host?.id, param },
      });

      if (response) {
        callback(response.data!);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateLocation = async (
    id: string,
    param: LocationParam,
    callback: (result: UpdateLocationResult) => void
  ) => {
    try {
      const response = await updateLocation({ variables: { id, param } });

      if (response) {
        callback(response.data!);
      }
    } catch (error) {
      console.log(error, 'location update');
    }
  };

  const handleDeleteLocation = async (id: string, callback: () => void) => {
    try {
      const response = await deleteLocation({ variables: { id } });

      if (response) {
        callback();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  return {
    updateLocation,
    createLocation,
    handleCreateLocation,
    handleUpdateLocation,
    handleDeleteLocation,
  };
}
