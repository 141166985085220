import React from 'react';
import styled from 'styled-components';
import { ICON_ARROW_BLACK_14, ICON_CLOSE } from 'assets/icons';
import CommonImage from 'components/common/CommonImage';
import {
  callWebviewHandler,
  isWebview,
  WebviewOperatorType,
} from 'utils/webview';
import { STORAGE_KEY } from 'utils/localStoarge';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  width: 100%;

  border-bottom: 1px solid #dbdcdf;
  background: #fff;
`;

const HostNoticeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 8px 14px;

  border-radius: 999px;
  background: #f4f4f5;

  color: #1b1c1e;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.4px;
`;

interface GuideHeaderIProps {
  handleIsOpenHostGuideToast: () => void;
}

const GuideHeader: React.FC<GuideHeaderIProps> = props => {
  const { handleIsOpenHostGuideToast } = props;

  const navigate = useNavigate();

  return (
    <Container>
      <CommonImage
        src={ICON_CLOSE}
        onClick={() => {
          if (isWebview()) {
            callWebviewHandler(WebviewOperatorType.CloseWebView);
            localStorage.removeItem(STORAGE_KEY.AUTHORIZATION);

            return;
          }

          navigate(-1);
        }}
      />
      <HostNoticeContainer onClick={handleIsOpenHostGuideToast}>
        프립 호스트로 활동해 본 적이 있나요?
        <img src={ICON_ARROW_BLACK_14} alt="arrow black 13" />
      </HostNoticeContainer>
    </Container>
  );
};

export default GuideHeader;
