import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

import {
  callWebviewHandler,
  isWebview,
  WebviewOperatorType,
} from 'utils/webview';
import { useSettlementListHook } from './hooks/useSettlementListInHook';

import SettlementItem from './components/SettlementItem';
import SettlementFAQ from 'containers/Settlement/components/SettlementFAQ';
import SettlementRequestModal from './components/SettlementRequestModal';
import SettlementListModal from './components/SettlementListModal';

import {
  ICON_ARROW_LEFT,
  ICON_SETTLEMENT_ARROW_LEFT,
  ICON_SETTLEMENT_ARROW_RIGHT,
  ICON_SETTLEMENT_ARROW_LEFT_DISABLE,
  ICON_SETTLEMENT_ARROW_RIGHT_DISABLE,
  ICON_MENU_BUTTON,
  ICON_ARROW_BLACK,
} from 'assets/icons';
import SettlementValidationModal from 'containers/SettlementDetail/components/SettlementValidationModal';
import CommonElementDialog from 'components/Modal/CommonElementDialog';
import { useResizeDetector } from 'react-resize-detector';
import CommonImage from 'components/common/CommonImage';
import MenuBackwardButton from 'components/Button/MenuBackwardButton';
import CommonRow from 'components/common/Row';
import Text from 'components/common/Text';
import BasicButton from 'components/Button/BasicButton';
import { SETTLEMENT_PATH } from 'constants/path';
import HackleManager from 'utils/hackle';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 768px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  width: 100%;
`;

const CalulateAllText = styled.div`
  color: #1b1c1e;
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 18.2px */
  letter-spacing: -0.14px;
`;

const SettlementListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;

  padding: 40px 20px;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
`;

const ArrowContainer = styled.div<{ $isActiveButton: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;

  border-radius: 50%;
  border: ${props =>
    props.$isActiveButton ? '1px solid #c2c4c8' : '1px solid #EAEBEC'};
  background: #fff;
`;

const ProvisionButton = styled.div<{ color: string; background: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;

  border-radius: 8px;
  background: ${props => props.background};
  color: ${props => props.color};
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.6px;
`;

const DescriptionText = styled.div`
  color: #70737c;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.6px;
`;

const SettlementListPage = () => {
  const {
    isLoading,
    pageIndex,
    settlementList,
    settlementItem,
    handleNextSettlementItem,
    handlePrevSettlementItem,
    handleSelectSettlementItem,
    handleRequestInvoiceSettlement,
  } = useSettlementListHook();
  dayjs.locale('ko');

  const navigate = useNavigate();

  const { width, ref } = useResizeDetector();

  const isMobile = width ? width < 768 : window.innerWidth < 768;

  const [isOpenAllSettlementItemModal, setIsOpenAllSettlementItemModal] =
    useState<boolean>(false);
  const [isOpenSettlementRequestModal, setIsOpenSettlementRequestModal] =
    useState<boolean>(false);
  const [
    isOpenSettlementInformationModal,
    setIsOpenSettlementInformationModal,
  ] = useState<boolean>(false);
  const [isOpenCommonDialog, setIsOpenCommonDialog] = useState<boolean>(false);

  const isHaveMinusSettlement =
    settlementList.filter(item => Number(item.node.totalPayment.value) < 0)
      .length !== 0;

  const handlePageBackwardNavigate = () => {
    if (isOpenSettlementInformationModal) {
      setIsOpenSettlementInformationModal(false);

      return;
    }

    if (isOpenSettlementRequestModal) {
      setIsOpenSettlementRequestModal(false);

      return;
    }

    navigate(-1);
  };

  useEffect(() => {
    window.handlePageBackwardNavigate = handlePageBackwardNavigate;
  }, [isOpenSettlementRequestModal, isOpenSettlementInformationModal]);

  useEffect(() => {
    HackleManager.logSettlementListView();
  }, []);

  const renderHeader = (): JSX.Element => {
    if (isMobile) {
      return (
        <Header>
          <CommonImage
            src={ICON_ARROW_LEFT}
            onClick={handlePageBackwardNavigate}
          />
          <CalulateAllText
            onClick={() => {
              setIsOpenAllSettlementItemModal(true);
            }}
          >
            전체기간
          </CalulateAllText>
        </Header>
      );
    }

    return (
      <Header style={{ padding: '40px 20px 0' }}>
        <CommonRow gap="16px">
          <MenuBackwardButton onClick={handlePageBackwardNavigate} />
          <Text color="#1b1c1e" size="24px">
            정산신청
          </Text>
        </CommonRow>
        <CommonRow gap="12px">
          <BasicButton
            content={
              <CommonRow gap="4px">
                <CommonImage
                  src={ICON_MENU_BUTTON}
                  onClick={() => {}}
                  style={{ width: '12px' }}
                />
                <Text color="#1b1c1e">전체기간</Text>
              </CommonRow>
            }
            onClick={() => {
              setIsOpenAllSettlementItemModal(true);
            }}
          />
          <BasicButton
            content={
              <CommonRow gap="4px">
                <Text color="#1b1c1e">정산 정보 관리</Text>
                <CommonImage
                  src={ICON_ARROW_BLACK}
                  onClick={() => {}}
                  style={{ width: '12px' }}
                />
              </CommonRow>
            }
            onClick={() => {
              navigate(SETTLEMENT_PATH);
            }}
          />
        </CommonRow>
      </Header>
    );
  };

  return (
    <React.Fragment>
      {settlementItem && (
        <Container ref={ref}>
          {renderHeader()}
          <SettlementListContainer>
            <Row style={{ width: '100%' }}>
              <SettlementItem
                settlementItem={settlementItem!.node}
                handleOpenSettlementInformationModal={() =>
                  setIsOpenSettlementInformationModal(true)
                }
                handleIsOpenAllSettlementItemModal={() => {
                  setIsOpenAllSettlementItemModal(true);
                }}
              />
              <Row style={{ paddingTop: '15px' }}>
                <ArrowContainer
                  $isActiveButton={
                    settlementList.length !== 1 &&
                    pageIndex < settlementList.length - 1
                  }
                  onClick={handleNextSettlementItem}
                >
                  <img
                    src={
                      settlementList.length !== 1 &&
                      pageIndex < settlementList.length - 1
                        ? ICON_SETTLEMENT_ARROW_LEFT
                        : ICON_SETTLEMENT_ARROW_LEFT_DISABLE
                    }
                    alt="icon settlement arrow left"
                  />
                </ArrowContainer>
                <ArrowContainer
                  $isActiveButton={pageIndex !== 0}
                  onClick={handlePrevSettlementItem}
                >
                  <img
                    src={
                      pageIndex !== 0
                        ? ICON_SETTLEMENT_ARROW_RIGHT
                        : ICON_SETTLEMENT_ARROW_RIGHT_DISABLE
                    }
                    alt="icon settlement arrow right"
                  />
                </ArrowContainer>
              </Row>
            </Row>
            {settlementItem.node.invoice === null &&
              Number(settlementItem.node.totalPayment.value) +
                Number(settlementItem.node.totalFee.value) >
                0 && (
                <ProvisionButton
                  color="#FFF"
                  background="#1B1C1E"
                  onClick={() => {
                    if (isHaveMinusSettlement) {
                      setIsOpenCommonDialog(true);
                    } else {
                      setIsOpenSettlementRequestModal(true);
                    }
                  }}
                >
                  지급 요청
                </ProvisionButton>
              )}
            <SettlementFAQ />
          </SettlementListContainer>
        </Container>
      )}
      {isOpenSettlementInformationModal && (
        <SettlementRequestModal
          type="information"
          settlementItem={settlementItem!.node}
          onClose={() => setIsOpenSettlementInformationModal(false)}
        />
      )}
      {isOpenSettlementRequestModal && (
        <SettlementRequestModal
          type="request"
          settlementItem={settlementItem!.node}
          handleRequestInvoice={handleRequestInvoiceSettlement}
          onClose={() => setIsOpenSettlementRequestModal(false)}
        />
      )}
      {isOpenAllSettlementItemModal && (
        <SettlementListModal
          settlementList={settlementList}
          handleSelectSettlementItem={handleSelectSettlementItem}
          onClose={() => {
            setIsOpenAllSettlementItemModal(false);
          }}
        />
      )}
      {isOpenCommonDialog && (
        <CommonElementDialog
          title="지급 신청 처리 불가 안내"
          description={
            <DescriptionText>
              회수되지 않은{' '}
              <span style={{ color: '#ff4242', fontWeight: 700 }}>
                마이너스 정산서가 있을 경우
              </span>
              , 지급 요청을 처리할 수 없습니다. 추가 문의는 아래 고객센터를 통해
              문의해 주세요.
            </DescriptionText>
          }
          acceptText={
            <a href="https://pf.kakao.com/_NQxnQxd" target="_blank">
              고객센터 문의하기
            </a>
          }
          onClose={() => {
            setIsOpenCommonDialog(false);
          }}
        />
      )}
      {!isLoading && settlementList.length === 0 && (
        <SettlementValidationModal settlementItem={settlementItem} />
      )}
    </React.Fragment>
  );
};

export default SettlementListPage;
