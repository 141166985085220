import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useResizeDetector } from 'react-resize-detector';

import { getEnvConstant } from 'constants/env';

import BasicInformationRegist from './components/BasicInformationRegist';
import OptionSelectRegist from './components/OptionSelectRegist';
import InformationRegist from './components/InformationRegist';

import FloatingContainer from './components/FloatingContainer';
import { useProductRegistHooks } from './hooks/useProductRegistHooks';
import SelectButton from './components/common/SelectButton';
import Row from 'components/common/Row';
import { ICON_CLOSE, ICON_FRIP_LOGO, ICON_QUESTION } from 'assets/icons';
import ModalContainer from './components/common/ModalContainer';
import PreviewProductDetail from './components/PreviewProductDetail';
import { PRODUCT_CREATE_GUIDE } from 'constants/path';
import RegistCompleteContainer from './components/RegistCompleteContainer';
import Text from 'components/common/Text';
import CommonImage from 'components/common/CommonImage';
import Column from 'components/common/Column';
import HackleManager from 'utils/hackle';
import InspectionComepleteModal from './components/ProductInspection/InspectionCompleteModal';
import InspectionFaildeModal from './components/ProductInspection/InspectionFaildeModal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100vh;
  /* min-height: 100vh; */
`;

const ProductRegistPage = () => {
  const {
    registPageNumber,
    productParamInput,
    imageThumbnail,
    isShowCompleteView,
    inquiryList,
    isOpenGuideModal,
    isOpenModal,
    errorMessage,
    setIsShowCompleteView,
    setRegistPageNumber,
    setProductParamInput,
    setImageThumbnail,
    handleOnChangeProductParamInput,
    handleProductTemporarySave,
    isVaildCheckBasicPage,
    handleNavigateBackwardPage,
    handleNavigatePage,
    handleRequestInspection,
    handelGetInquiryTemplate,
    handleRefetchInquiryTemplate,
    handleOpenGuideModal,
  } = useProductRegistHooks();

  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { width, height, ref } = useResizeDetector();

  const [isOpenPreviewProductDetail, setIsOpenPreviewProductDetail] =
    useState<boolean>(false);

  const isMobile = width ? width < 768 : window.innerWidth < 768;

  const handleProductRequestInspection = () => {
    handleRequestInspection(productParamInput.id);
    // const convertingProductParamInput = new ConvertingProductParamInput();

    // const convertingParam = convertingProductParamInput.convertProductParam(
    //   productParamInput,
    //   scheduleList,
    // );

    // if (location.state?.isCopy) {
    //   handleCreateProduct(convertingParam, handleRequestInspection);

    //   return;
    // }

    // if (params.id === 'draft') {
    //   handleCreateProduct(convertingParam, (productId: string) => {
    //     handleRequestInspection(productId);
    //     // handleSaveProduct(convertingParam, productId);
    //   });

    //   return;
    // }

    // handleSaveProduct(convertingParam, undefined, handleRequestInspection);
  };

  useEffect(() => {
    if (isMobile) {
      document.body.style.overflow = '';

      return;
    }

    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = ''; // 컴포넌트가 언마운트될 때 스크롤 복구
    };
  }, [isMobile]);

  const renderWebHeader = (): JSX.Element => {
    if (!isMobile) {
      return (
        <Row
          justifyContent="center"
          style={{
            position: 'sticky',
            top: 0,
            width: '100%',
            background: '#fff',
            borderBottom: '1px solid #dbdcdf',
            // zIndex: 10,
          }}
        >
          <Row
            justifyContent="space-between"
            style={{
              padding: '24px 40px',
              width: '100%',
              maxWidth: '1280px',
            }}
          >
            <CommonImage src={ICON_FRIP_LOGO} onClick={() => navigate('/')} />
            <Row gap="13px">
              <SelectButton
                text={
                  registPageNumber.basicPageNumber.page > 3
                    ? '저장 후 나가기'
                    : '나가기'
                }
                onClick={() => {
                  HackleManager.logCreateCloseClick(
                    registPageNumber.basicPageNumber.page > 3
                      ? '저장 후 나가기'
                      : '나가기',
                  );

                  if (registPageNumber.basicPageNumber.page > 3) {
                    handleProductTemporarySave();

                    return;
                  }

                  navigate(-1);
                }}
              />
              {registPageNumber.page === 3 &&
                registPageNumber.informationPageNumber.page !== 0 && (
                  <a href={PRODUCT_CREATE_GUIDE} target="_blank">
                    <SelectButton
                      text="궁금한점이 있나요?"
                      onClick={() => {}}
                    />
                  </a>
                )}
            </Row>
          </Row>
        </Row>
      );
    }

    if (isShowCompleteView) {
      return (
        <Row
          justifyContent="space-between"
          style={{
            position: 'sticky',
            top: 0,
            padding: '24px',
            width: '100%',
            background: '#fff',
          }}
        >
          <SelectButton
            text="나가기"
            onClick={() => {
              HackleManager.logCreateCloseClick('나가기');

              navigate(-1);
            }}
          />
        </Row>
      );
    }

    if (registPageNumber.basicPageNumber.page > 3) {
      return (
        <Row
          justifyContent="space-between"
          style={{
            position: 'sticky',
            top: 0,
            padding: '24px',
            width: '100%',
            background: '#fff',
            zIndex: 1,
          }}
        >
          {!isShowCompleteView ? (
            <Row gap="12px">
              <SelectButton
                text="저장 후 나가기"
                onClick={() => {
                  HackleManager.logCreateCloseClick('저장 후 나가기');

                  handleProductTemporarySave();
                }}
              />
              <SelectButton
                text="미리보기"
                onClick={() => {
                  HackleManager.logCreatePreviewClick(
                    params.id !== 'draft' ? Number(params.id) : undefined,
                  );
                  setIsOpenPreviewProductDetail(true);
                }}
              />
            </Row>
          ) : (
            <div />
          )}
          {registPageNumber.page === 3 &&
            registPageNumber.informationPageNumber.page !== 0 && (
              <a href={PRODUCT_CREATE_GUIDE} target="_blank">
                <CommonImage
                  src={ICON_QUESTION}
                  onClick={() => {
                    HackleManager.logCreateGuideClick(
                      registPageNumber.page === 1
                        ? '프립 만들기 시작 페이지'
                        : registPageNumber.page === 2
                        ? '판매 정보를 알려주세요'
                        : '프립을 돋보이게 해주세요',
                    );
                  }}
                />
              </a>
            )}
          {((registPageNumber.page === 2 &&
            registPageNumber.optionPageNumber.page !== 0) ||
            registPageNumber.page === 1 ||
            registPageNumber.page !== 3) && (
            <CommonImage
              style={{
                display:
                  registPageNumber.optionPageNumber.page === 0
                    ? 'none'
                    : 'flex',
              }}
              src={ICON_QUESTION}
              onClick={() => {
                HackleManager.logCreateGuideClick(
                  registPageNumber.page === 1
                    ? '프립 만들기 시작 페이지'
                    : registPageNumber.page === 2
                    ? '판매 정보를 알려주세요'
                    : '프립을 돋보이게 해주세요',
                );

                handleOpenGuideModal(true);
              }}
            />
          )}
        </Row>
      );
    }

    return (
      <Row
        justifyContent="space-between"
        style={{
          position: 'sticky',
          top: 0,
          padding: '24px',
          width: '100%',
          background: '#fff',
        }}
      >
        <SelectButton
          text="나가기"
          onClick={() => {
            HackleManager.logCreateCloseClick('나가기');

            navigate(-1);
          }}
        />
        {registPageNumber.basicPageNumber.page !== 0 && (
          <CommonImage
            src={ICON_QUESTION}
            onClick={() => {
              HackleManager.logCreateGuideClick(
                registPageNumber.page === 1
                  ? '프립 만들기 시작 페이지'
                  : registPageNumber.page === 2
                  ? '판매 정보를 알려주세요'
                  : '프립을 돋보이게 해주세요',
              );

              handleOpenGuideModal(true);
            }}
          />
        )}
      </Row>
    );
  };

  const renderRegistPage = (): JSX.Element => {
    switch (registPageNumber.page) {
      case 1: {
        return (
          <BasicInformationRegist
            isMobile={isMobile}
            height={height}
            productParamInput={productParamInput}
            registPageNumber={registPageNumber}
            imageThumbnail={imageThumbnail}
            isOpenGuideModal={isOpenGuideModal}
            setProductParamInput={setProductParamInput}
            handleOnChangeProductParamInput={handleOnChangeProductParamInput}
            handleOpenGuideModal={handleOpenGuideModal}
          />
        );
      }
      case 2: {
        return (
          <OptionSelectRegist
            isMobile={isMobile}
            height={height}
            productParamInput={productParamInput}
            registPageNumber={registPageNumber}
            imageThumbnail={imageThumbnail}
            isOpenGuideModal={isOpenGuideModal}
            setProductParamInput={setProductParamInput}
            handleOnChangeProductParamInput={handleOnChangeProductParamInput}
            handleOpenGuideModal={handleOpenGuideModal}
          />
        );
      }
      case 3: {
        return (
          <InformationRegist
            isMobile={isMobile}
            height={height}
            productParamInput={productParamInput}
            registPageNumber={registPageNumber}
            imageThumbnail={imageThumbnail}
            inquiryList={inquiryList}
            setProductParamInput={setProductParamInput}
            setImageThumbnail={setImageThumbnail}
            handleOnChangeProductParamInput={handleOnChangeProductParamInput}
            handelGetInquiryTemplate={handelGetInquiryTemplate}
            handleRefetchInquiryTemplate={handleRefetchInquiryTemplate}
          />
        );
      }
      default: {
        return <></>;
      }
    }
  };

  return (
    <Container ref={ref}>
      <Helmet>
        <script src="https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js" />
        <script
          src={`//dapi.kakao.com/v2/maps/sdk.js?appkey=${getEnvConstant(
            'kakaoAppKey',
          )}&autoload=false&libraries=services`}
        />
        <script
          src={`https://maps.googleapis.com/maps/api/js?language=ko&key=${getEnvConstant(
            'googleMapApiKey',
          )}`}
        />
      </Helmet>
      {renderWebHeader()}
      {isShowCompleteView ? (
        <React.Fragment>
          <RegistCompleteContainer
            isMobile={isMobile}
            setIsShowCompleteView={setIsShowCompleteView}
            handleProductRequestInspection={handleProductRequestInspection}
          />
          {isOpenModal.success && <InspectionComepleteModal />}
          {isOpenModal.failed && (
            <InspectionFaildeModal
              productParamInput={productParamInput}
              errorMessage={errorMessage}
            />
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {renderRegistPage()}
          <FloatingContainer
            registPageNumber={registPageNumber}
            setRegistPageNumber={setRegistPageNumber}
            isVaildCheckBasicPage={isVaildCheckBasicPage}
            handleNavigateBackwardPage={handleNavigateBackwardPage}
            handleNavigatePage={handleNavigatePage}
          />
        </React.Fragment>
      )}

      {isOpenPreviewProductDetail && (
        <ModalContainer
          isMobile={isMobile}
          isAbled={true}
          style={{ maxWidth: isMobile ? '100%' : '375px', gap: '0' }}
          onClick={() => {}}
          isBottomSheet={true}
          onClose={() => setIsOpenPreviewProductDetail(false)}
        >
          {isMobile && (
            <Column>
              <Row
                style={{
                  position: 'relative',
                  padding: '16px 0',
                  width: '100%',
                }}
              >
                <CommonImage
                  src={ICON_CLOSE}
                  onClick={() => setIsOpenPreviewProductDetail(false)}
                  style={{ position: 'absolute', left: '20px' }}
                />
                <Text
                  color="#1b1c1e"
                  size="16px"
                  weight={600}
                  style={{ padding: '0 20px' }}
                >
                  미리보기
                </Text>
              </Row>
              <Row
                style={{
                  padding: '12px 0',
                  width: '100%',
                  background: 'rgba(27, 28, 30, 0.80)',
                }}
              >
                <Text color="#fff">
                  사용자에게 보여지는 화면을 미리 확인해 보세요.
                </Text>
              </Row>
            </Column>
          )}
          <PreviewProductDetail
            isMobile={isMobile}
            productParamInput={productParamInput}
            imageThumbnail={imageThumbnail}
          />
        </ModalContainer>
      )}
    </Container>
  );
};

export default ProductRegistPage;
