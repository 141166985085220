import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import dayjs from 'dayjs';
import { useOptionBottomSheetHooks } from 'containers/ProductRegist/components/OptionSelectRegist/hooks/useOptionBottomSheetHooks';
import {
  Items,
  ProductParamInput,
  ScheduleParam,
} from 'containers/ProductRegist/hooks/types';
import { OptionSheetType } from 'containers/ProductRegist/components/OptionSelectRegist/hooks/useCalenderHooks';
import { ICON_CLOSE, ICON_PRODUCT_DELETE_RED } from 'assets/icons';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import AuthButton from 'components/Button/AuthButton';
import Column from 'components/common/Column';
import OptionContainer from 'containers/ProductRegist/components/OptionSelectRegist/components/OptionContainer';
import OptionSelectForm from 'containers/ProductRegist/components/OptionSelectRegist/components/OptionSelectForm';
import OptionTermDate from 'containers/ProductRegist/components/OptionSelectRegist/components/OptionTermDate';
import OptionRecruitPeople from 'containers/ProductRegist/components/OptionSelectRegist/components/OptionRecruitPeople';
import OptionDueDateForm from 'containers/ProductRegist/components/OptionSelectRegist/components/OptionDueDateForm';
import OptionModal from 'containers/ProductRegist/components/OptionSelectRegist/components/OptionModal';
import { InventoryTargetType, ScheduleState } from 'types/api';
import { useMutationScheduleStatus } from 'containers/ProductDetail/graphQL/hooks/useMutationScheduleStatus';
import ConfirmDialog from 'components/Modal/ConfirmDialog';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';

const StatusBox = styled.div<{ background: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${props => props.background};
`;

interface CalendarModifyFormIProps {
  productParamInput: ProductParamInput;
  scheduleItem: ScheduleParam;
  checkedDate: any[];
  isMobile: boolean;
  items: Items[];
  optionSheetType: OptionSheetType;
  setScheduleItem: React.Dispatch<React.SetStateAction<ScheduleParam>>;
  setCheckedDate: React.Dispatch<React.SetStateAction<any[]>>;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
  handleClearCalenderData: (isClear: boolean) => void;
  handleOnCloseOptionModal?: () => void;
  handleChangeScheduleItem: (key: string, value: any) => void;
  handleInitScheduleItem: () => void;
  handleGetProductScheduleTerm: () => void;
  handleNavigateSaveButton: () => void;
}

const CalendarModifyForm: React.FC<CalendarModifyFormIProps> = props => {
  const {
    productParamInput,
    scheduleItem,
    isMobile,
    items,
    optionSheetType,
    setScheduleItem,
    setCheckedDate,
    handleOnChangeProductParamInput,
    handleOnCloseOptionModal,
    handleChangeScheduleItem,
    handleGetProductScheduleTerm,
    handleNavigateSaveButton,
  } = props;

  const {
    endDateFormat,
    isOpenOptionModal,
    selectedItems,
    termDate,
    endDueDate,
    setEndDueDate,
    setTermDate,
    setSelectedItems,
    setIsOpenOptionModal,
    setEndDateFormat,
  } = useOptionBottomSheetHooks();

  const twentyFourHoursMs = 24 * 60 * 60 * 1000;

  const [isOverDay, setIsOverDay] = useState<boolean>(
    scheduleItem.term.duration > twentyFourHoursMs,
  );

  const { handleOpenSchedule, handlePauseSchedule } =
    useMutationScheduleStatus();

  const [isOpenOptionDeleteDialog, setIsOpenOptionDeleteDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] =
    useState<boolean>(false);

  const handleUpadteScheduleItem = () => {
    if (
      productParamInput.inventoryTargetType !== InventoryTargetType.BY_ITEM &&
      scheduleItem.minimumQuota > scheduleItem.quota
    ) {
      window.showToast('최대 인원은 최소 인원보다 적을 수 없습니다.', 'failed');

      return;
    }

    if (
      productParamInput.inventoryTargetType !== InventoryTargetType.BY_ITEM &&
      (scheduleItem.minimumQuota === 0 || scheduleItem.quota === 0)
    ) {
      window.showToast('최소/최대인원은 0명으로 설정할 수 없어요.', 'failed');

      return;
    }

    const updatedItems = productParamInput.frip!.schedules.map(item => item);
    const index = productParamInput.frip!.schedules.findIndex(
      item => item.planParamId === scheduleItem.planParamId,
    );

    updatedItems[index] = {
      ...updatedItems[index],
      minimumQuota: Number(scheduleItem.minimumQuota),
      quota: Number(scheduleItem.quota),
    };

    handleOnChangeProductParamInput('frip', {
      ...productParamInput.frip,
      schedules: updatedItems,
    });

    setCheckedDate([]);
    handleNavigateSaveButton();
    handleOnCloseOptionModal && handleOnCloseOptionModal();
  };

  const handleUpdateEditScheduleItem = () => {
    if (
      productParamInput.inventoryTargetType !== InventoryTargetType.BY_ITEM &&
      scheduleItem.minimumQuota > scheduleItem.quota
    ) {
      window.showToast('최대 인원은 최소 인원보다 적을 수 없습니다.', 'failed');

      return;
    }

    if (
      productParamInput.inventoryTargetType !== InventoryTargetType.BY_ITEM &&
      (scheduleItem.minimumQuota === 0 || scheduleItem.quota === 0)
    ) {
      window.showToast('최소/최대인원은 0명으로 설정할 수 없어요.', 'failed');

      return;
    }

    const updatedItems = productParamInput.frip!.schedules.map(item => item);
    const index = productParamInput.frip!.schedules.findIndex(
      item => item.id === scheduleItem.id,
    );
    const oneDayMs = 24 * 60 * 60 * 1000;

    const startedTime = termDate.startDate.split(':');
    const endedTime = termDate.endDate.split(':');
    const startDate = new Date(updatedItems[index].term.startedAt);
    const endDate = new Date(
      startDate.getTime() + oneDayMs * Number(termDate.durationDate),
    );

    startDate.setHours(Number(startedTime[0]));
    startDate.setMinutes(Number(startedTime[1]));
    endDate.setHours(Number(endedTime[0]));
    endDate.setMinutes(Number(endedTime[1]));

    if (isOverDay) {
      endDate.setTime(
        startDate.getTime() + oneDayMs * Number(termDate.durationDate),
      );
    }

    let applicationEndDate = cloneDeep(startDate);

    if (endDateFormat === '1day') {
      applicationEndDate.setDate(applicationEndDate.getDate() - 1);
    }

    if (endDateFormat === '2day') {
      applicationEndDate.setDate(applicationEndDate.getDate() - 2);
    }

    if (endDateFormat === '3day') {
      applicationEndDate.setDate(applicationEndDate.getDate() - 3);
    }

    let applicationStartDate = cloneDeep(applicationEndDate);
    applicationStartDate.setMonth(applicationStartDate.getMonth() - 3);

    console.log(endDate, startDate);

    updatedItems[index] = {
      ...updatedItems[index],
      term: {
        duration: dayjs(endDate).diff(startDate),
        startedAt: startDate.getTime(),
      },
      title: dayjs(startDate).format('YYYY년 M월 D일 HH:mm'),
      saleTerm: {
        startedAt: applicationStartDate.getTime(),
        endedAt: applicationEndDate.getTime(),
      },
      minimumQuota: Number(scheduleItem.minimumQuota),
      quota: scheduleItem.quota,
      itemParamIds: scheduleItem.appliedAllItems
        ? []
        : selectedItems.map(item => item.paramId),
    };

    handleOnChangeProductParamInput('frip', {
      ...productParamInput.frip,
      schedules: updatedItems,
    });

    setCheckedDate([]);
    handleNavigateSaveButton();
    handleOnCloseOptionModal && handleOnCloseOptionModal();
  };

  const handleDeleteScheduleItems = () => {
    handleOnChangeProductParamInput('frip', {
      ...productParamInput,
      schedules: productParamInput.frip?.schedules.filter(
        item => item.planParamId !== scheduleItem.planParamId,
      ),
    });

    setCheckedDate([]);
    handleOnCloseOptionModal && handleOnCloseOptionModal();

    setIsOpenOptionDeleteDialog(false);
  };

  useEffect(() => {
    if (optionSheetType === OptionSheetType.UPDATE) {
      let result: Items[] = [];
      items.filter((item: Items) => {
        if (scheduleItem.itemParamIds.includes(item.paramId))
          result = [...result, item];
      });

      setSelectedItems(result);
    }
  }, [optionSheetType, scheduleItem]);

  const renderStatusItem = (item: ScheduleParam): JSX.Element => {
    switch (item.status) {
      case ScheduleState.OPENED: {
        return <StatusBox background="#0071E9" />;
      }
      case ScheduleState.PAUSED: {
        return <StatusBox background="#FF9200" />;
      }
      case ScheduleState.SOLD_OUT: {
        return <StatusBox background="#DBDCDF" />;
      }
      case ScheduleState.CANCELED: {
        return <StatusBox background="#FF4242" />;
      }
    }

    return;
  };

  const renderScheduleModifyForm = (item: ScheduleParam): JSX.Element => {
    switch (item.status) {
      case ScheduleState.OPENED: {
        return (
          <OptionContainer>
            <Row
              gap="4px"
              style={{ width: '100%' }}
              onClick={() => {
                setIsOpenConfirmDialog(true);
              }}
            >
              <div
                style={{
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  background: '#FF9200',
                }}
              />
              <Text color="#1B1C1E">일정 판매 일시 중지하기</Text>
            </Row>
          </OptionContainer>
        );
      }
      case ScheduleState.PAUSED: {
        return (
          <OptionContainer>
            <Row
              gap="4px"
              style={{ width: '100%' }}
              onClick={() => {
                setIsOpenConfirmDialog(true);
              }}
            >
              <div
                style={{
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  background: '#0071E9',
                }}
              />
              <Text color="#1B1C1E">일정 판매 다시 시작하기</Text>
            </Row>
          </OptionContainer>
        );
      }
    }
  };

  const renderBottomSheet = (): JSX.Element => {
    if (isMobile) {
      return (
        <ModalContainer
          isAbled={
            (selectedItems.length !== 0 || scheduleItem.appliedAllItems) &&
            termDate.startDate !== '' &&
            termDate.endDate !== ''
          }
          onAcceptText="저장"
          isMobile={isMobile}
          onClose={() => {
            handleOnCloseOptionModal && handleOnCloseOptionModal();
          }}
          onClick={() => {
            if (scheduleItem.status === ScheduleState.EDITING) {
              handleUpdateEditScheduleItem();

              return;
            }

            handleUpadteScheduleItem();
          }}
        >
          <Row
            gap="12px"
            style={{
              width: '100%',
              position: 'sticky',
              top: 0,
              padding: '20px',
              background: '#fff',
              zIndex: 3,
            }}
          >
            {renderStatusItem(scheduleItem)}
            <Text color="#1b1c1e" size="16px" weight={600}>
              {dayjs(scheduleItem.term.startedAt).format('YY년 M월 D일 HH:mm')}{' '}
              ~{' '}
              {dayjs(
                scheduleItem.term.startedAt + scheduleItem.term.duration,
              ).format('HH:mm')}
            </Text>
          </Row>
          {renderContents()}
          {renderScheduleStatus()}
        </ModalContainer>
      );
    }

    return (
      <Column>
        <Row
          justifyContent="flex-start"
          gap="12px"
          style={{
            position: 'sticky',
            top: 0,
            padding: '32px 20px',
            width: '100%',
            background: '#fff',
            borderLeft: '1px solid #eaebec',
            borderBottom: '1px solid #eaebec',
            zIndex: 3,
          }}
        >
          {renderStatusItem(scheduleItem)}
          <Text color="#1b1c1e" size="24px" weight={500}>
            {dayjs(scheduleItem.term.startedAt).format('YY년 M월 D일 HH:mm')} ~{' '}
            {dayjs(
              scheduleItem.term.startedAt + scheduleItem.term.duration,
            ).format('HH:mm')}
          </Text>
        </Row>
        <Column gap="16px" style={{ padding: '32px 20px' }}>
          {renderContents()}
          {renderScheduleStatus()}
          <Row justifyContent="flex-end" style={{ width: '100%' }}>
            <AuthButton
              name="적용"
              isAbled={
                (selectedItems.length !== 0 || scheduleItem.appliedAllItems) &&
                termDate.startDate !== '' &&
                termDate.endDate !== ''
              }
              onClick={() => {
                if (scheduleItem.status === ScheduleState.EDITING) {
                  handleUpdateEditScheduleItem();

                  return;
                }
                handleUpadteScheduleItem();
              }}
              style={{ width: '82px' }}
            />
          </Row>
        </Column>
      </Column>
    );
  };

  const renderScheduleStatus = (): JSX.Element => {
    return (
      <Column gap="16px" style={{ padding: isMobile ? '0 20px' : '0' }}>
        {renderScheduleModifyForm(scheduleItem)}
        {scheduleItem.status === ScheduleState.EDITING && (
          <OptionContainer>
            <Row
              gap="4px"
              style={{ width: '100%', cursor: 'pointer' }}
              onClick={() => {
                setIsOpenOptionDeleteDialog(true);
              }}
            >
              <img src={ICON_PRODUCT_DELETE_RED} alt="delete icon" />
              <Text color="#FF4242" size="14px">
                일정 삭제하기
              </Text>
            </Row>
          </OptionContainer>
        )}
      </Column>
    );
  };

  const renderContents = (): JSX.Element => {
    const handleDeleteSelectForm = (item: Items) => {
      setSelectedItems(
        selectedItems.filter(filterItem => filterItem.paramId !== item.paramId),
      );
      setScheduleItem({
        ...scheduleItem,
        itemParamIds: scheduleItem.itemParamIds.filter(
          paramItem => paramItem !== item.paramId,
        ),
      });
    };

    return (
      <Column
        gap="16px"
        align="flex-start"
        style={{ padding: isMobile ? '0 20px' : '0' }}
      >
        <OptionSelectForm
          scheduleItem={scheduleItem}
          selectedItems={selectedItems}
          isMobile={isMobile}
          isDisable={
            scheduleItem.status !== ScheduleState.EDITING &&
            !(optionSheetType === OptionSheetType.CREATE)
          }
          handleDeleteSelectForm={handleDeleteSelectForm}
          handleIsOpenModal={() => {
            setIsOpenOptionModal(true);
          }}
        />
        <OptionTermDate
          scheduleItem={scheduleItem}
          termDate={termDate}
          isOverDay={isOverDay}
          isDisable={
            scheduleItem.status !== ScheduleState.EDITING &&
            !(optionSheetType === OptionSheetType.CREATE)
          }
          setIsOverDay={setIsOverDay}
          setTermDate={setTermDate}
        />
        {!(
          productParamInput.frip.attachedToSchedule &&
          productParamInput.inventoryTargetType === InventoryTargetType.BY_ITEM
        ) && (
          <OptionRecruitPeople
            scheduleItem={scheduleItem}
            isDisable={false}
            handleChangeScheduleItem={handleChangeScheduleItem}
          />
        )}
        <OptionDueDateForm
          scheduleItem={scheduleItem}
          endDateFormat={endDateFormat}
          termDate={termDate}
          endDueDate={endDueDate}
          isDisable={
            scheduleItem.status !== ScheduleState.EDITING &&
            !(optionSheetType === OptionSheetType.CREATE)
          }
          setEndDueDate={setEndDueDate}
          setEndDateFormat={setEndDateFormat}
        />
      </Column>
    );
  };

  const renderConfirmModal = (): JSX.Element => {
    switch (scheduleItem.status) {
      case ScheduleState.OPENED: {
        return (
          <ConfirmDialog
            title="선택한 일정의 판매를 일시 중지 할까요?"
            description="일시 중지 시 크루에게 상품이 노출되지 않아요. 판매중 상태로 변경 시 다시 판매를 시작할 수 있어요."
            onCloseText="아니요"
            onClose={() => {
              setIsOpenConfirmDialog(false);
            }}
            onAcceptText="네, 일시 중지 할게요"
            onAccept={() => {
              setIsOpenConfirmDialog(false);
              handlePauseSchedule(scheduleItem.id, () => {
                handleGetProductScheduleTerm();
              });
            }}
          />
        );
      }
      case ScheduleState.PAUSED: {
        return (
          <ConfirmDialog
            title="선택한 일정의 판매를 다시 시작 할까요?"
            description="일시 중지 시 크루에게 상품이 노출되지 않아요. 판매중 상태로 변경 시 다시 판매를 시작할 수 있어요."
            onCloseText="아니요"
            onClose={() => {
              setIsOpenConfirmDialog(false);
            }}
            onAcceptText="네, 다시 시작 할게요"
            onAccept={() => {
              setIsOpenConfirmDialog(false);
              handleOpenSchedule(scheduleItem.id, () => {
                handleGetProductScheduleTerm();
              });
            }}
          />
        );
      }
    }
  };

  return (
    <>
      {isMobile ? (
        renderBottomSheet()
      ) : (
        <Row
          align="flex-start"
          justifyContent="flex-start"
          style={{
            position: 'sticky',
            top: 0,
            overflowY: 'scroll',
            minWidth: '375px',
            height: '80vh',
          }}
        >
          {renderBottomSheet()}
        </Row>
      )}
      {isOpenOptionModal && (
        <OptionModal
          isMobile={isMobile}
          items={items}
          scheduleItem={scheduleItem}
          selectedItems={selectedItems}
          setScheduleItem={setScheduleItem}
          setSelectedItems={setSelectedItems}
          handleChangeScheduleItem={handleChangeScheduleItem}
          onClose={() => setIsOpenOptionModal(false)}
        />
      )}
      {isOpenOptionDeleteDialog && (
        <ConfirmDialog
          title="선택한 일정을 삭제할까요?"
          description="일정은 저장되지 않고 삭제됩니다."
          onCloseText="아니요"
          onClose={() => {
            setIsOpenOptionDeleteDialog(false);
          }}
          onAcceptText="네, 삭제할게요"
          onAccept={handleDeleteScheduleItems}
        />
      )}
      {isOpenConfirmDialog && renderConfirmModal()}
    </>
  );
};

export default CalendarModifyForm;
