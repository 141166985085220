import React, { useState } from 'react';
import styled from 'styled-components';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import {
  ICON_CATEGORY_SEARCH,
  ICON_CATEGORY_SEARCH_ACTIVE,
  ICON_CLOSE,
} from 'assets/icons';
import Text from 'components/common/Text';
import { useReviewFilterHooks } from '../hooks/useReviewFilterHooks';

const SearchInputContainer = styled.div`
  display: flex;
  gap: 8px;
  padding: 12px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: var(--Static-White, #fff);
`;

const SearchInput = styled.textarea`
  width: 100%;
  border: none;

  color: var(--gray-gray-09, #1b1c1e);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;

  ::placeholder {
    color: #989ba2;
  }
`;

const RegistDateSelectButton = styled.button<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  border-radius: 8px;
  border: ${props =>
    props.$isSelected ? '1.5px solid #1b1c1e' : '1px solid #DBDCDF'};
  background: ${props => (props.$isSelected ? '#f7f7f8' : '#fff')};

  color: var(--gray-gray-09, #1b1c1e);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
`;

interface ReviewFilterBottomSheetIProps {
  isMobile: boolean;
  searchValue: string;
  ratingType: string[];
  ratingsIn: number[];
  handlerSetSearchValue: (e: any) => void;
  handleSetRatingIn: (rating: string) => void;
  handleResetFilterInput: () => void;
  onClose: () => void;
  handleAcceptFilter: (content: string, ratingsIn: number[]) => void;
}

const ReviewFilterBottomSheet: React.FC<
  ReviewFilterBottomSheetIProps
> = props => {
  const {
    isMobile,
    searchValue,
    ratingType,
    ratingsIn,
    handlerSetSearchValue,
    handleSetRatingIn,
    handleResetFilterInput,
    onClose,
    handleAcceptFilter,
  } = props;
  // const {
  //   searchValue,
  //   ratingType,
  //   ratingsIn,
  //   handlerSetSearchValue,
  //   handleSetRatingIn,
  // } = useReviewFilterHooks();

  const renderProductStatus = (): JSX.Element => {
    return (
      <Column gap="12px" align="flex-start">
        <Text color="#1b1c1e" size="16px">
          후기 점수
        </Text>
        <Row
          gap="12px"
          justifyContent="flex-start"
          style={{ width: '100%', flexWrap: 'wrap' }}
        >
          <RegistDateSelectButton
            $isSelected={ratingType.includes('all')}
            onClick={() => {
              handleSetRatingIn('all');
            }}
          >
            전체
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={ratingType.includes('5')}
            onClick={() => {
              handleSetRatingIn('5');
            }}
          >
            5점
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={ratingType.includes('4')}
            onClick={() => {
              handleSetRatingIn('4');
            }}
          >
            4-5점
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={ratingType.includes('3')}
            onClick={() => {
              handleSetRatingIn('3');
            }}
          >
            3-4점
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={ratingType.includes('2')}
            onClick={() => {
              handleSetRatingIn('2');
            }}
          >
            2-3점
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={ratingType.includes('1')}
            onClick={() => {
              handleSetRatingIn('1');
            }}
          >
            1-2점
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={ratingType.includes('0')}
            onClick={() => {
              handleSetRatingIn('0');
            }}
          >
            0.5-1점
          </RegistDateSelectButton>
        </Row>
      </Column>
    );
  };

  return (
    <ModalContainer
      isAbled={true}
      isMobile={isMobile}
      onCloseText="초기화"
      onAcceptText="적용"
      onClose={() => {
        handleResetFilterInput();
      }}
      onClick={() => {
        onClose();

        handleAcceptFilter(searchValue, ratingsIn);
      }}
      style={{ maxWidth: '600px' }}
    >
      <Column gap="24px" style={{ padding: '16px 20px' }}>
        <Row style={{ position: 'relative', width: '100%' }}>
          <img
            src={ICON_CLOSE}
            alt="close icon"
            style={{ position: 'absolute', left: 0, cursor: 'pointer' }}
            onClick={() => {
              onClose();

              handleAcceptFilter(searchValue, ratingsIn);
            }}
          />
          <Text color="#000" size="16px" weight={600}>
            필터
          </Text>
        </Row>
        <Column gap="12px" align="flex-start">
          <Text color="#1b1c1e" size="16px">
            검색어
          </Text>
          <SearchInputContainer>
            <img
              src={
                searchValue !== ''
                  ? ICON_CATEGORY_SEARCH_ACTIVE
                  : ICON_CATEGORY_SEARCH
              }
              alt="search icon"
            />
            <SearchInput
              placeholder="후기 내용을 입력해 주세요."
              value={searchValue}
              onChange={handlerSetSearchValue}
              rows={1}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            />
          </SearchInputContainer>
        </Column>
        {renderProductStatus()}
      </Column>
    </ModalContainer>
  );
};

export default ReviewFilterBottomSheet;
