export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const MOBILE_REGEX = /^01([0|1|6|7|8|9])([0-9]{7,8})$/;

export const PASSWORD_REGEX =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{10,}$/;

// 주민등록번호
export const IDNUMBER_REGEX =
  /^(?:[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01]))-?[1-4][0-9]{6}$/;
// 사업자 등록번호
export const BUSINESSNUMBER_REGEX = /^\d{3}-?\d{2}-?\d{5}$/;
// 휴대폰 번호 *처리
export const PHONE_SECURITY_REGEX = /(\d{3})(\d{4})(\d{4})/;
// 인풋 숫자만 입력제한
export const ONLY_NUMBER_REGEX = /[^0-9]/g;
// 상품 임시저장 확인 정규 표현식
export const SAVE_PRODUCT_REGEX =
  /^\(임시저장\) \d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
