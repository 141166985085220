import { isEmpty } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import {
  BookingItem,
  BookingState,
  OrderItem,
  OrderState,
  OrderTransaction,
  TransactionState,
  TransactionType,
} from 'types/api';
import { CancelItems } from '../hooks/useSaleDetailHooks';

const Container = styled.div<{
  color: string;
  background: string;
  border: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 9px;
  border-radius: 14px;
  border: 1px solid ${props => props.border};
  background-color: ${props => props.background};
  color: ${props => props.color};

  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 140% */
  letter-spacing: -0.2px;
`;

interface OrderStatusButtonIProps {
  item: BookingItem;
  cancelItems: CancelItems;
  status: BookingState;
  orderItems: OrderItem;
}

const OrderStatusButton: React.FC<OrderStatusButtonIProps> = props => {
  const { item, cancelItems, status, orderItems } = props;

  const dependedItems = orderItems.dependedItems;

  const renderStatusButton = (): JSX.Element => {
    if (!isEmpty(dependedItems)) {
      if (cancelItems.request.indexNo.includes(item.indexNo)) {
        return (
          <Container color="#E90000" border="#FF8F8F" background="#FFF2F2">
            취소요청
          </Container>
        );
      }

      if (cancelItems.complete.includes(item.indexNo)) {
        return (
          <Container color="#E90000" border="#FF8F8F" background="#FFF2F2">
            취소완료
          </Container>
        );
      }
    }

    switch (status) {
      case BookingState.APPLIED: {
        return (
          <Container color="#0071E9" border="#0071E9" background="#d4e7ee">
            예약대기
          </Container>
        );
      }
      case BookingState.CONFIRMED: {
        return (
          <Container color="#7126FF" border="#7126FF" background="#E2D3FE">
            예약확정
          </Container>
        );
      }
      case BookingState.CANCELED: {
        return (
          <Container color="#E90000" border="#FF8F8F" background="#FFF2F2">
            취소완료
          </Container>
        );
      }
      case BookingState.ABSENT: {
        return (
          <Container color="#E90000" border="#FF8F8F" background="#FFF2F2">
            불참
          </Container>
        );
      }
      case BookingState.USED: {
        return (
          <Container color="#70737C" border="#DBDCDF" background="#EAEBEC">
            사용완료
          </Container>
        );
      }
      default:
        return <></>;
    }
  };

  return renderStatusButton();
};

export default OrderStatusButton;
