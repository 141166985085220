import React, { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import BasicButton from 'components/Button/BasicButton';
import Row from 'components/common/Row';
import Divider from 'components/Divider';
import { formatModifyText } from 'utils/formatting';
import { Product, ProductState } from 'types/api';
import { getEnvConstant } from 'constants/env';
import { ModifyValue } from '../hooks/useProductDetaillHooks';
import HackleManager from 'utils/hackle';

const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  bottom: 0;
  padding: 24px 0 32px;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid var(--gray-gray-03, #eaebec);
`;

const ProductSaveButton = styled.button<{ $isAbled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  width: 82px;
  border-radius: 8px;
  background: ${props => (props.$isAbled ? '#7126FF' : '#f4f4f5')};

  color: ${props => (props.$isAbled ? '#fff' : '#dbdcdf')};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.6px;
`;

interface ProductBottomIProps {
  productInfo: Product;
  modifyValue: ModifyValue;
  handleSetProductStatus: (status: ProductState) => void;
  handleUpdateProduct: () => void;
  setIsOpenConfirmDialog: Dispatch<SetStateAction<boolean>>;
  setIsOpenCloseProductDetail: Dispatch<SetStateAction<boolean>>;
}

const ProductBottom: React.FC<ProductBottomIProps> = props => {
  const {
    productInfo,
    modifyValue,
    handleSetProductStatus,
    handleUpdateProduct,
    setIsOpenConfirmDialog,
    setIsOpenCloseProductDetail,
  } = props;

  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  return (
    <BottomContainer>
      <Row
        justifyContent="space-between"
        style={{
          padding: '0 40px',
          width: '100%',
          maxWidth: '1280px',
        }}
      >
        <BasicButton
          content="나가기"
          onClick={() => {
            if (modifyValue.isModify) {
              setIsOpenCloseProductDetail(true);

              return;
            }

            navigate(-1);
          }}
          style={{ padding: '12px', borderRadius: '8px' }}
        />
        <Row gap="16px">
          <Row gap="12px">
            {productInfo.status !== ProductState.CLOSED && (
              <BasicButton
                content={`${formatModifyText(productInfo.status)}하기`}
                onClick={() => {
                  setIsOpenConfirmDialog(true);
                }}
                style={{ padding: '12px', borderRadius: '8px' }}
              />
            )}
            <BasicButton
              content={
                <a
                  href={`${getEnvConstant('webClientUrl')}/products/${
                    params.id
                  }`}
                  target="_blank"
                >
                  상품보기
                </a>
              }
              onClick={() => {}}
              style={{ padding: '12px', borderRadius: '8px' }}
            />
          </Row>
          {productInfo.status !== ProductState.CLOSED && (
            <Divider
              style={{ width: '1px', height: '24px', background: '#dbdcdf' }}
            />
          )}
          {productInfo.status !== ProductState.CLOSED && (
            <ProductSaveButton
              $isAbled={modifyValue.isModify}
              onClick={() => {
                if (modifyValue.isModify) {
                  handleUpdateProduct();

                  HackleManager.logProductModifySaveComplete(
                    Number(productInfo.id),
                    productInfo.title,
                  );
                }
              }}
            >
              저장
            </ProductSaveButton>
          )}
        </Row>
      </Row>
    </BottomContainer>
  );
};

export default ProductBottom;
