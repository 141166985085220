import React from 'react';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import CommonImage from 'components/common/CommonImage';
import { ICON_CLOSE } from 'assets/icons';
import Text from 'components/common/Text';
import AuthButton from 'components/Button/AuthButton';

interface SignFailModalIProps {
  content: React.ReactElement;
  onClose: () => void;
}

const SignFailModal: React.FC<SignFailModalIProps> = props => {
  const { content, onClose } = props;

  return (
    <ModalContainer
      isAbled={true}
      isMobile={false}
      isBottomSheet={true}
      onCloseText=" "
      onAcceptText=""
      onClose={onClose}
      onClick={() => {}}
      style={{ maxWidth: '375px' }}
    >
      <Column align="flex-start">
        <Row style={{ padding: '16px 20px' }}>
          <CommonImage src={ICON_CLOSE} onClick={onClose} />
        </Row>
        {content}
        <Row
          justifyContent="flex-end"
          style={{ width: '100%', padding: '16px 20px' }}
        >
          <AuthButton
            name={
              <a href="http://pf.kakao.com/_NQxnQxd" target="_blank">
                고객센터 문의하기
              </a>
            }
            isAbled={true}
            onClick={() => {}}
            style={{ width: '160px' }}
          />
        </Row>
      </Column>
    </ModalContainer>
  );
};

export default SignFailModal;
