import React, { useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { ICON_CLOSE } from 'assets/icons';
import Column from 'components/common/Column';
import { Content } from 'types/api';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 64px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 20;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
  max-width: 375px;
  overflow-y: scroll;

  background-color: #fff;
`;

const SliderContainer = styled(Slider)`
  /* display: flex;
  align-items: center; */
  width: 100%;
  height: 100%;
  /* min-height: ${window.innerWidth}px; */
  overflow-x: hidden;

  button {
    display: none !important;
  }

  .slick-track {
    display: flex;
    align-items: center;
    min-width: 375px;
    width: 100%;
  }

  .slick-slide {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;

const ImageThumbnail = styled.img`
  width: 100%;
  min-width: 375px !important;
  height: 375px;
  object-fit: contain;
`;

const ConuntBox = styled.div`
  display: flex;
  gap: 2px;
  position: absolute;
  bottom: 16px;
  padding: 5px 8px;
  border-radius: 16px;
  background: var(--Grayscale-Opacity-Gray-500, rgba(17, 17, 17, 0.44));

  color: #fff;
  text-align: right;

  /* Font/B50 */
  font-family: SUIT;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.12px;
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  border-radius: 99px;
  border: 1px solid #dbdcdf;
  background-color: #fff;
`;

interface ImageThumbnailSliderIProps {
  imageList: Content[];
  onClose: () => void;
}

const ImageThumbnailSlider: React.FC<ImageThumbnailSliderIProps> = (props) => {
  const { imageList, onClose } = props;

  const [currentIndex, setCurrentIndex] = useState<number>(1);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    afterChange: (index: number) => setCurrentIndex(index + 1),
  };

  return (
    <Container>
      <ContentContainer>
        <Column style={{ position: 'relative' }}>
          <SliderContainer {...settings}>
            {imageList.map((item, idx) => {
              return (
                <ImageThumbnail
                  src={item.uri}
                  alt="product thumbnail"
                  key={idx}
                />
              );
            })}
          </SliderContainer>
          <ConuntBox>
            <span>{currentIndex}</span>
            <span style={{ color: '#ccc' }}>/</span>
            <span style={{ color: '#ccc' }}>{imageList.length}</span>
          </ConuntBox>
        </Column>
      </ContentContainer>
      <CloseButton onClick={onClose}>
        <img src={ICON_CLOSE} alt="close icon" />
      </CloseButton>
    </Container>
  );
};

export default ImageThumbnailSlider;
