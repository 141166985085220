import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

import Column from 'components/common/Column';
import Text from 'components/common/Text';
import Row from 'components/common/Row';
import { EdgesEntity } from '../graphQL/types';
import { ICON_MENU_BUTTON, ICON_SETTLEMENT_IMAGE_UPLOAD } from 'assets/icons';
import StatusButton from './StatusButton';
import { useNavigate } from 'react-router-dom';
import { PRODUCT_DETAIL_PATH, PRODUCT_REGIST_PATH } from 'constants/path';
import { ProductState } from 'types/api';
import HackleManager from 'utils/hackle';
import { formatProductStatus } from 'utils/hackle/parameterFormatting';

const ImageThumbnailContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 16px;
`;

const EmptyThumbnailContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background: var(--gray-gray-02, #f4f4f5);
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const ImageThumbnail = styled.img`
  object-fit: cover;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const IdBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  border-radius: 4px;
  background: var(--Grayscale-Nomal-Gray-100, #eee);

  color: var(--Grayscale-Nomal-Gray-800, #333);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px; /* 130% */
  letter-spacing: -0.1px;
`;

const MenuBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 11px;
  right: 11px;
  cursor: pointer;
`;

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;

  border-radius: 50%;
  border: 1px solid #dbdcdf;
  background: var(--Static-White, #fff);
  cursor: pointer;
`;

interface ProductItemIProps {
  item: EdgesEntity;
  width: number;
  isMobile: boolean;
  isTablet: boolean;
  handleSetSelectProduct: (item: EdgesEntity) => void;
}

const ProductItem: React.FC<ProductItemIProps> = props => {
  const { item, width, isMobile, isTablet, handleSetSelectProduct } = props;

  const navigate = useNavigate();

  const responseWidth = isMobile
    ? '100%'
    : isTablet
    ? `${(width - 120) / 2}px`
    : `${(width - 160) / 3}px`;

  return (
    <Column
      gap="16px"
      style={{
        width: responseWidth,
      }}
      onClick={() => {
        HackleManager.logProductClick(
          Number(item.node.id),
          item.node.title,
          item.node.createdAt,
          item.node.saleTerm?.endedAt,
          formatProductStatus([item.node.status]),
        );

        if (item.node.status === ProductState.CLOSED) {
          window.showToast(
            "해당 상품은 판매 종료된 상품으로 내용 수정이 불가합니다. 다시 판매를 원하시면 호스트지원센터(카카오톡 '@프립호스트')으로 문의 부탁드립니다.",
            'failed',
            5000,
          );
        }

        if (item.node.status === ProductState.REJECTED) {
          handleSetSelectProduct(item);

          return;
        }

        if (item.node.status === ProductState.EDITING) {
          navigate(`${PRODUCT_REGIST_PATH}/${item.node.id}`, {
            state: { isCopy: false },
          });

          return;
        }

        navigate(`${PRODUCT_DETAIL_PATH}/${item.node.id}`);
      }}
    >
      <ImageThumbnailContainer
        $isMobile={isMobile}
        style={{
          width: responseWidth,
        }}
      >
        <StatusButton
          status={item.node.status}
          style={{ position: 'absolute', top: '16px', left: '16px' }}
        />
        <MenuBox
          onClick={e => {
            HackleManager.logProductMenuClick(
              Number(item.node.id),
              item.node.title,
              item.node.createdAt,
              item.node.saleTerm?.endedAt,
              formatProductStatus([item.node.status]),
            );

            e.stopPropagation();
            handleSetSelectProduct(item);
          }}
        >
          <MenuContainer>
            <img
              src={ICON_MENU_BUTTON}
              alt="menu icon"
              style={{ cursor: 'pointer' }}
            />
          </MenuContainer>
        </MenuBox>
        {isEmpty(item.node.headerContents) ? (
          <EmptyThumbnailContainer
            $isMobile={isMobile}
            style={{
              width: responseWidth,
              height: isMobile
                ? `${width - 40}px`
                : isTablet
                ? `${(width - 120) / 2}px`
                : `${(width - 160) / 3}px`,
            }}
          >
            <ImageThumbnail
              src={ICON_SETTLEMENT_IMAGE_UPLOAD}
              alt="product Thumbnail"
              style={{ width: '64px', height: '64px' }}
            />
          </EmptyThumbnailContainer>
        ) : (
          <ImageThumbnail
            src={item.node.headerContents[0].content.thumbnail}
            alt="product Thumbnail"
            style={{
              width: responseWidth,
            }}
          />
        )}
      </ImageThumbnailContainer>
      <Column
        gap="8px"
        align="flex-start"
        style={{
          width: responseWidth,
        }}
      >
        <IdBox>ID {item.node.id}</IdBox>
        <Column gap="12px" align="flex-start">
          <Text color="#1b1c1e" size="16px" weight={600}>
            {item.node.title}
            {/* : `상품 등록중: ${dayjs(item.node.updatedAt).format(
                  '(임시저장) YYYY-MM-DD hh:mm:ss',
                )}`} */}
          </Text>
          <Row gap="4px">
            <Text color="#46474c" size="14px">
              상품 등록일:
            </Text>
            <Text color="#70737C" size="14px">
              {dayjs(item.node.createdAt).format('YY년 M월 D일')}
            </Text>
            <Text color="#C2C4C8" size="14px">
              |
            </Text>
            <Text color="#46474c" size="14px">
              판매 종료일:
            </Text>
            <Text color="#70737C" size="14px">
              {item.node.saleTerm?.endedAt
                ? dayjs(item.node.saleTerm?.endedAt).format('YY년 M월 D일')
                : '-'}
            </Text>
          </Row>
        </Column>
      </Column>
    </Column>
  );
};

export default ProductItem;
