import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import {
  SettlementAccountNode,
  SettlementAccountType,
  SettlementsAccountEdges,
} from 'containers/SettlementList/graphQL/types';

const TableContainer = styled.div`
  display: flex;
  padding: 0 20px;
  overflow: auto;
  width: 100%;
  /* width: ${window.innerWidth}px; */

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Table = styled.table`
  width: auto !important;
  height: 40px;
  table-layout: fixed;
  white-space: nowrap;
  border-collapse: collapse;
  text-align: left;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.084px;
  color: #1b1c1e;

  border-radius: 8px;
  border: 1px solid #eaebec;

  .title {
    th {
      padding: 16px 20px;
      background: #f7f7f8;
      font-weight: 400;
    }
  }

  .item {
    .productName {
      display: flex;
      flex-direction: column;
      padding: 16px 40px 8px 20px;

      .title {
        max-width: 268px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .option {
        color: #70737c;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        letter-spacing: -0.12px;
      }
    }

    td {
      padding: 20px 40px 8px 20px;
      background: #fff;
      font-weight: 500;
    }

    :last-child {
      .productName {
        padding: 16px 40px 16px 20px;
      }

      td {
        padding: 20px 40px 20px 20px;
      }
    }
  }
`;

export const referredType = {
  ABSENCE: '불참 수수료',
  CANCEL_FEE: '취소 수수료',
  COUPON: '쿠폰',
  DIGITAL: '문서',
  ETC: '기타',
  FRIP: '프립',
  GOODS: '배송',
};

interface SettlementTableIProps {
  accountItem: SettlementsAccountEdges[];
}

const SettlementTable: React.FC<SettlementTableIProps> = (props) => {
  const { accountItem } = props;

  return (
    <TableContainer>
      <Table>
        <tbody>
          <tr className="title">
            <th>구분</th>
            <th>결제일</th>
            <th>유형</th>
            <th>상품</th>
            <th>수수료 %</th>
            <th>결제 금액</th>
            <th>수수료</th>
            <th>지급액</th>
            <th>구매자</th>
          </tr>
          {accountItem.map((item: { node: SettlementAccountNode }, idx) => {
            return (
              <tr className="item" key={idx}>
                <td
                  style={{
                    color:
                      item.node.type === SettlementAccountType.PAYABLE
                        ? '#1B1C1E'
                        : '#FF4242',
                  }}
                >
                  {item.node.type === SettlementAccountType.PAYABLE
                    ? '지급'
                    : '회수'}
                </td>
                <td>
                  {dayjs(item.node.order.transactions[0].firmedAt).format(
                    'YY.M.D(dd)'
                  )}
                </td>
                <td>{referredType[item.node.referredType]}</td>
                <td className="productName">
                  <span className="title">
                    {item.node.product === null
                      ? '삭제된 상품입니다.'
                      : item.node.product.listingProduct.title}
                  </span>
                  {item.node.product !== null && (
                    <span className="option">
                      옵션:{' '}
                      {!isEmpty(item.node.product.options)
                        ? item.node.product.options[0].names[0]
                        : ''}
                    </span>
                  )}
                </td>
                <td>
                  {(Number(item.node.commissionFee.value) /
                    (Number(item.node.payment.value) +
                      Number(item.node.commissionFee.value))) *
                    100}
                  %
                </td>
                <td
                  style={{
                    color:
                      item.node.type === SettlementAccountType.PAYABLE
                        ? '#1B1C1E'
                        : '#FF4242',
                  }}
                >
                  {(
                    Number(item.node.payment.value) +
                    Number(item.node.commissionFee.value)
                  ).toLocaleString()}
                  원
                </td>
                <td
                  style={{
                    color: '#FF4242',
                  }}
                >
                  {Number(item.node.commissionFee.value).toLocaleString()}원
                </td>
                <td
                  style={{
                    color:
                      item.node.type === SettlementAccountType.PAYABLE
                        ? '#1B1C1E'
                        : '#FF4242',
                  }}
                >
                  {Number(item.node.payment.value).toLocaleString()}원
                </td>
                <td>{item.node.order.customer.nickname}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default SettlementTable;
