import { useState } from 'react';
import { BankAccountVerifyParamInput } from '../graphQL/type';
import { useMutationVerifyBankAccount } from '../graphQL/hooks/useMutationVerifyBankAccount';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';

export const useRegistBankInformationHook = () => {
  const { handleVerifyBankAccount } = useMutationVerifyBankAccount();

  const myInfo = useUserInformation((state: any) => state.userInfo);

  const [bankInformation, setBankInformation] =
    useState<BankAccountVerifyParamInput>({
      accountNo:
        myInfo.me.host!.settlement!.bankAccount !== null
          ? myInfo.me.host!.settlement!.bankAccount.account.accountNo
          : '',
      code:
        myInfo.me.host!.settlement!.bankAccount !== null
          ? myInfo.me.host!.settlement!.bankAccount.account.bank.code
          : '',
      holder:
        myInfo.me.host?.settlement!.bankAccount !== null
          ? myInfo.me.host!.settlement!.bankAccount.account.holder
          : myInfo.me.host!.settlement!.businessInfo.taxPayerName,
    });
  const [isVerifiedFailed, setIsVerifiedFailed] = useState<boolean>(false);
  const [isOpenBankModal, setIsOpenBankModal] = useState<boolean>(false);

  const handleAcceptBankCode = (code: string) => {
    setBankInformation({ ...bankInformation, code });
  };

  const handleOnChangeAccountNo = (target: string, value: string) => {
    setBankInformation({ ...bankInformation, [target]: value });
  };

  const handleOnClickVerifyBankAccount = () => {
    handleVerifyBankAccount(myInfo.me.host!.id, bankInformation, () =>
      setIsVerifiedFailed(true)
    );
  };

  const handleChangeOpenBankModal = (isOpen: boolean) => {
    setIsOpenBankModal(isOpen);
  };

  const checkValidation = () => {
    if (
      bankInformation.accountNo !== '' &&
      bankInformation.code !== '' &&
      bankInformation.holder !== ''
    ) {
      return true;
    }

    return false;
  };

  return {
    bankInformation,
    isVerifiedFailed,
    isOpenBankModal,
    setIsVerifiedFailed,
    checkValidation,
    handleAcceptBankCode,
    handleOnChangeAccountNo,
    handleOnClickVerifyBankAccount,
    handleChangeOpenBankModal,
  };
};
