import { gql } from '@apollo/client';

export const GET_ORDERS = gql`
  query GetOrderItems($filter: OrderFilterInput, $page: Int, $size: Int) {
    order {
      orders(filter: $filter, page: $page, size: $size) {
        totalCount
        edges {
          node {
            id
            status
            customer {
              id
              nickname
              mobileNumber
              gender
            }
            transactions {
              id
              type
              firm
              firmedAt
              status
              order {
                id
              }
            }
            items {
              count
              remains
              amount {
                value
              }
              dependedItems {
                canceled
              }
              info {
                id
                name
                ticketCount
                saleTerm {
                  startedAt
                  endedAt
                }
                origin {
                  product {
                    frip {
                      attachedToSchedule
                    }
                  }
                }
                options {
                  id
                  name
                }
                product {
                  id
                  title
                }
                host {
                  id
                  name
                }
                origin {
                  type
                }
              }
              transaction {
                id
                type
                firm
                firmedAt
                status
                order {
                  id
                }
              }
              booking {
                counts {
                  total
                  confirmed
                  applied
                }
                tickets {
                  confirmedAt
                  usedAt
                  scheduleTerm {
                    startedAt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PURCHASE_ITEMS = gql`
  query GetPurchaseItems(
    $filter: PurchaseItemFilterInput
    $page: Int
    $size: Int
  ) {
    order {
      purchaseItems(filter: $filter, page: $page, size: $size) {
        totalCount
        edges {
          node {
            id
            transaction {
              id
              type
              firm
              firmedAt
              status
              order {
                id
              }
            }
            info {
              id
              name
              ticketCount
              saleTerm {
                startedAt
                endedAt
              }
              options {
                id
                name
              }
              product {
                id
                title
              }
              host {
                id
                name
              }
              origin {
                type
              }
            }
            schedule {
              id
              term {
                startedAt
                endedAt
                duration
              }
            }
            amount {
              value
              currencyCode
            }
            origin {
              id
              booking {
                id
              }
            }
            count
            canceled
            booking {
              id
              order {
                id
              }
            }
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
`;
