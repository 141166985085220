import React from 'react';
import styled from 'styled-components';

import { ICON_PROFILE_DEFAULT } from 'assets/icons';
import { MeData } from 'graphQL/Auth/types';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';

const ProfileThumbnailContainer = styled.img`
  width: 32px;
  height: 32px;

  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
`;

interface ProfileThumbnailIProps {
  style?: React.CSSProperties;
  onClick?: () => void;
}

const ProfileThumbnail: React.FC<ProfileThumbnailIProps> = (props) => {
  const { style, onClick } = props;
  const myInfo: MeData = useUserInformation((state: any) => state.userInfo);

  return (
    <ProfileThumbnailContainer
      src={
        myInfo.me && myInfo.me.host?.profileImage
          ? myInfo.me.host.profileImage.thumbnail
          : ICON_PROFILE_DEFAULT
      }
      style={{ ...style, cursor: onClick ? 'pointer' : 'auto' }}
      alt="profile thumbnail"
      onClick={() => {
        onClick && onClick();
      }}
    />
  );
};

export default ProfileThumbnail;
