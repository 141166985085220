import { useEffect, useState } from 'react';
import { cloneDeep, isEmpty } from 'lodash';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { Value } from 'react-calendar/dist/cjs/shared/types';
import { DayOfWeek, InventoryTargetType } from 'types/api';
import {
  ProductParamInput,
  ScheduleParam,
} from 'containers/ProductRegist/hooks/types';

dayjs.extend(utc);
dayjs.extend(timezone);

export enum OptionSheetType {
  CREATE = 'create',
  UPDATE = 'update',
}

const formatDayWork = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

export const useCalendarHooks = (
  productParamInput?: ProductParamInput,
  handleOnChangeProductParamInput?: (key: string, value: any) => void,
) => {
  const defaultScheduleItem = {
    term: {
      duration: 0,
      startedAt: 0,
    },
    quota:
      productParamInput &&
      productParamInput.inventoryTargetType === InventoryTargetType.BY_ITEM
        ? 0
        : 10,
    minimumQuota: 1,
    itemParamIds: [],
    appliedAllItems: false,
    maximumPurchasableCount: 0,
    planParamId: null,
    saleTerm: {
      duration: 0,
      startedAt: 0,
      endedAt: 0,
    },
    title: '',
  };

  const [scheduleItem, setScheduleItem] =
    useState<ScheduleParam>(defaultScheduleItem);
  const [optionSheetType, setOptionSheetType] = useState<OptionSheetType>(
    OptionSheetType.CREATE,
  );
  const [isOpenOptionBottomSheet, setIsOpenOptionBottomSheet] =
    useState<boolean>(false);

  const [checkedDate, setCheckedDate] = useState<any[]>([]);
  const [isOpenSelectDateModal, setIsOpenSelectDateModal] =
    useState<boolean>(false);
  const [isClearCalendarData, setIsClearCalenderDate] = useState<boolean>(true);

  const handleSetAddSetCheckedDate = (value: Value) => {
    const date = new Date(
      dayjs(value.toString())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)!
        .toString(),
    );

    const time = date.getTime();

    if (optionSheetType === OptionSheetType.UPDATE) {
      setCheckedDate([time]);

      return;
    }

    if (checkedDate.includes(time)) {
      setCheckedDate(checkedDate.filter(item => item !== time));

      return;
    }

    setCheckedDate([...checkedDate, time]);
  };

  const handleClearCalenderData = (isClear: boolean) => {
    setIsClearCalenderDate(isClear);
  };

  const handleIsOpenUpdateOptionSheet = (param: {
    isOpen: boolean;
    sheetType: OptionSheetType;
    scheduleItem?: ScheduleParam;
  }) => {
    setIsOpenOptionBottomSheet(param.isOpen);
    setOptionSheetType(param.sheetType);
    if (param.scheduleItem) setScheduleItem(param.scheduleItem);
  };

  const handleChangeScheduleItem = (key: string, value: any) => {
    setScheduleItem({ ...scheduleItem, [key]: value });
  };

  const handleInitScheduleItem = () => {
    setScheduleItem(defaultScheduleItem);
  };

  const handleGetSpecificDaysInRange = (
    startDate: number,
    endDate: number,
    selectedDays: DayOfWeek[],
  ) => {
    const result = [];
    const currentDate = new Date(startDate);
    const dateForm = selectedDays.map(item => formatDayWork[item]);

    const today = new Date();
    const ninetyDaysLater = new Date();
    ninetyDaysLater.setDate(today.getDate() + 90);

    // 종료일을 넘어설 때까지 반복
    while (currentDate <= new Date(endDate) && currentDate <= ninetyDaysLater) {
      const dayOfWeek = currentDate.getDay();
      if (dateForm.includes(dayOfWeek)) {
        result.push(new Date(currentDate).getTime());
      }
      // 다음 날짜로 이동
      currentDate.setDate(currentDate.getDate() + 1);
    }

    setCheckedDate(result);
  };

  useEffect(() => {
    if (!isEmpty(productParamInput.frip.schedules)) {
      const schduleItem = cloneDeep(productParamInput.frip.schedules);
      const result: ScheduleParam[] = schduleItem
        .filter(scheduleItems => {
          // scheduleItems.itemParamIds 중 하나라도 items에 존재하면 남김
          return scheduleItems.itemParamIds.some(itemParamId =>
            productParamInput.items.some(item => item.paramId === itemParamId),
          );
        })
        .map(scheduleItems => {
          // scheduleItems.itemParamIds에서 items에 없는 ID 제거
          const validItemParamIds = scheduleItems.itemParamIds.filter(
            itemParamId =>
              productParamInput.items.some(
                item => item.paramId === itemParamId,
              ),
          );

          return {
            ...scheduleItems,
            itemParamIds: validItemParamIds, // 유효한 ID만 남김
          };
        });

      handleOnChangeProductParamInput('frip', {
        ...productParamInput.frip,
        schedules: result,
      });
    }
  }, []);

  return {
    scheduleItem,
    optionSheetType,
    checkedDate,
    isOpenOptionBottomSheet,
    isClearCalendarData,
    isOpenSelectDateModal,
    setScheduleItem,
    setCheckedDate,
    setIsOpenSelectDateModal,
    handleSetAddSetCheckedDate,
    handleChangeScheduleItem,
    handleClearCalenderData,
    setIsOpenOptionBottomSheet,
    handleIsOpenUpdateOptionSheet,
    handleInitScheduleItem,
    handleGetSpecificDaysInRange,
  };
};
