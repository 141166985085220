import React from 'react';
import Row from 'components/common/Row';
import { useNavigate } from 'react-router-dom';
import CommonImage from 'components/common/CommonImage';
import { ICON_FRIP_LOGO } from 'assets/icons';
import { Product } from 'types/api';
import StatusButton from 'containers/ProductList/components/StatusButton';

interface ProductDetailHeaderIProps {
  productInfo: Product;
}

const ProductDetailHeader: React.FC<ProductDetailHeaderIProps> = (props) => {
  const { productInfo } = props;

  const navigate = useNavigate();

  return (
    <Row
      style={{
        position: 'sticky',
        width: '100%',
        borderBottom: '1px solid #DBDCDF',
        background: '#fff',
      }}
    >
      <Row
        justifyContent="space-between"
        style={{
          padding: '24px 40px',
          width: '100%',
          maxWidth: '1280px',
        }}
      >
        <CommonImage
          src={ICON_FRIP_LOGO}
          onClick={() => {
            navigate('/');
          }}
        />
        <StatusButton status={productInfo.status} />
      </Row>
    </Row>
  );
};

export default ProductDetailHeader;
