import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { SEARCH_CATEGORY } from '..';
import { SearchCategory } from '../types';

export function useGetSearchCategory() {
  const [getSearchCategory] = useLazyQuery(SEARCH_CATEGORY);
  const [searchResult, setSearchResult] = useState<SearchCategory>();
  const [isOpenSearchResultForm, setIsOpenSearchResultForm] =
    useState<boolean>(false);

  const handleGetSearchCategory = async (value: string, categoryId: string) => {
    try {
      const response = await getSearchCategory({
        variables: {
          page: 1,
          size: 10,
          filter: {
            likeLabelNameIncludePath: value,
            rootCategoryId: categoryId,
          },
        },
      });

      if (response) {
        setSearchResult(response.data);
        setIsOpenSearchResultForm(true);
      }
    } catch (error) {
      console.log(error, 'search category error');
    }
  };

  return {
    searchResult,
    isOpenSearchResultForm,
    setIsOpenSearchResultForm,
    handleGetSearchCategory,
  };
}
