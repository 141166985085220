import { useMutation } from '@apollo/client';
import { errorHandler } from 'utils/ErrorHandler';
import {
  ABSENT_TICKETS,
  CONFIRM_TICKETS,
  REQUEST_CANCEL_ORDERS,
  RESOTRE_TICKETS_USING,
  RESTORE_TICKETS_ABSENCE,
  RESTORE_TICKETS_CONFIRMATION,
  RETRACT_CANCEL_ORDER_REQUESTS,
  USE_TICKETS,
} from '../bookingTicket';

export function useMutationTicketsSale() {
  const [confirmTickets] = useMutation(CONFIRM_TICKETS); //? 예약 확정
  const [restoreTickets] = useMutation(RESTORE_TICKETS_CONFIRMATION); //? 예약 확정 취소
  const [useTickets] = useMutation(USE_TICKETS); //? 사용처리
  const [restoreUseTickets] = useMutation(RESOTRE_TICKETS_USING); //? 사용처리 취소
  const [absentTickets] = useMutation(ABSENT_TICKETS); //? 불참 처리
  const [restoreTicketsAbsence] = useMutation(RESTORE_TICKETS_ABSENCE); //? 불참처리 취소
  const [requestCancelOrders] = useMutation(REQUEST_CANCEL_ORDERS); //? 주문 취소 요청
  const [retractCancelOrderRequests] = useMutation(
    RETRACT_CANCEL_ORDER_REQUESTS,
  ); //? 주문 취소 철회

  const handleConfirmTickets = async (
    ticketIds: string[],
    term: { startedAt: number; duration: number } | null,
    callback: () => void,
  ) => {
    try {
      const response = await confirmTickets({ variables: { ticketIds, term } });

      if (response) {
        window.showToast('예약일이 확정되었습니다.', 'success');

        callback();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleRestoreTickets = async (
    ticketIds: string[],
    callback: () => void,
  ) => {
    try {
      const response = await restoreTickets({
        variables: { ticketIds, reason: '사유 입력 안함' },
      });

      if (response) {
        window.showToast(
          '예약 확정이 취소 되었습니다. 판매 내역을 확인해 주세요',
          'cancel',
        );

        callback();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleUseTickets = async (
    ticketIds: string[],
    callback: () => void,
  ) => {
    try {
      const response = await useTickets({
        variables: { ticketIds },
      });

      if (response) {
        window.showToast('사용처리가 완료되었습니다.', 'success');

        callback();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleRestoreUseTickets = async (
    ticketIds: string[],
    callback: () => void,
  ) => {
    try {
      const response = await restoreUseTickets({
        variables: { ticketIds, reason: '사유 입력 안함' },
      });

      if (response) {
        window.showToast(
          '사용 처리가 취소 되었습니다. 예약 확정 탭에서 취소된 내역을 확인해 주세요.',
          'cancel',
        );

        callback();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleAbsentTickets = async (
    ticketIds: string[],
    callback: () => void,
  ) => {
    try {
      const response = await absentTickets({
        variables: {
          ticketIds,
        },
      });

      if (response) {
        window.showToast('불참처리가 완료되었습니다.', 'success');

        callback();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleRestoreTicketsAbsence = async (
    ticketIds: string[],
    callback: () => void,
  ) => {
    try {
      const response = await restoreTicketsAbsence({
        variables: {
          ticketIds,
          reason: '사유 입력 안함',
        },
      });

      if (response) {
        window.showToast(
          '불참 처리가 취소 되었습니다. 예약 확정 탭에서 취소된 내역을 확인해 주세요.',
          'cancel',
        );

        callback();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleRequestCancelOrders = async (
    param: any,
    callback: () => void,
  ) => {
    try {
      const response = await requestCancelOrders({ variables: { param } });

      if (response) {
        window.showToast('주문 취소 요청이 완료되었습니다.', 'cancel');

        callback();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleRetractCancelOrderRequests = async (
    ids: string[],
    callback: () => void,
  ) => {
    try {
      const response = await retractCancelOrderRequests({
        variables: { tids: ids },
      });

      if (response) {
        window.showToast('주문 취소 철회가 완료되었습니다.', 'success');

        callback();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  return {
    handleConfirmTickets,
    handleRestoreTickets,
    handleUseTickets,
    handleRestoreUseTickets,
    handleAbsentTickets,
    handleRestoreTicketsAbsence,
    handleRequestCancelOrders,
    handleRetractCancelOrderRequests,
  };
}
