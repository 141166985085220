import React from 'react';
import Lottie from 'lottie-react';
import styled from 'styled-components';
import lottie from 'assets/lotties/loading_lottie.json';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  /* background: rgba(0, 0, 0, 0.4); */
  z-index: 50;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

interface LoadingIProps {
  type?: string;
  style?: React.CSSProperties;
}

const Loading: React.FC<LoadingIProps> = props => {
  const { type, style } = props;

  if (type) {
    return (
      <FlexContainer style={{ ...style }}>
        <Lottie animationData={lottie} style={{ width: '150px' }} />
      </FlexContainer>
    );
  }

  return (
    <Container style={{ ...style }}>
      <Lottie animationData={lottie} style={{ width: '150px' }} />
    </Container>
  );
};

export default Loading;
