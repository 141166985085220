import React, { Dispatch, SetStateAction } from 'react';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import FilterButton from 'containers/SaleList/components/FilterButton';
import { TicketFilterInput } from '../hooks/useBookingListHooks';
import { TicketOrderType, TicketState } from 'types/api';
import { BookingTermType } from '../graphQL/types';

interface BookingTicketStatusFilterIProps {
  ticketFilterInput: TicketFilterInput;
  setTicketFilterInput: Dispatch<SetStateAction<TicketFilterInput>>;
  handleResetSelectTickets: () => void;
}

const BookingTicketStatusFilter: React.FC<
  BookingTicketStatusFilterIProps
> = props => {
  const { ticketFilterInput, setTicketFilterInput, handleResetSelectTickets } =
    props;

  const startedAt = new Date();
  startedAt.setMonth(startedAt.getMonth() - 1);
  startedAt.setHours(0, 0, 0, 0);

  return (
    <Column align="flex-start" gap="24px" style={{ padding: '0 20px' }}>
      <Row gap="12px">
        <FilterButton
          isSelected={ticketFilterInput.filter.statusIn.includes(
            TicketState.CONFIRMED,
          )}
          content="예약확정"
          onClick={() => {
            handleResetSelectTickets();

            setTicketFilterInput({
              ...ticketFilterInput,
              page: 1,
              filter: {
                ...ticketFilterInput.filter,
                statusIn: [TicketState.CONFIRMED],
                orderType: TicketOrderType.SCHEDULE_EARLIEST,
                termWithType: {
                  term: { startedAt: startedAt.getTime() },
                  type: BookingTermType.STARTING,
                },
              },
            });
          }}
        />
        <FilterButton
          isSelected={ticketFilterInput.filter.statusIn.includes(
            TicketState.USED,
          )}
          content="사용완료"
          onClick={() => {
            handleResetSelectTickets();

            setTicketFilterInput({
              ...ticketFilterInput,
              page: 1,
              filter: {
                ...ticketFilterInput.filter,
                statusIn: [TicketState.USED],
                orderType: TicketOrderType.SCHEDULE_NEAREST,
                termWithType: null,
              },
            });
          }}
        />
        <FilterButton
          isSelected={ticketFilterInput.filter.statusIn.includes(
            TicketState.ABSENT,
          )}
          content="예약불참"
          onClick={() => {
            handleResetSelectTickets();

            setTicketFilterInput({
              ...ticketFilterInput,
              page: 1,
              filter: {
                ...ticketFilterInput.filter,
                statusIn: [TicketState.ABSENT],
                orderType: TicketOrderType.SCHEDULE_NEAREST,
                termWithType: null,
              },
            });
          }}
        />
      </Row>
    </Column>
  );
};

export default BookingTicketStatusFilter;
