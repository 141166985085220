import { gql } from '@apollo/client';

export const GET_SCHEDULE_BYTERM = gql`
  query GetScheduleByTerm(
    $filter: ScheduleFilterInput
    $page: Int
    $size: Int
  ) {
    product {
      schedules(filter: $filter, page: $page, size: $size) {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            id
            term {
              startedAt
              duration
              endedAt
            }
            saleTerm {
              startedAt
              endedAt
            }
            status
            booking {
              counts {
                total
                applied
                confirmed
                used
                absent
              }
            }
            product {
              title
              catchphrase
              kind
              status
              saleTerm {
                startedAt
                endedAt
              }
              frip {
                kind
                attachedToSchedule
                bookingConfirmationEnabled
                difficulty
                isIndoor
                isOutdoor
                locationsOfVenue {
                  id
                  label
                  address
                  geoPoint {
                    latitude
                    longitude
                  }
                  overseas
                  location {
                    id
                    name
                  }
                }
                locationOfGathering {
                  id
                  label
                  address
                  geoPoint {
                    latitude
                    longitude
                  }
                  overseas
                  location {
                    id
                    name
                  }
                }
                plans {
                  id
                  appliedAllItems
                  name
                  minimumQuota
                  preparatoryPeriod
                  quota
                  overNight
                  term {
                    startedAt
                    endedAt
                  }
                  targetItems {
                    id
                  }
                  times {
                    starting {
                      hour
                      minute
                    }
                    duration
                  }
                  weeks
                }
                curriculum {
                  kind
                  sections {
                    id
                    title
                    etc
                    status
                    items {
                      id
                      term {
                        startingTime {
                          hour
                          minute
                        }
                        endingTime {
                          hour
                          minute
                        }
                      }
                      duration
                      textTime
                      content
                    }
                  }
                  style
                }
                exclusions
                inclusions
                stuffsToPrepare
                daysOfExpiration
                recommendedAge
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_BOOKING_LIST = gql`
  query GetBookingListByFilter(
    $filter: BookingFilterInput
    $page: Int
    $size: Int
  ) {
    booking {
      bookings(filter: $filter, page: $page, size: $size) {
        totalCount
        edges {
          node {
            id
            status
            tickets {
              id
              confirmedAt
              usedAt
              scheduleTerm {
                startedAt
              }
            }
            customer {
              id
              nickname
              mobileNumber
              gender
            }
            order {
              id
              transactions {
                id
                type
                firm
                firmedAt
                status
                order {
                  id
                }
              }
            }
            info {
              id
              name
              options {
                id
                name
              }
              product {
                id
                title
              }
              host {
                id
                name
              }
              origin {
                type
                product {
                  saleTerm {
                    startedAt
                    endedAt
                  }
                }
                saleTerm {
                  startedAt
                  endedAt
                }
              }
              ticketCount
            }
            schedule {
              id
              term {
                startedAt
                endedAt
                duration
              }
            }
            items {
              tickets {
                id
                absentAt
                canceledAt
                availableActions
                scheduleTerm {
                  startedAt
                  endedAt
                  duration
                }
                confirmedAt
                usedAt
                status
                sequence
                bookingItem {
                  booking {
                    id
                    customer {
                      id
                      nickname
                      mobileNumber
                      gender
                    }
                    info {
                      id
                      name
                      options {
                        id
                        name
                      }
                      product {
                        id
                        title
                      }
                      host {
                        id
                        name
                      }
                      origin {
                        type
                        product {
                          saleTerm {
                            startedAt
                            endedAt
                          }
                        }
                        saleTerm {
                          startedAt
                          endedAt
                        }
                        inquiryTemplate {
                          inquiries {
                            title
                            items {
                              label
                              value
                            }
                          }
                        }
                      }
                      ticketCount
                    }
                    schedule {
                      id
                      term {
                        startedAt
                        endedAt
                        duration
                      }
                    }
                  }
                }
              }
            }
            purchaseCount
            counts {
              total
              applied
              confirmed
              used
              absent
            }
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
`;

export const GET_BOOKING_TICKETS = gql`
  query GetBookingTickets($filter: TicketFilterInput, $page: Int, $size: Int) {
    booking {
      tickets(filter: $filter, page: $page, size: $size) {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            id
            absentAt
            canceledAt
            availableActions
            scheduleTerm {
              startedAt
              endedAt
              duration
            }
            confirmedAt
            usedAt
            status
            sequence
            bookingItem {
              booking {
                customer {
                  id
                }
                id
                status
                purchaseCount
                customer {
                  id
                  nickname
                  mobileNumber
                  gender
                }
                info {
                  id
                  name
                  options {
                    id
                    name
                  }
                  product {
                    id
                    title
                  }
                  host {
                    id
                    name
                  }
                  origin {
                    type
                    product {
                      saleTerm {
                        startedAt
                        endedAt
                      }
                      frip {
                        attachedToSchedule
                      }
                    }
                    saleTerm {
                      startedAt
                      endedAt
                    }
                    inquiryTemplate {
                      inquiries {
                        title
                        items {
                          label
                          value
                        }
                      }
                    }
                  }
                  ticketCount
                }
                schedule {
                  id
                  term {
                    startedAt
                    endedAt
                    duration
                  }
                }
                order {
                  id
                  items {
                    units(includeCancel: true) {
                      id
                      indexNo
                      replies {
                        id
                        indexNo
                        title
                        answer
                        selections {
                          value
                          label
                        }
                      }
                      status
                    }
                  }
                  transactions {
                    firmedAt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
