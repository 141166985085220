import { create } from 'zustand';

import { MeData } from 'graphQL/Auth/types';
import { STORAGE_KEY } from 'utils/localStoarge';

export const useUserInformation = create((set: any) => ({
  userInfo: localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo') as string)
    : {},
  isLoggedIn: localStorage.getItem(STORAGE_KEY.AUTHORIZATION) ? true : false,
  setUserInfo: (data: MeData) => {
    set({ userInfo: data });
    localStorage.setItem('userInfo', JSON.stringify(data));
  },
  clearUserInfo: () => {
    set({ userInfo: {} });
    localStorage.removeItem('userInfo');
    localStorage.removeItem(STORAGE_KEY.AUTHORIZATION);
  },
}));
