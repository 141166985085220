import React from 'react';
import Row from 'components/common/Row';
import FilterButton from 'containers/SaleList/components/FilterButton';
import { NoticeFilter, NoticeType } from '../graphQL/types';

interface FilterContainerIProps {
  noticeFilter: NoticeFilter;
  handleSetFilterItem: (key: string, value: any) => void;
}

const FilterContainer: React.FC<FilterContainerIProps> = (props) => {
  const { noticeFilter, handleSetFilterItem } = props;

  return (
    <Row gap="12px">
      <FilterButton
        isSelected={!noticeFilter.filter.type}
        content="전체"
        onClick={() => {
          handleSetFilterItem('filter', { ...noticeFilter.filter, type: null });
        }}
      />
      <FilterButton
        isSelected={noticeFilter.filter.type === NoticeType.HOST_NOTICE}
        content="공지"
        onClick={() => {
          handleSetFilterItem('filter', {
            ...noticeFilter.filter,
            type: NoticeType.HOST_NOTICE,
          });
        }}
      />
      <FilterButton
        isSelected={noticeFilter.filter.type === NoticeType.HOST_COMMON}
        content="일반"
        onClick={() => {
          handleSetFilterItem('filter', {
            ...noticeFilter.filter,
            type: NoticeType.HOST_COMMON,
          });
        }}
      />
      <FilterButton
        isSelected={noticeFilter.filter.type === NoticeType.HOST_EVENT}
        content="이벤트"
        onClick={() => {
          handleSetFilterItem('filter', {
            ...noticeFilter.filter,
            type: NoticeType.HOST_EVENT,
          });
        }}
      />
    </Row>
  );
};

export default FilterContainer;
