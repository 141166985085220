import {
  CurriculumParam,
  ProductParamInput,
} from 'containers/ProductRegist/hooks/types';
import { CommonState, CurriculumKind, CurriculumStyle } from 'types/api';

export const useCurriculumHooks = (
  productParamInput: ProductParamInput,
  handleOnChangeProductParamInput: (key: string, value: any) => void,
) => {
  const sectionItems = {
    title: '',
    items: [
      {
        duration: 0,
        term: {
          startingTime: {
            hour: 0,
            minute: 0,
          },
          endingTime: {
            hour: 0,
            minute: 0,
          },
        },
        textTime: '',
        content: '',
      },
    ],
    etc: '',
    isOpenEtc: false,
    status: CommonState.INACTIVE,
  };

  const curriculumParams: CurriculumParam = {
    style: CurriculumStyle.DURATION,
    sections: [sectionItems],
    kind: CurriculumKind.SCHEDULE,
  };

  const handleInitCurriculum = () => {
    handleOnChangeProductParamInput('frip', {
      ...productParamInput.frip,
      curriculum: curriculumParams,
    });
  };

  const handleResetCurriculum = (style: CurriculumStyle) => {
    handleOnChangeProductParamInput('frip', {
      ...productParamInput.frip,
      curriculum: {
        ...curriculumParams,
        style,
      },
    });
  };

  const handleAddCurriculumSections = () => {
    handleOnChangeProductParamInput('frip', {
      ...productParamInput.frip,
      curriculum: {
        ...productParamInput.frip?.curriculum,
        sections: [
          ...productParamInput.frip!.curriculum.sections,
          sectionItems,
        ],
      },
    });
  };

  const handleAddCurriculumItems = (idx: number) => {
    const curriculumItem = productParamInput.frip!.curriculum;
    const updatedItems = [...curriculumItem.sections];

    const addItems = {
      duration: 0,
      term: {
        startingTime: {
          hour: 0,
          minute: 0,
        },
        endingTime: {
          hour: 0,
          minute: 0,
        },
      },
      textTime: '',
      content: '',
    };

    updatedItems[idx] = {
      ...updatedItems[idx],
      items: [...updatedItems[idx].items, addItems],
    };

    handleOnChangeProductParamInput('frip', {
      ...productParamInput.frip,
      curriculum: {
        ...productParamInput.frip?.curriculum,
        sections: updatedItems,
      },
    });
  };

  const handleDeleteCurriculumItems = (idx: number) => {
    if (productParamInput.frip.curriculum.sections.length > 1) {
      handleOnChangeProductParamInput('frip', {
        ...productParamInput.frip,
        curriculum: {
          ...productParamInput.frip.curriculum,
          sections: productParamInput.frip?.curriculum.sections.filter(
            (item, sectionIdx) => sectionIdx !== idx,
          ),
        },
      });

      return;
    }

    if (productParamInput.frip.curriculum.sections.length === 1) {
      handleOnChangeProductParamInput('frip', {
        ...productParamInput.frip,
        curriculum: {
          style: CurriculumStyle.NONE,
          sections: [],
          kind: CurriculumKind.SCHEDULE,
        },
      });
    }
  };

  const handleRemoveCurriculumItems = (idx: number) => {
    handleOnChangeProductParamInput('frip', {
      ...productParamInput.frip,
      curriculum: {
        ...productParamInput.frip?.curriculum,
        sections: productParamInput.frip?.curriculum.sections.map(section => {
          return {
            ...section,
            items: section.items.filter(
              (item, sectionIdx) => sectionIdx !== idx,
            ),
          };
        }),
      },
    });
  };

  return {
    handleResetCurriculum,
    handleInitCurriculum,
    handleAddCurriculumSections,
    handleAddCurriculumItems,
    handleDeleteCurriculumItems,
    handleRemoveCurriculumItems,
  };
};
