import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { ICON_SETTLEMENT_VALIDATION } from 'assets/icons';
import Row from 'components/common/Row';

const Container = styled.div<{
  $focused: boolean;
  $styleType: string;
  $isVaildCheck: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;

  width: 100%;

  border: ${(props) =>
    !props.$isVaildCheck
      ? '1px solid #C13515 !important'
      : props.$focused
      ? '2px solid #1B1C1E !important'
      : props.$styleType === 'large'
      ? '1px solid #999'
      : 'none'};
  border-bottom: ${(props) =>
    props.$styleType !== 'bottom' && !props.$focused
      ? '1px solid #999'
      : !props.$isVaildCheck
      ? '1px solid #C13515'
      : props.$focused && '2px solid #1B1C1E'};
  background-color: ${(props) => (props.$isVaildCheck ? '#fff' : '#FDF4F2')};
  border-radius: ${(props) =>
    props.$styleType === 'top'
      ? '8px 8px 0 0'
      : props.$styleType === 'bottom'
      ? '0 0 8px 8px'
      : props.$styleType === 'large'
      ? '8px'
      : props.$focused && props.$styleType === 'top'
      ? '8px 8px 0 0'
      : props.$focused && props.$styleType === 'bottom'
      ? '0 0 8px 8px'
      : props.$focused && props.$styleType === 'center'
      ? '0'
      : props.$focused
      ? '8px'
      : '0'};
`;

const Label = styled.div<{ $focused: boolean }>`
  display: flex;
  align-items: center;
  gap: 3px;

  top: ${(props) => props.$focused && '10px'};

  font-family: Pretendard;
  font-size: ${(props) => (props.$focused ? '12px' : '16px')};
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  z-index: 6;

  ${({ $focused }) =>
    $focused
      ? css`
          position: static;
        `
      : css`
          position: absolute;
          left: 12px;
        `}
`;

const Input = styled.input`
  width: 100%;
  height: 30px;

  color: #1b1c1e;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  caret-color: #c2c4c8;
  line-height: 150%;
  z-index: 7;
  border: none;
  background: none;

  ::placeholder {
    color: #c2c4c8;
  }
`;

interface CalculateInputIProps {
  type: string;
  styleType: string;
  label: string;
  target: string;
  informationValue: string;
  validation?: RegExp;
  onChange: (target: string, value: string) => void;
  handleCheckIsFocusecInput?: (isFocused: boolean, label: string) => void;
}

const SettlementInput: React.FC<CalculateInputIProps> = (props) => {
  const {
    type,
    styleType,
    label,
    target,
    informationValue,
    validation,
    onChange,
    handleCheckIsFocusecInput,
  } = props;

  const [value, setValue] = useState<string>(informationValue);
  const [isFocused, setIsFocused] = useState(false);
  const [isVaildCheck, setIsValidCheck] = useState<boolean>(true);

  const handleChange = (e: any) => {
    e.preventDefault();

    if (target === '') {
      let value = e.target.value.replace(/[^0-9]/g, '');

      if (label === '사업자등록증 번호') {
        if (value.length > 3 && value.length <= 5) {
          value = value.slice(0, 3) + '-' + value.slice(3);
        } else if (value.length > 5) {
          value =
            value.slice(0, 3) + '-' + value.slice(3, 5) + '-' + value.slice(5);
        }
      } else {
        if (value.length > 6) {
          value = value.slice(0, 6) + '-' + value.slice(6);
        }
      }

      setValue(value);
      onChange(target, value);
      handleValidCheck(e.target.value);

      return;
    }

    setValue(e.target.value);
    onChange(target, e.target.value);

    handleValidCheck(e.target.value);
  };

  const handleFocus = () => {
    setIsFocused(true);

    if (handleCheckIsFocusecInput) {
      handleCheckIsFocusecInput(true, label);
    }
  };

  const handleOnBlur = () => {
    setIsFocused(false);

    if (handleCheckIsFocusecInput) {
      handleCheckIsFocusecInput(false, label);
    }
  };

  const handleValidCheck = (value: string) => {
    if (validation) {
      if (validation.test(value)) {
        setIsValidCheck(true);
      } else {
        setIsValidCheck(false);
      }
    }
  };

  return (
    <Row gap="12px" style={{ width: '100%', position: 'relative' }}>
      <Container
        $focused={isFocused}
        $isVaildCheck={isVaildCheck}
        $styleType={styleType}
        style={{
          padding: isFocused || value !== '' ? '8px 12px' : '16px 12px',
        }}
      >
        <Label $focused={isFocused || value !== ''}>
          {label}
          {!isVaildCheck && (
            <img
              src={ICON_SETTLEMENT_VALIDATION}
              alt="validation"
              style={{ paddingBottom: '2px' }}
            />
          )}
        </Label>
        <Input
          type={type}
          name={label}
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleOnBlur}
        />
      </Container>
    </Row>
  );
};

export default SettlementInput;
