import {
  CommissionType,
  CompanionType,
  Curriculum,
  CurriculumKind,
  CurriculumSection,
  CurriculumStyle,
  DayOfWeek,
  FixedTerm,
  FripKind,
  FripLocation,
  GeoPoint,
  InteractionType,
  InventoryTargetType,
  ItemState,
  LevelOfDifficulty,
  Price,
  ProductCategory,
  ProductHeaderContentStyle,
  ProductKind,
  ScheduleState,
  Term,
} from 'types/api';

export interface ProductParamInput {
  id?: string;
  kind: ProductKind | null;
  attributeIds: string[];
  catchphrase: string;
  categories: ProductCategoryParam[];
  title: string;
  standardCategoryId: string;
  inventoryTargetType: InventoryTargetType | null;
  items: Items[];
  saleTerm?: Term | null;
  grossPurchaseLimitation: { count: number };
  cancelingRestricted: boolean;
  exposedChannelIds: string[];
  externalProvisionIds: string[];
  options: any[];
  tagIds: string[];
  underageRestricted: boolean;
  inventoryTargetOptionLevel: number;
  maximumPurchasableCount: number;
  frip?: FripSaleInfoParam;
  notice: string;
  keywords: string[];
  htmlDetailContent: string;
  inquiryTemplateId?: string | null;
  headerContents: HeaderContents[];
  updatedAt?: string;
  firstOpenedAt?: number;
}

export interface HeaderContents {
  contentId: string;
  style: ProductHeaderContentStyle;
  uri?: string;
}

export interface Items {
  id?: string;
  checked?: boolean; // 리스트에서 체크를 위해 만듬! 등록할땐 삭제
  absentFee: Commission;
  commission: Commission;
  daysOfExpiration: number;
  description: string;
  inquiryTemplateId?: string;
  maximumPurchasableCount: number;
  minimumQuota: number;
  name: string;
  salesType: SalesType;
  options: Options[];
  paramId: string;
  price: Price;
  quota: number;
  status?: ItemState;
  representative: boolean;
  saleTerm?: Term | null;
  scheduledBySale: boolean;
  ticketCount: number;
  digitalContent?: { uri: string };
}

export interface Options {
  id?: string;
  name: string;
  title: string;
}

export interface Commission {
  bias: string;
  charge: string;
  type: CommissionType;
}

export enum SalesType {
  AGENCY = 'AGENCY', // 판매 대행(순액): 수수료가 프립꺼
  PURCHASE = 'PURCHASE', // 사입: 호스트에게 미리 상품을 선구매하여 판매 ! 매출 전부 다 프립꺼
}

export interface FripSaleInfoParam {
  curriculum: Curriculum;
  kind: FripKind;
  attachedToSchedule: boolean | null;
  bookingConfirmationEnabled: boolean;
  schedulingTerm?: FixedTerm;
  daysOfExpiration: number;
  plans: PlanParam[];
  schedules: ScheduleParam[];
  exclusions: string[];
  inclusions: string[];
  locationOfGathering?: FripLocationParam | null;
  locationsOfVenue: FripLocationParam[];
  stuffsToPrepare: string[];
  difficulty: LevelOfDifficulty | null;
  recommendedAge: number | null;
  interactionType: InteractionType;
  companions: CompanionType[];
  //TODO 후에 옵셔널 제거 필요
  isIndoor?: boolean;
  isOutdoor?: boolean;
}

export interface ProductCategoryParam {
  displayCategoryId: string;
  representative: boolean;
}

export interface CurriculumParam {
  kind: CurriculumKind;
  sections: CurriculumSection[];
  style: CurriculumStyle;
}

export interface FripLocationParam {
  address: string;
  geoPoint?: GeoPoint;
  id?: string;
  label: string;
  locationId?: string;
  overseas: boolean;
}

export interface ProductOptionParam {
  id?: string;
  names: string[];
  title: string;
}

export interface DigitalContentParam {
  uri: string;
}

export interface ItemOptionParam {
  id?: string;
  name: string;
  title: string;
}

export interface CommissionParam {
  charge: number;
  bias: number;
  type: CommissionType;
}

export interface PlanParam {
  id?: string;
  appliedAllItems: boolean;
  paramId: string; // 임시로 값을 채워넣어야함
  minimumQuota: number;
  name: string;
  overNight: boolean;
  preparatoryPeriod: number; // duration(ms 단위)
  quota: number;
  targetItemParamIds: string[]; // 임시로 값을 채워넣어야함
  term: FixedTerm;
  times: ScheduleTimeParam[];
  weeks: DayOfWeek[];
}

export interface ScheduleTimeParam {
  paramId?: string; // 임시로 만듬
  starting: {
    hour: number;
    minute: number;
  };
  duration: number;
}

export interface ScheduleTermParam {
  duration: number;
  startedAt: number;
  endedAt?: number;
}

export interface ScheduleParam {
  id?: string;
  appliedAllItems: boolean;
  paramId?: string; // unique값이 필요해서 만듬 등록/수정에서는 삭제
  itemParamIds: string[];
  maximumPurchasableCount: number;
  minimumQuota: number;
  quota: number;
  planParamId?: string | null;
  saleTerm: Term;
  term: ScheduleTermParam;
  title: string;
  status?: ScheduleState;
}

const registParams = {
  kind: 'GOODS',
  attributeIds: ['9', '10', '12'],
  catchphrase: '',
  categories: [],
  title: 'tewtesst',
  inventoryTargetOptionLevel: 0,
  inventoryTargetType: 'BY_ITEM',
  standardCategoryId: null,
  exposedChannelIds: ['category', 'recommend', 'search'],
  saleTerm: {
    startedAt: 1724812670000,
    endedAt: 1756393199000,
  },
  items: [
    {
      absentFee: {
        type: 'RATIO',
        charge: '19.80',
        bias: 0,
      },
      commission: {
        type: 'RATIO',
        charge: '19.80',
        bias: 0,
      },
      daysOfExpiration: 90,
      description: '',
      maximumPurchasableCount: 0,
      minimumQuota: 0,
      name: '테스트 입니다',
      options: [
        {
          name: '테스트 입니다',
          title: '테스트',
        },
      ],
      paramId: 'item0.5552004449456207',
      price: {
        currencyCode: 'KRW',
        discountRate: 0,
        retail: 1000,
        sale: 1000,
      },
      quota: 0,
      representative: true,
      salesType: 'AGENCY',
      scheduledBySale: false,
      ticketCount: 1,
      digitalContent: {
        uri: '',
      },
      saleTerm: null,
    },
    {
      id: '485608',
      absentFee: {
        bias: 0,
        charge: '19.80',
        type: 'RATIO',
      },
      commission: {
        bias: 0,
        charge: '19.80',
        type: 'RATIO',
      },
      daysOfExpiration: 90,
      description: '',
      maximumPurchasableCount: 0,
      minimumQuota: 0,
      quota: 0,
      name: '테스트',
      options: [
        {
          id: '171119',
          name: '테스트',
          title: '성별',
        },
      ],
      paramId: '485608',
      price: {
        currencyCode: 'KRW',
        discountRate: 0,
        retail: 1000,
        sale: 1000,
      },
    },
  ],
  options: [],
  headerContents: [],
  productContentIds: [],
  htmlDetailContent: '',
  maximumPurchasableCount: 0,
  grossPurchaseLimitation: {
    count: 0,
  },
  unverifiedUserRestricted: false,
  keywords: [],
  underageRestricted: false,
  notice:
    '[신청 시 유의사항]\n· 상품이 배송되면 이후, 신청 취소 및 환불이 불가합니다.\n· 반품 시 먼저 호스트와 연락하셔서 반품 사유, 택배사, 배송비, 반품지 주소 등을 협의하신 후 반품 상품을 발송해 주시기 바랍니다.\n· 구매자 단순 변심에 의한 환불은 불가합니다.\n· 표시/광고와 상이, 상품하자의 경우 상품 수령 후 7일 이내인 경우 교환/반품이 가능합니다.\n· 배송 지연 등의 문의는 프립 상품 페이지 하단 [문의하기] 게시판을 이용해주시기 바랍니다.\n· DIY 키트는 설명서 및 동영상이 첨부되어 발송 후 환불이 불가능한 점 참고 부탁 드립니다.\n※ 교환이 필요할 경우, 먼저 호스트와 연락하셔서 반품을 진행해주시기 바랍니다.\n\n[배송]\n· 상황에 따라 1~3일 내 배송됩니다.\n· 구매 후, 3일 이내에 발송해드립니다.\n· 주문하신 상품 결제 확인 후, 배송해드립니다. 다만, 상품종류에 따라서 상품의 배송이 다소 지연 될 수 있습니다.',
  cancelingRestricted: false,
  externalProvisionIds: [],
  tagIds: [],
  frip: {
    curriculum: {
      style: 'NONE',
      sections: [],
      kind: 'SCHEDULE',
    },
    schedules: [],
    plans: [],
    locationOfGathering: null,
    locationsOfVenue: [],
    inclusions: [],
    exclusions: [],
    stuffsToPrepare: [],
    kind: 'COMMON',
    attachedToSchedule: false,
    bookingConfirmationEnabled: true,
    daysOfExpiration: 90,
    difficulty: 'EASY',
    recommendedAge: 0,
    schedulingTerm: {
      startedAt: 0,
      endedAt: 0,
    },
  },
};
