import { ICON_ARROW_BLACK, ICON_SETTLEMENT_IMAGE_UPLOAD } from 'assets/icons';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import { PRODUCT_REGIST_PATH } from 'constants/path';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { EdgesEntity } from '../graphQL/types';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #dbdcdf;
  background: #fff;
  cursor: pointer;
`;

const EmptyThumbnail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 64px;
  height: 64px;
  border-radius: 8px;
  background: #f4f4f5;
`;

const ThumbnailImage = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 8px;
`;

interface ProductCardIProps {
  item: EdgesEntity;
  type: 'update' | 'copy';
}

const ProductCard: React.FC<ProductCardIProps> = props => {
  const { item, type } = props;

  const navigate = useNavigate();

  return (
    <Container
      onClick={() => {
        navigate(`${PRODUCT_REGIST_PATH}/${item.node.id}`, {
          state: { isCopy: !(type === 'update') },
        });
      }}
    >
      <Row gap="14px">
        {isEmpty(item.node.headerContents) ? (
          <EmptyThumbnail>
            <img src={ICON_SETTLEMENT_IMAGE_UPLOAD} alt="icon image empty" />
          </EmptyThumbnail>
        ) : (
          <ThumbnailImage
            src={item.node.headerContents[0].content.thumbnail}
            alt="product thumbnail"
          />
        )}
        <Text color="#1b1c1e">
          {type === 'copy'
            ? item.node.title
            : `상품 등록중: ${dayjs(item.node.updatedAt).format(
                'YYYY년 M월 D일 hh:mm',
              )}`}
        </Text>
        <img src={ICON_ARROW_BLACK} alt="icon arrow black" />
      </Row>
    </Container>
  );
};

export default ProductCard;
