import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import DaumPostcodeEmbed from 'react-daum-postcode';
import { fromAddress } from 'react-geocode';

import Column from 'components/common/Column';
import { ICON_CLOSE } from 'assets/icons';
import { AddressItems } from './hooks/useAddressModalHooks';

const ModalContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${props => (props.$isMobile ? 'flex-end' : 'center')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 21;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
  position: relative;
  width: 100%;

  border-radius: 16px 16px 0px 0px;
  background: #fff;
  color: #000;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.6px;

  img {
    position: absolute;
    left: 20px;
    cursor: pointer;
  }
`;

interface KakaoMapModalIProps {
  isMobile: boolean;
  addressItems: AddressItems;
  setAddressItems: React.Dispatch<React.SetStateAction<AddressItems>>;
  onClose: () => void;
}

const KakaoMapModal: React.FC<KakaoMapModalIProps> = props => {
  const { isMobile, addressItems, setAddressItems, onClose } = props;

  const handleFindInternalAddress = async (address: string) => {
    // window.kakao.maps.load(() => {
    //   const geocoder = new window.kakao.maps.services.Geocoder();

    //   const callback = function (result: any, status: any) {
    //     if (status === window.kakao.maps.services.Status.OK) {
    //       setAddressItems({
    //         ...addressItems,
    //         address,
    //         geoPoint: {
    //           latitude: result[0].address.y,
    //           longitude: result[0].address.x,
    //         },
    //       });
    //       console.log(result[0], 'result');
    //     }
    //   };

    //   geocoder.addressSearch(addressItems.address, callback);
    // });

    try {
      const response = await fromAddress(address);

      if (response) {
        const { lat, lng } = response.results[0].geometry.location;

        setAddressItems({
          ...addressItems,
          address,
          geoPoint: { latitude: lat, longitude: lng },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleComplete = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    onClose();
    handleFindInternalAddress(fullAddress);
  };

  return (
    <ModalContainer $isMobile={isMobile}>
      <Column
        style={{
          maxWidth: '768px',
        }}
      >
        <Header>
          <img src={ICON_CLOSE} alt="close icon" onClick={onClose} />
          주소 검색
        </Header>
        <DaumPostcodeEmbed
          onComplete={handleComplete}
          style={{ height: '550px' }}
        />
      </Column>
    </ModalContainer>
  );
};

export default KakaoMapModal;
