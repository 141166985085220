import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Column from 'components/common/Column';
import Text from 'components/common/Text';
import InputForm from 'containers/Login/components/InputForm';
import { EMAIL_REGEX } from 'utils/regExp';
import { SignupParam } from '../hooks/types';
import { instance } from 'utils/axiosInstance';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
`;

interface SignupEmailIProps {
  signupParam: SignupParam;
  isExistEmail: boolean;
  handleCheckValidationEmail: () => void;
  handleChangeSignupParam: (key: string, value: string) => void;
}

const SignupEmail: React.FC<SignupEmailIProps> = props => {
  const {
    signupParam,
    isExistEmail,
    handleCheckValidationEmail,
    handleChangeSignupParam,
  } = props;

  const [isFocusedInput, setIsFocusedInput] = useState<boolean>(false);

  const handleCheckIsFocusedInput = (isFocused: boolean) => {
    setIsFocusedInput(isFocused);

    if (!isFocused) {
      handleCheckValidationEmail();
    }
  };

  useEffect(() => {
    if (
      EMAIL_REGEX.test(signupParam.email) &&
      !isFocusedInput &&
      isExistEmail
    ) {
      handleCheckValidationEmail();
    }
  }, [isFocusedInput, signupParam.email]);

  return (
    <Column gap="8px" align="flex-start">
      <Container>
        <InputForm
          label="아이디(이메일)"
          type="text"
          styleType="large"
          target="email"
          validation={isExistEmail}
          regExp={EMAIL_REGEX}
          autoComplete={true}
          handleCheckIsFocusedInput={handleCheckIsFocusedInput}
          onChange={handleChangeSignupParam}
        />
      </Container>
      {signupParam.email === '' && isFocusedInput && (
        <Text color="#70737c" size="12px" weight={400}>
          아이디로 사용할 이메일 주소를 입력해 주세요.
        </Text>
      )}

      {EMAIL_REGEX.test(signupParam.email) && isExistEmail && (
        <Text color="#FF4242" size="12px" weight={400}>
          이미 가입된 계정입니다.
        </Text>
      )}

      {signupParam.email !== '' && !EMAIL_REGEX.test(signupParam.email) && (
        <Text color="#FF4242" size="12px" weight={400}>
          올바른 이메일 주소를 입력해 주세요.
        </Text>
      )}
    </Column>
  );
};

export default SignupEmail;
