import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import SettlementInput from './SettlementInput';
import { HostVerifiedBusinessParamInput, TaxPayerType } from '../graphQL/type';
import { BUSINESSNUMBER_REGEX, IDNUMBER_REGEX } from 'utils/regExp';
import Text from 'components/common/Text';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  gap: 12px;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const ForeignCheck = styled.div<{ isForeign: boolean }>`
  color: ${(props) => (props.isForeign ? '#1B1C1E' : '#DBDCDF')};
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.6px;
`;

const CheckBox = styled.input`
  width: 24px;
  height: 24px;
  -webkit-appearance: none; /* 기본 스타일 제거 */
  -moz-appearance: none;
  appearance: none;
  background-color: #fff; /* 기본 배경색 */
  border: 1px solid #ddd; /* 테두리 */
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: 5px;

  :checked {
    background-color: #000;
  }

  :checked::before {
    content: '✓';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
`;

const InputFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-radius: 8px;
  border: 1px solid #999;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #999;
`;

interface RegistCalculateInformationIProps {
  businessInformation: HostVerifiedBusinessParamInput;
  registrationNo: string;
  handleCheckForeign: () => void;
  handleSetBusinessInformation: (target: string, value: string) => void;
}

const RegistSettlementInformation: React.FC<
  RegistCalculateInformationIProps
> = (props) => {
  const {
    businessInformation,
    registrationNo,
    handleCheckForeign,
    handleSetBusinessInformation,
  } = props;

  const [isFocusedInput, setIsFocusedInput] = useState<{
    isFocused: boolean;
    label: string;
  }>({ isFocused: false, label: '' });

  const handleCheckIsFocusecInput = (isFocused: boolean, label: string) => {
    setIsFocusedInput({ isFocused, label });
  };

  const emptyStringRegex = /^.+$/;

  const renderInputField = (): JSX.Element => {
    switch (businessInformation.taxPayerType) {
      case TaxPayerType.INDIVIDUAL: {
        return (
          <React.Fragment>
            <Text color="#1b1c1e" size="24px">
              호스트 정보를 입력해 주세요
            </Text>
            <Row>
              <ForeignCheck isForeign={businessInformation.foreign}>
                *외국인(Foreigner)이면 체크해주세요
              </ForeignCheck>
              <CheckBox
                type="checkbox"
                checked={businessInformation.foreign}
                onChange={handleCheckForeign}
              />
            </Row>
            <InputFormContainer>
              <SettlementInput
                type="text"
                styleType="top"
                label="이름"
                target="taxPayerName"
                informationValue={businessInformation.taxPayerName}
                validation={emptyStringRegex}
                onChange={handleSetBusinessInformation}
                handleCheckIsFocusecInput={handleCheckIsFocusecInput}
              />
              <SettlementInput
                type="text"
                styleType="center"
                label={
                  businessInformation.foreign ? '거소신고번호' : '주민등록번호'
                }
                target=""
                informationValue={registrationNo}
                validation={IDNUMBER_REGEX}
                onChange={handleSetBusinessInformation}
                handleCheckIsFocusecInput={handleCheckIsFocusecInput}
              />
              <SettlementInput
                type="text"
                styleType="bottom"
                label="주소"
                target="address"
                informationValue={businessInformation.address}
                validation={emptyStringRegex}
                onChange={handleSetBusinessInformation}
                handleCheckIsFocusecInput={handleCheckIsFocusecInput}
              />
            </InputFormContainer>
            {businessInformation.foreign && (
              <div style={{ marginTop: '-4px' }}>
                <Text color="#70737c" size="12px" weight={400}>
                  외국인의 경우 비자가 소득을 벌 수 있는 비자인지를 확인해
                  주세요.
                </Text>
              </div>
            )}
          </React.Fragment>
        );
      }
      case TaxPayerType.CORPORATION: {
        return (
          <React.Fragment>
            <Text color="#1b1c1e" size="24px">
              사업자 등록증 정보를 입력해 주세요
            </Text>
            <Text color="#70737c" size="16px" weight={400}>
              세금계산서 발급을 위해 사업자 등록증 내용을 확인할 수 있는 정보를
              입력해주세요.
            </Text>
            <Column>
              <InputFormContainer>
                <SettlementInput
                  type="text"
                  styleType="top"
                  label="대표자명"
                  target="representativeName"
                  informationValue={businessInformation.representativeName}
                  validation={emptyStringRegex}
                  onChange={handleSetBusinessInformation}
                  handleCheckIsFocusecInput={handleCheckIsFocusecInput}
                />
                <SettlementInput
                  type="text"
                  styleType="center"
                  label="업체명"
                  target="taxPayerName"
                  informationValue={businessInformation.taxPayerName}
                  validation={emptyStringRegex}
                  onChange={handleSetBusinessInformation}
                  handleCheckIsFocusecInput={handleCheckIsFocusecInput}
                />
                <SettlementInput
                  type="text"
                  styleType="center"
                  label="사업자등록증 번호"
                  target=""
                  informationValue={registrationNo}
                  validation={BUSINESSNUMBER_REGEX}
                  onChange={handleSetBusinessInformation}
                  handleCheckIsFocusecInput={handleCheckIsFocusecInput}
                />
                {!isFocusedInput && <Divider />}
                <SettlementInput
                  type="text"
                  styleType="center"
                  label="주소"
                  target="address"
                  informationValue={businessInformation.address}
                  validation={emptyStringRegex}
                  onChange={handleSetBusinessInformation}
                  handleCheckIsFocusecInput={handleCheckIsFocusecInput}
                />
                <SettlementInput
                  type="text"
                  styleType="center"
                  label="사업 업태"
                  target="businessItem"
                  informationValue={businessInformation.businessItem}
                  validation={emptyStringRegex}
                  onChange={handleSetBusinessInformation}
                  handleCheckIsFocusecInput={handleCheckIsFocusecInput}
                />
                <SettlementInput
                  type="text"
                  styleType="bottom"
                  label="사업 종목"
                  target="businessType"
                  informationValue={businessInformation.businessType}
                  validation={emptyStringRegex}
                  onChange={handleSetBusinessInformation}
                  handleCheckIsFocusecInput={handleCheckIsFocusecInput}
                />
              </InputFormContainer>
              <Text color="#70737c" size="12px" weight={400}>
                사업 업태와 사업 종목은 사업자 등록증 상단에 명시된 대로, 각각
                한 가지씩만 입력해주세요.
              </Text>
            </Column>
          </React.Fragment>
        );
      }
      default:
        return <></>;
    }
  };

  return <Container>{renderInputField()}</Container>;
};

export default RegistSettlementInformation;
