import React, { useEffect } from 'react';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import Column from 'components/common/Column';
import Text from 'components/common/Text';
import Row from 'components/common/Row';
import PreviewProductDetail from '../PreviewProductDetail';
import { useParams } from 'react-router-dom';
import HackleManager from 'utils/hackle';

interface ChapterEightIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  imageThumbnail: string[];
}

const ChapterEight: React.FC<ChapterEightIProps> = props => {
  const { isMobile, productParamInput, imageThumbnail } = props;

  const params = useParams<{ id: string }>();
  const title =
    params.id !== 'draft'
      ? '프립을 미리 확인하고, 이상이 없다면 상품 저장을 완료해 주세요'
      : '프립을 미리 확인하고, 이상이 없다면 상품 등록을 완료해 주세요';

  const description =
    params.id !== 'draft'
      ? '상품 저장이 완료된 후, 다음 단계에서 검수를 요청해 주세요'
      : '상품 등록이 완료된 후, 다음 단계에서 검수를 요청해 주세요';

  useEffect(() => {
    HackleManager.logCreateStepFourPreviewView(
      params.id !== 'draft' ? Number(params.id) : undefined,
    );
  }, []);

  const renderContents = (): JSX.Element => {
    if (isMobile) {
      return (
        <Column gap="32px">
          <Column align="flex-start" gap="12px">
            <Text
              color="#1b1c1e"
              size="24px"
              style={{ padding: '16px 20px 0', lineHeight: '32px' }}
            >
              {title}
            </Text>
            <Text color="#70737c" size="16px" weight={400}>
              {description}
            </Text>
          </Column>
          <PreviewProductDetail
            productParamInput={productParamInput}
            imageThumbnail={imageThumbnail}
            isMobile={isMobile}
          />
        </Column>
      );
    }

    return (
      <Row gap="64px" style={{ width: '100%' }}>
        {params.id !== 'draft' ? (
          <Column align="flex-start" gap="12px" style={{ width: 'auto' }}>
            <Text
              color="#1b1c1e"
              size="32px"
              style={{ maxWidth: '450px', lineHeight: '40px' }}
            >
              프립을 미리 확인하고, <br />
              이상이 없다면 상품 저장을 완료해 주세요
            </Text>
            <Text color="#70737c" size="16px" weight={400}>
              {description}
            </Text>
          </Column>
        ) : (
          <Column align="flex-start" gap="12px" style={{ width: 'auto' }}>
            <Text
              color="#1b1c1e"
              size="32px"
              style={{ maxWidth: '450px', lineHeight: '40px' }}
            >
              프립을 미리 확인하고,
              <br /> 이상이 없다면 상품 등록을 완료해 주세요
            </Text>
            <Text color="#70737c" size="16px" weight={400}>
              {description}
            </Text>
          </Column>
        )}
        <PreviewProductDetail
          productParamInput={productParamInput}
          imageThumbnail={imageThumbnail}
          isMobile={isMobile}
        />
      </Row>
    );
  };

  return renderContents();
};

export default ChapterEight;
