import React from 'react';
import { styled } from 'styled-components';
import dayjs from 'dayjs';

const Input = styled.input`
  border: none;
  width: 16px !important;
  height: 16px !important;
  cursor: pointer;
  font-size: 14px;

  background: url('icon_calendar.svg') center center no-repeat;
  background-size: 16px 16px !important;
  will-change: transform;
  /* background-size: contain; */

  /* 텍스트 숨기기 */
  color: transparent;
  caret-color: transparent; /* 커서가 나타나지 않도록 */
  text-indent: -9999px;

  &::-webkit-calendar-picker-indicator {
    opacity: 0; /* 기본 달력 아이콘 숨기기 */
    cursor: pointer;
  }

  &::-webkit-inner-spin-button {
    display: none; /* 스핀 버튼 숨기기 */
  }

  &::placeholder {
    color: transparent; /* placeholder 색상을 투명하게 */
  }
`;

interface DateInputIProps {
  onChange: (e: any) => void;
}

const DateInput: React.FC<DateInputIProps> = props => {
  const { onChange } = props;

  return (
    <Input
      type="date"
      min={dayjs().format('YYYY-MM-DD')}
      onChange={e => {
        if (new Date(e.target.value) < new Date()) {
          window.showToast('이전 날짜는 선택 불가능 합니다.', 'failed');

          return;
        }

        onChange(e);
      }}
    />
  );
};

export default DateInput;
