import Column from 'components/common/Column';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useResizeDetector } from 'react-resize-detector';
import styled from 'styled-components';
import HackleManager from 'utils/hackle';

const Container = styled.div`
  margin: auto;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;

  iframe {
    border: none;

    #host_switch {
      display: none;
    }
  }
`;

const GuideContent = () => {
  const { height, ref } = useResizeDetector();

  const iframePart = () => {
    return {
      __html: `<iframe src="./guide.html" width="100%" height=${height}px></iframe>`,
    };
  };

  useEffect(() => {
    HackleManager.logLandingView();
  }, []);

  return (
    <Column>
      <Helmet>{/* <title>어드민 테스트</title> */}</Helmet>
      <Container dangerouslySetInnerHTML={iframePart()} ref={ref} />
    </Column>
  );
};
export default GuideContent;
