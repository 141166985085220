import React, { useState } from 'react';
import styled from 'styled-components';
import BankListModal from './BankListModal';
import { BankAccountVerifyParamInput } from '../graphQL/type';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';

import { ICON_ARROW_BLACK_BOTTOM } from 'assets/icons';
import SettlementInput from 'containers/SettlementRegist/components/SettlementInput';
import Text from 'components/common/Text';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 40px 20px;

  width: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const BankSelectButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 8px;
  border: 1px solid #c2c4c8;
  background: #fff;
  color: #70737c;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const UserInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 12px;

  border-radius: 8px;
  border: 1px solid #c2c4c8;
  background: #f4f4f5;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;

  p {
    color: #70737c;
    font-size: 12px;
  }

  span {
    color: #1b1c1e;
    font-size: 16px;
    line-height: 24px;
  }
`;

const UnderlineText = styled.div`
  color: #1b1c1e;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: -0.072px;
  text-decoration-line: underline;
`;

interface RegistBankInformationIProp {
  bankInformation: BankAccountVerifyParamInput;
  isOpenBankModal: boolean;
  handleAcceptBankCode: (code: string) => void;
  handleOnChangeAccountNo: (target: string, value: string) => void;
  handleChangeOpenBankModal: (isOpen: boolean) => void;
}

const RegistBankInformation: React.FC<RegistBankInformationIProp> = (props) => {
  const {
    bankInformation,
    isOpenBankModal,
    handleAcceptBankCode,
    handleOnChangeAccountNo,
    handleChangeOpenBankModal,
  } = props;
  const myInfo = useUserInformation((state: any) => state.userInfo);

  const [isHolderChange, setIsholderChange] = useState<boolean>(false);
  const [bankName, setBankName] = useState<string>(
    myInfo.me.host!.settlement!.bankAccount !== null
      ? myInfo.me.host!.settlement!.bankAccount.account.bank.name
      : ''
  );

  return (
    <Container>
      <Text color="#1b1c1e" size="24px">
        계좌 정보를 입력해 주세요
      </Text>
      <Column>
        <Text color="#1b1c1e" size="16px" weight={400}>
          은행 이름
        </Text>
        <BankSelectButton
          style={{ padding: bankName !== '' ? '8px 12px' : '16px 12px' }}
          onClick={() => {
            handleChangeOpenBankModal(true);
          }}
        >
          <Column style={{ gap: '0px' }}>
            <span style={{ fontSize: bankName !== '' ? '12px' : '16px' }}>
              은행을 선택해 주세요
            </span>
            {bankName !== '' && (
              <Text color="#1b1c1e" size="16px" weight={400}>
                {bankName}
              </Text>
            )}
          </Column>

          <img src={ICON_ARROW_BLACK_BOTTOM} alt="select arrow button" />
        </BankSelectButton>
      </Column>
      <Column style={{ gap: '8px' }}>
        <Column>
          <Text color="#1b1c1e" size="16px" weight={400}>
            계좌 번호
          </Text>
          <SettlementInput
            type="text"
            styleType="large"
            label="계좌 번호를 입력해 주세요"
            target="accountNo"
            informationValue={bankInformation.accountNo}
            onChange={handleOnChangeAccountNo}
          />
        </Column>
        <Text color="#1b1c1e" size="12px" weight={400}>
          “-” 기호 없이 숫자만 입력해 주세요.
        </Text>
      </Column>
      <Column style={{ gap: '8px' }}>
        <Column>
          <Text color="#1b1c1e" size="16px" weight={400}>
            예금주명
          </Text>
          {isHolderChange ? (
            <SettlementInput
              type="text"
              styleType="large"
              label="예금주명"
              target="holder"
              informationValue={bankInformation.holder}
              onChange={handleOnChangeAccountNo}
            />
          ) : (
            <UserInformationContainer>
              <p>예금주명</p>
              <span>{bankInformation.holder}</span>
            </UserInformationContainer>
          )}
        </Column>
        {!isHolderChange && (
          <Row>
            <Text color="#1b1c1e" size="12px" weight={400}>
              호스트 정보에서 입력한 이름입니다.
            </Text>
            <UnderlineText onClick={() => setIsholderChange(!isHolderChange)}>
              예금주명 수정하기
            </UnderlineText>
          </Row>
        )}
      </Column>
      {isOpenBankModal && (
        <BankListModal
          handleAcceptBankCode={handleAcceptBankCode}
          setBankName={setBankName}
          onClose={() => {
            handleChangeOpenBankModal(false);
          }}
        />
      )}
    </Container>
  );
};

export default RegistBankInformation;
