export interface UserLoginResponse {
  accessToken: string;
  authorityLevel: AuthorityLevel;
  email: string;
  expiresIn: number;
  joinedAt: string;
  name: string;
  tokenType: string;
  userId: number;
  validatedAt: string;
}

export enum AuthorityLevel {
  HOST = 'HOST',
  USER = 'USER',
  ADMIN = 'ADMIN',
}
