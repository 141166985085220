import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Map, MapMarker } from 'react-kakao-maps-sdk';

import { ICON_PRODUCT_CLIPBOARD } from 'assets/icons';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import Divider from 'components/Divider';
import { FripLocation } from 'types/api';
import { FripLocationParam } from 'containers/ProductRegist/hooks/types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const AddressBox = styled.div`
  border-radius: 5px 5px 0px 0px;
  border: 1px solid rgba(17, 17, 17, 0.1);
`;

const PlaceInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  padding: 14px;

  border-radius: 0px 0px 5px 5px;
  border: 1px solid var(--Grayscale-Gray-200, #ddd);
  background: var(--Black-White-White, #fff);
`;

const ShareButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-radius: 5px;
  border: 1px solid var(--Grayscale-Gray-100, #eee);
  background: var(--Black-White-White, #fff);
`;

const ConvenienceButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 8px;

  border-radius: 5px;
  background: var(--Grayscale-Gray-50, #f4f4f4);

  color: #333;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.36px;
`;

interface PlaceFormIProps {
  item: FripLocationParam;
}

const PlaceForm: React.FC<PlaceFormIProps> = (props) => {
  const { item } = props;

  const mapRef = useRef(null);

  const initMap = useCallback(
    (lat: number, lng: number) => {
      if (mapRef.current) {
        return new window.google.maps.Map(mapRef.current, {
          center: { lat, lng },
          zoom: 10,
        });
      }
    },
    [mapRef]
  );

  const handleClipBoardAddress = () => {
    navigator.clipboard.writeText(item.address);
  };

  useEffect(() => {
    if (item.overseas) {
      initMap(item.geoPoint!.latitude, item.geoPoint!.longitude);
    }
  }, [item.overseas]);

  return (
    <Container>
      <AddressBox>
        {item.overseas ? (
          <>
            {mapRef && (
              <div
                className="map"
                style={{ width: '100%', height: '185px', borderRadius: '8px' }}
                ref={mapRef}
              />
            )}
          </>
        ) : (
          <Map
            draggable={false}
            zoomable={false}
            center={{
              lat: item.geoPoint!.latitude,
              lng: item.geoPoint!.longitude,
            }}
            style={{ width: '100%', height: '185px', borderRadius: '8px' }}
          >
            <MapMarker
              position={{
                lat: item.geoPoint!.latitude,
                lng: item.geoPoint!.longitude,
              }}
            />
          </Map>
        )}
      </AddressBox>
      <PlaceInformation>
        <Row gap="20px" style={{ width: '100%' }}>
          <Column gap="8px" align="flex-start">
            <Text color="#333" size="14px">
              {item.label}
            </Text>
            <Text
              color="#777"
              size="14px"
              weight={400}
              style={{ lineHeight: '22px' }}
            >
              {item.address}
            </Text>
          </Column>
          <ShareButton onClick={handleClipBoardAddress}>
            <img src={ICON_PRODUCT_CLIPBOARD} alt="icon product clipboard" />
          </ShareButton>
        </Row>
        <Divider style={{ color: '#eee' }} />
        <Column gap="8px" align="flex-start">
          <Text color="#333" size="14px" weight={400}>
            편의 정보
          </Text>
          <Row
            justifyContent="flex-start"
            gap="8px"
            style={{ flexWrap: 'wrap' }}
          >
            <ConvenienceButton>와이파이</ConvenienceButton>
          </Row>
          <Text color="#777" size="12px" weight={400}>
            건물 내 1대 주차 가능
          </Text>
        </Column>
      </PlaceInformation>
    </Container>
  );
};

export default PlaceForm;
