import React, { useState } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import ModifyContainer from '../ModifyContainer';
import Column from 'components/common/Column';
import Divider from 'components/Divider';
import Text from 'components/common/Text';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import Row from 'components/common/Row';
import {
  CurriculumKind,
  CurriculumSectionItem,
  CurriculumStyle,
} from 'types/api';
import { ModifyValue } from '../../hooks/useProductDetaillHooks';

const ScheduleButton = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #dbdcdf;
`;

const ScheduleBar = styled.div`
  width: 1px;
  height: 40px;
  background-color: #dbdcdf;
`;

const ScheduleEtcForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 16px;
  width: 100%;

  border-radius: 12px;
  background: var(--gray-gray-01, #f7f7f8);
`;

interface ScheduleContainerIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  modifyValue: ModifyValue;
  setModifyValue: React.Dispatch<React.SetStateAction<ModifyValue>>;
}

const ScheduleContainer: React.FC<ScheduleContainerIProps> = props => {
  const { isMobile, productParamInput, modifyValue, setModifyValue } = props;
  const fripInfo = productParamInput.frip;

  const renderScheduleTime = (item: CurriculumSectionItem): JSX.Element => {
    switch (fripInfo.curriculum.style) {
      case CurriculumStyle.DURATION: {
        return <Text size="12px">{item.duration}분</Text>;
      }
      case CurriculumStyle.TIMETABLE: {
        return (
          <Row>
            <Text size="12px">
              {item.term.startingTime.hour}시 {item.term.startingTime.minute}분
              ~
            </Text>
            <Text size="12px">
              {item.term.endingTime.hour}시 {item.term.endingTime.minute}분
            </Text>
          </Row>
        );
      }
      case CurriculumStyle.LEGACY: {
        return <Text size="12px">{item.textTime}</Text>;
      }
    }
    return;
  };

  return (
    <ModifyContainer
      title="스케줄"
      isSelected={!isMobile && modifyValue.modifyStatus === 'schedule'}
      modifyText={
        isEmpty(fripInfo.curriculum.sections) ? '등록하기' : '수정하기'
      }
      onClick={() => {
        setModifyValue({ ...modifyValue, modifyStatus: 'schedule' });
      }}
    >
      {isEmpty(fripInfo.curriculum.sections) ? (
        <Column>
          <Text color="#46474C" weight={400}>
            등록한 스케줄이 없어요.
          </Text>
          <Text color="#46474C" weight={400}>
            크루들이 호스트님의 프립을 더 잘 이해할 수 있도록
          </Text>
          <Text color="#46474C" weight={400}>
            작성해 보세요.
          </Text>
        </Column>
      ) : (
        <Column gap="16px">
          {fripInfo.curriculum.sections.map((curriculumItem, curriculumIdx) => {
            return (
              <React.Fragment key={curriculumIdx}>
                <Column gap="12px" align="flex-start">
                  {curriculumItem.title}
                  {curriculumItem.items.map((item, idx) => {
                    return (
                      <React.Fragment>
                        <Row
                          align="flex-end"
                          justifyContent="flex-start"
                          gap="16px"
                          style={{ width: '100%' }}
                          key={idx}
                        >
                          <Column
                            gap="2px"
                            style={{
                              width: 'auto',
                              position: 'relative',
                            }}
                          >
                            <Column
                              gap="5px"
                              style={{
                                width: 'auto',
                                position: 'absolute',
                                top: '-13px',
                              }}
                            >
                              <ScheduleButton />
                              {curriculumItem.items.length !== idx + 1 && (
                                <ScheduleBar />
                              )}
                            </Column>
                          </Column>
                          <Column gap="12px" style={{ width: 'auto' }}>
                            <Column gap="4px" align="flex-start">
                              {renderScheduleTime(item)}
                              <Text color="#46474c" size="14px" weight={400}>
                                {item.content}
                              </Text>
                            </Column>
                          </Column>
                        </Row>
                      </React.Fragment>
                    );
                  })}
                  {curriculumItem.etc !== '' && (
                    <ScheduleEtcForm>
                      <Text color="#46474C">기타사항</Text>
                      <Text color="#46474C" weight={400}>
                        {curriculumItem.etc}
                      </Text>
                    </ScheduleEtcForm>
                  )}
                </Column>
                {fripInfo.curriculum.sections.length !== curriculumIdx + 1 && (
                  <Divider />
                )}
              </React.Fragment>
            );
          })}
        </Column>
      )}
    </ModifyContainer>
  );
};

export default ScheduleContainer;
