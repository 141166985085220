import { useNavigate } from 'react-router-dom';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import { instance } from './axiosInstance';
import { LOGIN_PATH } from 'constants/path';
import HackleManager from './hackle';

export const signoutHook = () => {
  const { clearUserInfo } = useUserInformation(state => state);
  const navigate = useNavigate();

  const handleSignout = async () => {
    try {
      window.handleOpenLoading();

      const response = await instance.post('/users/signout');

      if (response) {
        clearUserInfo();

        HackleManager.logSignoutComplete();
        HackleManager.userLogout();

        window.handleCloseLoading();
        navigate(LOGIN_PATH);
      }
    } catch (error) {
      window.showToast(error.response.data.message, 'failed');
    }
  };

  return {
    handleSignout,
  };
};
