import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { MeData } from 'graphQL/Auth/types';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import ProfileThumbnail from 'components/common/ProfileThumbnail';
import Column from 'components/common/Column';
import NavigateForm from 'containers/ProductList/components/NavigateForm';
import {
  ICON_MENU_BOOKING,
  ICON_MENU_CONCIERGE,
  ICON_MENU_DELIVERY,
  ICON_MENU_HEADPHONE,
  ICON_MENU_INDIVIDUAL,
  ICON_MENU_LOGOUT,
  ICON_MENU_NOTICE,
  ICON_MENU_PRODUCT,
  ICON_MENU_PROFILE,
  ICON_MENU_QNA,
  ICON_MENU_QUESTION,
  ICON_MENU_REVIEW,
  ICON_MENU_SALE_BLACK,
  ICON_SETTLEMENT_LIST,
  ICON_SETTLEMENT_REQUEST,
  ICON_SWITCH,
} from 'assets/icons';
import Divider from 'components/Divider';
import {
  BOOKING_LIST_PATH,
  CONCIERGE_PATH,
  HOST_GUIDE_NOTICE,
  INDIVIDUAL_NOTICE,
  INDIVIDUAL_PATH,
  NOTICE_PATH,
  PRODUCT_LIST_PATH,
  PROFILE_PATH,
  QNA_LIST_PATH,
  QUESTION_PATH,
  REVIEW_LIST_PATH,
  SALE_LIST_PATH,
  SETTLEMENT_LIST_PATH,
  SETTLEMENT_PATH,
  SUPER_HOST_NOTICE,
  TERM_NOTICE_PATH,
} from 'constants/path';
import {
  callWebviewHandler,
  isWebview,
  WebviewOperatorType,
} from 'utils/webview';
import HostApplicationContainer from './components/HostApplicationContainer';
import { useMenuHooks } from './hooks/useMenuHooks';
import CurrentBanners from './components/CurrentBanners';
import DashBoardContainer from './components/DashBoardContainer';
import BasicButton from 'components/Button/BasicButton';
import { STORAGE_KEY } from 'utils/localStoarge';
import { useResizeDetector } from 'react-resize-detector';
import Footer from 'components/common/Footer';
import { signoutHook } from 'utils/SignoutHooks';
import { useQueryGetQnaCount } from 'containers/QnaList/graphQL/hooks/useQueryGetQnaCount';
import HackleManager from 'utils/hackle';

import packageJson from '../../../package.json';

const WebVersion: string = packageJson.version;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
  max-width: 768px;
`;

const SwitchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: fixed;
  bottom: 85px;

  padding: 12px;
  border-radius: 99px;
  background-color: #1b1c1e;
  z-index: 15;
  color: var(--Static-White, #fff);

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.6px;
`;

const BadgeItem = styled.div<{ color: string; background: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;

  border-radius: 4px;
  background: ${props => props.background};
  color: ${props => props.color};
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px; /* 130% */
  letter-spacing: -0.1px;
`;

const MyInfoSheet = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: absolute;
  right: 0px;
  top: 45px;
  padding: 16px 20px;
  width: 200px;
  border-radius: 12px;
  background: var(--Static-White, #fff);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  z-index: 20;
`;

const MenuPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const myInfo: MeData = useUserInformation(state => state.userInfo);
  const { handleSignout } = signoutHook();
  const { width, ref } = useResizeDetector();

  const { qnaCount } = useQueryGetQnaCount();
  const isMobile = width ? width < 768 : window.innerWidth < 768;
  const [isOpenMyInfoSheet, setIsOpenMyInfoSheet] = useState<boolean>(false);

  const {
    currentBanners,
    hostDashBoard,
    isOpenIndividualModal,
    isOpenProfileUpdateModal,
    isOpenHostApplicationModal,
    setIsOpenIndividualModal,
    setIsOpenProfileUpdateModal,
    setIsOpenHostApplicationModal,
  } = useMenuHooks();

  useEffect(() => {
    if (location.state) {
      setIsOpenProfileUpdateModal(true);
    }
  }, [location.state]);

  const renderMyInfoSheet = (): JSX.Element => {
    return (
      <MyInfoSheet>
        <Text
          color="#46474C"
          onClick={() => {
            navigate(PROFILE_PATH);

            HackleManager.logProfileMenuClick('프로필 관리');
            setIsOpenMyInfoSheet(false);
          }}
        >
          프로필 관리
        </Text>
        <Text
          color="#46474C"
          onClick={() => {
            navigate(INDIVIDUAL_PATH);

            HackleManager.logProfileMenuClick('호스트 연락처 설정');
            setIsOpenMyInfoSheet(false);
          }}
        >
          호스트 연락처 설정
        </Text>
      </MyInfoSheet>
    );
  };

  // const renderIndividualModalContainer = (): JSX.Element => {
  //   return (
  //     <ModalContainer
  //       isMobile={true}
  //       isAbled={true}
  //       isBottomSheet={true}
  //       onClose={() => {}}
  //       onClick={() => {}}
  //       style={{ gap: 0 }}
  //     >
  //       <Row style={{ padding: '16px' }}>
  //         <Text color="#000" size="16px" weight={600}>
  //           개인 정보 수정
  //         </Text>
  //       </Row>
  //       <IndividualUpdateContainer
  //         isMobile={true}
  //         onClose={() => setIsOpenIndividualModal(false)}
  //       />
  //     </ModalContainer>
  //   );
  // };

  // const renderProfileUpdateContainer = (): JSX.Element => {
  //   return (
  //     <ModalContainer
  //       isMobile={true}
  //       isAbled={true}
  //       isBottomSheet={true}
  //       onClose={() => {}}
  //       onClick={() => {}}
  //     >
  //       <Row style={{ padding: '16px' }}>
  //         <Text color="#000" size="16px" weight={600}>
  //           프로필
  //         </Text>
  //       </Row>
  //       <ProfileUpdateContainer
  //         isMobile={true}
  //         onClose={() => {
  //           setIsOpenProfileUpdateModal(false);
  //         }}
  //       />
  //     </ModalContainer>
  //   );
  // };

  const renderHostApplicationContainer = (): JSX.Element => {
    return (
      <HostApplicationContainer
        isMobile={isMobile}
        onClose={() => setIsOpenHostApplicationModal(false)}
      />
    );
  };

  return (
    <React.Fragment>
      <Container ref={ref}>
        <Column style={{ padding: isMobile ? '0 0 64px' : '24px 40px 64px' }}>
          {isMobile && (
            <Row
              justifyContent="space-between"
              style={{
                position: isMobile ? 'sticky' : 'static',
                top: 0,
                padding: isMobile ? '24px 20px' : '0 20px',
                width: '100%',
                background: '#fff',
                zIndex: 10,
              }}
            >
              <Text color="#1b1c1e" size={isMobile ? '24px' : '20px'}>
                메뉴
              </Text>
              <Row
                gap="6px"
                onClick={() => {
                  setIsOpenMyInfoSheet(!isOpenMyInfoSheet);
                }}
                style={{ position: 'relative', height: '40px' }}
              >
                <ProfileThumbnail />
                <Text color="#46474c" size="16px">
                  <span style={{ textDecoration: 'underline' }}>
                    {myInfo.me.host.name}{' '}
                  </span>
                </Text>
                {isOpenMyInfoSheet && renderMyInfoSheet()}
              </Row>
            </Row>
          )}
          <Column gap="24px">
            {hostDashBoard && (
              <Column style={{ padding: isMobile ? '0 0 0 20px' : '0 20px' }}>
                <DashBoardContainer
                  isMobile={isMobile}
                  hostDashBoard={hostDashBoard}
                />
              </Column>
            )}
            <Column gap="32px" style={{ padding: '0 20px' }}>
              {currentBanners && (
                <CurrentBanners
                  isMobile={isMobile}
                  currentBanners={currentBanners}
                />
              )}
              <Column gap="24px">
                <NavigateForm
                  icon={ICON_SETTLEMENT_REQUEST}
                  title="정산 신청"
                  onClick={() => {
                    HackleManager.logMenuItemClick('정산 정보 등록');

                    navigate(SETTLEMENT_LIST_PATH);
                  }}
                />
                <NavigateForm
                  icon={ICON_SETTLEMENT_LIST}
                  title="정산 정보 관리"
                  onClick={() => {
                    HackleManager.logMenuItemClick('정산 내역 및 지급 요청');

                    navigate(SETTLEMENT_PATH);
                  }}
                />
                <Divider />
                <NavigateForm
                  icon={ICON_MENU_SALE_BLACK}
                  title="판매 관리"
                  onClick={() => {
                    HackleManager.logMenuItemClick('판매 관리');

                    navigate(SALE_LIST_PATH);
                  }}
                />
                <NavigateForm
                  icon={ICON_MENU_BOOKING}
                  title="예약 관리"
                  onClick={() => {
                    HackleManager.logMenuItemClick('예약 관리');

                    navigate(BOOKING_LIST_PATH);
                  }}
                />
                <NavigateForm
                  icon={ICON_MENU_PRODUCT}
                  title="상품 관리"
                  onClick={() => {
                    HackleManager.logMenuItemClick('상품 관리');

                    navigate(PRODUCT_LIST_PATH);
                  }}
                />
                <Divider />
                <NavigateForm
                  icon={ICON_MENU_QNA}
                  title="문의 관리"
                  onClick={() => {
                    HackleManager.logMenuItemClick('문의 관리');

                    navigate(QNA_LIST_PATH);
                  }}
                  badgeContent={
                    <BadgeItem background="#7126FF" color="#fff">
                      답변 대기 {qnaCount.new.toLocaleString()}건
                    </BadgeItem>
                  }
                />
                <NavigateForm
                  icon={ICON_MENU_REVIEW}
                  title="후기 관리"
                  onClick={() => {
                    HackleManager.logMenuItemClick('후기 관리');

                    navigate(REVIEW_LIST_PATH);
                  }}
                />
                <Divider />
                <NavigateForm
                  icon={ICON_MENU_DELIVERY}
                  title="배송 관리"
                  onClick={() => {
                    HackleManager.logMenuItemClick('배송 관리');
                  }}
                  badgeContent={
                    <BadgeItem background="#EAEBEC" color="#46474C">
                      준비중
                    </BadgeItem>
                  }
                />
                <Divider />
                <NavigateForm
                  icon={ICON_MENU_PROFILE}
                  title="호스트 프로필 설정"
                  onClick={() => {
                    HackleManager.logMenuItemClick('호스트 프로필 설정');

                    navigate(PROFILE_PATH);
                  }}
                />
                <NavigateForm
                  icon={ICON_MENU_INDIVIDUAL}
                  title="호스트 연락처 설정"
                  onClick={() => {
                    HackleManager.logMenuItemClick('호스트 연락처 설정');

                    navigate(INDIVIDUAL_PATH);
                  }}
                />
                <Divider />
                <a
                  href={CONCIERGE_PATH}
                  target="_blank"
                  style={{ width: '100%' }}
                >
                  <NavigateForm
                    icon={ICON_MENU_CONCIERGE}
                    title="컨시어지 서비스"
                    onClick={() => {
                      HackleManager.logMenuItemClick('컨시어지 서비스');
                    }}
                  />
                </a>
                <Divider />
                <NavigateForm
                  icon={ICON_MENU_NOTICE}
                  title="공지사항"
                  onClick={() => {
                    HackleManager.logMenuItemClick('공지사항');

                    navigate(NOTICE_PATH);
                  }}
                />
                <a
                  href={QUESTION_PATH}
                  target="_blank"
                  style={{ width: '100%' }}
                >
                  <NavigateForm
                    icon={ICON_MENU_QUESTION}
                    title={'자주 하는 질문'}
                    onClick={() => {
                      HackleManager.logMenuItemClick('자주 하는 질문');
                    }}
                  />
                </a>
                <NavigateForm
                  icon={ICON_MENU_HEADPHONE}
                  title="호스트 지원센터"
                  onClick={() => {
                    HackleManager.logMenuItemClick('호스트 지원센터');

                    setIsOpenHostApplicationModal(true);
                  }}
                />
                <Divider />
                {!isWebview() && (
                  <NavigateForm
                    icon={ICON_MENU_LOGOUT}
                    title="로그아웃"
                    onClick={() => {
                      handleSignout();
                    }}
                  />
                )}
              </Column>
              <Row gap="8px" style={{ width: '100%' }}>
                <BasicButton
                  content={
                    <a href={SUPER_HOST_NOTICE} target="_blank">
                      슈퍼호스트 안내
                    </a>
                  }
                  onClick={() => {
                    HackleManager.logMenuItemClick('슈퍼호스트 안내');
                  }}
                  style={{ borderRadius: '8px', width: '100%' }}
                />
                <BasicButton
                  content={
                    <a href={HOST_GUIDE_NOTICE} target="_blank">
                      호스트 가이드
                    </a>
                  }
                  onClick={() => {
                    HackleManager.logMenuItemClick('호스트 가이드');
                  }}
                  style={{ borderRadius: '8px', width: '100%' }}
                />
              </Row>
              <Column gap="12px">
                <Row gap="8px">
                  <a href={TERM_NOTICE_PATH} target="_blank">
                    <Text
                      color="#46474c"
                      size="12px"
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => HackleManager.logMenuItemClick('이용약관')}
                    >
                      이용약관
                    </Text>
                  </a>
                  •
                  <a href={INDIVIDUAL_NOTICE} target="_blank">
                    <Text
                      color="#46474c"
                      size="12px"
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() =>
                        HackleManager.logMenuItemClick('개인정보 처리방침')
                      }
                    >
                      개인정보 처리방침
                    </Text>
                  </a>
                </Row>
                <Text color="#70737C" size="10px" weight={400}>
                  버전 {WebVersion}
                </Text>
              </Column>
            </Column>
          </Column>
          {isWebview() && (
            <SwitchButton
              onClick={() => {
                callWebviewHandler(WebviewOperatorType.CloseWebView);
                localStorage.removeItem(STORAGE_KEY.AUTHORIZATION);
              }}
            >
              <img src={ICON_SWITCH} alt="switch icon" />
              크루 모드로 전환
            </SwitchButton>
          )}
          {/* {isOpenIndividualModal && renderIndividualModalContainer()}
          {isOpenProfileUpdateModal && renderProfileUpdateContainer()} */}
          {isOpenHostApplicationModal && renderHostApplicationContainer()}
        </Column>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default MenuPage;
