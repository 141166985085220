import { useEffect, useState } from 'react';
import { cloneDeep, isEmpty } from 'lodash';

import {
  Items,
  ProductParamInput,
  SalesType,
} from 'containers/ProductRegist/hooks/types';
import { CommissionType, CurrencyCode, InventoryTargetType } from 'types/api';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import { MeData } from 'graphQL/Auth/types';

export const useOptionSelectRegistHooks = (
  productParamInput: ProductParamInput,
  optionPage: number,
  handleOnChangeProductParamInput: (key: string, value: any) => void,
) => {
  const myInfo: MeData = useUserInformation((state: any) => state.userInfo);
  const [items, setItems] = useState<Items[]>(
    isEmpty(productParamInput.items) ? [] : productParamInput.items,
  );
  const [selectedItems, setSelectedItems] = useState<Items[]>([]);

  const defaultItem = {
    absentFee: {
      type: CommissionType[myInfo.me.host!.commission.absent.type],
      charge: myInfo.me.host!.commission.absent.charge,
      bias: myInfo.me.host!.commission.absent.bias,
    },
    commission: {
      type: CommissionType[myInfo.me.host!.commission.absent.type],
      charge: myInfo.me.host!.commission.absent.charge,
      bias: myInfo.me.host!.commission.absent.bias,
    },
    daysOfExpiration: 90,
    description: '',
    maximumPurchasableCount: 0,
    minimumQuota: 0,
    name: '',
    options: isEmpty(productParamInput.items)
      ? [
          {
            name: '옵션 선택',
            title: '옵션',
          },
        ]
      : productParamInput.items[0].options.map(optionItem => {
          return { name: '', title: optionItem.title };
        }),
    paramId: `item${Math.random()}`,
    price: {
      currencyCode: CurrencyCode.KRW,
      discountRate: 0,
      retail: 0,
      sale: 0,
    },
    quota:
      productParamInput.frip.attachedToSchedule &&
      productParamInput.inventoryTargetType === InventoryTargetType.BY_ITEM
        ? 10
        : 0,
    representative: false,
    salesType: SalesType.AGENCY,
    scheduledBySale: false,
    ticketCount: 1,
    digitalContent: {
      uri: '',
    },
    saleTerm: null,
  };

  const handleAddOptions = () => {
    if (isEmpty(items)) {
      setItems([{ ...defaultItem, representative: true }]);

      return;
    }

    setItems([...items, defaultItem]);
  };

  const handleResetOptions = (attacedSchedule: boolean) => {
    setItems([
      {
        ...defaultItem,
        representative: true,
        quota:
          attacedSchedule &&
          productParamInput.inventoryTargetType === InventoryTargetType.BY_ITEM
            ? 10
            : 0,
      },
    ]);
  };

  const handleChangeScheduleItems = (inventoryType: InventoryTargetType) => {
    if (inventoryType === InventoryTargetType.BY_ITEM) {
      setItems([{ ...defaultItem, representative: true, quota: 10 }]);

      return;
    }

    setItems([{ ...defaultItem, representative: true, quota: 0 }]);
  };

  const handleSelectedItems = (items: Items) => {
    const filterItem = selectedItems.filter(
      filterItems => filterItems.paramId === items.paramId,
    );

    if (filterItem.length > 0) {
      const resultItem = selectedItems.filter(
        filterItem => filterItem.paramId !== items.paramId,
      );

      setSelectedItems(resultItem);

      return;
    }

    setSelectedItems([...selectedItems, items]);
  };

  const handleAllSettingSelectedItems = (type: 'clear' | 'allAdd') => {
    if (type === 'clear') {
      setSelectedItems([]);

      return;
    }

    setSelectedItems(items);
  };

  const handleDeleteItmes = (deleteItems: Items) => {
    const filterItem = items.filter(
      item => item.paramId !== deleteItems.paramId,
    );

    setItems(filterItem);
  };

  const handleDeleteSelectedItems = () => {
    const copyItems = cloneDeep(items);
    let result: Items[] = [];

    copyItems.filter(item => {
      const selected = selectedItems.map(selectItem => selectItem.paramId);

      if (!selected.includes(item.paramId)) {
        result = [...result, item];
      }
    });

    setItems(result);
    setSelectedItems([]);
  };

  const handleSetRepresentativeItems = (paramId: string) => {
    const copyItems = cloneDeep(items);

    copyItems.forEach(item => {
      if (item.paramId === paramId) {
        item.representative = true;
      } else {
        item.representative = false;
      }
    });

    setItems(copyItems);
  };

  const handleChangeAllPrice = (type: string, price: number) => {
    const copyItems = cloneDeep(items);
    let result: Items[] = [];

    if (type === 'up') {
      copyItems.forEach(item => {
        selectedItems.filter(selectedItem => {
          if (selectedItem.paramId === item.paramId) {
            item.price.sale = item.price.sale + price;
            item.price.retail = item.price.retail + price;
          }
        });
      });
    } else {
      copyItems.forEach(item => {
        selectedItems.filter(selectedItem => {
          if (selectedItem.paramId === item.paramId) {
            item.price.sale =
              item.price.sale - price < 0 ? 0 : item.price.sale - price;
            item.price.retail =
              item.price.retail - price < 0 ? 0 : item.price.retail - price;
          }
        });
      });
    }

    setItems(copyItems);
  };

  useEffect(() => {
    if (isEmpty(productParamInput.items) && optionPage === 2) {
      setItems([
        {
          ...defaultItem,
          representative: true,
          quota:
            productParamInput.frip.attachedToSchedule &&
            productParamInput.inventoryTargetType ===
              InventoryTargetType.BY_ITEM
              ? 10
              : 0,
        },
      ]);
    }
  }, [optionPage]);

  useEffect(() => {
    items.some(item => {
      if (item.options.length > 1) {
        item.name = item.options.map(optionItem => optionItem.name).join('|');
      }
    });

    handleOnChangeProductParamInput('items', items);
  }, [items]);

  return {
    items,
    selectedItems,
    setItems,
    handleAddOptions,
    handleResetOptions,
    handleSelectedItems,
    handleChangeScheduleItems,
    handleAllSettingSelectedItems,
    handleDeleteSelectedItems,
    handleDeleteItmes,
    handleSetRepresentativeItems,
    handleChangeAllPrice,
  };
};
