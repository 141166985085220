import React from 'react';
import styled from 'styled-components';

import Text from 'components/common/Text';

const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;
`;

interface ModifyButtonIProps {
  text: string;
  onClick: () => void;
}

const ModifyButton: React.FC<ModifyButtonIProps> = (props) => {
  const { text, onClick } = props;

  return (
    <Container onClick={onClick}>
      <Text color="#1b1c1e">{text}</Text>
    </Container>
  );
};

export default ModifyButton;
