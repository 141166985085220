import { useMutation } from '@apollo/client';
import axios from 'axios';
import { GENERATE_HOST_ATTACHMENT_UPLOAD_URL } from '..';

export function useMutationGenerateHostAttachmentUploadUrl() {
  const [generateHostAttachmentUploadUrl] = useMutation(
    GENERATE_HOST_ATTACHMENT_UPLOAD_URL
  );

  const handleGenerateHostAttachmentUploadUrl = async (
    hostId: string,
    file: File,
    fileName: string,
    callback: (link: string) => void
  ) => {
    try {
      const response = await generateHostAttachmentUploadUrl({
        variables: { hostId, fileName },
      });

      if (response) {
        const s3UploadResult = await axios.put(
          response.data.generateHostAttachmentUploadUrl.url,
          file,
          {
            headers: {
              'Content-Type': file.type,
            },
          }
        );
        callback(response.data.generateHostAttachmentUploadUrl.url);
      }
    } catch (error) {
      console.log(error, 'generate download url error');
    }
  };

  return { handleGenerateHostAttachmentUploadUrl };
}
