import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import Column from 'components/common/Column';
import Text from 'components/common/Text';
import Divider from 'components/Divider';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import Row from 'components/common/Row';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import { ModifyValue } from 'containers/ProductDetail/hooks/useProductDetaillHooks';
import { Product, ProductState, Term } from 'types/api';
import SaveButton from '../SaveButton';
import DateInput from 'components/Input/DateInput';
import HackleManager from 'utils/hackle';

const OptionSettingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
  width: 100%;

  border-radius: 12px;
  border: 1px solid #dbdcdf;
  background: #f7f7f8;
`;

const DateBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
  padding: 8px 12px;
  position: relative;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;
  cursor: pointer;
`;

interface SaleTermModifyFormIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  productInfo: Product;
  modifyValue: ModifyValue;
  setModifyValue: Dispatch<SetStateAction<ModifyValue>>;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
}

const SaleTermModifyForm: React.FC<SaleTermModifyFormIProps> = props => {
  const {
    isMobile,
    productParamInput,
    productInfo,
    modifyValue,
    setModifyValue,
    handleOnChangeProductParamInput,
  } = props;

  const [saleTerm, setSaleTerm] = useState<Term>(productParamInput.saleTerm);

  const isAbled = productParamInput.saleTerm !== saleTerm;

  const handleResetSaleTerm = () => {
    setSaleTerm(productParamInput.saleTerm);
  };

  const handleRegistSaleTerm = () => {
    handleOnChangeProductParamInput('saleTerm', saleTerm);
  };

  useEffect(() => {
    HackleManager.logProductModifySalesPeriodView(
      Number(productInfo.id),
      productInfo.title,
    );
  }, []);

  const renderSaleTerm = (): JSX.Element => {
    return (
      <OptionSettingBox>
        <Column gap="12px" align="flex-start">
          <Text color="#1b1c1e" size="16px">
            판매 시작일
          </Text>
          <DateBox>
            <Row gap="2px">
              <Text
                color={saleTerm?.startedAt ? '#1b1c1e' : '#c2c4c8'}
                size="14px"
                weight={400}
                style={{ zIndex: 2 }}
              >
                {saleTerm?.startedAt
                  ? `${dayjs(saleTerm.startedAt).format('YY년 • M월 • D일')}`
                  : '년 • 월 • 일'}
              </Text>
              <DateInput
                onChange={e =>
                  setSaleTerm({
                    ...saleTerm,
                    startedAt: new Date(e.target.value).getTime(),
                  })
                }
              />
            </Row>
            <Text
              color="#1b1c1e"
              size="12px"
              weight={400}
              style={{ zIndex: 2 }}
            >
              00시 00분 부터
            </Text>
          </DateBox>
        </Column>
        <Divider />
        <Column gap="12px" align="flex-start">
          <Text color="#1b1c1e" size="16px">
            판매 종료일
          </Text>
          <DateBox>
            <Row gap="2px">
              <Text
                color={saleTerm?.endedAt ? '#1b1c1e' : '#c2c4c8'}
                size="14px"
                weight={400}
                style={{ zIndex: 2 }}
              >
                {saleTerm?.endedAt
                  ? `${dayjs(saleTerm.endedAt).format('YY년 • M월 • D일')}`
                  : '년 • 월 • 일'}
              </Text>
              <DateInput
                onChange={e =>
                  setSaleTerm({
                    ...saleTerm,
                    endedAt: new Date(e.target.value).getTime(),
                  })
                }
              />
            </Row>
            <Text
              color="#1b1c1e"
              size="12px"
              weight={400}
              style={{ zIndex: 2 }}
            >
              23시 59분 까지
            </Text>
          </DateBox>
        </Column>
      </OptionSettingBox>
    );
  };

  const renderContents = (): JSX.Element => {
    if (isMobile) {
      return (
        <ModalContainer
          isAbled={productInfo.status !== ProductState.CLOSED && isAbled}
          isMobile={isMobile}
          onAcceptText="적용"
          onClose={() => {
            setModifyValue({ ...modifyValue, modifyStatus: '' });
          }}
          onClick={() => {
            HackleManager.logProductModifyItemSaveComplete(
              Number(productInfo.id),
              productInfo.title,
              '판매일정',
            );

            handleRegistSaleTerm();

            setModifyValue({
              ...modifyValue,
              isModify: true,
              modifyStatus: '',
            });
          }}
        >
          <Column gap="24px" style={{ padding: '16px 20px 0' }}>
            <Row>
              <Text color="#000" size="16px" weight={600}>
                판매 일정
              </Text>
            </Row>
            {renderSaleTerm()}
          </Column>
        </ModalContainer>
      );
    }

    return (
      <Column>
        <Row
          justifyContent="flex-start"
          style={{
            position: 'sticky',
            top: 0,
            padding: '32px 40px',
            width: '100%',
            background: '#fff',
            borderBottom: '1px solid #eaebec',
          }}
        >
          <Text color="#1B1C1E" size="24px">
            판매일정 수정하기
          </Text>
        </Row>
        <Column gap="24px" style={{ padding: '32px 40px' }}>
          {renderSaleTerm()}
          {productInfo.status !== ProductState.CLOSED && (
            <SaveButton
              isAbled={isAbled}
              modifyValue={modifyValue}
              setModifyValue={setModifyValue}
              handleResetItem={handleResetSaleTerm}
              handleRegistItem={() => {
                HackleManager.logProductModifyItemSaveComplete(
                  Number(productInfo.id),
                  productInfo.title,
                  '판매일정',
                );
                handleRegistSaleTerm();
              }}
            />
          )}
        </Column>
      </Column>
    );
  };

  return renderContents();
};

export default SaleTermModifyForm;
