import React, { SetStateAction, useState } from 'react';
import styled from 'styled-components';
import Column from './Column';
import {
  ICON_CATEGORY_SEARCH,
  ICON_CATEGORY_SEARCH_ACTIVE,
} from 'assets/icons';

const SearchInputContainer = styled.div`
  display: flex;
  gap: 8px;
  padding: 12px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: var(--Static-White, #fff);
`;

const SearchInput = styled.textarea`
  width: 100%;
  border: none;

  color: var(--gray-gray-09, #1b1c1e);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;

  &::placeholder {
    color: #989ba2;
  }
`;

interface SearchFormIProps {
  isDisabled?: boolean;
  placeholder: string;
  searchValue: string;
  setSearchValue: React.Dispatch<SetStateAction<string>>;
  onKeyPress?: (e: any) => void;
  style?: React.CSSProperties;
}

const SearchForm: React.FC<SearchFormIProps> = props => {
  const {
    isDisabled,
    placeholder,
    searchValue,
    setSearchValue,
    onKeyPress,
    style,
  } = props;

  const handleSetValue = (e: any) => {
    setSearchValue(e.target.value);
  };

  return (
    <Column gap="12px" align="flex-start">
      <SearchInputContainer style={{ ...style }}>
        <img
          src={
            searchValue !== ''
              ? ICON_CATEGORY_SEARCH_ACTIVE
              : ICON_CATEGORY_SEARCH
          }
          alt="search icon"
        />
        <SearchInput
          disabled={isDisabled}
          placeholder={placeholder}
          value={searchValue}
          onChange={handleSetValue}
          rows={1}
          onKeyPress={e => {
            onKeyPress && onKeyPress(e);
          }}
        />
      </SearchInputContainer>
    </Column>
  );
};

export default SearchForm;
