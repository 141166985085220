import { useMutation } from '@apollo/client';
import { errorHandler } from 'utils/ErrorHandler';
import { CHANGE_HOST_PROFILEIMAGE } from '..';

export function useMutationChageHostProfileImage() {
  const [changeHostProfileImage] = useMutation(CHANGE_HOST_PROFILEIMAGE);

  const handleChangeHostProfileImage = async (
    hostId: string,
    contentId: string,
  ) => {
    try {
      await changeHostProfileImage({ variables: { hostId, contentId } });
    } catch (error) {
      errorHandler(error);
    }
  };

  return { handleChangeHostProfileImage };
}
