import { GET_QNA_COUNT } from './../index';
import { useLazyQuery } from '@apollo/client';
import { ProductQnaConnection } from 'types/api';
import { MeData } from 'graphQL/Auth/types';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import { useEffect, useState } from 'react';

export function useQueryGetQnaCount(productId?: string) {
  const myInfo: MeData = useUserInformation((state: any) => state.userInfo);

  const [qnaCount, setQnaCount] = useState<{ new: number; complete: number }>({
    new: 0,
    complete: 0,
  });

  const [getQnaNewCount] = useLazyQuery<{
    qna: { productQnas: ProductQnaConnection };
  }>(GET_QNA_COUNT);
  const [getQnaCount] = useLazyQuery<{
    qna: { productQnas: ProductQnaConnection };
  }>(GET_QNA_COUNT);

  const handleGetCompleteCount = async () => {
    try {
      const response = await getQnaCount({
        variables: {
          filter: {
            isAnswering: true,
            statusIn: ['COMPLETE'],
            hostIds: [myInfo.me.host.id],
            productIds: productId ? [productId] : null,
          },
          page: 1,
          size: 5,
        },
      });

      if (response) {
        const newResponse = await getQnaNewCount({
          variables: {
            filter: {
              isAnswering: true,
              statusIn: ['NEW'],
              hostIds: [myInfo.me.host.id],
              productIds: productId ? [productId] : null,
            },
            page: 1,
            size: 5,
          },
        });

        if (newResponse) {
          setQnaCount({
            new: newResponse.data.qna.productQnas.totalCount,
            complete: response.data.qna.productQnas.totalCount,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetCompleteCount();
  }, []);

  return { qnaCount };
}
