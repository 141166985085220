import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ICON_CLOSE } from 'assets/icons';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import SignupCertification from 'containers/Signup/components/SignupCertification';
import SignupTerm from 'containers/Signup/components/SignupTerm';
import AuthButton from 'components/Button/AuthButton';
import { SignupParam } from 'containers/Signup/hooks/types';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import {
  AuthorityLevel,
  UserLoginResponse,
} from 'containers/Login/graphQL/types';
import { MeData } from 'graphQL/Auth/types';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import SignupSuccessModal from 'containers/Signup/components/SignupSuccessModal';
import { MENU_PATH, PROFILE_PATH, SALE_LIST_PATH } from 'constants/path';
import SignFailModal from 'containers/Signup/components/SignFailModal';
import { useGetMe } from 'graphQL/Auth/user/useGetMe';
import HackleManager from 'utils/hackle';

interface SignupHostToastIProps {
  signupParam: SignupParam;
  isMobileCertification: boolean;
  isOpenSuccessModal: boolean;
  isOpenFailModal: boolean;
  handleCreateHost: (
    params: UserLoginResponse,
    mobileNumber: string,
    signupParam?: SignupParam,
  ) => void;
  handleChangeSignupParam: (key: string, value: string) => void;
  handelCheckedMarketing: (checked: boolean) => void;
  handleMobileCertification: () => void;
  handleIsOpenSignupHostToast: () => void;
  handleCloseFailModal: () => void;
}

const SignupHostToast: React.FC<SignupHostToastIProps> = props => {
  const {
    signupParam,
    isMobileCertification,
    isOpenSuccessModal,
    isOpenFailModal,
    handleCreateHost,
    handleChangeSignupParam,
    handelCheckedMarketing,
    handleMobileCertification,
    handleIsOpenSignupHostToast,
    handleCloseFailModal,
  } = props;

  const { handleGetUserInformation, handleRefetchUser } = useGetMe();

  const [isTermChecked, setIsTermChecked] = useState<boolean>(false);

  const handleTermChecked = (isTermCheck: boolean) => {
    setIsTermChecked(isTermCheck);
  };

  const navigate = useNavigate();
  const myInfo: MeData = useUserInformation(state => state.userInfo);

  useEffect(() => {
    HackleManager.logLandingSignupView();
  }, []);

  const renderSignUpForm = (): JSX.Element => {
    if (myInfo.me.user.certificated) {
      return (
        <Column gap="24px" style={{ padding: '20px 20px 32px 20px' }}>
          <Column gap="8px" align="flex-start">
            <Text color="#1B1C1E" size="20px" weight={700}>
              약관 동의가 필요해요.
            </Text>
          </Column>
          <SignupTerm
            marketingChecked={signupParam.agreement.marketing}
            handleChangeMarketing={handelCheckedMarketing}
            callback={handleTermChecked}
          />
        </Column>
      );
    }

    return (
      <Column gap="24px" style={{ padding: '20px 20px 32px 20px' }}>
        <Column gap="8px" align="flex-start">
          <Text color="#1B1C1E" size="20px" weight={700}>
            본인 인증 및 약관 동의가 필요해요.
          </Text>
          <Text
            color="#70737c"
            size="14px"
            weight={400}
            style={{ letterSpacing: '-0.8px' }}
          >
            호스트가 되려면 최초 1회 본인 인증 및 약관동의가 필요해요. 모든
            프립을 안전하고 신뢰할 수 있게 만들기 위한 중요한 절차입니다.
          </Text>
        </Column>
        <SignupCertification
          mobileNumber={signupParam.mobileNumber}
          isMobileCertification={isMobileCertification}
          handleChangeSignupParam={handleChangeSignupParam}
          handleMobileCertification={handleMobileCertification}
        />
        <SignupTerm
          marketingChecked={signupParam.agreement.marketing}
          handleChangeMarketing={handelCheckedMarketing}
          callback={handleTermChecked}
        />
      </Column>
    );
  };

  return (
    <ModalContainer
      isAbled={true}
      isMobile={true}
      isBottomSheet={true}
      onClose={() => {
        handleIsOpenSignupHostToast();
      }}
      onClick={() => {}}
      modalStyle={{ zIndex: 9099999 }}
      style={{ gap: 0 }}
    >
      <Row
        justifyContent="flex-start"
        style={{ padding: '16px 20px', borderBottom: '1px solid #EAEBEC' }}
      >
        <img
          src={ICON_CLOSE}
          alt="close icon"
          onClick={handleIsOpenSignupHostToast}
        />
      </Row>
      {renderSignUpForm()}
      <Row
        style={{
          padding: '16px 20px',
          width: '100%',
          borderTop: '1px solid #EAEBEC',
        }}
      >
        <AuthButton
          name="호스트 시작하기"
          isAbled={
            myInfo.me.user.certificated
              ? isTermChecked
              : isMobileCertification && isTermChecked
          }
          onClick={() => {
            if (myInfo.me.user.certificated && isTermChecked) {
              handleCreateHost(
                {
                  accessToken: '',
                  authorityLevel: AuthorityLevel.HOST,
                  name: myInfo.me.user.nickname,
                  email: myInfo.me.user.email,
                  userId: myInfo.me.user.id,
                  expiresIn: 0,
                  joinedAt: '',
                  tokenType: '',
                  validatedAt: '',
                },
                myInfo.me.user.mobileNumber,
              );

              HackleManager.logLandingSignupComplete(
                signupParam.agreement.marketing,
              );
              return;
            }

            if (isMobileCertification && isTermChecked) {
              handleCreateHost(
                {
                  accessToken: '',
                  authorityLevel: AuthorityLevel.HOST,
                  name: myInfo.me.user.nickname,
                  email: myInfo.me.user.email,
                  userId: myInfo.me.user.id,
                  expiresIn: 0,
                  joinedAt: '',
                  tokenType: '',
                  validatedAt: '',
                },
                signupParam.mobileNumber,
              );

              HackleManager.logLandingSignupComplete(
                signupParam.agreement.marketing,
              );
            }
          }}
        />
      </Row>
      {isOpenSuccessModal && (
        <SignupSuccessModal
          onCloseNavigate={() => {
            // handleRefetchUser();
            // navigate(SALE_LIST_PATH);
          }}
          onStartNavigate={() => {
            handleRefetchUser(() => {
              navigate(PROFILE_PATH);
            });
          }}
        />
      )}
      {isOpenFailModal && (
        <SignFailModal
          content={
            <Column
              gap="8px"
              align="flex-start"
              style={{
                padding: '24px 20px',
                borderTop: '1px solid #EAEBEC',
                borderBottom: '1px solid #EAEBEC',
              }}
            >
              <Text color="#1b1c1e" size="20px" weight={600}>
                계정에 문제가 있어요
              </Text>
              <Text color="#1b1c1e" size="16px" weight={400}>
                이미 다른 호스트 계정에 연결된 번호이거나 계정에 문제가 있을 수
                있어요. 고객센터에 문의해 주세요.
              </Text>
            </Column>
          }
          onClose={handleCloseFailModal}
        />
      )}
    </ModalContainer>
  );
};

export default SignupHostToast;
