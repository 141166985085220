import { useState } from 'react';
import { useQuery } from '@apollo/client';

import { GET_SETTLEMENT_LIST_BY_FILTER } from '..';
import { Settlement } from '../types';

export function useQueryGetSettlementListByFilter() {
  const { data, loading, refetch } = useQuery<Settlement>(
    GET_SETTLEMENT_LIST_BY_FILTER,
    {
      variables: {
        page: 1,
        size: 50,
        filter: {
          hostId: null,
          invoiceIds: null,
          taxIds: null,
          uncollected: null,
          unissued: null,
          term: null,
          hasMinusTotalPayment: null,
        },
      },
    }
  );
  const [refetchLoading, setRefetchLoading] = useState<boolean>(false);

  const refetchSettlementItems = () => {
    refetch({
      page: 1,
      size: 50,
      filter: {
        hostId: null,
        invoiceIds: null,
        taxIds: null,
        uncollected: null,
        unissued: null,
        term: null,
        hasMinusTotalPayment: null,
      },
    });

    setRefetchLoading(true);
  };

  return {
    data,
    isLoading: loading,
    refetchLoading,
    refetchSettlementItems,
  };
}
