const MESSAGE_HANDLER_NAME = 'FripMessageHandler';

export enum WebviewOperatorType {
  RecordTrackingCode = 'recordTrackingCodeHandler',
  CloseWebView = 'closeWebViewHandler',
  GetClipboardText = 'getClipboardTextHandler',
  OpenActionUrl = 'openActionUrlHandler',
  ShareHandler = 'shareHandler',
  RefreshUser = 'refreshUserHandler',
  UpdateWebviewHeader = 'updateWebviewHeaderHandler',
  RecordPurchaseTracking = 'recordPurchaseTrackingHandler',
  RecordKinesisEvent = 'recordKinesisEventHandler',
  RecordKakaoPurchase = 'recordKakaoPurchaseHandler',
  RecordAirbridgeEvent = 'recordAirbridgeEventHandler',
  RecordAmplitudeEvent = 'recordAmplitudeEventHandler',
  AnalyticsEventHandler = 'analyticsEventHandler',
  ChangeChannelNotificationHandler = 'changeChannelNotificationHandler',
  UpdateMessageImageFileHandler = 'updateMessageImageFileHandler',
  UploadSettlementIdentityHandler = 'uploadSettlementIdentitiyHandler',
  RequestCalendarDateHandler = 'requestCalendarDateHandler',
  MakeTelePhoneHandler = 'makeTelePhoneHandler',
  ViewPortResize = 'viewPortResize',
  FindAddressHandler = 'findAddressHandler',
  LightFripEditorHandler = 'lightFripEditorHandler',
  SendLightFripEditorHeightHandler = 'sendLightFripEditorHeightHandler',
  BackwardControlHandler = 'backwardControlHandler',
  CheckChannelUnreadCount = 'checkChannelUnreadCount',
  SubmitLogoutHandler = 'submitLogoutHandler',
  SetAuthorizationHandler = 'setAuthorizationHandler',
  SetMobileModalNavigate = 'setMobileModalNavigate',
  OpenWebConsoleModal = 'openWebConsoleModal',
}

export function callWebviewHandler<TParams>(
  operatorName: WebviewOperatorType,
  params?: Object | TParams,
  callbackName?: string,
) {
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers[MESSAGE_HANDLER_NAME]
  ) {
    window.webkit.messageHandlers[MESSAGE_HANDLER_NAME].postMessage({
      operatorName,
      callbackName,
      params: params || {},
    });
    return true;
  }

  if (window.Android && window.Android[MESSAGE_HANDLER_NAME]) {
    window.Android[MESSAGE_HANDLER_NAME](
      operatorName,
      callbackName,
      JSON.stringify(params),
    );
    return true;
  }

  return false;
}

export function isWebview() {
  return (
    navigator.userAgent.includes('Frip') &&
    Boolean(window.webkit || window.Android)
  );
}
