import React, { useState } from 'react';
import ModifyContainer from '../ModifyContainer';
import Text from 'components/common/Text';
import { ModifyValue } from '../../hooks/useProductDetaillHooks';

interface PrecautionContainerIProps {
  isMobile: boolean;
  notice: string;
  modifyValue: ModifyValue;
  setModifyValue: React.Dispatch<React.SetStateAction<ModifyValue>>;
}

const PrecautionContainer: React.FC<PrecautionContainerIProps> = props => {
  const { isMobile, notice, modifyValue, setModifyValue } = props;

  return (
    <ModifyContainer
      title="유의사항"
      isSelected={!isMobile && modifyValue.modifyStatus === 'precaution'}
      onClick={() => {
        setModifyValue({ ...modifyValue, modifyStatus: 'precaution' });
      }}
      style={{ alignItems: 'flex-start' }}
    >
      <Text
        color="#70737c"
        size="14px"
        weight={400}
        style={{ whiteSpace: 'pre-line' }}
      >
        {notice}
      </Text>
    </ModifyContainer>
  );
};

export default PrecautionContainer;
