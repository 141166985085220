import { useEffect, useState } from 'react';
import { useQueryGetNoticeBoard } from '../graphQL/hooks/useQueryGetNoticeBoard';
import { NoticeFilter } from '../graphQL/types';

export const useNoticeHooks = () => {
  const { noticeBoard, handleRefetchNoticeBoard } = useQueryGetNoticeBoard();

  const [isOpenCountFilter, setIsOpenCountFilter] = useState<boolean>(false);
  const [noticeFilter, setNoticeFilter] = useState<NoticeFilter>({
    filter: {
      forHostOnly: true,
      titleLike: '',
    },
    page: 1,
    size: 10,
  });
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [isOpenDetailModal, setIsOpenDetailModal] = useState<boolean>(false);

  const handleSetFilterItem = (key: string, value: any) => {
    setNoticeFilter({ ...noticeFilter, [key]: value });
  };

  const handleIsOpenCountFilter = () => {
    setIsOpenCountFilter(!isOpenCountFilter);
  };

  const handleOpenDetailModal = (index: number) => {
    setSelectedIndex(index);
    setIsOpenDetailModal(true);
  };

  useEffect(() => {
    if (noticeBoard) {
      handleRefetchNoticeBoard(noticeFilter);
    }
  }, [noticeFilter]);

  return {
    noticeBoard,
    noticeFilter,
    isOpenCountFilter,
    selectedIndex,
    isOpenDetailModal,
    setIsOpenDetailModal,
    handleOpenDetailModal,
    handleSetFilterItem,
    handleIsOpenCountFilter,
  };
};
