import React, { useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import Slider from 'react-slick';
import styled from 'styled-components';
import HackleManager from 'utils/hackle';
import { CurrentBanner } from '../graphQL/types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const SliderContainer = styled(Slider)`
  width: 100%;
  height: 100%;
  overflow-x: hidden;

  button {
    display: none !important;
  }

  .slick-track {
    display: flex;
    align-items: center;
    min-width: 375px;
    width: 100%;
  }

  .slick-slide {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;

const ImageThumbnail = styled.img<{ imageWidth: number }>`
  width: ${props => props.imageWidth}px !important;
  object-fit: cover;
  border-radius: 12px;
`;

const ConuntBox = styled.div`
  display: flex;
  gap: 2px;
  position: absolute;
  right: 12px;
  bottom: 12px;
  padding: 5px 8px;
  border-radius: 16px;
  background: var(--Grayscale-Opacity-Gray-500, rgba(17, 17, 17, 0.44));

  color: #fff;
  text-align: right;

  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.12px;
`;

interface CurrentBannersIProps {
  isMobile: boolean;
  currentBanners: CurrentBanner;
}

const CurrentBanners: React.FC<CurrentBannersIProps> = props => {
  const { isMobile, currentBanners } = props;

  const [currentIndex, setCurrentIndex] = useState<number>(1);

  const { width, ref } = useResizeDetector();

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    afterChange: (index: number) => setCurrentIndex(index + 1),
  };

  return (
    <Container ref={ref}>
      <SliderContainer {...settings}>
        {currentBanners.banner.currents.map((item, idx) => {
          return (
            <a href={item.actionUri} target="_blank">
              <ImageThumbnail
                src={item.content.thumbnail}
                alt="product thumbnail"
                key={idx}
                imageWidth={width}
                onClick={() => {
                  HackleManager.logMenuBannerClick(
                    item.id,
                    item.title,
                    item.actionUri,
                  );
                }}
              />
            </a>
          );
        })}
      </SliderContainer>
      <ConuntBox>
        <span>{currentIndex}</span>
        <span style={{ color: '#ccc' }}>/</span>
        <span style={{ color: '#ccc' }}>
          {currentBanners.banner.currents.length}
        </span>
      </ConuntBox>
      {/* {isOpen && (
        <ModalContainer
          isAbled={true}
          isMobile={true}
          isBottomSheet={true}
          onClose={() => {}}
          onClick={() => {}}
        >
          <iframe
            src="https://www.naver.com/"
            style={{ width: '100%', height: '100vh' }}
            onClick={() => setIsOpen(false)}
          />
        </ModalContainer>
      )} */}
    </Container>
  );
};

export default CurrentBanners;
