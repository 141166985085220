import React from 'react';
import styled from 'styled-components';
import SettlementBadge from './SettlementBadge';
import { SettlementsEdges } from '../graphQL/types';
import CommonModalContainer from 'containers/Settlement/components/ModalContainer';

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 0 20px;
  width: 100%;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border: 0.5px solid #dbdcdf;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const Title = styled.div<{ isActivedPayment: boolean }>`
  color: ${(props) => (props.isActivedPayment ? '#1B1C1E' : '#C2C4C8')};
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.6px;
`;

const UnderlineText = styled.div`
  color: #1b1c1e;
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 16.9px */
  letter-spacing: -0.6px;
  text-decoration-line: underline;
`;

const Price = styled.div<{ isActivedPayment: boolean }>`
  color: ${(props) => (props.isActivedPayment ? '#46474c' : '#C2C4C8')};
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.6px;
`;

interface SettlementListModalIProps {
  settlementList: SettlementsEdges[];
  handleSelectSettlementItem: (index: number) => void;
  onClose: () => void;
}

const SettlementListModal: React.FC<SettlementListModalIProps> = (props) => {
  const { settlementList, handleSelectSettlementItem, onClose } = props;

  return (
    <CommonModalContainer onClose={onClose}>
      {settlementList.map((item, idx) => {
        return (
          <React.Fragment key={idx}>
            <Row>
              <Column>
                <Row
                  style={{
                    justifyContent: 'center',
                    padding: '0',
                    width: 'inherit',
                  }}
                >
                  <Title
                    isActivedPayment={
                      Number(item.node.totalPayment.value) !== 0
                    }
                  >
                    {item.node.title.slice(2)}
                  </Title>
                  <SettlementBadge
                    status={
                      item.node.invoice !== null
                        ? item.node.invoice.status
                        : null
                    }
                  />
                </Row>
                <Price
                  isActivedPayment={Number(item.node.totalPayment.value) !== 0}
                  style={{
                    color:
                      Number(item.node.totalPayment.value) +
                        Number(item.node.totalFee.value) >=
                      0
                        ? '#1b1c1e'
                        : '#FF4242',
                  }}
                >
                  {(
                    Number(item.node.totalPayment.value) +
                    Number(item.node.totalFee.value)
                  ).toLocaleString()}
                  원
                </Price>
              </Column>
              <UnderlineText
                onClick={() => {
                  handleSelectSettlementItem(idx);
                  onClose();
                }}
              >
                보기
              </UnderlineText>
            </Row>
            <Divider />
          </React.Fragment>
        );
      })}
    </CommonModalContainer>
  );
};

export default SettlementListModal;
