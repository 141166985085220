import { Items } from 'containers/ProductRegist/hooks/types';
import { FripKind, ItemState, ProductKind, ProductState } from 'types/api';

export enum ProductTermSearchType {
  PRODUCT_CREATED = 'PRODUCT_CREATED',
  PRODUCT_UPDATED = 'PRODUCT_UPDATED',
  SALE_END = 'SALE_END',
  SALE_START = 'SALE_START',
}
export interface ProductFilterInput {
  hostId: string;
  statusIn: ProductState[];
  fripKind: FripKind;
  attributeIds?: string[];
  beenOpened?: boolean;
  categoryIds?: string;
  ids?: string | null;
  managerId?: string;
  kind?: ProductKind;
  titleLike?: string | null;
  termWithType?: ProductTermWithTypeInput;
  standardCategoryIds?: string[];
  exposedChannelIds?: string | null;
  itemStatusIn?: ItemState[];
}

export interface ProductTermWithTypeInput {
  type: ProductTermSearchType;
  term: { startedAt: number; endedAt: number };
}

export interface ProductList {
  product: Product;
}

export interface Product {
  products: Products;
}

export interface Products {
  pageInfo: { hasNextPage: boolean };
  edges: EdgesEntity[];
  totalCount: number;
}

export interface EdgesEntity {
  node: Node;
}

export interface Node {
  id: string;
  title: string;
  frip: Frip;
  host: LabelOrHost;
  manager?: null;
  createdAt: number;
  updatedAt: number;
  standardCategories?: StandardCategoriesEntity[];
  exposures?: (ExposuresEntity | null)[];
  headerContents?: (HeaderContentsEntity | null)[];
  lastInspection?: LastInspection | null;
  saleTerm?: SaleTerm | null;
  status: ProductState;
}

export interface Frip {
  kind: FripKind;
}

export interface LabelOrHost {
  id: string;
  name: string;
}

export interface StandardCategoriesEntity {
  id: string;
  label: LabelOrHost;
}

export interface ExposuresEntity {
  id: string;
  description: string;
  name: string;
  status: string;
}

export interface HeaderContentsEntity {
  id: string;
  style: string;
  content: Content;
}

export interface Content {
  id: string;
  thumbnail: string;
}

export interface LastInspection {
  id: string;
  status: string;
  items: {
    comment: string;
    reasons: { name: string; description: string }[];
  }[];
}

export interface SaleTerm {
  duration: number;
  startedAt: number;
  endedAt: number;
}

// export interface ProductDetail {
//   id: string;
//   title: string;
//   catchphrase: string;
//   kind: ProductKind;
//   status: string;
//   saleTerm: SaleTerm;
//   firstOpenedAt?: null;
//   underageRestricted: boolean;
//   unverifiedUserRestricted: boolean;
//   cancelingRestricted: boolean;
//   host: Host;
//   manager?: null;
//   attributes?: AttributesEntityOrLabelOrLocation[];
//   standardCategories?: StandardCategoriesEntityOrDisplayCategory[] | null;
//   categories?: CategoriesEntity[];
//   headerContents?: HeaderContents[];
//   contents?: ContentsEntity[] | null;
//   options?: OptionsEntity[] | null;
//   items?: Items[];
//   externalProvisions?: null[] | null;
//   grossPurchaseLimitation: GrossPurchaseLimitation;
//   lastInspection?: null;
//   maximumPurchasableCount: number;
//   tags?: null[] | null;
//   inquiryTemplate: ExposuresEntityOrInquiryTemplate;
//   inventoryTargetOptionLevel: number;
//   inventoryTargetType: string;
//   frip: Frip;
//   keywords?: string[] | null;
//   refundPolicy: RefundPolicy;
//   exposures?: ExposuresEntityOrInquiryTemplate[] | null;
//   notice: string;
// }
// export interface SaleTerm {
//   startedAt: number;
//   endedAt: number;
// }
// export interface Host {
//   id: string;
//   name: string;
//   super: boolean;
//   commission: Commission;
// }
// export interface Commission {
//   default: DefaultOrAbsentOrAbsentFeeOrCommission;
//   absent: DefaultOrAbsentOrAbsentFeeOrCommission;
// }
// export interface DefaultOrAbsentOrAbsentFeeOrCommission {
//   bias: string;
//   charge: string;
//   type: string;
// }
// export interface AttributesEntityOrLabelOrLocation {
//   id: string;
//   name: string;
// }
// export interface StandardCategoriesEntityOrDisplayCategory {
//   id: string;
//   label: AttributesEntityOrLabelOrLocation;
// }
// export interface CategoriesEntity {
//   displayCategory: StandardCategoriesEntityOrDisplayCategory;
//   representative: boolean;
// }
// export interface HeaderContents {
//   id: string;
//   content: { id: string; uri: string };
// }
// export interface ContentsEntity {
//   id: string;
//   content: string;
// }
// export interface OptionsEntity {
//   id: string;
//   title: string;
//   names?: string[] | null;
// }
// export interface ItemsEntity {
//   id: string;
//   options?: OptionsEntity1[] | null;
//   saleTerm?: null;
//   minimumQuota: number;
//   maximumPurchasableCount: number;
//   quota: number;
//   representative: boolean;
//   daysOfExpiration: number;
//   description: string;
//   name: string;
//   ticketCount: number;
//   scheduledBySale: boolean;
//   salesType: string;
//   absentFee: DefaultOrAbsentOrAbsentFeeOrCommission;
//   commission: DefaultOrAbsentOrAbsentFeeOrCommission;
//   price: Price;
//   digitalContent: any;
// }
// export interface OptionsEntity1 {
//   id: string;
//   name: string;
//   title: string;
// }
// export interface Price {
//   sale: number;
//   retail: number;
// }
// export interface GrossPurchaseLimitation {
//   count: number;
// }
// export interface ExposuresEntityOrInquiryTemplate {
//   id: string;
// }
// export interface Frip {
//   kind: FripKind;
//   attachedToSchedule: boolean;
//   bookingConfirmationEnabled: boolean;
//   difficulty: string;
//   locationsOfVenue?: LocationsOfVenueEntityOrLocationOfGathering[] | null;
//   locationOfGathering: LocationsOfVenueEntityOrLocationOfGathering;
//   plans?: null[] | null;
//   curriculum: Curriculum;
//   exclusions?: string[] | null;
//   inclusions?: string[] | null;
//   stuffsToPrepare?: string[] | null;
//   daysOfExpiration: number;
//   recommendedAge: number;
// }
// export interface LocationsOfVenueEntityOrLocationOfGathering {
//   id: string;
//   label: string;
//   address: string;
//   geoPoint: GeoPoint;
//   overseas: boolean;
//   location: AttributesEntityOrLabelOrLocation;
// }
// export interface GeoPoint {
//   latitude: number;
//   longitude: number;
// }
// export interface Curriculum {
//   kind: string;
//   sections?: SectionsEntity[] | null;
//   style: string;
// }
// export interface SectionsEntity {
//   id: string;
//   title: string;
//   etc: string;
//   status: string;
//   items?: ItemsEntity1[] | null;
// }
// export interface ItemsEntity1 {
//   id: string;
//   term: Term;
//   duration: number;
//   textTime: string;
//   content: string;
// }
// export interface Term {
//   startingTime: StartingTimeOrEndingTime;
//   endingTime: StartingTimeOrEndingTime;
// }
// export interface StartingTimeOrEndingTime {
//   hour: number;
//   minute: number;
// }
// export interface RefundPolicy {
//   id: string;
//   name: string;
//   extraNotice?: null;
//   description: string;
//   representative: boolean;
//   status: string;
//   type: string;
// }
