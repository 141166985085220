import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { callWebviewHandler, WebviewOperatorType } from 'utils/webview';
import SettlementButton from './components/Button';
import CheckInformation from './components/CheckInformation';
import CloseViewModal from './components/CloseViewModal';
import Header from './components/Header';
import HostInformation from './components/HostInformation';
import RegistSettlementInformation from './components/RegistSettlementInformation';
import RegistIdentity from './components/RegistIdentity';
import { useSettlementRegist } from './hooks/useSettlementRegist';
import { useResizeDetector } from 'react-resize-detector';
import Column from 'components/common/Column';
import CommonRow from 'components/common/Row';
import { ICON_FRIP_LOGO } from 'assets/icons';
import CommonImage from 'components/common/CommonImage';
import SelectButton from 'containers/ProductRegist/components/common/SelectButton';
import HackleManager from 'utils/hackle';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* max-width: 768px; */
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 85px;
  z-index: 21;
`;

const BottomContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  padding: 16px 20px;
  margin: 0 auto;
  max-width: 768px;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #ddd;
  z-index: 10;
`;

const NextButton = styled.div<{ $isActiveButton: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  color: ${props => (props.$isActiveButton ? '#fff' : '#dbdcdf')};
  font-weight: 700;
  text-decoration-line: none;
  border-radius: 8px;
  background: ${props => (props.$isActiveButton ? '#1B1C1E' : '#f4f4f5')};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #1b1c1e;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.6px;

  span {
    text-decoration-line: underline;
  }
`;

const SettlementRegistPage = () => {
  const location = useLocation();

  const {
    pageNumber,
    businessInformation,
    registrationNo,
    previewImage,
    isClosePage,
    handlePageForwardNavigate,
    handlePageBackwardNavigate,
    handleSetBusinessInformation,
    handleCheckForeign,
    handleGetImageUrl,
    setPreviewImage,
    handleValidationCheck,
    handleModifyBusinessInformation,
    handleOnClosePage,
  } = useSettlementRegist();

  const { width, ref } = useResizeDetector();

  const isMobile = width ? width < 768 : window.innerWidth < 768;

  useEffect(() => {
    HackleManager.logSettlementRegistInfoView();

    if (window.Android) {
      callWebviewHandler(
        WebviewOperatorType.BackwardControlHandler,
        undefined,
        'handleOnClosePage',
      );

      window.handleOnClosePage = handleOnClosePage;
    }
  }, []);

  const renderRegistPage = (): JSX.Element => {
    switch (pageNumber) {
      case 1: {
        return (
          <HostInformation
            businessInformation={businessInformation}
            handleSetBusinessInformation={handleSetBusinessInformation}
          />
        );
      }
      case 2: {
        return (
          <RegistSettlementInformation
            businessInformation={businessInformation}
            registrationNo={registrationNo}
            handleCheckForeign={handleCheckForeign}
            handleSetBusinessInformation={handleSetBusinessInformation}
          />
        );
      }
      case 3: {
        return (
          <RegistIdentity
            businessInformation={businessInformation}
            previewImage={previewImage}
            handleGetImageUrl={handleGetImageUrl}
            setPreviewImage={setPreviewImage}
          />
        );
      }
      case 4: {
        return (
          <CheckInformation
            businessInformation={businessInformation}
            registrationNo={registrationNo}
            previewImage={previewImage}
            handleModifyBusinessInformation={handleModifyBusinessInformation}
          />
        );
      }
      default:
        return <></>;
    }
  };

  const renderContents = (): JSX.Element => {
    if (isMobile) {
      return (
        <Column>
          <Header
            title="호스트 정보 등록"
            onClose={() => handleOnClosePage()}
          />
          {renderRegistPage()}
          <BottomContainer
            style={{ padding: window.webkit && '16px 20px 48px 20px' }}
          >
            {pageNumber === 1 ? (
              location.state && location.state.isModify ? (
                <Row>
                  <span onClick={handlePageBackwardNavigate}>뒤로</span>
                  <NextButton
                    $isActiveButton={handleValidationCheck()}
                    onClick={() => {
                      if (handleValidationCheck()) {
                        handlePageForwardNavigate();
                      }
                    }}
                  >
                    다음
                  </NextButton>
                </Row>
              ) : (
                <SettlementButton
                  isActvieButton={businessInformation.taxPayerType !== ''}
                  text="계속"
                  onClick={() => {
                    if (businessInformation.taxPayerType !== '') {
                      handlePageForwardNavigate();
                    }
                  }}
                />
              )
            ) : (
              <Row>
                <span onClick={handlePageBackwardNavigate}>뒤로</span>
                <NextButton
                  $isActiveButton={handleValidationCheck()}
                  onClick={() => {
                    if (handleValidationCheck()) {
                      handlePageForwardNavigate();
                    }
                  }}
                >
                  {pageNumber === 4
                    ? '등록'
                    : location.state &&
                      location.state.isModify &&
                      location.state.pageNumber !== 1
                    ? '수정'
                    : '다음'}
                </NextButton>
              </Row>
            )}
          </BottomContainer>
        </Column>
      );
    }

    return (
      <Column>
        <CommonRow
          justifyContent="center"
          style={{
            position: 'sticky',
            top: 0,
            padding: '24px',
            width: '100%',
            background: '#fff',
            borderBottom: '1px solid #dbdcdf',
          }}
        >
          <CommonRow
            justifyContent="space-between"
            style={{ padding: '0 40px', width: '100%', maxWidth: '1280px' }}
          >
            <CommonImage
              src={ICON_FRIP_LOGO}
              onClick={() => {
                // navigate('/');
              }}
            />
            <SelectButton
              text="나가기"
              onClick={() => {
                handleOnClosePage();
              }}
            />
          </CommonRow>
        </CommonRow>
        <Column style={{ padding: '0 40px', maxWidth: '768px' }}>
          {renderRegistPage()}
        </Column>
        <BottomContainer style={{ justifyContent: 'center', maxWidth: '100%' }}>
          <CommonRow
            justifyContent="space-between"
            style={{ padding: '0 40px', width: '100%', maxWidth: '1280px' }}
          >
            {pageNumber === 1 ? (
              location.state && location.state.isModify ? (
                <Row>
                  <div />
                  <NextButton
                    $isActiveButton={handleValidationCheck()}
                    onClick={() => {
                      if (handleValidationCheck()) {
                        handlePageForwardNavigate();
                      }
                    }}
                  >
                    다음
                  </NextButton>
                </Row>
              ) : (
                <>
                  <div />
                  <SettlementButton
                    isActvieButton={businessInformation.taxPayerType !== ''}
                    text="계속"
                    style={{ width: '76px' }}
                    onClick={() => {
                      if (businessInformation.taxPayerType !== '') {
                        handlePageForwardNavigate();
                      }
                    }}
                  />
                </>
              )
            ) : (
              <Row>
                <span onClick={handlePageBackwardNavigate}>뒤로</span>
                <NextButton
                  $isActiveButton={handleValidationCheck()}
                  onClick={() => {
                    if (handleValidationCheck()) {
                      handlePageForwardNavigate();
                    }
                  }}
                >
                  {pageNumber === 4
                    ? '등록'
                    : location.state &&
                      location.state.isModify &&
                      location.state.pageNumber !== 1
                    ? '수정'
                    : '다음'}
                </NextButton>
              </Row>
            )}
          </CommonRow>
        </BottomContainer>
      </Column>
    );
  };

  return (
    <Container ref={ref}>
      {renderContents()}
      {isClosePage && (
        <CloseViewModal
          onClose={() => {
            handleOnClosePage();
          }}
        />
      )}
    </Container>
  );
};

export default SettlementRegistPage;
