import React, { ReactElement } from 'react';
import styled from 'styled-components';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import Text from 'components/common/Text';

const ContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  padding: 24px 20px;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: -0.6px;
  color: #000;
`;

const Description = styled.div`
  display: flex;
  justify-content: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.6px;
  color: #777;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 7px;
  align-self: stretch;
  padding: 16px 20px;
  border-top: 1px solid #eaebec;
`;

const AcceptButton = styled.div`
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  background-color: #000;
  border-radius: 9999px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: -0.6px;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
`;

interface ConfirmDialogIProps {
  title: string;
  description?: string;
  onCloseText?: string;
  onAcceptText: string | ReactElement;
  onClose?: () => void;
  onAccept?: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogIProps> = props => {
  const { title, description, onAcceptText, onCloseText, onClose, onAccept } =
    props;

  return (
    <ModalContainer
      isAbled={true}
      isMobile={false}
      isBottomSheet={true}
      onClick={() => {}}
      onClose={() => {}}
      style={{ gap: '0', maxWidth: '375px' }}
    >
      <ContentsContainer>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
      </ContentsContainer>
      <ButtonContainer>
        {onClose && (
          <Text
            color="#1b1c1e"
            size="16px"
            onClick={onClose}
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            {onCloseText ? onCloseText : '닫기'}
          </Text>
        )}
        {onAccept && (
          <AcceptButton onClick={onAccept}>{onAcceptText}</AcceptButton>
        )}
      </ButtonContainer>
    </ModalContainer>
  );
};

export default ConfirmDialog;
