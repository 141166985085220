import { useResizeDetector } from 'react-resize-detector';
import styled from 'styled-components';

import { ICON_PRODUCT_FILTER_DISABLE } from 'assets/icons';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import Column from 'components/common/Column';
import {
  callWebviewHandler,
  isWebview,
  WebviewOperatorType,
} from 'utils/webview';
import { getEnvConstant } from 'constants/env';

const Container = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => (props.$isMobile ? '0 20px' : '0 40px')};
  width: 100%;
  max-width: 1280px;
`;

const FilterButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 10px 16px;
  height: 40px;

  border-radius: 999px;
  border: 1px solid #dbdcdf;
  background: #fff;
`;

const MessageNavigateButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;

  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background-color: #fff;

  color: #1b1c1e;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
`;

const MessageListPage = () => {
  const { width, ref } = useResizeDetector();

  const isMobile = width ? width < 768 : window.innerWidth < 768;

  const handleNavigateMessageList = () => {
    if (isWebview()) {
      callWebviewHandler(WebviewOperatorType.CloseWebView, {
        shouldClearViewStack: true,
        actionUrl: 'frip://chattingList',
      });

      return;
    }

    window.open(`${getEnvConstant('webClientUrl')}/messageList`);
  };

  return (
    <Container ref={ref} $isMobile={isMobile}>
      <Row
        justifyContent="space-between"
        style={{ padding: '24px 0', width: '100%' }}
      >
        <Text color="#1b1c1e" size="24px">
          메시지
        </Text>
        <FilterButton>
          <img src={ICON_PRODUCT_FILTER_DISABLE} alt="filter icon" />
          <Text>필터</Text>
        </FilterButton>
      </Row>
      <Column gap="24px" style={{ minHeight: '73vh' }}>
        <Column gap="8px">
          <Text color="#1b1c1e" size="16px">
            메시지 기능은 준비중입니다.
          </Text>
          <Text color="#70737C" size="14px" weight={400}>
            프립 앱 또는 웹사이트의 메시지에서 이용하실 수 있습니다.
          </Text>
        </Column>
        <MessageNavigateButton
          onClick={() => {
            handleNavigateMessageList();
          }}
        >
          메시지 확인하기
        </MessageNavigateButton>
      </Column>
    </Container>
  );
};

export default MessageListPage;
