import React, { useState } from 'react';
import { css, styled } from 'styled-components';
import { flatten, isEmpty } from 'lodash';

import { ICON_NOTICE_BLACK } from 'assets/icons';
import BasicButton from 'components/Button/BasicButton';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import { BookingItem, BookingState, OrderItem } from 'types/api';
import { useMutationTicketsSale } from '../graphQL/hooks/useMutationTicketsSale';
import HackleManager from 'utils/hackle';
import ConfirmCalendarModal from './ConfirmCalendarModal';
import { useMutationTicketSale } from '../graphQL/hooks/useMutationTicketSale';
import { useQueryCheckExistenceOfInvoice } from '../graphQL/hooks/useQueryCheckExistenceOfInvoice';
import CancelMultipleOrderContainer from './CancelMultipleOrderContainer';
import { CancelItems } from '../hooks/useSaleDetailHooks';

const Container = styled.div<{ $isMobile: boolean; $width: number }>`
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  padding: 16px 20px 24px;
  width: ${props => props.$width}px;
  border-top: 1px solid ${props => (props.$isMobile ? '#dbdcdf' : '#eaebec')};
  box-shadow: ${props =>
    props.$isMobile ? ' 0px -4px 20px 0px rgba(0, 0, 0, 0.1)' : '0'};

  background: #fff;

  z-index: 2;
`;

interface OrderBottomSheetIProps {
  isMobile: boolean;
  width: number;
  selectedTicket: BookingItem[];
  cancelItems: CancelItems;
  orderItem: OrderItem;
  handleRefetchOrderItem: () => void;
  onClose: () => void;
}

const OrderBottomSheet: React.FC<OrderBottomSheetIProps> = props => {
  const {
    isMobile,
    width,
    selectedTicket,
    cancelItems,
    orderItem,
    handleRefetchOrderItem,
    onClose,
  } = props;

  const {
    handleConfirmTickets,
    handleRestoreTickets,
    // handleRequestCancelOrders,
    // handleRetractCancelOrderRequests,
  } = useMutationTicketsSale();

  // const { handleCheckTicketsExistenceOfInvoice } =
  //   useQueryCheckExistenceOfInvoice();

  const [isOpenCalendarModal, setIsOpenCalendarModal] =
    useState<boolean>(false);
  // const [isOpenCancelModal, setIsOpenCancelModal] = useState<boolean>(false);

  let selectedTicketItems: { isAllSame: boolean; status: BookingState };
  const firstStatus = !isEmpty(selectedTicket) && selectedTicket[0].status;
  const allSame = selectedTicket.every(item => {
    if (
      item.status !== firstStatus ||
      (cancelItems.request.orderId.includes(item.booking.id) &&
        cancelItems.request.indexNo.includes(item.indexNo))
    ) {
      return false;
    }

    return true;
  });

  const ticketIds = flatten(
    selectedTicket.map(item => item.tickets.map(ticketItem => ticketItem.id)),
  );

  if (allSame) {
    selectedTicketItems = { isAllSame: true, status: firstStatus };
  } else {
    selectedTicketItems = { isAllSame: false, status: null };
  }

  const renderStatusButton = (): JSX.Element => {
    switch (selectedTicketItems.status) {
      case BookingState.APPLIED: {
        return (
          <React.Fragment>
            {/* <BasicButton
              content="주문 취소 요청"
              onClick={() => {
                selectedTicket.every(item => {
                  if (
                    handleCheckTicketsExistenceOfInvoice({
                      orderItemId: item.booking.id.toString(),
                      indexNo: item.indexNo,
                    })
                  ) {
                    setIsOpenCancelModal(true);
                  }
                });
              }}
              style={{ borderRadius: '8px' }}
            /> */}
            {selectedTicket.every(
              item => !item.booking.schedule && item.ticketCount === 1,
            ) && (
              <BasicButton
                content="예약확정"
                onClick={() => {
                  if (
                    selectedTicket.every(item => item.booking.schedule !== null)
                  ) {
                    handleConfirmTickets(ticketIds, null, () => {
                      HackleManager.logSalesBookingComplete(
                        Number(selectedTicket[0].booking.order.id),
                        selectedTicket[0].booking.order.customer.id,
                        ticketIds,
                        selectedTicket[0].tickets[0].scheduleTerm.startedAt,
                        '판매상세,',
                      );

                      handleRefetchOrderItem();
                    });

                    return;
                  }

                  setIsOpenCalendarModal(true);
                }}
                style={{ borderRadius: '8px', color: '#7126FF' }}
              />
            )}
            {selectedTicket.every(
              item =>
                item.booking.schedule &&
                new Date(item.booking.schedule.saleTerm.endedAt).getTime() <
                  new Date().getTime(),
            ) && (
              <BasicButton
                content="예약확정 다시하기"
                onClick={() => {
                  handleConfirmTickets(ticketIds, null, () => {
                    HackleManager.logSalesBookingComplete(
                      Number(selectedTicket[0].booking.order.id),
                      selectedTicket[0].booking.order.customer.id,
                      ticketIds,
                      selectedTicket[0].tickets[0].scheduleTerm.startedAt,
                      '판매상세,',
                    );

                    handleRefetchOrderItem();
                  });
                }}
                style={{ borderRadius: '8px', color: '#7126FF' }}
              />
            )}
          </React.Fragment>
        );
      }
      case BookingState.CONFIRMED: {
        return (
          <React.Fragment>
            {selectedTicket.every(item => item.ticketCount === 1) && (
              <BasicButton
                content="예약확정 취소"
                onClick={() => {
                  handleRestoreTickets(ticketIds, () => {
                    handleRefetchOrderItem();

                    HackleManager.logSalesBookingReverseComplete(
                      Number(selectedTicket[0].booking.order.id),
                      selectedTicket[0].booking.order.customer.id,
                      ticketIds,
                      '판매상세',
                      '판매상세',
                    );

                    onClose();
                  });
                }}
                style={{ borderRadius: '8px' }}
              />
            )}
            {/* <BasicButton
              content="예약일 수정"
              onClick={() => {}}
              style={{ borderRadius: '8px' }}
            /> */}
          </React.Fragment>
        );
      }

      default:
        return <></>;
    }
  };

  if (isEmpty(selectedTicket)) {
    return;
  }

  return (
    <React.Fragment>
      <Container $isMobile={isMobile} $width={width}>
        {selectedTicketItems.isAllSame ? (
          <Row gap="8px" justifyContent="flex-end" style={{ width: '100%' }}>
            {renderStatusButton()}
          </Row>
        ) : (
          <Row gap="4px" style={{ width: '100%' }}>
            <img src={ICON_NOTICE_BLACK} alt="notice black" />
            <Text color="#1b1c1e">
              예약 상태가 다른 주문은 동시에 처리할 수 없어요
            </Text>
          </Row>
        )}
      </Container>
      {isOpenCalendarModal && (
        <ConfirmCalendarModal
          isMobile={isMobile}
          ticketIds={ticketIds}
          handleConfirmTickets={handleConfirmTickets}
          handleRefetchOrderItem={handleRefetchOrderItem}
          onClose={() => {
            setIsOpenCalendarModal(false);
          }}
        />
      )}
      {/* {isOpenCancelModal && (
        <CancelMultipleOrderContainer
          isMobile={isMobile}
          bookingItem={selectedTicket}
          handleRequestCancelOrder={handleRequestCancelOrders}
          handleRefetchOrderItem={handleRefetchOrderItem}
          onClose={() => {
            setIsOpenCancelModal(false);
          }}
        />
      )} */}
    </React.Fragment>
  );
};

export default OrderBottomSheet;
