import { createInstance } from '@hackler/react-sdk';
import { getEnvConstant } from 'constants/env';
import dayjs from 'dayjs';
import { callWebviewHandler, WebviewOperatorType } from 'utils/webview';

class HackleManager {
  static initialize() {
    const config = {
      debug: true,
    };

    return createInstance(getEnvConstant('hackleKey'), config);
  }

  static setUserId(userId: string, hostName: string, isSuperHost: boolean) {
    const hackleClient = HackleManager.initialize();

    hackleClient.setUserId(userId);
    hackleClient.setUserProperty('호스트명', hostName);
    hackleClient.setUserProperty('슈퍼호스트여부', isSuperHost);
  }

  static userLogout() {
    const hackleClient = HackleManager.initialize();

    hackleClient.resetUser();
  }

  static logEvent(eventName: string, params?: any) {
    const hackleClient = HackleManager.initialize();

    const event = { key: eventName, properties: params };

    hackleClient.track(event);
  }

  static logSignoutComplete() {
    this.logEvent('signout_complete');
  }

  static logMenuClick(menuItem: string) {
    this.logEvent('menu_click', { 메뉴구분: menuItem });
  }

  static logProfileMenuClick(profileMenuItem: string) {
    this.logEvent('profile_menu_click', { 메뉴구분: profileMenuItem });
  }

  static logMenuItemClick(menuItem: string) {
    this.logEvent('menu_item_click', { 메뉴구분: menuItem });
  }

  static logMenuBannerClick(id: string, title: string, url: string) {
    this.logEvent('menu_banner_click', {
      배너ID: id,
      배너타이틀: title,
      랜딩URL: url,
    });
  }

  static logSigninView() {
    this.logEvent('signin_view');
  }

  static logSigninClick(type: string) {
    this.logEvent('signin_click', { 가입구분: type });
  }

  static logForgotPasswordView() {
    this.logEvent('signin_forgotpassword_view');
  }

  static logSignupView() {
    this.logEvent('signup_view');
  }

  static logSignupVerificationComplete(type: string) {
    this.logEvent('signup_verification_complete', { 가입구분: type });
  }

  static logSignupComplete(type: string, marketing: boolean) {
    this.logEvent('signup_complete', {
      가입구분: type,
      마케팅수신동의: marketing,
    });
  }

  static logLandingView() {
    this.logEvent('landing_view');
  }

  static logLandingSignupView() {
    this.logEvent('landing_signup_view');
  }

  static logLandingSignupVerificationComplete() {
    this.logEvent('landing_signup_verification_complete');
  }

  static logLandingSignupComplete(marketing: boolean) {
    this.logEvent('landing_signup_complete', { 마케팅수신동의: marketing });
  }

  static logNoticeview() {
    this.logEvent('notice_view');
  }

  static logNoticeDetailView(id: string) {
    this.logEvent('notice_detail_view', { 공지사항ID: id });
  }

  static logProfileView() {
    this.logEvent('profile_view');
  }

  static logProfileUpdateComplete() {
    this.logEvent('profile_update_complete');
  }

  static logIndividualView() {
    this.logEvent('individual_view');
  }

  static logIndividualUpdateComplete() {
    this.logEvent('individual_update_complete');
  }

  static logSettlementView() {
    this.logEvent('settlement_view');
  }

  static logSettlementRequestApprovalComplete() {
    this.logEvent('settlement_request_approval_complete');
  }

  static logSettlementRegistInfoView() {
    this.logEvent('settlement_regist_info_view');
  }

  static logSettlementRegistInfoUpdateComplete(type: string) {
    this.logEvent('settlement_regist_info_update_complete', {
      호스트구분: type,
    });
  }

  static logSettlementReigstBankView() {
    this.logEvent('settlement_regist_bank_view');
  }

  static logSettlementReigstBankUpdateComplete() {
    this.logEvent('settlement_regist_bank_update_complete');
  }

  static logSettlementListView() {
    this.logEvent('settlement_list_view');
  }

  static logSettlementListPayoutRequestClick(
    id: string,
    week: string,
    status: string,
    amount: number,
    commission: number,
    payment: number,
  ) {
    this.logEvent('settlement_list_payout_request_click', {
      정산서ID: id,
      주차: week,
      상태: status,
      총수입: amount,
      플랫폼수수료: commission,
      총지급예정금액: payment,
    });
  }

  static logSettlementListPayoutRequestComplete(
    id: string,
    week: string,
    amount: number,
    commission: number,
    payment: number,
  ) {
    this.logEvent('settlement_list_payout_request_complete', {
      정산서ID: id,
      주차: week,
      총수입: amount,
      플랫폼수수료: commission,
      총지급예정금액: payment,
    });
  }

  static logSettlementDetailView(id: string) {
    this.logEvent('settlement_detail_view', {
      정산서ID: id,
    });
  }

  static logSalesView(saleCount: number, page: number, type: string) {
    this.logEvent('sales_view', {
      판매건수: saleCount,
      페이지: page,
      구분: type,
    });
  }

  static logSalesSearchComplete(
    filter: string,
    searchValue: string,
    term: string,
  ) {
    this.logEvent('sales_search_complete', {
      검색필터: filter,
      검색어: searchValue,
      조회기간: term,
    });
  }

  static logSalesDetailView(
    id: number,
    userId: string,
    saleCount: number,
    amount: number,
    ticketId: string[],
    bookingType: string,
  ) {
    this.logEvent('sales_detail_view', {
      주문ID: id,
      유저ID: userId,
      판매건수: saleCount,
      판매금액: amount,
      티켓ID: ticketId,
      예약방식: bookingType,
    });
  }

  static logSalesMessageClick(
    id: number,
    userId: string,
    bookingType: string,
    view: string,
  ) {
    this.logEvent('sales_message_click', {
      주문ID: id,
      유저ID: userId,
      예약방식: bookingType,
      화면: view,
    });
  }

  static logSalesCancelRequestComplete(
    id: number,
    userId: string,
    cancelRequestTicket: string[],
    cancelTicketCount: number,
    cancelReason: string,
    detailReason: string,
    bookingType: string,
    view: string,
  ) {
    this.logEvent('sales_cancel_request_complete', {
      주문ID: id,
      유저ID: userId,
      취소요청티켓ID: cancelRequestTicket,
      취소요청티켓수: cancelTicketCount,
      취소요청사유: cancelReason,
      상세사유: detailReason,
      예약방식: bookingType,
      화면: view,
    });
  }

  static logSalesCancelRequestReverseComplete(
    id: number,
    userId: string,
    ticketId: string[],
    view: string,
  ) {
    this.logEvent('sales_cancel_request_reverse_complete', {
      주문ID: id,
      유저ID: userId,
      티켓ID: ticketId,
      화면: view,
    });
  }

  static logSalesBookingComplete(
    id: number,
    userId: string,
    ticketId: string[],
    scheduleDate: number,
    view: string,
  ) {
    this.logEvent('sales_booking_complete', {
      주문ID: id,
      유저ID: userId,
      티켓ID: ticketId,
      예약일시: dayjs(scheduleDate).format('YYYY-MM-DD HH:mm:ss'),
      화면: view,
    });
  }

  static logSalesBookingReverseComplete(
    id: number,
    userId: string,
    ticketId: string[],
    view: string,
    path: string,
  ) {
    this.logEvent('sales_booking_reverse_complete', {
      주문ID: id,
      유저ID: userId,
      티켓ID: ticketId,
      화면: view,
      경로: path,
    });
  }

  static logBookingView(bookingCount: number, type: string) {
    this.logEvent('booking_view', { 예약건수: bookingCount, 구분: type });
  }

  static logBookingFilterComplete(filter: string, searchValue: string) {
    this.logEvent('booking_filter_complete', {
      검색필터: filter,
      검색어: searchValue,
    });
  }

  static logBookingSearchComplete(searchValue: string) {
    this.logEvent('booking_search_complete', { 검색어: searchValue });
  }

  static logBookingCalendarView() {
    this.logEvent('booking_calander_view');
  }

  static logBookingAbsenceComplete(
    id: number,
    userId: string,
    ticketName: string,
    ticketOptionName: string,
    ticketId: string[],
    view: string,
  ) {
    this.logEvent('booking_absence_complete', {
      주문ID: id,
      유저ID: userId,
      티켓상품명: ticketName,
      티켓옵션명: ticketOptionName,
      티켓ID: ticketId,
      화면: view,
    });
  }

  static logBookingAbsenceReverseComplete(
    id: number,
    userId: string,
    ticketName: string,
    ticketOptionName: string,
    ticketId: string[],
    view: string,
  ) {
    this.logEvent('booking_absence_reverse_complete', {
      주문ID: id,
      유저ID: userId,
      티켓상품명: ticketName,
      티켓옵션명: ticketOptionName,
      티켓ID: ticketId,
      화면: view,
    });
  }

  static logBookingAttendanceComplete(
    id: number,
    userId: string,
    ticketName: string,
    ticketOptionName: string,
    ticketId: string[],
    view: string,
  ) {
    this.logEvent('booking_attendance_complete', {
      주문ID: id,
      유저ID: userId,
      티켓상품명: ticketName,
      티켓옵션명: ticketOptionName,
      티켓ID: ticketId,
      화면: view,
    });
  }

  static logBookingAttendanceReverseComplete(
    id: number,
    userId: string,
    ticketName: string,
    ticketOptionName: string,
    ticketId: string[],
    view: string,
  ) {
    this.logEvent('booking_attendance_reverse_complete', {
      주문ID: id,
      유저ID: userId,
      티켓상품명: ticketName,
      티켓옵션명: ticketOptionName,
      티켓ID: ticketId,
      화면: view,
    });
  }

  static logBookingDetailView(
    id: number,
    userId: string,
    ticketName: string,
    ticketOptionName: string,
    ticketId: string[],
    view: string,
  ) {
    this.logEvent('booking_detail_view', {
      주문ID: id,
      유저ID: userId,
      티켓상품명: ticketName,
      티켓옵션명: ticketOptionName,
      티켓ID: ticketId,
      화면: view,
    });
  }

  static logBookingMessageClick(
    id: number,
    userId: string,
    ticketName: string,
    ticketOptionName: string,
    ticketId: string[],
    bookingType: string,
    view: string,
  ) {
    this.logEvent('booking_message_click', {
      주문ID: id,
      유저ID: userId,
      티켓상품명: ticketName,
      티켓옵션명: ticketOptionName,
      티켓ID: ticketId,
      예약방식: bookingType,
      화면: view,
    });
  }

  static logProductView(
    productCount: number,
    searchValue: string,
    createdAt: string,
    status: string,
  ) {
    this.logEvent('product_view', {
      상품수: productCount,
      검색어: searchValue,
      상품등록일: createdAt,
      상태: status,
    });
  }

  static logProductFilterComplete(
    productCount: number,
    searchValue: string,
    createdAt: string,
    status: string,
  ) {
    this.logEvent('product_filter_complete', {
      상품수: productCount,
      검색어: searchValue,
      상품등록일: createdAt,
      상태: status,
    });
  }

  static logProductCreateClick() {
    this.logEvent('product_create_click');
  }

  static logProductCreateTypeClick(registType: string) {
    this.logEvent('product_create_type_click', { 등록방법: registType });
  }

  static logProductClick(
    productId: number,
    productName: string,
    createdAt: number,
    endedAt: number,
    status: string,
  ) {
    this.logEvent('product_click', {
      상품ID: productId,
      상품명: productName,
      상품등록일: dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss'),
      판매종료일: dayjs(endedAt).format('YYYY-MM-DD HH:mm:ss'),
      상태: status,
    });
  }

  static logProductMenuClick(
    productId: number,
    productName: string,
    createdAt: number,
    endedAt: number,
    status: string,
  ) {
    this.logEvent('product_menu_click', {
      상품ID: productId,
      상품명: productName,
      상품등록일: dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss'),
      판매종료일: dayjs(endedAt).format('YYYY-MM-DD HH:mm:ss'),
      상태: status,
    });
  }

  static logProductMenuItemClick(
    productId: number,
    productName: string,
    createdAt: number,
    endedAt: number,
    status: string,
    menuName: string,
  ) {
    this.logEvent('product_menu_item_click', {
      상품ID: productId,
      상품명: productName,
      상품등록일: dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss'),
      판매종료일: dayjs(endedAt).format('YYYY-MM-DD HH:mm:ss'),
      상태: status,
      메뉴명: menuName,
    });
  }

  static logProductDeleteComplete(productId: number, productName: string) {
    this.logEvent('product_delete_complete', {
      상품ID: productId,
      상품명: productName,
    });
  }

  static logCreateStepOneView(productId?: number) {
    productId
      ? this.logEvent('create_step1_view', {
          상품ID: productId,
        })
      : this.logEvent('create_step1_view');
  }

  static logCreateStepOneHostTypeView(productId?: number) {
    productId
      ? this.logEvent('create_step1_host_type_view', { 상품ID: productId })
      : this.logEvent('create_step1_host_type_view');
  }

  static logCreateStepOneHostMethodView(productId?: number) {
    productId
      ? this.logEvent('create_step1_host_method_view', { 상품ID: productId })
      : this.logEvent('create_step1_host_method_view');
  }

  static logCreateStepOneShippingTypeView(productId?: number) {
    productId
      ? this.logEvent('create_step1_shipping_type_view', { 상품ID: productId })
      : this.logEvent('create_step1_shipping_type_view');
  }

  static logCreateStepOneOnlineMethodView(productId?: number) {
    productId
      ? this.logEvent('create_step1_online_method_view', { 상품ID: productId })
      : this.logEvent('create_step1_online_method_view');
  }

  static logCreateStepOneOfflineMethodView(productId?: number) {
    productId
      ? this.logEvent('create_step1_offline_location_view', {
          상품ID: productId,
        })
      : this.logEvent('create_step1_offline_location_view');
  }

  static logCreateStepOneAddLocationView(productId?: number) {
    productId
      ? this.logEvent('create_step1_add_location_view', { 상품ID: productId })
      : this.logEvent('create_step1_add_location_view');
  }

  static logCreateStepOneCategoryView(productId?: number) {
    productId
      ? this.logEvent('create_step1_category_view', { 상품ID: productId })
      : this.logEvent('create_step1_category_view');
  }

  static logCreateStepTwoView(productId?: number) {
    productId
      ? this.logEvent('create_step2_view', { 상품ID: productId })
      : this.logEvent('create_step2_view');
  }

  static logCreateStepTwoReservationMethodView(productId?: number) {
    productId
      ? this.logEvent('create_step2_reservation_method_view', {
          상품ID: productId,
        })
      : this.logEvent('create_step2_reservation_method_view');
  }

  static logCreateStepTwoSalesOptionView(productId?: number) {
    productId
      ? this.logEvent('create_step2_sales_option_view', { 상품ID: productId })
      : this.logEvent('create_step2_sales_option_view');
  }

  static logCreateStepTwoScheduleSettingView(productId?: number) {
    productId
      ? this.logEvent('create_step2_schedule_setting_view', {
          상품ID: productId,
        })
      : this.logEvent('create_step2_schedule_setting_view');
  }

  static logCreateStepTwoSalesPeriodLimitView(productId?: number) {
    productId
      ? this.logEvent('create_step2_sales_period_limit_view', {
          상품ID: productId,
        })
      : this.logEvent('create_step2_sales_period_limit_view');
  }

  static logCreateStepThreeView(productId?: number) {
    productId
      ? this.logEvent('create_step3_view', { 상품ID: productId })
      : this.logEvent('create_step3_view');
  }

  static logCreateStepThreeProductNameImageView(productId?: number) {
    productId
      ? this.logEvent('create_step3_product_name_image_view', {
          상품ID: productId,
        })
      : this.logEvent('create_step3_product_name_image_view');
  }

  static logCreateStepThreeProductDescriptionView(productId?: number) {
    productId
      ? this.logEvent('create_step3_product_description_view', {
          상품ID: productId,
        })
      : this.logEvent('create_step3_product_description_view');
  }

  static logCreateStepThreeParticipantGuideView(productId?: number) {
    productId
      ? this.logEvent('create_step3_participant_guide_view', {
          상품ID: productId,
        })
      : this.logEvent('create_step3_participant_guide_view');
  }

  static logCreateStepThreeAdditionalInfoView(productId?: number) {
    productId
      ? this.logEvent('create_step3_additional_info_view', {
          상품ID: productId,
        })
      : this.logEvent('create_step3_view');
  }

  static logCreateStepThreeParticipantInfoView(productId?: number) {
    productId
      ? this.logEvent('create_step3_participant_info_view', {
          상품ID: productId,
        })
      : this.logEvent('create_step3_participant_info_view');
  }

  static logCreateStepThreeCurriculumView(productId?: number) {
    productId
      ? this.logEvent('create_step3_curriculum_view', { 상품ID: productId })
      : this.logEvent('create_step3_curriculum_view');
  }

  static logCreateStepFourPreviewView(productId?: number) {
    productId
      ? this.logEvent('create_step4_preview_view', { 상품ID: productId })
      : this.logEvent('create_step4_preview_view');
  }

  static logCreateStepFourReviewRequestView(productId?: number) {
    productId
      ? this.logEvent('create_step4_review_request_view', { 상품ID: productId })
      : this.logEvent('create_step4_review_request_view');
  }

  static logCreateStepFourReviewRequestCompleteView(productId?: number) {
    productId
      ? this.logEvent('create_step4_review_request_complete', {
          상품ID: productId,
        })
      : this.logEvent('create_step4_review_request_complete');
  }

  static logCreatePreviewClick(productId?: number) {
    productId
      ? this.logEvent('create_preview_click', { 상품ID: productId })
      : this.logEvent('create_preview_click');
  }

  static logCreateGuideClick(page: string) {
    this.logEvent('create_guide_click', { 페이지: page });
  }

  static logCreateCloseClick(buttonText: string) {
    this.logEvent('create_close_click', { 버튼명: buttonText });
  }

  static logProductModifyView(
    productId: number,
    productName: string,
    createdAt: number,
    endedAt: number,
    status: string,
  ) {
    this.logEvent('product_modify_view', {
      상품ID: productId,
      상품명: productName,
      상품등록일: dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss'),
      판매종료일: dayjs(endedAt).format('YYYY-MM-DD HH:mm:ss'),
      상태: status,
    });
  }

  static logProductModifyImageView(productId: number, productName: string) {
    this.logEvent('product_modify_image_view', {
      상품ID: productId,
      상품명: productName,
    });
  }

  static logProductModifyProductNameView(
    productId: number,
    productName: string,
  ) {
    this.logEvent('product_modify_product_name_view', {
      상품ID: productId,
      상품명: productName,
    });
  }

  static logProductModifyParticipantGuideView(
    productId: number,
    productName: string,
  ) {
    this.logEvent('product_modify_participant_guide_view', {
      상품ID: productId,
      상품명: productName,
    });
  }

  static logProductModifyProductDescriptionView(
    productId: number,
    productName: string,
  ) {
    this.logEvent('product_modify_product_description_view', {
      상품ID: productId,
      상품명: productName,
    });
  }

  static logProductModifyCurriculumView(
    productId: number,
    productName: string,
  ) {
    this.logEvent('product_modify_curriculum_view', {
      상품ID: productId,
      상품명: productName,
    });
  }

  static logProductModifyParticipantInfoView(
    productId: number,
    productName: string,
  ) {
    this.logEvent('product_modify_participant_info_view', {
      상품ID: productId,
      상품명: productName,
    });
  }

  static logProductModifyAddLocationView(
    productId: number,
    productName: string,
  ) {
    this.logEvent('product_modify_add_location_view', {
      상품ID: productId,
      상품명: productName,
    });
  }

  static logProductModifyAdditionalInfoView(
    productId: number,
    productName: string,
  ) {
    this.logEvent('product_modify_additional_info_view', {
      상품ID: productId,
      상품명: productName,
    });
  }

  static logProductModifyHostProfileView(
    productId: number,
    productName: string,
  ) {
    this.logEvent('product_modify_host_profile_view', {
      상품ID: productId,
      상품명: productName,
    });
  }

  static logProductModifyNoticeView(productId: number, productName: string) {
    this.logEvent('product_modify_notice_view', {
      상품ID: productId,
      상품명: productName,
    });
  }

  static logProductModifySalesOptionView(
    productId: number,
    productName: string,
  ) {
    this.logEvent('product_modify_sales_option_view', {
      상품ID: productId,
      상품명: productName,
    });
  }

  static logProductModifySalesPeriodView(
    productId: number,
    productName: string,
  ) {
    this.logEvent('product_modify_sales_period_view', {
      상품ID: productId,
      상품명: productName,
    });
  }

  static logProductModifySalesLimitView(
    productId: number,
    productName: string,
  ) {
    this.logEvent('product_modify_sales_limit_view', {
      상품ID: productId,
      상품명: productName,
    });
  }

  static logProductModifyScheduleSettingView(
    productId: number,
    productName: string,
  ) {
    this.logEvent('product_modify_schedule_setting_view', {
      상품ID: productId,
      상품명: productName,
    });
  }

  static logProductModifyItemSaveComplete(
    productId: number,
    productName: string,
    page: string,
  ) {
    this.logEvent('product_modify_item_save_complete', {
      상품ID: productId,
      상품명: productName,
      페이지: page,
    });
  }

  static logProductModifySaveComplete(productId: number, productName: string) {
    this.logEvent('product_modify_save_complete', {
      상품ID: productId,
      상품명: productName,
    });
  }

  static logProductModifyStatusSaveComplete(
    productId: number,
    productName: string,
    status: string,
    buttonName: string,
  ) {
    this.logEvent('product_modify_status_save_complete', {
      상품ID: productId,
      상품명: productName,
      상태: status,
      버튼명: buttonName,
    });
  }

  static logQnaView(qnaCount: number, type: string) {
    this.logEvent('qna_view', {
      전체문의수: qnaCount,
      구분: type,
    });
  }

  static logQnaResponseComplete(qnaId: number, buttonName: string) {
    this.logEvent('qna_response_complete', {
      문의ID: qnaId,
      버튼명: buttonName,
    });
  }

  static logReviewView(reviewCount: number) {
    this.logEvent('review_view', {
      전체후기수: reviewCount,
      // 구분: type,
    });
  }

  static logReviewResponseComplete(reviewId: number, buttonName: string) {
    this.logEvent('review_response_complete', {
      후기ID: reviewId,
      버튼명: buttonName,
    });
  }

  static logReviewReportComplete(reviewId: number, reportReason: string) {
    this.logEvent('review_report_complete', {
      후기ID: reviewId,
      신고사유: reportReason,
    });
  }
}

export default HackleManager;
