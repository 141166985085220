import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useResizeDetector } from 'react-resize-detector';
import { InView } from 'react-intersection-observer';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import Row from 'components/common/Row';
import Text from 'components/common/Text';
import {
  ICON_NOTICE_RED,
  ICON_PLUS_BLACK,
  ICON_PRODUCTLIST_EMPTY,
  ICON_PRODUCT_FILTER,
} from 'assets/icons';
import ProductItem from './components/ProductItem';
import { useQueryGetProductList } from './graphQL/hooks/useQueryGetProductList';
import { PRODUCT_REGIST_PATH } from 'constants/path';
import ProductFilterModal from './components/ProductFilterModal';
import Column from 'components/common/Column';
import ProductRegistModal from './components/ProductRegistModal';
import { ProductState } from 'types/api';
import ProductItemModal from './components/ProductItemModal';
import { useProductFilterHooks } from './hooks/useProductFilterHooks';
import Loading from 'components/Loading/Loading';
import {
  formatProductStatus,
  formatSalesTermType,
} from 'utils/hackle/parameterFormatting';
import HackleManager from 'utils/hackle';

const Container = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  /* padding: ${props =>
    props.$isMobile ? '20px 20px 40px' : '20px 40px 40px'}; */

  width: 100%;
  max-width: 1280px;

  ::first-child {
    gap: 0px;
  }
`;

const SelectButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;

  border-radius: 999px;
  border: 1px solid #dbdcdf;
  background: var(--Static-White, #fff);

  color: var(--gray-gray-09, #1b1c1e);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
`;

const ListContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  gap: 40px;
  width: 100%;
`;

const RejectContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 20px;
  width: 100%;

  border-radius: 12px;
  border: 1px solid var(--gray-gray-03, #eaebec);
  background: var(--gray-gray-01, #f7f7f8);
`;

const ProductListPage = () => {
  const {
    isLoading,
    myInfo,
    productList,
    productfilterInput,
    isAcceptFilter,
    selectProduct,
    isOpenSelectProductModal,
    setProductFilterInput,
    setIsOpenSelectProductModal,
    handleSetSelectProduct,
    handleLoadMoreProductList,
    setIsAcceptFilter,
  } = useQueryGetProductList();

  const {
    searchValue,
    productStatus,
    termWithType,
    termType,
    setTermType,
    handlerSetSearchValue,
    handleSetProductStatus,
    handleSetTermWithType,
    handleResetFilterInput,
  } = useProductFilterHooks();

  const navigate = useNavigate();

  const { width, ref } = useResizeDetector();

  const [isOpenFilterModal, setIsOpenFilterModal] = useState<boolean>(false);
  const [isOpenProductRegistBottomSheet, setIsOpenProductRegistBottomSheet] =
    useState<boolean>(false);

  const isMobile = width ? width < 768 : window.innerWidth < 768;
  const isTablet = width && width > 768 && width < 1024;

  useEffect(() => {
    if (productList) {
      HackleManager.logProductView(
        productList.product.products.totalCount,
        searchValue,
        formatSalesTermType(termType),
        productStatus.type === 'all'
          ? '전체'
          : formatProductStatus(productfilterInput.statusIn),
      );

      HackleManager.logProductFilterComplete(
        productList.product.products.totalCount,
        searchValue,
        formatSalesTermType(termType),
        productStatus.type === 'all'
          ? '전체'
          : formatProductStatus(productfilterInput.statusIn),
      );
    }
  }, [productList]);

  const renderRejectNotice = (): JSX.Element => {
    const isRejectProduct =
      myInfo.me.host.productInfo &&
      myInfo.me.host.productInfo.counts.find(
        item => item.status === ProductState.REJECTED,
      );

    if (isRejectProduct) {
      return (
        <RejectContainer>
          <img src={ICON_NOTICE_RED} alt="icon notice red" />
          <Column gap="16px" align="flex-start">
            <Column gap="4px" align="flex-start">
              <Text color="#1B1C1E" size="14px" weight={600}>
                검수되지 않은 프립이 있어요
              </Text>
              <Text color="#1B1C1E" size="13px" weight={400}>
                반려 내용을 확인하여 수정 후 재검수를 요청해 주세요
              </Text>
            </Column>
            <Text
              color="#1b1c1e"
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                handleSetProductStatus('select', ProductState.REJECTED);
                setIsAcceptFilter(true);
                setProductFilterInput({
                  ...productfilterInput,
                  statusIn: [ProductState.REJECTED],
                });
              }}
            >
              확인하기
            </Text>
          </Column>
        </RejectContainer>
      );
    }

    return <></>;
  };

  const renderProductList = (): JSX.Element => {
    if (productList) {
      return (
        <React.Fragment>
          {/* myInfo.me.host.productInfo?.counts.length === 0 || */}
          {isEmpty(productList.product.products.edges) ? (
            <Column gap="8px" style={{ minHeight: '65vh' }}>
              <img src={ICON_PRODUCTLIST_EMPTY} alt="empty product list" />
              <Text color="#1b1c1e" size="16px">
                등록된 상품이 없습니다.
              </Text>
              <Text color="#70737c" size="14px" weight={400}>
                호스팅할 프립을 등록해 보세요!{' '}
                <span
                  style={{ color: '#1b1c1e', textDecoration: 'underline' }}
                  onClick={() => {
                    HackleManager.logProductCreateClick();
                    navigate(`${PRODUCT_REGIST_PATH}/draft`);
                  }}
                >
                  프립 만들기
                </span>
              </Text>
            </Column>
          ) : (
            <ListContainer>
              {productList.product.products.edges.map((item, idx) => {
                return (
                  <ProductItem
                    key={idx}
                    item={item}
                    width={width}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    handleSetSelectProduct={handleSetSelectProduct}
                  />
                );
              })}
              <InView
                onChange={inView => {
                  if (
                    inView &&
                    productList.product.products.pageInfo.hasNextPage
                  ) {
                    handleLoadMoreProductList();
                  }
                }}
              ></InView>
            </ListContainer>
          )}
        </React.Fragment>
      );
    }

    return <></>;
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container ref={ref} $isMobile={isMobile}>
      <Column
        gap={isMobile ? '0' : '40px'}
        // style={{ padding: isMobile ? '0 0 40px' : '20px 40px 40px' }}
      >
        <Row
          justifyContent="space-between"
          style={{
            position: 'sticky',
            top: 0,
            padding: isMobile ? '24px 20px' : '20px 40px',
            width: '100%',
            background: '#fff',
            zIndex: 10,
          }}
        >
          <Text color="#1b1c1e" size="24px">
            상품
          </Text>
          <Row gap="8px">
            <SelectButton
              onClick={() => {
                setIsOpenFilterModal(true);
              }}
              style={{
                height: '40px',
                border: isAcceptFilter
                  ? '1.5px solid #1b1c1e'
                  : '1px solid  #dbdcdf',
                background: isAcceptFilter ? '#F7F7F8' : '#fff',
              }}
            >
              <img src={ICON_PRODUCT_FILTER} alt="icon product filter" />{' '}
              {isAcceptFilter ? '필터 적용' : '필터'}
            </SelectButton>
            <SelectButton
              onClick={() => {
                if (
                  myInfo.me.host.productInfo?.counts.length === 0 ||
                  isEmpty(productList.product.products.edges)
                ) {
                  navigate(`${PRODUCT_REGIST_PATH}/draft`);
                  return;
                }

                setIsOpenProductRegistBottomSheet(true);
              }}
              style={{ height: '40px' }}
            >
              <img src={ICON_PLUS_BLACK} alt="icon product filter" /> 프립
              만들기
            </SelectButton>
          </Row>
        </Row>
        <Column
          gap="40px"
          style={{ padding: isMobile ? '0 20px 95px' : '0 40px 40px' }}
        >
          {renderRejectNotice()}
          {renderProductList()}
        </Column>
        {isOpenFilterModal && (
          <ProductFilterModal
            isMobile={isMobile}
            productfilterInput={productfilterInput}
            searchValue={searchValue}
            productStatus={productStatus}
            termWithType={termWithType}
            termType={termType}
            setTermType={setTermType}
            handlerSetSearchValue={handlerSetSearchValue}
            handleSetProductStatus={handleSetProductStatus}
            handleResetFilterInput={handleResetFilterInput}
            handleSetTermWithType={handleSetTermWithType}
            setProductFilterInput={setProductFilterInput}
            setIsAcceptFilter={setIsAcceptFilter}
            onClose={() => setIsOpenFilterModal(false)}
          />
        )}
        {isOpenProductRegistBottomSheet && (
          <ProductRegistModal
            myInfo={myInfo}
            isMobile={isMobile}
            onClose={() => setIsOpenProductRegistBottomSheet(false)}
          />
        )}
        {isOpenSelectProductModal && (
          <ProductItemModal
            isMobile={isMobile}
            selectProduct={selectProduct}
            onClose={() => setIsOpenSelectProductModal(false)}
          />
        )}
      </Column>
    </Container>
  );
};

export default ProductListPage;
