import { instance } from 'utils/axiosInstance';
import { errorHandler } from 'utils/ErrorHandler';

export const useCertifictationHooks = () => {
  const handleHostCertification = async (
    impUid: string,
    callback: () => void,
  ) => {
    try {
      const response = await instance.post('users/certification', {
        key: impUid,
        provider: 'IAMPORT',
      });

      if (response) {
        callback();
      }
    } catch (error) {
      let message = 'Unknown Error';
      if (error instanceof Error)
        message = (error as any).response.data.message;

      window.showToast(message, 'failed');
    }
  };

  return { handleHostCertification };
};
