import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from 'styled-components';

import {
  ICON_CLOSE,
  ICON_COPY_GRAY,
  ICON_MESSAGE_BLACK,
  ICON_NOTICE_BLACK,
  ICON_SHARE,
} from 'assets/icons';
import Column from 'components/common/Column';
import CommonImage from 'components/common/CommonImage';
import Row from 'components/common/Row';
import { Ticket } from 'types/api';
import Text from 'components/common/Text';
import { dateFormattingForm, formatPhoneNumber } from 'utils/formatting';
import Divider from 'components/Divider';
import BookingStatusButton from './BookingStatusButton';
import BasicButton from 'components/Button/BasicButton';
import {
  callWebviewHandler,
  isWebview,
  WebviewOperatorType,
} from 'utils/webview';
import { getEnvConstant } from 'constants/env';
import { SALE_DETAIL_PATH } from 'constants/path';
import InquiresTemplate from 'containers/SaleDetail/components/InquiresTemplate';
import HackleManager from 'utils/hackle';

const NotificationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 12px;
  width: 100%;
  border-radius: var(--Radius-border-radius-200, 8px);
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);
`;
interface BookingDetailIProps {
  item: Ticket;
  onClose: () => void;
  handleRefetchBookingList: () => void;
}

const BookingDetail: React.FC<BookingDetailIProps> = props => {
  const { item, onClose, handleRefetchBookingList } = props;
  const navigate = useNavigate();

  useEffect(() => {
    HackleManager.logBookingDetailView(
      Number(item.bookingItem.booking.order.id),
      item.bookingItem.booking.customer.id,
      item.bookingItem.booking.info.product.title,
      item.bookingItem.booking.info.name,
      [item.id],
      item.bookingItem.booking.info.origin.product.frip.attachedToSchedule
        ? '일정 지정형'
        : '일정 조율형',
    );
  }, []);

  const renderOrderInfo = (): JSX.Element => {
    return (
      <Column gap="8px" align="flex-start">
        <Row gap="6px">
          <Text color="#1B1C1E">예약 ID:</Text>
          <Text color="#1B1C1E" weight={400}>
            {item.bookingItem.booking.id}
          </Text>
        </Row>
        <Row gap="6px">
          <Text color="#1B1C1E">구매 프립:</Text>
          <Text color="#1B1C1E" weight={400}>
            {item.bookingItem.booking.info.product.title}
          </Text>
        </Row>
        <Row gap="6px">
          <Text color="#1B1C1E">구매 옵션:</Text>
          <Text color="#1B1C1E" weight={400}>
            {item.bookingItem.booking.info.name}
          </Text>
        </Row>
        {/* <Row gap="6px">
          <Text color="#1B1C1E">수량:</Text>
          <Text color="#1B1C1E" weight={400}>
            {item.bookingItem.booking.purchaseCount}개
          </Text>
        </Row>
        <Row gap="6px">
          <Text color="#1B1C1E">티켓:</Text>
          <Text color="#1B1C1E" weight={400}>
            {item.bookingItem.booking.info.ticketCount}개
          </Text>
        </Row> */}
      </Column>
    );
  };

  const renderCustomerInfo = (): JSX.Element => {
    return (
      <Column gap="8px" align="flex-start">
        <Row justifyContent="space-between" style={{ width: '100%' }}>
          <Row gap="6px">
            <Text color="#1B1C1E">크루 연락처:</Text>
            <Text
              color="#1B1C1E"
              weight={400}
              style={{ textDecoration: 'underline' }}
            >
              {formatPhoneNumber(
                item.bookingItem.booking.customer.mobileNumber,
              )}
            </Text>
            <CommonImage
              src={ICON_COPY_GRAY}
              onClick={() => {
                navigator.clipboard.writeText(
                  item.bookingItem.booking.customer.mobileNumber,
                );

                window.showToast('연락처가 복사되었습니다.', 'success', 3000);
              }}
            />
          </Row>
          <BasicButton
            content={
              <Row gap="2px">
                <CommonImage src={ICON_MESSAGE_BLACK} onClick={() => {}} />
                <Text color="#1b1c1e">메시지</Text>
              </Row>
            }
            onClick={() => {
              HackleManager.logBookingMessageClick(
                Number(item.bookingItem.booking.order.id),
                item.bookingItem.booking.customer.id,
                item.bookingItem.booking.info.product.title,
                item.bookingItem.booking.info.name,
                [item.id],
                item.bookingItem.booking.info.origin.product.frip
                  .attachedToSchedule
                  ? '일정 지정형'
                  : '일정 조율형',
                '예약상세',
              );

              if (isWebview()) {
                callWebviewHandler(WebviewOperatorType.OpenActionUrl, {
                  actionUrl: `frip://chattingDetail/O${item.bookingItem.booking.order.id}`,
                });

                return;
              }

              window.open(
                `${getEnvConstant('webClientUrl')}/messageDetail/O${
                  item.bookingItem.booking.order.id
                }`,
              );
            }}
            style={{ borderRadius: '8px' }}
          />
        </Row>
        <Row gap="6px">
          <Text color="#1B1C1E">크루명:</Text>
          <Text color="#1B1C1E" weight={400}>
            {item.bookingItem.booking.customer.nickname}
          </Text>
        </Row>
        <Row gap="6px">
          <Text color="#1B1C1E">성별:</Text>
          <Text color="#1B1C1E" weight={400}>
            {item.bookingItem.booking.customer.gender === 'M' ? '남자' : '여자'}
          </Text>
        </Row>
        {item.bookingItem.booking.order.items[0] && (
          <InquiresTemplate
            orderItems={item.bookingItem.booking.order.items[0]}
            type="booking"
          />
        )}
      </Column>
    );
  };

  const renderBookingInfo = (): JSX.Element => {
    return (
      <Column gap="8px" align="flex-start">
        <Row gap="6px">
          <Text color="#1B1C1E">구매일자:</Text>
          <Text color="#1B1C1E" weight={400}>
            {dayjs(
              item.bookingItem.booking.order.transactions[0].firmedAt,
            ).format(dateFormattingForm())}
          </Text>
        </Row>
        <Row gap="6px">
          <Text color="#1B1C1E">예약처리일시:</Text>
          <Text color="#1B1C1E" weight={400}>
            {dayjs(item.confirmedAt).format(dateFormattingForm())}
          </Text>
        </Row>
        <Row gap="6px">
          <Text color="#1B1C1E">예약일시:</Text>
          <Text color="#1B1C1E" weight={400}>
            {dayjs(
              item.scheduleTerm ? item.scheduleTerm.startedAt : '-',
            ).format(dateFormattingForm())}
          </Text>
        </Row>
        <Row gap="6px">
          <Text color="#1B1C1E">사용일시:</Text>
          <Text color="#1B1C1E" weight={400}>
            {item.usedAt
              ? dayjs(item.usedAt).format(dateFormattingForm())
              : '-'}
          </Text>
        </Row>
      </Column>
    );
  };

  return (
    <React.Fragment>
      <Column gap="16px" align="flex-start" style={{ padding: '0 20px 32px' }}>
        <Row
          style={{
            position: 'sticky',
            top: 0,
            padding: '16px 0',
            width: '100%',
            background: '#fff',
          }}
        >
          <Row style={{ width: '100%', position: 'relative' }}>
            <CommonImage
              src={ICON_CLOSE}
              style={{ position: 'absolute', left: 0 }}
              onClick={() => {
                onClose();
              }}
            />
            <Text color="#000" size="16px" weight={600}>
              예약 정보
            </Text>
            <Row
              gap="4px"
              style={{ position: 'absolute', right: 0, cursor: 'pointer' }}
              onClick={() => {
                navigate(
                  `${SALE_DETAIL_PATH}/${item.bookingItem.booking.order.id}`,
                );
              }}
            >
              <CommonImage
                src={ICON_SHARE}
                onClick={() => {
                  onClose();
                }}
                style={{ width: '16px' }}
              />
              <Text color="#1b1c1e">판매 내역</Text>
            </Row>
          </Row>
        </Row>
        {renderOrderInfo()}
        <Divider />
        {renderCustomerInfo()}
        <Divider />
        {renderBookingInfo()}
        {item.scheduleTerm &&
          dayjs().diff(item.scheduleTerm.startedAt, 'hour') < 1 && (
            <NotificationContainer>
              <img src={ICON_NOTICE_BLACK} alt="notification" />
              <Text color="#1b1c1e" size="13px">
                {dayjs(item.scheduleTerm.startedAt).format(
                  dateFormattingForm(),
                )}{' '}
                이후에 사용 처리와 불참 처리가 가능합니다.
              </Text>
            </NotificationContainer>
          )}
      </Column>
      <Row
        gap="8px"
        justifyContent="flex-end"
        style={{
          padding: '16px 20px 24px',
          position: 'sticky',
          bottom: '0',
          width: '100%',
          borderTop: '1px solid #dbdcdf',
          background: '#fff',
        }}
      >
        <BookingStatusButton
          item={item}
          isModal={true}
          page="detail"
          handleRefetchBookingList={handleRefetchBookingList}
        />
      </Row>
    </React.Fragment>
  );
};

export default BookingDetail;
