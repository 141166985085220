import { useQuery } from '@apollo/client';
import { MeData } from 'graphQL/Auth/types';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import { useEffect, useState } from 'react';
import { FripKind, ProductState } from 'types/api';
import HackleManager from 'utils/hackle';
import { GET_PRODUCT_LIST } from '..';
import { EdgesEntity, ProductFilterInput, ProductList } from '../types';

export function useQueryGetProductList() {
  const myInfo: MeData = useUserInformation((state: any) => state.userInfo);

  const [productfilterInput, setProductFilterInput] =
    useState<ProductFilterInput>({
      hostId: myInfo.me.host!.id,
      statusIn: [
        ProductState.APPLIED,
        ProductState.INSPECTING,
        ProductState.EDITING,
        ProductState.READY,
        ProductState.SALE,
        ProductState.SOLD_OUT,
        ProductState.PAUSED,
        ProductState.REJECTED,
        ProductState.SUSPENDED,
        ProductState.CLOSED,
      ],
      fripKind: FripKind.COMMON,
    });
  const [productList, setProductList] = useState<ProductList>();
  const [pageSize, setPageSize] = useState<number>(9);
  const [isAcceptFilter, setIsAcceptFilter] = useState<boolean>(false);
  const [selectProduct, setSelectProduct] = useState<EdgesEntity>();
  const [isOpenSelectProductModal, setIsOpenSelectProductModal] =
    useState<boolean>(false);

  const { data, loading, refetch } = useQuery(GET_PRODUCT_LIST, {
    variables: {
      page: 1,
      size: pageSize,
      filter: productfilterInput,
    },
  });

  const handleRefetchProductList = async () => {
    try {
      const response = await refetch({
        page: 1,
        size: 9,
        filter: productfilterInput,
      });

      if (response) {
        setProductList(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoadMoreProductList = async () => {
    try {
      const response = await refetch({
        page: 1,
        size: pageSize + 9,
        filter: productfilterInput,
      });
      setPageSize(pageSize + 9);

      if (response) {
        setProductList(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSetSelectProduct = (item: EdgesEntity) => {
    setSelectProduct(item);

    setIsOpenSelectProductModal(true);
  };

  useEffect(() => {
    if (data) {
      setProductList(data);
    }
  }, [data]);

  useEffect(() => {
    handleRefetchProductList();
  }, [productfilterInput]);

  return {
    isLoading: loading,
    myInfo,
    productList,
    productfilterInput,
    isAcceptFilter,
    selectProduct,
    isOpenSelectProductModal,
    setProductFilterInput,
    setIsOpenSelectProductModal,
    handleSetSelectProduct,
    handleLoadMoreProductList,
    setIsAcceptFilter,
  };
}
