import { useMutationGenerateContentUploadInfo } from 'graphQL/Image/useMutationGenerateContentUploadInfo';
import { useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import {
  callWebviewHandler,
  isWebview,
  WebviewOperatorType,
} from 'utils/webview';

export const useIntroduceModifyHooks = (editorValue: string) => {
  const { handleGenerateContentUpload } =
    useMutationGenerateContentUploadInfo();

  const containerRef = useRef<HTMLDivElement>(null);
  const quillRef = useRef<ReactQuill>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState<string>(editorValue);

  const isAbled = value !== editorValue;

  const handleImageUploadClick = () => {
    if (fileInputRef.current) {
      if (isWebview()) {
        callWebviewHandler(
          WebviewOperatorType.UpdateMessageImageFileHandler,
          undefined,
          'handleImageUpload',
        );
      } else {
        fileInputRef.current.click();
      }
    }
  };

  const handleImageUpload = (link: { link: string }) => {
    const result = JSON.parse(link.link);

    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection();

      if (link.link) {
        editor.insertEmbed(range ? range.index : 0, 'image', result.url);
      }
    }
  };

  const handleWebImageUpload = (link: { link: string }) => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection();

      if (link.link) {
        editor.insertEmbed(range ? range.index : 0, 'image', link.link);
      }
    }
  };

  const handleFileChange = (event: any) => {
    handleGenerateContentUpload(
      {
        file: event.target.files[0],
        fileName: event.target.files[0].name,
      },
      (url: string, contentId: string) => {
        handleWebImageUpload({ link: url });
      },
    );
  };

  const handleResetIntroduce = () => {
    setValue(editorValue);
  };

  return {
    containerRef,
    quillRef,
    fileInputRef,
    value,
    isAbled,
    setValue,
    handleImageUploadClick,
    handleFileChange,
    handleImageUpload,
    handleResetIntroduce,
  };
};
