import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import styled from 'styled-components';
import 'react-quill/dist/quill.snow.css';

import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import { ModifyValue } from 'containers/ProductDetail/hooks/useProductDetaillHooks';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import { useIntroduceModifyHooks } from './hooks/useIntroductModifyHooks';
import SaveButton from '../SaveButton';
import { Product, ProductState } from 'types/api';
import HackleManager from 'utils/hackle';

const AlignStyle = Quill.import('attributors/style/align');
const BackgroundStyle = Quill.import('attributors/style/background');
const ColorStyle = Quill.import('attributors/style/color');
const FontStyle = Quill.import('attributors/style/font');
const SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = ['0.75em', '1em', '1.5em', '2.5em'];

// 기본 스타일 속성으로 사용되도록 등록
Quill.register(AlignStyle, true);
Quill.register(BackgroundStyle, true);
Quill.register(ColorStyle, true);
Quill.register(FontStyle, true);
// Quill.register(SizeStyle, true);

const QuillContainer = styled.div<{ $isMobile: boolean; height: number }>`
  position: relative;
  width: 100%;
  height: ${props => props.height}px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  img {
    width: 100%;
    border-radius: 7px;
  }

  .quill {
    width: 100%;

    font-family: Pretendard !important;
    color: #111;

    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }

    .ql-editor {
      padding: 12px 20px;

      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
      }
    }

    .ql-size-huge {
      font-family: Pretendard;
      line-height: 40px;
    }

    .ql-tooltip {
      left: 0 !important;
    }

    .ql-editor h1 {
      font-family: Pretendard;
      font-size: 22px;
      font-weight: 700;
      line-height: 160%;
    }

    .ql-editor h2 {
      font-size: 18px;
      font-weight: 700;
      line-height: 160%;
    }

    .ql-editor h3 {
      font-size: 16px;
      font-weight: 400;
      line-height: 160%;
    }

    .ql-editor p {
      font-size: 14px;
      font-weight: 400;
      line-height: 160%;
    }

    .ql-blank {
      font-size: 16px;
      line-height: 160%;
    }

    .ql-blank::before {
      position: absolute;
      left: 0;
      font-family: Pretendard !important;
      font-style: normal !important;
      color: #999;
    }

    .ql-container {
      font-family: Pretendard !important;
      border: none;
    }

    .ql-toolbar {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;
      padding: ${props => (props.$isMobile ? '0 20px 10px' : '32px 20px')};
      position: sticky;
      top: 0;
      z-index: 2;

      width: 100%;
      border: none;
      border-bottom: 1px solid #eee;
      background-color: #fff;

      .ql-formats:nth-child(2) {
        padding-right: 10px;
        border-right: 1px solid #eaecf0;
      }

      .ql-formats:nth-child(5) {
        padding-right: 8px;
        border-right: 1px solid #eaecf0;
      }

      button {
        width: 22px;
        padding: 0;
      }

      svg {
        width: 22px !important;
        height: 22px !important;
      }
    }

    .ql-formats {
      margin-right: 0;
    }
  }

  .ql-tooltip {
    height: 0;
  }
`;

const ImageContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  align-items: center;
  position: absolute;
  right: ${props => (props.$isMobile ? '20px' : '20px')};
  top: ${props => (props.$isMobile ? '1px' : '20px')};
  padding: 2px 0;
  z-index: 2;

  input {
    display: none;
  }
`;

interface IntroduceModifyFormIProps {
  isMobile: boolean;
  editorValue: string;
  productInfo: Product;
  modifyValue: ModifyValue;
  height: number;
  setModifyValue: Dispatch<SetStateAction<ModifyValue>>;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
}

const IntroduceModifyForm: React.FC<IntroduceModifyFormIProps> = props => {
  const {
    isMobile,
    editorValue,
    productInfo,
    modifyValue,
    height,
    setModifyValue,
    handleOnChangeProductParamInput,
  } = props;

  const {
    containerRef,
    quillRef,
    fileInputRef,
    value,
    isAbled,
    setValue,
    handleImageUploadClick,
    handleFileChange,
    handleImageUpload,
    handleResetIntroduce,
  } = useIntroduceModifyHooks(editorValue);

  const handleRegistIntroduce = () => {
    handleOnChangeProductParamInput('htmlDetailContent', value);
  };

  useEffect(() => {
    HackleManager.logProductModifyProductDescriptionView(
      Number(productInfo.id),
      productInfo.title,
    );

    window.handleImageUpload = handleImageUpload;
  }, []);

  const toolbarContainer = [
    // [{ size: ['0.75em', '1em', '1.5em', '2.5em'] }], // 드롭다운에서 선택 가능
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: 1 }, { header: 2 }],
    ['bold'],
    ['italic'],
    ['underline'],
    ['strike'],
    [{ color: [] }, { background: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
    ['link'],
    ['video'],
    ['image'],
  ];

  const formats = [
    'size',
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'color',
    'background',
    'list',
    'bullet',
    'align',
    'link',
    'video',
    'image',
  ];

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: toolbarContainer,
        handlers: {
          image: () => {
            handleImageUploadClick();
          },
        },
      },
    };
  }, []);

  const renderEditorContainer = (): JSX.Element => {
    return (
      <QuillContainer
        ref={containerRef}
        $isMobile={isMobile}
        height={isMobile ? height - 175 : height - 275}
      >
        {isMobile ? (
          <Row style={{ paddingBottom: '24px' }}>
            <Text color="#000" size="16px" weight={600}>
              상세 정보
            </Text>
          </Row>
        ) : (
          <Row
            justifyContent="flex-start"
            style={{
              padding: '32px 40px 20px',
              width: '100%',
              background: '#fff',
              zIndex: 3,
            }}
          >
            <Text color="#1B1C1E" size="24px">
              상세 정보 수정하기
            </Text>
          </Row>
        )}
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          ref={quillRef}
          style={{
            width: '100%',
          }}
          value={value}
          onChange={(value: string) => {
            setValue(value);
          }}
        />
        <ImageContainer $isMobile={isMobile}>
          {/* <CommonImage
            src={LOADING_GIF}
            onClick={() => {
              handleImageUploadClick();
            }}
            style={{
              width: '22px',
            }}
          /> */}
          <input
            ref={fileInputRef}
            type="file"
            multiple={false}
            onChange={handleFileChange}
            accept=".jpg, .png, .jpeg"
          />
        </ImageContainer>
      </QuillContainer>
    );
  };

  const renderContents = (): JSX.Element => {
    if (isMobile) {
      return (
        <ModalContainer
          isMobile={isMobile}
          isAbled={productInfo.status !== ProductState.CLOSED && isAbled}
          onClose={() => {
            setModifyValue({ ...modifyValue, modifyStatus: '' });
          }}
          onCloseText="닫기"
          onAcceptText="적용"
          onClick={() => {
            if (isAbled) {
              HackleManager.logProductModifyItemSaveComplete(
                Number(productInfo.id),
                productInfo.title,
                '상세정보',
              );

              handleRegistIntroduce();

              setModifyValue({
                ...modifyValue,
                isModify: true,
                modifyStatus: '',
              });
            }
          }}
          style={{ height: `${height - 64}px`, overflow: 'hidden' }}
        >
          <Column
            gap="24px"
            style={{
              padding: '16px 0 0',
            }}
          >
            {renderEditorContainer()}
          </Column>
        </ModalContainer>
      );
    }

    return (
      <Column>
        <Column>
          {renderEditorContainer()}
          {productInfo.status !== ProductState.CLOSED && (
            <Row
              style={{
                position: 'sticky',
                bottom: 0,
                padding: '16px 40px',
                width: '100%',
                borderTop: '1px solid #EAEBEC',
              }}
            >
              <SaveButton
                isAbled={isAbled}
                modifyValue={modifyValue}
                setModifyValue={setModifyValue}
                handleResetItem={handleResetIntroduce}
                handleRegistItem={() => {
                  HackleManager.logProductModifyItemSaveComplete(
                    Number(productInfo.id),
                    productInfo.title,
                    '상세정보',
                  );
                  handleRegistIntroduce();
                }}
              />
            </Row>
          )}
        </Column>
      </Column>
    );
  };

  return renderContents();
};

export default IntroduceModifyForm;
