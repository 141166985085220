import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import LoginPage from './containers/Login';
import SignupPage from 'containers/Signup';
import PrivateRoute from 'containers/PrivateRoute';
import GuidePage from 'containers/Guide';
import SettlementPage from 'containers/Settlement';
import SettlementRegistPage from 'containers/SettlementRegist';

import {
  GUIDE_PATH,
  LOGIN_PATH,
  SETTLEMENT_PATH,
  SETTLEMENT_REGIST_PATH,
  SETTLEMENT_BANK_REGIST_PATH,
  SIGNUP_PATH,
  SETTLEMENT_LIST_PATH,
  SETTLEMENT_DETAIL_PATH,
  PRODUCT_REGIST_PATH,
  PRODUCT_LIST_PATH,
  PRODUCT_DETAIL_PATH,
  MESSAGE_LIST_PATH,
  QNA_LIST_PATH,
  REVIEW_LIST_PATH,
  MENU_PATH,
  SALE_LIST_PATH,
  NOTICE_PATH,
  SALE_DETAIL_PATH,
  BOOKING_LIST_PATH,
  PROFILE_PATH,
  INDIVIDUAL_PATH,
  NOTICE_DETAIL_PATH,
  START_PATH,
} from 'constants/path';
import SettlementBankRegistPage from 'containers/SettlementBankRegist';
import SettlementListPage from 'containers/SettlementList';
import SettlementDetailPage from 'containers/SettlementDetail';
import { useGetMe } from 'graphQL/Auth/user/useGetMe';
import ProductRegistPage from 'containers/ProductRegist';
import { STORAGE_KEY } from 'utils/localStoarge';
import {
  callWebviewHandler,
  isWebview,
  WebviewOperatorType,
} from 'utils/webview';
import ProductListPage from 'containers/ProductList';
import ProductDetailPage from 'containers/ProductDetail';
import MessageListPage from 'containers/MessageList';
import QnAListPage from 'containers/QnaList';
import ReviewListPage from 'containers/ReviewList';
import MenuPage from 'containers/Menu';
import SaleListPage from 'containers/SaleList';
import NoticePage from 'containers/Notice';
import SaleDetailPage from 'containers/SaleDetail';
import BookingListPage from 'containers/BookingList';
import ProfilePage from 'containers/Profile';
import IndividualPage from 'containers/Profile/Individual';
import NoticeDetailPage from 'containers/NoticeDetail';
import ToastManager from 'components/Modal/ToastManager';
import GuideContent from 'containers/Guide/components/GuideContent';
import Loading from 'components/Loading/Loading';
import NavigateLoading from 'components/Loading/NavigateLoading';

const AppLayOut = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  /* max-width: 768px; */
  width: 100%;
`;

function App() {
  const { handleGetUserInformation, handleRefetchUser } = useGetMe();

  const [loading, setLoading] = useState<boolean>(true);

  const handleSetAuthorization = (param: { authToken: string }) => {
    if (localStorage.getItem(STORAGE_KEY.AUTHORIZATION) !== param.authToken) {
      localStorage.removeItem(STORAGE_KEY.AUTHORIZATION);
    }

    localStorage.setItem(STORAGE_KEY.AUTHORIZATION, param.authToken);
    window.__INIT_AUTHORIZATION__ = param.authToken;

    handleRefetchUser(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (isWebview()) {
      callWebviewHandler(WebviewOperatorType.SetAuthorizationHandler);
      setLoading(true);
      // callWebviewHandler(WebviewOperatorType.OpenWebConsoleModal);
    }

    handleGetUserInformation();
    window.handleSetAuthorization = handleSetAuthorization;
  }, []);

  if (isWebview() && loading) {
    return <Loading />;
  }

  return (
    <AppLayOut>
      <NavigateLoading />
      <ToastManager />
      <BrowserRouter>
        <Routes>
          <Route path={LOGIN_PATH} element={<LoginPage />} />
          <Route path={SIGNUP_PATH} element={<SignupPage />} />
          <Route path={GUIDE_PATH} element={<GuidePage />} />
          <Route path={START_PATH} element={<GuideContent />} />
          <Route element={<PrivateRoute />}>
            <Route
              path="/"
              element={<Navigate replace to={SALE_LIST_PATH} />}
            />

            <Route path={SETTLEMENT_PATH} element={<SettlementPage />} />
            <Route
              path={SETTLEMENT_REGIST_PATH}
              element={<SettlementRegistPage />}
            />
            <Route
              path={SETTLEMENT_BANK_REGIST_PATH}
              element={<SettlementBankRegistPage />}
            />
            <Route
              path={SETTLEMENT_LIST_PATH}
              element={<SettlementListPage />}
            />
            <Route
              path={SETTLEMENT_DETAIL_PATH}
              element={<SettlementDetailPage />}
            />
            <Route path={PRODUCT_LIST_PATH} element={<ProductListPage />} />
            <Route
              path={`${PRODUCT_REGIST_PATH}/:id`}
              element={<ProductRegistPage />}
            />
            <Route
              path={`${PRODUCT_DETAIL_PATH}/:id`}
              element={<ProductDetailPage />}
            />
            <Route path={MESSAGE_LIST_PATH} element={<MessageListPage />} />
            <Route path={QNA_LIST_PATH} element={<QnAListPage />} />
            <Route path={REVIEW_LIST_PATH} element={<ReviewListPage />} />
            <Route path={SALE_LIST_PATH} element={<SaleListPage />} />
            <Route
              path={`${SALE_DETAIL_PATH}/:id`}
              element={<SaleDetailPage />}
            />
            <Route path={BOOKING_LIST_PATH} element={<BookingListPage />} />
            <Route path={MENU_PATH} element={<MenuPage />} />
            <Route path={NOTICE_PATH} element={<NoticePage />} />
            <Route
              path={`${NOTICE_DETAIL_PATH}/:id`}
              element={<NoticeDetailPage />}
            />
            <Route path={PROFILE_PATH} element={<ProfilePage />} />
            <Route path={INDIVIDUAL_PATH} element={<IndividualPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AppLayOut>
  );
}

export default App;
