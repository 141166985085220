import React from "react";
import styled from "styled-components";
import { ICON_SETTLEMENT_ARROW_RIGHT } from "assets/icons";
import { FilterType } from "../hooks/useSettlementDetailHooks";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 20px 16px 32px;
  width: 100%;

  background-color: #fff;
  border-radius: 16px 16px 0 0;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0;
  width: 100%;

  color: #1b1c1e;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.16px;
`;

interface SettlementFilterModalIProps {
  handleAccountListFilter: (filterType: FilterType) => void;
  onClose: () => void;
}

const SettlementFilterModal: React.FC<SettlementFilterModalIProps> = (
  props
) => {
  const { handleAccountListFilter, onClose } = props;

  return (
    <Container>
      <ModalContainer style={{ padding: window.webkit && "20px 16px 53px" }}>
        <Row
          onClick={() => {
            handleAccountListFilter(FilterType.DATE);
            onClose();
          }}
        >
          <span>결제일순</span>
          <img src={ICON_SETTLEMENT_ARROW_RIGHT} alt="arrow right icon" />
        </Row>
        <Row
          onClick={() => {
            handleAccountListFilter(FilterType.TYPE);
            onClose();
          }}
        >
          <span>유형별</span>
          <img src={ICON_SETTLEMENT_ARROW_RIGHT} alt="arrow right icon" />
        </Row>
        <Row
          onClick={() => {
            handleAccountListFilter(FilterType.PRODUCT);
            onClose();
          }}
        >
          <span>상품별</span>
          <img src={ICON_SETTLEMENT_ARROW_RIGHT} alt="arrow right icon" />
        </Row>
      </ModalContainer>
    </Container>
  );
};

export default SettlementFilterModal;
