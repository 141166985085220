import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ICON_CLOSE } from 'assets/icons';
import Column from 'components/common/Column';
import CommonImage from 'components/common/CommonImage';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import SearchForm from 'components/common/SearchForm';
import { PurchaseItemFilterInput } from '../graphQL/types';
import { SaleFilterValue, TermWithType } from '../hooks/useSaleListHooks';
import HackleManager from 'utils/hackle';
import {
  formatSalesSearchType,
  formatSalesTermType,
} from 'utils/hackle/parameterFormatting';

const RegistDateSelectButton = styled.button<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  border-radius: 8px;
  border: ${props =>
    props.$isSelected ? '1.5px solid #1b1c1e' : '1px solid #DBDCDF'};
  background: ${props => (props.$isSelected ? '#f7f7f8' : '#fff')};

  color: var(--gray-gray-09, #1b1c1e);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.6px;
`;

interface SearchModalContainerIProps {
  isMobile: boolean;
  purchaseItemFilterInput: PurchaseItemFilterInput;
  filterValue: SaleFilterValue;
  termWithType: TermWithType;
  handleSetFilterValue: (key: string, value: any) => void;
  handleSetTermWithType: (type: string) => void;
  handleSetPurchaseItemFilterInput: (key: string, value: any) => void;
  onClose: () => void;
  handleResetFilterValue: () => void;
  setIsAcceptFilter: Dispatch<SetStateAction<boolean>>;
}

const SearchModalContainer: React.FC<SearchModalContainerIProps> = props => {
  const {
    isMobile,
    purchaseItemFilterInput,
    filterValue,
    termWithType,
    handleSetFilterValue,
    handleSetTermWithType,
    handleSetPurchaseItemFilterInput,
    onClose,
    handleResetFilterValue,
    setIsAcceptFilter,
  } = props;

  const [searchValue, setSearchValue] = useState<string>(
    filterValue.searchValue,
  );
  const [isClearFilter, setIsClearFilter] = useState<boolean>(false);

  useEffect(() => {
    handleSetFilterValue('searchValue', searchValue);
  }, [searchValue]);

  const handleAcceptFilter = () => {
    if (
      isClearFilter &&
      filterValue.searchValue === '' &&
      filterValue.termType === 'all' &&
      filterValue.searchType === 'productName'
    ) {
      handleSetPurchaseItemFilterInput('filter', {
        statusIn: null,
        transactionStatusIn: null,
      });
      setIsAcceptFilter(false);

      return;
    }

    setIsClearFilter(false);

    if (searchValue !== '') {
      HackleManager.logSalesSearchComplete(
        formatSalesSearchType(filterValue.searchType),
        searchValue,
        formatSalesTermType(filterValue.termType),
      );

      setIsAcceptFilter(true);

      switch (filterValue.searchType) {
        case 'productName': {
          handleSetPurchaseItemFilterInput('filter', {
            ...purchaseItemFilterInput.filter,
            productTitleLike: searchValue,
            productIds: null,
            phoneLike: null,
            nameLike: null,
            transactionTerm: termWithType,
          });

          return;
        }
        case 'fripId': {
          handleSetPurchaseItemFilterInput('filter', {
            ...purchaseItemFilterInput.filter,
            productTitleLike: null,
            productIds: searchValue.includes(',')
              ? searchValue.split(',')
              : [searchValue],
            phoneLike: null,
            nameLike: null,
            transactionTerm: termWithType,
          });

          return;
        }
        case 'optionName': {
          handleSetPurchaseItemFilterInput('filter', {
            ...purchaseItemFilterInput.filter,
            itemNameLike: searchValue,
            productTitleLike: null,
            productIds: null,
            phoneLike: null,
            nameLike: null,
            transactionTerm: termWithType,
          });

          return;
        }
        case 'crewName': {
          handleSetPurchaseItemFilterInput('filter', {
            ...purchaseItemFilterInput.filter,
            itemNameLike: null,
            productTitleLike: null,
            productIds: null,
            phoneLike: null,
            nameLike: searchValue,
            transactionTerm: termWithType,
          });

          return;
        }
        case 'phoneNumber': {
          handleSetPurchaseItemFilterInput('filter', {
            ...purchaseItemFilterInput.filter,
            itemNameLike: null,
            productTitleLike: null,
            productIds: null,
            nameLike: null,
            phoneLike: searchValue,
            transactionTerm: termWithType,
          });

          return;
        }
        default:
          return;
      }
    } else {
      setIsAcceptFilter(true);

      handleSetPurchaseItemFilterInput('filter', {
        ...purchaseItemFilterInput.filter,
        transactionTerm: termWithType,
      });
    }
  };

  const renderSearchForm = (): JSX.Element => {
    return (
      <Column align="flex-start" gap="12px">
        <Text color="#1b1c1e" size="16px">
          검색어
        </Text>
        <Row
          gap="12px"
          justifyContent="flex-start"
          style={{ width: '100%', flexWrap: 'wrap' }}
        >
          <RegistDateSelectButton
            $isSelected={filterValue.searchType === 'productName'}
            onClick={() => {
              handleSetFilterValue('searchType', 'productName');
            }}
          >
            프립명
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={filterValue.searchType === 'fripId'}
            onClick={() => {
              handleSetFilterValue('searchType', 'fripId');
            }}
          >
            프립ID
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={filterValue.searchType === 'optionName'}
            onClick={() => {
              handleSetFilterValue('searchType', 'optionName');
            }}
          >
            옵션명
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={filterValue.searchType === 'crewName'}
            onClick={() => {
              handleSetFilterValue('searchType', 'crewName');
            }}
          >
            크루명
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={filterValue.searchType === 'phoneNumber'}
            onClick={() => {
              handleSetFilterValue('searchType', 'phoneNumber');
            }}
          >
            크루 전화번호
          </RegistDateSelectButton>
        </Row>
        <SearchForm
          placeholder="검색어를 입력해 주세요."
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        />
      </Column>
    );
  };

  const renderProductStatus = (): JSX.Element => {
    const handleSetTermType = (type: string) => {
      handleSetTermWithType(type);

      handleSetFilterValue('termType', type);
    };

    return (
      <Column gap="12px" align="flex-start">
        <Text color="#1b1c1e" size="16px">
          판매일
        </Text>
        <Row
          gap="12px"
          justifyContent="flex-start"
          style={{ width: '100%', flexWrap: 'wrap' }}
        >
          <RegistDateSelectButton
            $isSelected={filterValue.termType === 'all'}
            onClick={() => {
              handleSetTermType('all');
            }}
          >
            전체
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={filterValue.termType === '1month'}
            onClick={() => {
              handleSetTermType('1month');
            }}
          >
            최근 1개월
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={filterValue.termType === '6month'}
            onClick={() => {
              handleSetTermType('6month');
            }}
          >
            최근 6개월
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={filterValue.termType === '1year'}
            onClick={() => {
              handleSetTermType('1year');
            }}
          >
            최근 1년
          </RegistDateSelectButton>
          <RegistDateSelectButton
            $isSelected={filterValue.termType === '5year'}
            onClick={() => {
              handleSetTermType('5year');
            }}
          >
            최근 5년
          </RegistDateSelectButton>
        </Row>
      </Column>
    );
  };

  return (
    <ModalContainer
      isMobile={isMobile}
      isAbled={searchValue !== '' || termWithType !== null}
      onAcceptText="적용"
      onCloseText="초기화"
      onClick={() => {
        handleAcceptFilter();

        onClose();
      }}
      onClose={() => {
        handleResetFilterValue();

        setIsClearFilter(true);

        setSearchValue('');
      }}
      style={{ gap: 0 }}
    >
      <Row style={{ padding: '16px 0' }}>
        <CommonImage
          src={ICON_CLOSE}
          style={{ position: 'absolute', left: '20px' }}
          onClick={() => {
            onClose();
          }}
        />
        <Text color="#000" size="16px" weight={600}>
          검색
        </Text>
      </Row>
      <Column
        align="flex-start"
        gap="32px"
        style={{ padding: '12px 20px 32px' }}
      >
        {renderSearchForm()}
        {renderProductStatus()}
      </Column>
    </ModalContainer>
  );
};

export default SearchModalContainer;
