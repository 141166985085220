import Row from 'components/common/Row';
import Text from 'components/common/Text';
import Divider from 'components/Divider';
import {
  FripLocationParam,
  ProductParamInput,
} from 'containers/ProductRegist/hooks/types';
import React from 'react';
import styled from 'styled-components';
import { FripLocation, ProductKind } from 'types/api';
import { formatInteractionType } from 'utils/formatting';
import PlaceForm from './PlaceForm';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 24px;
  width: 100%;
`;

interface ProgressPlaceContainerIProps {
  productParamInput: ProductParamInput;
  locationsOfVenue: FripLocationParam[];
}

const ProgressPlaceContainer: React.FC<
  ProgressPlaceContainerIProps
> = props => {
  const { productParamInput, locationsOfVenue } = props;

  return (
    <React.Fragment>
      <Divider style={{ height: '8px', background: '#F4F4F4' }} />
      <Container>
        <Text color="#000" size="18px" weight={700}>
          {productParamInput.kind === ProductKind.ONLINE
            ? '온라인 진행 방법'
            : '진행 하는 장소'}
        </Text>
        {productParamInput.kind === ProductKind.ONLINE ? (
          <Row gap="20px">
            <Text color="#999">온라인</Text>
            <Text color="#333">
              {formatInteractionType(productParamInput.frip.interactionType)}
            </Text>
          </Row>
        ) : (
          <React.Fragment>
            {locationsOfVenue.map((item, idx) => {
              return <PlaceForm key={idx} item={item} />;
            })}
          </React.Fragment>
        )}
      </Container>
    </React.Fragment>
  );
};

export default ProgressPlaceContainer;
