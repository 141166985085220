import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import ModifyContainer from '../ModifyContainer';
import Column from 'components/common/Column';
import Text from 'components/common/Text';
import Divider from 'components/Divider';

import Row from 'components/common/Row';
import {
  InquiryTemplate,
  InquiryTemplateItem,
  ItemInquiryInputKind,
} from 'types/api';
import { ModifyValue } from '../../hooks/useProductDetaillHooks';

interface QuestionContainerIProps {
  isMobile: boolean;
  inquiryList: InquiryTemplate;
  modifyValue: ModifyValue;
  setModifyValue: React.Dispatch<React.SetStateAction<ModifyValue>>;
}

const QuestionContainer: React.FC<QuestionContainerIProps> = props => {
  const { isMobile, inquiryList, modifyValue, setModifyValue } = props;

  const renderInquiryKind = (item: InquiryTemplateItem): JSX.Element => {
    switch (item.inputKind) {
      case ItemInquiryInputKind.TEXT: {
        return (
          <Column gap="4px" align="flex-start">
            <Text color="#989ba2">답변 유형</Text>
            <Text color="#1b1c1e" size="16px" weight={400}>
              주관식
            </Text>
          </Column>
        );
      }
      default: {
        return (
          <React.Fragment>
            {!isEmpty(item.items) && (
              <Column gap="2px" align="flex-start">
                {item.items.map((selectItem, selectIdx) => {
                  return (
                    <Column gap="4px" align="flex-start" key={selectIdx}>
                      <Text color="#989ba2">객관식 답변{selectIdx + 1}</Text>
                      <Text color="#1b1c1e" size="16px" weight={400}>
                        {selectItem.label}
                      </Text>
                    </Column>
                  );
                })}
              </Column>
            )}
          </React.Fragment>
        );
      }
    }
  };

  const renderContents = (): JSX.Element => {
    if (isEmpty(inquiryList)) {
      return (
        <Column>
          <Text color="#46474C" weight={400}>
            등록한 질문이 없어요.
          </Text>
          <Text color="#46474C" weight={400}>
            프립 진행을 위해 크루들로부터
          </Text>
          <Text color="#46474C" weight={400}>
            추가 정보를 수집해야 할 경우 작성해 보세요.
          </Text>
        </Column>
      );
    }

    if (inquiryList && isEmpty(inquiryList.inquiries)) {
      return (
        <Column>
          <Text color="#46474C" weight={400}>
            등록한 질문이 없어요.
          </Text>
          <Text color="#46474C" weight={400}>
            프립 진행을 위해 크루들로부터
          </Text>
          <Text color="#46474C" weight={400}>
            추가 정보를 수집해야 할 경우 작성해 보세요.
          </Text>
        </Column>
      );
    }

    return (
      <Column gap="12px">
        {inquiryList.inquiries.map((inquiryItem, inquiryIdx) => {
          return (
            <React.Fragment key={inquiryIdx}>
              <Column gap="8px" align="flex-start">
                <Column gap="4px" align="flex-start">
                  <Row justifyContent="space-between" style={{ width: '100%' }}>
                    <Text color="#989BA2" style={{ minWidth: '42px' }}>
                      질문{inquiryIdx + 1}
                    </Text>
                  </Row>
                  <Text color="#1B1C1E" size="16px" weight={400}>
                    {inquiryItem.title}
                  </Text>
                </Column>
                {renderInquiryKind(inquiryItem)}
                <Column gap="4px" align="flex-start">
                  <Text color="#989BA2">답변 필수 여부</Text>
                  <Text color="#1B1C1E" size="16px" weight={400}>
                    {inquiryItem.required
                      ? '필수로 받을게요'
                      : '필수가 아니에요'}
                  </Text>
                </Column>
              </Column>
              {inquiryList.inquiries.length !== inquiryIdx + 1 && <Divider />}
            </React.Fragment>
          );
        })}
        {/* <DashButton
      text="질문 추가"
      onClick={() => {
        setInquiryTemplate({
          ...inquiryTemplate,
          inquiries: [
            ...inquiryTemplate.inquiries,
            {
              title: '',
              inputKind: ItemInquiryInputKind.TEXT,
              inputPattern: ItemInquiryInputPattern.PER_ITEM_COUNT,
              required: false,
              activated: true,
              items: [],
            },
          ],
        });
        setInquiries({
          title: '',
          inputKind: ItemInquiryInputKind.TEXT,
          inputPattern: ItemInquiryInputPattern.PER_ITEM_COUNT,
          required: false,
          activated: true,
          items: [],
        });
      }}
    /> */}
      </Column>
    );
  };

  return (
    <ModifyContainer
      title="질문"
      isSelected={!isMobile && modifyValue.modifyStatus === 'question'}
      modifyText={isEmpty(inquiryList) ? '질문 등록하기' : '수정하기'}
      onClick={() => {
        // window.showToast(
        //   '이 기능은 현재 점검 중입니다. 11월 26일 자정 이후부터 정상적으로 이용하실 수 있습니다.',
        //   'failed',
        //   7000,
        // );

        setModifyValue({ ...modifyValue, modifyStatus: 'question' });
      }}
    >
      {renderContents()}
    </ModifyContainer>
  );
};

export default QuestionContainer;
