import { IMAGE_REGIST_PRODUCT_COMPLETE } from 'assets/images';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import { PRODUCT_LIST_PATH } from 'constants/path';
import { MeData } from 'graphQL/Auth/types';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import React, { SetStateAction, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import HackleManager from 'utils/hackle';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 48px;
  padding: 0 20px;
  width: 100%;
  min-height: 65vh;
`;

const FloatingContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: 0;
  padding: 16px 20px;
  width: 100%;
  border-top: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);
`;

const InspectionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  border-radius: var(--Radius-border-radius-200, 8px);
  background: var(--Primary-Normal, #7126ff);

  color: var(--Static-White, #fff);

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.6px;
`;

interface RegistCompleteContainerIProps {
  isMobile: boolean;
  setIsShowCompleteView: React.Dispatch<SetStateAction<boolean>>;
  handleProductRequestInspection: () => void;
}

const RegistCompleteContainer: React.FC<
  RegistCompleteContainerIProps
> = props => {
  const { isMobile, setIsShowCompleteView, handleProductRequestInspection } =
    props;

  const navigate = useNavigate();

  const myInfo: MeData = useUserInformation((state: any) => state.userInfo);
  const params = useParams<{ id: string }>();

  useEffect(() => {
    HackleManager.logCreateStepFourReviewRequestView(
      params.id !== 'draft' ? Number(params.id) : undefined,
    );
  }, []);

  // useEffect(() => {
  //   const beforeunloadFunc = event => {
  //     event.preventDefault();

  //     event.returnValue = '';
  //   };
  //   const unloadFunc = event => {
  //     //데이터 삭제 등 진행
  //     navigate(PRODUCT_LIST_PATH);
  //   };

  //   //unload 이벤트
  //   window.addEventListener('unload', unloadFunc);

  //   //새로고침 이벤트
  //   window.addEventListener('beforeunload', beforeunloadFunc);

  //   return () => {
  //     window.removeEventListener('beforeunload', beforeunloadFunc);
  //     window.removeEventListener('unload', unloadFunc);
  //   };
  // }, []);

  const renderContents = (): JSX.Element => {
    if (isMobile) {
      return (
        <Container>
          <img
            src={IMAGE_REGIST_PRODUCT_COMPLETE}
            alt="regist complete"
            style={{ width: '220px' }}
          />
          <Column gap="12px" align="flex-start">
            <Text color="#1b1c1e" size="24px" style={{ lineHeight: '32px' }}>
              {myInfo.me.host.name}님, 상품 등록이 완료되었습니다.
            </Text>
            <Text
              color="#70737c"
              size="16px"
              weight={400}
              style={{ lineHeight: '24px' }}
            >
              아래 검수 요청하기 버튼을 클릭하시면, 프립에서 검수가 시작됩니다.
            </Text>
            <Text
              color="#7126FF"
              size="14px"
              weight={400}
              style={{ lineHeight: '22px' }}
            >
              검수는 영업일 기준 3~7일이 소요되며, 설정하신 판매 기간에 따라
              판매가 시작됩니다.
            </Text>
          </Column>
          <FloatingContainer>
            <InspectionButton
              onClick={() => {
                handleProductRequestInspection();
              }}
            >
              검수 요청하기
            </InspectionButton>
          </FloatingContainer>
        </Container>
      );
    }

    return (
      <Row gap="120px" style={{ minHeight: '70vh' }}>
        <Column gap="12px" align="flex-start">
          <Text color="#1b1c1e" size="24px" style={{ lineHeight: '32px' }}>
            {myInfo.me.host.name}님, 상품 등록이 완료되었습니다.
          </Text>
          <Text
            color="#70737c"
            size="16px"
            weight={400}
            style={{ lineHeight: '24px' }}
          >
            아래 검수 요청하기 버튼을 클릭하시면, 프립에서 검수가 시작됩니다.
          </Text>
          <Text
            color="#7126FF"
            size="14px"
            weight={400}
            style={{ lineHeight: '22px' }}
          >
            검수는 영업일 기준 3~7일이 소요되며, 설정하신 판매 기간에 따라
            판매가 시작됩니다.
          </Text>
        </Column>
        <FloatingContainer>
          <InspectionButton onClick={handleProductRequestInspection}>
            검수 요청하기
          </InspectionButton>
        </FloatingContainer>
        <img
          src={IMAGE_REGIST_PRODUCT_COMPLETE}
          alt="regist complete"
          style={{ width: '220px' }}
        />
      </Row>
    );
  };

  return renderContents();
};

export default RegistCompleteContainer;
