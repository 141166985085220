import React, { useState } from 'react';
import { styled } from 'styled-components';
import Slider from 'react-slick';

import { ICON_IMAGE } from 'assets/icons';
import Text from 'components/common/Text';
import Column from 'components/common/Column';

const EmptyImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  height: 375px;
  background: var(--gray-gray-01, #f7f7f8);
`;

const SliderContainer = styled(Slider)`
  /* display: flex;
  align-items: center; */
  width: 100%;
  height: 100%;
  /* min-height: ${window.innerWidth}px; */
  overflow-x: hidden;

  button {
    display: none !important;
  }

  .slick-track {
    display: flex;
    align-items: center;
    min-width: 375px;
    width: 100%;
  }

  .slick-slide {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;

const ImageThumbnail = styled.img`
  width: 100%;
  min-width: 375px !important;
  height: 375px;
  object-fit: cover;
`;

const ConuntBox = styled.div`
  display: flex;
  gap: 2px;
  position: absolute;
  bottom: 16px;
  padding: 5px 8px;
  border-radius: 16px;
  background: var(--Grayscale-Opacity-Gray-500, rgba(17, 17, 17, 0.44));

  color: #fff;
  text-align: right;

  /* Font/B50 */
  font-family: SUIT;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.12px;
`;

interface ImageContainerIProps {
  imageThumbnail: string[];
}

const ImageContainer: React.FC<ImageContainerIProps> = (props) => {
  const { imageThumbnail } = props;

  const [currentIndex, setCurrentIndex] = useState<number>(1);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    afterChange: (index: number) => setCurrentIndex(index + 1),
  };

  const renderEmptyImageContainer = (): JSX.Element => {
    return (
      <EmptyImageContainer>
        <img src={ICON_IMAGE} alt="icon empty img" />
        <Text size="14px">등록된 이미지 없음</Text>
      </EmptyImageContainer>
    );
  };

  const renderImageSlider = (): JSX.Element => {
    if (imageThumbnail.length === 1) {
      return <ImageThumbnail src={imageThumbnail[0]} alt="image thumbnail" />;
    }

    return (
      <Column style={{ position: 'relative' }}>
        <SliderContainer {...settings}>
          {imageThumbnail.map((item, idx) => {
            return (
              <ImageThumbnail key={idx} src={item} alt="product thumbnail" />
            );
          })}
        </SliderContainer>
        <ConuntBox>
          <span>{currentIndex}</span>
          <span style={{ color: '#ccc' }}>/</span>
          <span style={{ color: '#ccc' }}>{imageThumbnail.length}</span>
        </ConuntBox>
      </Column>
    );
  };

  return (
    <React.Fragment>
      {imageThumbnail.length === 0
        ? renderEmptyImageContainer()
        : renderImageSlider()}
    </React.Fragment>
  );
};

export default ImageContainer;
