import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

import {
  ICON_CLOSE,
  ICON_PRODUCT_FILTER,
  ICON_SETTLEMENT_IMAGE_UPLOAD,
  ICON_SHARE,
} from 'assets/icons';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import Divider from 'components/Divider';
import NavigateForm from 'containers/ProductList/components/NavigateForm';
import { ListingProduct, Product } from 'types/api';
import { useNavigate } from 'react-router-dom';
import { PRODUCT_DETAIL_PATH } from 'constants/path';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';

const ProductThumbnail = styled.img`
  width: 76px;
  height: 76px;
  border-radius: 9.5px;
  border: 1.81px solid rgba(0, 0, 0, 0.1);
`;

const IdBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  border-radius: 4px;
  background: var(--Grayscale-Nomal-Gray-100, #eee);

  color: var(--Grayscale-Nomal-Gray-800, #333);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px; /* 130% */
  letter-spacing: -0.1px;
`;

interface QnaItemBottomSheetIProps {
  type: 'qna' | 'review';
  isMobile: boolean;
  productInfo: Product;
  handleSetProductQnaItem: (productId: string) => void;
  onClose: () => void;
}

const QnaItemBottomSheet: React.FC<QnaItemBottomSheetIProps> = props => {
  const { type, isMobile, productInfo, handleSetProductQnaItem, onClose } =
    props;

  const navigate = useNavigate();

  return (
    <ModalContainer
      isAbled={true}
      isBottomSheet={true}
      isMobile={isMobile}
      onClose={() => {}}
      onClick={() => {}}
      style={{ padding: '16px 20px 40px' }}
    >
      <img
        src={ICON_CLOSE}
        alt="close icon"
        style={{ width: '24px' }}
        onClick={onClose}
      />
      <Column gap="16px" align="flex-start">
        <Row gap="16px">
          <ProductThumbnail
            src={
              isEmpty(productInfo.headerContents)
                ? ICON_SETTLEMENT_IMAGE_UPLOAD
                : productInfo.headerContents[0].content.thumbnail
            }
            alt="product Thumbnail"
          />
          <Column gap="8px" align="flex-start">
            <IdBox>ID {productInfo.id}</IdBox>
            <Text color="#1b1c1e" size="16px" weight={600}>
              {productInfo.title}
            </Text>
          </Column>
        </Row>
        <Row gap="4px">
          <Text color="#46474c" size="14px">
            상품 등록일:
          </Text>
          <Text color="#70737C" size="14px">
            {dayjs(productInfo.createdAt).format('YY년 MM월 DD일')}
          </Text>
          <Text color="#C2C4C8" size="14px">
            |
          </Text>
          <Text color="#46474c" size="14px">
            판매 종료일:
          </Text>
          <Text color="#70737C" size="14px">
            {dayjs(productInfo.saleTerm.endedAt).format('YY년 MM월 DD일')}
          </Text>
        </Row>
      </Column>
      <Divider />
      <NavigateForm
        icon={ICON_PRODUCT_FILTER}
        title={type === 'qna' ? '이 프립 문의만 보기' : '이 프립 후기만 보기'}
        onClick={() => {
          handleSetProductQnaItem(productInfo.id);

          onClose();
        }}
      />
      <NavigateForm
        icon={ICON_SHARE}
        title="이 프립 수정 페이지로 이동하기"
        onClick={() => {
          navigate(`${PRODUCT_DETAIL_PATH}/${productInfo.id}`);
        }}
      />
    </ModalContainer>
  );
};

export default QnaItemBottomSheet;
