import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ICON_ARROW_GRAY_16 } from 'assets/icons';
import CommonImage from 'components/common/CommonImage';
import Text from 'components/common/Text';
import { StandardChildrenEntity } from 'containers/ProductRegist/graphQL/types';
import Column from 'components/common/Column';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 16px 12px;
  width: 100%;

  border-radius: var(--Radius-border-radius-200, 8px);
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);
  cursor: pointer;
`;

const SelectResultForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  position: absolute;
  left: 0;
  top: 60px;
  padding: 16px 20px;
  width: 100%;
  max-height: 290px;
  overflow-y: scroll;
  border-radius: 12px;
  background: var(--Static-White, #fff);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  z-index: 1;

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface CategorySelectFormIProps {
  selectCategoryNum: string;
  categoryValue: string;
  categoryItems: StandardChildrenEntity[] | null;
  handleRefetchStandardCategory: (
    selectNum: string,
    categoryId: string,
    categoryName: string
  ) => void;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
  handleGetStandardCategory?: () => void;
}

const CategorySelectForm: React.FC<CategorySelectFormIProps> = (props) => {
  const {
    selectCategoryNum,
    categoryValue,
    categoryItems,
    handleRefetchStandardCategory,
    handleOnChangeProductParamInput,
    handleGetStandardCategory,
  } = props;

  const [isOpenSelectForm, setIsOpenSelectForm] = useState<boolean>(false);

  const formattingText = (): string => {
    switch (selectCategoryNum) {
      case 'first':
        return '1차';
      case 'second':
        return '2차';
      case 'third':
        return '3차';
      case 'fourth':
        return '4차';
    }

    return '';
  };

  return (
    <Column style={{ position: 'relative' }}>
      <Container
        onClick={() => {
          if (selectCategoryNum === 'first') {
            handleGetStandardCategory();
          }

          if (categoryItems) {
            setIsOpenSelectForm(!isOpenSelectForm);
          }
        }}
      >
        <Text
          color={categoryValue !== '' ? '#46474C' : '#989ba2'}
          size="16px"
          weight={400}
        >
          {categoryValue !== ''
            ? categoryValue
            : `${formattingText()} 카테고리를 설정해 주세요`}
        </Text>
        <CommonImage
          src={ICON_ARROW_GRAY_16}
          onClick={() => {}}
          style={{ rotate: '90deg' }}
        />
      </Container>
      {isOpenSelectForm && (
        <SelectResultForm>
          {categoryItems &&
            categoryItems.map((item: StandardChildrenEntity, idx: number) => {
              return (
                <Text
                  color="#46474C"
                  key={idx}
                  onClick={() => {
                    handleRefetchStandardCategory(
                      selectCategoryNum,
                      item.id,
                      item.label.name
                    );
                    setIsOpenSelectForm(false);

                    if (selectCategoryNum === 'fourth') {
                      handleOnChangeProductParamInput(
                        'standardCategoryId',
                        item.id
                      );
                    }
                  }}
                >
                  {item.label.name}
                </Text>
              );
            })}
        </SelectResultForm>
      )}
    </Column>
  );
};

export default CategorySelectForm;
