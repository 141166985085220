import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import ModifyContainer from '../ModifyContainer';
import {
  ICON_CLOSE,
  ICON_PRODUCTDETAIL_EXCLUSION,
  ICON_PRODUCTDETAIL_INCLUSION,
  ICON_PRODUCTDETAIL_KEYWORD,
  ICON_PRODUCTDETAIL_PREPARE,
} from 'assets/icons';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import { ModifyValue } from '../../hooks/useProductDetaillHooks';

interface AdditionalContainerIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  modifyValue: ModifyValue;
  setModifyValue: React.Dispatch<React.SetStateAction<ModifyValue>>;
}

const AdditionalContainer: React.FC<AdditionalContainerIProps> = (props) => {
  const { isMobile, productParamInput, modifyValue, setModifyValue } = props;

  const fripInfo = productParamInput.frip;

  const isEmptyAdditional =
    isEmpty(fripInfo.exclusions) &&
    isEmpty(fripInfo.inclusions) &&
    isEmpty(fripInfo.stuffsToPrepare);

  const renderExclustion = (): JSX.Element => {
    return (
      <Row align="flex-start" gap="8px">
        <img
          src={ICON_PRODUCTDETAIL_EXCLUSION}
          alt="icon productdetail exclusion"
        />
        <Text
          color="#46474c"
          size="14px"
          weight={400}
          style={{ textWrap: 'wrap' }}
        >
          포함사항:{' '}
          {!isEmpty(fripInfo.inclusions) ? (
            <React.Fragment>
              {fripInfo.inclusions.map((item, idx) => {
                return (
                  <span key={idx}>
                    {item} {fripInfo.inclusions.length !== idx + 1 && '• '}
                  </span>
                );
              })}
            </React.Fragment>
          ) : (
            <span style={{ color: '#989BA2', fontWeight: 400 }}>없음</span>
          )}
        </Text>
      </Row>
    );
  };

  const renderInclusion = (): JSX.Element => {
    return (
      <Row align="flex-start" gap="8px">
        <img
          src={ICON_PRODUCTDETAIL_INCLUSION}
          alt="icon productdetail exclusion"
        />
        <Text color="#46474c" size="14px" weight={400}>
          불포함사항:{' '}
          {!isEmpty(fripInfo.exclusions) ? (
            <React.Fragment>
              {fripInfo.exclusions.map((item, idx) => {
                return (
                  <span key={idx}>
                    {item} {fripInfo.exclusions.length !== idx + 1 && '• '}
                  </span>
                );
              })}
            </React.Fragment>
          ) : (
            <span style={{ color: '#989BA2', fontWeight: 400 }}>없음</span>
          )}
        </Text>
      </Row>
    );
  };

  const renderPrepare = (): JSX.Element => {
    return (
      <Row align="flex-start" gap="8px">
        <img
          src={ICON_PRODUCTDETAIL_PREPARE}
          alt="icon productdetail exclusion"
        />
        <Text
          color="#46474c"
          size="14px"
          weight={400}
          style={{ minWidth: '42px' }}
        >
          준비물:{' '}
          {!isEmpty(fripInfo.stuffsToPrepare) ? (
            <React.Fragment>
              {fripInfo.stuffsToPrepare.map((item, idx) => {
                return (
                  <span key={idx}>
                    {item} {fripInfo.stuffsToPrepare.length !== idx + 1 && '• '}
                  </span>
                );
              })}
            </React.Fragment>
          ) : (
            <span style={{ color: '#989BA2', fontWeight: 400 }}>없음</span>
          )}
        </Text>
      </Row>
    );
  };

  const renderKeyword = (): JSX.Element => {
    return (
      <Row align="flex-start" gap="8px">
        <img
          src={ICON_PRODUCTDETAIL_KEYWORD}
          alt="icon productdetail keyword"
        />
        <Text color="#46474c" size="14px" weight={400}>
          검색 키워드:{' '}
          {!isEmpty(productParamInput.keywords) ? (
            <React.Fragment>
              {productParamInput.keywords.map((item, idx) => {
                return (
                  <span key={idx}>
                    {item}{' '}
                    {productParamInput.keywords.length !== idx + 1 && '• '}
                  </span>
                );
              })}
            </React.Fragment>
          ) : (
            <span style={{ color: '#989BA2', fontWeight: 400 }}>없음</span>
          )}
        </Text>
      </Row>
    );
  };

  return (
    <ModifyContainer
      title="추가 정보"
      isSelected={!isMobile && modifyValue.modifyStatus === 'additional'}
      modifyText={isEmptyAdditional ? '추가정보 등록하기' : '수정하기'}
      onClick={() => {
        setModifyValue({ ...modifyValue, modifyStatus: 'additional' });
      }}
    >
      <Column align="flex-start" gap="12px">
        {isEmptyAdditional && (
          <Column>
            <Text color="#46474C" weight={400}>
              등록한 추가정보가 없어요.
            </Text>
            <Text color="#46474C" weight={400}>
              크루들이 호스트님의 프립을 더 잘 이해할 수 있도록
            </Text>
            <Text color="#46474C" weight={400}>
              추가정보를 등록해 보세요.
            </Text>
          </Column>
        )}
        {renderPrepare()}
        {renderExclustion()}
        {renderInclusion()}
        {renderKeyword()}
      </Column>
    </ModifyContainer>
  );
};

export default AdditionalContainer;
