import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { cloneDeep, isEmpty } from 'lodash';

import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import Divider from 'components/Divider';
import {
  InquiryTemplate,
  ItemInquiryInputKind,
  ItemInquiryInputPattern,
  Product,
  ProductState,
} from 'types/api';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import { Inquiries } from 'containers/ProductRegist/components/InformationRegist/graphQL/types';
import DashButton from 'components/Button/DashButton';
import ModalContainer from 'containers/ProductRegist/components/common/ModalContainer';
import { ModifyValue } from 'containers/ProductDetail/hooks/useProductDetaillHooks';
import QuestionModifyModal from './QuestionModifyModal';
import { useQuestionModifyHooks } from './hooks/useQuestionModifyHooks';
import SaveButton from '../SaveButton';
import HackleManager from 'utils/hackle';
import OptionSelectBox from 'containers/ProductRegist/components/common/OptionSelectBox';

const QuestionForm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;

  border-radius: 8px;
  border: 1px solid #dbdcdf;
  background: #fff;
`;

const RegistButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  width: 100%;

  border-radius: 8px;
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);

  color: var(--gray-gray-09, #1b1c1e);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.14px;
`;
interface QuestionModifyFormIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  productInfo: Product;
  inquiryList: InquiryTemplate;
  modifyValue: ModifyValue;
  setModifyValue: Dispatch<SetStateAction<ModifyValue>>;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
  handleRefetchInquiryTemplate: (id: string) => void;
}

const QuestionModifyForm: React.FC<QuestionModifyFormIProps> = props => {
  const {
    isMobile,
    inquiryList,
    productParamInput,
    productInfo,
    modifyValue,
    setModifyValue,
    handleOnChangeProductParamInput,
    handleRefetchInquiryTemplate,
  } = props;

  const {
    inquiryTemplate,
    inquiries,
    inputPattern,
    isOpenQuestionModifyModal,
    modifyInquiriesIdx,
    isAbled,
    setInquiries,
    handleSetInquiries,
    handleSetInquiryTemplate,
    setModifyInquiresIdx,
    handleCreateInquiryTemplate,
    handleModifyInquiryTemplates,
    handleDeleteInquiryTemplate,
    handleUpdateInquiryTemplate,
    handleOpenQuestionModifyModal,
    handleResetInquiries,
    handleResetQuestion,
    handleSetItemInputPattern,
  } = useQuestionModifyHooks(inquiryList);

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isUpdateInquiryTemplates, setIsUpdateInquiryTemplates] =
    useState<boolean>(false);

  const handleAdjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleReigstQuestion = () => {
    if (productParamInput.inquiryTemplateId === null) {
      handleCreateInquiryTemplate(inquiryTemplate, (id: string) => {
        handleRefetchInquiryTemplate(id);
        handleOnChangeProductParamInput('inquiryTemplateId', id);
      });

      setIsUpdateInquiryTemplates(false);
      return;
    }

    handleUpdateInquiryTemplate(
      productParamInput.inquiryTemplateId,
      inquiryTemplate,
      () => {
        handleRefetchInquiryTemplate(productParamInput.inquiryTemplateId);
        handleOnChangeProductParamInput(
          'inquiryTemplateId',
          productParamInput.inquiryTemplateId,
        );
      },
    );

    setIsUpdateInquiryTemplates(false);
  };

  useEffect(() => {
    if (isUpdateInquiryTemplates) {
      handleReigstQuestion();
    }
  }, [isUpdateInquiryTemplates]);

  useEffect(() => {
    HackleManager.logProductModifyParticipantInfoView(
      Number(productInfo.id),
      productInfo.title,
    );

    handleAdjustTextareaHeight();
  }, []);

  const renderQuestionModifyModal = (): JSX.Element => {
    return (
      <ModalContainer
        isMobile={isMobile}
        isAbled={true}
        onClose={() => {
          handleOpenQuestionModifyModal(false);
        }}
        onClick={() => {
          if (modifyInquiriesIdx !== null) {
            handleModifyInquiryTemplates();

            setModifyInquiresIdx(null);
          } else {
            handleSetInquiryTemplate('inquiries', [
              ...inquiryTemplate.inquiries,
              inquiries,
            ]);
          }

          handleOpenQuestionModifyModal(false);
          handleResetInquiries();
        }}
        modalStyle={{ overflow: 'auto' }}
      >
        <QuestionModifyModal
          isMobile={isMobile}
          inquiries={inquiries}
          setInquiries={setInquiries}
          handleSetInquiries={handleSetInquiries}
        />
      </ModalContainer>
    );
  };

  const renderInquiryKind = (item: Inquiries): JSX.Element => {
    switch (item.inputKind) {
      case ItemInquiryInputKind.TEXT: {
        return (
          <Column gap="4px" align="flex-start">
            <Text color="#989ba2">답변 유형</Text>
            <Text color="#1b1c1e" size="16px" weight={400}>
              주관식
            </Text>
          </Column>
        );
      }
      default: {
        return (
          <React.Fragment>
            {!isEmpty(item.items) && (
              <Column gap="2px" align="flex-start">
                {item.items.map((selectItem, selectIdx) => {
                  return (
                    <Column gap="4px" align="flex-start" key={selectIdx}>
                      <Text color="#989ba2">객관식 답변{selectIdx + 1}</Text>
                      <Text color="#1b1c1e" size="16px" weight={400}>
                        {selectItem.label}
                      </Text>
                    </Column>
                  );
                })}
              </Column>
            )}
          </React.Fragment>
        );
      }
    }
  };

  const renderInquiryInputPattern = (): JSX.Element => {
    return (
      <Row gap="8px" style={{ width: '100%' }}>
        <OptionSelectBox
          title="1개 이상 답변 받기"
          description="구매 옵션의 개수만큼 답변을 받을게요"
          isSelected={inputPattern === ItemInquiryInputPattern.PER_ITEM_COUNT}
          onClick={() => {
            handleSetItemInputPattern(ItemInquiryInputPattern.PER_ITEM_COUNT);
          }}
        />
        <OptionSelectBox
          title="1개만 답변 받기"
          description="구매 옵션의 개수와 상관 없이 1개의 답변을 받을게요"
          isSelected={inputPattern === ItemInquiryInputPattern.PER_PRODUCT}
          onClick={() => {
            handleSetItemInputPattern(ItemInquiryInputPattern.PER_PRODUCT);
          }}
        />
      </Row>
    );
  };

  const renderInquiryForm = (): JSX.Element => {
    if (isEmpty(inquiryTemplate.inquiries)) {
      return (
        <Column gap="16px" align="flex-start">
          {renderInquiryInputPattern()}
          <RegistButton onClick={() => handleOpenQuestionModifyModal(true)}>
            등록
          </RegistButton>
          <Row gap="8px">
            {/* <Text color="#1b1c1e" size="16px">
              질문
            </Text> */}
            <Text color="#989BA2" size="14px" weight={400}>
              {`예시) 이전에 활동 경력이 있으신가요?`}
            </Text>
          </Row>
        </Column>
      );
    }

    return (
      <Column gap="24px">
        {renderInquiryInputPattern()}
        {inquiryTemplate.inquiries.map((inquiryItem, inquiryIdx) => {
          return (
            <QuestionForm>
              <Column gap="12px" align="flex-start" key={inquiryIdx}>
                <Column gap="4px" align="flex-start">
                  <Row justifyContent="space-between" style={{ width: '100%' }}>
                    <Text color="#989BA2" style={{ minWidth: '42px' }}>
                      질문{inquiryIdx + 1}
                    </Text>
                  </Row>
                  <Text color="#1B1C1E" size="16px" weight={400}>
                    {inquiryItem.title}
                  </Text>
                </Column>
                <Divider />
                {renderInquiryKind(inquiryItem)}
                <Divider />
                <Column gap="4px" align="flex-start">
                  <Text color="#989BA2">답변 필수 여부</Text>
                  <Text color="#1B1C1E" size="16px" weight={400}>
                    {inquiryItem.required
                      ? '필수로 받을게요'
                      : '필수가 아니에요'}
                  </Text>
                </Column>
                <Row
                  gap="8px"
                  justifyContent="flex-end"
                  style={{ width: '100%' }}
                >
                  <Text
                    color="#FF4242"
                    onClick={() => {
                      handleDeleteInquiryTemplate(inquiryIdx, inquiries.id);
                    }}
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                  >
                    삭제
                  </Text>
                  <Text color="#C2C4C8">|</Text>
                  <Text
                    color="#70737C"
                    onClick={() => {
                      handleOpenQuestionModifyModal(true);
                      setInquiries(inquiryItem);
                      setModifyInquiresIdx(inquiryIdx);
                    }}
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                  >
                    수정하기
                  </Text>
                </Row>
              </Column>
            </QuestionForm>
          );
        })}
        <DashButton
          text="질문 추가"
          onClick={() => {
            handleOpenQuestionModifyModal(true);
            handleResetInquiries();
          }}
        />
      </Column>
    );
  };

  const renderContents = (): JSX.Element => {
    if (isMobile) {
      return (
        <ModalContainer
          isMobile={isMobile}
          isAbled={productInfo.status !== ProductState.CLOSED && isAbled}
          onClose={() => {
            setModifyValue({ ...modifyValue, modifyStatus: '' });
          }}
          onCloseText="닫기"
          onAcceptText="적용"
          onClick={() => {
            HackleManager.logProductModifyItemSaveComplete(
              Number(productInfo.id),
              productInfo.title,
              '질문',
            );
            handleReigstQuestion();

            setModifyValue({
              ...modifyValue,
              isModify: true,
              modifyStatus: '',
            });
          }}
        >
          <Column gap="24px" style={{ padding: '0 20px' }}>
            <Row
              style={{
                position: 'sticky',
                top: 0,
                padding: '16px 20px',
                width: '100%',
                background: '#fff',
              }}
            >
              <Text color="#1b1c1e" size="16px" weight={600}>
                질문 수정하기
              </Text>
            </Row>
            {renderInquiryForm()}
          </Column>
          {isOpenQuestionModifyModal && renderQuestionModifyModal()}
        </ModalContainer>
      );
    }

    return (
      <Column>
        <Row
          justifyContent="flex-start"
          style={{
            position: 'sticky',
            top: 0,
            padding: '32px 40px',
            width: '100%',
            background: '#fff',
            borderBottom: '1px solid #eaebec',
          }}
        >
          <Text color="#1B1C1E" size="24px">
            수정하기
          </Text>
        </Row>
        <Column gap="24px" style={{ padding: '32px 40px' }}>
          {renderInquiryForm()}
          {productInfo.status !== ProductState.CLOSED && (
            <SaveButton
              isAbled={isAbled}
              modifyValue={modifyValue}
              setModifyValue={setModifyValue}
              handleResetItem={handleResetQuestion}
              handleRegistItem={() => {
                HackleManager.logProductModifyItemSaveComplete(
                  Number(productInfo.id),
                  productInfo.title,
                  '질문',
                );
                handleReigstQuestion();
              }}
            />
          )}
        </Column>
        {isOpenQuestionModifyModal && renderQuestionModifyModal()}
      </Column>
    );
  };

  return renderContents();
};

export default QuestionModifyForm;
