import { PlanParam } from 'containers/ProductRegist/hooks/types';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

export enum RepeatDateType {
  DAY = 'day',
  WEEKDAY = 'weekday',
  MONTH = 'month',
}

export const useSelectDateHooks = () => {
  const [planParam, setPlanParam] = useState<PlanParam>({
    targetItemParamIds: [],
    appliedAllItems: true,
    minimumQuota: 1,
    quota: 10,
    term: {
      startedAt: 0,
      endedAt: 0,
    },
    name: '시간대',
    overNight: false,
    weeks: [],
    preparatoryPeriod: 0,
    paramId: `plan${Math.random()}`,
    times: [
      {
        starting: {
          hour: 0,
          minute: 0,
        },
        duration: 0,
      },
    ],
  });
  const [repeatDateType, setRepeatDateType] = useState<RepeatDateType>(
    RepeatDateType.WEEKDAY
  );
  const [repeatTerm, setRepeatTerm] = useState<number | 'select'>(3);

  const handleSetPlanParams = (key: string, value: any) => {
    setPlanParam({ ...planParam, [key]: value });
  };

  const handleSetRepeatDateType = (dateType: RepeatDateType) => {
    setRepeatDateType(dateType);
  };

  const handleSetRepeatTerm = (term: number | 'select') => {
    setRepeatTerm(term);
  };

  const handleResetPlanParam = () => {
    setPlanParam({
      targetItemParamIds: [],
      appliedAllItems: true,
      minimumQuota: 1,
      quota: 10,
      term: {
        startedAt: 0,
        endedAt: 0,
      },
      name: '시간대',
      overNight: false,
      weeks: [],
      preparatoryPeriod: 0,
      paramId: `plan${Math.random()}`,
      times: [
        {
          starting: {
            hour: 0,
            minute: 0,
          },
          duration: 0,
        },
      ],
    });
  };

  useEffect(() => {
    if (planParam.term.startedAt !== 0) {
      const endDate = new Date(planParam.term.startedAt);

      if (repeatTerm !== 'select') {
        endDate.setMonth(endDate.getMonth() + repeatTerm);

        setPlanParam({
          ...planParam,
          term: { ...planParam.term, endedAt: endDate.getTime() },
        });
      }
    }
  }, [repeatTerm, planParam.term.startedAt]);

  return {
    planParam,
    repeatDateType,
    repeatTerm,
    handleSetPlanParams,
    handleSetRepeatDateType,
    handleSetRepeatTerm,
    handleResetPlanParam,
  };
};
