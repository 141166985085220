import Column from "components/common/Column";
import Row from "components/common/Row";
import Text from "components/common/Text";
import React from "react";
import styled from "styled-components";

const Container = styled.div<{ $isChecked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 16px;
  width: 100%;

  border-radius: 12px;
  border: 1px solid ${(props) => (props.$isChecked ? "#1b1c1e" : "#dbdcdf")};
  background: ${(props) => (props.$isChecked ? "#F7F7F8" : "#fff")};

  cursor: pointer;
`;

const CheckBox = styled.input.attrs({ type: "checkbox" })`
  width: 24px;
  height: 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff; /* 기본 배경색 */
  border: 1px solid #ddd; /* 테두리 */
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: 5px;

  &:checked {
    background-color: #000;
  }

  &:checked::before {
    content: "✓";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
`;

interface RegistCheckBoxFormIProps {
  title: string;
  description: string;
  isChecked: boolean;
  onClick: () => void;
}

const RegistCheckBoxForm: React.FC<RegistCheckBoxFormIProps> = (props) => {
  const { title, description, isChecked, onClick } = props;

  return (
    <Container $isChecked={isChecked} onClick={onClick}>
      <Row gap="12px" justifyContent="space-between" style={{ width: "100%" }}>
        <Column gap="4px" align="flex-start">
          <Text color="#1b1c1e" size="16px">
            {title}
          </Text>
          <Text color="#70737C" size="13px" weight={400}>
            {description}
          </Text>
        </Column>
        <CheckBox
          type="checkbox"
          checked={isChecked}
          defaultChecked={isChecked}
          onChange={onClick}
        />
      </Row>
    </Container>
  );
};

export default RegistCheckBoxForm;
