import { GET_QNA_LIST } from './../index';
import { useQuery } from '@apollo/client';
import { MeData } from 'graphQL/Auth/types';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import { ProductQnaConnection } from 'types/api';
import { useEffect, useState } from 'react';
import { QnaFilter } from 'containers/QnaList/hooks/useQnaListHooks';
import { errorHandler } from 'utils/ErrorHandler';
import { ProductQnaState } from '../types';
import { useLocation } from 'react-router-dom';

export function useQueryQnaList() {
  const myInfo: MeData = useUserInformation((state: any) => state.userInfo);
  const location = useLocation();
  const productId = location.search.split('=')[1];

  const [qnaList, setQnaList] = useState<ProductQnaConnection>();
  const [qnaFilter, setQnaFilter] = useState<QnaFilter>({
    filter: {
      hostIds: [myInfo.me.host.id],
      productIds: productId ? [productId] : null,
      isAnswering: true,
      statusIn: [
        ProductQnaState.NEW,
        ProductQnaState.BLIND,
        ProductQnaState.CONFIRM,
        ProductQnaState.COMPLETE,
      ],
    },
    page: 1,
    size: 10,
  });

  const { data, refetch } = useQuery<{
    qna: { productQnas: ProductQnaConnection };
  }>(GET_QNA_LIST, {
    variables: qnaFilter,
  });

  const handleRefetchQnaList = async (filterItem: QnaFilter) => {
    try {
      const response = await refetch(filterItem);

      if (response) {
        setQnaList(response.data.qna.productQnas);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (data) {
      setQnaList(data.qna.productQnas);
    }
  }, [data]);

  return { qnaList, qnaFilter, setQnaFilter, handleRefetchQnaList };
}
