import React, { useCallback, useEffect, useRef } from 'react';
import { Map, MapMarker } from 'react-kakao-maps-sdk';

import { FripLocation } from 'types/api';
import Column from 'components/common/Column';
import { ICON_ADDRESS_BLACK } from 'assets/icons';
import Text from 'components/common/Text';
import Row from 'components/common/Row';
import { FripLocationParam } from 'containers/ProductRegist/hooks/types';
import GoogleMap from 'containers/ProductRegist/components/BasicInformation/components/GoogleMap';

interface AddressFormIProps {
  item: FripLocationParam;
}

const AddressForm: React.FC<AddressFormIProps> = props => {
  const { item } = props;

  return (
    <Column gap="12px" align="flex-start" style={{ zIndex: 0 }}>
      {item.overseas ? (
        <GoogleMap item={item} />
      ) : (
        <Map
          draggable={false}
          zoomable={false}
          center={{
            lat: item.geoPoint!.latitude,
            lng: item.geoPoint!.longitude,
          }}
          style={{ width: '100%', height: '185px', borderRadius: '8px' }}
        >
          <MapMarker
            position={{
              lat: item.geoPoint!.latitude,
              lng: item.geoPoint!.longitude,
            }}
          />
        </Map>
      )}
      <Row gap="8px">
        <img src={ICON_ADDRESS_BLACK} alt="icon address" />
        <Text color="#46474c">{item.address}</Text>
      </Row>
    </Column>
  );
};

export default AddressForm;
