import React, { useEffect } from 'react';
import styled from 'styled-components';

import FripRegistButton from '../common/FripRegistButton';
import RegistCheckBoxForm from '../common/RegistCheckBoxForm';

import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import { ProductKind } from 'types/api';
import { useParams } from 'react-router-dom';
import HackleManager from 'utils/hackle';

const SelectOnlineWay = styled.div<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;

  width: 100%;

  border-radius: 12px;
  border: ${props =>
    props.$isSelected ? '1.5px solid #1b1c1e' : '1px solid #dbdcdf'};
  background: ${props => (props.$isSelected ? '#f7f7f8' : '#fff')};

  color: #1b1c1e;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.6px;
  cursor: pointer;
`;

const RadioBox = styled.input`
  width: 24px;
  height: 24px;
  appearance: none;
  border: 3px solid #eee;
  border-radius: 50%;
  cursor: pointer;

  &:checked {
    border: 8px solid #1b1c1e;
  }
`;

interface ChapterThreeIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  setProductParamInput: React.Dispatch<React.SetStateAction<ProductParamInput>>;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
}

const ChapterThree: React.FC<ChapterThreeIProps> = props => {
  const {
    isMobile,
    productParamInput,
    setProductParamInput,
    handleOnChangeProductParamInput,
  } = props;

  const params = useParams<{ id: string }>();

  const handleChangeProductParam = (value: string) => {
    if (productParamInput.attributeIds.includes(value)) {
      const filterItem = productParamInput.attributeIds.filter(
        item => item !== value,
      );

      handleOnChangeProductParamInput('attributeIds', filterItem);

      return;
    }

    handleOnChangeProductParamInput('attributeIds', [
      ...productParamInput.attributeIds,
      value,
    ]);
  };

  useEffect(() => {
    HackleManager.logCreateStepOneHostMethodView(
      params.id !== 'draft' ? Number(params.id) : undefined,
    );
  }, []);

  const renderResponsivePage = (): JSX.Element => {
    if (isMobile) {
      return (
        <React.Fragment>
          <Text size="24px" color="#1b1c1e" style={{ lineHeight: '32px' }}>
            어떤 방법으로 진행 할까요?
          </Text>
          <Column gap="12px">{renderProcessWay()}</Column>
        </React.Fragment>
      );
    }

    return (
      <Row gap="64px" style={{ padding: '32px 0', width: '100%' }}>
        <Column gap="32px" align="flex-start">
          <Text size="24px" color="#1b1c1e" style={{ lineHeight: '32px' }}>
            어떤 방법으로 진행 할까요?
          </Text>
          <Column gap="12px">{renderProcessWay()}</Column>
        </Column>
      </Row>
    );
  };

  const renderProcessWay = (): JSX.Element => {
    if (productParamInput.kind !== ProductKind.GOODS) {
      return (
        <React.Fragment>
          <SelectOnlineWay
            $isSelected={productParamInput.kind === ProductKind.OFFLINE}
            onClick={() => {
              setProductParamInput({
                ...productParamInput,
                // standardCategoryId: '0001',
                kind: ProductKind.OFFLINE,
              });

              if (productParamInput.kind === ProductKind.OFFLINE) {
                setProductParamInput({
                  ...productParamInput,
                  standardCategoryId: '',
                  kind: null,
                });

                return;
              }
            }}
          >
            <Column gap="4px" align="flex-start">
              <Text color="#1b1c1e" size="16px">
                오프라인
              </Text>
              <Text color="#70737C" size="13px" weight={400}>
                직접 만나서 대면으로 진행하는 프립
              </Text>
            </Column>
            <RadioBox
              type="radio"
              checked={productParamInput.kind === ProductKind.OFFLINE}
              onChange={() => {
                setProductParamInput({
                  ...productParamInput,
                  // standardCategoryId: '0001',
                  kind: ProductKind.OFFLINE,
                });

                if (productParamInput.kind === ProductKind.OFFLINE) {
                  setProductParamInput({
                    ...productParamInput,
                    standardCategoryId: '',
                    kind: null,
                  });

                  return;
                }
              }}
            />
          </SelectOnlineWay>
          <SelectOnlineWay
            $isSelected={productParamInput.kind === ProductKind.ONLINE}
            onClick={() => {
              setProductParamInput({
                ...productParamInput,
                // standardCategoryId: '0002',
                kind: ProductKind.ONLINE,
              });

              if (productParamInput.kind === ProductKind.ONLINE) {
                setProductParamInput({
                  ...productParamInput,
                  standardCategoryId: '',
                  kind: null,
                });
              }
            }}
          >
            <Column gap="4px" align="flex-start">
              <Text color="#1b1c1e" size="16px">
                온라인
              </Text>
              <Text color="#70737C" size="13px" weight={400}>
                화상 채팅, 전화, 채팅 등을 통해 비대면으로 진행하는 프립
              </Text>
            </Column>
            <RadioBox
              type="radio"
              checked={productParamInput.kind === ProductKind.ONLINE}
              onChange={() => {
                setProductParamInput({
                  ...productParamInput,
                  // standardCategoryId: '0002',
                  kind: ProductKind.ONLINE,
                });

                if (productParamInput.kind === ProductKind.ONLINE) {
                  setProductParamInput({
                    ...productParamInput,
                    standardCategoryId: '',
                    kind: null,
                  });
                }
              }}
            />
          </SelectOnlineWay>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <RegistCheckBoxForm
            title="완제품"
            description="완성된 상태로 배송되는 제품 (떡 4종 세트, 소스 3종 등)"
            isChecked={productParamInput.attributeIds.includes('9')}
            onClick={() => {
              handleChangeProductParam('9');
            }}
          />
          <RegistCheckBoxForm
            title="DIY 키트"
            description="고객이 직접 완성해야하는 제품 (프랑수 자수 키트 등)"
            isChecked={productParamInput.attributeIds.includes('10')}
            onClick={() => {
              handleChangeProductParam('10');
            }}
          />
          <RegistCheckBoxForm
            title="밀키트"
            description="고객이 조리해야 하는 밀키트 제품"
            isChecked={productParamInput.attributeIds.includes('12')}
            onClick={() => {
              handleChangeProductParam('12');
            }}
          />
        </React.Fragment>
      );
    }
  };

  return renderResponsivePage();
};

export default ChapterThree;
