const ICON_FRIP_LOGO = require('./icon_frip_logo.svg').default;
const ICON_CLOSE = require('./icon_close.svg').default;
const ICON_ARROW_GRAY_16 = require('./icon_arrow_gray_16.svg').default;
const ICON_ARROW_GRAY_BOLD = require('./icon_arrow_gray_bold.svg').default;
const ICON_ARROW_BLACK_14 = require('./icon_arrow_black_14.svg').default;
const ICON_ARROW_BLACK = require('./icon_arrow_black.svg').default;
const ICON_ARROW_LEFT = require('./icon_arrow_left.svg').default;
const ICON_ARROW_BLACK_BOTTOM =
  require('./icon_arrow_black_bottom.svg').default;
const ICON_ARROW_BLACK_TOP = require('./icon_arrow_black_top.svg').default;
const ICON_ARROW_BLUE = require('./icon_arrow_blue.svg').default;
const ICON_PLUS_GRAY = require('./icon_plus_gray.svg').default;
const ICON_PLUS_BLACK = require('./icon_plus_black.svg').default;
const ICON_MENU_BUTTON = require('./icon_menu_button.svg').default;
const ICON_HEART_BLACK = require('./icon_heart_black.svg').default;
const ICON_EMAIL_BLACK = require('./icon_email_black.svg').default;
const ICON_NOTICE_BLACK = require('./icon_notice.svg').default;
const ICON_NOTICE_RED = require('./icon_notice_red.svg').default;
const ICON_NOTICE_BLUE = require('./icon_notice_blue.svg').default;
const ICON_SHARE = require('./icon_share.svg').default;
const ICON_PHONE = require('./icon_phone.svg').default;
const ICON_NAVIGATE_BACK = require('./icon_navigate_back.svg').default;
const ICON_PROFILE_DEFAULT = require('./icon_profile_default.svg').default;
const ICON_STAR_YELLOW = require('./icon_star_yellow.svg').default;
const ICON_SHOW_EYE = require('./icon_show_eye.svg').default;
const ICON_SWITCH = require('./icon_switch.svg').default;
const ICON_REFRESH = require('./icon_refresh.svg').default;
const ICON_COPY_GRAY = require('./icon_copy_gray.svg').default;
const ICON_MESSAGE_BLACK = require('./icon_message_black.svg').default;
const ICON_LOCATION = require('./icon_location.svg').default;

// Menu
const ICON_MENU_HOME = require('./icon_menu_home.svg').default;
const ICON_MENU_CALENDAR = require('./icon_menu_calendar.svg').default;
const ICON_MENU_LIST = require('./icon_menu_list.svg').default;
const ICON_MENU_MESSAGE = require('./icon_menu_message.svg').default;
const ICON_MENU_MENU = require('./icon_menu_menu.svg').default;
const ICON_MENU_SALE = require('./icon_menu_sale.svg').default;
const ICON_MENU_HOME_ACTIVE = require('./icon_menu_home_active.svg').default;
const ICON_MENU_CALENDAR_ACTIVE =
  require('./icon_menu_calendar_active.svg').default;
const ICON_MENU_LIST_ACTIVE = require('./icon_menu_list_active.svg').default;
const ICON_MENU_MESSAGE_ACTIVE =
  require('./icon_menu_message_active.svg').default;
const ICON_MENU_MENU_ACTIVE = require('./icon_menu_menu_active.svg').default;
const ICON_MENU_SALE_ACTIVE = require('./icon_menu_sale_active.svg').default;
const ICON_SETTLEMENT_REQUEST =
  require('./icon_settlement_request.svg').default;
const ICON_SETTLEMENT_LIST = require('./icon_settlement_list.svg').default;
const ICON_MENU_QNA = require('./icon_menu_qna.svg').default;
const ICON_MENU_REVIEW = require('./icon_menu_review.svg').default;
const ICON_MENU_HEADPHONE = require('./icon_menu_headphone.svg').default;
const ICON_MENU_DELIVERY = require('./icon_menu_delivery.svg').default;
const ICON_MENU_INDIVIDUAL = require('./icon_menu_individual.svg').default;
const ICON_MENU_PRODUCT = require('./icon_menu_product.svg').default;
const ICON_MENU_SALE_BLACK = require('./icon_menu_sale_black.svg').default;
const ICON_MENU_BOOKING = require('./icon_menu_booking.svg').default;
const ICON_MENU_PROFILE = require('./icon_menu_profile.svg').default;
const ICON_MENU_LOGOUT = require('./icon_menu_logout.svg').default;
const ICON_MENU_CONCIERGE = require('./icon_menu_concierge.svg').default;
const ICON_MENU_QUESTION = require('./icon_menu_question.svg').default;
const ICON_MENU_NOTICE = require('./icon_menu_notice.svg').default;

const ICON_AUTH_CLOSE_BUTTON = require('./icon_auth_close_button.svg').default;
const ICON_SOCIAL_LOGIN_KAKAO =
  require('./icon_social_login_kakao.svg').default;
const ICON_SOCIAL_LOGIN_FACEBOOK =
  require('./icon_social_login_facebook.svg').default;
const ICON_TERM_CHECKED_DISABLE =
  require('./icon_term_checked_disable.svg').default;
const ICON_TERM_CHECKED_ABLE = require('./icon_term_checked_able.svg').default;
const ICON_SIGNUP_TERMCHECKED =
  require('./icon_signup_termChecked.svg').default;
const ICON_SIGNUP_TERMCHECKED_DISABLED =
  require('./icon_signup_termChecked_disable.svg').default;
const ICON_CHECK_COMPELETE = require('./icon_check_complete.svg').default;
const ICON_QUESTION = require('./icon_question.svg').default;
const ICON_QUESTION_RED = require('./icon_question_red.svg').default;
const ICON_ADDRESS = require('./icon_address.svg').default;
const ICON_ADDRESS_BLACK = require('./icon_address_black.svg').default;
const ICON_CATEGORY_SEARCH = require('./icon_category_search.svg').default;
const ICON_CATEGORY_SEARCH_ACTIVE =
  require('./icon_category_search_active.svg').default;
const ICON_IMAGE = require('./icon_image.svg').default;
const ICON_CALENDAR = require('./icon_calendar.svg').default;
const ICON_PROFILE_CAMERA = require('./icon_profile_camera.svg').default;

//Settlement
const ICON_SETTLEMENT_REGIST_REQUEST =
  require('./icon_settlement_regist_request.svg').default;
const ICON_SETTLEMENT_BANK = require('./icon_settlement_bank.svg').default;
const ICON_SETTLEMENT_ALERT = require('./icon_settlement_alert.svg').default;
const ICON_SETTLEMENT_VALIDATION =
  require('./icon_settlement_validation.svg').default;
const ICON_SETTLEMENT_IMAGE_UPLOAD =
  require('./icon_settlement_image_upload.svg').default;
const ICON_SETTLEMENT_ARROW_LEFT =
  require('./icon_settlement_arrow_left.svg').default;
const ICON_SETTLEMENT_ARROW_RIGHT =
  require('./icon_settlement_arrow_right.svg').default;
const ICON_SETTLEMENT_ARROW_LEFT_DISABLE =
  require('./icon_settlement_arrow_left_disable.svg').default;
const ICON_SETTLEMENT_ARROW_RIGHT_DISABLE =
  require('./icon_settlement_arrow_right_disable.svg').default;

// Product Regist
const ICON_PRODUCT_HOSTING_SERVICE =
  require('./icon_product_hosting_service.svg').default;
const ICON_PRODUCT_HOSTING_CLASS =
  require('./icon_product_hosting_class.svg').default;
const ICON_PRODUCT_HOSTING_TOUR =
  require('./icon_product_hosting_tour.svg').default;
const ICON_PRODUCT_HOSTING_MEET =
  require('./icon_product_hosting_meet.svg').default;
const ICON_PRODUCT_HOSTING_SHIP =
  require('./icon_product_hosting_ship.svg').default;
const ICON_PRODUCT_DELETE = require('./icon_product_delete.svg').default;
const ICON_PRODUCT_SETTING = require('./icon_product_setting.svg').default;
const ICON_PRODUCT_SELECT = require('./icon_product_select.svg').default;
const ICON_PRODUCT_SELECT_DISABLE =
  require('./icon_product_select_disable.svg').default;
const ICON_PRODUCT_CAMERA = require('./icon_product_camera.svg').default;
const ICON_PRODUCT_CHECKMARK = require('./icon_product_checkmark.svg').default;
const ICON_PRODUCT_CHECKMARK_BLACK =
  require('./icon_product_checkmark_black.svg').default;
const ICON_PRODUCT_DELETE_RED =
  require('./icon_product_delete_red.svg').default;
const ICON_PRODUCT_CLIPBOARD = require('./icon_product_clipboard.svg').default;
const ICON_PRODUCT_FILTER = require('./icon_product_filter.svg').default;
const ICON_PRODUCT_FILTER_DISABLE =
  require('./icon_product_filter_disable.svg').default;
const ICON_PRODUCT_LIST = require('./icon_product_list.svg').default;
const ICON_PRODUCT_STAR = require('./icon_product_star.svg').default;
const ICON_PRODUCT_EDIT = require('./icon_product_edit.svg').default;
const ICON_PRODUCT_SCHEDULE_SELECT =
  require('./icon_product_schedule_select.svg').default;
const ICON_PRODUCT_SCHEDULE_CHOICE =
  require('./icon_product_schedule_choice.svg').default;
const ICON_PRODUCT_IMAGE_UPLOAD =
  require('./icon_product_image_upload.svg').default;

const ICON_PRODUCTLIST_EMPTY = require('./icon_productlist_empty.svg').default;
const ICON_PRODUCTLIST_COPY = require('./icon_productlist_copy.svg').default;
const ICON_PRODUCTLIST_REGIST =
  require('./icon_productlist_regist.svg').default;

// Product Detail
const ICON_PRODUCTDETAIL_LEVEL =
  require('./icon_productdetail_level.svg').default;
const ICON_PRODUCTDETAIL_PEOPLE =
  require('./icon_productdetail_people.svg').default;
const ICON_PRODUCTDETAIL_AGE = require('./icon_productdetail_age.svg').default;
const ICON_PRODUCTDETAIL_EXCLUSION =
  require('./icon_productdetail_exclusion.svg').default;
const ICON_PRODUCTDETAIL_INCLUSION =
  require('./icon_productdetail_inclusion.svg').default;
const ICON_PRODUCTDETAIL_PREPARE =
  require('./icon_productdetail_prepare.svg').default;
const ICON_PRODUCTDETAIL_KEYWORD =
  require('./icon_product_keyword.svg').default;

//QNA
const ICON_QNA_LOCK = require('./icon_qna_lock.svg').default;

//Toast
const ICON_TOAST_SUCCESS = require('./icon_toast_success.svg').default;
const ICON_TOAST_FAILED = require('./icon_toast_failed.svg').default;
const ICON_TOAST_CANCEL = require('./icon_toast_cancel.svg').default;

export {
  ICON_FRIP_LOGO,
  ICON_CLOSE,
  ICON_ARROW_GRAY_16,
  ICON_ARROW_GRAY_BOLD,
  ICON_ARROW_BLACK_14,
  ICON_ARROW_BLACK,
  ICON_ARROW_LEFT,
  ICON_ARROW_BLACK_BOTTOM,
  ICON_ARROW_BLACK_TOP,
  ICON_ARROW_BLUE,
  ICON_PLUS_BLACK,
  ICON_PLUS_GRAY,
  ICON_MENU_BUTTON,
  ICON_HEART_BLACK,
  ICON_EMAIL_BLACK,
  ICON_NOTICE_BLACK,
  ICON_NOTICE_RED,
  ICON_NOTICE_BLUE,
  ICON_SHARE,
  ICON_PHONE,
  ICON_NAVIGATE_BACK,
  ICON_PROFILE_DEFAULT,
  ICON_STAR_YELLOW,
  ICON_SHOW_EYE,
  ICON_SWITCH,
  ICON_REFRESH,
  ICON_COPY_GRAY,
  ICON_MESSAGE_BLACK,
  ICON_LOCATION,
  //Menu
  ICON_MENU_HOME,
  ICON_MENU_CALENDAR,
  ICON_MENU_LIST,
  ICON_MENU_MESSAGE,
  ICON_MENU_MENU,
  ICON_MENU_SALE,
  ICON_MENU_HOME_ACTIVE,
  ICON_MENU_CALENDAR_ACTIVE,
  ICON_MENU_LIST_ACTIVE,
  ICON_MENU_MESSAGE_ACTIVE,
  ICON_MENU_MENU_ACTIVE,
  ICON_MENU_SALE_ACTIVE,
  ICON_SETTLEMENT_REQUEST,
  ICON_SETTLEMENT_LIST,
  ICON_MENU_QNA,
  ICON_MENU_REVIEW,
  ICON_MENU_HEADPHONE,
  ICON_MENU_DELIVERY,
  ICON_MENU_INDIVIDUAL,
  ICON_MENU_PRODUCT,
  ICON_MENU_SALE_BLACK,
  ICON_MENU_BOOKING,
  ICON_MENU_PROFILE,
  ICON_MENU_LOGOUT,
  ICON_MENU_CONCIERGE,
  ICON_MENU_QUESTION,
  ICON_MENU_NOTICE,
  ICON_AUTH_CLOSE_BUTTON,
  ICON_SOCIAL_LOGIN_KAKAO,
  ICON_SOCIAL_LOGIN_FACEBOOK,
  ICON_TERM_CHECKED_DISABLE,
  ICON_TERM_CHECKED_ABLE,
  ICON_SIGNUP_TERMCHECKED,
  ICON_SIGNUP_TERMCHECKED_DISABLED,
  ICON_CHECK_COMPELETE,
  ICON_QUESTION,
  ICON_QUESTION_RED,
  ICON_ADDRESS,
  ICON_ADDRESS_BLACK,
  ICON_CATEGORY_SEARCH,
  ICON_CATEGORY_SEARCH_ACTIVE,
  ICON_IMAGE,
  ICON_CALENDAR,
  ICON_PROFILE_CAMERA,
  //Settlement
  ICON_SETTLEMENT_REGIST_REQUEST,
  ICON_SETTLEMENT_BANK,
  ICON_SETTLEMENT_ALERT,
  ICON_SETTLEMENT_VALIDATION,
  ICON_SETTLEMENT_IMAGE_UPLOAD,
  ICON_SETTLEMENT_ARROW_LEFT,
  ICON_SETTLEMENT_ARROW_RIGHT,
  ICON_SETTLEMENT_ARROW_LEFT_DISABLE,
  ICON_SETTLEMENT_ARROW_RIGHT_DISABLE,
  // Product Regist
  ICON_PRODUCT_HOSTING_SERVICE,
  ICON_PRODUCT_HOSTING_TOUR,
  ICON_PRODUCT_HOSTING_CLASS,
  ICON_PRODUCT_HOSTING_MEET,
  ICON_PRODUCT_HOSTING_SHIP,
  ICON_PRODUCT_DELETE,
  ICON_PRODUCT_SETTING,
  ICON_PRODUCT_SELECT,
  ICON_PRODUCT_SELECT_DISABLE,
  ICON_PRODUCT_CAMERA,
  ICON_PRODUCT_CHECKMARK,
  ICON_PRODUCT_CHECKMARK_BLACK,
  ICON_PRODUCT_DELETE_RED,
  ICON_PRODUCT_CLIPBOARD,
  ICON_PRODUCT_FILTER,
  ICON_PRODUCT_FILTER_DISABLE,
  ICON_PRODUCT_LIST,
  ICON_PRODUCT_STAR,
  ICON_PRODUCT_EDIT,
  ICON_PRODUCT_SCHEDULE_SELECT,
  ICON_PRODUCT_SCHEDULE_CHOICE,
  ICON_PRODUCT_IMAGE_UPLOAD,
  ICON_PRODUCTLIST_EMPTY,
  ICON_PRODUCTLIST_COPY,
  ICON_PRODUCTLIST_REGIST,
  //Product Detail
  ICON_PRODUCTDETAIL_LEVEL,
  ICON_PRODUCTDETAIL_PEOPLE,
  ICON_PRODUCTDETAIL_AGE,
  ICON_PRODUCTDETAIL_EXCLUSION,
  ICON_PRODUCTDETAIL_INCLUSION,
  ICON_PRODUCTDETAIL_PREPARE,
  ICON_PRODUCTDETAIL_KEYWORD,
  // QNA
  ICON_QNA_LOCK,
  //Toast
  ICON_TOAST_SUCCESS,
  ICON_TOAST_FAILED,
  ICON_TOAST_CANCEL,
};
