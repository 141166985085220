import { AddressItems } from 'containers/ProductRegist/components/BasicInformation/hooks/useAddressModalHooks';
import {
  FripLocationParam,
  ProductParamInput,
} from 'containers/ProductRegist/hooks/types';
import { useState } from 'react';

export interface LocationInformation {
  locationsOfVenue: FripLocationParam[];
  locationOfGathering: FripLocationParam | null;
}

export const useAddressModifyHooks = (
  productParamInput: ProductParamInput,
  handleOnchangeProductParamInput: (key: string, value: any) => void
) => {
  const [copyProductParamInput, setCopyProductParamInput] =
    useState<ProductParamInput>(productParamInput);

  const [locationInformation, setLocationInformation] =
    useState<LocationInformation>({
      locationsOfVenue: productParamInput.frip.locationsOfVenue,
      locationOfGathering: productParamInput.frip.locationOfGathering,
    });

  const [locationVenueItems, setLocationVenueItems] =
    useState<FripLocationParam | null>(null);

  const handleOnChangeCopyProductParamInput = (key: string, value: any) => {
    setCopyProductParamInput({ ...copyProductParamInput, [key]: value });
  };

  const handleResetLocationVenueItems = () => {
    setLocationVenueItems(null);
  };

  const handleResetAddress = () => {
    setCopyProductParamInput(productParamInput);
  };

  const handleRegistAddress = () => {
    handleOnchangeProductParamInput('frip', copyProductParamInput.frip);
  };

  return {
    copyProductParamInput,
    locationVenueItems,
    locationInformation,
    setLocationVenueItems,
    handleResetLocationVenueItems,
    handleOnChangeCopyProductParamInput,
    handleResetAddress,
    handleRegistAddress,
  };
};
