import { ICON_NOTICE_BLUE } from 'assets/icons';
import Text from 'components/common/Text';
import React from 'react';
import styled from 'styled-components';
import { CurriculumStyle } from 'types/api';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 16px 18px;
  width: 100%;
  border-radius: 10px;
  background: var(--gray-gray-01, #f7f7f8);
`;

interface CalloutContainerIProps {
  curriculumStyle: CurriculumStyle;
}

const CalloutContainer: React.FC<CalloutContainerIProps> = props => {
  const { curriculumStyle } = props;

  const calloutText = (): string => {
    switch (curriculumStyle) {
      case CurriculumStyle.DURATION: {
        return '소요시간을 기준으로 작성할 수 있어요.';
      }
      case CurriculumStyle.TIMETABLE: {
        return '정확한 시각을 기준으로 작성할 수 있어요.';
      }
      case CurriculumStyle.LEGACY: {
        return '자유롭게 작성할 수 있어요.';
      }
    }

    return '';
  };

  return (
    <Container>
      <img src={ICON_NOTICE_BLUE} alt="icon notice blue" />
      <Text color="#70737c" weight={400}>
        {calloutText()}
      </Text>
    </Container>
  );
};

export default CalloutContainer;
