import { useMutation } from '@apollo/client';
import axios from 'axios';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import { useEffect, useState } from 'react';
import {
  ContentUploadInfo,
  ContentUploadInfoItem,
  ProductHeaderContentStyle,
} from 'types/api';
import { isWebview } from 'utils/webview';
import { UPLOAD_GENERATE_CONTENT } from '..';

export interface ImageResult {
  asset_id: string;
  public_id: string;
  version: number;
  version_id: string;
  signature: string;
  width: number;
  height: number;
  format: string;
  resource_type: string;
  created_at: string;
  tags?: [] | null;
  bytes: number;
  type: string;
  etag: string;
  placeholder: boolean;
  url: string;
  secure_url: string;
  folder: string;
  access_mode: string;
  original_filename: string;
  api_key: string;
  contentId: string;
}

interface GenerateContentUploadIProps {
  productParamInput: ProductParamInput;
  imageThumbnail: string[];
  setImageThumbnail: React.Dispatch<React.SetStateAction<string[]>>;
  handleOnChangeProductParamInput: (key: string, value: any) => void;
}

export function useMutationGenerateContentUpload({
  productParamInput,
  imageThumbnail,
  setImageThumbnail,
  handleOnChangeProductParamInput,
}: GenerateContentUploadIProps) {
  const [generateContentupload] = useMutation(UPLOAD_GENERATE_CONTENT);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleMakeFormForUpload = (
    file: File,
    uploadInfo: ContentUploadInfo,
  ) => {
    const form = new FormData();
    uploadInfo.formData.forEach((element: ContentUploadInfoItem) => {
      form.append(element.key, element.value);
    });
    form.append('file', file);
    return form;
  };

  const handleGenerateContentUpload = async (uploadInfo: {
    file: File;
    fileName: string;
  }) => {
    try {
      const response = await generateContentupload({
        variables: { param: { fileName: uploadInfo.fileName } },
      });

      if (response) {
        const result = response.data.generateContentUploadInfo;

        handleOnChangeProductParamInput('headerContents', [
          ...productParamInput.headerContents,
          {
            contentId: result.contentId,
            style: ProductHeaderContentStyle.SQUARE,
          },
        ]);

        const form = handleMakeFormForUpload(uploadInfo.file, result);
        const uploadedImage = await axios.post(result.uploadUrl, form, {
          headers: {},
        });

        if (uploadedImage) {
          setImageThumbnail([...imageThumbnail, uploadedImage.data.url]);
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error, 'upload error');
    }
  };

  const handleImageUpload = (link: { link: string }) => {
    const result = JSON.parse(link.link);

    setImageThumbnail([...imageThumbnail, result.url]);
    handleOnChangeProductParamInput('headerContents', [
      ...productParamInput.headerContents,
      {
        contentId: result.contentId,
        style: ProductHeaderContentStyle.SQUARE,
      },
    ]);
    setIsLoading(false);
  };

  const handleDeleteImage = (idx: number) => {
    const filterImageList = imageThumbnail.filter(
      (item, filterIdx) => filterIdx !== idx,
    );

    const productFilter = productParamInput.headerContents.filter(
      (item, filterIdx) => filterIdx !== idx,
    );

    handleOnChangeProductParamInput('headerContents', productFilter);
    setImageThumbnail(filterImageList);
  };

  useEffect(() => {
    if (isWebview()) {
      window.handleImageUpload = handleImageUpload;
      window.handleImageLoading = () => {
        setIsLoading(true);
      };
    }
  }, [imageThumbnail, productParamInput]);

  return {
    isLoading,
    setIsLoading,
    handleGenerateContentUpload,
    handleDeleteImage,
  };
}
