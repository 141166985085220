import { gql } from '@apollo/client';

export const GET_BANK_INFORMATION = gql`
  query getBankInfos {
    settlement {
      banks {
        code
        name
        __typename
      }
      __typename
    }
  }
`;

export const VERIFY_BANK_ACCOUNT = gql`
  mutation VerifyBankAccount($param: BankAccountVerifyParamInput!) {
    verifyBankAccount(param: $param) {
      message
      success
    }
  }
`;

export const SAVE_HOST_BANK_ACCOUNT = gql`
  mutation SaveHostBankAccount($hostId: ID!, $param: BankAccountParamInput!) {
    saveHostBankAccount(hostId: $hostId, param: $param) {
      account {
        accountNo
        bank {
          code
          name
        }
        holder
      }
      verified
      verifiedAt
    }
  }
`;
