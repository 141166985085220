import React, { useEffect } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import Divider from 'components/Divider';
import HeaderContents from 'containers/QnaList/components/HeaderContents';
import TotalCountContainer from 'containers/QnaList/components/TotalCountContainer';
import ReviewItem from './components/ReviewItem';
import ReviewFilterBottomSheet from './components/ReviewFilterBottomSheet';
import QnaItemBottomSheet from 'containers/QnaList/components/QnaItemBottomSheet';
import PaginataionContainer from 'containers/QnaList/components/PaginataionContainer';
import Column from 'components/common/Column';
import Text from 'components/common/Text';
import Loading from 'components/Loading/Loading';
import { useReviewListHooks } from './hooks/useReviewListHooks';
import { useReviewFilterHooks } from './hooks/useReviewFilterHooks';
import HackleManager from 'utils/hackle';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 0 0 90px;
  width: 100%;
  max-width: 768px;
`;

const ReviewListPage = () => {
  const {
    reviewFilter,
    reviewList,
    isOpenCountFilter,
    isOpenReviewBottomSheet,
    isOpenReviewFilterBottomSheet,
    selectedReviewItem,
    handleSetReviewFilter,
    setIsOpenReviewBottomSheet,
    setIsOpenReviewFilterBottomSheet,
    handleIsOpenCountFilter,
    handleSelectedReviewItem,
    handleAcceptFilter,
    handleRefetchList,
  } = useReviewListHooks();

  const {
    searchValue,
    ratingType,
    ratingsIn,
    handlerSetSearchValue,
    handleSetRatingIn,
    handleResetFilterInput,
  } = useReviewFilterHooks();

  const { width, ref } = useResizeDetector();

  const isMobile = width ? width < 768 : window.innerWidth < 768;

  useEffect(() => {
    if (reviewList) {
      HackleManager.logReviewView(reviewList.totalCount);
    }
  }, [reviewList]);

  return (
    <Container ref={ref}>
      {reviewList ? (
        <React.Fragment>
          <HeaderContents
            isMobile={isMobile}
            type="review"
            // itemCount={{ new: 0, complete: 0 }}
            qnaFilter=""
            isAcceptFilter={searchValue !== '' || !isEmpty(ratingsIn)}
            handleSetQnaFilter={() => {}}
            setIsOpenQnaFilterBottomSheet={setIsOpenReviewFilterBottomSheet}
          />
          <Column style={{ padding: '0 20px' }}>
            <TotalCountContainer
              totalCount={reviewList.totalCount}
              isOpenCountFilter={isOpenCountFilter}
              filterItem={reviewFilter}
              handleSetFilterItem={handleSetReviewFilter}
              handleIsOpenCountFilter={handleIsOpenCountFilter}
            />
          </Column>
          {!isEmpty(reviewList.edges) ? (
            reviewList.edges.map((item, idx) => {
              return (
                <React.Fragment key={idx}>
                  <ReviewItem
                    isMobile={isMobile}
                    item={item}
                    handleSelectedReviewItem={handleSelectedReviewItem}
                    handleRefetchList={handleRefetchList}
                  />
                  {reviewFilter.size !== idx + 1 && (
                    <Divider style={{ height: '6px', background: '#F4F4F5' }} />
                  )}
                </React.Fragment>
              );
            })
          ) : (
            <Column gap="8px" style={{ minHeight: '60vh' }}>
              <Text color="#1b1c1e" size="16px">
                조회되는 후기가 없어요.
              </Text>
              {/* <Text color="#70737c" weight={400}>
                필터를 다시 확인해 주세요
              </Text> */}
            </Column>
          )}
          <PaginataionContainer
            totalCount={reviewList.totalCount}
            page={reviewFilter.page}
            size={reviewFilter.size}
            handleSetQnaFilter={handleSetReviewFilter}
          />
          {isOpenReviewBottomSheet && (
            <QnaItemBottomSheet
              type="review"
              isMobile={isMobile}
              productInfo={
                selectedReviewItem.node.target.orderItem.booking.info.origin
                  .product
              }
              onClose={() => {
                setIsOpenReviewBottomSheet(false);
              }}
              handleSetProductQnaItem={productId => {
                handleSetReviewFilter('filter', {
                  ...reviewFilter.filter,
                  productId: Number(productId),
                });
              }}
            />
          )}
          {isOpenReviewFilterBottomSheet && (
            <ReviewFilterBottomSheet
              isMobile={isMobile}
              searchValue={searchValue}
              ratingType={ratingType}
              ratingsIn={ratingsIn}
              handlerSetSearchValue={handlerSetSearchValue}
              handleSetRatingIn={handleSetRatingIn}
              handleResetFilterInput={handleResetFilterInput}
              onClose={() => setIsOpenReviewFilterBottomSheet(false)}
              handleAcceptFilter={handleAcceptFilter}
            />
          )}
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </Container>
  );
};

export default ReviewListPage;
