import { useMutation } from '@apollo/client';
import { DELETE_COMMENT, MODIFY_COMMENT, WRITE_COMMENT } from '..';

export function useMutationReviewComment(handleRefetchList: () => void) {
  const [writeComment] = useMutation(WRITE_COMMENT);
  const [modifyComment] = useMutation(MODIFY_COMMENT);
  const [deleteComment] = useMutation(DELETE_COMMENT);

  const handleWriteComment = async (reviewId: string, content: string) => {
    try {
      const response = await writeComment({
        variables: { param: { productReviewId: Number(reviewId), content } },
      });

      if (response) {
        window.showToast('답변 작성 완료했습니다.', 'success');

        handleRefetchList();
        // window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleModifyComment = async (id: string, content: string) => {
    try {
      const response = await modifyComment({
        variables: { param: { id: Number(id), content } },
      });

      if (response) {
        window.showToast('답변 수정 완료했습니다.', 'success');

        handleRefetchList();
        // window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteComment = async (id: string) => {
    try {
      const response = await deleteComment({ variables: { id } });

      if (response) {
        window.showToast('답변 삭제 완료했습니다.', 'cancel');

        handleRefetchList();
        // window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return { handleWriteComment, handleModifyComment, handleDeleteComment };
}
