import React from 'react';
import styled from 'styled-components';

const Image = styled.img`
  cursor: pointer;
`;

interface CommonImageIProps {
  src: any;
  style?: React.CSSProperties;
  onClick: () => void;
}

const CommonImage: React.FC<CommonImageIProps> = props => {
  const { src, style, onClick } = props;

  return (
    <Image
      src={src}
      alt={`icon ${src}`}
      onClick={e => {
        e.stopPropagation();

        onClick();
      }}
      style={{ ...style }}
    />
  );
};

export default CommonImage;
