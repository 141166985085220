import React, { SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import Divider from 'components/Divider';
import { ICON_ARROW_GRAY_16, ICON_NOTICE_BLACK } from 'assets/icons';
import { dateFormattingForm, formatPhoneNumber } from 'utils/formatting';
import { OrderEdge, OrderState, TransactionType } from 'types/api';
import { SALE_DETAIL_PATH } from 'constants/path';

const Container = styled.div<{ $isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 20px 16px;
  width: 100%;
  border-radius: 12px;
  border: ${props =>
    props.$isSelected ? '1.5px solid #1b1c1e' : '1px solid #dbdcdf'};
  background: var(--gray-gray-01, #f7f7f8);
  cursor: pointer;
`;

const NotificationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 12px;
  width: 100%;
  border-radius: var(--Radius-border-radius-200, 8px);
  border: 1px solid var(--gray-gray-04, #dbdcdf);
  background: var(--Static-White, #fff);
`;

interface SaleCardIProps {
  isMobile: boolean;
  item: OrderEdge;
  selectedId: string;
  setSelectedId: React.Dispatch<SetStateAction<string>>;
}

const SaleCard: React.FC<SaleCardIProps> = props => {
  const { isMobile, item, selectedId, setSelectedId } = props;

  const navigate = useNavigate();

  const colorFormat = (): string => {
    switch (item.node.status) {
      case OrderState.COMPLETED: {
        return '#1b1c1e';
      }
      case OrderState.CANCELED: {
        return '#FF4242';
      }
    }
  };

  const statusFormat = (): string => {
    switch (item.node.status) {
      case OrderState.COMPLETED: {
        return '결제완료';
      }
      case OrderState.CANCELED: {
        return '취소완료';
      }
    }
  };

  const convertTotalPrice = (): number => {
    let totalPrice: number = 0;

    item.node.items.map(bookingItem => {
      totalPrice += Number(bookingItem.amount.value);
    });

    return totalPrice;
  };

  const convertTotalCount = (): number => {
    let totalCount: number = 0;

    item.node.items.map(bookingItem => {
      totalCount += bookingItem.info.ticketCount * bookingItem.count;
    });

    return totalCount;
  };

  const convertConfirmedCount = (): number => {
    let totalConfirmedCount: number = 0;

    item.node.items.filter(bookingItem => {
      if (!isEmpty(bookingItem.dependedItems)) {
        totalConfirmedCount +=
          bookingItem.remains - bookingItem.booking.counts.confirmed;

        return;
      }

      totalConfirmedCount +=
        bookingItem.booking.counts.total - bookingItem.booking.counts.confirmed;
    });

    return totalConfirmedCount;
  };

  const isShowConfirmNotice = (): boolean => {
    const isAttachSchedule = item.node.items.every(
      bookingItem => !bookingItem.info.origin.product.frip.attachedToSchedule,
    );

    const isApplied = item.node.items.every(
      bookingItem => bookingItem.booking?.counts.applied >= 1,
    );

    return isAttachSchedule && isApplied && convertConfirmedCount() > 1;
  };

  return (
    <Container
      $isSelected={!isMobile && item.node.id === selectedId}
      onClick={() => {
        if (isMobile) {
          navigate(`${SALE_DETAIL_PATH}/${item.node.id}`);

          return;
        }

        setSelectedId(item.node.id);
      }}
    >
      <Row
        justifyContent="space-between"
        align="flex-start"
        style={{ width: '100%' }}
      >
        <Column align="flex-start" style={{ width: 'auto' }}>
          <Text color={colorFormat()} size="13px">
            {statusFormat()}
          </Text>
          <Text color="#1b1c1e" size="12px" weight={400}>
            <span style={{ color: '#70737C' }}>결제일: </span>
            {dayjs(item.node.items[0].transaction.firmedAt).format(
              dateFormattingForm(),
            )}
          </Text>
          {item.node.transactions[0].type === TransactionType.CANCELLATION && (
            <Row gap="2px">
              <Text color="#70737c" size="12px" weight={400}>
                취소일:
              </Text>
              <Text color="#1b1c1e" size="12px" weight={400}>
                {dayjs(item.node.transactions[0].firmedAt).format(
                  'YYYY-MM-DD HH:mm',
                )}
              </Text>
            </Row>
          )}
        </Column>
        <Text color="#70737c" size="12px" weight={400}>
          주문ID: {item.node.id}
        </Text>
      </Row>
      <Divider />
      <Column align="flex-start" gap="8px">
        <Column align="flex-start" gap="4px">
          <Text color="#46474c">{item.node.items[0].info.product.title}</Text>
          <Text color="#46474C" size="13px" weight={400}>
            {item.node.customer.nickname} •{' '}
            <a
              href={`tel:${item.node.customer.mobileNumber}`}
              style={{ textDecoration: 'underline' }}
            >
              {formatPhoneNumber(item.node.customer.mobileNumber)}
            </a>
            {/* {item.node.customer.mobileNumber.replace(
              PHONE_SECURITY_REGEX,
              '$1****$3'
            )} */}
          </Text>
        </Column>
      </Column>
      {isShowConfirmNotice() && (
        <NotificationContainer>
          <img src={ICON_NOTICE_BLACK} alt="notification" />
          <Text color="#1b1c1e" size="13px">
            {convertConfirmedCount().toLocaleString()}건의 예약 확정이 필요해요
          </Text>
        </NotificationContainer>
      )}
      <Divider />
      <Row justifyContent="space-between" style={{ width: '100%' }}>
        <Text color="#1B1C1E" size="14px" weight={600}>
          {convertTotalPrice().toLocaleString()}원{' '}
          <span style={{ fontWeight: 400 }}>
            ({convertTotalCount().toLocaleString()}건)
          </span>
        </Text>
        <Row
          onClick={() => {
            if (isMobile) {
              navigate(`${SALE_DETAIL_PATH}/${item.node.id}`);

              return;
            }

            setSelectedId(item.node.id);
          }}
        >
          <Text color="#70737c" style={{ cursor: 'pointer' }}>
            상세 보기
          </Text>
          <img src={ICON_ARROW_GRAY_16} alt="icon arrow gray" />
        </Row>
      </Row>
    </Container>
  );
};

export default SaleCard;
