import { useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useReviewFilterHooks = () => {
  const location = useLocation();
  const productId = location.search.split('=')[1];

  const [searchValue, setSearchValue] = useState<string>(
    productId ? productId : '',
  );
  const [ratingsIn, setRatingsIn] = useState<number[]>([]);
  const [ratingType, setRatingType] = useState<string[]>([]);

  const handlerSetSearchValue = (e: any) => {
    setSearchValue(e.target.value);
  };

  const handleSetRatingIn = (rating: string) => {
    if (rating === 'all') {
      if (ratingType.includes(rating)) {
        setRatingType(ratingType.filter(item => item !== rating));
        setRatingsIn([]);

        return;
      }

      if (ratingType.length === 0) {
        setRatingType([...ratingType, rating]);
        setRatingsIn([0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5]);
      }

      return;
    }

    if (!ratingType.includes('all')) {
      switch (rating) {
        case '0': {
          if (ratingType.includes(rating)) {
            const filterItem = ratingsIn.filter(
              item => item !== 0.5 && item !== 1,
            );
            setRatingType(ratingType.filter(item => item !== rating));
            setRatingsIn(filterItem);

            return;
          }
          setRatingType([...ratingType, rating]);
          setRatingsIn([...ratingsIn, 0.5, 1]);

          return;
        }
        case '1': {
          if (ratingType.includes(rating)) {
            const filterItem = ratingsIn.filter(
              item => item !== 1.5 && item !== 2,
            );

            setRatingType(ratingType.filter(item => item !== rating));
            setRatingsIn(filterItem);

            return;
          }
          setRatingType([...ratingType, rating]);
          setRatingsIn([...ratingsIn, 1.5, 2]);

          return;
        }
        case '2': {
          if (ratingType.includes(rating)) {
            const filterItem = ratingsIn.filter(
              item => item !== 2.5 && item !== 3,
            );

            setRatingType(ratingType.filter(item => item !== rating));
            setRatingsIn(filterItem);

            return;
          }
          setRatingType([...ratingType, rating]);
          setRatingsIn([...ratingsIn, 2.5, 3]);

          return;
        }
        case '3': {
          if (ratingType.includes(rating)) {
            const filterItem = ratingsIn.filter(
              item => item !== 3.5 && item !== 4,
            );

            setRatingType(ratingType.filter(item => item !== rating));
            setRatingsIn(filterItem);

            return;
          }
          setRatingType([...ratingType, rating]);
          setRatingsIn([...ratingsIn, 3.5, 4]);

          return;
        }
        case '4': {
          if (ratingType.includes(rating)) {
            let filterItem;

            if (ratingType.includes('5')) {
              filterItem = ratingsIn.filter(item => item !== 4.5);
            } else {
              filterItem = ratingsIn.filter(item => item !== 4.5 && item !== 5);
            }

            setRatingType(ratingType.filter(item => item !== rating));
            setRatingsIn(filterItem);

            return;
          }

          setRatingType([...ratingType, rating]);
          setRatingsIn([...ratingsIn, 4.5, 5]);

          return;
        }
        case '5': {
          if (ratingType.includes(rating)) {
            setRatingType(ratingType.filter(item => item !== rating));

            if (ratingType.includes('4')) {
              return;
            }

            const filterItem = ratingsIn.filter(item => item !== 5);

            setRatingsIn(filterItem);

            return;
          }

          setRatingType([...ratingType, rating]);
          setRatingsIn([...ratingsIn, 5]);

          return;
        }
        default:
          return;
      }
    }
  };

  const handleResetFilterInput = () => {
    setSearchValue('');
    setRatingType([]);
    setRatingsIn([]);
  };

  return {
    searchValue,
    ratingType,
    ratingsIn,
    handlerSetSearchValue,
    handleSetRatingIn,
    handleResetFilterInput,
  };
};
