import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useState } from "react";
import { ProductState } from "types/api";
import {
  ProductTermSearchType,
  ProductTermWithTypeInput,
} from "../graphQL/types";

export const useProductFilterHooks = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [productStatus, setProductStatus] = useState<{
    type: "all" | "select";
    statusIn: ProductState[];
  }>({
    type: "all",
    statusIn: [
      ProductState.APPLIED,
      ProductState.INSPECTING,
      ProductState.EDITING,
      ProductState.READY,
      ProductState.SALE,
      ProductState.SOLD_OUT,
      ProductState.PAUSED,
      ProductState.REJECTED,
      ProductState.SUSPENDED,
      ProductState.CLOSED,
    ],
  });
  const [termWithType, setTermWithType] =
    useState<ProductTermWithTypeInput | null>(null);
  const [termType, setTermType] = useState<string>("all");

  const handlerSetSearchValue = (e: any) => {
    setSearchValue(e.target.value);
  };

  const handleSetProductStatus = (
    type: "all" | "select",
    value?: ProductState
  ) => {
    if (type === "all") {
      if (productStatus.type === "select") {
        setProductStatus({
          type: "all",
          statusIn: [
            ProductState.APPLIED,
            ProductState.INSPECTING,
            ProductState.EDITING,
            ProductState.READY,
            ProductState.SALE,
            ProductState.SOLD_OUT,
            ProductState.PAUSED,
            ProductState.REJECTED,
            ProductState.SUSPENDED,
            ProductState.CLOSED,
          ],
        });

        return;
      }

      if (isEmpty(productStatus.statusIn)) {
        setProductStatus({
          type: "all",
          statusIn: [
            ProductState.APPLIED,
            ProductState.INSPECTING,
            ProductState.EDITING,
            ProductState.READY,
            ProductState.SALE,
            ProductState.SOLD_OUT,
            ProductState.PAUSED,
            ProductState.REJECTED,
            ProductState.SUSPENDED,
            ProductState.CLOSED,
          ],
        });

        return;
      }

      setProductStatus({ type: "select", statusIn: [] });

      return;
    }

    if (productStatus.type === "all") {
      setProductStatus({
        type: "select",
        statusIn: [value],
      });

      return;
    }

    if (productStatus.statusIn.includes(value)) {
      const filterItem = productStatus.statusIn.filter(
        (item) => item !== value
      );

      setProductStatus({ type: "select", statusIn: filterItem });

      return;
    }

    setProductStatus({
      ...productStatus,
      statusIn: [...productStatus.statusIn, value],
    });
  };

  const handleSetTermWithType = (type: string) => {
    const today = new Date();

    switch (type) {
      case "all": {
        setTermWithType(null);

        return;
      }
      case "1month": {
        const startedAt = new Date(today);
        startedAt.setMonth(startedAt.getMonth() - 1);

        setTermWithType({
          type: ProductTermSearchType.PRODUCT_CREATED,
          term: { startedAt: startedAt.getTime(), endedAt: today.getTime() },
        });

        return;
      }
      case "6month": {
        const startedAt = new Date(today);
        startedAt.setMonth(startedAt.getMonth() - 1);

        setTermWithType({
          type: ProductTermSearchType.PRODUCT_CREATED,
          term: { startedAt: startedAt.getTime(), endedAt: today.getTime() },
        });

        return;
      }
      case "1year": {
        const startedAt = new Date(today);
        startedAt.setFullYear(startedAt.getFullYear() - 1);

        setTermWithType({
          type: ProductTermSearchType.PRODUCT_CREATED,
          term: { startedAt: startedAt.getTime(), endedAt: today.getTime() },
        });

        return;
      }
      case "5year": {
        const startedAt = new Date(today);
        startedAt.setFullYear(startedAt.getFullYear() - 5);

        setTermWithType({
          type: ProductTermSearchType.PRODUCT_CREATED,
          term: { startedAt: startedAt.getTime(), endedAt: today.getTime() },
        });

        return;
      }
      default:
        return;
    }
  };

  const handleResetFilterInput = () => {
    setSearchValue("");
    setTermWithType(null);
    setProductStatus({
      type: "all",
      statusIn: [
        ProductState.APPLIED,
        ProductState.INSPECTING,
        ProductState.EDITING,
        ProductState.READY,
        ProductState.SALE,
        ProductState.SOLD_OUT,
        ProductState.PAUSED,
        ProductState.REJECTED,
        ProductState.SUSPENDED,
        ProductState.CLOSED,
      ],
    });
  };

  return {
    searchValue,
    productStatus,
    termWithType,
    termType,
    setTermType,
    handleSetTermWithType,
    handlerSetSearchValue,
    handleSetProductStatus,
    handleResetFilterInput,
  };
};
