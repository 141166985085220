import { useGetSearchCategory } from 'containers/ProductRegist/graphQL/hooks/useGetSearchCategory';
import { useGetStandardCategory } from 'containers/ProductRegist/graphQL/hooks/useGetStandardCategory';
import { SelectCategoryItem } from 'containers/ProductRegist/graphQL/types';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import { useEffect, useState } from 'react';
import { FripKind, ProductKind } from 'types/api';
import { errorHandler } from 'utils/ErrorHandler';

export const useCategoryHooks = (productParamInput: ProductParamInput) => {
  const defaultId =
    productParamInput.kind! === ProductKind.OFFLINE
      ? '0001'
      : productParamInput.kind! === ProductKind.ONLINE
      ? '0002'
      : '0003';

  const { data, getStandardCategory, refetch } =
    useGetStandardCategory(defaultId);

  const [categorySelect, setCategorySelect] = useState<'Search' | 'Select'>(
    'Search',
  );
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [categoryList, setCateogryList] = useState<{
    first: SelectCategoryItem | null;
    second: SelectCategoryItem | null;
    third: SelectCategoryItem | null;
    fourth: SelectCategoryItem | null;
  }>({ first: null, second: null, third: null, fourth: null });
  const [categoryValue, setCategoryValue] = useState<{
    first: string;
    second: string;
    third: string;
    fourth: string;
  }>({
    first: '',
    second: '',
    third: '',
    fourth: '',
  });

  const handleGetStandardCategory = async (defaultId: string) => {
    try {
      const response = await getStandardCategory({
        variables: { id: defaultId, filter: { fripKind: FripKind.COMMON } },
      });

      if (response) {
        setCateogryList({
          ...categoryList,
          first: response.data.category,
          second: null,
          third: null,
          fourth: null,
        });
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleRefetchStandardCategory = async (
    selectNum: string,
    categoryId: string,
    categoryName: string,
  ) => {
    try {
      const response = await refetch({
        id: categoryId,
      });

      if (response) {
        switch (selectNum) {
          case 'first': {
            setCateogryList({
              ...categoryList,
              second: response.data.category,
              third: null,
              fourth: null,
            });
            setCategoryValue({
              ...categoryValue,
              first: categoryName,
              second: '',
              third: '',
              fourth: '',
            });

            setValue(categoryName);

            return;
          }
          case 'second': {
            setCateogryList({
              ...categoryList,
              third: response.data.category,
              fourth: null,
            });
            setCategoryValue({
              ...categoryValue,
              second: categoryName,
              third: '',
              fourth: '',
            });

            setValue(`${value} > ${categoryName}`);

            return;
          }
          case 'third': {
            setCateogryList({
              ...categoryList,
              fourth: response.data.category,
            });
            setCategoryValue({
              ...categoryValue,
              third: categoryName,
              fourth: '',
            });

            setValue(`${value} > ${categoryName}`);
            return;
          }
          case 'fourth': {
            setCategoryValue({ ...categoryValue, fourth: categoryName });
            setCateogryList({
              ...categoryList,
            });

            setValue(`${value} > ${categoryName}`);
            return;
          }
          default: {
            return setCateogryList({ ...categoryList, first: data.category });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetCategoryItems = async () => {
    try {
      const response = await refetch({
        id: productParamInput.standardCategoryId,
      });

      if (response) {
        const categoryItems = response.data.category;
        const first = categoryItems.standard.parent?.parent?.parent?.label.name;
        const second = categoryItems.standard.parent?.parent?.label.name;
        const third = categoryItems.standard.parent?.label.name;
        const fourth = categoryItems.standard.label.name;

        setValue(`${first} > ${second} > ${third} > ${fourth}`);
        setCategoryValue({ first, second, third, fourth });
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (
      productParamInput.standardCategoryId !== '' &&
      productParamInput.standardCategoryId !== defaultId
    ) {
      handleGetCategoryItems();
    }
  }, [productParamInput.standardCategoryId]);

  const {
    searchResult,
    isOpenSearchResultForm,
    handleGetSearchCategory,
    setIsOpenSearchResultForm,
  } = useGetSearchCategory();

  return {
    categoryList,
    categorySelect,
    categoryValue,
    isFocused,
    searchResult,
    isOpenSearchResultForm,
    value,
    setValue,
    setCategorySelect,
    setIsFocused,
    handleGetSearchCategory,
    handleRefetchStandardCategory,
    setIsOpenSearchResultForm,
    handleGetStandardCategory,
  };
};
