import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  callWebviewHandler,
  isWebview,
  WebviewOperatorType,
} from 'utils/webview';
import { ICON_CLOSE } from 'assets/icons';
import Text from 'components/common/Text';
import { SETTLEMENT_PATH } from 'constants/path';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 768px;
  width: 100%;

  background-color: #fff;
  border-radius: 16px 16px 0 0;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 12px 20px;
  width: 100%;

  border-radius: 16px 16px 0 0;
  border-bottom: 1px solid #dbdcdf;
  background: #fff;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${window.webkit ? '16px 20px 32px 16px' : '16px 20px'};
  width: 100%;

  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: -0.6px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 24px 20px;
  width: 100%;
  border-bottom: 1px solid #dbdcdf;
`;

const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  border-radius: 8px;
  background: #1b1c1e;

  color: #fff;
  font-weight: 700;
`;

const UnderlineText = styled.div`
  color: #1b1c1e;
  font-weight: 500;
  text-decoration-line: underline;
`;

interface CloseViewModalIProps {
  onClose: () => void;
}

const CloseViewModal: React.FC<CloseViewModalIProps> = (props) => {
  const { onClose } = props;
  const navigate = useNavigate();

  const handelCloseWebView = () => {
    if (isWebview()) {
      if (history.length > 1) {
        navigate(SETTLEMENT_PATH);

        return;
      }

      // callWebviewHandler(WebviewOperatorType.CloseWebView);
    } else {
      navigate(SETTLEMENT_PATH);
    }
  };

  return (
    <Container>
      <ModalContainer>
        <HeaderContainer>
          <img src={ICON_CLOSE} alt="close icon" onClick={onClose} />
        </HeaderContainer>
        <Column>
          <Text color="#1b1c1e" size="20px">
            정말 페이지에서 나가시겠어요?
          </Text>
          <Text color="#1b1c1e" size="16px" weight={400}>
            지금 나가시면 입력하신 정보가 저장되지 않습니다.
          </Text>
        </Column>
        <Row>
          <UnderlineText onClick={onClose}>아니요</UnderlineText>
          <CloseButton onClick={handelCloseWebView}>네,나갈게요</CloseButton>
        </Row>
      </ModalContainer>
    </Container>
  );
};

export default CloseViewModal;
