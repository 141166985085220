import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  callWebviewHandler,
  isWebview,
  WebviewOperatorType,
} from 'utils/webview';
import { ICON_CLOSE } from 'assets/icons';
import { SETTLEMENT_PATH } from 'constants/path';

const Container = styled.div`
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  padding: 16px 20px;
  width: 100%;
  height: 64px;

  border-bottom: 1px solid #ddd;
  background: #fff;
  z-index: 10;

  img {
    position: absolute;
    left: 20px;
  }
`;

const Title = styled.div`
  color: #1b1c1e;
  text-align: center;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: -0.096px;
`;

interface HeaderIProps {
  title: string;
  onClose?: () => void;
}

const Header: React.FC<HeaderIProps> = (props) => {
  const { title, onClose } = props;
  const navigate = useNavigate();

  const handleOnCloseWebview = () => {
    if (onClose) {
      onClose();

      return;
    }

    if (isWebview()) {
      if (history.length > 1) {
        navigate(SETTLEMENT_PATH);

        return;
      }

      // callWebviewHandler(WebviewOperatorType.CloseWebView);
    } else {
      navigate(SETTLEMENT_PATH);
    }
  };

  return (
    <Container>
      <img src={ICON_CLOSE} alt="close icon" onClick={handleOnCloseWebview} />
      <Title>{title}</Title>
    </Container>
  );
};

export default Header;
