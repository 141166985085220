import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useResizeDetector } from 'react-resize-detector';
import styled, { css } from 'styled-components';
import {
  BOOKING_LIST_PATH,
  MENU_PATH,
  MESSAGE_LIST_PATH,
  PRODUCT_LIST_PATH,
  SALE_LIST_PATH,
} from 'constants/path';
import { signoutHook } from 'utils/SignoutHooks';
import PCHeader from './components/PCHeader';
import HackleManager from 'utils/hackle';
import { formatMenuItem } from 'utils/hackle/parameterFormatting';
import MobileBottomSheet from './components/MobileBottomSheet';

const Container = styled.div<{ $isMobile: boolean }>`
  display: flex;
  align-items: center;

  width: 100%;
  z-index: 20;

  ${({ $isMobile }) =>
    $isMobile
      ? css``
      : css`
          position: sticky;
          top: 0;
        `}
`;

const Header = () => {
  const { handleSignout } = signoutHook();

  const navigate = useNavigate();
  const { width, ref } = useResizeDetector();

  const isMobile = width ? width < 768 : window.innerWidth < 768;

  const [activeButton, setActiveButton] = useState<string>('home');
  const [isOpenMyInfoSheet, setIsOpenMyInfoSheet] = useState<boolean>(false);
  const [isOpenMenuSheet, setIsOpenMenuSheet] = useState<boolean>(false);

  const handleNavigatePage = (path: string) => {
    setActiveButton(path);

    HackleManager.logMenuClick(formatMenuItem(path));

    if (isOpenMyInfoSheet) {
      setIsOpenMyInfoSheet(false);
    }

    if (isOpenMenuSheet) {
      setIsOpenMenuSheet(false);
    }

    if (path === activeButton) {
      return;
    }

    switch (path) {
      case 'product': {
        navigate(`${PRODUCT_LIST_PATH}`);

        return;
      }
      case 'message': {
        navigate(MESSAGE_LIST_PATH);

        return;
      }
      case 'overview': {
        navigate(MENU_PATH);

        return;
      }
      case 'sales': {
        navigate(SALE_LIST_PATH);

        return;
      }
      case 'booking': {
        navigate(BOOKING_LIST_PATH);

        return;
      }
      default:
        return;
    }
  };

  useEffect(() => {
    setActiveButton(window.location.pathname.slice(1));
  }, [window.location.pathname]);

  const renderHeaderItem = (): JSX.Element => {
    if (isMobile) {
      return (
        <MobileBottomSheet
          activeButton={activeButton}
          handleNavigatePage={handleNavigatePage}
        />
      );
    }

    return (
      <PCHeader
        activeButton={activeButton}
        isOpenMenuSheet={isOpenMenuSheet}
        isOpenMyInfoSheet={isOpenMyInfoSheet}
        setIsOpenMenuSheet={setIsOpenMenuSheet}
        setIsOpenMyInfoSheet={setIsOpenMyInfoSheet}
        handleNavigatePage={handleNavigatePage}
        handleSignout={handleSignout}
      />
    );
  };

  return (
    <Container ref={ref} $isMobile={isMobile}>
      {renderHeaderItem()}
    </Container>
  );
};

export default Header;
