import { gql } from '@apollo/client';

export const CONFIRM_TICKETS = gql`
  mutation ConfirmTickets($ticketIds: [ID!]!, $term: ScheduleTermInput) {
    confirmTickets(ticketIds: $ticketIds, term: $term) {
      id
      status
      availableActions
      scheduleTerm {
        startedAt
        endedAt
        duration
      }
      confirmedAt
      usedAt
      bookingItem {
        id
        status
        booking {
          id
          status
        }
      }
    }
  }
`;

export const USE_TICKETS = gql`
  mutation UseTickets($ticketIds: [ID!]!) {
    useTickets(ticketIds: $ticketIds) {
      id
      status
      availableActions
      scheduleTerm {
        startedAt
        endedAt
        duration
      }
      confirmedAt
      usedAt
      bookingItem {
        id
        status
        booking {
          id
          status
        }
      }
    }
  }
`;

export const RESTORE_TICKETS_CONFIRMATION = gql`
  mutation RestoreTicketsConfirmation($ticketIds: [ID!]!, $reason: String!) {
    restoreTicketsConfirmation(ticketIds: $ticketIds, reason: $reason) {
      id
      status
      availableActions
      scheduleTerm {
        startedAt
        endedAt
        duration
      }
      confirmedAt
      usedAt
      bookingItem {
        id
        status
        booking {
          id
          status
        }
      }
    }
  }
`;

export const RESOTRE_TICKETS_USING = gql`
  mutation RestoreTicketsUsing($ticketIds: [ID!]!, $reason: String!) {
    restoreTicketsUsing(ticketIds: $ticketIds, reason: $reason) {
      id
      status
      availableActions
      scheduleTerm {
        startedAt
        endedAt
        duration
      }
      confirmedAt
      usedAt
      bookingItem {
        id
        status
        booking {
          id
          status
        }
      }
    }
  }
`;

export const ABSENT_TICKETS = gql`
  mutation AbsentTickets($ticketIds: [ID!]!) {
    absentTickets(ticketIds: $ticketIds) {
      id
      status
    }
  }
`;

export const RESTORE_TICKETS_ABSENCE = gql`
  mutation RestoreTicketsAbsence($ticketIds: [ID!]!, $reason: String!) {
    restoreTicketsAbsence(ticketIds: $ticketIds, reason: $reason) {
      id
      status
      bookingItem {
        booking {
          approvedAt
          status
          customer {
            id
            email
            nickname
            description
            profileImage {
              id
              uri
              thumbnail
            }
          }
          schedule {
            term {
              startedAt
              endedAt
            }
          }
          tickets {
            id
          }
        }
      }
    }
  }
`;

export const REQUEST_CANCEL_ORDERS = gql`
  mutation ReqeustCancelOrders($param: CancelRequestBulkParamInput!) {
    requestCancelOrders(param: $param) {
      id
      status
    }
  }
`;

export const RETRACT_CANCEL_ORDER_REQUESTS = gql`
  mutation RetractCancelOrderRequests($tids: [ID!]!) {
    retractCancelOrderRequests(tids: $tids) {
      id
      status
    }
  }
`;
