import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_NOTICE_BOARD } from '..';
import { NoticeBoard, NoticeFilter } from '../types';

export function useQueryGetNoticeBoard() {
  const { data, refetch } = useQuery<NoticeBoard>(GET_NOTICE_BOARD, {
    variables: {
      filter: {
        forHostOnly: true,
      },
      page: 1,
      size: 10,
    },
  });
  const [noticeBoard, setNoticeBoard] = useState<NoticeBoard>();

  const handleRefetchNoticeBoard = async (param: NoticeFilter) => {
    try {
      const response = await refetch(param);

      if (response) {
        setNoticeBoard(response.data);
      }
    } catch (error) {
      console.log(error, 'notice board error');
    }
  };

  useEffect(() => {
    if (data) {
      setNoticeBoard(data);
    }
  }, [data]);

  return { noticeBoard, handleRefetchNoticeBoard };
}
