import React, { useState } from 'react';

import Column from 'components/common/Column';
import Row from 'components/common/Row';
import Text from 'components/common/Text';
import Divider from 'components/Divider';
import ModifyContainer from '../ModifyContainer';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import AddressForm from '../AddressForm';
import { ProductKind } from 'types/api';
import { formatInteractionType } from 'utils/formatting';
import { ModifyValue } from '../../hooks/useProductDetaillHooks';

interface AddressContainerIProps {
  isMobile: boolean;
  productParamInput: ProductParamInput;
  modifyValue: ModifyValue;
  setModifyValue: React.Dispatch<React.SetStateAction<ModifyValue>>;
}

const AddressContainer: React.FC<AddressContainerIProps> = props => {
  const { isMobile, productParamInput, modifyValue, setModifyValue } = props;

  const fripInfo = productParamInput.frip;
  const isOffline = productParamInput.kind === ProductKind.OFFLINE;

  const renderLocationVenue = (): JSX.Element => {
    return (
      <React.Fragment>
        {fripInfo.locationsOfVenue.map((item, idx) => {
          return (
            <React.Fragment key={idx}>
              <Row justifyContent="space-between" style={{ width: '100%' }}>
                <Text color="#46474C">진행하는 장소</Text>
              </Row>
              <AddressForm item={item} />
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };

  const renderLocationGathering = (): JSX.Element => {
    return (
      <React.Fragment>
        {fripInfo.locationOfGathering ? (
          <React.Fragment>
            <Divider />
            <Row justifyContent="space-between" style={{ width: '100%' }}>
              <Text color="#46474C">모이는 장소</Text>
            </Row>
            <AddressForm item={fripInfo.locationOfGathering} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Divider />
            <Row justifyContent="space-between" style={{ width: '100%' }}>
              <Text color="#46474C">모이는 장소</Text>
            </Row>
            <Column>
              <Text color="#46474C" weight={400}>
                등록한 모이는 장소가 없어요.
              </Text>
              <Text color="#46474C" weight={400}>
                진행 장소와 모이는 장소가 다를 시
              </Text>
              <Text color="#46474C" weight={400}>
                추가 등록해 주세요.
              </Text>
            </Column>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  const renderAddressForm = () => {
    if (isOffline) {
      return (
        <Column gap="16px">
          {renderLocationVenue()}
          {renderLocationGathering()}
        </Column>
      );
    }

    return (
      <Column align="flex-start">
        <Text color="#46474c" weight={400}>
          {formatInteractionType(productParamInput.frip.interactionType)}
        </Text>
      </Column>
    );
  };

  return (
    <ModifyContainer
      title={isOffline ? '장소' : '온라인 진행방법'}
      isSelected={
        !isMobile &&
        (modifyValue.modifyStatus === 'address' ||
          modifyValue.modifyStatus === 'online')
      }
      onClick={() => {
        if (!isOffline) {
          setModifyValue({ ...modifyValue, modifyStatus: 'online' });

          return;
        }

        setModifyValue({ ...modifyValue, modifyStatus: 'address' });
      }}
    >
      {renderAddressForm()}
    </ModifyContainer>
  );
};

export default AddressContainer;

// interface AddressContainerIProps {
//   isMobile: boolean;
//   productParamInput: ProductParamInput;
//   modifyValue: ModifyValue;
//   setModifyValue: React.Dispatch<React.SetStateAction<ModifyValue>>;
//   locationVenueItems?: FripLocationParam;
//   handleOnChangeProductParamInput: (key: string, value: any) => void;
//   handleUpdateProduct?: () => void;
//   setLocationVenueItems: React.Dispatch<
//     React.SetStateAction<FripLocationParam>
//   >;
// }

// const AddressContainer: React.FC<AddressContainerIProps> = (props) => {
//   const {
//     isMobile,
//     productParamInput,
//     modifyValue,
//     setModifyValue,
//     locationVenueItems,
//     handleOnChangeProductParamInput,
//     handleUpdateProduct,
//     setLocationVenueItems,
//   } = props;

//   const { handleDeleteLocation } = useMutationUpdateLocation();

//   const fripInfo = productParamInput.frip;
//   const isOffline = productParamInput.kind === ProductKind.OFFLINE;

//   const [type, setType] = useState<'address' | 'meeting'>('address');
//   const [isOpenModifyModal, setIsOpenModifyModal] = useState<boolean>(false);
//   const [isOpenOnlineModifyModal, setIsOpenOnlineModifyModal] =
//     useState<boolean>(false);

//   const handleResetLocationVenueItems = () => {
//     setLocationVenueItems(null);
//   };

//   const handleIsOpenModifyModal = (item: FripLocationParam) => {
//     setLocationVenueItems(item);

//     setIsOpenModifyModal(true);
//   };

//   const renderModifyModal = (): JSX.Element => {
//     return (
//       <ModalContainer
//         isMobile={true}
//         isAbled={true}
//         isBottomSheet={true}
//         onClick={() => {}}
//         onClose={() => {
//           setIsOpenModifyModal(false);
//         }}
//       >
//         <AddressModal
//           isMobile={isMobile}
//           productParamInput={productParamInput}
//           locationVenueItems={locationVenueItems}
//           handleOnChangeProductParamInput={handleOnChangeProductParamInput}
//           handleResetLocationVenueItems={handleResetLocationVenueItems}
//           type={type}
//           onClose={() => setIsOpenModifyModal(false)}
//           handleUpdateProduct={handleUpdateProduct}
//         />
//       </ModalContainer>
//     );
//   };

//   const renderOnlineModifyModal = (): JSX.Element => {
//     return (
//       <ModalContainer
//         isMobile={true}
//         isAbled={true}
//         onAcceptText="저장"
//         onClick={() => {
//           handleUpdateProduct();
//         }}
//         onClose={() => {
//           setIsOpenOnlineModifyModal(false);
//         }}
//       >
//         <Column style={{ padding: '24px 20px' }}>
//           <OnlineSelectForm
//             isMobile={true}
//             productParamInput={productParamInput}
//             handleOnChangeProductParamInput={handleOnChangeProductParamInput}
//           />
//         </Column>
//       </ModalContainer>
//     );
//   };

//   const renderAddressForm = () => {
//     if (isOffline) {
//       return (
//         <Column gap="16px">
//           {fripInfo.locationsOfVenue.map((item, idx) => {
//             return (
//               <React.Fragment key={idx}>
//                 <Row justifyContent="space-between" style={{ width: '100%' }}>
//                   <Text color="#46474C">진행하는 장소</Text>
//                   <Row gap="8px">
//                     {fripInfo.locationsOfVenue.length !== 1 && (
//                       <React.Fragment>
//                         <Text
//                           color="#FF4242"
//                           style={{ textDecoration: 'underline' }}
//                           onClick={() => {
//                             handleDeleteLocation(item.locationId, () => {
//                               handleOnChangeProductParamInput('frip', {
//                                 ...productParamInput.frip,
//                                 locationsOfVenue:
//                                   productParamInput.frip.locationsOfVenue.filter(
//                                     (venueItem) => venueItem.id !== item.id
//                                   ),
//                               });
//                             });
//                           }}
//                         >
//                           삭제
//                         </Text>
//                         <Text color="#70737c">|</Text>
//                       </React.Fragment>
//                     )}
//                     <Text
//                       color="#70737c"
//                       style={{ minWidth: '42px', textDecoration: 'underline' }}
//                       onClick={() => {
//                         // if (isMobile) {
//                         //   setType('address');
//                         //   handleIsOpenModifyModal(item);

//                         //   return;
//                         // }

//                         // setModifyStatus('address');
//                         setType('address');
//                         setLocationVenueItems(item);
//                       }}
//                     >
//                       수정하기
//                     </Text>
//                   </Row>
//                 </Row>
//                 <AddressForm item={item} />
//               </React.Fragment>
//             );
//           })}
// <DashButton
//   text="진행 장소 추가"
//   onClick={() => {
//     // if (isMobile) {
//     //   setIsOpenModifyModal(true);
//     // }

//     setLocationVenueItems(null);
//     // setModifyStatus('address');
//     setType('address');
//   }}
// />
//           {fripInfo.locationOfGathering ? (
//             <React.Fragment>
//               <Divider />
//               <Row justifyContent="space-between" style={{ width: '100%' }}>
//                 <Text color="#46474C">모이는 장소</Text>
//                 <Row gap="8px">
//                   <Text
//                     color="#FF4242"
//                     style={{ textDecoration: 'underline' }}
//                     onClick={() => {
//                       handleDeleteLocation(
//                         fripInfo.locationOfGathering.id,
//                         () => {
//                           handleOnChangeProductParamInput('frip', {
//                             ...productParamInput.frip,
//                             locationOfGathering: null,
//                           });
//                         }
//                       );
//                     }}
//                   >
//                     삭제
//                   </Text>
//                   <Text color="#70737c">|</Text>
//                   <Text
//                     color="#70737c"
//                     style={{ minWidth: '42px', textDecoration: 'underline' }}
//                     onClick={() => {
//                       // if (isMobile) {
//                       //   setType('meeting');
//                       //   handleIsOpenModifyModal(fripInfo.locationOfGathering);

//                       //   return;
//                       // }

//                       // setModifyStatus('meeting');
//                       setType('meeting');
//                       setLocationVenueItems(fripInfo.locationOfGathering);
//                     }}
//                   >
//                     수정하기
//                   </Text>
//                 </Row>
//               </Row>
//               <AddressForm item={fripInfo.locationOfGathering} />
//             </React.Fragment>
//           ) : (
//             <React.Fragment>
//               <Divider />
//               <Row justifyContent="space-between" style={{ width: '100%' }}>
//                 <Text color="#46474C">모이는 장소</Text>
//                 <Text
//                   color="#70737c"
//                   style={{ textDecoration: 'underline' }}
//                   onClick={() => {
//                     if (isMobile) {
//                       setType('meeting');
//                       handleIsOpenModifyModal(fripInfo.locationOfGathering);

//                       return;
//                     }

//                     // setModifyStatus('meeting');
//                     setType('meeting');
//                     setLocationVenueItems(null);
//                   }}
//                 >
//                   등록하기
//                 </Text>
//               </Row>
//               <Column>
//                 <Text color="#46474C" weight={400}>
//                   등록한 모이는 장소가 없어요.
//                 </Text>
//                 <Text color="#46474C" weight={400}>
//                   진행 장소와 모이는 장소가 다를 시
//                 </Text>
//                 <Text color="#46474C" weight={400}>
//                   추가 등록해 주세요.
//                 </Text>
//               </Column>
//             </React.Fragment>
//           )}
//         </Column>
//       );
//     }

//     return (
//       <Column align="flex-start">
//         <Text color="#46474c" weight={400}>
//           {formatInteractionType(productParamInput.frip.interactionType)}
//         </Text>
//       </Column>
//     );
//   };

//   return (
//     <ModifyContainer
//       title={isOffline ? '장소' : '온라인 진행방법'}
//       isSelected={
//         !isMobile &&
//         (modifyValue.modifyStatus === 'address' ||
//           modifyValue.modifyStatus === 'online')
//       }
//       modifyDisabled={isOffline ? true : false}
//       onClick={() => {
//         if (!isOffline) {
//           setModifyValue({ ...modifyValue, modifyStatus: 'online' });

//           return;
//         }

//         setModifyValue({ ...modifyValue, modifyStatus: 'address' });
//       }}
//     >
//       {renderAddressForm()}
//       {isOpenModifyModal && renderModifyModal()}
//       {isOpenOnlineModifyModal && renderOnlineModifyModal()}
//     </ModifyContainer>
//   );
// };
