import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApolloError, useMutation } from '@apollo/client';
import { ProductParamInput } from 'containers/ProductRegist/hooks/types';
import { MeData } from 'graphQL/Auth/types';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import { CREATE_PRODUCT, SAVE_PRODUCT } from '..';
import { errorHandler } from 'utils/ErrorHandler';

export function useMutationProductItems() {
  const myInfo: MeData = useUserInformation((state: any) => state.userInfo);
  const params = useParams<{ id: string }>();

  const [createProduct] = useMutation(CREATE_PRODUCT);
  const [saveProduct] = useMutation(SAVE_PRODUCT);

  const handleCreateProduct = async (
    param: ProductParamInput,
    callback?: (productId: string) => void,
  ) => {
    try {
      const response = await createProduct({
        variables: { hostId: myInfo.me.host!.id, param },
      });

      if (response) {
        if (callback) {
          callback(response.data.createProduct.id);
        }
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleSaveProduct = async (
    param: ProductParamInput,
    productId?: string,
    callback?: (productId: string) => void,
  ) => {
    try {
      const response = await saveProduct({
        variables: { id: productId ? productId : params.id, param },
      });

      if (response) {
        if (callback) {
          callback(response.data.saveProduct.id);
        }
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  return { handleCreateProduct, saveProduct, handleSaveProduct };
}
