import { ICON_NAVIGATE_BACK } from 'assets/icons';
import React from 'react';
import { styled } from 'styled-components';

const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  background-color: #f4f4f5;
`;

interface MenuBackWardButton {
  onClick: () => void;
}

const MenuBackwardButton: React.FC<MenuBackWardButton> = (props) => {
  const { onClick } = props;

  return (
    <Container>
      <img
        src={ICON_NAVIGATE_BACK}
        alt="navigate back icon"
        onClick={onClick}
        style={{ width: '16px', height: '16px' }}
      />
    </Container>
  );
};

export default MenuBackwardButton;
