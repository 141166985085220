import { TicketFilterInput } from './../../hooks/useBookingListHooks';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { MeData } from 'graphQL/Auth/types';
import { useUserInformation } from 'graphQL/Zustand/useUserInformation';
import { useEffect, useState } from 'react';
import {
  BookingTicket,
  Ticket,
  TicketEdge,
  TicketOrderType,
  TicketState,
} from 'types/api';
import { GET_BOOKING_TICKETS } from '..';
import { cloneDeep, isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';
import { errorHandler } from 'utils/ErrorHandler';
import { BookingTermType } from '../types';

dayjs.locale('ko');

export function useQueryGetBookingTickets() {
  const myInfo: MeData = useUserInformation(state => state.userInfo);
  const location = useLocation();

  const startedAt = new Date();
  startedAt.setMonth(startedAt.getMonth() - 1);
  startedAt.setHours(0, 0, 0, 0);

  const [ticketFilterInput, setTicketFilterInput] = useState<TicketFilterInput>(
    {
      page: 1,
      size: 10,
      filter: {
        hostId: myInfo.me.host.id,
        statusIn: [TicketState.CONFIRMED],
        productId: location.state ? location.state.productId : null,
        orderType: TicketOrderType.SCHEDULE_EARLIEST,
        termWithType: {
          term: { startedAt: startedAt.getTime() },
          type: BookingTermType.STARTING,
        },
      },
    },
  );

  const { data, loading, refetch } = useQuery<BookingTicket>(
    GET_BOOKING_TICKETS,
    {
      variables: ticketFilterInput,
    },
  );

  const [bookingInfo, setBookingInfo] = useState<{
    totalCount: number;
    hasNextPage: boolean;
  }>();
  const [bookingTickets, setBookingTickets] = useState<
    {
      date: string;
      value: Ticket[];
    }[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const convertTimestampToDate = timestamp => {
    return dayjs(timestamp).format('YYYY년 M월 D일 ddd요일');
  };

  // 데이터를 날짜별로 그룹화하는 함수
  const groupDataByDate = (data: TicketEdge[]) => {
    let result = [];

    // const sortedItem = cloneDeep(data)
    //   .filter(item => item.node.scheduleTerm)
    //   .sort(
    //     (a, b) => b.node.scheduleTerm.startedAt - a.node.scheduleTerm.startedAt,
    //   );

    for (let idx = 0; idx < data.length; idx++) {
      const dateString = data[idx].node.scheduleTerm
        ? convertTimestampToDate(data[idx].node.scheduleTerm.startedAt)
        : '';

      // result에서 해당 날짜가 존재하는지 확인
      const existingEntryIndex = result.findIndex(
        item => item.date === dateString,
      );

      if (existingEntryIndex !== -1) {
        // 이미 존재하는 날짜일 경우 value에 새로운 데이터를 추가
        result[existingEntryIndex].value.push(data[idx].node);
      } else {
        // 존재하지 않는 날짜일 경우 새로운 항목 추가
        result = [
          ...result,
          {
            date: dateString,
            value: [data[idx].node],
          },
        ];
      }
    }

    return result;
  };

  // 데이터를 날짜별로 그룹화하는 함수
  const loadMoreGroupDataByDate = (data: TicketEdge[]) => {
    let result = cloneDeep(bookingTickets);

    // const sortedItem = cloneDeep(data)
    //   .filter(item => item.node.scheduleTerm)
    //   .sort(
    //     (a, b) => b.node.scheduleTerm.startedAt - a.node.scheduleTerm.startedAt,
    //   );

    for (let idx = 0; idx < data.length; idx++) {
      const dateString = data[idx].node.scheduleTerm
        ? convertTimestampToDate(data[idx].node.scheduleTerm.startedAt)
        : '';

      // result에서 해당 날짜가 존재하는지 확인
      const existingEntryIndex = result.findIndex(
        item => item.date === dateString,
      );

      if (existingEntryIndex !== -1) {
        // 이미 존재하는 날짜일 경우 value에 새로운 데이터를 추가
        result[existingEntryIndex].value.push(data[idx].node);
      } else {
        // 존재하지 않는 날짜일 경우 새로운 항목 추가
        result = [
          ...result,
          {
            date: dateString,
            value: [data[idx].node],
          },
        ];
      }
    }

    return result;
  };

  const handleLoadMoreBookingTickets = async (page: number) => {
    try {
      const response = await refetch({ ...ticketFilterInput, page });

      if (response) {
        setIsLoading(false);

        const bookingItems = response.data.booking.tickets;

        setBookingInfo({
          totalCount: bookingItems.totalCount,
          hasNextPage: bookingItems.pageInfo.hasNextPage,
        });

        setBookingTickets([
          // ...bookingTickets,
          ...loadMoreGroupDataByDate(bookingItems.edges),
        ]);
        setTicketFilterInput({ ...ticketFilterInput, page });
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleRefetchBooikingFilter = async (
    ticketFilterInput: TicketFilterInput,
  ) => {
    try {
      const response = await refetch({ ...ticketFilterInput });

      if (response) {
        setIsLoading(false);

        const bookingItems = response.data.booking.tickets;

        setBookingInfo({
          totalCount: bookingItems.totalCount,
          hasNextPage: bookingItems.pageInfo.hasNextPage,
        });

        setBookingTickets(groupDataByDate(bookingItems.edges));
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  return {
    bookingTickets,
    ticketFilterInput,
    loading,
    isLoading,
    bookingInfo,
    setIsLoading,
    setTicketFilterInput,
    handleRefetchBooikingFilter,
    handleLoadMoreBookingTickets,
  };
}
